<template>
  <div class="controlComponent" v-if="drawer">
    <el-drawer
      custom-class="controlComponentDrawer"
      title="传感组件清单"
      :visible.sync="drawer"
      size="23%"
    >
      <div class="list-div roll-auto" v-if="listData.length">
        <div class="li-item" v-for="(item, index) in listData" :key="index">
          <div class="icon">
            <img src="@/assets/image/equipment/cgzj-icon.png" alt="" />
          </div>
          <div
            class="name-box"
            v-show="editorObj.index !== index || editorObj.isBool == false"
          >
            <div class="name">
              {{ item.deviceSensorName }}
            </div>
            <div
              class="pic-icon"
              @click.stop="listeditorName(index, item.deviceSensorName)"
            ></div>
          </div>
          <div
            class="editor-name-box"
            v-show="editorObj.index == index && editorObj.isBool == true"
          >
            <el-input
              placeholder="请输入内容"
              v-model="editorInput"
              maxlength="9"
            >
              <i slot="suffix" class="suffix-content">
                <img
                  src="@/assets/image/equipment/icon-quxiao.png"
                  alt=""
                  @click.stop="listqxeditor"
                />
                <img
                  src="@/assets/image/equipment/icon-queding.png"
                  alt=""
                  @click.stop="listdetermineName(item)"
                />
              </i>
            </el-input>
          </div>
        </div>
      </div>
      <div v-else class="flex flex_col flex_a_c" style="padding-top: 2.5rem">
        <img
          class="table-noimg"
          src="@/assets/image/personal/no_table.png"
          alt=""
        />
        <div class="table-emptyword">暂无数据</div>
      </div>
      <Msg ref="tjmsg"></Msg>
    </el-drawer>
  </div>
</template>

<script>
import Msg from "@/components/msg.vue"; //信息提示组件
export default {
  components: { Msg },
  data() {
    return {
      // 控制抽屉的开关
      drawer: false,
      // 设备id
      deviceId: null,
      //列表数据
      listData: [],
      // 控制修改名字
      editorObj: {
        index: 0, //修改项的下标
        isBool: false, //是否有修改
      },
      // 修改名字输入框文字
      editorInput: "",
    };
  },
  methods: {
    // 列表中修改名字点击
    listeditorName(index, name) {
      this.editorObj.index = index;
      this.editorObj.isBool = true;
      this.editorInput = name;
    },
    // 列表取消修改
    listqxeditor() {
      this.editorObj.isBool = false;
    },
    // 列表确定修改
    listdetermineName(item) {
      let sensorId = item.sensorId;
      let objRegExp = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
      if (objRegExp.test(this.editorInput)) {
        this.updateControlName(sensorId, this.editorInput);
      } else {
        this.$refs.tjmsg.msgShow("名称只能是中文，英文，数字，下划线", 1.5);
      }
    },
    /**
     * 请求数据
     * **/
    // 通过设备ID获取设备传感组件
    getDeviceSensor() {
      this.$http
        .getAction(this.$api.getDeviceSensor + "?deviceId=" + this.deviceId)
        .then((res) => {
          if (res.data && res.data.length) {
            this.listData = res.data;
          } else {
            this.listData = [];
          }
        });
    },
    //修改设备传感组件名称
    updateControlName(sensorId, deviceSensorName) {
      let params = {
        deviceId: this.deviceId,
        sensorId: sensorId,
        deviceSensorName: deviceSensorName,
      };
      this.$http.postAction(this.$api.updateControlName, params).then((res) => {
        if (res.code == "200") {
          this.getDeviceControl();
          this.editorObj.isBool = false;
          this.$refs.tjmsg.msgShow("修改成功", 1.5);
        } else {
          this.editorObj.isBool = false;
          this.$refs.tjmsg.msgShow(res.msg, 1.5);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.controlComponent {
  .controlComponentDrawer {
    border-radius: 0.225rem 0px 0px 0.225rem;
    .el-drawer__header {
      height: 0.825rem;
      box-sizing: border-box;
      text-align: left;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #454545;
      padding-top: 0;
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 0.225rem;
      span {
        font-size: 0.225rem;
      }
    }
    .el-drawer__close-btn {
      color: #454545;
    }
    .el-drawer__body {
      padding: 0 0.3rem 0.25rem;
      display: flex;
      flex-direction: column;
      text-align: left;
      .list-div {
        .li-item {
          height: 0.7rem;
          background: #ffffff;
          box-shadow: 0px 2px 12px 0px rgba(44, 44, 45, 0.1);
          border-radius: 0.05rem;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          padding-left: 0.25rem;
          padding-right: 0.25rem;
          margin-bottom: 0.3rem;
          .icon {
            width: 0.475rem;
            height: 0.475rem;
            background: #f2f8f9;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.25rem;
          }
          .name-box {
            flex: 1;
            display: flex;
            justify-content: space-between;
            .name {
              flex: 1;
            }
            .pic-icon {
              cursor: pointer;
              width: 0.1875rem;
              height: 0.175rem;
              background: url("../../../assets/image/equipment/icon-bj.png")
                no-repeat;
            }
          }
          .editor-name-box {
            flex: 1;
            height: 0.45rem;
            .el-input__inner {
              border-radius: 0.1rem;
              border-color: transparent;

              background: #f2f8f9;
            }
            .el-input.is-active .el-input__inner,
            .el-input__inner:focus {
              border-color: rgba(45, 174, 169, 1);
            }
            .el-input__suffix {
              width: 4vw;
              height: 100%;
              .el-input__suffix-inner {
                display: block;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                .suffix-content {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  height: 1.099vw;
                  box-sizing: border-box;
                  border-left: 1px solid #f1f1f1;
                  justify-content: space-around;
                  padding: 0 0.1875rem;
                  img {
                    cursor: pointer;
                    width: 0.175rem;
                    height: 0.175rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
