import axios from 'axios'
import api from './api'
// import api from './apiMock'
// 请求地址
// let baseUrl = process.env.VUE_APP_API_BASE_URL
// let baseUrl = 'http://ibms.hnyfkj.com' // 测试
// let baseUrl = '/wlw' // 本地调试
// let baseUrl = 'https://wlw.hnyfkj.com/agro-universe' // 打包
// let baseUrl = 'http://222.133.14.246:28089/agro-universe'  // 正式
// let baseUrl = 'http://nywlw.hnyfkj.com'
// let baseUrl = 'https://nywlw.hnyfkj.com'
// let baseUrl = 'http://82.157.58.19:8083/agro-universe'
let baseUrl = '/agro-universe' // 打包
// let axiosInstance = axios.create({
// 	baseURL: baseUrl,
// })
let axiosInstance = axios.create({})
let token = ''
axiosInstance.interceptors.request.use(
	(config) => {
		token = window.localStorage.getItem('wlwtoken')
		// 全局设置头部token
		config.headers['X-Token'] = token
		return config
	},
	(err) => {
		return Promise.reject(err)
	}
)

axiosInstance.interceptors.response.use(
	(reponse) => {
		// console.log(reponse)
		if (reponse.data.code == 401) {
			console.log('token失效，请重新登录')
			window.localStorage.clear()
			window.location.href = '/'
			return
		}
		return reponse.data
	},
	(error) => {
		// console.log(error)
		// console.log(error.response)
		if (error.response) {
			// console.log(error.response.request.responseURL.indexOf('/loginin'))
			if (error.response.request.responseURL.indexOf('/loginin') > -1) {
				// console.log('token失效，请重新登录')
				window.localStorage.clear()
				window.location.href = '/'
				return error.response
				// return Promise.reject('token失效，请重新登录')
			}
			switch (error.response.status) {
				case 401:
					return Promise.reject('token失效，请重新登录')
				case 404:
					return Promise.reject('网络错误，请检查网络连接或联系维护人员')
				case 504:
					return Promise.reject('连接服务器失败')
				default:
					return Promise.reject('')
			}
		}
	}
)
// get
function getAction(url, params, isBase = baseUrl) {
	return axiosInstance({
		url: isBase + url,
		method: 'get',
		params: params,
	})
}
// post
function postAction(url, data, isBase = baseUrl) {
	return axiosInstance({
		url: isBase + url,
		method: 'post',
		data: data,
	})
}
//put
function putAction(url, parameter, isBase = baseUrl) {
	return axiosInstance({
		url: isBase + url,
		method: 'put',
		data: parameter,
	})
}
//deleteAction
function deleteAction(url, parameter, isBase = baseUrl) {
	return axiosInstance({
		url: isBase + url,
		method: 'delete',
		params: parameter,
	})
}

function getOrgids() {
	let userInfo = JSON.parse(window.localStorage.getItem('wlwUserInfo')) || {}
	return axiosInstance({
		url: baseUrl + api.findChildOrgByParentOrgId,
		method: 'get',
		params: {
			parentOrgId: userInfo.orgId,
			type: '2',
		},
	})
}

function jqAjax(url, type, requestContent, successCallback, isAsync = true, isJson = true, failCallback) {
	if (type == null) {
		type = 'POST'
	}
	function invocationFailure(error) {
		if (failCallback && typeof failCallback == 'function') {
			failCallback()
		}
	}
	$.ajax({
		type: type,
		url: baseUrl + url,
		data: requestContent,
		async: isAsync,
		success: successCallback,
		processData: isJson, // 使用formData传参很重要的配置
		contentType: isJson ? 'application/json; charset=utf-8' : 'application/x-www-form-urlencoded; charset=utf-8',
		dataType: 'json',
		error: function (jqXHR) {
			invocationFailure(jqXHR)
		},
	})
}

export { postAction, getAction, putAction, deleteAction, getOrgids, jqAjax }
