<template>
  <el-dialog
    custom-class="propicdialog"
    :visible.sync="productPic"
    width="100%"
    top="0%"
    @closed="handleClose"
  >
    <div
      class="el-image-viewer__btn el-image-viewer__close"
      @click="productPic = false"
    >
      <i class="el-icon-close"></i>
    </div>
    <div class="el-image-viewer__btn el-image-viewer__prev" @click="prevClick">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="el-image-viewer__btn el-image-viewer__next" @click="nextClick">
      <i class="el-icon-arrow-right"></i>
    </div>
    <div class="el-image-viewer__btn el-image-viewer__actions">
      <div class="el-image-viewer__actions__inner">
        <i class="el-icon-zoom-out" @click="shrink"></i>
        <i class="el-icon-zoom-in" @click="enlarge"></i>
        <i class="el-image-viewer__actions__divider"></i>
        <i
          :class="{
            'el-icon-full-screen': styleObj.isFull,
            'el-icon-c-scale-to-original': !styleObj.isFull,
          }"
          @click="fullClick"
        ></i>
        <i class="el-image-viewer__actions__divider"></i>
        <i class="el-icon-refresh-left" @click="niRotate"></i>
        <i class="el-icon-refresh-right" @click="shunRotate"></i>
      </div>
    </div>
    <div class="el-image-viewer__canvas" @click="productPic = false">
      <div
        class="content-box"
        :style="
          styleObj.isFull
            ? 'transform:scale(' +
              styleObj.scale +
              ') rotate(' +
              styleObj.rotate +
              'deg);margin-left: ' +
              styleObj.x +
              'px;margin-top: ' +
              styleObj.y +
              'px;'
            : 'transform:scale(' +
              styleObj.scale +
              ') rotate(' +
              styleObj.rotate +
              'deg);margin-left: ' +
              styleObj.x +
              'px;margin-top: ' +
              styleObj.y +
              'px;'
        "
      >
        <img
          :src="preview.imglist[index]"
          class="el-image-viewer__img"
          alt=""
          :style="styleObj.isFull ? 'max-height: 756px;max-width: 1728px;' : ''"
        />
        <div class="txt-box">
          {{ preview.content }}
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ["preview"],
  data() {
    return {
      productPic: false,
      imgUrl: null,
      index: 0,
      styleObj: {
        scale: 1,
        isFull: true,
        rotate: 0,
        x: 0,
        y: 0,
      },
      isMousedown: false, //鼠标是否按下
      moveStart: { x: 0, y: 0 }, //拖拽元素初始位置
    };
  },
  mounted() {
    this.imgUrl = this.preview.imglist[this.index];
    window.addEventListener("wheel", this.handleMouseWheel, {
      passive: false,
    });
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleMouseWheel, {
      passive: false,
    });
  },
  methods: {
    handleClose() {
      this.index = 0;
      this.styleObj = {
        scale: 1,
        isFull: true,
        rotate: 0,
        x: 0,
        y: 0,
      };
    },
    // 上一张
    prevClick() {
      let num = this.preview.imglist.length;
      if (this.index <= 0) {
        this.index = num - 1;
      } else {
        this.index--;
      }
      this.styleObj = {
        scale: 1,
        isFull: true,
        rotate: 0,
        x: 0,
        y: 0,
      };
    },
    // 下一张
    nextClick() {
      let num = this.preview.imglist.length;
      if (this.index >= num - 1) {
        this.index = 0;
      } else {
        this.index++;
      }
      this.styleObj = {
        scale: 1,
        isFull: true,
        rotate: 0,
        x: 0,
        y: 0,
      };
    },
    // 放大
    enlarge() {
      this.styleObj.scale += 0.1;
    },
    // 缩小
    shrink() {
      if (this.styleObj.scale <= 0.2) {
        return;
      }
      this.styleObj.scale -= 0.1;
    },
    // 比例切换
    fullClick() {
      this.styleObj.isFull = !this.styleObj.isFull;
    },
    //逆时针
    niRotate() {
      console.log("点击");
      this.styleObj.rotate -= 90;
    },
    // 顺时针
    shunRotate() {
      this.styleObj.rotate += 90;
    },
    // 滚轮事件
    handleMouseWheel(e) {
      if (!window.scrollY) {
        // 禁止页面滚动
        // e.preventDefault()

        if (e.wheelDelta) {
          // 判断浏览器IE，谷歌滑轮事件
          if (e.wheelDelta > 0) {
            this.styleObj.scale += 0.1;
          }
          if (e.wheelDelta < 0) {
            // 当滑轮向下滚动时
            if (this.styleObj.scale <= 0.2) {
              return;
            }
            this.styleObj.scale -= 0.1;
          }
        } else if (e.detail) {
          // Firefox滑轮事件
          if (e.detail > 0) {
            // 当滑轮向下滚动时
            if (this.styleObj.scale <= 0.2) {
              return;
            }
            this.styleObj.scale -= 0.1;
          }
          if (e.detail < 0) {
            // 当滑轮向上滚动时
            this.styleObj.scale += 0.1;
          }
        }
      }
    },
    // 鼠标按下
    dragMousedown() {
      this.isMousedown = true;
      this.moveStart.x = event.clientX;
      this.moveStart.y = event.clientY;
    },
    // 鼠标按下且移动
    dragMousemove() {
      if (this.isMousedown) {
        this.styleObj.x += (event.clientX - this.moveStart.x) / 8;
        this.styleObj.y += (event.clientY - this.moveStart.y) / 8;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .propicdialog {
  margin: 0;
  height: 100%;
  background: transparent;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
    .el-image-viewer__actions__inner {
      i {
        cursor: pointer;
      }
    }
    .el-image-viewer__canvas {
      .content-box {
        position: relative;
        img {
          pointer-events: none;
        }
        .txt-box {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          padding: 34px 33px 24px 29px;
          background: rgba(0, 0, 0, 0.4);
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 38px;
          text-align: left;
        }
      }
    }
  }
}
</style>