<template>
	<div id="he-plugin-standard">
		<iframe
			scrolling="no"
			src="https://widget.tianqiapi.com/?style=yf&skin=pitaya"
			frameborder="0"
			width="530"
			height="420"
			allowtransparency="true"
		></iframe>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	mounted() {
		// setTimeout(() => {
		// 	window.WIDGET = {
		// 		CONFIG: {
		// 			layout: '2',
		// 			width: 530,
		// 			height: 420,
		// 			background: '5',
		// 			dataColor: 'FFFFFF',
		// 			key: 'a8f6741fb31f4535bfd126ba32b75cf3',
		// 		},
		// 	}
		// 	;(function (d) {
		// 		var c = d.createElement('link')
		// 		c.rel = 'stylesheet'
		// 		//   c.href =
		// 		//     "https://widget.qweather.net/standard/static/css/he-standard.css?v=1.4.0";
		// 		c.href = './he-simple.css'
		// 		var s = d.createElement('script')
		// 		//   s.src =
		// 		//     "https://widget.qweather.net/standard/static/js/he-standard.js?v=1.4.0";
		// 		s.src = 'https://widget.qweather.net/standard/static/js/he-standard.js?v=1.4.0'
		// 		//   s.src = "./he-simple.js";
		// 		var sn = d.getElementsByTagName('script')[0]
		// 		sn.parentNode.insertBefore(c, sn)
		// 		sn.parentNode.insertBefore(s, sn)
		// 	})(document)
		// }, 500)
	},
}
</script>

<style lang="scss">
.bg_pic {
	width: 530px !important;
	height: 420px !important;
}
@import '@/assets/css/weather.scss';
</style>
