<template>
	<div class="environment-page flex">
		<!-- 左边树形选择图 -->
		<div class="left">
			<div class="hea-box">我的区域</div>
			<div class="trees-box" v-if="treeData.length && treeData[0]['children'].length">
				<el-tree
					ref="trees"
					:data="treeData"
					:props="defaultPropsInit"
					highlight-current
					:default-expanded-keys="['0', '0-0']"
					node-key="id"
					@node-click="handleNodeClick"
				>
					<template #default="{ node, data }">
						<span class="custom-tree-node">
							<span v-if="data.children"
								>{{ node.label }}（<span class="on-color">{{ data.onlineNum }}</span
								>/{{ data.totalNum }}）</span
							>
							<span
								v-else
								:class="{
									'tree-choose-active': playIdObj.id == data.id,
								}"
								><img class="tree-icon" v-if="data.online" src="@/assets/image/product/icon-online.png" alt="" /><img
									class="tree-icon"
									v-else
									src="@/assets/image/product/icon-offlInne.png"
									alt=""
								/>{{ node.label }}
							</span>
						</span>
					</template>
				</el-tree>
			</div>
			<div class="table-empty flex flex_col flex_ju_c flex_a_c trees-box" v-else>
				<img class="table-noimg" src="@/assets/image/home/ZWT.png" alt="" />
				<div class="table-emptyword">暂无数据</div>
			</div>
		</div>
		<!-- 右边数据展示 -->
		<div class="flex flex_col right">
			<!-- 头部表单 -->
			<!-- 上面的表单 -->
			<div class="flex flex_a_c flex_ju_sb selects">
				<el-form :model="queryParam" ref="ruleform" :inline="true" class="warnform-inline">
					<el-form-item label="选择时间" class="form-picker">
						<div class="picker-divs picker-divs-more">
							<el-date-picker
								v-model="queryParam.startTime"
								size="small"
								type="datetime"
								format="yyyy-MM-dd HH:mm:ss"
								value-format="yyyy-MM-dd HH:mm:ss"
								placeholder="选择日期"
								popper-class="pick-poper"
								class="picker-div"
								prefix-icon=" "
								clear-icon="el-icon-date"
								:clearable="false"
								:picker-options="pickerOptions0"
								:editable="false"
							>
							</el-date-picker>
							<i class="pick-icon el-icon-date"></i>
							<i class="pick-icon-yuan el-icon-time"></i>
						</div>

						<span class="pick-word">至</span>
						<div class="picker-divs picker-divs-more">
							<el-date-picker
								v-model="queryParam.endTime"
								size="small"
								type="datetime"
								format="yyyy-MM-dd HH:mm:ss"
								value-format="yyyy-MM-dd HH:mm:ss"
								placeholder="选择日期"
								popper-class="pick-poper"
								class="picker-div"
								prefix-icon=" "
								clear-icon="el-icon-date"
								:clearable="false"
								:picker-options="pickerOptions1"
								:editable="false"
							>
							</el-date-picker>
							<i class="pick-icon el-icon-date"></i>
							<i class="pick-icon-yuan el-icon-time"></i>
						</div>
					</el-form-item>
					<el-form-item>
						<div class="flex flex_ju_end form-btns">
							<el-button type="primary" @click="searchClick">查询</el-button>
						</div>
					</el-form-item>
				</el-form>
				<div class="flex flex_a_c select-top-btns">
					<div class="btn-tops" @click="handleOpenExport">导出记录</div>
					<div class="btn-tops" v-if="openType == 'all'" @click="changeOpenType('alalysis')">数据分析</div>
					<div class="btn-tops" v-if="openType == 'lines'" @click="changeOpenType('alalysis')">重新筛选</div>
					<div class="btn-tops" v-if="openType != 'all'" @click="changeOpenType('all')">返回</div>
				</div>
			</div>
			<template v-if="treeData[0] && treeData[0]['children'].length">
				<!-- 下面数据展示 -->
				<div class="content-all" v-if="openType == 'all'">
					<!-- 综合显示 -->
					<div class="flex flex_col wraps">
						<!-- 名称 -->
						<div class="titles" :class="{ 'titles-two': tabIndex == 1 }">
							{{ deviceInfo.deviceName }}
						</div>
						<!-- 传感器tab -->
						<div class="tabs-wrap" v-if="treeThreeItem && treeThreeItem.length && tabIndex == 0">
							<div class="flex flex_a_c tabs">
								<div
									class="tab-btn"
									:class="{ 'tab-btn-active': treeThreeIndex == index }"
									v-for="(item, index) in treeThreeItem"
									:key="index"
									@click="changeEquip(index)"
								>
									{{ item.subDeviceName }}
								</div>
							</div>
						</div>
						<!-- 有图表时显示 -->

						<!-- 图表选项与按钮切换 -->
						<div class="flex flex_a_c flex_ju_sb items-wrap">
							<!-- 左侧采集项 -->
							<template v-if="tabIndex == 0">
								<ul class="flex flex_a_c lengends" v-if="lengend.length">
									<li class="lengend-div" v-for="(item, index) in lengend" :key="index">
										<span class="lengend-len" :style="{ background: lengendColors[index] }"></span>
										<span class="lengend-name">{{ item }}</span>
									</li>
								</ul>
								<div v-else class="lengends lengends-no"></div>
							</template>

							<div class="lengends-no" v-else></div>
							<!-- 右侧按钮 -->
							<div class="flex items-btns">
								<div class="items-btn" :class="{ 'items-btn-active': tabIndex == 0 }" @click="changeTab(0)">
									<img v-if="tabIndex == 0" class="tab-icon" src="@/assets/image/analysis/icon_charts.png" alt="" />
									<img v-else class="tab-icon" src="@/assets/image/analysis/icon_chartn.png" alt="" />
									<span>查看图表</span>
								</div>
								<div class="items-btn" :class="{ 'items-btn-active': tabIndex == 1 }" @click="changeTab(1)">
									<img v-if="tabIndex == 1" class="tab-icon" src="@/assets/image/analysis/icon_tableh.png" alt="" />
									<img v-else class="tab-icon" src="@/assets/image/analysis/icon_tables.png" alt="" /><span
										>查看报表</span
									>
								</div>
							</div>
						</div>
						<template v-if="lengend.length">
							<!-- 折线图数据 -->
							<div class="chart-all" v-if="tabIndex == 0">
								<div class="chart-divs" v-for="(item, index) in smallLineData" :key="index">
									<!-- 折线图 -->
									<small-chart-modal :index="index" :datas="item" :orgName="orgName"></small-chart-modal>
									<div class="icon_setdiv" @click="changeWrap(true, index)">
										<img class="icon_add" src="@/assets/image/analysis/icon_add.png" alt="" />
									</div>
								</div>
							</div>
							<!-- 图表 -->
							<div v-else>
								<!-- 表格 -->
								<big-table-modal ref="bigTableModalRef"></big-table-modal>
							</div>
						</template>
						<div v-else class="table-empty flex flex_col flex_ju_c flex_a_c">
							<img class="table-noimg" src="@/assets/image/personal/no_table.png" alt="" />
							<div class="table-emptyword">暂无数据</div>
						</div>
					</div>
					<!-- 单个显示 -->
					<div class="one-wrap" v-if="isShowBig">
						<!-- 有数据 -->
						<div class="flex flex_ju_sb one-main" v-show="!bigIsEmpty">
							<!-- 左边的折线图 -->
							<div class="big-chart" ref="bigChart"></div>
							<!-- 右边的table -->
							<div class="warn-tables big-table">
								<div style="text-align: right">
									<div class="icon_setdiv" @click="changeWrap(false)">
										<img class="icon_add" src="@/assets/image/analysis/icon_sun.png" alt="" />
									</div>
								</div>

								<el-table
									:data="bigTableShowData"
									class="tables"
									:stripe="false"
									align="center"
									header-cell-class-name="header-cell"
									cell-class-name="table-cell"
								>
									<el-table-column type="index" label="序号" align="center" :width="100"> </el-table-column>
									<el-table-column
										prop="values"
										:label="bigTableName"
										align="center"
										show-overflow-tooltip
										:width="100"
									>
									</el-table-column>
									<el-table-column prop="times" label="接收时间" align="center" show-overflow-tooltip>
									</el-table-column>
								</el-table>
								<!-- 分页 -->
								<el-pagination
									class="pagination"
									layout="prev, pager, next"
									:current-page.sync="rightPageNum"
									:total="bigTableData.length"
									:pager-count="5"
									:page-size="10"
									:hide-on-single-page="true"
									@current-change="handleCurrentChange"
								>
								</el-pagination>
							</div>
						</div>
						<!-- 空数据状态 -->
					</div>
				</div>

				<!-- 点击数据分析的显示 -->
				<div class="content-all" v-if="openType == 'alalysis'">
					<div class="analysis-wrap">
						<div class="flex flex_a_c bold analysis-word">
							请多选左侧您需要对比的产品设备,进行数据分析（温馨提示：一次分析可支持最多6个参数进行分析）
							<div class="btn-default" @click="changeOpenType('lines')">开始分析</div>
						</div>
						<div class="analysis-trees">
							<!-- 树形选择 -->
							<el-tree
								ref="trees"
								:data="analysisTree"
								:props="defaultProps"
								highlight-current
								:default-expanded-keys="['0']"
								node-key="id"
								@node-click="handleNodeClickAna"
							>
								<template #default="{ node, data }">
									<span class="custom-tree-node">
										<!-- 有下一级 -->
										<span v-if="data.children && data.children.length">{{ node.label }}</span>
										<!-- 传感器采集项 -->
										<!-- <span v-else
										><el-checkbox>{{ node.label }}</el-checkbox>
									</span> -->
										<div class="flex flex_a_c" v-else>
											<div
												class="flex flex_a_c flex_ju_c check-div"
												:class="{
													'check-div-active': anaPlayArr.includes(data.id),
												}"
											>
												<i class="el-icon-check check-icon" v-if="anaPlayArr.includes(data.id)"></i>
											</div>
											<span>{{ node.label }}</span>
										</div>
									</span>
								</template>
							</el-tree>
						</div>
					</div>
				</div>
				<!-- 数据分析的折线图 -->
				<div class="content-all" v-show="openType == 'lines'">
					<div class="flex flex_a_c flex_ju_c btn-export" @click="exportDownLoad" v-show="isShowLine">
						<img src="@/assets/image/analysis/icon_export.png" alt="" />导出
					</div>
					<div v-if="isShowLine" class="lines-analysis" ref="lineAnalysis"></div>
					<div v-else class="lines-analysis table-empty flex flex_col flex_ju_c flex_a_c">
						<img class="table-noimg" src="@/assets/image/personal/no_table.png" alt="" />
						<div class="table-emptyword">暂无数据</div>
					</div>
				</div>
			</template>
			<div v-else class="content-all table-empty flex flex_col flex_ju_c flex_a_c">
				<img class="table-noimg" src="@/assets/image/personal/no_table.png" alt="" />
				<div class="table-emptyword">暂无数据</div>
			</div>
		</div>
		<!-- 导出记录右侧抽屉 -->
		<environ-export-drawer-vue ref="exportDrawer"></environ-export-drawer-vue>
		<Msg ref="tsmsg"></Msg>
	</div>
</template>

<script>
import tableMinin from '@/mixins/TableMinin'
import environmentJs from './assets/environment'
import SmallChartModal from './components/SmallChartModal.vue'
import BigTableModal from './components/BigTableModal.vue'
// 导出记录抽屉
import EnvironExportDrawerVue from './components/EnvironExportDrawer.vue'
import Msg from '@/components/msg.vue' //信息提示组件
export default {
	mixins: [tableMinin, environmentJs],
	components: {
		SmallChartModal,
		BigTableModal,
		EnvironExportDrawerVue,
		Msg,
	},
	data() {
		return {
			//树形组件数据
			treeData: [],
			defaultPropsInit: {
				children: 'children',
				label: 'label',
			},
			defaultProps: {
				children: 'children',
				label: 'label',
			},
			// 存放树形组件的选中
			playIdObj: {},
			deviceInfo: {}, // 选中树形下面的传感器数据
			treeThreeItem: [], // 选中大棚下的传感器等设备
			treeThreeIndex: 0, // 传感器选中的第几个
			// 时间限制
			pickerOptions0: {
				disabledDate: (time) => {
					if (this.queryParam.endTime) {
						if (this.queryParam.endTime != '') {
							let num = new Date(this.queryParam.endTime).getTime()
							return time.getTime() > num
						} else {
						}
					} else {
						return false
					}
				},
			},
			pickerOptions1: {
				disabledDate: (time) => {
					let num = new Date().getTime()
					if (time.getTime() > num) {
						return true
					} else {
						let startNum = new Date(this.queryParam.startTime).getTime() - 43200000
						if (time.getTime() < startNum) {
							return true
						} else {
							return false
						}
					}
				},
			},

			// 图表还是报表
			tabIndex: 0,
			lengendColors: [
				'#2DAEA9',
				'#1AA1DF',

				'#8745E9',
				'#0EF0A3',
				'#EE00EB',
				'#2D3CAE',
				'#D76933',
				'#ADE534',
				'#F33D1B',
				'#EAEC1B',
				'#0EF024',
				'#0EECF3',
				'#998AE7',
				'#1560D6',
			],
			orgName: '',
			// 图例数据
			lengend: [],
			// 默认进来多个折线图的总数据
			smallLineData: [],

			// 单数据展示所用变量
			bigTableData: [], // 表格总数据
			// table根据分页显示的数据
			bigTableShowData: [],
			bigTableName: '空气温度',
			isShowBig: false, // 页面显示的是否是图表放大状态
			bigIndex: 0, // 页面放大状态显示的弟几个
			bigIsEmpty: false, // 放大状态是否为空
			rightPageNum: 1,

			openType: 'all', // 显示页面的类型  all  alalysis  lines
			// 数据分析页面数据
			analysisTree: [],
			lineColors: ['#2CADA7', '#44A9FF', '#00DDDA', '#FFCC44', '#FF9256', '#56FF7A'],
			isShowLine: true, // 数据分析页面折线图是否显示
		}
	},
	mounted() {
		// 默认时间赋值
		this.$set(this.queryParam, 'startTime', this.getNowDate() + ' 00:00:00')
		this.$set(this.queryParam, 'endTime', this.getNowDate() + ' 23:59:59')
		// 获取设备下拉选
		// 获取左侧我的区域数据
		this.userDeviceList()
	},
	methods: {
		/*
		 * @description: 数据请求
		 */
		userDeviceList() {
			//  气象,控制箱,智能终端,土壤墒情
			let params = {
				type: '1,6,2,3',
			}
			this.$http.getAction(this.$api.userDeviceList, params).then((res) => {
				// res = {
				// 	code: 200,
				// 	msg: 'ok',
				// 	data: [
				// 		{
				// 			areaName: '全区域',
				// 			totalNum: 1,
				// 			onlineNum: 0,
				// 			list: [
				// 				{
				// 					deviceNum: '22120900014',
				// 					productTypeDesc: '智能终端',
				// 					deviceBizStatus: 'normal',
				// 					productId: '27',
				// 					deviceId: '1217',
				// 					deviceName: '智能放风机14',
				// 					productName: '智能放风机',
				// 					deviceStatus: 'offline',
				// 				},
				// 			],
				// 			offlineNum: 1,
				// 		},
				// 		{ areaName: '123', totalNum: 0, onlineNum: 0, list: [], offlineNum: 0 },
				// 	],
				// }
				if (res.code == 200) {
					let datas = res.data || []
					let treeData = []
					if (!datas.length) {
						treeData = []
					} else if (datas.length == 1 && datas[0]['areaName'] == '全区域') {
						let newDatas = [...datas]
						// 为数据选择分析赋值的数据
						let twoArrs = []
						newDatas.forEach((item, index) => {
							let obj = {}
							obj.label = item.areaName
							obj.id = index + '-' + index
							obj.onlineNum = item.onlineNum
							obj.totalNum = item.totalNum
							obj.children = []
							item.list.forEach((item2, index2) => {
								let twoObj = {}
								obj.children.push({
									deviceNum: item2.deviceNum,
									label: item2.deviceName,
									pname: item.areaName,
									id: item2.deviceId,
									online: item2.deviceStatus == 'offline' ? false : true,
									source: 0, //0自己1共享
									deviceId: item.deviceId,
								})
								// 为数据选择分析赋值的数据
								twoObj.label = item2.deviceName
								twoObj.id = item2.deviceId
								twoObj.children = []

								twoArrs.push(twoObj)
							})
							treeData.push(obj)
						})

						// 赋值数据分析页面的树形数据
						this.analysisTree = twoArrs || []
						if (treeData.length) {
							this.playIdObj = treeData[0].children[0]
						}

						// 获取区域下设备的传感器信息列表
						if (this.playIdObj && JSON.stringify(this.playIdObj) != '{}') {
							// 获取区域下设备的传感器信息列表
							this.getDeviceInfo()
						}
					} else {
						treeData = []
						// 有其它的区域
						let allIndex = datas.findIndex((item) => {
							return item.areaName == '全区域'
						})
						// 第一个全区域的数据
						let allItem = datas.slice(allIndex, 1)[0]
						this.$set(allItem, 'children', [])
						this.$set(allItem, 'label', allItem.areaName)
						this.$set(allItem, 'id', '0')
						let newDatas = [...datas]
						this.$delete(newDatas, allIndex)
						let arrs = []
						// 为数据选择分析赋值的数据
						let twoArrs = []
						if (allItem.list && allItem.list.length) {
							allItem.list.forEach((item2, index2) => {
								let obj = {}
								let twoObj = {}
								obj = {
									deviceNum: item2.deviceNum,
									label: item2.deviceName,
									pname: allItem.areaName,
									id: item2.deviceId,
									online: item2.deviceStatus == 'offline' ? false : true,
									source: 0, //0自己1共享
								}
								arrs.push(obj)

								// 为数据选择分析赋值的数据
								twoObj.label = item2.deviceName
								twoObj.id = item2.deviceId
								twoObj.children = []

								twoArrs.push(twoObj)
							})
						}

						newDatas.forEach((item, index) => {
							let obj = {}
							obj.label = item.areaName
							obj.id = index + '-' + index
							obj.onlineNum = item.onlineNum
							obj.totalNum = item.totalNum
							obj.children = []
							if (item.list.length) {
								item.list.forEach((item2, index2) => {
									let twoObj = {}
									obj.children.push({
										deviceNum: item2.deviceNum,
										label: item2.deviceName,
										pname: item.areaName,
										id: item2.deviceId,
										online: item2.deviceStatus == 'offline' ? false : true,
										source: 0, //0自己1共享
										deviceId: item.deviceId,
									})
									// 为数据选择分析赋值的数据
									twoObj.label = item2.deviceName
									twoObj.id = item2.deviceId
									twoObj.children = []

									twoArrs.push(twoObj)
								})
							}
							arrs.push(obj)
						})
						// 赋值数据分析页面的树形数据
						this.analysisTree = twoArrs || []
						if (arrs.length && arrs[0]) {
							if (arrs[0].children) {
								this.playIdObj = arrs[0].children[0] || {}
							} else if (arrs[0]['id']) {
								this.playIdObj = arrs[0] || {}
							}
						}
						allItem.children = arrs
						treeData.push(allItem)
						if (this.playIdObj && JSON.stringify(this.playIdObj) != '{}') {
							// 获取区域下设备的传感器信息列表
							this.getDeviceInfo()
						}
					}
					console.log(treeData)
					this.treeData = treeData
					// 设置默认选中值
				} else {
					this.treeData = []
				}
			})
		},
		// 获取区域下设备的传感器信息列表
		getDeviceInfo() {
			let deviceIds = []
			deviceIds.push(this.playIdObj.id)
			let params = deviceIds
			this.$http.postAction(this.$api.sensorDeviceInfo, params).then((res) => {
				// res = {
				// 	code: 200,
				// 	msg: 'ok',
				// 	data: [
				// 		{
				// 			deviceId: '756',
				// 			productId: '27',
				// 			productType: '6',
				// 			deviceName: '智能卷被机',
				// 			deviceNum: '22102603000',
				// 			deviceCompId: '22102603000',
				// 			subsetList: [
				// 				{
				// 					subDeviceNum: 's_0',
				// 					subDeviceName: '温湿度传感',
				// 					itemList: [
				// 						{ dataItemCode: 'WD', dataItemName: '温度', dataItemValue: '' },
				// 						{ dataItemCode: 'SD', dataItemName: '湿度', dataItemValue: '' },
				// 					],
				// 					commandsItemList: [],
				// 				},
				// 				{ subDeviceNum: '1', subDeviceName: '测试', itemList: [], commandsItemList: [] },
				// 			],
				// 			buttonList: [],
				// 		},
				// 	],
				// }
				if (res.code == 200) {
					let result = res.data || []
					if (result && result.length) {
						let datas = result[0]
						this.deviceInfo = datas
						// 传感器设备
						if (datas.subsetList && datas.subsetList.length) {
							this.treeThreeItem = datas.subsetList || []
							this.treeThreeIndex = 0

							// 获取对那个传感器下面的采集项列表
							this.getAllItemLine()
						} else {
							this.treeThreeItem = []
						}
					} else {
						this.deviceInfo = {}
					}
				}
			})
		},
		// 获取对选中传感器下面的采集项列表数据
		getAllItemLine() {
			let deviceInfo = this.treeThreeItem[this.treeThreeIndex]
			let itemList = deviceInfo.itemList
			let sensorData = []
			itemList.forEach((item) => {
				sensorData.push({
					deviceCode: this.deviceInfo['deviceNum'],
					subDeviceId: deviceInfo.subDeviceNum,
					dataItem: item.dataItemCode,
				})
			})
			let params = {
				startTime: this.queryParam.startTime,
				endTime: this.queryParam.endTime,
				sensorData: sensorData,
				// sensorData: [
				// 	{
				// 		deviceCode: '22112202000',
				// 		subDeviceId: 's_0',
				// 		dataItem: 'WD',
				// 	},
				// 	{
				// 		deviceCode: '22112202000',
				// 		subDeviceId: 's_1',
				// 		dataItem: 'SD',
				// 	},
				// 	{
				// 		deviceCode: '22111503000',
				// 		subDeviceId: 's_0',
				// 		dataItem: 'SD',
				// 	},
				// ],
			}
			this.$http.postAction(this.$api.environmentAnalyse, params).then((res) => {
				// res = {
				// 	code: 200,
				// 	msg: 'ok',
				// 	data: [
				// 		{
				// 			dataList: [
				// 				['2022-11-23 20:37:35', '54.8'],
				// 				['2022-11-23 20:42:35', '54.7'],
				// 				['2022-11-23 20:47:34', '54.6'],
				// 				['2022-11-23 20:52:34', '54.7'],
				// 				['2022-11-23 20:57:35', '54.5'],
				// 				['2022-11-23 21:02:35', '54.7'],
				// 				['2022-11-23 21:07:34', '54.8'],
				// 				['2022-11-23 21:12:34', '55.0'],
				// 				['2022-11-23 21:17:35', '55.4'],
				// 				['2022-11-23 21:22:35', '55.5'],
				// 				['2022-11-23 21:27:34', '55.4'],
				// 				['2022-11-23 21:32:35', '55.6'],
				// 				['2022-11-23 21:37:35', '55.5'],
				// 				['2022-11-23 21:40:48', '55.3'],
				// 				['2022-11-23 21:45:47', '55.2'],
				// 				['2022-11-23 21:55:47', '54.7'],
				// 				['2022-11-23 22:05:48', '55.2'],
				// 				['2022-11-23 22:15:47', '55.1'],
				// 				['2022-11-23 22:25:48', '54.9'],
				// 				['2022-11-23 22:35:47', '54.8'],
				// 				['2022-11-23 22:45:47', '54.9'],
				// 				['2022-11-23 22:55:48', '54.7'],
				// 				['2022-11-23 23:05:47', '54.6'],
				// 				['2022-11-23 23:15:47', '54.8'],
				// 				['2022-11-23 23:25:47', '55.1'],
				// 				['2022-11-23 23:35:47', '55.5'],
				// 				['2022-11-23 23:45:47', '55.9'],
				// 				['2022-11-23 23:55:47', '56.1'],
				// 			],
				// 			name: '温度',
				// 			unit: '%',
				// 		},
				// 		{
				// 			dataList: [
				// 				['2022-11-23 20:37:35', '54.8'],
				// 				['2022-11-23 20:42:35', '54.7'],
				// 				['2022-11-23 20:47:34', '54.6'],
				// 				['2022-11-23 20:52:34', '54.7'],
				// 				['2022-11-23 20:57:35', '54.5'],
				// 				['2022-11-23 21:02:35', '54.7'],
				// 				['2022-11-23 21:07:34', '54.8'],
				// 				['2022-11-23 21:12:34', '55.0'],
				// 				['2022-11-23 21:17:35', '55.4'],
				// 				['2022-11-23 21:22:35', '55.5'],
				// 				['2022-11-23 21:27:34', '55.4'],
				// 				['2022-11-23 21:32:35', '55.6'],
				// 				['2022-11-23 21:37:35', '55.5'],
				// 				['2022-11-23 21:40:48', '55.3'],
				// 				['2022-11-23 21:45:47', '55.2'],
				// 				['2022-11-23 21:55:47', '54.7'],
				// 				['2022-11-23 22:05:48', '55.2'],
				// 				['2022-11-23 22:15:47', '55.1'],
				// 				['2022-11-23 22:25:48', '54.9'],
				// 				['2022-11-23 22:35:47', '54.8'],
				// 				['2022-11-23 22:45:47', '54.9'],
				// 				['2022-11-23 22:55:48', '54.7'],
				// 				['2022-11-23 23:05:47', '54.6'],
				// 				['2022-11-23 23:15:47', '54.8'],
				// 				['2022-11-23 23:25:47', '55.1'],
				// 				['2022-11-23 23:35:47', '55.5'],
				// 				['2022-11-23 23:45:47', '55.9'],
				// 				['2022-11-23 23:55:47', '56.1'],
				// 			],
				// 			name: '湿度',
				// 			unit: '%',
				// 		},
				// 		{
				// 			dataList: [
				// 				['2022-11-23 20:37:35', '54.8'],
				// 				['2022-11-23 20:42:35', '54.7'],
				// 				['2022-11-23 20:47:34', '54.6'],
				// 				['2022-11-23 20:52:34', '54.7'],
				// 				['2022-11-23 20:57:35', '54.5'],
				// 				['2022-11-23 21:02:35', '54.7'],
				// 				['2022-11-23 21:07:34', '54.8'],
				// 				['2022-11-23 21:12:34', '55.0'],
				// 				['2022-11-23 21:17:35', '55.4'],
				// 				['2022-11-23 21:22:35', '55.5'],
				// 				['2022-11-23 21:27:34', '55.4'],
				// 				['2022-11-23 21:32:35', '55.6'],
				// 				['2022-11-23 21:37:35', '55.5'],
				// 				['2022-11-23 21:40:48', '55.3'],
				// 				['2022-11-23 21:45:47', '55.2'],
				// 				['2022-11-23 21:55:47', '54.7'],
				// 				['2022-11-23 22:05:48', '55.2'],
				// 				['2022-11-23 22:15:47', '55.1'],
				// 				['2022-11-23 22:25:48', '54.9'],
				// 				['2022-11-23 22:35:47', '54.8'],
				// 				['2022-11-23 22:45:47', '54.9'],
				// 				['2022-11-23 22:55:48', '54.7'],
				// 				['2022-11-23 23:05:47', '54.6'],
				// 				['2022-11-23 23:15:47', '54.8'],
				// 				['2022-11-23 23:25:47', '55.1'],
				// 				['2022-11-23 23:35:47', '55.5'],
				// 				['2022-11-23 23:45:47', '55.9'],
				// 				['2022-11-23 23:55:47', '56.1'],
				// 			],
				// 			name: '湿度',
				// 			unit: '%',
				// 		},
				// 	],
				// }
				if (res.code == 200) {
					let datas = res.data || []
					if (datas && datas.length) {
						let smallLineData = []

						// 遍历赋值采集项
						let lengend = []
						datas.forEach((item) => {
							if (item.dataList && item.dataList.length) {
								smallLineData.push(item)
								if (lengend.indexOf(item.name) < 0) {
									lengend.push(item.name)
								}
							}
						})
						this.lengend = lengend
						this.smallLineData = smallLineData
					} else {
						this.smallLineData = []
					}
				} else {
					this.smallLineData = []
				}
			})
		},

		// 获取数据分析的多个折线图数据
		getAnalysisLines() {
			// 设置设备的编码以及名称等数据
			let anaplayArrInfo = JSON.parse(JSON.stringify(this.anaplayArrInfo))
			let sensorData = []
			anaplayArrInfo.forEach((item, index) => {
				let splitIds = item.id.split('~')
				sensorData.push({
					deviceCode: splitIds[0],
					subDeviceId: splitIds[2],
					dataItem: splitIds[3],
					name: `${this.playIdObj.pname}-${item.allname}-${index}`,
				})
			})
			let params = {
				startTime: this.queryParam.startTime,
				endTime: this.queryParam.endTime,
				sensorData: sensorData,
			}
			this.$http.postAction(this.$api.environmentAnalyseMore, params).then((res) => {
				if (res.code == 200) {
					let datas = res.data || {}
					let yAxis = []
					let series = []
					let timesAll = []
					let isData = false
					if (datas.series && datas.series.length) {
						isData = true
						datas.series.forEach((item, index) => {
							timesAll = timesAll.concat(item.data)
						})
						const maps = new Map()
						timesAll = timesAll.filter((key) => !maps.has(key[0]) && maps.set(key[0], 1))
						datas.series.forEach((item, index) => {
							// timesAll = timesAll.concat(item.data)
							if (item.data && item.data.length) {
								// yAxis
								let oneTimeData = JSON.parse(JSON.stringify(timesAll))
								timesAll.forEach((itemt, indext) => {
									this.$set(oneTimeData[indext], 1, null)
									item.data.forEach((item3) => {
										if (item3[0] == itemt[0]) {
											this.$set(oneTimeData[indext], 1, item3[1])
										}
									})
								})
								item.data = oneTimeData
								let nameArr = item.name.split('-')
								let itemName = nameArr[nameArr.length - 2]
								let itemIndex = yAxis.findIndex((itemy) => {
									return itemy.itemName == itemName
								})
								if (itemIndex < 0) {
									let aa = {
										type: 'value',
										itemName: itemName,
										name: `(${item.unit})`,
										nameTextStyle: {
											color: '#6B6B6B',
											fontSize: 14,
											align: 'right',
										},
										axisLabel: {
											color: '#6B6B6B',
											fontSize: 14,
										},
										axisLine: {
											show: true,
											lineStyle: {
												color: '#EDEDED',
												width: 2,
											},
										},
										splitLine: {
											show: true,
											lineStyle: {
												color: 'rgba(9, 116, 124, .3)',
											},
										},
									}

									if (index != 0) {
										aa.position = 'left'
										aa.offset = yAxis.length * 45
										aa.splitLine.show = false
									}
									yAxis.push(aa)
								}

								// series
								item.data.sort(function (a, b) {
									let obj1 = a[0]
									let obj2 = b[0]
									const val1 = Math.floor(new Date(obj1).getTime() / 1000)
									const val2 = Math.floor(new Date(obj2).getTime() / 1000)
									return val1 - val2
								})
								let thisSerise = item
								thisSerise.itemName = itemName
								thisSerise.type = 'line'
								thisSerise.smooth = true
								thisSerise.showSymbol = false
								thisSerise.symbolSize = 10
								thisSerise.connectNulls = true
								// thisSerise.yAxisIndex = index
								thisSerise.itemStyle = {
									borderWidth: 5,
								}

								series.push(thisSerise)
							}
						})
					}
					let yAxisEnd = yAxis
					let seriesEnd = series
					// series.forEach((item, index) => {
					// 	if (item.data.length) {
					// 		// yAxisEnd.push(yAxis[index])
					// 		seriesEnd.push(item)
					// 	}
					// })
					seriesEnd.forEach((item, index) => {
						let itemIndex = yAxisEnd.findIndex((itemy) => {
							return itemy.itemName == item.itemName
						})
						item.yAxisIndex = itemIndex
					})
					// 绘制图表
					if (isData && seriesEnd.length) {
						this.isShowLine = true
						this.$nextTick(() => {
							this.lineAnalysis(yAxisEnd, seriesEnd)
						})
					} else {
						this.isShowLine = false
					}
				}
			})
		},
	},
}
</script>
<style>
.pagination {
	padding-top: 0.25rem !important;
}
</style>
<style lang="scss">
@import './assets/environment.scss';
</style>
