<template>
	<div class="rain">
		<div class="top-box">
			<el-form
				:model="tjFrom"
				ref="warnform"
				:inline="true"
				class="flex flex_ju_sb warnform-inline rb-form"
				:style="{ paddingRight: 0 + 'px' }"
			>
				<div class="form-inputs">
					<el-form-item label="选择设备" :style="{ marginRight: 40 + 'px' }">
						<el-select placeholder="请选择" v-model="tjFrom.subDeviceId">
							<el-option
								v-for="(item, index) in collectList"
								:key="index"
								:label="item.deviceName"
								:value="item.deviceNum + '-' + item.subDeviceNum"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-radio-group v-model="tjFrom.type" class="form-radio-group" @change="radioChange">
							<el-radio label="1">按日统计</el-radio>
							<el-radio label="2">按月统计</el-radio>
							<el-radio label="3">按年统计</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="选择时间" class="form-picker">
						<div class="picker-divs picker-divs-more">
							<el-date-picker
								v-model="tjFrom.startTime"
								size="small"
								:type="pickTypes"
								:key="pickTypes"
								placeholder="选择日期"
								popper-class="pick-poper"
								class="picker-div"
								:format="formats"
								:value-format="formats"
								prefix-icon=" "
								clear-icon="el-icon-date"
								:clearable="false"
								:picker-options="pickerOptions0"
								:editable="false"
							>
							</el-date-picker>
							<i class="pick-icon el-icon-date"></i>
							<i class="pick-icon-yuan el-icon-time"></i>
						</div>

						<span class="pick-word">至</span>
						<div class="picker-divs picker-divs-more">
							<el-date-picker
								v-model="tjFrom.endTime"
								size="small"
								:type="pickTypes"
								:key="pickTypes"
								placeholder="选择日期"
								popper-class="pick-poper"
								class="picker-div"
								:format="formats"
								:value-format="formats"
								prefix-icon=" "
								clear-icon="el-icon-date"
								:clearable="false"
								:picker-options="pickerOptions1"
								:editable="false"
							>
							</el-date-picker>
							<i class="pick-icon el-icon-date"></i>
							<i class="pick-icon-yuan el-icon-time"></i>
						</div>
					</el-form-item>
				</div>

				<el-form-item class="form-btns" :style="{ marginRight: 0 + 'px' }">
					<el-button type="primary" @click="searchClick">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="dc-btn" @click="exportRecords">导出记录</div>
		</div>
		<div class="bottom">
			<div class="bottom-title">气象站每日降雨量累计</div>
			<!-- 导出按钮 -->
			<div class="flex flex_a_c flex_ju_c btn-export" @click="exportDownLoad" v-show="isShowLine">
				<img src="@/assets/image/analysis/icon_export.png" alt="" />导出
			</div>
			<div class="echart" ref="chartRef" v-show="isShowLine"></div>
			<div class="echart table-empty flex flex_col flex_ju_c flex_a_c bottom-empty" v-show="!isShowLine">
				<img class="table-noimg" src="@/assets/image/home/ZWT.png" alt="" />
				<div class="table-emptyword">暂无数据</div>
			</div>
		</div>
		<!-- 导出记录右侧抽屉 -->
		<environ-export-drawer-vue ref="exportDrawer"></environ-export-drawer-vue>
	</div>
</template>

<script>
import Msg from '@/components/msg.vue' //信息提示组件
// 导出记录抽屉
import EnvironExportDrawerVue from './components/EnvironExportDrawer.vue'
import {
	prevDayTime,
	getFormatDate2,
	GetPreMonthDay,
	GetNextMonthDay,
	getFormatDate,
	todayTime,
	monthStartDay,
} from '../../times/moment'
import moment from 'moment'
export default {
	components: { Msg, EnvironExportDrawerVue },
	data() {
		return {
			pickerOptions0: {
				disabledDate: (time) => {
					if (this.tjFrom.endTime) {
						if (this.tjFrom.endTime != '') {
							let num = new Date(this.tjFrom.endTime).getTime()
							return time.getTime() > num
						} else {
						}
					} else {
						return false
					}
				},
			},
			pickerOptions1: {
				disabledDate: (time) => {
					let num = new Date().getTime()
					if (time.getTime() > num) {
						return true
					} else {
						let startNum = new Date(this.tjFrom.startTime).getTime() - 43200000
						if (time.getTime() < startNum) {
							return true
						} else {
							return false
						}
					}
				},
			},
			//采集项数组
			collectList: [],
			//表单参数
			// 1 日 2 月 3 年
			tjFrom: {
				subDeviceId: '',
				startTime: '',
				endTime: '',
				type: '1',
			},
			pickTypes: 'date',
			timeType: '日', // 时间的类型
			// 用作对比的时间数据
			comData: {
				startTime: '',
				endTime: '',
			},
			isShowLine: true,
			// 控制导出记录
			drawer: false,
			// 删除确认信息
			Information: false,
			// 记录详情
			detailsDrawer: false,
			// 记录表格数据
			tableData: [],
			chooseId: '',
		}
	},
	computed: {
		formats() {
			let valFormat = 'yyyy-MM-dd'
			switch (this.tjFrom.type) {
				case '1':
					this.pickTypes = 'date'
					valFormat = 'yyyy-MM-dd'
					break
				case '2':
					this.pickTypes = 'month'
					valFormat = 'yyyy-MM'
					break
				case '3':
					this.pickTypes = 'year'
					valFormat = 'yyyy'
					break
				default:
					break
			}
			return valFormat
		},
		exportFormat() {
			let valFormat = 'yyyy-MM-dd'
			switch (this.tjFrom.type) {
				case '1':
					this.pickTypes = 'date'
					valFormat = 'yyyy-MM-dd'
					break
				case '2':
					this.pickTypes = 'month'
					valFormat = 'yyyy-MM'
					break
				case '3':
					this.pickTypes = 'year'
					valFormat = 'yyyy'
					break
				default:
					break
			}
			return valFormat
		},
	},
	mounted() {
		this.makeTimeInit()
	},
	methods: {
		// 时间初始化
		makeTimeInit() {
			this.$set(this.tjFrom, 'startTime', monthStartDay())
			this.$set(this.tjFrom, 'endTime', todayTime())
			this.$set(this.comData, 'startTime', monthStartDay())
			this.$set(this.comData, 'endTime', todayTime())

			// 获取设备下拉选
			this.getSelect()
		},
		/*
		 * @description: 数据获取
		 */
		// 获取设备下拉选
		getSelect() {
			//  气象
			let params = {
				type: '2',
			}
			this.$http.getAction(this.$api.deviceSelect, params).then((res) => {
				if (res.code == 200) {
					let datas = res.data || []
					if (datas && datas.length) {
						this.collectList = datas
						// 设置默认选中值
						this.$set(this.tjFrom, 'subDeviceId', datas[0]['deviceNum'] + '-' + datas[0]['subDeviceNum'])
						// 获取图表数据
						this.getLineData()
					} else {
						this.collectList = []
						this.isShowLine = false
					}
				} else {
					this.collectList = []
					this.isShowLine = false
				}
			})
		},
		//  获取折线图数据
		getLineData() {
			let subDeviceIds = this.tjFrom.subDeviceId.split('-')
			let params = {
				startTime: this.tjFrom.startTime,
				endTime: this.tjFrom.endTime,
				deviceCode: subDeviceIds[0],
				subDeviceId: subDeviceIds[1],
				type: this.tjFrom.type,
			}
			this.$http.postAction(this.$api.rainChart, params).then((res) => {
				if (res.code == 200) {
					let datas = res.data || {}
					// 有折线图数据
					if (datas.xAxis && datas.xAxis.length) {
						this.isShowLine = true
						let xData = datas.xAxis
						let yData = datas.series.data
						let unit = datas.series.unit
						this.$nextTick(() => {
							this.makeChart(xData, yData, unit)
						})
					} else {
						this.isShowLine = false
					}
				} else {
					this.isShowLine = false
				}
			})
		},
		// 查询
		searchClick() {
			this.getLineData()
		},
		// 导出记录
		exportRecords() {
			this.$refs.exportDrawer.open()
		},
		// 添加类名
		addClass({ row, column, rowIndex, columnIndex }) {
			if (columnIndex == 3) {
				//此判断为最后1列
				return 'caozuo-cell'
			}
		},
		// 类型选择切换
		radioChange(val) {
			switch (val) {
				case '1':
					this.timeType = '日'
					this.pickTypes = 'date'
					// 当月的一号到今天
					this.$set(this.tjFrom, 'startTime', this.comData.startTime)
					this.$set(this.tjFrom, 'endTime', this.comData.endTime)
					break
				case '2':
					this.timeType = '月'
					this.pickTypes = 'month'
					// 本年的一月到当前月
					this.$set(this.tjFrom, 'startTime', getFormatDate2(GetPreMonthDay(todayTime(), 5)))
					this.$set(this.tjFrom, 'endTime', this.getNowYearMonth())
					break
				case '3':
					this.timeType = '年'
					this.pickTypes = 'year'
					// 当前年往前推10年
					let yearTimes = moment().subtract(10, 'y').format('YYYY')
					this.$set(this.tjFrom, 'startTime', yearTimes)
					this.$set(this.tjFrom, 'endTime', this.getNowYear())
					break

				default:
					break
			}
		},
		// 导出
		exportDownLoad() {
			let subDeviceIds = this.tjFrom.subDeviceId.split('-')
			let sensorData = []
			sensorData.push({
				deviceCode: subDeviceIds[0],
				subDeviceId: subDeviceIds[1],
				dataItem: 'JYL',
				name: '',
			})
			let params = {
				exportParam: {
					startTime: this.tjFrom.startTime,
					endTime: this.tjFrom.endTime,
					subDeviceId: [],
					dataItems: [],
					sensorData: sensorData,
				},
			}
			this.$http.postAction(this.$api.saveExport, params).then((res) => {
				if (res.code == 200) {
					this.$message(res.msg, 'success')
				} else {
					this.$message(res.msg, 'error')
				}
			})
		},
		// 折线图绘制
		makeChart(xData, yData, yUnit) {
			// xData = [1, 2, 3]
			// yData = [1, 2, 30000]
			// // yName = "温度";
			// yUnit = 'Lux'
			const _this = this
			let myChart = this.$echarts.init(this.$refs.chartRef)
			let option = {
				tooltip: {
					show: true,
					trigger: 'axis',
					backgroundColor: '#fff',
					padding: [11, 17],
					extraCssText:
						'box-shadow: 0px 0px 12px 0px rgba(76, 216, 179, 0.33);text-align:left;font-size:14px;color:#3F3F3F;letter-spacing: 1px;',
					formatter: function (param) {
						let infos = ''
						infos += `<div style="text-align:left;">气象站一号</div><div style="margin:12px 0">${param[0].name}</div><div style="color:#2DAEA9">每日降雨量累计：${param[0].value}Lux</div>`
						return infos
					},
					// formatter: "气象站一号</br>{b}</br>每日降雨量累计：{c}mm",
				},
				color: '#2DAEA9',
				grid: {
					left: '5%',
					bottom: '8%',
					top: '15%',
					right: '3%',
				},
				xAxis: {
					type: 'category',
					data: xData,
					// 刻度线
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#EDEDED',
							width: 2,
						},
					},
					axisLabel: {
						color: '#6B6B6B',
						fontSize: 14,
						lineHeight: 30,
					},
					boundaryGap: false,
				},
				yAxis: {
					type: 'value',
					name: '（' + yUnit + '）',
					nameTextStyle: {
						color: '#6B6B6B',
						fontSize: 14,
						align: 'right',
					},
					axisLabel: {
						color: '#6B6B6B',
						fontSize: 14,
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: '#EDEDED',
							width: 2,
						},
					},
				},
				series: [
					{
						type: 'line',
						smooth: true,
						showSymbol: false,
						symbolSize: 14,
						itemStyle: {
							borderColor: '#2DAEA9',
							borderWidth: 4,
						},
						data: yData,
					},
				],
			}
			myChart.clear()
			myChart.setOption(option)
			window.addEventListener('resize', function () {
				myChart.resize()
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.rain {
	height: 100%;
	min-height: 610px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.top-box {
		height: 8%;
		background: #ffffff;
		border-radius: 0.125rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding-right: 0.275rem;
		.dc-btn {
			cursor: pointer;
			width: 1.05rem;
			height: 0.45rem;
			background: #42b5b2;
			border-radius: 0.1rem;
			text-align: center;
			line-height: 0.45rem;
			color: #ffffff;
			font-size: 0.175rem;
		}
	}
	.bottom {
		position: relative;
		height: 89.4%;
		background: #ffffff;
		border-radius: 0.125rem;

		.bottom-title {
			position: absolute;
			font-size: 0.2rem;
			color: #333333;
			right: 50%;
			transform: translateX(50%);
			top: 0.46rem;
			z-index: 20;
		}
		// 导出按钮
		.btn-export {
			width: 1.05rem;
			height: 0.45rem;
			color: #42b5b2;
			border: 1px solid #2daea9;
			border-radius: 4px;
			font-size: 0.17rem;
			position: absolute;
			top: 0.31rem;
			right: 0.39rem;
			cursor: pointer;
			z-index: 20;
			img {
				width: 0.25rem;
				margin-right: 0.09rem;
			}
		}
		.echart {
			position: relative;
			width: 100%;
			height: 100%;
		}
	}
}
</style>
<style lang="scss" scoped>
/deep/ .recordDrawer {
	border-radius: 0.225rem 0px 0px 0.225rem;

	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		span {
			font-size: 0.225rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		position: relative;
		.Information {
			position: absolute;
			z-index: 999999;
			top: 40%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%) scale(0);
			width: 3.45rem;
			height: 2.075rem;
			background: #ffffff;
			box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
			border-radius: 10px;
			transition: all 0.3s;
			.top {
				box-sizing: border-box;
				height: 1.375rem;
				text-align: center;
				line-height: 1.375rem;
				border-bottom: 1px solid #f1f1f1;
			}
			.bottom {
				display: flex;
				height: 0.6875rem;
				justify-content: space-around;
				align-items: center;
				& > div {
					cursor: pointer;
				}
				.line {
					width: 1px;
					height: 0.45rem;
					background: #f1f1f1;
				}
				.green {
					color: rgba(45, 174, 169, 1);
				}
			}
		}
		.Information-show {
			transform: translateX(-50%) translateY(-50%) scale(1);
		}
	}
}
/deep/ .detailsDrawer {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		span {
			font-size: 0.225rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		.detailstop-box {
			display: flex;
			margin-bottom: 0.3125rem;
			align-items: center;
			.back {
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 1.125rem;
				height: 0.425rem;
				margin-right: 0.375rem;
				background: #ffffff;
				border: 0.0125rem solid #2daea9;
				border-radius: 0.05rem;
				font-size: 0.175rem;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #42b5b2;
				img {
					margin-right: 0.15rem;
				}
			}
			.txt {
				margin-right: 0.75rem;

				color: #333333;
				span {
					color: #666666;
				}
			}
		}
	}
}
/deep/ .el-table__row > td {
	/* 去除表格线 */

	border: none;
}
/* 用来设置当前页面element全局table 鼠标移入某行时的背景色*/
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
	background-color: #f3f7f8;
	/* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
/deep/ .caozuo-cell {
	.cell {
		padding: 0 0.375rem;
		display: flex;
		justify-content: space-around;
	}
}
</style>
