<template>
  <el-drawer
    title="导出记录"
    custom-class="export-record"
    :visible.sync="visible"
    direction="rtl"
    size="41.845%"
  >
    <!-- 表格数据 -->
    <el-table
      :data="tableData"
      border
      align="center"
      :header-cell-style="{
        background: '#F3F7F8',
        color: '#333333',
        fontFamily: 'Microsoft YaHei',
        border: 'none',
      }"
      cell-class-name="table-cell"
    >
      <el-table-column
        prop="fileName"
        label="导出文件名称"
        align="center"
        width="230"
      ></el-table-column>
      <el-table-column
        prop="modifyTime"
        label="导出时间"
        align="center"
      ></el-table-column>
      <el-table-column label="导出状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.exportStatus == 0">已导出</span>
          <div v-else-if="scope.row.exportStatus == 2">
            导出失败
            <span class="export-again" @click="exportAgain(scope.row)"
              >(重新导出)</span
            >
          </div>
          <span v-else-if="scope.row.exportStatus == 1">正在导出</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span
            v-if="scope.row.exportStatus == 0"
            class="span-btn span-down"
            @click="handleDown(scope.row)"
            >下载</span
          >
          <span class="span-btn span-detail" @click="handleDetail(scope.row)"
            >详情</span
          >
          <span class="span-btn span-del" @click="handleDel(scope.row)"
            >删除</span
          >
        </template>
      </el-table-column>
      <!-- 占位数据 -->
      <div slot="empty" class="table-empty" style="padding: 1.5rem 0 1.5rem">
        <img class="table-noimg" src="@/assets/image/home/ZWT.png" alt="" />
        <div class="table-emptyword">暂无数据</div>
      </div>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      class="pagination"
      layout="prev, pager, next"
      :current-page.sync="ipagination.currentPage"
      :page-size="ipagination.pageSize"
      :total="ipagination.total"
      :hide-on-single-page="true"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!-- 确定删除信息警告 -->
    <div class="Information" :class="{ 'Information-show': Information }">
      <div class="top">确定删除吗？</div>
      <div class="bottom">
        <div @click="Information = false">取消</div>
        <div class="line"></div>
        <div class="green" @click="scheduleDel">确定</div>
      </div>
    </div>
    <Msg ref="tsmsg"></Msg>
    <!-- 里面的详情数据抽屉 -->
    <el-drawer
      :title="exportName"
      custom-class="export-record"
      :append-to-body="true"
      :visible.sync="detailsDrawer"
      size="41.845%"
    >
      <!-- 上面的按钮和状态显示 -->
      <div class="flex flex_a_c inner-top">
        <div class="t_c cursor inner-back" @click="handleCloseInner">
          <i class="el-icon-arrow-left"></i>
          返回
        </div>
        <div class="inner-word inner-status">
          导出状态：
          <span v-if="exportStatus == 0">已导出</span>
          <div v-else-if="exportStatus == 2">导出失败</div>
          <span v-else-if="exportStatus == 1">正在导出</span>
        </div>
        <div class="inner-word inner-times">
          导出时间：<span>{{ exportTime }}</span>
        </div>
      </div>
      <!-- 下面的导出数据 -->
      <div class="inner-tables">
        <el-row>
          <el-col :span="6">设备区域</el-col>
          <el-col :span="6">设备名称</el-col>
          <el-col :span="6">传感器名称</el-col>
          <el-col :span="6">所选参数</el-col>
        </el-row>
        <!-- 内容 -->
        <div class="innertable-body">
          <div
            v-for="(item, index) in innerTable"
            :key="index"
            class="table-table"
          >
            <div class="w-25 table-cell border-top">
              {{ item.name }}
            </div>
            <div class="w-25 table-cell border-top border-left">
              {{ item.equipName }}
            </div>
            <div class="w-50 table-cell">
              <div
                v-for="(item2, index2) in item.cgqArr"
                :key="index2"
                class="table-table"
              >
                <div class="w-50 table-cell border-top border-left">
                  {{ item2.name }}
                </div>
                <div class="w-50 table-cell">
                  <div
                    v-for="(item3, index3) in item2.items"
                    :key="index3"
                    style="width: 100%"
                  >
                    <div class="end-col">{{ item3 }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </el-drawer>
</template>

<script>
/*
 * @description: 环境数据分析-导出记录统计页面
 */
import exportJs from "../assets/export.js";
import Msg from "@/components/msg.vue"; //信息提示组件
export default {
  mixins: [exportJs],
  components: { Msg },
  data() {
    return {
      // 控制导出记录
      visible: false,
      // 记录表格数据
      tableData: [],
      /* 分页参数 */
      // 0已导出，1正在导出，2导出失败
      ipagination: {
        pageSize: 10,
        total: 0,
        currentPage: 1,
      },
      detailsDrawer: false, // 内层抽屉是否打开
      // 内层抽屉详情表格数据
      innerTable: [
        {
          name: "全区域-大棚一号",
          equipName: "控制箱三号",
          cgqArr: [
            {
              name: "六合一传感器1",
              items: ["空气温度1", "土壤湿度1"],
            },
            {
              name: "六合一传感器2",
              items: ["空气温度2", "土壤湿度2"],
            },
            {
              name: "六合一传感器3",
              items: ["空气温度3"],
            },
          ],
        },
        {
          name: "全区域-大棚一号",
          equipName: "控制箱六号",
          cgqArr: [
            {
              name: "六合一传感器4",
              items: ["空气温度4", "土壤湿度4"],
            },
          ],
        },
      ],
      // 删除确认信息
      Information: false,
    };
  },
  methods: {
    // 打开
    open() {
      this.visible = true;
      this.ipagination.currentPage = 1;
      // 获取导出记录数据
      this.getExportRecord();
    },

    // 弹窗关闭
    onClose() {
      this.chooseId = "";
      this.visible = false;
    },

    // 内抽屉点击返回
    handleCloseInner() {
      this.detailsDrawer = false;
    },
    // 重新导出
    againExport(obj) {
      console.log(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .export-record {
  border-radius: 0.225rem 0px 0px 0.225rem;
  .el-drawer__header {
    height: 0.825rem;
    box-sizing: border-box;
    text-align: left;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #454545;
    padding-top: 0;
    border-bottom: 1px solid #f1f1f1;
    span {
      font-size: 0.225rem;
    }
  }

  .el-drawer__close-btn {
    color: #454545;
  }
  .el-drawer__body {
    padding: 0 0.3rem 0.25rem;
  }
}
/deep/ .el-table__row > td {
  /* 去除表格线 */

  border: none;
}
/* 用来设置当前页面element全局table 鼠标移入某行时的背景色*/
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f3f7f8;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}

.export-record {
  font-size: 0.17rem;
  .export-again {
    color: #42b5b2;
    cursor: pointer;
  }
  .span-btn {
    cursor: pointer;
    &:not(:first-child) {
      margin-left: 0.21rem;
    }
  }
  .span-down {
    color: #3392e6;
  }
  .span-detail {
    color: #42b5b2;
  }
  .span-del {
    color: #ff7171;
  }

  // 里面的抽屉样式
  .inner-top {
    .inner-back {
      width: 1.13rem;
      height: 0.42rem;
      line-height: 0.42rem;
      background: #ffffff;
      border: 1px solid #2daea9;
      border-radius: 0.05rem;
      color: #42b5b2;
    }
    .inner-word {
      font-size: 0.2rem;
      color: #333333;
      span {
        color: #666666;
      }
    }
    .inner-status {
      margin-left: 0.39rem;
    }
    .inner-times {
      margin-left: 0.76rem;
    }
  }
}
</style>
<style lang="scss">
.export-record {
  .inner-tables {
    width: 100%;
    margin-top: 0.31rem;
    border: 1px solid #e2e2e2;
    .el-row {
      width: 100%;
      .el-col {
        height: 0.68rem;
        line-height: 0.68rem;
        text-align: center;
        background-color: #f3f7f8;
      }
    }
    .innertable-body {
      .table-table {
        display: table;
        width: 100%;
      }
      .table-cell {
        display: table-cell;
        vertical-align: middle;
      }
      div {
        text-align: center;
        background-color: #fff;
        // border-top: 1px solid #e2e2e2;
      }
      .w-25 {
        width: 25%;
      }
      .w-50 {
        width: 50%;
      }
      .end-col {
        text-align: center;
        height: 0.68rem;
        line-height: 0.68rem;
        border-top: 1px solid #e2e2e2;
        border-left: 1px solid #e2e2e2;
      }
      .border-top {
        border-top: 1px solid #e2e2e2;
      }
      .border-left {
        border-left: 1px solid #e2e2e2;
      }
      .border-noleft {
        border-left: none;
      }
      .col-h {
        height: 100%;
      }
    }
  }
  .Information {
    position: absolute;
    z-index: 999999;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0);
    width: 3.45rem;
    height: 2.075rem;
    background: #ffffff;
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    transition: all 0.3s;
    .top {
      box-sizing: border-box;
      height: 1.375rem;
      text-align: center;
      line-height: 1.375rem;
      border-bottom: 1px solid #f1f1f1;
    }
    .bottom {
      display: flex;
      height: 0.6875rem;
      justify-content: space-around;
      align-items: center;
      & > div {
        cursor: pointer;
      }
      .line {
        width: 1px;
        height: 0.45rem;
        background: #f1f1f1;
      }
      .green {
        color: rgba(45, 174, 169, 1);
      }
    }
  }
  .Information-show {
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
}
</style>
