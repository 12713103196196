<template>
  <div class="logins">
    <div class="wrap" @click="hide($event)">
      <!-- 上面的头部 -->
      <div class="header clearfix">
        <!-- <div class="logo fl">
          <img src="../assets/image/login/login-logo-top.png" alt="" />
        </div> -->
        <p class="title fl">农业物联网通用平台</p>

        <span
          class="control fr pointer noselect"
          @click.stop="showDownLoad = !showDownLoad"
        >
          控件下载
          <i :class="showDownLoad ? 'rotate180' : ''"></i>
        </span>
        <section
          class="download flex flex_col flex_ju_c animate__animated"
          v-show="showDownLoad"
          :class="showDownLoad ? 'animate__fadeIn' : 'animate__fadeOut'"
        >
          <span class="pointer">
            <a
              style="font-size: 0.2rem"
              href="http://nywlw.hnyfkj.com/oss/vlc-3.0.14-win32.exe"
              >vlc控件</a
            >
          </span>
          <!-- <span class="pointer">数据控件</span> -->
        </section>
      </div>
      <!-- 中间区域 -->
      <div class="main flex flex_a_end flex_ju_c">
        <div class="banner">
          <el-carousel trigger="click" :autoplay="true" arrow="never">
            <el-carousel-item v-for="item in bannerArr" :key="item.src">
              <img :src="item.src" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="form-div">
          <!-- 登录框 -->
          <div class="user-login" v-show="formType == 0">
            <div class="tabs flex">
              <div
                class="flex flex_a_c flex_ju_c"
                v-for="(tab, i) in tabsArr"
                :key="i"
              >
                <!-- <el-image :src="i === index ? tab.activeImg : tab.deactiveImg" lazy></el-image> -->
                <img :src="i === index ? tab.activeImg : tab.deactiveImg" />
                <span
                  class="pointer"
                  :class="i === index ? 'active' : ''"
                  @click="index = i"
                >
                  {{ tab.text }}
                </span>
              </div>
            </div>

            <div class="spilt"></div>

            <div class="content">
              <!-- 表单 -->
              <div v-if="index === 0" class="account">
                <h3>
                  <span v-show="loginType == 0">密码登录</span
                  ><span v-show="loginType == 1">验证码登录</span>
                </h3>
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  class="demo-ruleForm"
                >
                  <el-form-item prop="loginName">
                    <el-input
                      type="text"
                      v-model="ruleForm.loginName"
                      autocomplete="off"
                      placeholder="请输入手机号"
                      @focus="loginNameFous"
                      @blur="loginNameBlur"
                    >
                      <i slot="prefix" class="slot-phone">
                        <img
                          v-show="!inputFous.loginName"
                          src="../assets/image/login/icon-phone.png"
                          alt=""
                        />
                        <img
                          v-show="inputFous.loginName"
                          src="../assets/image/login/icon-phone-lv.png"
                          alt=""
                        />
                      </i>
                    </el-input>
                  </el-form-item>
                  <!-- 账号密码登录 -->
                  <el-form-item prop="password" v-if="loginType == 0">
                    <el-input
                      :type="pwdInputType ? 'password' : 'text'"
                      v-model="ruleForm.password"
                      autocomplete="off"
                      placeholder="请输入密码"
                      @focus="passwordFous"
                      @blur="passwordBlur"
                    >
                      <i slot="prefix" class="slot-phone">
                        <img
                          v-show="!inputFous.password"
                          src="../assets/image/login/icon-pwd.png"
                          alt=""
                        />
                        <img
                          v-show="inputFous.password"
                          src="../assets/image/login/icon-pwd-lv.png"
                          alt=""
                        />
                      </i>
                      <i
                        slot="suffix"
                        :class="this.pwdInputType ? 'eyen-icon' : 'eye-icon'"
                        @mousedown="zzsj"
                        @click="pwdEyeClick"
                      ></i>
                    </el-input>
                  </el-form-item>
                  <!-- 验证码登录 -->
                  <el-form-item prop="code" v-if="loginType == 1">
                    <el-input
                      class="codeInput"
                      type="text"
                      v-model="ruleForm.code"
                      autocomplete="off"
                      placeholder="请输入验证码"
                      @focus="codeFous"
                      @blur="codeBlur"
                      :maxlength="6"
                    >
                      <i slot="prefix" class="slot-phone">
                        <img
                          v-show="!inputFous.code"
                          src="../assets/image/login/icon-yanz.png"
                          alt=""
                        />
                        <img
                          v-show="inputFous.code"
                          src="../assets/image/login/icon-yanz-lv.png"
                          alt=""
                        />
                      </i>
                    </el-input>
                    <div
                      class="code-btn"
                      :class="{
                        'code-btn-gray':
                          codeShowText == false || ruleForm.loginName == '',
                      }"
                      @click="codebtnClick('login', 3)"
                    >
                      <span v-show="codeShowText">获取验证码</span>
                      <span v-show="!codeShowText">{{ codeTime }}s</span>
                    </div>
                  </el-form-item>
                  <el-form-item
                    prop="isHk"
                    v-if="loginType == 1 || !isBase64Code"
                  >
                    <drag-verify
                      ref="dragVerify"
                      :width="350"
                      :height="48"
                      progressBarBg="#E9E9E9"
                      completedBg="#069f99"
                      :isPassing.sync="ruleForm.isPassing"
                      text="请按住滑块，拖动到最右边"
                      successText="验证通过"
                      textSize="16px"
                      textColor="#C9C9C9"
                      handlerIcon="el-icon-d-arrow-right"
                      successIcon="el-icon-circle-check"
                      @passcallback="passcallback"
                    >
                      <!-- <i
												v-show="!ruleForm.isPassing"
												slot="textBefore"
												class="el-icon-lock"
											></i> -->
                    </drag-verify>
                  </el-form-item>
                  <!-- 错误次数过多时输入图形验证码 -->
                  <el-form-item
                    prop="code"
                    v-if="loginType == 0 && isBase64Code"
                  >
                    <el-input
                      class="codeInput"
                      type="text"
                      v-model="ruleForm.code"
                      autocomplete="off"
                      placeholder="请输入验证码"
                      @focus="codeFous"
                      @blur="codeBlur"
                      :maxlength="6"
                    >
                      <i slot="prefix" class="slot-phone">
                        <img
                          v-show="!inputFous.code"
                          src="../assets/image/login/icon-yanz.png"
                          alt=""
                        />
                        <img
                          v-show="inputFous.code"
                          src="../assets/image/login/icon-yanz-lv.png"
                          alt=""
                        />
                      </i>
                    </el-input>
                    <div class="code-pic" @click="codepicClick()">
                      <img :src="base64Captcha" alt="" />
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div class="flex-box">
                      <span class="green" @click="goforgetPwd">忘记密码？</span>
                      <span class="green" @click="loginTypeChange"
                        ><span v-show="loginType == 1">密码登录</span
                        ><span v-show="loginType == 0">验证码登录</span></span
                      >
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      class="login-btn"
                      v-loading="isLoading"
                      type="primary"
                      :class="{ active: isCanTap }"
                      @click="onSubmit('ruleForm')"
                      >登录</el-button
                    >
                  </el-form-item>
                  <el-form-item>
                    <div class="flex-box2">
                      还没有账号？<span class="green" @click="goregistered"
                        >去注册</span
                      >
                    </div>
                  </el-form-item>
                </el-form>
              </div>
              <!-- app下载 -->
              <div v-if="index === 1" class="APP">
                <h3>扫描二维码下载APP</h3>
                <div class="qr-code flex">
                  <img src="@/assets/image/login/ynCode.png" alt="" />
                </div>
                <div class="description">元丰云农APP二维码</div>
              </div>
            </div>
          </div>
          <!-- 注册框 -->
          <div class="user-login" v-show="formType == 1">
            <div class="back-box" @click="formType = 0">
              <img src="../assets/image/login/back.png" alt="" />返回
            </div>
            <div class="content" :style="{ marginTop: 100 + 'px' }">
              <!-- 表单 -->
              <div class="account">
                <h3>
                  <span>账号注册</span>
                </h3>
                <el-form
                  :model="zcruleForm"
                  :rules="rules"
                  ref="zcruleForm"
                  class="demo-ruleForm"
                >
                  <el-form-item prop="loginName">
                    <el-input
                      type="text"
                      v-model="zcruleForm.loginName"
                      autocomplete="off"
                      placeholder="请输入手机号"
                      @focus="loginNameFous"
                      @blur="loginNameBlur"
                    >
                      <i slot="prefix" class="slot-phone">
                        <img
                          v-show="!inputFous.loginName"
                          src="../assets/image/login/icon-phone.png"
                          alt=""
                        />
                        <img
                          v-show="inputFous.loginName"
                          src="../assets/image/login/icon-phone-lv.png"
                          alt=""
                        />
                      </i>
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="code">
                    <el-input
                      class="codeInput"
                      type="text"
                      v-model="zcruleForm.code"
                      autocomplete="off"
                      placeholder="请输入验证码"
                      @focus="codeFous"
                      @blur="codeBlur"
                      :maxlength="6"
                    >
                      <i slot="prefix" class="slot-phone">
                        <img
                          v-show="!inputFous.code"
                          src="../assets/image/login/icon-yanz.png"
                          alt=""
                        />
                        <img
                          v-show="inputFous.code"
                          src="../assets/image/login/icon-yanz-lv.png"
                          alt=""
                        />
                      </i>
                    </el-input>
                    <div
                      class="code-btn"
                      :class="{
                        'code-btn-gray':
                          codeShowText == false || zcruleForm.loginName == '',
                      }"
                      @click="codebtnClick('regiest', 1)"
                    >
                      <span v-show="codeShowText">获取验证码</span>
                      <span v-show="!codeShowText"
                        >{{ codeTime }}s后重新获取</span
                      >
                    </div>
                  </el-form-item>
                  <el-form-item prop="password2">
                    <el-input
                      :type="pwdInputType ? 'password' : 'text'"
                      v-model="zcruleForm.password2"
                      autocomplete="off"
                      placeholder="密码长度为6-8位包含字母与数字组合"
                      @focus="passwordFous"
                      @blur="passwordBlur"
                    >
                      <i slot="prefix" class="slot-phone">
                        <img
                          v-show="!inputFous.password"
                          src="../assets/image/login/icon-pwd.png"
                          alt=""
                        />
                        <img
                          v-show="inputFous.password"
                          src="../assets/image/login/icon-pwd-lv.png"
                          alt=""
                        />
                      </i>
                      <i
                        slot="suffix"
                        :class="this.pwdInputType ? 'eyen-icon' : 'eye-icon'"
                        @mousedown="zzsj"
                        @click="pwdEyeClick"
                      ></i>
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="industry">
                    <el-select
                      v-model="zcruleForm.industry"
                      placeholder="请选择所属行业"
                      :popper-append-to-body="false"
                      @focus="industryFous"
                      @blur="industryBlur"
                    >
                      <i slot="prefix" class="slot-phone">
                        <img
                          v-show="!inputFous.industry"
                          src="../assets/image/login/icon-hangy.png"
                          alt=""
                        />
                        <img
                          v-show="inputFous.industry"
                          src="../assets/image/login/icon-hangy-lv.png"
                          alt=""
                        />
                      </i>
                      <el-option
                        v-for="item in industryList"
                        :key="item.id"
                        :label="item.industryName"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      class="login-btn"
                      v-loading="isLoading"
                      type="primary"
                      :class="{ active: isZcCanTap }"
                      @click="registeredClick"
                      >注册</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <!-- 忘记框 -->
          <div class="user-login" v-show="formType == 2">
            <div class="back-box" @click="formType = 0">
              <img src="../assets/image/login/back.png" alt="" />返回
            </div>
            <div class="content" :style="{ marginTop: 100 + 'px' }">
              <!-- 表单 -->
              <div class="account">
                <h3>
                  <span>忘记密码</span>
                </h3>
                <el-form
                  :model="wjruleForm"
                  :rules="rules"
                  ref="wjruleForm"
                  class="demo-ruleForm"
                >
                  <el-form-item prop="loginName">
                    <el-input
                      type="text"
                      v-model="wjruleForm.loginName"
                      autocomplete="off"
                      placeholder="请输入手机号"
                      @focus="loginNameFous"
                      @blur="loginNameBlur"
                    >
                      <i slot="prefix" class="slot-phone">
                        <img
                          v-show="!inputFous.loginName"
                          src="../assets/image/login/icon-phone.png"
                          alt=""
                        />
                        <img
                          v-show="inputFous.loginName"
                          src="../assets/image/login/icon-phone-lv.png"
                          alt=""
                        />
                      </i>
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="code">
                    <el-input
                      class="codeInput"
                      type="text"
                      v-model="wjruleForm.code"
                      autocomplete="off"
                      placeholder="请输入验证码"
                      @focus="codeFous"
                      @blur="codeBlur"
                      :maxlength="6"
                    >
                      <i slot="prefix" class="slot-phone">
                        <img
                          v-show="!inputFous.code"
                          src="../assets/image/login/icon-yanz.png"
                          alt=""
                        />
                        <img
                          v-show="inputFous.code"
                          src="../assets/image/login/icon-yanz-lv.png"
                          alt=""
                        />
                      </i>
                    </el-input>
                    <div
                      class="code-btn"
                      :class="{
                        'code-btn-gray':
                          codeShowText == false || wjruleForm.loginName == '',
                      }"
                      @click="codebtnClick('forget', 2)"
                    >
                      <span v-show="codeShowText">获取验证码</span>
                      <span v-show="!codeShowText"
                        >{{ codeTime }}s后重新获取</span
                      >
                    </div>
                  </el-form-item>
                  <el-form-item prop="password2">
                    <el-input
                      :type="pwdInputType ? 'password' : 'text'"
                      v-model="wjruleForm.password2"
                      autocomplete="off"
                      placeholder="密码长度为6-8位包含字母与数字组合"
                      @focus="passwordFous"
                      @blur="passwordBlur"
                    >
                      <i slot="prefix" class="slot-phone">
                        <img
                          v-show="!inputFous.password"
                          src="../assets/image/login/icon-pwd.png"
                          alt=""
                        />
                        <img
                          v-show="inputFous.password"
                          src="../assets/image/login/icon-pwd-lv.png"
                          alt=""
                        />
                      </i>
                      <i
                        slot="suffix"
                        :class="this.pwdInputType ? 'eyen-icon' : 'eye-icon'"
                        @mousedown="zzsj"
                        @click="pwdEyeClick"
                      ></i>
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      class="login-btn"
                      v-loading="isLoading"
                      type="primary"
                      :class="{ active: isWjCanTap }"
                      @click="saveClick"
                      >保存</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <Msg ref="zcmsg"></Msg>
        </div>
      </div>
      <!-- 底部图标和名称 -->
      <div class="footer">
        <ul class="flex flex_ju_sb">
          <li
            class="categories flex flex_col flex_a_c"
            v-for="cate of footer_categories"
            :key="cate.title"
          >
            <div class="flex flex_a_c flex_ju_c img-div">
              <img :src="cate.imgSrc" alt="" />
            </div>

            <span>{{ cate.title }}</span>
          </li>
        </ul>
        <div class="footnote">
          <span style="opacity: 0"
            >© &nbsp;2010-2020河南元丰科技网络股份有限公司版权所有</span
          >
          <!-- <img src="@/assets/image/login/logo-bottpm.png" alt="" /> -->
          <div class="a_link">
            <a target="_blank" href="https://beian.miit.gov.cn/"
              >ICP备案/许可证号：豫B2-20150097-1
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CanvalCode from "@/components/CanvalCode.vue";
import Msg from "@/components/msg.vue"; //信息提示组件
/**
 * @description 登陆页面
 */
export default {
  name: "Login",
  data() {
    // 手机号
    var validatePhone = (rule, value, callback) => {
      const phoneReg = /^1[3456789]\d{9}$/;
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!phoneReg.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    // 密码
    var validatePass = (rule, value, callback) => {
      const pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,8}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!pwdReg.test(value)) {
        callback(new Error("密码长度为6-8位包含字母与数字组合"));
      } else {
        callback();
      }
    };

    return {
      showDownLoad: false,

      // tab切换
      tabsArr: [
        {
          text: "用户登录",
          activeImg: require("@/assets/image/login/avatar.png"),
          deactiveImg: require("@/assets/image/login/deactive-avatar.png"),
        },
        {
          text: "APP下载",
          activeImg: require("@/assets/image/login/download.png"),
          deactiveImg: require("@/assets/image/login/deactive-download.png"),
        },
      ],
      // 轮播数据
      bannerArr: [
        { src: require("@/assets/image/login/banner1.png") },
        { src: require("@/assets/image/login/banner2.png") },
        { src: require("@/assets/image/login/banner3.png") },
      ],
      index: 0,
      // 表单数据
      ruleForm: {
        loginName: "",
        password: "",
        code: "",
        remember: "",
        isPassing: false, //滑块
        isHk: "",
      },
      // 表单校验
      rules: {
        loginName: [
          { required: true, trigger: "blur", message: "请输入手机号" },
          { validator: validatePhone, trigger: "blur" },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入密码" },
          // { validator: validatePass, trigger: "blur" },
        ],
        password2: [
          { required: true, trigger: "blur", message: "请输入密码" },
          { validator: validatePass, trigger: "blur" },
        ],
        code: [{ required: true, trigger: "blur", message: "请输入验证码" }],
        industry: [
          { required: true, trigger: "change", message: "请选择所属行业" },
        ],
        // code: [{ trigger: "blur", validator: checkAge }],

        isHk: [
          {
            required: true,
            trigger: "blur",
            message: "请按住滑块，拖动到最右边",
          },
        ],
      },
      // 注册表单数据
      zcruleForm: {
        loginName: "",
        password2: "",
        code: "",
        industry: "",
      },
      // 所属行业列表
      industryList: [],
      isLoading: false,
      isCanTap: false, // 登录按钮是否可点击
      isZcCanTap: false, // 注册按钮是否可点击
      isWjCanTap: false, // 忘记密码按钮是否可点击
      wrongNumber: 0, //輸入次數
      // 下面的类别
      footer_categories: [
        { title: "温室大棚", imgSrc: require("@/assets/image/login/peng.png") },
        { title: "畜牧养殖", imgSrc: require("@/assets/image/login/niu.png") },
        { title: "水产渔业", imgSrc: require("@/assets/image/login/shui.png") },
        { title: "大田种植", imgSrc: require("@/assets/image/login/tian.png") },
      ],
      checkCode: "", // 自己绘制出来的验证码
      // 输入框获取焦点
      inputFous: {
        loginName: false,
        password: false,
        code: false,
        industry: false,
      },
      // 忘记密码表单数据
      wjruleForm: {
        loginName: "",
        password2: "",
        code: "",
      },
      // 获取验证码定时器
      codeTimer: null,
      codeTime: 60,
      codeShowText: true,
      // 登录类型
      loginType: 0, //0密码登录 1 验证码登录
      // 当前状态
      formType: 0, //0登录 1注册 2忘记密码
      //   密码框类型
      pwdInputType: true, //true密码显示false文本显示

      // 是否需要图形验证码
      isBase64Code: false,
      base64Captcha: null, //图形验证码地址
      captchaId: null,
    };
  },
  components: { CanvalCode, Msg },
  created() {
    var r = window.location;
    console.log(r);
    this.keyupSubmit();
  },
  computed: {
    isYanZheng() {
      if (this.wrongNumber >= 3) {
        this.isCanTap = false;
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    // 如果是记住账号密码，则页面进入的时候就获取缓存，进行赋值
    let isRemember = window.localStorage.getItem("wlwisRemember");

    if (isRemember) {
      let names = window.localStorage.getItem("wlwname");
      let pass = window.localStorage.getItem("wlwpass");
      this.$set(this.ruleForm, "loginName", names);
      this.$set(this.ruleForm, "password", pass);
    }
  },
  methods: {
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        //!this.clickState是防止用户重复点击回车
        if (_key === 13 && this.isCanTap) {
          this.onSubmit("ruleForm");
        }
      };
    },
    // 页面点击隐藏控件下载的下拉框
    hide(e) {
      console.log(e.path);
      let res = true;
      if (e.path) {
        res = e.path.some((s) => {
          const name = s.className || "";
          return (
            (name && name.includes("control")) || name.includes("download")
          );
        });
      } else {
        res = false;
      }

      if (!res) {
        // 点击的不是控件下载, 隐藏掉
        if (this.showDownLoad) this.showDownLoad = false;
      }
    },
    // 点击登陆
    onSubmit(formName) {
      if (this.isLoading) return;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 加载中
          this.isLoading = true;
          this.makeLogin();
        } else {
          return false;
        }
      });
    },
    // 登录请求
    async makeLogin() {
      // 传参
      let params = {
        loginName: "",
        password: "",
      };
      // let params = {
      //   loginName: "znly",
      //   password: "Hnyf2022",
      // };

      // 请求接口
      let loginPath = "";
      // 判断是账号密码登录还是验证码登录
      // 密码登录
      if (this.loginType == 0) {
        params = {
          loginName: this.ruleForm.loginName,
          password: this.ruleForm.password,
        };
        if (this.isBase64Code) {
          params.captchaCode = this.ruleForm.code;
          params.captchaId = this.captchaId;
        }
        loginPath = this.$api.apiLogin;
      } else if (this.loginType == 1) {
        // 验证码登录
        params = {
          phone: this.ruleForm.loginName,
          smsCode: this.ruleForm.code,
        };
        loginPath = this.$api.codeLogin;
      }

      if (this.isYanZheng) {
        params.imageCode = this.ruleForm.code;
      }
      // params = {
      //   loginName: "znly",
      //   password: "Hnyf2022",
      // };
      // params = {
      //   loginName: "13460213112",
      //   password: "yfkj2020",
      // };
      await this.$http
        .postAction(loginPath, params)
        .then((res) => {
          if (res.code == 200) {
            let datas = res.data;
            // 存储token值，用作是都已登陆的判断
            window.localStorage.setItem("wlwtoken", datas.token);
            window.localStorage.setItem("wlwUsers", JSON.stringify(datas));
            if (datas.user) {
              window.localStorage.setItem(
                "wlwUser",
                JSON.stringify(datas.user)
              );
            }
            if (datas.userInfo) {
              window.localStorage.setItem(
                "wlwUserInfo",
                JSON.stringify(datas.userInfo)
              );
            }

            if (datas.org) {
              window.localStorage.setItem("wlwOrg", JSON.stringify(datas.org));
            }
            window.localStorage.setItem("wlwuserName", this.ruleForm.loginName);
            // 存储记住账号和密码
            if (this.ruleForm.remember) {
              window.localStorage.setItem("wlwisRemember", true);
              window.localStorage.setItem("wlwname", this.ruleForm.loginName);
              window.localStorage.setItem("wlwpass", this.ruleForm.password);
            } else {
              window.localStorage.removeItem("wlwisRemember");
              window.localStorage.removeItem("wlwname");
              window.localStorage.removeItem("wlwpass");
            }
            setTimeout(() => {
              this.isLoading = false;

              // this.$router.push('/home')
              this.$router.replace("/home");
              // window.location.href = '#/home'
              // this.$nextTick(()=> {
              // 	window.localStorage.clear()
              // })
              // window.localStorage.clear()
            }, 1000);

            // 事件发送，进行socket连接
            this.$bus.$emit("hadLogin");
          } else if (res.code == 1001) {
            if (res.data.defaultPwdErrNum && res.data.pwdErrNum) {
              if (res.data.pwdErrNum >= res.data.defaultPwdErrNum) {
                this.isBase64Code = true;
                this.getcaptcha();
                this.$refs.zcmsg.msgShow(res.data.pwdErrMsg, 1.5);
              } else {
                this.isBase64Code = false;
                this.$refs.zcmsg.msgShow(res.msg, 1.5);
              }
            }
            this.isLoading = false;
            // this.wrongNumber++;
          } else if (res.code == 1004) {
            this.isBase64Code = true;
            this.getcaptcha();
            this.isLoading = false;
            // this.wrongNumber++;
            this.$refs.zcmsg.msgShow(res.msg, 1.5);
          } else {
            this.isLoading = false;
            // this.wrongNumber++;
            this.$refs.zcmsg.msgShow(res.msg, 1.5);
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.$refs.zcmsg.msgShow("服务出错，请稍后重试", 1.5);
        });
    },
    // 验证码变化
    codeUpdata(val) {
      this.checkCode = val;
    },
    // 滑块验证
    passcallback() {
      this.ruleForm.isHk = true;
    },
    // 密码小眼睛点击
    pwdEyeClick() {
      this.pwdInputType = !this.pwdInputType;
    },
    // zzsj
    zzsj() {
      // 即可阻止点击按钮时触发input失去焦点事件
      event.preventDefault();
    },
    // 登录类型切换
    loginTypeChange() {
      if (this.loginType == 1) {
        this.loginType = 0;
      } else {
        this.loginType = 1;
      }
      this.$refs["ruleForm"].resetFields();
      this.$refs.dragVerify.reset();
      this.ruleForm.isPassing = false;
    },
    // 获取验证码按钮点击
    codebtnClick(type, moduleType) {
      if (!this.codeShowText) {
        return;
      }
      // 按钮不能点击
      this.codeShowText = false;
      // 倒计时
      this.codeTimer = setInterval(() => {
        if (this.codeTime <= 0) {
          this.codeShowText = true;
          this.codeTime = 60;
          clearInterval(this.codeTimer);
          return;
        }
        this.codeTime--;
      }, 1000);

      let phones = "";
      // 忘记密码
      if (type == "forget") {
        if (!this.wjruleForm.loginName || this.wjruleForm.loginName == "") {
          // this.$message('请先输入手机号', 'error')
          this.$refs.zcmsg.msgShow("请先输入手机号", 1.5);
          return;
        }
        phones = this.wjruleForm.loginName;
      } else if (type == "regiest") {
        // 注册
        if (!this.zcruleForm.loginName || this.zcruleForm.loginName == "") {
          // this.$message('请先输入手机号', 'error')
          this.$refs.zcmsg.msgShow("请先输入手机号", 1.5);
          return;
        }
        phones = this.zcruleForm.loginName;
      } else if (type == "login") {
        // 登录
        if (!this.ruleForm.loginName || this.ruleForm.loginName == "") {
          // this.$message('请先输入手机号', 'error')
          this.$refs.zcmsg.msgShow("请先输入手机号", 1.5);
          return;
        }
        phones = this.ruleForm.loginName;
      }
      // 功能代码，1注册，2忘记密码，3登录，4修改手机号_旧手机号，5修改手机号_新手机号
      let params = {
        phone: phones,
        moduleType: moduleType,
      };
      // 请求获取验证码接口
      this.$http.getAction(this.$api.getSmsCode, params).then((res) => {
        if (res.code == 200) {
        } else {
          // this.$message(res.msg, 'error')
          this.$refs.zcmsg.msgShow(res.msg, 1.5);
          this.codeShowText = true;
          this.codeTime = 60;
          clearInterval(this.codeTimer);
        }
      });
    },
    // 点击去注册
    goregistered() {
      this.pwdInputType = true;
      this.formType = 1;
      this.codeTime = 60;
      this.codeShowText = true;
      clearInterval(this.codeTimer); //清除注册时的定时器
      this.$refs["zcruleForm"].clearValidate("industry"); //获取焦点时如果有效验结果清除结果
      this.ruleForm = {
        loginName: "",
        password: "",
        code: "",
        remember: "",
        isPassing: false, //滑块
        isHk: "",
      };
      this.$refs.dragVerify.reset();
      this.ruleForm.isPassing = false;

      // 获取行业下拉选
      this.getIndustrySelect();
    },
    // 获取行业下拉选
    getIndustrySelect() {
      this.$http
        .getAction(this.$api.getIndustrySelect)
        .then((res) => {
          if (res.code == 200) {
            let datas = res.data || [];
            this.industryList = datas || [];
          } else {
            this.industryList = [];
          }
        })
        .catch(() => {
          this.industryList = [];
        });
    },
    // 点击注册
    registeredClick() {
      if (this.isLoading) return;
      this.$refs["zcruleForm"].validate((valid) => {
        console.log(valid);
        if (valid) {
          let params = {
            phone: this.zcruleForm.loginName,
            smsCode: this.zcruleForm.code,
            password: this.zcruleForm.password2,
            industryId: this.zcruleForm.industry,
          };

          // 请求接口
          this.$http.postAction(this.$api.toRegiest, params).then((res) => {
            if (res.code == 200) {
              this.$refs.zcmsg.msgShow("注册成功", 1.5);
              // this.$message(res.msg, 'success')
              this.$nextTick(() => {
                this.formType = 0;
                this.codeTime = 60;
                this.codeShowText = true;
                clearInterval(this.codeTimer); //清除注册时的定时器
                this.zcruleForm = {
                  loginName: "",
                  password: "",
                  code: "",
                  industry: "",
                };
              });
            } else {
              if (res.msg == "请求参数不正确：验证码必须是6位数字") {
                this.$refs.zcmsg.msgShow("验证码必须是6位数字", 1.5);
              } else {
                this.$refs.zcmsg.msgShow(res.msg, 1.5);
              }
            }
          });
          // this.$refs.zcmsg.msgShow('注册成功', 1.5)
        } else {
          return false;
        }
      });
    },
    // 去忘记密码
    goforgetPwd() {
      this.pwdInputType = true;
      this.formType = 2;
      this.codeTime = 60;
      this.codeShowText = true;
      clearInterval(this.codeTimer); //清除注册时的定时器
      this.ruleForm = {
        loginName: "",
        password: "",
        code: "",
        remember: "",
        isPassing: false, //滑块
        isHk: "",
      };
      this.$refs.dragVerify.reset();
      this.ruleForm.isPassing = false;
    },
    // 忘记密码-点击保存
    saveClick() {
      this.$refs["wjruleForm"].validate((valid) => {
        if (valid) {
          let params = {
            phone: this.wjruleForm.loginName,
            smsCode: this.wjruleForm.code,
            password: this.wjruleForm.password2,
          };

          // 请求接口
          this.$http.postAction(this.$api.forgetPass, params).then((res) => {
            if (res.code == 200) {
              // this.$message(res.msg, 'success')
              this.$refs.zcmsg.msgShow("修改密码成功", 1.5);
              this.$nextTick(() => {
                this.formType = 0;
                this.codeTime = 60;
                this.codeShowText = true;
                clearInterval(this.codeTimer); //清除忘记密码时的定时器
                this.wjruleForm = {
                  loginName: "",
                  password: "",
                  code: "",
                };
              });
            } else {
              // this.$message(res.msg, 'error')
              if (res.msg == "请求参数不正确：验证码必须是6位数字") {
                this.$refs.zcmsg.msgShow("验证码必须是6位数字", 1.5);
              } else {
                this.$refs.zcmsg.msgShow(res.msg, 1.5);
              }
            }
          });
        } else {
          return false;
        }
      });
    },
    // 输入框焦点事件
    loginNameFous() {
      this.inputFous.loginName = true;
      this.$refs["ruleForm"].clearValidate("loginName"); //获取焦点时如果有效验结果清除结果
      this.$refs["zcruleForm"].clearValidate("loginName"); //获取焦点时如果有效验结果清除结果
    },
    loginNameBlur() {
      this.inputFous.loginName = false;
    },
    passwordFous() {
      this.inputFous.password = true;
      this.$refs["ruleForm"].clearValidate("password"); //获取焦点时如果有效验结果清除结果
      this.$refs["zcruleForm"].clearValidate("password"); //获取焦点时如果有效验结果清除结果
      this.$refs["wjruleForm"].clearValidate("password"); //获取焦点时如果有效验结果清除结果
    },
    passwordBlur() {
      this.inputFous.password = false;
    },
    codeFous() {
      this.inputFous.code = true;
      this.$refs["ruleForm"].clearValidate("code"); //获取焦点时如果有效验结果清除结果
      this.$refs["zcruleForm"].clearValidate("code"); //获取焦点时如果有效验结果清除结果
    },
    codeBlur() {
      this.inputFous.code = false;
    },
    industryFous() {
      this.inputFous.industry = true;
      this.$refs["zcruleForm"].clearValidate("industry"); //获取焦点时如果有效验结果清除结果
    },
    industryBlur() {
      this.inputFous.industry = false;
    },

    // 图形验证码点击
    codepicClick() {
      this.getcaptcha();
    },
    // 获取图形验证码
    async getcaptcha() {
      const res = await this.$http.getAction(this.$api.getcaptcha);
      if (res.data) {
        this.base64Captcha = res.data.base64Captcha;
        this.captchaId = res.data.id;
      }
    },
  },
  watch: {
    // 登录按钮是否可点击
    ruleForm: {
      deep: true,
      handler(val) {
        if (this.loginType == 0) {
          if (this.isBase64Code) {
            if (
              val.loginName !== "" &&
              val.password !== "" &&
              val.code !== ""
            ) {
              this.isCanTap = true;
            } else {
              this.isCanTap = false;
            }
          } else {
            if (
              val.loginName !== "" &&
              val.password !== "" &&
              val.isHk !== ""
            ) {
              this.isCanTap = true;
            } else {
              this.isCanTap = false;
            }
          }
        } else {
          if (val.loginName !== "" && val.code !== "" && val.isHk !== "") {
            this.isCanTap = true;
          } else {
            this.isCanTap = false;
          }
        }
      },
    },
    // 注册按钮是否可点击
    zcruleForm: {
      deep: true,
      handler(val) {
        if (
          val.loginName !== "" &&
          val.password !== "" &&
          val.code !== "" &&
          val.industry !== ""
        ) {
          this.isZcCanTap = true;
        } else {
          this.isZcCanTap = false;
        }
      },
    },
    // 忘记密码按钮是否可点击
    wjruleForm: {
      deep: true,
      handler(val) {
        if (val.loginName !== "" && val.password !== "" && val.code !== "") {
          this.isWjCanTap = true;
        } else {
          this.isWjCanTap = false;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
$bacc: 100% 100%;
.logins {
  width: 100%;
  height: 100%;
  min-height: 100%;

  .wrap {
    width: 100%;
    min-height: 100%;
    background: url("../assets/image/login/login-bacc.png") no-repeat;
    background-size: $bacc;
  }

  .header {
    height: 0.97rem;
    padding: 0 0.3375rem 0 1.0625rem;
    background-color: #fff;

    .logo {
      height: 100%;
      display: flex;
      align-items: center;
      img {
        width: 2.125rem;
        height: 0.625rem;
      }
    }
    .title {
      padding-left: 69px;
      font-size: 0.4rem;
      line-height: 0.975rem;
      font-family: "DFPZongYiW7-GB";
      color: #2daea9;
    }
    .control {
      position: relative;
      font-size: 0.2rem;
      // font-family: 'Source Han Sans CN';
      line-height: 0.975rem;
      color: #262727;
      i {
        width: 0;
        height: 0;
        display: inline-block;
        margin-left: 0.225rem;
        border-top: 0.12rem solid #d7d7d7;
        border-left: 0.08rem solid transparent;
        border-right: 0.08rem solid transparent;
      }
    }
    .download {
      position: absolute;
      top: 0.9rem;
      right: 0;
      width: 1.8625rem;
      // height: 1.075rem;
      padding: 0.25rem 0;
      background: #ffffff;

      span {
        // height: 0.2rem;
        padding-left: 0.35rem;
        font-size: 0.2rem;
        // font-family: 'Source Han Sans CN';
        font-weight: 400;
        border-left: 0.025rem solid transparent;
      }
      span:hover {
        color: #2daea9;
        border-left: 0.025rem solid #2daea9;
      }
      span + span {
        margin-top: 0.19rem;
      }
    }
  }

  .main {
    width: 100%;
    // height: 8.125rem;
    // height: 7.22rem;
    padding-top: 4vh;
    .banner {
      width: 9rem;
      // height: 5.88rem;
      height: 51.3vh;
      img {
        width: 9rem;
        height: 100%;
      }

      ::v-deep .el-carousel__button {
        width: 10px;
        height: 10px;
        background: #c1c1c1;
        border-radius: 50%;
      }

      ::v-deep .el-carousel__indicator.is-active button {
        background: #2daea9;
      }
      ::v-deep .el-carousel__indicator + .el-carousel__indicator {
        margin-left: 0.625rem;
      }
    }
    .form-div {
      position: relative;
      margin-left: 0.96rem;
      display: flex;
    }
    .user-login {
      position: relative;
      width: 5.88rem;
      // height: 6.75rem;
      height: 59vh;
      min-height: 360px;
      background: #ffffff;
      box-shadow: 0px 1px 16px 0px rgba(233, 238, 244, 0.38);
      border-radius: 6px;
      // padding-bottom: 1.3rem;
      .back-box {
        position: absolute;
        top: 0.3rem;
        left: 0.3rem;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2daea9;
        img {
          width: 0.2rem;
          height: 0.2rem;
          margin-right: 0.175rem;
        }
      }
      .tabs > div {
        width: 50%;
        height: 0.85rem;

        img {
          width: 0.25rem;
          height: 0.25rem;
        }
        span {
          height: 100%;
          margin-left: 0.175rem;
          line-height: 0.85rem;
          font-size: 0.25rem;
          // font-family: 'Source Han Sans CN';
          color: #979797;
        }
        span.active {
          color: #2daea9;
          border-bottom: 3px solid #2daea9;
        }
      }
      .spilt {
        height: 1px;
        background: #e1e3e8;
      }

      h3 {
        margin-top: 0.5625rem;
        margin-bottom: 0.375rem;
        text-align: center;
        font-size: 0.3rem;
        // font-family: 'Source Han Sans CN';
        font-weight: 400;
        color: #2daea9;
        span {
          font-size: 0.3rem;
        }
      }

      .account {
        // width: 4.15rem;
        width: 4.375rem;
        margin: 0 auto;
        & ::v-deep .el-form-item {
          //   margin-bottom: 0.25rem;
          margin-bottom: 0.3rem;
        }
        & ::v-deep .el-input__inner {
          //   height: 0.6rem;
          height: 0.525rem;
        }
        & ::v-deep .el-input__inner::placeholder {
          font-size: 0.2rem;
          color: #c9c9c9;
        }
        .login-btn {
          width: 100%;
          height: 0.6rem;
          background: #069f99;
          border-radius: 4px;
          opacity: 0.5;
          border: none;
          pointer-events: none;
        }
        .active {
          opacity: 1;
          pointer-events: auto;
        }
        .qr-code {
          .el-input {
            width: 1.85rem;
          }
          .code {
            position: absolute;
            width: 1.69rem;
            height: 0.63rem;
            right: 0;
            top: 0;
            // background-color: red;
          }
        }
        .checkoutname {
          color: #e2e2e2;
          font-size: 0.23rem;
          .el-checkbox__input.is-checked .el-checkbox__inner {
            border-color: #66a3a1;
            background-color: transparent;
          }
        }
        .drag_verify {
          box-sizing: border-box;
          border: 1px solid #e9e9e9;

          & > div {
            line-height: 0.6rem;
          }
          .dv_handler {
            color: #e1e3e8;
          }
        }
        .flex-box {
          display: flex;
          justify-content: space-between;
          & > span {
            cursor: pointer;
          }
          .green {
            color: #2daea9;
          }
        }
        .flex-box2 {
          display: flex;
          justify-content: center;
          & > span {
            cursor: pointer;
          }
          .green {
            color: #2daea9;
          }
        }
        .code-btn {
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          width: 1.4375rem;
          height: 0.6rem;
          background: #2daea9;
          border-radius: 0.05rem;
          text-align: center;
          line-height: 0.6rem;
          span {
            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .code-btn-gray {
          pointer-events: none;
          background: #d7d7d7;
        }
        .code-pic {
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          width: 1.4375rem;
          height: 0.6rem;
          // background: #2daea9;
          border-radius: 0.05rem;
          text-align: center;
          line-height: 0.6rem;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0.6rem;
          }
        }
      }

      .APP {
        .qr-code {
          width: 3.25rem;
          height: 3.25rem;
          margin: 0 auto;
          background: url("../assets/image/login/QR-code-bacc.png") no-repeat;
          background-size: $bacc;
          img {
            width: 3.15rem;
            height: 3.05rem;
            margin: auto;
          }
        }
        .description {
          margin-top: 0.25rem;
          text-align: center;
          font-size: 0.225rem;
          // font-family: 'Source Han Sans CN';
          color: #2daea9;
        }
      }
    }
  }

  .footer {
    // margin-top: 0.6rem;
    margin-top: 5.2vh;
    // margin-bottom: .225rem;
    // height: 330px;
    // background-color: red;
    ul {
      width: 9.5rem;
      margin: 0 auto;
    }
    .categories {
      .img-div {
        width: 7.4vh;
        // height: 0.85rem;
        height: 7.4vh;
        // margin-bottom: 0.21rem;
        margin-bottom: 1.8vh;
        border: 2px solid #2daea9;
        border-radius: 50%;
      }
      img {
        width: 5vh;
        // height: 0.57rem;
        height: 5vh;
      }
      span {
        font-size: 0.25rem;
        color: #2daea9;
      }
    }
    .footnote {
      text-align: center;
      // padding-bottom: 0.225rem;
      padding-bottom: 1.96vh;
      // margin-top: 0.5rem;
      margin-top: 4.3vh;
      span {
        font-family: "Source Han Sans CN";
        font-size: 0.2rem;
        color: #262727;
        padding-top: 0.06rem;
      }
      img {
        width: 1.05rem;
        margin-left: 0.1625rem;
        vertical-align: bottom;
      }
      .a_link {
        margin-top: 0.125rem;
        a {
          &:hover {
            color: #262727;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.checkoutname {
  color: #e2e2e2;
  font-size: 0.23rem;
  .el-checkbox__input {
    outline: none;
    &.is-checked {
      .el-checkbox__inner {
        border-color: #66a3a1;
        background-color: transparent;
        &::after {
          border-color: #069f99;
        }
      }
    }
  }

  &.is-checked {
    .el-checkbox__label {
      font-size: 14px !important;
      color: #4e4e4e !important;
    }
  }
}
.login-btn {
  span {
    font-size: 0.25rem !important;
  }
}
.logins {
  .el-carousel {
    height: 100%;
  }
  .el-carousel__container {
    height: 100%;
  }
}
.drag_verify {
  .dv_text {
    line-height: 0.6rem;
  }
  .dv_handler {
    line-height: 0.6rem;
    i {
      color: #e1e3e8 !important;
    }
    .el-icon-circle-check {
      color: #069f99 !important;
    }
  }
}
.logins {
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #069f99;
  }
  .el-select .el-input__inner:focus,
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #069f99;
  }
  .el-input__prefix {
    i {
      //   line-height: 0.6rem;
      line-height: 0.525rem;
    }
  }
  .el-input__suffix {
    display: flex;
    align-items: center;
    .eye-icon {
      display: block;
      cursor: pointer;
      width: 0.25rem;
      height: 0.2rem;
      margin-right: 0.175rem;
      background: url("../assets/image/login/icon-eye.png") no-repeat;
    }
    .eyen-icon {
      display: block;
      cursor: pointer;
      width: 0.25rem;
      height: 0.2rem;
      margin-right: 0.175rem;
      background: url("../assets/image/login/icon-eyen.png") no-repeat;
    }
  }
  .codeInput {
    width: 160px;
  }
  .el-select {
    width: 100%;
  }
  .el-select-dropdown__item.selected {
    color: #069f99 !important;
  }
}
</style>
