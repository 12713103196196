import { mapState } from 'vuex'
export default {
	computed: {
		// 行程设备时长自动上报
		...mapState(['socketTime']),
	},
	watch: {
		socketTime: {
			handler(newVal) {
				if (!newVal) {
					return
				}
				if (newVal.data) {
					if (
						newVal.data.deviceNum == this.kzxInfo.deviceNum &&
						newVal.data.subDeviceNum == this.equipmentInfo.subDeviceNum
					) {
						// 正向总时长
						if (newVal.data.onTotalDuration) {
							if (this.zxzscFrom.tztime !== '0') {
								this.zxzscFrom.zdhqTime = newVal.data.onTotalDuration
								if (this.zxzscFrom.zdhqTime + (this.zxzscFrom.tztime - 0) > 0) {
									this.zxTimeHaveSd(newVal.data.onTotalDuration - 0 + (this.zxzscFrom.tztime - 0))
								} else {
									this.zxzscFrom.tztime = 0
									this.zxzscFrom.zdhqTime = newVal.data.onTotalDuration
								}
							} else {
								this.zxzscFrom.zdhqTime = newVal.data.onTotalDuration
							}
						}
						// 反向总时长
						if (newVal.data.offTotalDuration) {
							if (this.fxzscFrom.tztime !== '0') {
								this.fxzscFrom.zdhqTime = newVal.data.offTotalDuration
								if (this.fxzscFrom.zdhqTime + (this.fxzscFrom.tztime - 0) > 0) {
									this.fxTimeHaveSd(newVal.data.offTotalDuration - 0 + (this.fxzscFrom.tztime - 0))
								} else {
									this.fxzscFrom.tztime = 0
									this.fxzscFrom.zdhqTime = newVal.data.offTotalDuration
								}
							} else {
								this.fxzscFrom.zdhqTime = newVal.data.offTotalDuration
							}
						}
					}
				}
			},
			deep: true,
			immediate: true,
		},
	},
	mounted() {},
	destroyed() {},
	computed: {
		automationbjFromUnit() {
			if (this.cjxList.length) {
				let unit
				this.cjxList.forEach((item, index) => {
					if (item.dataItemCode == this.automationbjFrom.dataItemCode) {
						unit = item.unitName
					}
				})
				return unit
			} else {
				return '--'
			}
		},
	},
	methods: {
		// 抽屉关闭
		drawerClose() {
			this.controlItemDisable = {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: false, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			}
			this.isfineTuning = false
			this.menuisShow = true
			this.$refs.Ring.isTuoRun = false
			this.drawer = false
		},
		// 关闭全部的抽屉，主要用于设备状态改变
		drawerCloseAll() {
			if (this.insideSwitch.timeprotect) {
				this.timeprotectClose()
			}
			if (this.insideSwitch.protect) {
				this.insideSwitch.protect = false
			}
			if (this.insideSwitch.zxzsc) {
				this.zxzscClose()
			}
			if (this.insideSwitch.fxzsc) {
				this.fxzscColse()
			}
			if (this.insideSwitch.changPwd) {
				this.changPwdClose()
			}
			if (this.insideSwitch.certification) {
				this.certificationClose()
			}
			if (this.insideSwitch.wjaqmm) {
				this.wjaqmmClose()
			}
			if (this.insideSwitch.bjschedule) {
				this.bjscheduleClose()
			}
			if (this.insideSwitch.schedule) {
				this.insideSwitch.schedule = false
			}

			if (this.insideSwitch.automation) {
				this.insideSwitch.automation = false
			}
			if (this.insideSwitch.automationbj) {
				this.insideSwitch.automationbj = false
			}
			if (this.insideSwitch.perform) {
				if (this.insideSwitch.executionInfo) {
					this.insideSwitch.executionInfo = false
				}
				this.insideSwitch.perform = false
			}
			this.drawerClose()
		},
		// 点击编辑按钮
		editorClick(value) {
			this.bjInputValue = value
			this.bjInputShow = true
		},
		// 取消编辑
		qxeditor() {
			this.bjInputShow = false
		},
		// 确认编辑
		determineName() {
			let deviceNum = this.kzxInfo.deviceNum
			let serviceId = this.equipmentInfo.subDeviceNum
			let serviceName = this.bjInputValue
			let objRegExp = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
			if (objRegExp.test(this.bjInputValue)) {
				this.updateControlName(deviceNum, serviceId, serviceName)
			} else {
				this.$refs.tsmsg.msgShow('名称只能是中文，英文，数字，下划线', 1.5)
			}
		},
		// 修改设备名称
		updateControlName(deviceNum, serviceId, serviceName) {
			this.$http
				.getAction(
					this.$api.updateMetadataServiceName +
						'?deviceNum=' +
						deviceNum +
						'&serviceId=' +
						serviceId +
						'&serviceName=' +
						serviceName
				)
				.then((res) => {
					if (res.code == 200) {
						this.bjInputShow = false
						this.$refs.tsmsg.msgShow('修改成功', 1.5)
						this.$bus.$emit('xiugaiNameHuiD')
					} else {
						if (res.msg == '修改失败，服务id不存在，或者名称与原名称重复！') {
							this.bjInputShow = false
							this.$refs.tsmsg.msgShow('修改失败，不能和原名称一样', 1.5)
							return
						}
						this.bjInputShow = false
						this.$refs.tsmsg.msgShow(res.msg, 1.5)
					}
				})
		},
		// 圆环拖动赋值
		yuanhuanTValue() {
			this.$refs.Ring.sliderValueTwo = this.equipmentInfo.itemList[1].dataItemValue - 0
		},
		// 圆环进度赋值
		yuanhuanValue() {
			console.log(this.$refs.Ring)
			this.$refs.Ring.finishCount = this.equipmentInfo.itemList[1].dataItemValue
			this.$refs.Ring.sliderValue = this.equipmentInfo.itemList[1].dataItemValue - 0
			// this.$refs.Ring.sliderValueTwo = this.equipmentInfo.itemList[1].dataItemValue - 0
			if (!this.$refs.Ring.isTuoRun) {
				this.$refs.Ring.sliderValueTwo = this.equipmentInfo.itemList[1].dataItemValue - 0
			}
			this.equipmentInfo.itemList.forEach((item) => {
				this.equipmentInfo.commandsItemList.forEach((item2) => {
					if (item.dataItemCode == 'status' && item2.commandsItemCode == item.dataItemValue) {
						let commandsItemName = item2.commandsItemName
						if (commandsItemName == '停止') {
							console.log(11111)
							this.$refs.Ring.status = '0'
							this.$refs.Ring.color = 'rgba(45, 174, 169, 1)'
							this.$refs.Ring.color2 = this.$refs.Ring.color
							this.$refs.Ring.sliderValueTwo = this.equipmentInfo.itemList[1].dataItemValue - 0
						}
						if (commandsItemName == '下降') {
							// console.log(22222);
							this.$refs.Ring.status = '2'
							this.$refs.Ring.color = 'rgba(45, 174, 169, 1)'
						}
						if (commandsItemName == '上升') {
							// console.log(33333);
							this.$refs.Ring.status = '1'
							this.$refs.Ring.color = 'rgba(45, 174, 169, 1)'
						}
						if (this.$refs.Ring.isTuoRun) {
							if (this.$refs.Ring.sliderValueTwo > this.$refs.Ring.sliderValue) {
								this.$refs.Ring.color2 = 'rgba(45, 174, 169, .3)'
								this.$refs.Ring.qiuColor = 'rgba(45, 174, 169, 1)'
								this.$refs.Ring.qiuColor2 = '#fff'
							} else if (this.$refs.Ring.sliderValueTwo < this.$refs.Ring.sliderValue) {
								this.$refs.Ring.color2 = '#bae2e1'
								this.$refs.Ring.qiuColor = 'rgba(45, 174, 169, 1)'
								this.$refs.Ring.qiuColor2 = '#fff'
							} else {
								this.$refs.Ring.color2 = this.$refs.Ring.color
							}
						}

						// this.$refs.Ring.isHua = true
					}
				})
			})
			// 判断是否急停
			this.equipmentInfo.itemList.forEach((item) => {
				if (item.dataItemCode == 'e_stop') {
					if (item.dataItemValue == '1') {
						this.$refs.Ring.status = '-1'
						this.$refs.Ring.color = 'rgba(248, 134, 56, 1)'
						this.$refs.Ring.color2 = 'rgba(248, 134, 56, 1)'
						this.$refs.Ring.isHua = false
					}
				}
			})
			// this.$refs.Ring.draw()
		},
		// 菜单点击
		menuClick(index) {
			// 时长保护
			if (index == 3) {
				if (this.controlItem.stop || this.controlItem.scram || this.controlItem.vtStop) {
					this.insideSwitch.certification = true
					this.$nextTick(() => {
						this.$refs.myinput.focus()
					})
				} else {
					this.$refs.tsmsg.msgShow('当前设备正在操作', 1.5)
					return
				}
			}
			// 时间日程
			if (index == 0) {
				if (this.controlItem.stop || this.controlItem.scram || this.controlItem.vtStop) {
					this.autoTimeConfig()
					this.insideSwitch.schedule = true
				} else {
					this.$refs.tsmsg.msgShow('当前设备正在操作', 1.5)
					return
				}
			}
			//   设备自动化
			if (index == 1) {
				if (this.controlItem.stop || this.controlItem.scram || this.controlItem.vtStop) {
					this.thresholdList()
					// this.insideSwitch.automation = true
				} else {
					this.$refs.tsmsg.msgShow('当前设备正在操作', 1.5)
					return
				}
			}
			//   执行记录:设备运行时,执行记录可查看
			if (index == 2) {
				// if (this.controlItem.stop || this.controlItem.scram || this.controlItem.vtStop) {
				this.queryRecord()
				this.operationRecord()
				this.insideSwitch.perform = true
				// } else {
				// 	this.$refs.tsmsg.msgShow('当前设备正在操作', 1.5)
				// 	return
				// }
			}
		},
		// 验证码输入框失去焦点
		codeInputBlur() {
			this.inputFocus = false
		},
		// 验证码输入框获取到焦点
		codeInputFocus() {
			this.inputFocus = true
		},
		codeValueInputChange() {
			// this.codeValue = val.replace(/[^\d]/g, "");
		},
		// 打开修改安全密码
		openchangPwd() {
			this.insideSwitch.changPwd = true
		},
		// 打开编辑时间日程
		openBjschedule(bool, item) {
			this.timeRcbjOradd = bool
			if (bool) {
				if (item.effectiveState == '1') {
					this.$refs.tsmsg2.msgShow('当前已启用无法修改', 1.5)
					return
				}
				this.nowbjTimeRcObj = item
				this.timercFrom.time = item.time
				this.timercFrom.timeType = item.executionCycle
				this.timercFrom.action = item.actionItemCode
				if (item.executionCycle == 1) {
					if (item.exerciseDate == '1') {
						this.timercFrom.timeNumberType = '1'
					} else {
						this.timercFrom.timeNumberType = '2'
						this.timercFrom.timeNumber = item.exerciseDate
					}
				}
				if (item.executionCycle == 2) {
					this.timercFrom.checkList = item.exerciseDate.split(',')
				}
				if (item.executionCycle == 4) {
					this.timercFrom.pickTime = item.exerciseDate
				}
			}
			this.insideSwitch.bjschedule = true
		},
		statusClick(item) {
			let enable = ''
			if (item.effectiveState == '0') {
				enable = '1'
			} else {
				enable = '0'
			}
			this.$http.postAction(this.$api.timeConfigEnable + '?id=' + item.id + '&enable=' + enable).then((res) => {
				if (res.code == 200) {
					let msg = ''
					if (enable == '1') {
						msg = '该时间日程已启用'
					} else if (enable == '0') {
						msg = '该时间日程已禁用'
					}
					this.$refs.tsmsg2.msgShow(msg, 1.5)
					// 重新请求数据
					this.$nextTick(() => {
						this.autoTimeConfig()
					})
					this.Informationtitle = ''
				} else {
					this.Information = false
					this.InformationId = ''
					this.Informationtitle = ''
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
				}
			})
		},
		// 打开删除时间日程确认信息
		openscsjrcqrxx(item) {
			this.nowdelTimeRcObj = item
			this.Information = true
		},
		// 时间日程确定删除
		scheduleDel() {
			this.timeConfigDel(this.nowdelTimeRcObj.id)
		},
		// 时间日程编辑页面点击下发
		scheduleupto() {
			// 按钮不可点击
			if (!this.timeBtnTap) {
				return
			}
			if (this.timercFrom.time == '') {
				this.$refs.tsmsg6.msgShow('请选择时间', 1.5)
				return
			}
			if (this.timercFrom.action == '') {
				this.$refs.tsmsg6.msgShow('请选择执行动作', 1.5)
				return
			}
			if (this.timercFrom.timeType == '1' && this.timercFrom.timeNumberType == '2') {
				const reg = /^[1-9]\d*$/
				if (!reg.test(this.timercFrom.timeNumber)) {
					this.$refs.tsmsg6.msgShow('请输入大于零的整数', 1.5)
					return
				}
			}
			if (this.timercFrom.timeType == '2') {
				if (!this.timercFrom.checkList.length) {
					this.$refs.tsmsg6.msgShow('请选择周几执行', 1.5)
					return
				}
			}
			if (this.timercFrom.timeType == '4') {
				if (this.timercFrom.pickTime == '') {
					this.$refs.tsmsg6.msgShow('请选择日期', 1.5)
					return
				}
			}
			this.timeBtnTap = false
			if (this.timeRcbjOradd) {
				// 编辑
				this.timeConfigUpdate()
			} else {
				// 新增
				this.timeConfigAdd()
			}
			// if (this.timercFrom.time !== '') {
			// 	if (this.timercFrom.action !== '') {
			// 		this.timeBtnTap = false
			// 		if (this.timeRcbjOradd) {
			// 			// 编辑
			// 			this.timeConfigUpdate()
			// 		} else {
			// 			// 新增
			// 			this.timeConfigAdd()
			// 		}
			// 	} else {
			// 		this.$refs.tsmsg6.msgShow('请选择执行动作', 1.5)
			// 	}
			// } else {
			// 	this.$refs.tsmsg6.msgShow('请选择时间', 1.5)
			// }
		},
		// 删除设备自动化记录
		delautomation() {
			this.thresholdDel(this.automationzsFrom.id)
		},
		// 设备自动化下发
		sbzdhIssued() {
			if (this.automationbjFrom.dataItemCode == '') {
				this.$refs.tsmsg7.msgShow('请选择环境参数', 1.5)
				return
			}
			if (this.automationbjFrom.rightVal == '') {
				this.$refs.tsmsg7.msgShow('请设置上限值', 1.5)
				return
			}
			if (this.automationbjFrom.actionItemCodeRight == '') {
				this.$refs.tsmsg7.msgShow('请设置上限值执行动作', 1.5)
				return
			}
			if (this.automationbjFrom.leftVal == '') {
				this.$refs.tsmsg7.msgShow('请设置下限值', 1.5)
				return
			}
			if (this.automationbjFrom.actionItemCodeLeft == '') {
				this.$refs.tsmsg7.msgShow('请设置下限值执行动作', 1.5)
				return
			}
			if (this.automationbjFrom.rightVal - 0 <= this.automationbjFrom.leftVal - 0) {
				this.$refs.tsmsg7.msgShow('请设置合法的上下限', 1.5)
				return
			}
			this.thresholdSet()
			// if (this.automationbjFrom.dataItemCode !== '') {
			// 	if (this.automationbjFrom.rightVal !== '') {
			// 		if (this.automationbjFrom.actionItemCodeRight !== '') {
			// 			if (this.automationbjFrom.leftVal !== '') {
			// 				if (this.automationbjFrom.actionItemCodeLeft !== '') {
			// 					if (this.automationbjFrom.rightVal - 0 > this.automationbjFrom.leftVal - 0) {
			// 						this.thresholdSet()
			// 					} else {
			// 						this.$refs.tsmsg7.msgShow('请设置合法的上下限', 1.5)
			// 					}
			// 				} else {
			// 					this.$refs.tsmsg7.msgShow('请设置下限值执行动作', 1.5)
			// 				}
			// 			} else {
			// 				this.$refs.tsmsg7.msgShow('请设置下限值', 1.5)
			// 			}
			// 		} else {
			// 			this.$refs.tsmsg7.msgShow('请设置上限值执行动作', 1.5)
			// 		}
			// 	} else {
			// 		this.$refs.tsmsg7.msgShow('请设置上限值', 1.5)
			// 	}
			// } else {
			// 	this.$refs.tsmsg7.msgShow('请选择环境参数', 1.5)
			// }
		},
		// 执行记录列表点击
		performItemClick(index) {
			this.insideSwitch.executionInfo = true
			this.insideSwitch.executionInfo = true
			this.performInfoObj = this.performList[index]
			// this.ywList.forEach((item)=> {
			//   if(item.dictValue == this.performInfoObj.businessType) {
			//     item.businessTypeName = item.dictLabel
			//   }
			// })
		},
		// 控制按钮点击
		controlBtnClick(type) {
			this.$refs.Ring.isTuoRun = false //是否是拖动运动 trun 是 false 不是
			// 取消急停
			if (type == 'scram' && this.controlItem.scram) {
				this.controlItemDisable = {
					falling: false, //下降
					stop: false, //停止
					rising: false, //上升
					scram: false, //急停
					fineTuning: false, //微调
					back: false, //返回
					vtRising: false, //向上
					toTop: false, //顶复位
					vtStop: false, //微调停止
					vtFall: false, //向下
					toBottom: false, //低复位
				}
				this.controlItem[type] = false
				this.controlItem['stop'] = true
				this.scram(false)
				return
			}
			if (this.controlItem[type]) {
				return
			}
			this.controlItem = {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: false, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			}
			this.controlItem[type] = true
			if (type == 'falling' || type == 'rising' || type == 'scram') {
				this.controlItemDisable.fineTuning = true
				//上升
				if (type == 'rising') {
					this.equipmentInfo.commandsItemList.forEach((item) => {
						if (item.commandsItemName == '上升') {
							this.operateDevice(item.commandsItemCode)
						}
					})
				}
				// 下降
				if (type == 'falling') {
					this.equipmentInfo.commandsItemList.forEach((item) => {
						if (item.commandsItemName == '下降') {
							this.operateDevice(item.commandsItemCode)
						}
					})
				}
			}
			if (type == 'stop') {
				this.controlItemDisable.fineTuning = false
				// 停止
				if (type == 'stop') {
					this.equipmentInfo.commandsItemList.forEach((item) => {
						if (item.commandsItemName == '停止') {
							this.operateDevice(item.commandsItemCode)
						}
					})
				}
			}
			// 急停
			if (type == 'scram') {
				this.controlItem = {
					falling: false, //下降
					stop: false, //停止
					rising: false, //上升
					scram: false, //急停
					fineTuning: false, //微调
					back: false, //返回
					vtRising: false, //向上
					toTop: false, //顶复位
					vtStop: false, //微调停止
					vtFall: false, //向下
					toBottom: false, //低复位
				}
				this.controlItem.scram = true
				this.controlItemDisable.falling = true
				this.controlItemDisable.stop = true
				this.controlItemDisable.rising = true
				this.controlItemDisable.fineTuning = true
				this.scram(true)
			}
			if (type == 'vtRising' || type == 'vtFall') {
				this.menuisShow = false
			} else {
				this.menuisShow = true
			}
			if (type == 'toTop' || type == 'toBottom') {
				if (type == 'toTop') {
					this.xtInfoTxt = '已对设备进行顶端复位，您的设备位置将位于100%'
				} else {
					this.xtInfoTxt = '已对设备进行底端复位，您的设备位置将位于0%'
				}
				this.Information = true
			}
			if (type == 'vtStop') {
				this.deviceFineTuning()
			}
		},
		// 微调按钮点击
		fineTuning() {
			this.isfineTuning = true
			this.controlItem = {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: true, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			}
			this.xtInfoTxt = '请使用下方操作，完成您的微调。'
		},
		// 点击返回
		clickBack() {
			this.controlItem = {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: false, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			}
			this.xtInfoTxt = '请使用下方操作，完成您的微调。'
			this.isfineTuning = false
			this.menuisShow = true
			this.deviceStatus()
		},
		// 安全密码关闭
		certificationClose() {
			this.codeValue = ''
			this.$http
				.getAction(
					this.$api.queryDuration +
						'?deviceNum=' +
						this.kzxInfo.deviceNum +
						'&subDeviceNum=' +
						this.equipmentInfo.subDeviceNum
				)
				.then((res) => {
					if (res.data && res.data.whetherSet) {
						this.insideSwitch.certification = false
					} else {
						this.drawer = false
						this.insideSwitch.certification = false
					}
				})
		},
		// 修改安全密码关闭
		changPwdClose() {
			this.xiugaiPwdFrom = {
				oldPwd: '',
				newPwd: '',
				againNewPwd: '',
			}
			this.xiugaiPwdType = {
				oldPwd: true,
				newPwd: true,
				againNewPwd: true,
			}
		},
		// 安全密码认证点击确定
		mmrzClick() {
			// let regexp = /^(?!\d*?(\d)\d*?\1)\d{6}$/;
			let regexp = /^[0-9]{6}$/
			if (regexp.test(this.codeValue)) {
				this.$http.postAction(this.$api.comparePassword + '?password=' + this.codeValue).then((res) => {
					if (res.data) {
						if (this.pwdGoTo == '0') {
							this.certificationClose()
							//打开时长保护
							this.insideSwitch.timeprotect = true
							// 查询时长配置
							this.queryDuration()
						}
						if (this.pwdGoTo == '1') {
							this.certificationClose()
							//打开时长保护
							this.insideSwitch.timeprotect = true
							// 查询时长配置
							this.queryDuration()
							setTimeout(() => {
								this.timebaphuMenuClick(1)
							}, 500)
							// this.$nextTick(() => {
							// 	this.timebaphuMenuClick(1)
							// })
						}
						if (this.pwdGoTo == '2') {
							this.certificationClose()
							//打开时长保护
							this.insideSwitch.timeprotect = true
							// 查询时长配置
							this.queryDuration()
							setTimeout(() => {
								this.timebaphuMenuClick(2)
							}, 500)
							// this.$nextTick(() => {
							// 	this.timebaphuMenuClick(2)
							// })
						}
					} else {
						this.$refs.tsmsg3.msgShow('请输入正确的安全密码', 1.5)
						this.codeValue = ''
						this.$nextTick(() => {
							this.$refs.myinput.focus()
						})
					}
				})
			} else {
				this.$refs.tsmsg3.msgShow('请输入6位数字密码', 1.5)
			}
		},
		wjpwdEyeClick(key) {
			this.wjaqmmPwdType[key] = !this.wjaqmmPwdType[key]
		},
		xgpwdEyeClick(key) {
			this.xiugaiPwdType[key] = !this.xiugaiPwdType[key]
		},
		// 忘记密码确定点击
		wjaqmmQDClick() {
			// if (this.wjaqmmFrom.Pwd) {
			// 	if (this.wjaqmmFrom.newPwd) {
			// 		if (this.wjaqmmFrom.againNewPwd) {
			// 			let regexp = /^(?!\d*?(\d)\d*?\1)\d{6}$/
			// 			if (this.wjaqmmFrom.againNewPwd == this.wjaqmmFrom.newPwd) {
			// 				if (regexp.test(this.wjaqmmFrom.newPwd)) {
			// 					this.$http
			// 						.postAction(
			// 							this.$api.setSafePassword +
			// 							'?loginPassword=' +
			// 							this.wjaqmmFrom.Pwd +
			// 							'&password=' +
			// 							this.wjaqmmFrom.newPwd
			// 						)
			// 						.then((res) => {
			// 							if (res.code == 200) {
			// 								this.$refs.tsmsgwjmm.msgShow('设置成功', 1.5)
			// 								setTimeout(() => {
			// 									this.wjaqmmClose()
			// 								}, 1500)
			// 							} else {
			// 								this.$refs.tsmsgwjmm.msgShow(res.msg, 1.5)
			// 							}
			// 						})
			// 				} else {
			// 					this.$refs.tsmsgwjmm.msgShow('请输入正确的安全密码', 1.5)
			// 				}
			// 			} else {
			// 				this.$refs.tsmsgwjmm.msgShow('两次输入的密码不一样', 1.5)
			// 			}
			// 		} else {
			// 			this.$refs.tsmsgwjmm.msgShow('请再次输入新安全密码', 1.5)
			// 		}
			// 	} else {
			// 		this.$refs.tsmsgwjmm.msgShow('请输入新安全密码', 1.5)
			// 	}
			// } else {
			// 	this.$refs.tsmsgwjmm.msgShow('请输入登录密码', 1.5)
			// }
			const LOGIN_PWD_MSG = '请输入登录密码'
			const NEW_PWD_MSG = '请输入新安全密码'
			const CONFIRM_PWD_MSG = '请再次输入新安全密码'
			const NO_MATCH_MSG = '新安全密码和确认新安全密码不一致'
			const REGEXP_ERROR_MSG = '新安全密码格式错误'
			const SUCCESS_MSG = '设置成功'
			const regexp = /^(?!\d*?(\d)\d*?\1)\d{6}$/
			const { wjaqmmFrom, $refs } = this
			function showMessage(msg) {
				$refs.tsmsgwjmm.msgShow(msg, 1.5)
			}
			if (!wjaqmmFrom.Pwd) {
				showMessage(LOGIN_PWD_MSG)
				return
			}
			if (!wjaqmmFrom.newPwd) {
				showMessage(NEW_PWD_MSG)
				return
			}
			if (!wjaqmmFrom.againNewPwd) {
				showMessage(CONFIRM_PWD_MSG)
				return
			}
			if (wjaqmmFrom.againNewPwd !== wjaqmmFrom.newPwd) {
				showMessage(NO_MATCH_MSG)
				return
			}
			if (!regexp.test(wjaqmmFrom.newPwd)) {
				showMessage(REGEXP_ERROR_MSG)
				return
			}
			const params = {
				loginPassword: wjaqmmFrom.Pwd,
				password: wjaqmmFrom.newPwd,
			}
			this.$http
				.postAction(`${this.$api.setSafePassword}?loginPassword=${params.loginPassword}&password=${params.password}`)
				.then((res) => {
					if (res.code === 200) {
						showMessage(SUCCESS_MSG)
						setTimeout(() => {
							this.wjaqmmClose()
						}, 1500)
					} else {
						showMessage(res.msg)
					}
				})
		},
		// 忘记密码关闭
		wjaqmmClose() {
			this.insideSwitch.wjaqmm = false
			this.wjaqmmFrom = {
				Pwd: '',
				newPwd: '',
				againNewPwd: '',
			}
			this.wjaqmmPwdType = {
				Pwd: true,
				newPwd: true,
				againNewPwd: true,
			}
			this.codeValue = ''
		},
		// 时长保护菜单点击
		timebaphuMenuClick(index) {
			// 单次运行与间隔
			if (index == 0) {
				if (this.timeproect.onceOperationDuration != '--') {
					this.protectFrom.onceOperationDuration = this.timeproect.onceOperationDuration
				} else {
					this.protectFrom.onceOperationDuration = ''
				}
				if (this.timeproect.onceIntervalDuration != '--') {
					this.protectFrom.onceIntervalDuration = this.timeproect.onceIntervalDuration
				} else {
					this.protectFrom.onceIntervalDuration = ''
				}
				this.insideSwitch.protect = true
			}
			// 规则：正向反向设置需先设置   单次运行与间隔
			if (index == 1 || index == 2) {
				// 单次运行与间隔未设置，需要先设置
				if (this.timeproect.onceOperationDuration == '--' || this.timeproect.onceIntervalDuration == '--') {
					this.$refs.tsmsg10.msgShow('请先设置单次运行与间隔', 1.5)
					return
				}
				// 正向总时长
				if (index == 1) {
					if (this.timeproect.onTotalDuration != '--') {
						this.zxzscFrom.zdhqTime = this.timeproect.onTotalDuration
						// this.zxzscFrom.zdhqTime = "--"
					}
					this.$bus.$on('tiaozhengTime', this.tiaozhengTime)
					this.insideSwitch.zxzsc = true
				}
				// 反向总时长
				if (index == 2) {
					if (this.timeproect.offTotalDuration != '--') {
						this.fxzscFrom.zdhqTime = this.timeproect.offTotalDuration
						// this.fxzscFrom.zdhqTime = "--"
					}
					this.insideSwitch.fxzsc = true
					this.$bus.$on('tiaozhengTime', this.tiaozhengTimeF)
				}
			}
		},
		// 时长保护关闭
		timeprotectClose() {
			// if (
			// 	this.timeproect.onceOperationDuration !== '--' &&
			// 	this.timeproect.onceIntervalDuration !== '--' &&
			// 	this.timeproect.onTotalDuration !== '--' &&
			// 	this.timeproect.offTotalDuration !== '--'
			// ) {
			// 	this.insideSwitch.timeprotect = false
			// } else {
			// 	this.insideSwitch.timeprotect = false
			// 	this.drawer = false
			// }
			this.$http
				.getAction(
					this.$api.queryDuration +
						'?deviceNum=' +
						this.kzxInfo.deviceNum +
						'&subDeviceNum=' +
						this.equipmentInfo.subDeviceNum
				)
				.then((res) => {
					if (res.data && res.data.whetherSet) {
						this.drawer = true
						this.insideSwitch.timeprotect = false
					} else {
						this.drawer = false
						this.insideSwitch.timeprotect = false
					}
				})
		},
		// 正向总时长菜单点击
		zxzscmenuClick(index) {
			this.zxzscTab = index
			this.zxzscFrom = {
				sdTime: '', //手动设置的时间
				zdhqTime: '', //自动获取时间
				tztime: '0', //调整时间
				jstime: '0', //计时时间单位秒
				timeType: '0', //0秒1分钟
				inputTime: '',
			}
			if (index == 1) {
				this.$http
					.getAction(
						this.$api.queryDuration +
							'?deviceNum=' +
							this.kzxInfo.deviceNum +
							'&subDeviceNum=' +
							this.equipmentInfo.subDeviceNum
					)
					.then((res) => {
						if (res.data && res.data.onTotalDuration) {
							this.zxzscFrom.sdTime = res.data.onTotalDuration
						}
					})
			} else {
				this.$http
					.getAction(
						this.$api.queryDuration +
							'?deviceNum=' +
							this.kzxInfo.deviceNum +
							'&subDeviceNum=' +
							this.equipmentInfo.subDeviceNum
					)
					.then((res) => {
						if (res.data && res.data.onTotalDuration) {
							this.zxzscFrom.zdhqTime = res.data.onTotalDuration
						}
					})
			}
		},
		// 正向总时长按钮点击
		zxzscBtnClick(index) {
			this.zxzscBtn = index
			// 开始获取
			if (index == '0') {
				this.getzxzsctype = '0'
				this.automaticConfig(1, 1)
				this.zxzscFrom.zdhqTime = ''
				this.zxzscFrom.tztime = 0
			}
			// 继续获取
			if (index == '5') {
				this.zxzscBtn = 0
				this.getzxzsctype = '0'
				this.automaticConfig(5, 1)
				this.zxzscShowInput = false
			}
			// 暂停获取
			if (index == '1') {
				this.getzxzsctype = '1'
				this.automaticConfig(4, 1)
			}
			// 结束获取
			if (index == '2') {
				this.getzxzsctype = '2'
				this.automaticConfig(0, 1)
				this.zxdjsCz()
			}
		},
		// 正向总时长微调按钮点击
		zxzscWeitiaoClick(index) {
			this.zxzscWeitiao = index
			if (index == '0' || index == '2') {
				this.zxzscShowInput = true
				this.zxzscFrom.inputTime = ''
				this.zxzscFrom.timeType = '0'
			} else {
				this.xcscwtCaoZuo(0, 0)
				this.zxdjsstop()
			}
		},
		// 正向微调输入时间后确定按钮点击
		zxTrueClick() {
			// if (this.zxzscFrom.inputTime == '') {
			// 	this.$refs.tsmsg8.msgShow('输入大于零的数', 1.5)
			// 	return
			// }
			let reg = /^[1-9]\d*$/
			if (!reg.test(this.zxzscFrom.inputTime)) {
				this.$refs.tsmsg8.msgShow('输入大于零的整数', 1.5)
				return
			}
			if (this.zxzscFrom.timeType == 0) {
				this.zxzscFrom.jstime = this.zxzscFrom.inputTime
			} else {
				this.zxzscFrom.jstime = this.zxzscFrom.inputTime * 60
			}
			let operate = ''
			// 下降
			if (this.zxzscWeitiao == '0') {
				operate = '2'
			}
			// 停止
			if (this.zxzscWeitiao == '1') {
				operate = '0'
			}
			// 上升
			if (this.zxzscWeitiao == '2') {
				operate = '1'
			}
			this.xcscwtCaoZuo(operate, this.zxzscFrom.jstime)
			this.$nextTick(() => {
				this.$refs.countdown.time = 0
				this.$refs.countdown.timeTo = this.zxzscFrom.jstime
				this.$refs.countdown.Time()
			})

			this.zxzscShowInput = false
		},
		// 正向微调倒计时重置
		zxdjsCz() {
			this.$nextTick(() => {
				this.$refs.countdown.time = 0
				this.$refs.countdown.timeTo = 0
				this.$refs.countdown.Time()
				this.zxzscWeitiao = '1'
				this.xcscwtCaoZuo(0, 0)
				this.zxdjsstop()
			})
		},
		// 正向微调倒计时停止
		zxdjsstop() {
			this.$refs.countdown.stop()
		},

		// 反向总时长菜单点击
		fxzscmenuClick(index) {
			this.fxzscTab = index
			this.fxzscFrom = {
				sdTime: '', //手动设置的时间
				zdhqTime: '', //自动获取时间
				tztime: '0', //调整时间
				jstime: '0', //计时时间单位秒
				timeType: '0', //0秒1分钟
				inputTime: '',
			}
			if (index == 1) {
				this.$http
					.getAction(
						this.$api.queryDuration +
							'?deviceNum=' +
							this.kzxInfo.deviceNum +
							'&subDeviceNum=' +
							this.equipmentInfo.subDeviceNum
					)
					.then((res) => {
						if (res.data && res.data.offTotalDuration) {
							this.fxzscFrom.sdTime = res.data.offTotalDuration
						}
					})
			} else {
				this.$http
					.getAction(
						this.$api.queryDuration +
							'?deviceNum=' +
							this.kzxInfo.deviceNum +
							'&subDeviceNum=' +
							this.equipmentInfo.subDeviceNum
					)
					.then((res) => {
						if (res.data && res.data.offTotalDuration) {
							this.fxzscFrom.zdTime = res.data.offTotalDuration
						}
					})
			}
		},
		// 反向总时长按钮点击
		fxzscBtnClick(index) {
			this.fxzscBtn = index
			// 开始获取
			if (index == '0') {
				this.getfxzsctype = '0'
				this.automaticConfig(2, 2)
				this.fxzscFrom.zdhqTime = ''
				this.fxzscFrom.tztime = ''
			}
			// 继续获取
			if (index == '5') {
				this.fxzscBtn = 0
				this.getfxzsctype = '0'
				this.fxzscShowInput = false
				this.automaticConfig(5, 2)
			}
			// 暂停获取
			if (index == '1') {
				this.getfxzsctype = '1'
				this.automaticConfig(4, 2)
			}
			// 结束获取
			if (index == '2') {
				this.getfxzsctype = '2'
				this.automaticConfig(0, 2)
				this.fxdjsCz()
			}
		},
		// 反向总时长微调按钮点击
		fxzscWeitiaoClick(index) {
			this.fxzscWeitiao = index
			if (index == '0' || index == '2') {
				this.fxzscShowInput = true
				this.fxzscFrom.inputTime = ''
				this.fxzscFrom.timeType = '0'
			} else {
				this.xcscwtCaoZuo(0, 0)
				this.zxdjsstop()
			}
		},
		// 反向微调输入时间后确定按钮点击
		fxTrueClick() {
			// if (this.fxzscFrom.inputTime == '') {
			// 	this.$refs.tsmsg9.msgShow('输入大于零的数', 1.5)
			// 	return
			// }
			let reg = /^[1-9]\d*$/
			if (!reg.test(this.fxzscFrom.inputTime)) {
				this.$refs.tsmsg9.msgShow('输入大于零的整数', 1.5)
				return
			}
			if (this.fxzscFrom.timeType == 0) {
				this.fxzscFrom.jstime = this.fxzscFrom.inputTime
			} else {
				this.fxzscFrom.jstime = this.fxzscFrom.inputTime * 60
			}
			let operate = ''
			// 下降
			if (this.fxzscWeitiao == '0') {
				operate = '2'
			}
			// 停止
			if (this.fxzscWeitiao == '1') {
				operate = '0'
			}
			// 上升
			if (this.fxzscWeitiao == '2') {
				operate = '1'
			}
			this.xcscwtCaoZuo(operate, this.fxzscFrom.jstime)
			this.$nextTick(() => {
				this.$refs.countdown.time = 0
				this.$refs.countdown.timeTo = this.fxzscFrom.jstime
				this.$refs.countdown.Time()
			})

			this.fxzscShowInput = false
		},
		// 反向微调倒计时重置
		fxdjsCz() {
			this.$nextTick(() => {
				this.$refs.countdown.time = 0
				this.$refs.countdown.timeTo = 0
				this.$refs.countdown.Time()
				this.fxzscWeitiao = '1'
				this.xcscwtCaoZuo(0, 0)
				this.zxdjsstop()
			})
		},
		// 反向微调倒计时停止
		fxdjsstop() {
			this.$refs.countdown.stop()
		},

		// 修改安全密码关闭
		xgaqmmClose() {
			this.insideSwitch.changPwd = false
			this.xiugaiPwdFrom = {
				oldPwd: '',
				newPwd: '',
				againNewPwd: '',
			}
		},
		// 编辑时间日程关闭
		bjscheduleClose() {
			this.insideSwitch.bjschedule = false
			this.timercFrom = {
				time: '',
				timeType: '1', //1每天2每周3指定日期
				timeNumberType: '1', //1固定1天2不固定几天
				timeNumber: '',
				checkList: ['1'],
				pickTime: '',
				action: '', //0上升1停止2下降
			}
		},

		/**
		 * 数据请求
		 * **/
		// 查询时长配置
		queryDuration() {
			this.$http
				.getAction(
					this.$api.queryDuration +
						'?deviceNum=' +
						this.kzxInfo.deviceNum +
						'&subDeviceNum=' +
						this.equipmentInfo.subDeviceNum
				)
				.then((res) => {
					if (res.data) {
						if (res.data.onceOperationDuration != undefined && res.data.onceOperationDuration !== '') {
							this.timeproect.onceOperationDuration = res.data.onceOperationDuration
						} else {
							this.timeproect.onceOperationDuration = '--'
						}
						if (res.data.onceIntervalDuration != undefined && res.data.onceIntervalDuration !== '') {
							this.timeproect.onceIntervalDuration = res.data.onceIntervalDuration
						} else {
							this.timeproect.onceIntervalDuration = '--'
						}
						if (res.data.onTotalDuration != undefined && res.data.onTotalDuration !== '') {
							this.timeproect.onTotalDuration = res.data.onTotalDuration
						} else {
							this.timeproect.onTotalDuration = '--'
						}
						if (res.data.offTotalDuration != undefined && res.data.offTotalDuration !== '') {
							this.timeproect.offTotalDuration = res.data.offTotalDuration
						} else {
							this.timeproect.offTotalDuration = '--'
						}
						console.log(this.timeproect)
					} else {
						this.timeproect = {
							onceOperationDuration: '--',
							onceIntervalDuration: '--',
							onTotalDuration: '--',
							offTotalDuration: '--',
						}
					}
				})
		},
		// 验证是否为正整数
		isIntNum(val) {
			console.log(val)
			var regPos = /^\d+$/ //非负整数(正整数 + 0)
			// var regNeg = /^\-[1-9][0-9]*$/; // 负整数
			if (regPos.test(val)) {
				return true
			} else {
				return false
			}
		},
		// 修改时长配置单次运行与间隔
		setDuration() {
			if (this.protectFrom.onceOperationDuration == '') {
				this.$refs.tsmsg4.msgShow('单次运行时长应输入正整数', 1.5)
				return
			}
			if (this.protectFrom.onceIntervalDuration == '') {
				this.$refs.tsmsg4.msgShow('单次间隔时长应输入正整数', 1.5)
				return
			}
			if (!this.isIntNum(this.protectFrom.onceOperationDuration - 0)) {
				this.$refs.tsmsg4.msgShow('单次运行时长应输入正整数', 1.5)
				return
			}
			if (!this.isIntNum(this.protectFrom.onceIntervalDuration - 0)) {
				this.$refs.tsmsg4.msgShow('单次间隔时长应输入正整数', 1.5)
				return
			}
			let timeSmall = 0
			// 获取总时长的最小值用作比较
			// 判断总时长是否都输入了
			// 两个都有值
			if (this.timeproect.onTotalDuration != '--' && this.timeproect.offTotalDuration != '--') {
				if (Number(this.timeproect.onTotalDuration) > Number(this.timeproect.offTotalDuration)) {
					timeSmall = Number(this.timeproect.offTotalDuration)
				} else {
					timeSmall = Number(this.timeproect.onTotalDuration)
				}
			} else if (this.timeproect.onTotalDuration != '--' && this.timeproect.offTotalDuration == '--') {
				// 正向有值反向未设置
				timeSmall = Number(this.timeproect.onTotalDuration)
			} else if (this.timeproect.onTotalDuration == '--' && this.timeproect.offTotalDuration != '--') {
				// 反向有值正向未设置
				timeSmall = Number(this.timeproect.offTotalDuration)
			} else {
				timeSmall = 0
			}
			// 其中一个有值就进行比对
			if (this.timeproect.onTotalDuration != '--' || this.timeproect.offTotalDuration != '--') {
				// 比较
				if (Number(this.protectFrom.onceOperationDuration) >= timeSmall) {
					this.$refs.tsmsg4.msgShow('单次运行时长应小于' + timeSmall + 's', 1.5)
					return
				}
				if (Number(this.protectFrom.onceIntervalDuration) >= timeSmall) {
					this.$refs.tsmsg4.msgShow('单次间隔时长应小于' + timeSmall + 's', 1.5)
					return
				}
			}

			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				onceOperationDuration: this.protectFrom.onceOperationDuration - 0,
				onceIntervalDuration: this.protectFrom.onceIntervalDuration - 0,
			}
			let option = {
				fullscreen: true,
				target: document.querySelector('.dcyxjg'),
				spinner: 'el-icon-loading',
				background: 'rgba(0,0,0,.1)',
			}
			let loadingInstance = this.$loading(option)
			this.$http.postAction(this.$api.setDuration, params).then((res) => {
				this.$nextTick(() => {
					// 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close()
				})
				if (res.code == 200) {
					this.$refs.tsmsg4.msgShow('设置成功', 1.5)
					setTimeout(() => {
						this.insideSwitch.protect = false
						this.queryDuration()
					}, 1500)
				} else {
					this.$refs.tsmsg4.msgShow(res.msg, 1.5)
				}
			})
		},
		// 手动设备正向总时长
		sdszzxzsc() {
			if (this.zxzscFrom.sdTime <= 0) {
				this.$refs.tsmsg8.msgShow('总时长为大于0的正整数', 1.5)
				return
			}
			// 和单次运行与间隔 最大值的判断
			let timeSmall = 0
			if (Number(this.timeproect.onceOperationDuration) > Number(this.timeproect.onceIntervalDuration)) {
				timeSmall = Number(this.timeproect.onceOperationDuration)
			} else {
				timeSmall = Number(this.timeproect.onceIntervalDuration)
			}
			console.log(timeSmall)
			if (this.zxzscFrom.sdTime <= timeSmall) {
				this.$refs.tsmsg8.msgShow('总时长需大于单次运行与间隔时长', 1.5)
				return
			}
			if (this.zxzscFrom.sdTime > 43200) {
				this.$refs.tsmsg8.msgShow('总时长为大于0小于等于43200', 1.5)
				return
			}

			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				onTotalDuration: this.zxzscFrom.sdTime,
			}
			this.$http.postAction(this.$api.setDuration, params).then((res) => {
				if (res.code == 200) {
					console.log('执行')
					this.$refs.tsmsg8.msgShow('设置成功', 1.5)
					setTimeout(() => {
						this.zxzscClose()
						// this.queryDuration()
					}, 1500)
				} else {
					this.$refs.tsmsg8.msgShow(res.msg, 1.5)
				}
			})
		},
		// 手动设备反向总时长
		sdszfxzsc() {
			if (this.fxzscFrom.sdTime <= 0) {
				this.$refs.tsmsg9.msgShow('总时长为大于0的正整数', 1.5)
				return
			}
			// 和单次运行与间隔 最大值的判断
			let timeSmall = 0
			if (Number(this.timeproect.onceOperationDuration) > Number(this.timeproect.onceIntervalDuration)) {
				timeSmall = Number(this.timeproect.onceOperationDuration)
			} else {
				timeSmall = Number(this.timeproect.onceIntervalDuration)
			}
			if (this.fxzscFrom.sdTime <= timeSmall) {
				this.$refs.tsmsg9.msgShow('总时长需大于单次运行与间隔时长', 1.5)
				return
			}
			if (this.fxzscFrom.sdTime > 43200) {
				this.$refs.tsmsg9.msgShow('总时长为大于0小于43200', 1.5)
				return
			}
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				offTotalDuration: this.fxzscFrom.sdTime,
			}
			this.$http.postAction(this.$api.setDuration, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg9.msgShow('设置成功', 1.5)
					setTimeout(() => {
						this.fxzscColse()
						// this.queryDuration()
					}, 1500)
				} else {
					this.$refs.tsmsg9.msgShow(res.msg, 1.5)
				}
			})
		},
		// 正向总时长关闭
		zxzscClose() {
			this.insideSwitch.zxzsc = false
			this.zxzscTab = 0
			this.zxzscFrom.zdhqTime = ''
			this.getzxzsctype = '-1' //-1 未获取 0 正在获取 1 暂停
			this.zxzscBtn = '-1' //-1 未选中 0 开始获取 1停止获取 2结束获取
			this.queryDuration()
			this.$bus.$off('tiaozhengTime')
		},
		// 正向总时长关闭之前
		zxzscbeforeClose(done) {
			if (this.fxzscTab == 0) {
				if (this.zxzscBtn == 0 || this.zxzscBtn == 1 || this.zxzscBtn == 5) {
					this.$message('正在自动获取总时长，请结束后重试', 'error', {
						duration: 0,
					})
				} else {
					this.zxzscClose()
				}
			} else {
				this.zxzscClose()
			}
		},
		// 反向总时长关闭
		fxzscColse() {
			this.insideSwitch.fxzsc = false
			this.fxzscTab = 0
			this.fxzscFrom.zdhqTime = ''
			this.getfxzsctype = '-1' //-1 未获取 0 正在获取 1 暂停
			this.fxzscBtn = '-1' //-1 未选中 0 开始获取 1停止获取 2结束获取
			this.queryDuration()
			this.$bus.$off('tiaozhengTime')
		},
		// 反向总时长关闭之前
		fxzscbeforeClose(done) {
			if (this.fxzscTab == 0) {
				if (this.fxzscBtn == 0 || this.fxzscBtn == 1 || this.fxzscBtn == 5) {
					this.$message('正在自动获取总时长，请结束后重试', 'error', {
						duration: 0,
					})
				} else {
					this.fxzscColse()
				}
			} else {
				this.fxzscColse()
			}
		},
		// 修改安全密码
		updateSafePassword() {
			// if (this.xiugaiPwdFrom.oldPwd) {
			// 	if (this.xiugaiPwdFrom.newPwd) {
			// 		if (this.xiugaiPwdFrom.againNewPwd) {
			// 			let regexp = /^(?!\d*?(\d)\d*?\1)\d{6}$/
			// 			if (this.xiugaiPwdFrom.againNewPwd == this.xiugaiPwdFrom.newPwd) {
			// 				if (regexp.test(this.xiugaiPwdFrom.newPwd)) {
			// 					this.$http
			// 						.postAction(
			// 							this.$api.updateSafePassword +
			// 							'?oldPassword=' +
			// 							this.xiugaiPwdFrom.oldPwd +
			// 							'&newPassword=' +
			// 							this.xiugaiPwdFrom.newPwd
			// 						)
			// 						.then((res) => {
			// 							if (res.code == 200) {
			// 								this.$refs.tsmsg5.msgShow('设置成功', 1.5)
			// 								setTimeout(() => {
			// 									this.xgaqmmClose()
			// 								}, 1500)
			// 							} else {
			// 								this.$refs.tsmsg5.msgShow(res.msg, 1.5)
			// 							}
			// 						})
			// 				} else {
			// 					this.$refs.tsmsg5.msgShow('请输入正确的安全密码', 1.5)
			// 				}
			// 			} else {
			// 				this.$refs.tsmsg5.msgShow('两次输入的密码不一样', 1.5)
			// 			}
			// 		} else {
			// 			this.$refs.tsmsg5.msgShow('请再次输入新安全密码', 1.5)
			// 		}
			// 	} else {
			// 		this.$refs.tsmsg5.msgShow('请输入新安全密码', 1.5)
			// 	}
			// } else {
			// 	this.$refs.tsmsg5.msgShow('请输入原安全密码', 1.5)
			// }
			if (!this.xiugaiPwdFrom.oldPwd) {
				this.$refs.tsmsg5.msgShow('请输入原安全密码', 1.5)
				return
			}
			if (!this.xiugaiPwdFrom.newPwd) {
				this.$refs.tsmsg5.msgShow('请输入新安全密码', 1.5)
				return
			}
			if (!this.xiugaiPwdFrom.againNewPwd) {
				this.$refs.tsmsg5.msgShow('请再次输入新安全密码', 1.5)
				return
			}
			if (this.xiugaiPwdFrom.againNewPwd !== this.xiugaiPwdFrom.newPwd) {
				this.$refs.tsmsg5.msgShow('新安全密码和确认新安全密码不一致', 1.5)
				return
			}
			const regexp = /^(?!\d*?(\d)\d*?\1)\d{6}$/
			if (!regexp.test(this.xiugaiPwdFrom.newPwd)) {
				this.$refs.tsmsg5.msgShow('新安全密码格式错误', 1.5)
				return
			}
			const url = `${this.$api.updateSafePassword}?oldPassword=${this.xiugaiPwdFrom.oldPwd}&newPassword=${this.xiugaiPwdFrom.newPwd}`
			this.$http.postAction(url).then((res) => {
				if (res.code === 200) {
					this.$refs.tsmsg5.msgShow('设置成功', 1.5)
					setTimeout(() => {
						this.xgaqmmClose()
					}, 1500)
				} else {
					this.$refs.tsmsg5.msgShow(res.msg, 1.5)
				}
			})
		},
		// 查询时间日程列表
		autoTimeConfig() {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
			}
			this.$http.postAction(this.$api.autoTimeConfig, params).then((res) => {
				if (res.data.length) {
					res.data.forEach((item) => {
						item.time = item.conditionTime
						item.iseffect = item.effectiveState == '1' ? true : false
						item.action = item.actionItemName
						if (item.executionCycle == '1') {
							item.date = '每天'
						}
						if (item.executionCycle == '2') {
							let weekArr = [
								{
									type: '1',
									txt: '周一',
								},
								{
									type: '2',
									txt: '周二',
								},
								{
									type: '3',
									txt: '周三',
								},
								{
									type: '4',
									txt: '周四',
								},
								{
									type: '5',
									txt: '周五',
								},
								{
									type: '6',
									txt: '周六',
								},
								{
									type: '7',
									txt: '周日',
								},
							]
							let str = ''
							item.weeks.forEach((item2, index) => {
								weekArr.forEach((item3) => {
									if (item2 == item3.type) {
										if (index == 0) {
											str += item3.txt
										} else {
											str += '、' + item3.txt
										}
									}
								})
							})
							item.date = '每周（' + str + '）'
						}
						if (item.executionCycle == '4') {
							item.date = item.exerciseDate
						}
					})
					this.scheduleList = res.data
				} else {
					this.scheduleList = []
				}
			})
		},
		// 时间日程添加
		timeConfigAdd() {
			// 判断exerciseDate的传值
			let exerciseDate = ''
			// 按日
			if (this.timercFrom.timeType == 1) {
				if (this.timercFrom.timeNumberType == 1) {
					exerciseDate = '1'
				} else {
					exerciseDate = Number(this.timercFrom.timeNumber) + 1
				}
			} else if (this.timercFrom.timeType == 2) {
				// 按周
				exerciseDate = this.timercFrom.checkList.join(',')
			} else if (this.timercFrom.timeType == 4) {
				// 指定日期
				exerciseDate = this.timercFrom.pickTime
			}
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				executionCycle: this.timercFrom.timeType,
				exerciseDate: exerciseDate,
				conditionTime: this.timercFrom.time,
				actionItemCode: this.timercFrom.action,
				priority: '',
				duration: '',
				aperture: '',
			}
			this.$http
				.postAction(this.$api.timeConfigAdd, params)
				.then((res) => {
					if (res.code == 200) {
						this.bjscheduleClose()
						this.$refs.tsmsg2.msgShow('下发成功', 1.5)
						this.autoTimeConfig()
					} else {
						this.bjscheduleClose()
						this.$refs.tsmsg2.msgShow(res.msg, 1.5)
						this.autoTimeConfig()
					}
				})
				.finally(() => {
					this.timeBtnTap = true
				})
		},
		// 时间日程修改
		timeConfigUpdate() {
			// 判断exerciseDate的传值
			let exerciseDate = ''
			// 按日
			if (this.timercFrom.timeType == 1) {
				if (this.timercFrom.timeNumberType == 1) {
					exerciseDate = '1'
				} else {
					exerciseDate = Number(this.timercFrom.timeNumber) + 1
				}
			} else if (this.timercFrom.timeType == 2) {
				// 按周
				exerciseDate = this.timercFrom.checkList.join(',')
			} else if (this.timercFrom.timeType == 4) {
				// 指定日期
				exerciseDate = this.timercFrom.pickTime
			}
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				executionCycle: this.timercFrom.timeType,
				exerciseDate: exerciseDate,
				conditionTime: this.timercFrom.time,
				actionItemCode: this.timercFrom.action,
				id: this.nowbjTimeRcObj.id,
				priority: '',
				duration: '',
				aperture: '',
			}
			this.$http
				.postAction(this.$api.timeConfigUpdate, params)
				.then((res) => {
					if (res.code == 200) {
						this.bjscheduleClose()
						this.$refs.tsmsg2.msgShow('编辑成功', 1.5)
						this.autoTimeConfig()
					} else {
						this.bjscheduleClose()
						this.$refs.tsmsg2.msgShow(res.msg, 1.5)
					}
				})
				.finally(() => {
					this.timeBtnTap = true
				})
		},
		// 时间日程删除
		timeConfigDel(id) {
			this.$http.deleteAction(this.$api.timeConfigDel + '/' + id).then((res) => {
				if (res.code == 200) {
					this.Information = false
					this.$refs.tsmsg2.msgShow('删除成功', 1.5)
					this.autoTimeConfig()
				} else {
					this.Information = false
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
				}
			})
		},
		// 时间日程下发
		timeConfigEnable(id) {
			this.$http.postAction(this.$api.timeConfigEnable + '?id=' + id + '&enable=1').then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg2.msgShow('下发成功', 1.5)
					this.autoTimeConfig()
				} else {
					this.Information = false
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
				}
			})
		},
		// 设备自动化 - 查询
		thresholdList() {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
			}
			this.$http.postAction(this.$api.thresholdList, params).then((res) => {
				// 有数据展示列表项
				if (res.data) {
					this.insideSwitch.automation = true
					this.automationzsFrom = res.data
					this.cgqList.forEach((item) => {
						if (item.subDeviceNum == res.data.sensorNum) {
							this.automationzsFrom.subDeviceName = item.subDeviceName
							item.itemList.forEach((item2) => {
								if (item2.dataItemCode == res.data.dataItemCode) {
									this.automationzsFrom.dataItemName = item2.dataItemName
								}
							})
						}
					})
					this.equipmentInfo.commandsItemList.forEach((item) => {
						if (item.commandsItemCode == res.data.actionItemCodeLeft) {
							this.automationzsFrom.actionItemNameLeft = item.commandsItemName
						}
						if (item.commandsItemCode == res.data.actionItemCodeRight) {
							this.automationzsFrom.actionItemNameRight = item.commandsItemName
						}
					})
				} else {
					// 数据回空
					this.automationbjFrom = {
						sensorNum: '',
						dataItemCode: '',
						rightVal: '', //上限
						actionItemCodeRight: '', //0上升1停止2下降
						leftVal: '', //下限
						actionItemCodeLeft: '', //0上升1停止2下降
					}
					// 没有列表数据进入编辑项
					this.insideSwitch.automation = false
					this.automationbjFrom.sensorNum = this.cgqList[0].subDeviceNum
					this.cjxList = this.cgqList[0].itemList
					this.insideSwitch.automationbj = true
				}
			})
		},
		// 打开设备自动化编辑页
		openautomationbj() {
			this.automationbjFrom = this.automationzsFrom
			this.cgqList.forEach((item) => {
				if (item.subDeviceNum == this.automationbjFrom.sensorNum) {
					this.cjxList = item.itemList
				}
			})
			this.insideSwitch.automationbj = true
		},
		// 设备自动化编辑切换传感器
		sbzdhCgqChange() {
			this.cgqList.forEach((item) => {
				if (item.subDeviceNum == this.automationbjFrom.sensorNum) {
					this.cjxList = item.itemList
				}
			})
		},
		// 设备自动化环境阈值- 设置
		thresholdSet() {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				deviceId: this.kzxInfo.id,
				rightVal: this.automationbjFrom.rightVal,
				actionItemCodeRight: this.automationbjFrom.actionItemCodeRight,
				leftVal: this.automationbjFrom.leftVal,
				actionItemCodeLeft: this.automationbjFrom.actionItemCodeLeft,
				sensorNum: this.automationbjFrom.sensorNum,
				dataItemCode: this.automationbjFrom.dataItemCode,
			}
			if (this.automationbjFrom.id) {
				params.id = this.automationbjFrom.id
			}
			this.$http.postAction(this.$api.thresholdSet, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg7.msgShow('设置成功', 1.5)
					setTimeout(() => {
						this.thresholdList()

						this.insideSwitch.automationbj = false
						this.insideSwitch.automation = true
						this.automationbjFrom = {
							sensorNum: '',
							dataItemCode: '',
							rightVal: '', //上限
							actionItemCodeRight: '', //0上升1停止2下降
							leftVal: '', //下限
							actionItemCodeLeft: '', //0上升1停止2下降
						}
					}, 1500)
				} else {
					this.$refs.tsmsg7.msgShow(res.msg, 1.5)
				}
			})
		},
		// 设备自动化删除
		thresholdDel(id) {
			this.$http.deleteAction(this.$api.thresholdDel + '?id=' + id).then((res) => {
				if (res.code == 200) {
					this.Information = false
					this.insideSwitch.automation = false
					this.$refs.tsmsg.msgShow('删除成功', 1.5)
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
				}
			})
		},
		// 执行记录时间切换
		performTimeChange() {
			console.log(this.performFrom.time)
		},
		// 执行记录查询
		operationRecord() {
			let startTime = ''
			let endTime = ''
			if (this.performFrom.time && this.performFrom.time.length) {
				startTime = this.performFrom.time[0]
				endTime = this.performFrom.time[1]
			} else {
				startTime = ''
				endTime = ''
			}
			let params = {
				list: [
					{
						deviceNum: this.kzxInfo.deviceNum,
						subDeviceNums: [this.equipmentInfo.subDeviceNum],
					},
				],
				currentPage: 1,
				pageSize: 20,
			}
			if (startTime !== '') {
				params.startTime = startTime
			}
			if (endTime !== '') {
				params.endTime = endTime
			}
			if (this.performFrom.operation !== '') {
				params.operationSources = [this.performFrom.operation]
			} else {
				params.operationSources = []
			}
			if (this.performFrom.status !== '') {
				params.operationResult = this.performFrom.status
			} else {
				params.operationResult = ''
			}
			this.$http.postAction(this.$api.recordList, params).then((res) => {
				this.performList = []
				if (res.data && res.data.records.length) {
					this.performList = res.data.records
					let dom = this.$refs.seachList
					dom.scrollTop = 0
				} else {
					this.performList = []
				}
			})
		},
		// 执行记录抽屉关闭
		performClose() {
			this.performFrom = {
				time: '', //时间
				operation: '', //操作源
				status: '', //执行状态
			}
		},
		// 设备状态更新
		deviceStatus() {
			console.log(this.isfineTuning)
			this.controlItem = {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: false, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			}
			if (!this.isfineTuning) {
				this.equipmentInfo.itemList.forEach((item) => {
					if (item.dataItemCode == 'status') {
						if (item.dataItemValue == '') {
							this.controlItem.stop = true
							this.controlItemDisable.fineTuning = false
							this.$refs.Ring.isHua = false
							return
						}
						this.equipmentInfo.commandsItemList.forEach((item2) => {
							if (item.dataItemValue == item2.commandsItemCode) {
								let commandsItemName = item2.commandsItemName
								if (commandsItemName == '停止') {
									this.controlItem.stop = true
									this.controlItemDisable.fineTuning = false
									this.$refs.Ring.isHua = true
								}
								if (commandsItemName == '下降') {
									this.controlItem.falling = true
									this.controlItemDisable.fineTuning = true
									this.$refs.Ring.isHua = false
								}
								if (commandsItemName == '上升') {
									this.controlItem.rising = true
									this.controlItemDisable.fineTuning = true
									this.$refs.Ring.isHua = false
								}
							}
						})
					}
				})
				this.equipmentInfo.itemList.forEach((item) => {
					if (item.dataItemCode == 'e_stop') {
						if (item.dataItemValue == '1') {
							this.controlItem = {
								falling: false, //下降
								stop: false, //停止
								rising: false, //上升
								scram: false, //急停
								fineTuning: false, //微调
								back: false, //返回
								vtRising: false, //向上
								toTop: false, //顶复位
								vtStop: false, //微调停止
								vtFall: false, //向下
								toBottom: false, //低复位
							}
							this.controlItem.scram = true
							this.controlItemDisable.falling = true
							this.controlItemDisable.stop = true
							this.controlItemDisable.rising = true
							this.controlItemDisable.fineTuning = true
						}
					}
				})
			} else {
				this.equipmentInfo.itemList.forEach((item) => {
					if (item.dataItemCode == 'status') {
						this.controlItemDisable = {
							falling: false, //下降
							stop: false, //停止
							rising: false, //上升
							scram: false, //急停
							fineTuning: false, //微调
							back: false, //返回
							vtRising: false, //向上
							toTop: false, //顶复位
							vtStop: false, //微调停止
							vtFall: false, //向下
							toBottom: false, //低复位
						}
						this.equipmentInfo.commandsItemList.forEach((item2) => {
							if (item.dataItemValue == item2.commandsItemCode) {
								let commandsItemName = item2.commandsItemName
								if (commandsItemName == '停止') {
									this.controlItem.vtStop = true
									this.xtInfoTxt = '请使用下方操作，完成您的微调。'
									this.showcountdownUp = false
								}
								if (commandsItemName == '下降') {
									this.controlItem.vtFall = true
									this.controlItemDisable = {
										falling: false, //下降
										stop: false, //停止
										rising: false, //上升
										scram: false, //急停
										fineTuning: false, //微调
										back: true, //返回
										vtRising: true, //向上
										toTop: true, //顶复位
										vtStop: false, //微调停止
										vtFall: false, //向下
										toBottom: true, //低复位
									}
								}
								if (commandsItemName == '上升') {
									this.controlItem.vtRising = true
									this.controlItemDisable = {
										falling: false, //下降
										stop: false, //停止
										rising: false, //上升
										scram: false, //急停
										fineTuning: false, //微调
										back: true, //返回
										vtRising: false, //向上
										toTop: true, //顶复位
										vtStop: false, //微调停止
										vtFall: true, //向下
										toBottom: true, //低复位
									}
								}
							}
						})
					}
				})
			}
		},
		// 设备操作
		operateDevice(operate) {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				operate: operate,
				duration: '',
				khd: '',
			}
			this.$http.postAction(this.$api.operateDevice, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg.msgShow('操作成功', 1.5)
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
					this.deviceStatus()
				}
			})
		},
		// 设备操作
		operateDeviceTuo(operateStr, khd) {
			let operate = ''
			if (operateStr == 'rise') {
				//上升
				operate = '1'
			}
			if (operateStr == 'decline') {
				//下降
				operate = '2'
			}
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,

				duration: '',
				khd: khd,
			}
			;(params.operate = operate),
				this.$http.postAction(this.$api.operateDevice, params).then((res) => {
					if (res.code == 200) {
						this.$refs.tsmsg.msgShow('操作成功', 1.5)
					} else {
						this.$refs.tsmsg.msgShow(res.msg, 1.5)
						this.deviceStatus()
					}
				})
		},
		// 设备急停
		scram(bool) {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				stop: bool ? '1' : '0',
				operateSource: 'WEB',
			}
			this.$http.postAction(this.$api.scram, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg.msgShow('操作成功', 1.5)
					// if (bool) {
					//   this.$refs.Ring.color = "rgba(248, 134, 56, 1)";
					// } else {
					//   this.$refs.Ring.color = "rgba(45, 174, 169, 1)";
					// }
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
					this.deviceStatus()
				}
			})
		},
		// 确认位置信息警告取消
		alignedQuxiao() {
			this.Information = false
			this.xtInfoTxt = '请使用下方操作，完成您的微调。'
			this.controlItem = {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: true, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			}
		},
		isPositiveInteger(number) {
			// 使用Number.isInteger()函数判断是否为整数
			// 使用大于符号（>）判断是否大于0
			if (Number.isInteger(number) && number > 0) {
				return true // 是大于的整数
			} else {
				return false // 不是大于的整数
			}
		},
		// 设备微调
		deviceFineTuning() {
			let operate, duration
			let time = ''
			let vtFangs = ''
			if (this.controlItem.vtRising) {
				operate = '1'
				vtFangs = '向上'
			} else if (this.controlItem.vtStop) {
				operate = '0'
				vtFangs = '向下'
			} else {
				operate = '2'
				vtFangs = '向下'
			}
			if (this.controlItem.vtStop) {
				duration = ''
			} else {
				if (this.vtRunTime == '') {
					this.$refs.tsmsg.msgShow('请输入时间（运行时间为大于0的正整数）', 1.5)
					return
				}
				// let reg = /^[1-9]\d*$/
				// if (!reg.test(this.zxzscFrom.vtRunTime)) {
				// 	this.$refs.tsmsg.msgShow('输入大于零的整数', 1.5)
				// 	return
				// }
				if (this.isPositiveInteger(Number(this.vtRunTime))) {
				} else {
					this.$refs.tsmsg.msgShow('请输入正确的时间（运行时间为大于0的正整数）', 1.5)
					return
				}
				if (this.vtTimeType == '0') {
					duration = this.vtRunTime
					time = this.vtRunTime
				} else {
					duration = this.vtRunTime * 60
					time = this.vtRunTime * 60
				}
			}
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				operate: operate,
				duration: duration,
			}
			this.$http.postAction(this.$api.deviceFineTuning, params).then((res) => {
				if (res.code == 200) {
					if (this.controlItem.vtStop) {
						this.showcountdownUp = false
						this.xtInfoTxt = '请使用下方操作，完成您的微调。'
					} else {
						this.showcountdownUp = true
						this.xtInfoTxt = '您设定了' + time + '秒的' + vtFangs + '微调，请观察现场设备实际位置变化'
						this.$nextTick(() => {
							this.$refs.countdownUp.time = time
							this.$refs.countdownUp.Time()
						})
					}
					if (this.controlItem.vtRising) {
						this.controlItemDisable = {
							falling: false, //下降
							stop: false, //停止
							rising: false, //上升
							scram: false, //急停
							fineTuning: false, //微调
							back: true, //返回
							vtRising: false, //向上
							toTop: true, //顶复位
							vtStop: false, //微调停止
							vtFall: true, //向下
							toBottom: true, //低复位
						}
					} else {
						this.controlItemDisable = {
							falling: false, //下降
							stop: false, //停止
							rising: false, //上升
							scram: false, //急停
							fineTuning: false, //微调
							back: true, //返回
							vtRising: true, //向上
							toTop: true, //顶复位
							vtStop: false, //微调停止
							vtFall: false, //向下
							toBottom: true, //低复位
						}
					}
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
					this.deviceStatus()
				}
				this.menuisShow = true
				this.vtTimeType = '0'
				this.vtRunTime = ''
			})
		},
		// 设备复位
		aligned() {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				address: '',
			}
			if (this.controlItem.toTop) {
				params.address = '1'
			} else {
				params.address = '0'
			}
			this.$http.postAction(this.$api.aligned, params).then((res) => {
				if (res.code == 200) {
					this.controlItem = {
						falling: false, //下降
						stop: false, //停止
						rising: false, //上升
						scram: false, //急停
						fineTuning: false, //微调
						back: false, //返回
						vtRising: false, //向上
						toTop: false, //顶复位
						vtStop: true, //微调停止
						vtFall: false, //向下
						toBottom: false, //低复位
					}
					this.Information = false
					this.$refs.tsmsg.msgShow('操作成功', 1.5)
				} else {
					this.controlItem = {
						falling: false, //下降
						stop: false, //停止
						rising: false, //上升
						scram: false, //急停
						fineTuning: false, //微调
						back: false, //返回
						vtRising: false, //向上
						toTop: false, //顶复位
						vtStop: true, //微调停止
						vtFall: false, //向下
						toBottom: false, //低复位
					}
					this.xtInfoTxt = '请使用下方操作，完成您的微调。'
					this.Information = false
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
				}
			})
		},
		// 行程设备总时长自动设定功能
		automaticConfig(operate, direction) {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				operate: operate, //操作 0停止，1正向，2反向，4暂停，5继续
				direction: direction, //方向1 正向 ，2反向
			}
			this.$http.postAction(this.$api.automaticConfig, params).then((res) => {
				if (res.code == 200) {
					if (operate == 0) {
						if (direction == 1) {
							this.zxzscBtn = '-1'
							this.$refs.tsmsg8.msgShow('停止操作设置成功', 1.5)
						} else {
							this.fxzscBtn = '-1'
							this.$refs.tsmsg9.msgShow('停止操作设置成功', 1.5)
						}
					}
					if (operate == 1) {
						if (direction == 1) {
							this.$refs.tsmsg8.msgShow('正向操作设置成功', 1.5)
						} else {
							this.$refs.tsmsg9.msgShow('正向操作设置成功', 1.5)
						}
					}
					if (operate == 2) {
						if (direction == 1) {
							this.$refs.tsmsg8.msgShow('反向操作设置成功', 1.5)
						} else {
							this.$refs.tsmsg9.msgShow('反向操作设置成功', 1.5)
						}
					}
					if (operate == 4) {
						if (direction == 1) {
							this.$refs.tsmsg8.msgShow('暂停操作设置成功', 1.5)
						} else {
							this.$refs.tsmsg9.msgShow('暂停操作设置成功', 1.5)
						}
					}
					if (operate == 5) {
						if (direction == 1) {
							this.$refs.tsmsg8.msgShow('继续操作设置成功', 1.5)
						} else {
							this.$refs.tsmsg9.msgShow('继续操作设置成功', 1.5)
						}
					}
				} else {
					if (direction == 1) {
						this.$refs.tsmsg8.msgShow(res.msg, 1.5)
					} else {
						this.$refs.tsmsg9.msgShow(res.msg, 1.5)
					}
				}
			})
		},

		// 行程设备总时长微调操作
		xcscwtCaoZuo(operate, duration) {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				operate: operate,
				duration: duration,
			}
			this.$http.postAction(this.$api.deviceFineTuning, params).then((res) => {
				if (res.code == 200) {
					if (this.insideSwitch.zxzsc) {
						this.$refs.tsmsg8.msgShow('设置成功', 1.5)
					} else {
						this.$refs.tsmsg9.msgShow('设置成功', 1.5)
					}
				} else {
					if (this.insideSwitch.zxzsc) {
						this.$refs.tsmsg8.msgShow(res.msg, 1.5)
					} else {
						this.$refs.tsmsg9.msgShow(res.msg, 1.5)
					}
				}
			})
		},
		// 正向总时长状态设置
		zxzscStatusSz() {
			this.zxzscWeitiao = '-1' //-1未点击 0 下降 1 停止 2 上升
			this.equipmentInfo.itemList.forEach((item) => {
				if (item.dataItemCode == 'status') {
					if (item.dataItemValue == '') {
						this.controlItem.stop = true
						this.controlItemDisable.fineTuning = false
						return
					}
					this.equipmentInfo.commandsItemList.forEach((item2) => {
						if (item.dataItemValue == item2.commandsItemCode) {
							let commandsItemName = item2.commandsItemName
							console.log(commandsItemName)
							if (commandsItemName == '停止') {
								this.zxzscWeitiao = '1'
								this.zxdjsstop()
							}
							if (commandsItemName == '下降') {
								this.zxzscWeitiao = '0'
							}
							if (commandsItemName == '上升') {
								this.zxzscWeitiao = '2'
							}
						}
					})
				}
			})
		},
		// 反向总时长状态设置
		fxzscStatusSz() {
			this.fxzscWeitiao = '-1' //-1未点击 0 下降 1 停止 2 上升
			this.equipmentInfo.itemList.forEach((item) => {
				if (item.dataItemCode == 'status') {
					if (item.dataItemValue == '') {
						this.controlItem.stop = true
						this.controlItemDisable.fineTuning = false
						return
					}
					this.equipmentInfo.commandsItemList.forEach((item2) => {
						if (item.dataItemValue == item2.commandsItemCode) {
							let commandsItemName = item2.commandsItemName
							console.log(commandsItemName)
							if (commandsItemName == '停止') {
								this.fxzscWeitiao = '1'
								this.zxdjsstop()
							}
							if (commandsItemName == '下降') {
								this.fxzscWeitiao = '0'
							}
							if (commandsItemName == '上升') {
								this.fxzscWeitiao = '2'
							}
						}
					})
				}
			})
		},
		// 调整时间正向
		tiaozhengTime(time) {
			// 减
			if (this.zxzscWeitiao == '0') {
				this.zxzscFrom.tztime = this.zxzscFrom.tztime - 0 - 1
			}
			// 加
			if (this.zxzscWeitiao == '2') {
				this.zxzscFrom.tztime = this.zxzscFrom.tztime - 0 + 1
			}
		},
		// 调整时间反向
		tiaozhengTimeF(time) {
			// 加
			if (this.fxzscWeitiao == '0') {
				this.fxzscFrom.tztime = this.fxzscFrom.tztime - 0 + 1
			}
			// 减
			if (this.fxzscWeitiao == '2') {
				this.fxzscFrom.tztime = this.fxzscFrom.tztime - 0 - 1
			}
			console.log(this.fxzscFrom.tztime)
		},
		// 正向总时长需要时间调节
		zxTimeHaveSd(time) {
			console.log(time)
			console.log(this.zxzscFrom.tztime)
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				onTotalDuration: time,
			}
			this.$http.postAction(this.$api.setDuration, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg8.msgShow('设置成功', 1.5)
					// this.zxzscFrom.tztime = 0;
					// this.$http
					//   .getAction(
					//     this.$api.queryDuration +
					//       "?deviceNum=" +
					//       this.kzxInfo.deviceNum +
					//       "&subDeviceNum=" +
					//       this.equipmentInfo.subDeviceNum
					//   )
					//   .then((res) => {
					//     if (res.data && res.data.onTotalDuration) {
					//       this.zxzscFrom.zdhqTime = res.data.onTotalDuration;
					//     }
					//   });
				} else {
					this.$refs.tsmsg8.msgShow(res.msg, 1.5)
				}
			})
		},
		// 反向总时长需要时间调节
		fxTimeHaveSd(time) {
			console.log(time)
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				offTotalDuration: time,
			}
			this.$http.postAction(this.$api.setDuration, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg9.msgShow('设置成功', 1.5)
					// this.zxzscFrom.tztime = 0;
					// this.$http
					//   .getAction(
					//     this.$api.queryDuration +
					//       "?deviceNum=" +
					//       this.kzxInfo.deviceNum +
					//       "&subDeviceNum=" +
					//       this.equipmentInfo.subDeviceNum
					//   )
					//   .then((res) => {
					//     if (res.data && res.data.offTotalDuration) {
					//       this.fxzscFrom.zdhqTime = res.data.offTotalDuration;
					//     }
					//   });
				} else {
					this.$refs.tsmsg9.msgShow(res.msg, 1.5)
				}
			})
		},
		// 筛选执行条件
		queryRecord() {
			this.$http.postAction(this.$api.queryRecord, {}).then((res) => {
				if (res.data) {
					this.osList = res.data.osList
					this.orList = res.data.orList
					this.ywList = res.data.ywList
				}
			})
		},
		zzsj() {
			// 即可阻止点击按钮时触发input失去焦点事件
			event.preventDefault()
		},

		// input 只允许输入整数
		onlyNumber(num, type) {
			let n = String(num)
			const t = n.charAt(0)
			// 先把非数字的都替换掉，除了数字和.
			// n = n.replace(/[^\d\\.]/g, '')
			// 必须保证第一个为数字而不是.
			n = n.replace(/\./g, '').replace(/\-/g, '')
			// // 必须保证第一个数字非0
			n = n.replace(/^0(\d)/g, ($0, $1) => $1)
			// // 保证只有出现一个.而没有多个.
			// n = n.replace(/\.{2,}/g, '.')
			// // 保证.只出现一次，而不能出现两次以上
			// n = n.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
			n = n.substr(0, 4)
			// 如果第一位是负号，则允许添加
			if (t == '-') {
				n = '-' + n
			}
			this.automationbjFrom[type] = n
			return n
		},
		// 正方向总时长/单词运行/单次间隔 的设置
		timeNumber(num, type, keys) {
			let n = String(num)
			const t = n.charAt(0)
			// 先把非数字的都替换掉，除了数字和.
			// n = n.replace(/[^\d\\.]/g, '')
			// 必须保证第一个为数字而不是.
			n = n.replace(/\./g, '').replace(/\-/g, '')
			// // 必须保证第一个数字非0
			n = n.replace(/^0(\d)/g, ($0, $1) => $1)
			// // 保证只有出现一个.而没有多个.
			// n = n.replace(/\.{2,}/g, '.')
			// // 保证.只出现一次，而不能出现两次以上
			// n = n.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
			n = n.substr(0, 5)
			// 如果第一位是负号，则允许添加
			// if (t == '-') {
			// 	n = '-' + n
			// }
			this[type][keys] = n
			return n
		},
	},
}
