<template>
	<el-drawer custom-class="alarmConfiguration" title="告警配置" :visible.sync="drawer" size="23%" @close="drawerClose">
		<!-- 顶部盒子 -->
		<div class="drawe-top">
			<div class="img-box">
				<img src="@/assets/image/qx/icon_bg2.png" alt="" />
			</div>
			<div class="txt-box" :style="{ paddingTop: 30 + 'px', paddingBottom: 30 + 'px' }">
				<div class="tb-li doble" :style="{ height: 32 + 'px' }">
					<div class="flex flex_a_c" :style="{ fontSize: 16 + 'px' }" v-show="!bjInputShow">
						{{ deviceInfo.label }}
						<div class="bj-icon" @click="editorClick(deviceInfo.label)"></div>
					</div>
					<div class="input-div" v-show="bjInputShow">
						<el-input placeholder="请输入内容" v-model="bjInputValue">
							<i slot="suffix" class="suffix-content">
								<img src="../../../assets/image/equipment/icon-quxiao.png" alt="" @click.stop="qxeditor" />
								<img src="../../../assets/image/equipment/icon-queding.png" alt="" @click.stop="determineName" />
							</i>
						</el-input>
					</div>
				</div>
				<div class="tb-li">
					{{ deviceInfo.areaName }}
					<!-- <div class="line"></div> -->
					<!-- <span class="green">在线</span> -->
				</div>
			</div>
		</div>
		<!-- 菜单盒子 -->
		<div class="menu-box">
			<div class="menu-li" @click="menuClick(0)">
				<div class="li-l">告警配置</div>
			</div>
		</div>
		<!-- 里面潜逃的抽屉 -->
		<!-- 告警配置 -->
		<el-drawer
			title="告警配置"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.configuration"
			custom-class="insideDrawe flcu-box"
		>
			<!-- 添加盒子 -->
			<div class="addrc-box" @click="addAlter">
				<img src="@/assets/image/product/tianjia.png" alt="" />
				<div>点击添加告警配置</div>
			</div>
			<div class="line"></div>
			<div class="list-box" v-if="configurationList.length">
				<div class="item" v-for="(item, index) in configurationList" :key="index">
					<div class="top">
						{{ item.name }}
						<img v-if="item.status" src="@/assets/image/product/qiyong.png" alt="" @click="statusClick(index)" />
						<img v-else src="@/assets/image/product/jinyong.png" alt="" @click="statusClick(index)" />
					</div>
					<div class="bottom" v-html="item.content"></div>
				</div>
			</div>
			<div class="list-box-no" v-else>
				<img src="@/assets/image/home/ZWT.png" alt="" />
			</div>
			<!-- 确定按钮 -->
			<div class="btn-box">
				<div class="btn-item" v-if="configurationList.length" @click="insideSwitch.delete = true">删除</div>
			</div>
			<Msg ref="tsmsg1"></Msg>
		</el-drawer>
		<!-- 删除告警配置 -->
		<el-drawer
			title="告警配置"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.delete"
			custom-class="insideDrawe flcu-box"
		>
			<div class="flex flex_a_c flex_ju_sb font_16">
				<el-checkbox v-model="deleteIsAll" @change="deleteAllClick">全选</el-checkbox>
				<div class="font_16 c_45">已选{{ deleteNum }}项</div>
			</div>
			<!-- 删除列表 -->
			<div class="gj-delete-list">
				<div class="item" v-for="(item, index) in configurationList" :key="index">
					<div class="top">
						{{ item.name }}
						<el-checkbox v-model="item.isCheck"></el-checkbox>
					</div>
					<div class="bottom" v-html="item.content"></div>
				</div>
			</div>
			<!-- 确定按钮 -->
			<div class="btn-box">
				<div class="btn-item cancel" @click="insideSwitch.delete = false">取消</div>
				<div class="btn-item" @click="Information = true">删除</div>
			</div>
			<!-- 确定删除信息警告 -->
			<div class="Information" :class="{ 'Information-show': Information }">
				<div class="top">确定删除吗？</div>
				<div class="bottom">
					<div @click="Information = false">取消</div>
					<div class="line"></div>
					<div class="green" @click="deleteBtnClick">确定</div>
				</div>
			</div>
			<Msg ref="tsmsg2"></Msg>
		</el-drawer>
		<!-- 添加告警配置 -->
		<el-drawer
			title="告警配置"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.addGaojing"
			custom-class="insideDrawe flcu-box"
		>
			<div class="c_45">参数选择</div>
			<div class="gj-selbox">
				<el-select v-model="addGjFrom.csActq" placeholder="请选择传感器">
					<el-option
						v-for="item in TwTsName"
						:key="item.dataItemCode"
						:label="item.subDeviceName"
						:value="item.subDeviceNum"
					>
					</el-option>
				</el-select>
			</div>
			<div class="gj-selbox">
				<el-select v-model="addGjFrom.csAct" placeholder="请选择参数">
					<el-option
						v-for="item in csList"
						:key="item.dataItemCode"
						:label="item.dataItemName"
						:value="item.dataItemCode"
					>
					</el-option>
				</el-select>
			</div>

			<div class="gjsrbox" v-show="addGjFrom.csAct.length">
				<div>
					如果【<span class="green">{{ checkName }}</span
					>】
				</div>
				<div class="inpbox">
					高于
					<div class="input-box">
						<el-input
							v-model="addGjFrom.ceiling"
							placeholder="请输入数值"
							type="number"
							@input="onlyNumber($event, 'ceiling')"
						>
							<i slot="suffix" class="suffix-content"> {{ checkUNit }} </i>
						</el-input>
					</div>
				</div>
				<div>就执行【<span class="green">告警信息推送</span>】</div>
			</div>
			<div class="gjsrbox" v-show="addGjFrom.csAct.length">
				<div>
					如果【<span class="green">{{ checkName }}</span
					>】
				</div>
				<div class="inpbox">
					低于
					<div class="input-box">
						<el-input
							v-model="addGjFrom.lower"
							placeholder="请输入数值"
							type="number"
							@input="onlyNumber($event, 'lower')"
						>
							<i slot="suffix" class="suffix-content"> {{ checkUNit }} </i>
						</el-input>
					</div>
				</div>
				<div>就执行【<span class="green">告警信息推送</span>】</div>
			</div>
			<!-- 确定按钮 -->
			<div class="btn-box">
				<div class="btn-item cancel" @click="Information = true">取消</div>
				<div class="btn-item" @click="quedingAddbtnClick">确定</div>
			</div>
			<!-- 确定删除信息警告 -->
			<div class="Information" :class="{ 'Information-show': Information }">
				<div class="top">
					是否确定取消？<br />
					取消后数据将不会保存
				</div>
				<div class="bottom">
					<div @click="Information = false">取消</div>
					<div class="line"></div>
					<div class="green" @click="quxiaogjbj">确定</div>
				</div>
			</div>
			<Msg ref="tsmsg3"></Msg>
		</el-drawer>
	</el-drawer>
</template>

<script>
import Msg from '@/components/msg.vue' //信息提示组件
export default {
	components: { Msg },
	props: {
		TwTsName: {
			type: Array,
		},
	},
	data() {
		return {
			// 控制抽屉的开关
			drawer: false,
			// 控制里面抽屉的开关
			insideSwitch: {
				configuration: false, //告警配置
				delete: false, //删除
				addGaojing: false, //添加告警
			},
			// 设备编码
			deviceInfo: {},
			// 子设备编号
			subDeviceInfo: {},
			//   控制编辑输入框的显示
			bjInputShow: false,
			//   编辑输入框内容
			bjInputValue: '',
			// 控制警告信息
			Information: false,

			//告警配置列表
			configurationList: [
				// {
				//   name: "六合一传感器-空气温度",
				//   status: true,
				//   content: "如果【空气温度】高于【25.5】℃时<br/>就执行告警信息推送",
				//   isCheck: false,
				// },
				// {
				//   name: "六合一传感器-空气温度",
				//   status: false,
				//   content: "如果【空气温度】高于【25.5】℃时<br/>就执行告警信息推送",
				//   isCheck: false,
				// },
				// {
				//   name: "六合一传感器-空气温度",
				//   status: true,
				//   content:
				//     "如果【空气温度】高于【25.5】℃时<br/>就执行告警信息推送<br/>如果【空气温度】高于【25.5】℃时<br/>就执行告警信息推送",
				//   isCheck: false,
				// },
			],
			// 删除抽屉
			// 删除是否全选
			deleteIsAll: false,
			// 传感器列表
			cgqList: [
				{
					value: '选项1',
					label: '黄金糕',
				},
				{
					value: '选项2',
					label: '双皮奶',
				},
				{
					value: '选项3',
					label: '蚵仔煎',
				},
				{
					value: '选项4',
					label: '龙须面',
				},
				{
					value: '选项5',
					label: '北京烤鸭',
				},
			],
			// 参数列表
			csList: [
				// {
				//   value: "选项1",
				//   label: "黄金糕",
				// },
				// {
				//   value: "选项2",
				//   label: "双皮奶",
				// },
				// {
				//   value: "选项3",
				//   label: "蚵仔煎",
				// },
				// {
				//   value: "选项4",
				//   label: "龙须面",
				// },
				// {
				//   value: "选项5",
				//   label: "北京烤鸭",
				// },
			],
			addGjFrom: {
				cgqAct: '',
				csAct: '',
				ceiling: '',
				lower: '',
				csActq: '',
			},
			loding: false,
		}
	},
	computed: {
		// 删除已选择的数量
		deleteNum() {
			let arr = this.configurationList.filter((item) => {
				return item.isCheck == true
			})
			if (arr.length == this.configurationList.length) {
				this.deleteIsAll = true
			} else {
				this.deleteIsAll = false
			}
			return arr.length
		},
		// 选中的参数名字
		checkName() {
			let name = ''
			this.csList.forEach((item) => {
				if (item.dataItemCode == this.addGjFrom.csAct) {
					name = item.dataItemName
				}
			})
			return name
		},
		// 选中参数单位
		checkUNit() {
			let unit = ''
			this.csList.forEach((item) => {
				if (item.dataItemCode == this.addGjFrom.csAct) {
					unit = item.unitName
				}
			})
			return unit
		},
	},
	methods: {
		//添加告警配置
		addAlter() {
			this.addGjFrom = {
				cgqAct: '',
				csAct: '',
				ceiling: '',
				lower: '',
			}
			this.insideSwitch.addGaojing = true
		},
		// 点击编辑按钮
		editorClick(value) {
			this.bjInputValue = value
			this.bjInputShow = true
		},
		// 取消编辑
		qxeditor() {
			this.bjInputShow = false
		},
		// 确认编辑
		determineName() {
			this.bjInputShow = false
		},
		drawerClose() {
			this.drawer = false
		},
		// 菜单点击
		menuClick(index) {
			// 告警配置
			if (index == 0) {
				this.stAlarmConfList()
				this.insideSwitch.configuration = true
			}
		},
		// 列表项状态按钮点击
		statusClick(index) {
			// this.configurationList[index].status =
			//   !this.configurationList[index].status;
			let enable = !this.configurationList[index].status ? '1' : '0'
			let id = this.configurationList[index].id
			this.enableAlarmConf(id, enable)
		},
		// 删除全选点击
		deleteAllClick() {
			if (this.deleteIsAll) {
				this.configurationList.forEach((item, index) => {
					item.isCheck = true
				})
			} else {
				this.configurationList.forEach((item, index) => {
					item.isCheck = false
				})
			}
		},
		// 删除告警确认按钮点击
		deleteBtnClick() {
			this.removeAlarms()
		},
		// 确定取消告警配置
		quxiaogjbj() {
			this.Information = false
			this.insideSwitch.addGaojing = false
		},
		// 确定添加告警信息点击
		quedingAddbtnClick() {
			if (this.addGjFrom.csActq) {
				if (this.addGjFrom.csAct) {
					if (this.addGjFrom.ceiling) {
						if (this.addGjFrom.lower) {
							this.setAlarmConf()
						} else {
							this.$refs.tsmsg3.msgShow('请输入下限', 1.5)
							return
						}
					} else {
						this.$refs.tsmsg3.msgShow('请输入上限', 1.5)
						return
					}
				} else {
					this.$refs.tsmsg3.msgShow('请选择参数', 1.5)
					return
				}
			} else {
				this.$refs.tsmsg3.msgShow('请选择传感器', 1.5)
				return
			}
		},

		// 请求告警列表
		stAlarmConfList() {
			console.log(this.subDeviceInfo.subDeviceNum)
			let params = {
				deviceNum: this.deviceInfo.deviceNum,
				// subDeviceNumList: [this.subDeviceInfo.subDeviceNum],
				subDeviceNumList: [],
			}
			this.$http.postAction(this.$api.stAlarmConfList, params).then((res) => {
				// res = {
				//   code: 200,
				//   msg: "ok",
				//   data: [
				//     {
				//       id: "409",
				//       deviceNum: "22111700001",
				//       subDeviceNum: "1",
				//       subDeviceName: "六合一传感器",
				//       dataItemCode: "KQWD",
				//       unit: "℃",
				//       dataItemName: "空气温度",
				//       alarmUpper: "40",
				//       alarmLower: "30",
				//       effectiveState: "0",
				//     },
				//     {
				//       id: "410",
				//       deviceNum: "22111700001",
				//       subDeviceNum: "1",
				//       subDeviceName: "六合一传感器",
				//       dataItemCode: "KQWD",
				//       unit: "℃",
				//       dataItemName: "空气温度",
				//       alarmUpper: "3",
				//       alarmLower: "2",
				//       effectiveState: "1",
				//     },
				//     {
				//       id: "417",
				//       deviceNum: "22111700001",
				//       subDeviceNum: "1",
				//       subDeviceName: "六合一传感器",
				//       dataItemCode: "KQWD",
				//       unit: "℃",
				//       dataItemName: "空气温度",
				//       alarmUpper: "3",
				//       alarmLower: "2",
				//       effectiveState: "1",
				//     },
				//     {
				//       id: "509",
				//       deviceNum: "22111700001",
				//       subDeviceNum: "1",
				//       subDeviceName: "六合一传感器",
				//       dataItemCode: "KQSD",
				//       unit: "%RH",
				//       dataItemName: "空气湿度",
				//       alarmUpper: "40",
				//       alarmLower: "30",
				//       effectiveState: "1",
				//     },
				//   ],
				// };
				if (res.data.length) {
					let datas = res.data
					datas.forEach((item, index) => {
						// item.name = item.subDeviceName + "-" + item.dataItemName;
						item.name = item.subDeviceName + '-' + item.dataItemName
						item.status = item.effectiveState == '0' ? false : true
						item.isCheck = false
						item.content =
							'如果【' +
							item.dataItemName +
							'】高于' +
							'【' +
							item.alarmUpper +
							'】' +
							item.unit +
							'时<br/>就执行告警信息推送<br/>如果【' +
							item.dataItemName +
							'】低于' +
							'【' +
							item.alarmLower +
							'】' +
							item.unit +
							'时<br/>就执行告警信息推送'
					})
					this.configurationList = datas
				} else {
					this.configurationList = []
				}
			})
		},
		//告警设置-启用禁用
		enableAlarmConf(id, enable) {
			this.$http.postAction(this.$api.enableAlarmConf + '?id=' + id + '&enable=' + enable).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg1.msgShow('设置成功', 1.5)
					this.stAlarmConfList()
				} else {
					this.$refs.tsmsg1.msgShow(res.msg, 1.5)
				}
			})
		},
		// 告警设置-删除
		removeAlarms() {
			let params = {
				ids: [],
			}
			let arr = this.configurationList.filter((item, index) => {
				if (item.isCheck) {
					return item
				}
			})
			if (arr.length) {
				arr.forEach((item, index) => {
					params.ids.push(item.id)
				})
			} else {
				this.Information = false
				this.$refs.tsmsg2.msgShow('请选择要删除的告警信息', 1.5)
				return
			}
			this.$http.postAction(this.$api.removeAlarms, params).then((res) => {
				if (res.code == 200) {
					this.stAlarmConfList()
					this.Information = false
					this.$refs.tsmsg2.msgShow('删除成功', 1.5)
				} else {
					this.Information = false
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
				}
			})
		},
		// 添加告警配置
		setAlarmConf() {
			if (Number(this.addGjFrom.lower) >= Number(this.addGjFrom.ceiling)) {
				this.$refs.tsmsg3.msgShow('下限必须低于上限', 1.5)
				return
			}
			if (this.loding == false) {
				this.loding = true
				let params = {
					alarmLower: this.addGjFrom.lower,
					dataItemCode: this.addGjFrom.csAct,
					alarmUpper: this.addGjFrom.ceiling,
					deviceNum: this.deviceInfo.deviceNum,
					// subDeviceNum: this.subDeviceInfo.subDeviceNum,
					subDeviceNum: this.addGjFrom.csActq,
				}
				this.$http.postAction(this.$api.setAlarmConf, params).then((res) => {
					if (res.code == '200') {
						this.$refs.tsmsg3.msgShow('添加成功', 1.5)
						this.stAlarmConfList()
						// setTimeout(() => {
						this.insideSwitch.addGaojing = false
						this.loding = false
						// }, 1500)
					} else {
						this.$refs.tsmsg3.msgShow('添加失败\n' + res.msg, 1.5)
						this.loding = false
					}
				})
			} else {
			}
		},
		// input 只允许输入整数
		onlyNumber(num, type) {
			let n = String(num)
			const t = n.charAt(0)
			// 先把非数字的都替换掉，除了数字和.
			// n = n.replace(/[^\d\\.]/g, '')
			// 必须保证第一个为数字而不是.
			n = n.replace(/\./g, '').replace(/\-/g, '')
			// // 必须保证第一个数字非0
			n = n.replace(/^0(\d)/g, ($0, $1) => $1)
			// // 保证只有出现一个.而没有多个.
			// n = n.replace(/\.{2,}/g, '.')
			// // 保证.只出现一次，而不能出现两次以上
			// n = n.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
			n = n.substr(0, 4)
			// 如果第一位是负号，则允许添加
			if (t == '-') {
				n = '-' + n
			}
			this.addGjFrom[type] = n
			return n
		},
	},
}
</script>

<style lang="scss">
.alarmConfiguration {
	border-radius: 0.225rem 0px 0px 0.225rem;

	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		span {
			font-size: 0.225rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		display: flex;
		flex-direction: column;

		.btn-box {
			flex: 1;
			// position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			.btn-item {
				box-sizing: border-box;
				border: 0.0125rem solid #2daea9;
				cursor: pointer;
				width: 1.75rem;
				height: 0.45rem;
				background: #2daea9;
				border-radius: 0.05rem;
				text-align: center;
				line-height: 0.45rem;
				color: white;
			}
			.cancel {
				background: #fff;
				color: #2daea9;
			}
		}
		.drawe-top {
			display: flex;
			padding-bottom: 0.275rem;
			border-bottom: 1px solid rgba(241, 241, 241, 1);
			.img-box {
				width: 1.5rem;
				height: 1.5rem;
				background-color: rgba(45, 174, 169, 0.18);
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 40%;
				}
			}
			.txt-box {
				margin-left: 0.225rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				box-sizing: border-box;
				padding: 0.15rem 0;
				.tb-li {
					display: flex;
					font-size: 0.2rem;
					.line {
						width: 0.0125rem;
						height: 0.2rem;
						background: #bfbfbf;
						margin: 0 0.2rem;
					}
					.bj-icon {
						cursor: pointer;
						margin-left: 0.1875rem;
						width: 0.2rem;
						height: 0.2rem;
						background: url('../../../assets/image/product/icon-bj.png') no-repeat;
					}
					.input-div {
						width: 2.875rem;
						height: 0.4rem;
						.el-input {
							height: 100%;
						}
						.el-input__inner {
							border-radius: 0.1rem;
							height: 100%;
							font-weight: normal;
						}
						.el-input.is-active .el-input__inner,
						.el-input__inner:focus {
							border-color: rgba(45, 174, 169, 1);
						}
						.el-input__suffix {
							width: 4vw;
							height: 100%;
							.el-input__suffix-inner {
								display: block;
								width: 100%;
								height: 100%;
								display: flex;
								align-items: center;
								.suffix-content {
									display: flex;
									align-items: center;
									width: 100%;
									height: 1.099vw;
									box-sizing: border-box;
									border-left: 1px solid #f1f1f1;
									justify-content: space-around;
									padding: 0 0.1875rem;
									img {
										cursor: pointer;
										width: 0.175rem;
										height: 0.175rem;
									}
								}
							}
						}
					}
				}
				.doble {
					font-weight: bold;
				}
				.green {
					color: rgba(73, 201, 136, 1);
				}
				.blue {
					color: rgba(45, 174, 169, 1);
				}
			}
		}
		.menu-box {
			margin-top: 0.3rem;
			.menu-li {
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 0.2rem;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #454545;
				line-height: 0.2625rem;
				margin-bottom: 0.3rem;
				.blue {
					font-size: 0.175rem;
					color: rgba(45, 174, 169, 1);
					margin: 0 0.175rem;
				}
				&::after {
					content: '';
					width: 0.1125rem;
					height: 0.2rem;
					background: url('../../../assets/image/equipment/icon-open.png') no-repeat;
					background-size: 100% 100%;
				}
				.li-l {
					font-size: 0.2rem;
					img {
						margin-right: 0.25rem;
					}
				}
			}
			.b-line {
				height: 1px;
				background: #f1f1f1;
				margin-bottom: 0.3125rem;
			}
		}
	}
}
.insideDrawe {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		margin-bottom: 0.25rem;
		span {
			font-size: 0.225rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.flcu-box {
		display: flex;
		flex-direction: column;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		display: flex;
		flex-direction: column;
		position: relative;
		.gj-delete-list {
			margin-top: 0.3rem;
			height: 87%;
			overflow: auto;
			.item {
				padding: 0.2rem 0.25rem 0.275rem 0.125rem;
				background: #f1f9fa;
				border-radius: 0.125rem;
				margin-bottom: 0.3rem;
				.top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-left: 14px;
					padding-bottom: 0.2rem;
					border-bottom: 1px solid #fff;
					margin-bottom: 0.1rem;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #454545;
					img {
						cursor: pointer;
					}
				}
				.bottom {
					padding-left: 14px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #454545;
					line-height: 2;
					text-align: left;
				}
			}
			.item:last-child {
				margin-bottom: 0;
			}
		}
		.gj-selbox {
			margin-top: 0.2rem;
			margin-bottom: 0.3rem;
			height: 0.45rem;
			.el-select {
				width: 100%;
				height: 100%;
				.el-input__inner {
					width: 100%;
					height: 0.45rem;
					line-height: 0.45rem;
				}
				.el-input__icon {
					line-height: 0.45rem;
				}
				.el-icon-arrow-up:before {
					content: '\e78f';
				}
			}
		}
		.gjsrbox {
			margin-bottom: 0.3rem;
			height: 2.0375rem;
			background: #f1f9fa;
			border-radius: 0.125rem;
			box-sizing: border-box;
			padding: 0.3rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.green {
				color: rgba(45, 174, 169, 1);
			}
			.inpbox {
				display: flex;
				align-items: center;
				.input-box {
					flex: 1;
					height: 0.45rem;
					margin-left: 0.125rem;
					.el-input__inner {
						height: 0.45rem;
						line-height: 0.45rem;
					}
					.el-input__suffix {
						// width: 4vw;
						height: 100%;
						.el-input__suffix-inner {
							display: block;
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							.suffix-content {
								font-style: normal;
								display: flex;
								align-items: center;
								width: 100%;
								height: 1.099vw;
								box-sizing: border-box;
								border-left: 1px solid #f1f1f1;
								justify-content: space-around;
								padding: 0 0.1875rem;
								img {
									cursor: pointer;
									width: 0.175rem;
									height: 0.175rem;
								}
							}
						}
					}
				}
			}
		}
		.my-sel-box {
			position: relative;
			height: 0.45rem;
			margin-bottom: 0.3rem;
			.my-sel-header {
				width: 100%;
				height: 100%;
			}
			.change-centent {
				position: absolute;
				top: 0.475rem;
				left: 0;
				width: 100%;
				// height: 2.35rem;
				height: 0;
				overflow: hidden;
				background: #ffffff;
				box-sizing: border-box;

				border-radius: 0.05rem;
				transition: height 0.3s;
				.centent-box {
					width: 100%;
					height: 100%;
					box-sizing: border-box;
					padding-right: 4px;
					overflow: auto;
					display: flex;
					flex-wrap: wrap;
					//   justify-content: space-between;
					&::-webkit-scrollbar {
						width: 4px;
						height: 0px;
						background-color: transparent;
					}
					&::-webkit-scrollbar-track {
						border-radius: 4px;
						background-color: transparent;
					}
					&::-webkit-scrollbar-thumb {
						border-radius: 6px;
						background-color: rgba(45, 174, 169, 0.2);
					}
					.item {
						cursor: pointer;
						padding: 0 0.2rem;
						height: 0.45rem;
						line-height: 0.45rem;
						font-size: 0.2rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #666666;
						background: #ffffff;
						border: 1px solid #f1f1f1;
						border-radius: 4px;
						margin-right: 0.2rem;
					}
					.item-active {
						background: #2daea9;
						color: white;
					}
				}
			}
			.change-centent-show {
				height: 2.35rem;
				border: 1px solid #f1f1f1;
				box-shadow: 0px 3px 7px 0px rgba(45, 45, 45, 0.1);
				padding: 0.25rem 0.1rem 0.25rem 0.25rem;
			}
		}
		.rotating {
			transform: rotate(180deg);
		}
		.addrc-box {
			height: 1.1375rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			cursor: pointer;
			box-sizing: border-box;
			padding-top: 0.2rem;
			text-align: center;
			font-size: 0.2rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #2daea9;
			img {
				margin-bottom: 0.175rem;
			}
		}
		.line {
			height: 1px;
			background: #f1f1f1;
			margin: 0.3rem 0;
		}
		.list-box {
			height: 70%;
			overflow: auto;
			.item {
				padding: 0.2rem 0.25rem 0.275rem 0.125rem;
				background: #f1f9fa;
				border-radius: 0.125rem;
				margin-bottom: 0.3rem;
				.top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-left: 14px;
					padding-bottom: 0.2rem;
					border-bottom: 1px solid #fff;
					margin-bottom: 0.1rem;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #454545;
					img {
						cursor: pointer;
					}
				}
				.bottom {
					padding-left: 14px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #454545;
					line-height: 2;
					text-align: left;
				}
			}
			.item:last-child {
				margin-bottom: 0;
			}
		}
		.list-box-no {
			height: 70%;
			overflow: auto;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.btn-box {
			flex: 1;
			// position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			.btn-item {
				box-sizing: border-box;
				border: 0.0125rem solid #2daea9;
				cursor: pointer;
				width: 1.75rem;
				height: 0.45rem;
				background: #2daea9;
				border-radius: 0.05rem;
				text-align: center;
				line-height: 0.45rem;
				color: white;
			}
			.cancel {
				background: #fff;
				color: #2daea9;
			}
		}
	}
	.Information {
		position: absolute;
		z-index: 999999;
		top: 40%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) scale(0);
		width: 3.45rem;
		height: 2.075rem;
		background: #ffffff;
		box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		transition: all 0.3s;
		.top {
			box-sizing: border-box;
			height: 1.375rem;
			text-align: center;
			// line-height: 1.375rem;
			line-height: 1.5;
			border-bottom: 1px solid #f1f1f1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.bottom {
			display: flex;
			height: 0.6875rem;
			justify-content: space-around;
			align-items: center;
			padding: 0 0.3rem;
			& > div {
				cursor: pointer;
			}
			.line {
				width: 1px;
				height: 0.45rem;
				background: #f1f1f1;
			}
			.green {
				color: rgba(45, 174, 169, 1);
			}
		}
	}
	.Information-show {
		transform: translateX(-50%) translateY(-50%) scale(1);
	}
}
</style>
