<template>
	<el-drawer
		custom-class="deviceDrawer kgSetUp"
		:title="isfineTuning ? '微调操作' : '设置'"
		:visible.sync="drawer"
		size="23%"
		@close="drawerClose"
	>
		<!-- 顶部盒子 -->
		<div class="drawe-top">
			<div class="img-box">
				<img src="@/assets/image/product/xcsb_img.png" alt="" />
			</div>
			<div class="txt-box">
				<div class="tb-li doble" :style="{ height: 32 + 'px' }">
					<div class="flex flex_a_c" :style="{ fontSize: 16 + 'px' }" v-show="!bjInputShow">
						{{ equipmentInfo.subDeviceName }}
						<div class="bj-icon" @click="editorClick(equipmentInfo.subDeviceName)"></div>
					</div>
					<div class="input-div" v-show="bjInputShow">
						<el-input placeholder="请输入内容" v-model="bjInputValue" :maxlength="6">
							<i slot="suffix" class="suffix-content">
								<img src="@/assets/image/product/queding.png" alt="" @click.stop="determineName" />
								<img src="@/assets/image/product/quxiao.png" alt="" @click.stop="qxeditor" />
							</i>
						</el-input>
					</div>
				</div>
				<div class="tb-li">
					{{ kzxInfo.areaName }}
					<div class="line"></div>
					<span class="green"> <span v-if="kzxInfo.online">在线</span><span v-else>离线</span></span>
				</div>
				<div class="tb-li">
					设备状态：
					<template
						v-if="equipmentInfo.itemList[2].dataItemValue == '0' || equipmentInfo.itemList[2].dataItemValue == ''"
					>
						<span class="blue"
							><span
								v-if="equipmentInfo.itemList[0].dataItemValue == '0' || equipmentInfo.itemList[0].dataItemValue == ''"
								>当前位置{{ equipmentInfo.itemList[1].dataItemValue || 0 }}%</span
							>
							<span v-if="equipmentInfo.itemList[0].dataItemValue == '2'"
								>下降到{{ equipmentInfo.itemList[1].dataItemValue }}%</span
							>
							<span v-if="equipmentInfo.itemList[0].dataItemValue == '1'"
								>上升到{{ equipmentInfo.itemList[1].dataItemValue }}%</span
							></span
						>
					</template>
					<template v-else>
						<span class="red">已急停</span>
					</template>
				</div>
			</div>
		</div>
		<!-- 圆环进度条盒子 -->
		<div class="annulus-box" v-show="!isfineTuning">
			<!-- <div class="annulus-content"></div> -->
			<Ring ref="Ring" :operateDevice="operateDeviceTuo"></Ring>
		</div>
		<!-- 微调提示信息按钮 -->
		<div class="wt-tishibox" v-show="isfineTuning">
			<div class="jiqi-box"></div>
			<div class="tishi-box">
				<div class="tishi-content">
					{{ xtInfoTxt }}
					<div class="time" v-if="showcountdownUp">
						<CountdownUp ref="countdownUp"></CountdownUp>
					</div>
				</div>
			</div>
		</div>
		<!-- 控制设备按钮盒子 -->
		<div class="control-btn-box">
			<!-- 普通调整 -->
			<div class="btn-box" v-show="!isfineTuning">
				<div
					class="item mb18"
					:class="{
						itembggreen: controlItem.falling,
						'item-disable': controlItemDisable.falling,
					}"
					@click="controlBtnClick('falling')"
				>
					<img v-if="!controlItem.falling" src="@/assets/image/product/xiajiang.png" alt="" />
					<img v-else src="@/assets/image/product/xiajiangAct.png" alt="" />
					下降
				</div>
				<div
					class="item mb18"
					:class="{
						itembggreen: controlItem.stop,
						'item-disable': controlItemDisable.stop,
					}"
					@click="controlBtnClick('stop')"
				>
					<img v-if="!controlItem.stop" src="@/assets/image/product/tingzhi.png" alt="" />
					<img v-else src="@/assets/image/product/tingzhiAct.png" alt="" />
					停止
				</div>
				<div
					class="item mb18"
					:class="{
						itembggreen: controlItem.rising,
						'item-disable': controlItemDisable.rising,
					}"
					@click="controlBtnClick('rising')"
				>
					<img v-if="!controlItem.rising" src="@/assets/image/product/shangsheng.png" alt="" />
					<img v-else src="@/assets/image/product/shangshengAct.png" alt="" />
					上升
				</div>
				<div
					class="item"
					:class="{
						itembggreen: controlItem.fineTuning,
						'item-disable': controlItemDisable.fineTuning,
					}"
					@click="fineTuning"
					@mousedown="controlItem.fineTuning = true"
					@mouseup="controlItem.fineTuning = false"
				>
					<img v-if="!controlItem.fineTuning" src="@/assets/image/product/weitiao.png" alt="" />
					<img v-else src="@/assets/image/product/weitiaoAct.png" alt="" />
					微调
				</div>
				<div class="item orgeen" :class="{ itembgorg: controlItem.scram }" @click="controlBtnClick('scram')">
					<img v-if="!controlItem.scram" src="@/assets/image/product/jiting2.png" alt="" />
					<img v-else src="@/assets/image/product/jiting2Act.png" alt="" />
					<span v-if="!controlItem.scram">急停</span><span v-else>取消</span>
				</div>
			</div>
			<!-- 微调按钮 -->
			<div class="btn-box" v-show="isfineTuning">
				<div
					class="item mb18"
					:class="{
						itembggreen: controlItem.back,
						'item-disable': controlItemDisable.back,
					}"
					@click="clickBack"
					@mousedown="controlItem.back = true"
					@mouseup="controlItem.back = false"
				>
					<img v-if="!controlItem.back" src="@/assets/image/product/back.png" alt="" />
					<img v-else src="@/assets/image/product/backAct.png" alt="" />
					返回
				</div>
				<div
					class="item mb18"
					:class="{
						itembggreen: controlItem.vtRising,
						'item-disable': controlItemDisable.vtRising,
					}"
					@click="controlBtnClick('vtRising')"
				>
					<img v-if="!controlItem.vtRising" src="@/assets/image/product/xiangshangweitiao.png" alt="" />
					<img v-else src="@/assets/image/product/xiangshangweitiaoAct.png" alt="" />
					向上
				</div>
				<div
					class="item mb18 pading15"
					:class="{
						itembggreen: controlItem.toTop,
						'item-disable': controlItemDisable.toTop,
					}"
					@click="controlBtnClick('toTop')"
				>
					<img v-if="!controlItem.toTop" src="@/assets/image/product/dingfuwei.png" alt="" />
					<img v-else src="@/assets/image/product/dingfuweiAct.png" alt="" />
					顶复位
				</div>
				<div
					class="item"
					:class="{
						itembggreen: controlItem.vtStop,
						'item-disable': controlItemDisable.vtStop,
					}"
					@click="controlBtnClick('vtStop')"
				>
					<img v-if="!controlItem.vtStop" src="@/assets/image/product/tingzhi.png" alt="" />
					<img v-else src="@/assets/image/product/tingzhiAct.png" alt="" />
					停止
				</div>
				<div
					class="item"
					:class="{
						itembggreen: controlItem.vtFall,
						'item-disable': controlItemDisable.vtFall,
					}"
					@click="controlBtnClick('vtFall')"
				>
					<img v-if="!controlItem.vtFall" src="@/assets/image/product/xaingxiaweitiao.png" alt="" />
					<img v-else src="@/assets/image/product/xiangxiaweitiaoAct.png" alt="" />
					向下
				</div>
				<div
					class="item pading15"
					:class="{
						itembggreen: controlItem.toBottom,
						'item-disable': controlItemDisable.toBottom,
					}"
					@click="controlBtnClick('toBottom')"
				>
					<img v-if="!controlItem.toBottom" src="@/assets/image/product/difuwei.png" alt="" />
					<img v-else src="@/assets/image/product/difuweiAct.png" alt="" />
					底复位
				</div>
			</div>
		</div>
		<!-- 菜单盒子 -->
		<div class="menu-box" v-show="menuisShow">
			<div class="b-line"></div>
			<div class="menu-li" @click="menuClick(3)">
				<div class="li-l"><img src="@/assets/image/product/shichang.png" alt="" /> 时长保护</div>
			</div>
			<div class="menu-li" @click="menuClick(0)">
				<div class="li-l"><img src="@/assets/image/product/shijijan.png" alt="" /> 时间日程</div>
			</div>
			<div class="menu-li" @click="menuClick(1)">
				<div class="li-l"><img src="@/assets/image/product/zidonghua.png" alt="" /> 设备自动化</div>
			</div>
			<div class="menu-li" :style="{ marginBottom: 0 }" @click="menuClick(2)">
				<div class="li-l"><img src="@/assets/image/product/zhixingjilu.png" alt="" /> 执行记录</div>
			</div>
		</div>
		<div class="line" v-show="!menuisShow"></div>
		<!-- 微调设置的时间设置（向上 向下） -->
		<div class="prompts-title" v-show="!menuisShow">
			<span v-if="controlItem.vtRising">向上微调设置</span>
			<span v-else>向下微调设置</span>
		</div>
		<div class="vt-time-box" v-show="!menuisShow">
			时间类型：
			<el-radio-group v-model="vtTimeType">
				<el-radio label="0">秒</el-radio>
				<el-radio label="1">分钟</el-radio>
			</el-radio-group>
		</div>
		<div class="vt-time-box" v-show="!menuisShow">
			运行时间：
			<div class="input-box">
				<el-input v-model="vtRunTime" placeholder="请输入时间（运行时间为大于0的正整数）" type="number"></el-input>
			</div>
		</div>
		<div class="btn-box" v-show="!menuisShow">
			<div class="btn-item" @click="deviceFineTuning">确定</div>
		</div>
		<!-- 确定信息警告 -->
		<div class="Information" :class="{ 'Information-show': Information }">
			<div class="top">
				请确认现场设备实际已到达<span v-if="controlItem.toTop">顶部</span><span v-else>底部</span>位置
			</div>
			<div class="bottom">
				<div @click="alignedQuxiao">取消</div>
				<div class="line"></div>
				<div class="green" @click="aligned">确定</div>
			</div>
		</div>
		<!-- 里面潜逃的抽屉 -->
		<!-- 时长保护 -->
		<el-drawer
			title="时长保护"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.timeprotect"
			custom-class="insideDrawe flcu-box"
			@close="timeprotectClose"
		>
			<div slot="title" class="xgmm-box">
				时长保护
				<img @click="openchangPwd" src="@/assets/image/product/xiugaimima.png" alt="" />
			</div>
			<!-- 智能终端时长保护设置 -->
			<div class="znzd-timebaohu">
				<div class="item">
					<div class="top" @click="timebaphuMenuClick(0)">
						<div>
							单次运行与间隔<span
								v-if="timeproect.onceOperationDuration == '--' || timeproect.onceIntervalDuration == '--'"
								class="red"
								>（未设置）</span
							><span v-else class="green">（已设置）</span>
						</div>
					</div>
					<div
						class="bottom"
						v-if="timeproect.onceOperationDuration !== '--' || timeproect.onceIntervalDuration !== '--'"
					>
						<div class="item mb20">
							<div>单次运行时长</div>
							<div class="green">{{ timeproect.onceOperationDuration }}s</div>
						</div>
						<div class="item">
							<div>单次间隔时长</div>
							<div class="green">{{ timeproect.onceIntervalDuration }}s</div>
						</div>
					</div>
				</div>
				<div class="line"></div>
				<div class="item">
					<div class="top" @click="timebaphuMenuClick(1)">
						<div>
							正向总时长<span v-if="timeproect.onTotalDuration == '--'" class="red">（未设置）</span
							><span v-else class="green">（已设置）</span>
						</div>
					</div>
					<div class="bottom" v-if="timeproect.onTotalDuration !== '--'">
						<div class="item">
							<div>时长</div>
							<div class="green">{{ timeproect.onTotalDuration }}s</div>
						</div>
					</div>
				</div>
				<div class="line"></div>
				<div class="item" @click="timebaphuMenuClick(2)">
					<div class="top">
						<div>
							反向总时长<span v-if="timeproect.offTotalDuration == '--'" class="red">（未设置）</span
							><span v-else class="green">（已设置）</span>
						</div>
					</div>
					<div class="bottom" v-if="timeproect.offTotalDuration !== '--'">
						<div class="item">
							<div>时长</div>
							<div class="green">{{ timeproect.offTotalDuration }}s</div>
						</div>
					</div>
				</div>
			</div>
			<Msg ref="tsmsg10"></Msg>
		</el-drawer>
		<!-- 单次运行与间隔 -->
		<el-drawer
			title="单次运行与间隔"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.protect"
			custom-class="insideDrawe flcu-box dcyxjg"
		>
			<div class="prompts-title">单次运行时长</div>
			<div class="scbh-input">
				<el-input
					v-model="protectFrom.onceOperationDuration"
					placeholder="请输入时间"
					type="number"
					@input="timeNumber($event, 'protectFrom', 'onceOperationDuration')"
				>
					<i slot="suffix" class="suffix-content"> 秒</i>
				</el-input>
			</div>
			<div class="warm-txt">(设备持续运行过程中，单次运行所消耗的时间)</div>
			<div class="line"></div>
			<div class="prompts-title">单次间隔时长</div>
			<div class="scbh-input">
				<el-input
					v-model="protectFrom.onceIntervalDuration"
					placeholder="请输入时间"
					type="number"
					@input="timeNumber($event, 'protectFrom', 'onceIntervalDuration')"
				>
					<i slot="suffix" class="suffix-content"> 秒</i>
				</el-input>
			</div>
			<div class="warm-txt">(设备持续运行过程中，单次运行结束到下次运行开始的时间间隔)</div>
			<!-- 确定按钮 -->
			<div class="btn-box">
				<div class="btn-item" @click="setDuration">确定</div>
			</div>
			<Msg ref="tsmsg4"></Msg>
		</el-drawer>
		<!-- 正向总时长 -->
		<el-drawer
			title="正向总时长 "
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.zxzsc"
			custom-class="insideDrawe flcu-box"
			@close="zxzscClose"
			:before-close="zxzscbeforeClose"
		>
			<div class="znzd-tab">
				<div class="item" :class="{ itemAct: zxzscTab == 0 }" @click="zxzscmenuClick(0)">自动获取</div>
				<div
					class="item"
					:class="{
						itemAct: zxzscTab == 1,
						'item-disable': zxzscBtn == '0' || zxzscBtn == '1',
					}"
					@click="zxzscmenuClick(1)"
				>
					手动设置
				</div>
			</div>
			<!-- 自动获取盒子 -->
			<div class="zsc-box" v-show="zxzscTab == 0">
				<div class="top-alltime-box">
					<div>正向总时长</div>
					<span class="green">
						<span v-if="zxzscFrom.zdhqTime == ''"
							>--s
							<span v-if="zxzscFrom.tztime > 0">+{{ zxzscFrom.tztime }}s</span>
							<span v-if="zxzscFrom.tztime < 0">{{ zxzscFrom.tztime }}s</span>
						</span>
						<span v-else
							>{{ zxzscFrom.zdhqTime }}s
							<span v-if="zxzscFrom.tztime > 0">+{{ zxzscFrom.tztime }}s</span>
							<span v-if="zxzscFrom.tztime < 0">{{ zxzscFrom.tztime }}s</span>
						</span>
					</span>
				</div>
				<div class="tishibtn-box">
					<div class="top">
						<div class="img-box">
							<img v-if="getzxzsctype == '0'" src="@/assets/image/product/zidonghuoqu.gif" alt="" />
							<img v-else src="@/assets/image/product/robot1.png" alt="" />

							<span class="green" v-show="getzxzsctype == '0'">获取中</span>
							<span class="green" v-show="getzxzsctype == '1'">已暂停</span>
						</div>
						<div class="right-box">
							<div class="txt">温馨提示：开始操作前，请确保设备处于示意图标记位置(位于最底端)</div>
							<img src="@/assets/image/product/moxing.png" alt="" />
						</div>
					</div>
					<div class="line"></div>
					<div class="btn-box">
						<!-- 继续获取 -->
						<div v-if="zxzscBtn == 1" class="item" :class="{ 'item-act': zxzscBtn == '0' }" @click="zxzscBtnClick('5')">
							开始获取
						</div>
						<div v-else class="item" :class="{ 'item-act': zxzscBtn == '0' }" @click="zxzscBtnClick('0')">开始获取</div>
						<div
							class="item"
							v-show="zxzscBtn !== '-1'"
							:class="{
								'item-act': zxzscBtn == '1',
								'item-disable': zxzscBtn == '-1',
							}"
							@click="zxzscBtnClick('1')"
						>
							暂停获取
						</div>
						<div
							class="item"
							:class="{
								'item-act': zxzscBtn == '2',
								'item-disable': zxzscBtn == '-1',
							}"
							@click="zxzscBtnClick('2')"
						>
							结束获取
						</div>
					</div>
				</div>
				<div class="wzposition-box" :class="{ 'wzposition-box-noclick': zxzscBtn !== '1' }" v-show="!zxzscShowInput">
					<div class="header">
						设备位置调整
						<div class="tool-box">
							<img src="@/assets/image/product/tihsi.png" alt="" />
							<div class="txt-box">
								1、设备自动获取时间，现场设备满足要求时，无需进行设备位置调整，系统将根据您结束获取后，获得总时长
								2、现场设备位置不满足要求时，先暂停自动设备获取时间，此时您可以对设备进行上升、下降微调以及停止操作；微调结束后系统将根据您微调操作自动对时间进行计算，获得总时长
							</div>
						</div>
					</div>
					<div class="countdown-box">
						<div class="">位置调整计时：</div>
						<div class="time">
							<Countdown ref="countdown"></Countdown>
						</div>
						<img @click="zxdjsCz" src="@/assets/image/product/chongzhi.png" alt="" />
					</div>
					<div class="btn-box">
						<div
							class="item"
							:class="{
								'item-act': zxzscWeitiao == '0',
								'item-disable': zxzscWeitiao == '2',
							}"
							@click="zxzscWeitiaoClick('0')"
						>
							<img v-if="zxzscWeitiao == '0'" src="@/assets/image/product/zscxiajiangAct.png" alt="" />
							<img v-else src="@/assets/image/product/zscxiajiang.png" alt="" />
							下降
						</div>
						<div class="item" :class="{ 'item-act': zxzscWeitiao == '1' }" @click="zxzscWeitiaoClick('1')">
							<img v-if="zxzscWeitiao == '1'" src="@/assets/image/product/zsctingzhiAct.png" alt="" />
							<img v-else src="@/assets/image/product/zsctingzhi.png" alt="" />停止
						</div>
						<div
							class="item"
							:class="{
								'item-act': zxzscWeitiao == '2',
								'item-disable': zxzscWeitiao == '0',
							}"
							@click="zxzscWeitiaoClick('2')"
						>
							<img v-if="zxzscWeitiao == '2'" src="@/assets/image/product/zscshangshengAct.png" alt="" />
							<img v-else src="@/assets/image/product/zscshangsheng.png" alt="" />上升
						</div>
					</div>
				</div>
				<div class="wzposition-box" v-show="zxzscShowInput">
					<div class="header flex_ju_sb">
						<div v-if="zxzscWeitiao == '0'">向下微调设置</div>
						<div v-if="zxzscWeitiao == '2'">向上微调设置</div>
						<div class="green" @click="zxTrueClick">确定</div>
					</div>
					<div class="time-type">
						<div class="flex-l">时间类型：</div>
						<el-radio-group v-model="zxzscFrom.timeType">
							<el-radio label="0">秒</el-radio>
							<el-radio label="1">分钟</el-radio>
						</el-radio-group>
					</div>
					<div class="time-type">
						<div class="flex-l">运行时间：</div>
						<div class="inputbox">
							<el-input v-model="zxzscFrom.inputTime" type="number" placeholder="请输入时间"></el-input>
						</div>
					</div>
					<div class="time-type mt0">
						<div class="flex-l"></div>
						<div class="flex-r">（运行时间为大于0的正整数）</div>
					</div>
				</div>
			</div>
			<!-- 手动设置盒子 -->
			<div class="zsc-box" v-show="zxzscTab == 1">
				<div class="zsctishi-box">
					<div class="jiqi-box"></div>
					<div class="txt-box">
						<div class="tishi-content">设备从最底端持续运行到最顶端，所消耗的总时间</div>
					</div>
				</div>
				<div class="scbh-input">
					<el-input
						type="number"
						v-model="zxzscFrom.sdTime"
						placeholder="请输入合适的时间 "
						@input="timeNumber($event, 'zxzscFrom', 'sdTime')"
					>
						<i slot="suffix" class="suffix-content"> 秒</i>
					</el-input>
				</div>
				<span class="zscspantishi">注意：请确保您的设备已位于最顶端</span>
				<!-- 确定按钮 -->
				<div class="btn-box">
					<div class="btn-item" @click="sdszzxzsc">确定</div>
				</div>
			</div>
			<Msg ref="tsmsg8"></Msg>
		</el-drawer>
		<!-- 反向总时长 -->
		<el-drawer
			title="反向总时长 "
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.fxzsc"
			custom-class="insideDrawe flcu-box"
			@close="fxzscColse"
			:before-close="fxzscbeforeClose"
		>
			<div class="znzd-tab">
				<div class="item" :class="{ itemAct: fxzscTab == 0 }" @click="fxzscmenuClick(0)">自动获取</div>
				<div
					class="item"
					:class="{
						itemAct: fxzscTab == 1,
						'item-disable': fxzscBtn == '0' || fxzscBtn == '1',
					}"
					@click="fxzscmenuClick(1)"
				>
					手动设置
				</div>
			</div>
			<!-- 自动获取盒子 -->
			<div class="zsc-box" v-show="fxzscTab == 0">
				<div class="top-alltime-box">
					<div>反向总时长</div>
					<span class="green">
						<span v-if="fxzscFrom.zdhqTime == ''"
							>--s
							<span v-if="fxzscFrom.tztime > 0">+{{ fxzscFrom.tztime }}s</span>
							<span v-if="fxzscFrom.tztime < 0">{{ fxzscFrom.tztime }}s</span>
						</span>
						<span v-else
							>{{ fxzscFrom.zdhqTime }}s
							<span v-if="fxzscFrom.tztime > 0">+{{ fxzscFrom.tztime }}s</span>
							<span v-if="fxzscFrom.tztime < 0">{{ fxzscFrom.tztime }}s</span>
						</span>
					</span>
				</div>
				<div class="tishibtn-box">
					<div class="top">
						<div class="img-box">
							<img v-if="getfxzsctype == '0'" src="@/assets/image/product/zidonghuoqu.gif" alt="" />
							<img v-else src="@/assets/image/product/robot1.png" alt="" />

							<span class="green" v-show="getfxzsctype == '0'">获取中</span>
							<span class="green" v-show="getfxzsctype == '1'">已暂停</span>
						</div>
						<div class="right-box">
							<div class="txt">温馨提示：开始操作前，请确保设备处于示意图标记位置(位于最顶端)</div>
							<img src="@/assets/image/product/fanxiang.png" alt="" />
						</div>
					</div>
					<div class="line"></div>
					<div class="btn-box">
						<!-- 继续获取 -->
						<div v-if="fxzscBtn == 1" class="item" :class="{ 'item-act': fxzscBtn == '0' }" @click="fxzscBtnClick('5')">
							开始获取
						</div>
						<div v-else class="item" :class="{ 'item-act': fxzscBtn == '0' }" @click="fxzscBtnClick('0')">开始获取</div>
						<div
							class="item"
							v-show="fxzscBtn !== '-1'"
							:class="{
								'item-act': fxzscBtn == '1',
								'item-disable': fxzscBtn == '-1',
							}"
							@click="fxzscBtnClick('1')"
						>
							暂停获取
						</div>
						<div
							class="item"
							:class="{
								'item-act': fxzscBtn == '2',
								'item-disable': fxzscBtn == '-1',
							}"
							@click="fxzscBtnClick('2')"
						>
							结束获取
						</div>
					</div>
				</div>
				<div class="wzposition-box" :class="{ 'wzposition-box-noclick': fxzscBtn !== '1' }" v-show="!fxzscShowInput">
					<div class="header">
						设备位置调整
						<div class="tool-box">
							<img src="@/assets/image/product/tihsi.png" alt="" />
							<div class="txt-box">
								1、设备自动获取时间，现场设备满足要求时，无需进行设备位置调整，系统将根据您结束获取后，获得总时长
								2、现场设备位置不满足要求时，先暂停自动设备获取时间，此时您可以对设备进行上升、下降微调以及停止操作；微调结束后系统将根据您微调操作自动对时间进行计算，获得总时长
							</div>
						</div>
					</div>
					<div class="countdown-box">
						<div class="">位置调整计时：</div>
						<div class="time">
							<Countdown ref="countdown"></Countdown>
						</div>
						<img @click="fxdjsCz" src="@/assets/image/product/chongzhi.png" alt="" />
					</div>
					<div class="btn-box">
						<div
							class="item"
							:class="{
								'item-act': fxzscWeitiao == '0',
								'item-disable': fxzscWeitiao == '2',
							}"
							@click="fxzscWeitiaoClick('0')"
						>
							<img v-if="fxzscWeitiao == '0'" src="@/assets/image/product/zscxiajiangAct.png" alt="" />
							<img v-else src="@/assets/image/product/zscxiajiang.png" alt="" />
							下降
						</div>
						<div class="item" :class="{ 'item-act': fxzscWeitiao == '1' }" @click="fxzscWeitiaoClick('1')">
							<img v-if="fxzscWeitiao == '1'" src="@/assets/image/product/zsctingzhiAct.png" alt="" />
							<img v-else src="@/assets/image/product/zsctingzhi.png" alt="" />停止
						</div>
						<div
							class="item"
							:class="{
								'item-act': fxzscWeitiao == '2',
								'item-disable': fxzscWeitiao == '0',
							}"
							@click="fxzscWeitiaoClick('2')"
						>
							<img v-if="fxzscWeitiao == '2'" src="@/assets/image/product/zscshangshengAct.png" alt="" />
							<img v-else src="@/assets/image/product/zscshangsheng.png" alt="" />上升
						</div>
					</div>
				</div>
				<div class="wzposition-box" v-show="fxzscShowInput">
					<div class="header flex_ju_sb">
						<div v-if="fxzscWeitiao == '0'">向下微调设置</div>
						<div v-if="fxzscWeitiao == '2'">向上微调设置</div>
						<div class="green" @click="fxTrueClick">确定</div>
					</div>
					<div class="time-type">
						<div class="flex-l">时间类型：</div>
						<el-radio-group v-model="fxzscFrom.timeType">
							<el-radio label="0">秒</el-radio>
							<el-radio label="1">分钟</el-radio>
						</el-radio-group>
					</div>
					<div class="time-type">
						<div class="flex-l">运行时间：</div>
						<div class="inputbox">
							<el-input v-model="fxzscFrom.inputTime" type="number" placeholder="请输入时间"></el-input>
						</div>
					</div>
					<div class="time-type mt0">
						<div class="flex-l"></div>
						<div class="flex-r">（运行时间为大于0的正整数）</div>
					</div>
				</div>
			</div>
			<!-- 手动设置盒子 -->
			<div class="zsc-box" v-show="fxzscTab == 1">
				<div class="zsctishi-box">
					<div class="jiqi-box"></div>
					<div class="txt-box">
						<div class="tishi-content">设备从最定端持续运行到最底端，所消耗的总时间</div>
					</div>
				</div>
				<div class="scbh-input">
					<el-input
						type="number"
						v-model="fxzscFrom.sdTime"
						placeholder="请输入合适的时间 "
						@input="timeNumber($event, 'fxzscFrom', 'sdTime')"
					>
						<i slot="suffix" class="suffix-content"> 秒</i>
					</el-input>
				</div>
				<span class="zscspantishi">注意：请确保您的设备已位于最顶端</span>
				<!-- 确定按钮 -->
				<div class="btn-box">
					<div class="btn-item" @click="sdszfxzsc">确定</div>
				</div>
			</div>
			<Msg ref="tsmsg9"></Msg>
		</el-drawer>
		<!-- 修改安全密码 -->
		<el-drawer
			title="修改安全密码"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.changPwd"
			custom-class="insideDrawe flcu-box"
			@close="changPwdClose"
		>
			<div class="c_45">原安全密码：</div>
			<div class="xgmm-input">
				<el-input
					v-model="xiugaiPwdFrom.oldPwd"
					placeholder="请输入原安全密码"
					:maxlength="6"
					:type="xiugaiPwdType.oldPwd ? 'password' : 'text'"
				>
					<i
						slot="suffix"
						:class="xiugaiPwdType.oldPwd ? 'eyen-icon' : 'eye-icon'"
						@mousedown="zzsj"
						@click="xgpwdEyeClick('oldPwd')"
					></i>
				</el-input>
			</div>
			<div class="c_45">新安全密码：</div>
			<div class="xgmm-input">
				<el-input
					v-model="xiugaiPwdFrom.newPwd"
					placeholder="请输入新安全密码"
					:maxlength="6"
					:type="xiugaiPwdType.newPwd ? 'password' : 'text'"
				>
					<i
						slot="suffix"
						:class="xiugaiPwdType.newPwd ? 'eyen-icon' : 'eye-icon'"
						@mousedown="zzsj"
						@click="xgpwdEyeClick('newPwd')"
					></i>
				</el-input>
			</div>
			<div class="c_45">确认新安全密码：</div>
			<div class="xgmm-input">
				<el-input
					v-model="xiugaiPwdFrom.againNewPwd"
					placeholder="请再次输入新安全密码"
					:maxlength="6"
					:type="xiugaiPwdType.againNewPwd ? 'password' : 'text'"
				>
					<i
						slot="suffix"
						:class="xiugaiPwdType.againNewPwd ? 'eyen-icon' : 'eye-icon'"
						@mousedown="zzsj"
						@click="xgpwdEyeClick('againNewPwd')"
					></i>
				</el-input>
			</div>
			<!-- 确定按钮 -->
			<div class="btn-box">
				<div class="btn-item" @click="updateSafePassword">确定</div>
			</div>
			<Msg ref="tsmsg5"></Msg>
		</el-drawer>
		<!-- 安全密码认证 -->
		<el-drawer
			title="安全密码认证"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.certification"
			custom-class="insideDrawe flcu-box"
			@close="certificationClose"
		>
			<div class="rz-tishibox">
				<div class="jiqi-box"></div>
				<div class="right-box">请在下方输入正确的安全密码，进行认证</div>
				<div class="wjmm" @click="insideSwitch.wjaqmm = true">忘记密码></div>
			</div>
			<div class="mminput-box flexbox">
				<div class="code-item" :class="codeValue.length == 0 && inputFocus ? 'code-item-active' : ''">
					<template v-if="codeValue[0]">●</template>
				</div>
				<div class="code-item" :class="codeValue.length == 1 && inputFocus ? 'code-item-active' : ''">
					<template v-if="codeValue[1]">●</template>
				</div>
				<div class="code-item" :class="codeValue.length == 2 && inputFocus ? 'code-item-active' : ''">
					<template v-if="codeValue[2]">●</template>
				</div>
				<div class="code-item" :class="codeValue.length == 3 && inputFocus ? 'code-item-active' : ''">
					<template v-if="codeValue[3]">●</template>
				</div>
				<div class="code-item" :class="codeValue.length == 4 && inputFocus ? 'code-item-active' : ''">
					<template v-if="codeValue[4]">●</template>
				</div>
				<div class="code-item" :class="codeValue.length >= 5 && inputFocus ? 'code-item-active' : ''">
					<template v-if="codeValue[5]">●</template>
				</div>
				<el-input
					class="input-code"
					v-model="codeValue"
					maxlength="6"
					@blur="codeInputBlur"
					@focus="codeInputFocus"
					@input="codeValueInputChange"
					oninput="if(value.length > 6) value = value.slice(0,6)"
					ref="myinput"
					type="number"
				>
				</el-input>
			</div>
			<div class="btn-box">
				<div class="btn-item" @click="mmrzClick">确定</div>
			</div>
			<Msg ref="tsmsg3"></Msg>
		</el-drawer>
		<!-- 忘记安全密码 -->
		<el-drawer
			title="忘记安全密码"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.wjaqmm"
			custom-class="insideDrawe flcu-box"
			@close="wjaqmmClose"
		>
			<div class="c_45">登录密码：</div>
			<div class="xgmm-input">
				<el-input
					v-model="wjaqmmFrom.Pwd"
					placeholder="请输入登录密码"
					:maxlength="8"
					:type="wjaqmmPwdType.Pwd ? 'password' : 'text'"
				>
					<i
						slot="suffix"
						:class="wjaqmmPwdType.Pwd ? 'eyen-icon' : 'eye-icon'"
						@mousedown="zzsj"
						@click="wjpwdEyeClick('Pwd')"
					></i>
				</el-input>
			</div>
			<div class="c_45">新安全密码：</div>
			<div class="xgmm-input">
				<el-input
					v-model="wjaqmmFrom.newPwd"
					placeholder="请输入新安全密码"
					:maxlength="6"
					:type="wjaqmmPwdType.newPwd ? 'password' : 'text'"
				>
					<i
						slot="suffix"
						:class="wjaqmmPwdType.newPwd ? 'eyen-icon' : 'eye-icon'"
						@mousedown="zzsj"
						@click="wjpwdEyeClick('newPwd')"
					></i>
				</el-input>
			</div>
			<div class="c_45">确认新安全密码：</div>
			<div class="xgmm-input">
				<el-input
					v-model="wjaqmmFrom.againNewPwd"
					placeholder="请再次输入新安全密码"
					:maxlength="6"
					:type="wjaqmmPwdType.againNewPwd ? 'password' : 'text'"
				>
					<i
						slot="suffix"
						:class="wjaqmmPwdType.againNewPwd ? 'eyen-icon' : 'eye-icon'"
						@mousedown="zzsj"
						@click="wjpwdEyeClick('againNewPwd')"
					></i>
				</el-input>
			</div>
			<!-- 确定按钮 -->
			<div class="btn-box">
				<div class="btn-item" @click="wjaqmmQDClick">确定</div>
			</div>
			<Msg ref="tsmsgwjmm"></Msg>
		</el-drawer>
		<!-- 时间日程 -->
		<el-drawer
			title="时间日程"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.schedule"
			custom-class="insideDrawe flcu-box"
		>
			<!-- 添加日程盒子 -->
			<div class="addrc-box" @click="openBjschedule(false)">
				<img src="@/assets/image/product/tianjia.png" alt="" />
				<div>点击添加时间日程</div>
			</div>
			<div class="line"></div>
			<!-- 列表 -->
			<div class="schedule-list roll-auto" v-if="scheduleList.length">
				<div class="item" v-for="(item, index) in scheduleList" :key="index">
					<div class="item-header">
						<div class="flex header-left" :class="item.executionCycle == '2' ? '' : 'flex_a_c'">
							<img v-if="item.executionCycle == '1'" src="@/assets/image/product/shizhong.png" alt="" />
							<img v-if="item.executionCycle == '2'" src="@/assets/image/product/meizhou.png" alt="" />
							<img v-if="item.executionCycle == '4'" src="@/assets/image/product/rili.png" alt="" />
							<span v-if="item.executionCycle == '1'">
								<span v-if="item.exerciseDate == '1'">每天</span>
								<span v-else
									>每天 <span class="gray">(每隔{{ item.exerciseDate - 1 }}天)</span></span
								>
							</span>
							<!-- 周几,日期较长 -->
							<div v-if="item.executionCycle == '2'" class="schedule-weeks">
								{{ item.date }}
							</div>
							<span v-if="item.executionCycle == '4'">
								{{ item.exerciseDate }}
							</span>
						</div>
						<div class="header-right">
							<img
								v-if="item.effectiveState == '1'"
								src="@/assets/image/product/qiyong.png"
								alt=""
								@click="statusClick(item)"
							/>
							<img v-else src="@/assets/image/product/jinyong.png" alt="" @click="statusClick(item)" />
						</div>
					</div>

					<div class="item-top">
						<div class="lie-1">
							<div>
								时间：<span>{{ item.time | TimeDrop00 }}</span>
							</div>
							<div>
								动作：
								<!-- <span v-if="item.action">打开</span
                ><span v-else>关闭</span> -->
								{{ item.action }}
							</div>
						</div>
						<div class="lie-1">
							<div>是否生效：<span v-if="item.effectiveState == '1'">已生效</span><span v-else>未生效</span></div>
						</div>
					</div>
					<div class="item-bottom">
						<!-- <div
              class="cz-div blue"
              v-if="!item.iseffect"
              @click="timeConfigEnable(item.id)"
            >
              <img src="@/assets/image/product/xiafa.png" alt="" />
              下发
            </div>
            <div class="cz-line" v-if="!item.iseffect"></div> -->
						<div class="cz-div green" @click="openBjschedule(true, item)">
							<img src="@/assets/image/product/icon-bj.png" alt="" />
							编辑
						</div>
						<div class="cz-line"></div>
						<div class="cz-div red" @click="openscsjrcqrxx(item)">
							<img src="@/assets/image/product/icon-sc.png" alt="" />
							删除
						</div>
					</div>
				</div>
			</div>
			<div class="schedule-list-no" v-else>
				<img src="@/assets/image/home/ZWT.png" alt="" />
			</div>
			<!-- 编辑时间日程 -->
			<el-drawer
				title="时间日程"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.bjschedule"
				custom-class="insideDrawe flcu-box"
				@close="bjscheduleClose"
			>
				<div class="prompts-title">时间选择</div>
				<div class="flex flex_a_c" :style="{ marginTop: 21 + 'px' }">
					时间选择：
					<div class="time-box">
						<el-time-picker
							v-model="timercFrom.time"
							placeholder="任意时间点"
							format="HH:mm"
							value-format="HH:mm"
							:clearable="false"
						>
							<!-- <i slot="suffix" class="el-input__icon el-icon-date"></i> -->
						</el-time-picker>
					</div>
				</div>
				<div class="time-type-box">
					<el-radio-group v-model="timercFrom.timeType">
						<div class="time-type-li" @click="timercFrom.timeType = '1'">
							<div class="li-head">每天<el-radio label="1"></el-radio></div>
							<div class="li-bottom" :class="{ 'li-bottom-show': timercFrom.timeType == '1' }">
								<el-radio-group v-model="timercFrom.timeNumberType">
									<el-radio label="1">每天</el-radio>
									<el-radio label="2"
										>每
										<div class="input-box">
											<el-input v-model="timercFrom.timeNumber" type="number"></el-input>
										</div>
										天</el-radio
									>
								</el-radio-group>
							</div>
						</div>
						<div class="time-type-li" @click="timercFrom.timeType = '2'">
							<div class="li-head">每周<el-radio label="2"></el-radio></div>
							<div class="li-bottom" :class="{ 'li-bottom-show': timercFrom.timeType == '2' }">
								<el-checkbox-group v-model="timercFrom.checkList">
									<el-checkbox label="1">周一</el-checkbox>
									<el-checkbox label="2">周二</el-checkbox>
									<el-checkbox label="3">周三</el-checkbox>
									<el-checkbox label="4">周四</el-checkbox>
									<el-checkbox label="5">周五</el-checkbox>
									<el-checkbox label="6">周六</el-checkbox>
									<el-checkbox label="7">周日</el-checkbox>
								</el-checkbox-group>
							</div>
						</div>
						<div class="time-type-li" @click="timercFrom.timeType = '4'">
							<div class="li-head">指定日期<el-radio label="4"></el-radio></div>
							<div class="li-bottom border-b" :class="{ 'li-bottom-show': timercFrom.timeType == '4' }">
								<div class="picktime-box">
									<el-date-picker
										v-model="timercFrom.pickTime"
										size="small"
										type="date"
										placeholder="请选择日期"
										popper-class="pick-poper"
										class="picker-div"
										format="yyyy-MM-dd"
										value-format="yyyy-MM-dd"
										prefix-icon=" "
										clear-icon="el-icon-date"
										:clearable="false"
										:editable="false"
										:picker-options="pickerOptions0"
									>
									</el-date-picker>
									<i class="pick-icon el-icon-date"></i>
								</div>
							</div>
						</div>
					</el-radio-group>
				</div>
				<div class="line"></div>
				<div class="prompts-title">执行动作</div>
				<div class="zxaction-box">
					<el-radio-group v-model="timercFrom.action">
						<!-- <el-radio label="0">上升</el-radio>
            <el-radio label="1">停止</el-radio>
            <el-radio label="2">下降</el-radio> -->
						<el-radio
							:label="item.commandsItemCode"
							v-for="(item, index) in equipmentInfo.commandsItemList"
							:key="index"
							>{{ item.commandsItemName }}</el-radio
						>
					</el-radio-group>
				</div>
				<!-- 确定按钮 -->
				<div class="btn-box">
					<div class="btn-item" @click="scheduleupto">保存并下发</div>
				</div>
				<Msg ref="tsmsg6"></Msg>
			</el-drawer>
			<!-- 确定删除信息警告 -->
			<div class="Information" :class="{ 'Information-show': Information }">
				<div class="top">确定删除吗？</div>
				<div class="bottom">
					<div @click="Information = false">取消</div>
					<div class="line"></div>
					<div class="green" @click="scheduleDel">确定</div>
				</div>
			</div>
			<Msg ref="tsmsg2"></Msg>
		</el-drawer>
		<!-- 设备自动化展示页 -->
		<el-drawer
			title="设备自动化"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.automation"
			custom-class="insideDrawe flcu-box"
		>
			<div class="prompts-title">条件选择</div>
			<div class="table-box">
				<div class="table-li">
					<div class="li-left border-bottom border-right">传感设备</div>
					<div class="li-right border-bottom">
						{{ automationzsFrom.subDeviceName }}
					</div>
				</div>
				<div class="table-li">
					<div class="li-left border-right">环境参数</div>
					<div class="li-right">{{ automationzsFrom.dataItemName }}</div>
				</div>
			</div>
			<div class="line"></div>
			<div class="prompts-title">执行动作</div>
			<div class="table-box">
				<div class="table-li">
					<div class="li-left border-bottom border-right">高于上限</div>
					<div class="li-right border-bottom">
						{{ automationzsFrom.rightVal }}
					</div>
				</div>
				<div class="table-li">
					<div class="li-left border-right">执行动作</div>
					<div class="li-right green">
						{{ automationzsFrom.actionItemNameRight }}
					</div>
				</div>
			</div>
			<div class="table-box">
				<div class="table-li">
					<div class="li-left border-bottom border-right">低于下限</div>
					<div class="li-right border-bottom">
						{{ automationzsFrom.leftVal }}
					</div>
				</div>
				<div class="table-li">
					<div class="li-left border-right">执行动作</div>
					<div class="li-right red">
						{{ automationzsFrom.actionItemNameLeft }}
					</div>
				</div>
			</div>
			<div class="line"></div>
			<div class="prompts-title">下发结果</div>
			<div class="table-box">
				<div class="table-li">
					<div class="li-left border-right">是否生效</div>
					<div class="li-right green" v-if="automationzsFrom.effectiveState == '1'">已生效</div>
					<div class="li-right red" v-if="automationzsFrom.effectiveState == '0'">未生效</div>
				</div>
			</div>
			<div class="btn-box">
				<div class="btn-item cancel" v-if="automationzsFrom.id" @click="Information = true">删除配置</div>
				<div class="btn-item" v-if="automationzsFrom.id" @click="openautomationbj">修改配置</div>
			</div>
			<!-- 确定删除信息警告 -->
			<div class="Information" :class="{ 'Information-show': Information }">
				<div class="top">确定删除吗？</div>
				<div class="bottom">
					<div @click="Information = false">取消</div>
					<div class="line"></div>
					<div class="green" @click="delautomation">确定</div>
				</div>
			</div>
		</el-drawer>
		<!-- 设备自动化编辑页 -->
		<el-drawer
			title="设备自动化"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.automationbj"
			custom-class="insideDrawe flcu-box"
		>
			<div class="prompts-title">条件选择</div>
			<div class="flex flex_a_c" :style="{ marginTop: 21 + 'px' }">
				传感设备：
				<div class="time-box">
					<el-select v-model="automationbjFrom.sensorNum" placeholder="请选择">
						<el-option
							v-for="item in cgqList"
							:key="item.subDeviceNum"
							:label="item.subDeviceName"
							:value="item.subDeviceNum"
						>
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="parameter-box">
				<div class="box-header">环境参数</div>
				<div class="box-content">
					<el-radio-group v-model="automationbjFrom.dataItemCode" v-if="automationbjFrom.equipment !== ''">
						<el-radio :label="item.dataItemCode" v-for="(item, index) in cjxList" :key="index">{{
							item.dataItemName
						}}</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="line"></div>
			<div class="prompts-title">执行动作</div>
			<div class="zxaction-box2">
				<div class="item-li">
					上限值：
					<div class="input-box">
						<el-input
							type="number"
							v-model="automationbjFrom.rightVal"
							placeholder="设置上限值"
							@input="onlyNumber($event, 'rightVal')"
							><template slot="append">{{ automationbjFromUnit }}</template></el-input
						>
					</div>
				</div>
				<el-radio-group v-model="automationbjFrom.actionItemCodeRight">
					<el-radio
						:label="item.commandsItemCode"
						v-for="(item, index) in equipmentInfo.commandsItemList"
						:key="index"
						>{{ item.commandsItemName }}</el-radio
					>
				</el-radio-group>
				<div class="item-li">
					下限值：
					<div class="input-box">
						<el-input
							type="number"
							v-model="automationbjFrom.leftVal"
							placeholder="设置下限值"
							@input="onlyNumber($event, 'leftVal')"
						>
							<template slot="append">{{ automationbjFromUnit }}</template></el-input
						>
					</div>
				</div>
				<el-radio-group v-model="automationbjFrom.actionItemCodeLeft">
					<el-radio
						:label="item.commandsItemCode"
						v-for="(item, index) in equipmentInfo.commandsItemList"
						:key="index"
						>{{ item.commandsItemName }}</el-radio
					>
				</el-radio-group>
			</div>
			<div class="btn-box">
				<div class="btn-item" @click="sbzdhIssued">保存并下发</div>
			</div>
			<Msg ref="tsmsg7"></Msg>
		</el-drawer>
		<!-- 执行记录 -->
		<el-drawer
			title="执行记录"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.perform"
			custom-class="insideDrawe flcu-box"
			@close="performClose"
		>
			<!-- 查询条件盒子 -->
			<div class="search-box-kgsb">
				<span>查询条件</span>
				<div class="saach-item">
					<el-date-picker
						v-model="performFrom.time"
						type="daterange"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd"
						@change="operationRecord"
						:picker-options="pickerOptions1"
					>
					</el-date-picker>
				</div>
				<div class="saach-item flex flex_ju_sb">
					<div class="flex-sel">
						<el-select v-model="performFrom.operation" placeholder="请选择操作源" @change="operationRecord" clearable>
							<el-option :label="item.dictLabel" :value="item.dictValue" v-for="(item, index) in osList" :key="index">
							</el-option>
						</el-select>
					</div>
					<div class="flex-sel">
						<el-select v-model="performFrom.status" placeholder="请选择执行状态" @change="operationRecord" clearable>
							<el-option :label="item.dictLabel" :value="item.dictValue" v-for="(item, index) in orList" :key="index">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			<!-- 查询列表盒子 -->
			<div class="seach-list roll-auto" v-if="performList.length" ref="seachList">
				<div class="list-item" v-for="(item, index) in performList" :key="index" @click="performItemClick(index)">
					<div class="list-item-top">
						<div>{{ item.subDeviceName }}</div>
						<div class="r-div" v-if="item.operationResult == '1'">
							<img src="../../../assets/image/equipment/icon-chengg.png" alt="" />
							执行中
						</div>
						<div class="r-div r-div-green" v-if="item.operationResult == '2'">
							<img src="../../../assets/image/equipment/icon-shibia.png" alt="" />
							执行失败
						</div>
						<div class="r-div" v-if="item.operationResult == '3'">
							<img src="../../../assets/image/equipment/icon-chengg.png" alt="" />
							执行成功
						</div>
					</div>
					<div class="list-item-bot">
						<div class="lib-div ellipsis">
							<i>所属区域:</i>
							<span>{{ item.areaName || '--' }}</span>
						</div>
						<div class="lib-div ellipsis">
							<i>执行时间:</i><span>{{ item.operateTime || '--' }}</span>
						</div>
						<div class="lib-div ellipsis">
							<i>操作源:</i><span>{{ item.operationSourceName || item.operationSource }}</span>
						</div>
						<div class="lib-div ellipsis">
							<i>执行内容:</i><span>{{ item.recordContents || '--' }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="seach-list-no" v-else>
				<img src="@/assets/image/home/ZWT.png" alt="" />
			</div>
			<!-- 执行记录详情 -->
			<el-drawer
				:title="`详情（${performInfoObj.deviceName}-${performInfoObj.subDeviceName}）`"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.executionInfo"
				custom-class="insideDrawe flcu-box"
			>
				<div class="performInfo">
					<div class="info-item">
						<div class="title">所属区域:</div>
						<div class="cont">{{ performInfoObj.areaName || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">执行时间:</div>
						<div class="cont">{{ performInfoObj.operateTime || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">操作源:</div>
						<div class="cont">
							{{ performInfoObj.operationSourceName || performInfoObj.operationSource }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">产品类型:</div>
						<div class="cont">
							{{ performInfoObj.productType || '--' | ProductType }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">产品名称:</div>
						<div class="cont">{{ performInfoObj.productName || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">设备编号:</div>
						<div class="cont">{{ performInfoObj.deviceNum || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">业务类型:</div>
						<div class="cont">
							{{ performInfoObj.businessTypeName || '--' }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">操作账号:</div>
						<div class="cont">
							{{ performInfoObj.operationAccount || '--' }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">ip地址:</div>
						<div class="cont">{{ performInfoObj.ipAddress || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">执行状态:</div>
						<div class="cont">
							<span v-if="performInfoObj.operationResult == '2'">执行失败</span>
							<span v-if="performInfoObj.operationResult == '3'">执行成功</span>
						</div>
					</div>
					<div class="info-item">
						<div class="title">执行条件:</div>
						<div class="cont">
							{{ performInfoObj.executionCondition || '--' }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">执行内容:</div>
						<div class="cont">{{ performInfoObj.recordContents || '--' }}</div>
					</div>
				</div>
			</el-drawer>
		</el-drawer>
		<Msg ref="tsmsg"></Msg>
	</el-drawer>
</template>

<script>
import Msg from '@/components/msg.vue' //信息提示组件
import Countdown from './Countdown.vue'
import CountdownUp from './CountdownUp.vue'
// import Ring from './Ring.vue'
import Ring from './RingTwo.vue'
import { mapState } from 'vuex'
import setUpJs from '../js/xcSetup-znzd'
export default {
	components: { Msg, Countdown, Ring, CountdownUp },
	mixins: [setUpJs],
	data() {
		return {
			// 控制抽屉的开关
			drawer: false,
			// 控制箱信息
			kzxInfo: {},
			// 设备信息
			equipmentInfo: {
				subDeviceNum: '',
				subDeviceName: '',
				subDeviceType: '',
				subDeviceIcon: '',
				itemList: [
					{
						dataItemCode: 'e_stop',
						dataItemName: '急停',
						dataItemValue: '0',
					},
					{
						dataItemCode: 'khd',
						dataItemName: '设备开度值',
						dataItemValue: '0',
					},
					{
						dataItemCode: 'status',
						dataItemName: '状态',
						dataItemValue: '00',
					},
				],
				commandsItemList: [
					{
						commandsItemCode: '00',
						commandsItemName: '停止',
					},
					{
						commandsItemCode: '10',
						commandsItemName: '下降',
					},
					{
						commandsItemCode: '01',
						commandsItemName: '上升',
					},
				],
			},
			// 传感器设备列表信息
			cgqList: [
				{
					subDeviceNum: '--',
					subDeviceName: '--',
					subDeviceType: '--',
					subDeviceIcon: '--',
					itemList: [],
					commandsItemList: [],
				},
			],
			// 采集项列表
			cjxList: [],
			// 控制里面抽屉的开关
			insideSwitch: {
				timeprotect: false, //时长保护
				protect: false, //单次运行与间隔
				zxzsc: false, //正向总时长
				fxzsc: false, //反向总时长
				certification: false, //安全密码认证
				changPwd: false, //修改安全密码
				wjaqmm: false, //忘记安全密码
				schedule: false, //时间日程
				bjschedule: false, //编辑时间日程
				automation: false, //设备自动化展示页
				automationbj: false, //设备自动化编辑页
				perform: false, //执行记录
				executionInfo: false, //执行记录详情
			},
			//   控制编辑输入框的显示
			bjInputShow: false,
			//   编辑输入框内容
			bjInputValue: '',
			//   时间日程列表
			scheduleList: [
				// {
				//   time: "16.50",
				//   action: true,
				//   date: "每周（周二、周三、周四、周五）",
				//   iseffect: true,
				// },
				// {
				//   time: "16.50",
				//   action: true,
				//   date: "每周（周二、周三、周四、周五）",
				//   iseffect: false,
				// },
			],
			// 控制确定删除弹窗
			Information: false,
			//   时间日程编辑表单
			timercFrom: {
				time: '',
				timeType: '1', //1每天2每周3指定日期
				timeNumberType: '1', //1固定1天2不固定几天
				timeNumber: '',
				checkList: ['1'],
				pickTime: '',
				action: '', //0上升1停止2下降
			},
			// 时间日程是新增还是编辑
			timeRcbjOradd: false, //false 新增 true编辑
			timeBtnTap: true, // 时间日程新增/编辑按钮是否可点击,主要为了防止多次重复点击
			// 当前编辑对象
			nowbjTimeRcObj: {},
			// 当前删除对象
			nowdelTimeRcObj: {},
			// 设备自动化展示表单
			automationzsFrom: {
				id: null,
				sensorNum: '--',
				subDeviceName: '--',
				dataItemCode: '--',
				dataItemName: '--',
				rightVal: '--', //上限
				actionItemCodeRight: '--', //0上升1停止2下降
				actionItemNameRight: '--',
				leftVal: '--', //下限
				actionItemCodeLeft: '--', //0上升1停止2下降
				actionItemNameLeft: '--',
				effectiveState: '--',
			},
			// 设备自动化编辑表单
			automationbjFrom: {
				sensorNum: '',
				dataItemCode: '',
				rightVal: '', //上限
				actionItemCodeRight: '', //0上升1停止2下降
				leftVal: '', //下限
				actionItemCodeLeft: '', //0上升1停止2下降
			},
			// 设备自动化设备列表
			equipmentList: [
				// {
				//   value: "选项1",
				//   label: "黄金糕",
				// },
				// {
				//   value: "选项2",
				//   label: "双皮奶",
				// },
				// {
				//   value: "选项3",
				//   label: "蚵仔煎",
				// },
				// {
				//   value: "选项4",
				//   label: "龙须面",
				// },
				// {
				//   value: "选项5",
				//   label: "北京烤鸭",
				// },
			],
			// 执行记录时间查询
			performFrom: {
				time: '', //时间
				operation: '', //操作源
				status: '', //执行状态
			},
			// 执行记录操作源数组
			osList: [],
			// 执行记录操作结果数组
			orList: [],
			// 业务类型数组
			ywList: [],
			// 执行记录列表数组
			performList: [
				// {
				//   name: "放风机01号",
				//   area: "某某区域",
				//   time: "2022-05-12 15:43:24",
				//   source: "App",
				//   content:
				//     "执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容",
				// },
				// {
				//   name: "放风机01号",
				//   area: "某某区域",
				//   time: "2022-05-12 15:43:24",
				//   source: "App",
				//   content:
				//     "执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容",
				// },
				// {
				//   name: "放风机01号",
				//   area: "某某区域",
				//   time: "2022-05-12 15:43:24",
				//   source: "App",
				//   content:
				//     "执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容",
				// },
				// {
				//   name: "放风机01号",
				//   area: "某某区域",
				//   time: "2022-05-12 15:43:24",
				//   source: "App",
				//   content:
				//     "执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容",
				// },
			],
			// 执行记录详情对象
			performInfoObj: {
				// name: "控制箱一号-放风机01号",
				// area: "某某区域",
				// time: "2022-05-12 15:43:24",
				// source: "App",
				// cpType: "控制箱设备",
				// cpName: "控制箱一号",
				// cpNum: "12313454645478748",
				// ywType: "灾害模式",
				// czNumber: "4564654645656",
				// address: "河南省焦作市山阳区",
				// performState: "成功",
				// performconditions:
				//   "执行条件执行条件执行条件，执行条件执行条件执行条件执行条件执行条件，执行条件执行条件，执行条件执行条件执行条件执行条件执行条件，执行条。",
				// content:
				//   "执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容",
			},
			//   修改安全密码对象
			xiugaiPwdFrom: {
				oldPwd: '',
				newPwd: '',
				againNewPwd: '',
			},
			//   修改安全密码对象显示方式
			xiugaiPwdType: {
				oldPwd: true,
				newPwd: true,
				againNewPwd: true,
			},
			// 忘记安全密码对象
			wjaqmmFrom: {
				Pwd: '',
				newPwd: '',
				againNewPwd: '',
			},
			// 忘记安全密码对象显示方式
			wjaqmmPwdType: {
				Pwd: true,
				newPwd: true,
				againNewPwd: true,
			},
			// 时长保护
			timeproect: {
				onceOperationDuration: '--',
				onceIntervalDuration: '--',
				onTotalDuration: '--',
				offTotalDuration: '--',
			},
			//   时长保护配置 单次运行与间隔
			protectFrom: {
				onceOperationDuration: '--',
				onceIntervalDuration: '--',
			},
			// 控制正向总时长顶部菜单
			zxzscTab: 0, //0自动获取1手动设置
			// 控制反向总时长顶部菜单
			fxzscTab: 0, //0自动获取1手动设置
			// 正向总时长提交表单
			zxzscFrom: {
				sdTime: '', //手动设置的时间
				zdhqTime: '', //自动获取时间
				tztime: '0', //调整时间
				jstime: '0', //计时时间单位秒
				timeType: '0', //0秒1分钟
				inputTime: '',
			},
			// 正向总时长获取状态
			getzxzsctype: '-1', //-1 未获取 0 正在获取 1 暂停 2 结束获取
			// 控制正向总时长按钮点击
			zxzscBtn: '-1', //-1 未选中 0 开始获取 1停止获取 2结束获取
			// 正向总时长微调
			zxzscWeitiao: '-1', //-1未点击 0 下降 1 停止 2 上升
			// 正向总时长是否显示输入框
			zxzscShowInput: false,
			// 反向总时长提交表单
			fxzscFrom: {
				sdTime: '', //手动设置的时间
				zdhqTime: '', //自动获取时间
				tztime: '0', //调整时间
				jstime: '0', //计时时间单位秒
				timeType: '0', //0秒1分钟
				inputTime: '',
			},
			// 反向总时长获取状态
			getfxzsctype: '-1', //-1 未获取 0 正在获取 1 暂停
			// 控制反向总时长按钮点击
			fxzscBtn: '-1', //-1 未选中 0 开始获取 1停止获取 2结束获取
			// 反向总时长微调
			fxzscWeitiao: '-1', //-1未点击 0 下降 1 停止 2 上升
			// 反向总时长是否显示输入框
			fxzscShowInput: false,
			// 控制设备操作按钮点击
			controlItem: {
				falling: false, //下降
				stop: true, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: false, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			},
			// 是否显示微调倒计时
			showcountdownUp: false,
			// 控制设备操作按钮禁用
			controlItemDisable: {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: false, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			},
			// 普通操作还是微操
			isfineTuning: false,
			// 微调操作文字信息
			xtInfoTxt: '请使用下方操作，完成您的微调。',
			// 菜单列表是否显示
			menuisShow: true,
			// 微调时间类型
			vtTimeType: '0', //0秒1分钟
			// 微调运行时间
			vtRunTime: '',
			// 认证安全密码
			codeValue: '',
			inputFocus: false,
			pickerOptions0: {
				disabledDate: (time) => {
					let num = new Date().getTime()
					return time.getTime() < num - 86400000
				},
			},
			pickerOptions1: {
				disabledDate: (time) => {
					let num = new Date().getTime()
					return time.getTime() > num
				},
			},
			// 密码认证去向
			pwdGoTo: '0', //0 正常进去时长查询列表页面  1进去正向 2 进去反向
		}
	},
}
</script>

<style lang="scss" scoped>
/deep/ .kgSetUp {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		span {
			font-size: 0.225rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		display: flex;
		flex-direction: column;
		.drawe-top {
			display: flex;
			padding-bottom: 0.275rem;
			border-bottom: 1px solid rgba(241, 241, 241, 1);
			.img-box {
				width: 1.5rem;
				height: 1.5rem;
				background-color: rgba(45, 174, 169, 0.18);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 0.8625rem;
				}
			}
			.txt-box {
				margin-left: 0.225rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				box-sizing: border-box;
				padding: 0.15rem 0;
				.tb-li {
					display: flex;
					font-size: 0.2rem;
					.line {
						width: 0.0125rem;
						height: 0.2rem;
						background: #bfbfbf;
						margin: 0 0.2rem;
					}
					.bj-icon {
						cursor: pointer;
						margin-left: 0.1875rem;
						width: 0.2rem;
						height: 0.2rem;
						background: url('../../../assets/image/product/icon-bj2.png') no-repeat;
					}
					.input-div {
						width: 2.875rem;
						height: 0.4rem;
						.el-input {
							height: 100%;
						}
						.el-input__inner {
							border-radius: 0.1rem;
							height: 100%;
							font-weight: normal;
						}
						.el-input.is-active .el-input__inner,
						.el-input__inner:focus {
							border-color: rgba(45, 174, 169, 1);
						}
						.el-input__suffix {
							width: 4vw;
							height: 100%;
							.el-input__suffix-inner {
								display: block;
								width: 100%;
								height: 100%;
								display: flex;
								align-items: center;
								.suffix-content {
									display: flex;
									align-items: center;
									width: 100%;
									height: 1.099vw;
									box-sizing: border-box;
									border-left: 1px solid #f1f1f1;
									justify-content: space-around;
									padding: 0 0.1875rem;
									img {
										cursor: pointer;
										width: 0.175rem;
										height: 0.175rem;
									}
								}
							}
						}
					}
				}
				.doble {
					font-weight: bold;
				}
				.green {
					color: rgba(73, 201, 136, 1);
				}
				.blue {
					color: rgba(45, 174, 169, 1);
				}
				.red {
					color: rgb(248, 134, 56);
				}
			}
		}
		.switch-box {
			margin: 0.25rem 0;
			height: 0.9rem;
			background: rgba(231, 241, 243, 0.6);
			border-radius: 0.1rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 0 0.3rem;
			.status-box {
				display: flex;
				align-items: center;
				.icon {
					width: 0.45rem;
					height: 0.45rem;
					background: url('../../../assets/image/product/kaiguan.png') no-repeat;
					margin-right: 0.2625rem;
				}
				span {
					font-size: 0.2rem;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #454545;
				}
			}
			.set_icon {
				width: 0.8625rem;
				height: 0.35rem;
			}
		}
		.menu-box {
			margin-top: 0.3rem;
			.menu-li {
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 0.2rem;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #454545;
				line-height: 0.2625rem;
				margin-bottom: 0.3rem;
				.blue {
					font-size: 0.175rem;
					color: rgba(45, 174, 169, 1);
					margin: 0 0.175rem;
				}
				&::after {
					content: '';
					width: 0.1125rem;
					height: 0.2rem;
					background: url('../../../assets/image/equipment/icon-open.png') no-repeat;
					background-size: 100% 100%;
				}
				.li-l {
					font-size: 0.2rem;
					img {
						margin-right: 0.25rem;
					}
				}
			}
			.b-line {
				height: 1px;
				background: #f1f1f1;
				margin-bottom: 0.3125rem;
			}
		}
		.annulus-box {
			height: 3.1875rem;
			.annulus-content {
				width: 3.2125rem;
				height: 3.1875rem;
				margin: 0 auto;
				background: url('../../../assets/image/product/yuanhuan.png') no-repeat;
			}
		}
		.wt-tishibox {
			height: 3.1875rem;
			box-sizing: border-box;
			padding-left: 0.2875rem;
			display: flex;
			align-items: center;
			.jiqi-box {
				width: 1.5125rem;
				height: 2.2125rem;
				background: url('../../../assets/image/product/jiqiren.png') no-repeat;
			}
			.tishi-box {
				margin-left: 0.25rem;
				height: 3.1875rem;
				width: 2.5rem;
				box-sizing: border-box;
				padding-top: 0.75rem;
				.tishi-content {
					position: relative;
					text-align: left;
					padding: 0.25rem;
					background: #2daea9;
					border-radius: 8px;
					color: #ffffff;
					line-height: 26px;
					font-size: 0.2rem;
					&::after {
						position: absolute;
						top: 0.075rem;
						left: -0.4rem;
						content: '';
						width: 0;
						height: 0;
						border: 20px solid transparent;
						border-top-width: 10px;
						border-bottom-width: 10px;
						border-right-color: #2daea9;
					}
					.time {
						text-align: center;
						span {
							font-size: 0.3rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffffff;
							line-height: 0.325rem;
						}
					}
				}
			}
		}
		.control-btn-box {
			margin-top: 0.1rem;

			.btn-box {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.item {
					cursor: pointer;
					width: 110px;
					height: 56px;
					background: rgba(231, 241, 243, 0.6);
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 0.3125rem;
				}
				.pading15 {
					padding: 0 0.1875rem;
				}
				.orgeen {
					color: rgba(248, 134, 56, 1);
				}
				.itembggreen {
					background: rgba(45, 174, 169, 1);
					color: #ffffff;
				}
				.itembgorg {
					background: rgba(248, 134, 56, 1);
					color: #fff;
				}
				.mb18 {
					margin-bottom: 0.225rem;
				}
				.item-disable {
					pointer-events: none;
					filter: grayscale(1);
					cursor: not-allowed !important;
				}
			}
		}
		.prompts-title {
			position: relative;
			//   margin-top: 0.2875rem;
			display: flex;
			align-items: center;
			&::before {
				margin-right: 0.3rem;
				content: '';
				width: 0.025rem;
				height: 0.2rem;
				background: #2daea9;
			}
		}
		.line {
			height: 1px;
			background: #f1f1f1;
			margin: 0.3rem 0;
		}
		.vt-time-box {
			text-align: left;
			margin-top: 0.3125rem;
			display: flex;
			align-items: center;
			.input-box {
				flex: 1;
			}
		}
		.btn-box {
			flex: 1;
			// position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			.btn-item {
				box-sizing: border-box;
				border: 0.0125rem solid #2daea9;
				cursor: pointer;
				width: 1.75rem;
				height: 0.45rem;
				background: #2daea9;
				border-radius: 0.05rem;
				text-align: center;
				line-height: 0.45rem;
				color: white;
			}
			.cancel {
				background: #fff;
				color: #2daea9;
			}
		}
		.Information {
			position: absolute;
			z-index: 999999;
			top: 40%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%) scale(0);
			width: 3.45rem;
			height: 2.075rem;
			background: #ffffff;
			box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
			border-radius: 10px;
			transition: all 0.3s;
			.top {
				box-sizing: border-box;
				height: 1.375rem;
				text-align: center;
				line-height: 1.375rem;
				border-bottom: 1px solid #f1f1f1;
			}
			.bottom {
				display: flex;
				height: 0.6875rem;
				justify-content: space-around;
				align-items: center;
				& > div {
					cursor: pointer;
				}
				.line {
					width: 1px;
					height: 0.45rem;
					background: #f1f1f1;
				}
				.green {
					color: rgba(45, 174, 169, 1);
				}
			}
		}
		.Information-show {
			transform: translateX(-50%) translateY(-50%) scale(1);
		}
	}
}
/deep/ .insideDrawe {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		margin-bottom: 0.25rem;
		span {
			font-size: 0.225rem;
		}
		.xgmm-box {
			img {
				cursor: pointer;
				margin-left: 0.2rem;
			}
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.flcu-box {
		display: flex;
		flex-direction: column;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		display: flex;
		flex-direction: column;
		position: relative;
		.drawe-top {
			display: flex;
			padding-bottom: 0.275rem;
			border-bottom: 1px solid rgba(241, 241, 241, 1);
			.img-box {
				width: 1.5rem;
				height: 1.5rem;
				background-color: rgba(45, 174, 169, 0.18);
				border-radius: 50%;
			}
			.txt-box {
				margin-left: 0.225rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.tb-li {
					display: flex;
					font-size: 0.2rem;
					.line {
						width: 0.0125rem;
						height: 0.2rem;
						background: #bfbfbf;
						margin: 0 0.2rem;
					}
				}

				.doble {
					font-weight: bold;
				}
				.green {
					color: rgba(73, 201, 136, 1);
				}
				.blue {
					color: rgba(45, 174, 169, 1);
				}
			}
		}
		.addrc-box {
			height: 1.1375rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			cursor: pointer;
			box-sizing: border-box;
			padding-top: 0.2rem;
			text-align: center;
			font-size: 0.2rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #2daea9;
			img {
				margin-bottom: 0.175rem;
			}
		}
		.line {
			height: 1px;
			background: #f1f1f1;
			margin: 0.3rem 0;
		}
		.schedule-list {
			height: 80%;
			overflow: auto;
			.item {
				background: #e7f1f3;
				border-radius: 0.125rem;
				overflow: hidden;
				margin-bottom: 0.3rem;
				font-size: 0.2rem;
				&:last-child {
					margin-bottom: 0;
				}
				.item-header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					box-sizing: border-box;
					padding: 0.225rem 0.3rem;
					border-bottom: 1px solid rgba(255, 255, 255, 0.6);
					.header-left {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 28px;
						display: flex;
						// span {
						//    line-height: 28px;
						// }
						img {
							width: 0.225rem;
							height: 0.225rem;
							margin-right: 0.175rem;
						}
						.schedule-weeks {
							line-height: 1.5;
							margin-top: -0.03rem;
						}
						.gray {
							// color: rgba(156, 156, 156, 1);
							color: #5e5353;
						}
					}
					.header-right {
						margin-left: 0.45rem;
						img {
							cursor: pointer;
						}
					}
				}
				.item-top {
					height: 1.1375rem;
					box-sizing: border-box;
					padding: 0.25rem 0.3rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					position: relative;
					.r-jiao {
						position: absolute;
						top: 0.1375rem;
						right: -0.3125rem;
						width: 1.25rem;
						height: 0.3875rem;
						background: rgba(45, 174, 169, 1);
						text-align: center;
						line-height: 0.3875rem;
						color: white;
						transform: rotate(45deg);
					}
					.r-jiao-no {
						background: rgba(213, 213, 213, 1);
					}
					.lie-1 {
						display: flex;
						& > div {
							flex: 1;
							font-size: 0.2rem;
							span {
								color: rgba(45, 174, 169, 1);
								font-size: 0.2rem;
							}
						}
					}
				}
				.item-bottom {
					//   height: 0.7125rem;
					height: 0;
					overflow: hidden;
					transition: all 0.5s;
					box-sizing: border-box;

					border-top: 1px solid rgba(255, 255, 255, 0.6);
					display: flex;
					justify-content: space-around;
					.cz-div {
						cursor: pointer;
						display: flex;
						// display: none;
						align-items: center;
						img {
							margin-right: 0.15rem;
						}
					}
					.cz-line {
						// display: none;
						width: 0.0125rem;
						background: rgba(255, 255, 255, 0.6);
					}
					.blue {
						color: rgba(43, 137, 217, 1);
					}
					.green {
						color: rgba(45, 174, 169, 1);
					}
					.red {
						color: rgba(227, 52, 52, 1);
					}
				}
			}
			.item:hover {
				.item-bottom {
					height: 0.7125rem;
					display: flex;
					padding: 0.125rem;
				}
			}
		}
		.schedule-list-no {
			height: 80%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.prompts-title {
			position: relative;
			//   margin-top: 0.2875rem;
			display: flex;
			align-items: center;
			&::before {
				margin-right: 0.3rem;
				content: '';
				width: 0.025rem;
				height: 0.2rem;
				background: #2daea9;
			}
		}
		.time-box {
			flex: 1;
			height: 0.45rem;
			margin-left: 0.1875rem;
			.el-date-editor.el-input,
			.el-date-editor.el-input__inner {
				width: 100%;
				height: 100%;
				.el-input__inner {
					height: 100%;
					line-height: 0.45rem;
					border-radius: 0.1rem;
					padding-left: 0.25rem;
				}
				.el-input__icon {
					line-height: 0.45rem;
					font-size: 0.25rem;
				}
				.el-input__prefix {
					left: 250px;
				}
			}
			.el-select {
				width: 100%;
				height: 100%;
				.el-input__inner {
					height: 0.475rem;
					line-height: 0.475rem;
				}
				.el-icon-arrow-up:before {
					content: '\e78f';
				}
			}
		}
		.time-type-box {
			margin-top: 0.25rem;
			.el-radio-group {
				width: 100%;
			}
			.time-type-li {
				.li-head {
					cursor: pointer;
					box-sizing: border-box;
					height: 0.55rem;
					background: #f2f8f9;
					border: 1px solid #eeeeee;
					padding-left: 0.3125rem;
					padding-right: 0.3rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.el-radio__label {
						display: none;
					}
				}
				.li-bottom {
					height: 0;
					overflow: hidden;
					transition: all 0.5s;
					box-sizing: border-box;
					border-left: 1px solid #eeeeee;
					border-right: 1px solid #eeeeee;
					padding: 0 0.3125rem;

					.el-radio-group {
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.input-box {
							display: inline-block;
							//   width: 49px;
							height: 22px;
							margin: 0 0.05rem;
						}
					}
					.el-checkbox-group {
						display: flex;
						flex-wrap: wrap;
						// align-items: center;
						.el-checkbox:nth-child(1) {
							margin-bottom: 18px;
						}
						.el-checkbox {
							margin-right: 28px;
						}
					}
					.picktime-box {
						position: relative;
						width: 4.3rem;
						height: 0.45rem;
						margin: 0.125rem auto 0;
						.el-date-editor.el-input,
						.el-date-editor.el-input__inner {
							width: 100%;
						}
						.pick-icon {
							position: absolute;
							right: 0.25rem;
							top: 45%;
							transform: translateY(-50%);
							font-size: 0.25rem;
							color: #e5e5e5;
						}
					}
				}
				.li-bottom-show {
					padding: 0.2rem 0.3125rem;
					height: 1.125rem;
				}
				.border-b {
					border-bottom: 1px solid #eeeeee;
				}
			}
		}
		.zxaction-box {
			margin-top: 0.25rem;
			height: 0.75rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			box-sizing: border-box;
			padding-left: 0.25rem;
			padding-right: 0.25rem;
			display: flex;
			align-items: center;
			.el-radio-group {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		.table-box {
			border: 1px solid #eeeeee;
			margin-top: 0.25rem;
			.table-li {
				display: flex;
				.li-left {
					width: 29.4%;
					height: 0.6rem;
					box-sizing: border-box;
					background: #f2f8f9;
					text-align: center;
					line-height: 0.6rem;
					color: #666666;
				}
				.li-right {
					width: 70.6%;
					height: 0.6rem;
					box-sizing: border-box;
					padding-left: 0.3rem;
					color: #666666;
					text-align: left;
					line-height: 0.6rem;
				}
				.border-bottom {
					border-bottom: 1px solid #eeeeee;
				}
				.border-right {
					border-right: 1px solid #eeeeee;
				}
				.blue {
					color: rgba(43, 137, 217, 1);
				}
				.green {
					color: rgba(45, 174, 169, 1);
				}
				.red {
					color: rgba(227, 52, 52, 1);
				}
			}
		}
		.btn-box {
			flex: 1;
			// position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			.btn-item {
				box-sizing: border-box;
				border: 0.0125rem solid #2daea9;
				cursor: pointer;
				width: 1.75rem;
				height: 0.45rem;
				background: #2daea9;
				border-radius: 0.05rem;
				text-align: center;
				line-height: 0.45rem;
				color: white;
			}
			.cancel {
				background: #fff;
				color: #2daea9;
			}
		}
		.parameter-box {
			margin-top: 0.3rem;
			box-sizing: border-box;
			border: 1px solid #eeeeee;
			.box-header {
				box-sizing: border-box;
				padding-left: 0.25rem;
				line-height: 0.55rem;
				height: 0.55rem;
				background: #f2f8f9;
				border-bottom: 1px solid #eeeeee;
				color: #333333;
			}
			.box-content {
				box-sizing: border-box;
				padding: 0.2rem 0.5rem;
				.el-radio-group {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: center;
				}
				.el-radio {
					margin-bottom: 0.2rem;
					// margin-right: 100px;
					.el-radio__label {
						width: 1rem;
						display: inline-block;
					}
				}
				.el-radio:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
		.zxaction-box2 {
			margin-top: 0.25rem;
			height: 2.6rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			box-sizing: border-box;
			padding: 0.25rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.item-li {
				width: 100%;
				display: flex;
				align-items: center;
				.input-box {
					flex: 1;
					height: 0.4125rem;
					.el-input__inner {
						height: 0.4125rem;
						line-height: 0.4125rem;
					}
				}
			}
		}
		.seach-list {
			height: 7.925rem;
			overflow: auto;
			margin-top: 0.25rem;
			.list-item {
				cursor: pointer;
				height: 2.475rem;
				border-radius: 0.175rem;
				background: rgba(241, 249, 250, 1);
				margin-bottom: 0.25rem;
				box-sizing: border-box;
				padding: 0.125rem;
				&:last-child {
					margin-bottom: 0;
				}
				.list-item-top {
					box-sizing: border-box;
					padding: 0.125rem;
					height: 0.575rem;
					border-bottom: 1px solid rgba(255, 255, 255, 1);
					display: flex;
					justify-content: space-between;
					font-size: 0.2rem;
					font-family: Microsoft YaHei;
					font-weight: bold;
					.r-div {
						color: #2daea9;
						img {
							width: 0.2rem;
							height: 0.2rem;
							margin-right: 0.075rem;
						}
					}
				}
				.list-item-bot {
					box-sizing: border-box;
					height: 1.75rem;
					padding: 0.225rem 0.1875rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.lib-div {
						font-size: 0.175rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						i {
							display: inline-block;
							font-style: normal;
							width: 0.75rem;
						}
						span {
							color: #333333;
							margin-left: 0.1875rem;
						}
					}
				}
			}
		}
		.seach-list-no {
			height: 7.925rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.performInfo {
			.info-item {
				display: flex;
				margin-bottom: 0.2875rem;
				.title {
					width: 90px;
					color: #999999;
					line-height: 1.5;
				}
				.cont {
					flex: 1;
					margin-left: 0.2rem;
					line-height: 1.5;
				}
			}
		}
		.timeChange-box {
			position: relative;
			height: 36px;
			.el-date-editor.el-input,
			.el-date-editor.el-input__inner {
				width: 100%;
			}
			.pick-icon {
				position: absolute;
				right: 0.25rem;
				top: 45%;
				transform: translateY(-50%);
				font-size: 0.25rem;
				color: #e5e5e5;
			}
		}
		.search-box-kgsb {
			min-height: 1.8875rem;
			background: #ffffff;
			border: 1px solid #f1f1f1;
			border-radius: 0.05rem;
			box-sizing: border-box;
			padding: 0.2rem 0.3rem;
			font-size: 0.2rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #454545;
			.saach-item {
				height: 0.45rem;
				margin-top: 0.2rem;

				.el-date-editor--datetimerange.el-input,
				.el-date-editor--datetimerange.el-input__inner {
					width: 100%;
					height: 100%;
					line-height: 0.45rem;
				}
				.el-range-editor.el-input__inner {
					width: 100%;
					height: 100%;
					line-height: 0.45rem;
				}
				.el-date-editor .el-range__icon {
					position: absolute;
					left: 313px;
					line-height: 0.35rem;
					font-size: 0.25rem;
				}
				.el-date-editor .el-range__close-icon {
					line-height: 0.35rem;
				}
				.el-input__suffix-inner {
					width: 0.2rem;
					height: 100%;
					display: block;
					box-sizing: border-box;
					// padding-top: 0.125rem;
					padding-top: 0 !important;
					margin-right: 0.25rem;
					.seach-icon {
						position: relative;
						cursor: pointer;
						width: 0.2rem;
						height: 0.2rem;
						background: url('../../../assets/image/equipment/icon-seach.png') no-repeat;
						&::before {
							position: absolute;
							top: 50%;
							left: -0.25rem;
							transform: translateY(-50%);
							content: '';
							width: 1px;
							height: 22px;
							background: #f1f1f1;
						}
					}
				}
				.flex-sel {
					width: 48%;
					height: 100%;
					.el-select {
						width: 100%;
						height: 100%;
						.el-input__inner {
							height: 0.45rem;
							line-height: 0.45rem;
						}
						.el-input__icon {
							line-height: 0.45rem;
						}
						.el-icon-arrow-up:before {
							content: '\e78f';
						}
					}
				}
			}
		}
		.xgmm-input {
			margin-top: 0.2rem;
			margin-bottom: 0.3rem;
			height: 0.45rem;
			.el-input__inner {
				height: 0.45rem;
				line-height: 0.45rem;
			}
			.el-input__suffix {
				width: 0.25rem;
				display: flex;
				align-items: center;
				right: 10px;
				.eye-icon {
					display: block;
					cursor: pointer;
					width: 0.25rem;
					height: 0.2rem;
					margin-right: 0.175rem;
					background: url('../../../assets/image/login/icon-eye.png') no-repeat;
				}
				.eyen-icon {
					display: block;
					cursor: pointer;
					width: 0.25rem;
					height: 0.2rem;
					margin-right: 0.175rem;
					background: url('../../../assets/image/login/icon-eyen.png') no-repeat;
				}
			}
		}
		.scbh-input {
			height: 0.45rem;
			margin: 0 0.2rem;
			margin: 0.2rem 0;
			.el-input__inner {
				height: 0.45rem;
				line-height: 0.45rem;
			}
			.el-input__suffix {
				// width: 4vw;
				height: 100%;
				.el-input__suffix-inner {
					display: block;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					.suffix-content {
						font-style: normal;
						display: flex;
						align-items: center;
						width: 100%;
						height: 1.099vw;
						box-sizing: border-box;
						border-left: 1px solid #f1f1f1;
						justify-content: space-around;
						padding: 0 0.1875rem;
						font-size: 0.175rem;
						img {
							cursor: pointer;
							width: 0.175rem;
							height: 0.175rem;
						}
					}
				}
			}
		}
		.warm-txt {
			font-size: 0.175rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #999999;
			line-height: 1.5;
			//   margin: 0 0 0.3rem 0;
		}
		.rz-tishibox {
			// height: 3.1875rem;
			box-sizing: border-box;
			padding-left: 0.2875rem;
			display: flex;
			align-items: center;
			position: relative;
			.jiqi-box {
				width: 1.5125rem;
				height: 2.2125rem;
				background: url('../../../assets/image/product/jiqiren.png') no-repeat;
			}
			.right-box {
				margin-left: 0.25rem;
				// height: 3.1875rem;
				width: 2.5rem;
				position: relative;
				text-align: left;
				padding: 0.25rem;
				background: #2daea9;
				border-radius: 8px;
				color: #ffffff;
				line-height: 26px;
				font-size: 0.2rem;
				&::after {
					position: absolute;
					top: 0.075rem;
					left: -0.4rem;
					content: '';
					width: 0;
					height: 0;
					border: 20px solid transparent;
					border-top-width: 10px;
					border-bottom-width: 10px;
					border-right-color: #2daea9;
				}
			}
			.wjmm {
				cursor: pointer;
				position: absolute;
				right: 0.45rem;
				bottom: 0.225rem;
				font-size: 0.2rem;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2daea9;
			}
		}
		.mminput-box {
			margin-top: 20px;
			position: relative;
			display: flex;
			justify-content: space-between;
		}
		.input-code {
			position: absolute;
			height: 50px;
			width: 100%;
			overflow: hidden;
		}
		.code-item {
			width: 48px;
			height: 50px;
			text-align: center;
			box-sizing: border-box;
			line-height: 50px;
			border: 1px solid #f0f0f0;
			margin-right: 10px;
			color: rgba(45, 174, 169, 1);
		}
		.code-item-active {
			border: 1px solid rgba(45, 174, 169, 1);
			box-sizing: border-box;
		}
		// 隐藏input
		.mminput-box {
			.el-input__inner {
				height: 50px;
				background-color: transparent;
				border: none;
				color: transparent;
				user-select: none;
				padding-right: 1500px;
			}
		}
		.timeproect {
			height: 0.775rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 0.2875rem;
			margin-bottom: 0.3rem;
			.green-span {
				color: rgba(45, 174, 169, 1);
			}
		}
		.znzd-timebaohu {
			.item {
				.top {
					cursor: pointer;
					display: flex;
					justify-content: space-between;
					align-items: center;
					&::after {
						content: '';
						width: 0.1125rem;
						height: 0.2rem;
						background: url('../../../assets/image/equipment/icon-open.png') no-repeat;
						background-size: 100% 100%;
					}
					.red {
						color: rgba(252, 120, 76, 1);
					}
					.green {
						color: rgba(45, 174, 169, 1);
					}
				}
				.bottom {
					margin-top: 0.3rem;
					padding: 0.3rem;
					background: #e7f1f3;
					border-radius: 0.125rem;
					.item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						.green {
							color: rgba(45, 174, 169, 1);
						}
					}
					.mb20 {
						margin-bottom: 0.25rem;
					}
				}
			}
		}
		.znzd-tab {
			display: flex;
			align-items: center;
			justify-content: space-around;
			.item {
				cursor: pointer;
				font-size: 0.2rem;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #999999;
				padding-bottom: 0.15rem;
				border-bottom: 0.025rem solid transparent;
			}
			.itemAct {
				color: rgba(45, 174, 169, 1);
				border-bottom: 0.025rem solid rgba(45, 174, 169, 1);
			}
			.item-disable {
				pointer-events: none;
				cursor: not-allowed;
			}
		}
		.zsctishi-box {
			height: 3.1875rem;
			box-sizing: border-box;
			padding-left: 0.2875rem;
			display: flex;
			align-items: center;
			.jiqi-box {
				width: 1.5125rem;
				height: 2.2125rem;
				background: url('../../../assets/image/product/jiqiren.png') no-repeat;
			}
			.txt-box {
				margin-left: 0.25rem;
				height: 3.1875rem;
				width: 2.5rem;
				box-sizing: border-box;
				padding-top: 0.75rem;
				.tishi-content {
					position: relative;
					text-align: left;
					padding: 0.25rem;
					background: #2daea9;
					border-radius: 8px;
					color: #ffffff;
					line-height: 26px;
					font-size: 0.2rem;
					&::after {
						position: absolute;
						top: 0.075rem;
						left: -0.4rem;
						content: '';
						width: 0;
						height: 0;
						border: 20px solid transparent;
						border-top-width: 10px;
						border-bottom-width: 10px;
						border-right-color: #2daea9;
					}
				}
			}
		}
		.zscspantishi {
			color: rgba(252, 120, 76, 1);
			font-size: 0.175rem;
		}
		.zsc-box {
			flex: 1;
			display: flex;
			flex-direction: column;
			.top-alltime-box {
				margin-top: 0.25rem;
				height: 0.7rem;
				background: #e7f1f3;
				box-sizing: border-box;
				padding: 0 0.3rem;
				border-radius: 0.125rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.green {
					color: rgba(45, 174, 169, 1);
				}
			}
			.wzposition-box {
				margin-top: 0.3rem;
				height: 2.7875rem;
				background: #e7f1f3;
				border-radius: 0.125rem;
				box-sizing: border-box;
				.header {
					display: flex;
					height: 0.775rem;
					box-sizing: border-box;
					border-bottom: 1px solid #fff;
					align-items: center;
					padding-left: 0.3rem;
					padding-right: 0.3rem;
					img {
						width: 0.2rem;
						height: 0.2rem;
						margin-left: 0.2rem;
					}
					.green {
						cursor: pointer;
						color: #2daea9;
					}
					.tool-box {
						&:hover .txt-box {
							display: block;
						}
						position: relative;
						.txt-box {
							display: none;
							position: absolute;
							z-index: 1;
							top: 25px;
							right: -237px;
							width: 384px;
							height: 176px;
							background: #2daea9;
							border-radius: 0.125rem;
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffffff;
							line-height: 24px;
							box-sizing: border-box;
							padding: 0.1875rem;
							&::after {
								position: absolute;
								top: -0.1875rem;
								left: 1.6125rem;
								content: '';
								width: 0;
								height: 0;
								border-style: solid;
								border-color: transparent;
								border-width: 10px;
								border-bottom-color: #2daea9;
							}
						}
					}
				}
				.countdown-box {
					margin-top: 0.3rem;
					box-sizing: border-box;
					padding: 0 0.3rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.time {
						font-size: 0.4rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #2daea9;
						span {
							font-size: 0.4rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #2daea9;
						}
					}
					img {
						cursor: pointer;
					}
					.mr20 {
						margin-right: 0.25rem;
					}
				}
				.btn-box {
					margin-top: 0.375rem;
					display: flex;
					padding: 0 0.3rem;
					.item {
						cursor: pointer;
						width: 1.2375rem;
						height: 0.7rem;
						background: #ffffff;
						border-radius: 0.125rem;
						display: flex;
						align-items: center;
						justify-content: space-between;
						box-sizing: border-box;
						padding: 0 0.25rem;
					}
					.item-act {
						color: #2daea9;
					}
					.item-disable {
						filter: grayscale(0);
						pointer-events: none;
					}
				}
				.time-type {
					display: flex;
					align-items: center;
					box-sizing: border-box;
					padding: 0 0.3rem;
					margin-top: 0.325rem;
					.flex-l {
						width: 1rem;
					}
					.inputbox {
						flex: 1;
						height: 0.45rem;
						.el-input__inner {
							height: 0.45rem;
							line-height: 0.45rem;
						}
					}
					.flex-r {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						line-height: 21px;
					}
				}
				.mt0 {
					margin-top: 0.15rem;
				}
			}
			.wzposition-box-noclick {
				background: #f6f6f6;

				.header {
					.tool-box {
						// filter: grayscale(0);
						// pointer-events: none;
					}
				}
				.countdown-box,
				.btn-box {
					// filter: grayscale(1);
					// pointer-events: none;
					// opacity: 0.6;
				}
				.countdown-box {
					color: rgba(153, 153, 153, 1);
					.time {
						color: rgba(153, 153, 153, 1);
						span {
							color: rgba(153, 153, 153, 1);
						}
					}
					img {
						pointer-events: none;
						filter: grayscale(1);
						// opacity: 0.6;
					}
					.mr20 {
						margin-right: 0.25rem;
					}
				}
				.btn-box {
					// filter: grayscale(1);
					pointer-events: none;
					.item {
						color: rgba(153, 153, 153, 1);
						img {
							filter: grayscale(1);
						}
					}
				}
			}
		}
		.tishibtn-box {
			margin-top: 0.3rem;
			height: 3.925rem;
			background: #ffffff;
			border: 1px solid #2daea9;
			border-radius: 0.125rem;
			box-sizing: border-box;
			padding: 0.3rem 0.1875rem 0.2125rem 0.3rem;
			.top {
				display: flex;
				height: 208px;
				.img-box {
					display: flex;
					flex-direction: column;
					align-items: center;
					img {
						height: 2.25rem;
					}
					.green {
						margin-top: 0.15rem;
						color: rgba(45, 174, 169, 1);
					}
				}
				.right-box {
					margin-left: 0.3625rem;
					.txt {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #2daea9;
						line-height: 1.5;
						margin-bottom: 0.2625rem;
					}
				}
			}
			.line {
				margin-top: 0.2rem;
				margin-bottom: 0.2rem;
			}
			.btn-box {
				display: flex;
				.item {
					cursor: pointer;
					width: 1.1875rem;
					height: 0.45rem;
					background: #ffffff;
					border: 1px solid #2daea9;
					border-radius: 0.05rem;
					text-align: center;
					line-height: 0.45rem;

					color: #2daea9;
				}
				.item-act {
					background: #2daea9;
					color: #fff;
				}
				.item-disable {
					pointer-events: none;
					filter: grayscale(1);
					cursor: not-allowed !important;
				}
			}
		}
	}
	.Information {
		position: absolute;
		z-index: 999999;
		top: 40%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) scale(0);
		width: 3.45rem;
		height: 2.075rem;
		background: #ffffff;
		box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		transition: all 0.3s;
		.top {
			box-sizing: border-box;
			height: 1.375rem;
			text-align: center;
			line-height: 1.375rem;
			border-bottom: 1px solid #f1f1f1;
		}
		.bottom {
			display: flex;
			height: 0.6875rem;
			justify-content: space-around;
			align-items: center;
			& > div {
				cursor: pointer;
			}
			.line {
				width: 1px;
				height: 0.45rem;
				background: #f1f1f1;
			}
			.green {
				color: rgba(45, 174, 169, 1);
			}
		}
	}
	.Information-show {
		transform: translateX(-50%) translateY(-50%) scale(1);
	}
}
</style>
