<template>
  <div class="msg" :class="{ msgShow: show }">
    {{ txt }}
  </div>
</template>

<script>
/**
 * 2022 11 29
 * 用于登录时的提示信息，父元素要设置相对定位
 *
 * **/
export default {
  data() {
    return {
      txt: "",
      show: false, //控制显示
      timer: null,
    };
  },
  methods: {
    msgShow(txt, time = 3) {
      if (this.timer) {
        return;
      }
      this.timer = null;
      this.txt = txt;
      this.show = true;
      this.timer = setTimeout(() => {
        this.show = false;
        setTimeout(() => {
          clearTimeout(this.timer);
          this.timer = null;
        }, 300);
      }, time * 1000);
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.msg {
  position: absolute;
  z-index: 9999999;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0);
  padding: 0.2375rem;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #f7f7f7;
  line-height: 1.5;
  transition: all 0.3s;
  text-align: center;
  white-space: nowrap;
}
.msgShow {
  transform: translateX(-50%) translateY(-50%) scale(1);
}
</style>
