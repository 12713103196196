<template>
  <div class="removeShare" v-if="drawer">
    <el-drawer
      custom-class="removeShareDrawer"
      title="解除共享"
      :visible.sync="drawer"
      size="23%"
    >
      <el-checkbox v-model="allchecked" @change="allcheckedChange"
        >全选</el-checkbox
      >
      <!-- 产品列表 -->
      <div class="cpname-list">
        <div class="cpname-item" v-for="(item, index) in cpList" :key="index">
          <div class="item-l">
            <div class="img"></div>
            {{ item.name }}
          </div>
          <el-checkbox
            v-model="item.isCheck"
            @change="itemcheckedChange(index)"
          ></el-checkbox>
        </div>
      </div>
      <div class="text-box">
        温馨提示：当设备解除共享时，所有接受共享的用户将 一并解除共享
      </div>
      <!-- 确定按钮 -->
      <div class="btn-box">
        <div class="btn-item cancel" @click="drawer = false">取消</div>
        <div class="btn-item" @click="btnClick">确定</div>
      </div>
    </el-drawer>
    <Msg ref="tjmsg"></Msg>
  </div>
</template>

<script>
import Msg from "@/components/msg.vue"; //信息提示组件
export default {
  components: { Msg },
  data() {
    return {
      // 控制抽屉的开关
      drawer: false,
      //   是否全选
      allchecked: false,
      //   产品列表
      cpList: [
        {
          name: "产品名称",
          isCheck: false,
        },
        {
          name: "产品名称",
          isCheck: false,
        },
        {
          name: "产品名称",
          isCheck: false,
        },
        {
          name: "产品名称",
          isCheck: false,
        },
        {
          name: "产品名称",
          isCheck: false,
        },
        {
          name: "产品名称",
          isCheck: false,
        },
        {
          name: "产品名称",
          isCheck: false,
        },
      ],
    };
  },
  methods: {
    // 确定按钮点击
    btnClick() {
      this.$refs.tjmsg.msgShow("解除共享成功", 1.5);
      this.drawer = false;
    },
    // 解除共享全选按钮状态改变
    allcheckedChange() {
      if (this.allchecked) {
        this.cpList.forEach((item, index) => {
          item.isCheck = true;
        });
      } else {
        this.cpList.forEach((item, index) => {
          item.isCheck = false;
        });
      }
    },
    // 单个选中
    itemcheckedChange(index) {
      let arr = this.cpList.filter((item, index) => {
        if (!item.isCheck) {
          return item;
        }
      });
      if (!arr.length) {
        this.allchecked = true;
      } else {
        this.allchecked = false;
      }
    },
  },
};
</script>

<style lang="scss">
.removeShare {
  .removeShareDrawer {
    border-radius: 0.225rem 0px 0px 0.225rem;
    .el-drawer__header {
      height: 0.825rem;
      box-sizing: border-box;
      text-align: left;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #454545;
      padding-top: 0;
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 0.225rem;
      span {
        font-size: 0.225rem;
      }
    }
    .el-drawer__close-btn {
      color: #454545;
    }
    .el-drawer__body {
      padding: 0 0.3rem 0.25rem;
      display: flex;
      flex-direction: column;
      text-align: left;

      .btn-box {
        flex: 1;
        // position: relative;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        .btn-item {
          box-sizing: border-box;
          border: 0.0125rem solid #2daea9;
          cursor: pointer;
          width: 1.75rem;
          height: 0.45rem;
          background: #2daea9;
          border-radius: 0.05rem;
          text-align: center;
          line-height: 0.45rem;
          color: white;
        }
        .cancel {
          background: #fff;
          color: #2daea9;
        }
      }
      .cpname-list {
        margin-top: 0.3rem;
        max-height: 8.5rem;
        overflow: auto;
        .cpname-item {
          height: 1rem;
          background: #f1f9fa;
          border-radius: 0.125rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 0.3rem 0 0.25rem;
          margin-bottom: 0.25rem;
          .item-l {
            display: flex;
            align-items: center;
            .img {
              width: 0.6rem;
              height: 0.6rem;
              background: #ffffff;
              border: 1px solid #2daea9;
              border-radius: 50%;
              margin-right: 0.2125rem;
            }
          }
        }
      }
      .text-box {
        margin: 0.125rem auto 0;
        width: 80%;
        font-size: 0.175rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fc784c;
        line-height: 0.25rem;
      }
    }
  }
}
</style>