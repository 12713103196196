<template>
	<el-drawer custom-class="alarmConfiguration" title="告警配置" :visible.sync="drawer" size="23%" @close="drawerClose">
		<div class="my-sel-box">
			<div class="my-sel-header">
				<el-input v-model="input" readonly placeholder="请选择" @focus="inputFocus" @blur="inputBlur" ref="myInput">
					<i slot="suffix" class="el-input__icon el-icon-caret-bottom" :class="{ rotating: isInputFocus }"></i
				></el-input>
			</div>
			<div class="change-centent" :class="{ 'change-centent-show ': isInputFocus }" @mousedown="searchHandle">
				<!-- v-if="isInputFocus" -->
				<div class="centent-box" v-if="isInputFocus">
					<div
						class="item"
						:class="{ 'item-active': judgeIsCheck(item.id) }"
						v-for="(item, index) in optionList"
						:key="index"
						@click="optionClick(item.id)"
					>
						{{ item.label }}
					</div>
				</div>
			</div>
		</div>
		<!-- 添加盒子 -->
		<div class="addrc-box" @click="insideSwitch.addGaojing = true">
			<img src="@/assets/image/product/tianjia.png" alt="" />
			<div>点击添加告警配置</div>
		</div>
		<div class="line"></div>
		<div class="list-box roll-auto" v-if="configurationList.length">
			<div class="item" @click.stop="gjItemClick(item)" v-for="(item, index) in configurationList" :key="index">
				<div class="top">
					{{ item.name }}
					<img v-if="item.status" src="@/assets/image/product/qiyong.png" alt="" @click.stop="statusClick(index)" />
					<img v-else src="@/assets/image/product/jinyong.png" alt="" @click.stop="statusClick(index)" />
				</div>
				<div class="bottom" v-html="item.content"></div>
			</div>
		</div>
		<div class="list-box-no" v-else>
			<img src="@/assets/image/home/ZWT.png" alt="" />
		</div>
		<!-- 确定按钮 -->
		<div class="btn-box" v-if="configurationList.length">
			<div class="btn-item" @click="insideSwitch.delete = true">删除</div>
		</div>
		<!-- 里面潜逃的抽屉 -->
		<!-- 删除告警配置 -->
		<el-drawer
			title="告警配置"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.delete"
			custom-class="insideDrawe flcu-box"
		>
			<div class="flex flex_a_c flex_ju_sb font_16">
				<el-checkbox v-model="deleteIsAll" @change="deleteAllClick">全选</el-checkbox>
				<div class="font_16 c_45">已选{{ deleteNum }}项</div>
			</div>
			<!-- 删除列表 -->
			<div class="gj-delete-list roll-auto">
				<div class="item" v-for="(item, index) in configurationList" :key="index">
					<div class="top">
						{{ item.name }}
						<el-checkbox v-model="item.isCheck"></el-checkbox>
					</div>
					<div class="bottom" v-html="item.content"></div>
				</div>
			</div>
			<!-- 确定按钮 -->
			<div class="btn-box">
				<div class="btn-item cancel" @click="insideSwitch.delete = false">取消</div>
				<div class="btn-item" @click="InformationOpen">删除</div>
			</div>
			<!-- 确定删除信息警告 -->
			<div class="Information" :class="{ 'Information-show': Information }">
				<div class="top">确定删除吗？</div>
				<div class="bottom">
					<div @click="Information = false">取消</div>
					<div class="line"></div>
					<div class="green" @click="deleteBtnClick">确定</div>
				</div>
			</div>
			<Msg ref="tsmsg2"></Msg>
		</el-drawer>
		<!-- 添加告警配置 -->
		<el-drawer
			title="告警配置"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.addGaojing"
			custom-class="insideDrawe flcu-box"
			@close="addGaojingClose"
		>
			<div class="c_45">传感器选择</div>
			<div class="gj-selbox">
				<el-select v-model="addGjFrom.cgqAct" placeholder="请选择传感器" @change="addcgqChange">
					<el-option
						v-for="item in cgqList"
						:key="item.subDeviceNum"
						:label="item.subDeviceName"
						:value="item.subDeviceNum"
					>
					</el-option>
				</el-select>
			</div>
			<div class="c_45">参数选择</div>
			<div class="gj-selbox">
				<el-select v-model="addGjFrom.csAct" placeholder="请选择参数" @change="addcsChange">
					<el-option
						v-for="item in csList"
						:key="item.dataItemCode"
						:label="item.dataItemName"
						:value="item.dataItemCode"
					>
					</el-option>
				</el-select>
			</div>
			<div class="gjsrbox" v-show="addGjFrom.csAct.length">
				<div>
					如果【<span class="green">{{ checkName }}</span
					>】
				</div>
				<div class="inpbox">
					高于
					<div class="input-box">
						<el-input
							v-model="addGjFrom.ceiling"
							placeholder="请输入数值"
							type="number"
							@input="onlyNumber($event, 'addGjFrom', 'ceiling')"
						>
							<i slot="suffix" class="suffix-content"> {{ checkUNit }} </i>
						</el-input>
					</div>
				</div>
				<div>就执行【<span class="green">告警信息推送</span>】</div>
			</div>
			<div class="gjsrbox" v-show="addGjFrom.csAct.length">
				<div>
					如果【<span class="green">{{ checkName }}</span
					>】
				</div>
				<div class="inpbox">
					低于
					<div class="input-box">
						<el-input
							v-model="addGjFrom.lower"
							placeholder="请输入数值"
							type="number"
							@input="onlyNumber($event, 'addGjFrom', 'lower')"
						>
							<i slot="suffix" class="suffix-content"> {{ checkUNit }} </i>
						</el-input>
					</div>
				</div>
				<div>就执行【<span class="green">告警信息推送</span>】</div>
			</div>
			<!-- 确定按钮 -->
			<div class="btn-box">
				<div class="btn-item cancel" @click="quxiaogjbj">取消</div>
				<div class="btn-item" @click="quedingAddbtnClick">确定</div>
			</div>
			<!-- 确定删除信息警告 -->
			<div class="Information" :class="{ 'Information-show': Information }">
				<div class="top">
					是否确定取消？<br />
					取消后数据将不会保存
				</div>
				<div class="bottom">
					<div @click="Information = false">取消</div>
					<div class="line"></div>
					<div class="green" @click="quxiaogjbj">确定</div>
				</div>
			</div>
			<Msg ref="tsmsg3"></Msg>
		</el-drawer>
		<!-- 编辑告警配置 -->
		<el-drawer
			title="告警配置"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.bianjiGaojing"
			custom-class="insideDrawe flcu-box"
			@close="addGaojingClose"
		>
			<div class="c_45">传感器选择</div>
			<div class="gj-selbox">
				<el-select v-model="bianjiGjFrom.subDeviceNum" placeholder="请选择传感器" @change="bjcgqChange">
					<el-option
						v-for="item in cgqList"
						:key="item.subDeviceNum"
						:label="item.subDeviceName"
						:value="item.subDeviceNum"
					>
					</el-option>
				</el-select>
			</div>
			<div class="c_45">参数选择</div>
			<div class="gj-selbox">
				<el-select v-model="bianjiGjFrom.dataItemCode" placeholder="请选择参数" @change="bjcsChange">
					<el-option
						v-for="item in csList"
						:key="item.dataItemCode"
						:label="item.dataItemName"
						:value="item.dataItemCode"
					>
					</el-option>
				</el-select>
			</div>
			<div class="gjsrbox" v-show="bianjiGjFrom.dataItemCode.length">
				<div>
					如果【<span class="green">{{ checkName2 }}</span
					>】
				</div>
				<div class="inpbox">
					高于
					<div class="input-box">
						<el-input
							v-model="bianjiGjFrom.alarmUpper"
							placeholder="请输入数值"
							type="number"
							@input="onlyNumber($event, 'bianjiGjFrom', 'alarmUpper')"
						>
							<i slot="suffix" class="suffix-content"> {{ checkUNit2 }} </i>
						</el-input>
					</div>
				</div>
				<div>就执行【<span class="green">告警信息推送</span>】</div>
			</div>
			<div class="gjsrbox" v-show="bianjiGjFrom.dataItemCode.length">
				<div>
					如果【<span class="green">{{ checkName2 }}</span
					>】
				</div>
				<div class="inpbox">
					低于
					<div class="input-box">
						<el-input
							v-model="bianjiGjFrom.alarmLower"
							placeholder="请输入数值"
							type="number"
							@input="onlyNumber($event, 'bianjiGjFrom', 'alarmLower')"
						>
							<i slot="suffix" class="suffix-content"> {{ checkUNit2 }} </i>
						</el-input>
					</div>
				</div>
				<div>就执行【<span class="green">告警信息推送</span>】</div>
			</div>
			<!-- 确定按钮 -->
			<div class="btn-box">
				<div class="btn-item cancel" @click="Information = true">取消</div>
				<div class="btn-item" @click="quedingBianjibtnClick">确定</div>
			</div>
			<!-- 确定删除信息警告 -->
			<div class="Information" :class="{ 'Information-show': Information }">
				<div class="top">
					是否确定取消？<br />
					取消后数据将不会保存
				</div>
				<div class="bottom">
					<div @click="Information = false">取消</div>
					<div class="line"></div>
					<div class="green" @click="quxiaogjbj">确定</div>
				</div>
			</div>
			<Msg ref="tsmsg4"></Msg>
		</el-drawer>
		<Msg ref="tsmsg1"></Msg>
	</el-drawer>
</template>

<script>
import Msg from '@/components/msg.vue' //信息提示组件
export default {
	components: { Msg },
	data() {
		return {
			// 控制抽屉的开关
			drawer: false,
			// 控制里面抽屉的开关
			insideSwitch: {
				delete: false, //删除
				addGaojing: false, //添加告警
				bianjiGaojing: false, //编辑告警
			},
			// 控制警告信息
			Information: false,
			// 输入框是否聚焦
			isInputFocus: false,
			// 设备编码
			deviceNum: '',
			//   下拉选择列表
			optionList: [
				// {
				//   id: "all",
				//   label: "全部",
				// },
				// {
				//   id: "1",
				//   label: "四合一传感器",
				// },
				// {
				//   id: "2",
				//   label: "六合一传感器",
				// },
				// {
				//   id: "3",
				//   label: "八合一传感器",
				// },
				// {
				//   id: "4",
				//   label: "十合一传感器",
				// },
				// {
				//   id: "5",
				//   label: "十二合一传感器",
				// },
			],
			//   下拉选中列表
			changeList: ['all'],
			//告警配置列表
			configurationList: [
				// {
				//   name: "六合一传感器-空气温度",
				//   status: true,
				//   content: "如果【空气温度】高于【25.5】℃时<br/>就执行告警信息推送",
				//   isCheck: false,
				// },
				// {
				//   name: "六合一传感器-空气温度",
				//   status: false,
				//   content: "如果【空气温度】高于【25.5】℃时<br/>就执行告警信息推送",
				//   isCheck: false,
				// },
				// {
				//   name: "六合一传感器-空气温度",
				//   status: true,
				//   content:
				//     "如果【空气温度】高于【25.5】℃时<br/>就执行告警信息推送<br/>如果【空气温度】高于【25.5】℃时<br/>就执行告警信息推送",
				//   isCheck: false,
				// },
			],
			// 删除抽屉
			// 删除是否全选
			deleteIsAll: false,
			// 传感器列表
			cgqList: [
				// {
				//   value: "选项1",
				//   label: "黄金糕",
				// },
				// {
				//   value: "选项2",
				//   label: "双皮奶",
				// },
				// {
				//   value: "选项3",
				//   label: "蚵仔煎",
				// },
				// {
				//   value: "选项4",
				//   label: "龙须面",
				// },
				// {
				//   value: "选项5",
				//   label: "北京烤鸭",
				// },
			],
			// 参数列表
			csList: [
				// {
				//   value: "选项1",
				//   label: "黄金糕",
				// },
				// {
				//   value: "选项2",
				//   label: "双皮奶",
				// },
				// {
				//   value: "选项3",
				//   label: "蚵仔煎",
				// },
				// {
				//   value: "选项4",
				//   label: "龙须面",
				// },
				// {
				//   value: "选项5",
				//   label: "北京烤鸭",
				// },
			],
			addGjFrom: {
				cgqAct: '',
				csAct: '',
				ceiling: '',
				lower: '',
			},
			// 编辑表单
			bianjiGjFrom: {
				id: '',
				deviceNum: '',
				subDeviceNum: '',
				subDeviceName: '',
				dataItemCode: '',
				unit: '',
				dataItemName: '',
				alarmUpper: '',
				alarmLower: '',
				effectiveState: '',
				name: '',
				status: false,
				isCheck: false,
				content: '',
			},
		}
	},
	computed: {
		input() {
			let arr = []
			this.optionList.forEach((item, index) => {
				this.changeList.forEach((item2, index2) => {
					if (item.id == item2) {
						arr.push(item.label)
					}
				})
			})
			return arr.join(',')
		},
		// 删除已选择的数量
		deleteNum() {
			let arr = this.configurationList.filter((item) => {
				return item.isCheck == true
			})
			if (arr.length == this.configurationList.length) {
				this.deleteIsAll = true
			} else {
				this.deleteIsAll = false
			}
			return arr.length
		},
		// 选中的参数名字
		checkName() {
			let name = ''
			this.csList.forEach((item) => {
				if (item.dataItemCode == this.addGjFrom.csAct) {
					name = item.dataItemName
				}
			})
			return name
		},
		// 选中的参数名字
		checkName2() {
			let name = ''
			this.csList.forEach((item) => {
				if (item.dataItemCode == this.bianjiGjFrom.dataItemCode) {
					name = item.dataItemName
				}
			})
			return name
		},
		// 选中参数单位
		checkUNit() {
			let unit = ''
			this.csList.forEach((item) => {
				if (item.dataItemCode == this.addGjFrom.csAct) {
					unit = item.unitName
				}
			})
			return unit
		},
		// 选中参数单位
		checkUNit2() {
			let unit = ''
			this.csList.forEach((item) => {
				if (item.dataItemCode == this.bianjiGjFrom.dataItemCode) {
					unit = item.unitName
				}
			})
			return unit
		},
	},
	methods: {
		drawerClose() {
			this.drawer = false
		},
		drawerCloseAll() {
			if (this.insideSwitch.addGaojing) {
				this.addGaojingClose()
			}
			if (this.insideSwitch.bianjiGaojing) {
				this.addGaojingClose()
			}
			this.insideSwitch.delete = false
			this.drawerClose()
		},
		// 输入框获取焦点
		inputFocus() {
			this.isInputFocus = true
		},
		// 输入框失去焦点
		inputBlur() {
			this.isInputFocus = false
			this.stAlarmConfList()
		},
		// 让输入框获取焦点
		getFocus() {
			this.$refs.myInput.focus()
		},
		searchHandle(event) {
			// 即可阻止点击按钮时触发input失去焦点事件
			event.preventDefault()
		},
		// 判断是否选中
		judgeIsCheck(id) {
			let arr = []
			arr = this.changeList.filter((item, index) => {
				return item == id
			})
			if (arr.length) {
				return true
			} else {
				return false
			}
		},
		// 下拉项点击
		optionClick(id) {
			if (id == 'all') {
				if (this.changeList.indexOf('all') !== -1) {
					if (this.changeList.length == 1) {
						return
					}
				} else {
					this.changeList = []
					this.changeList.push(id)
				}
			} else {
				if (this.changeList.indexOf('all') !== -1) {
					this.changeList.splice(this.changeList.indexOf('all'), 1)
					this.changeList.push(id)
				} else {
					let arr = []
					let indexNum
					arr = this.changeList.filter((item, index) => {
						return item == id
					})
					if (arr.length) {
						indexNum = this.changeList.indexOf(id)
						this.changeList.splice(indexNum, 1)
						if (this.changeList.length == 0) {
							this.changeList.push('all')
						}
					} else {
						this.changeList.push(id)
					}
				}
			}
		},
		// 列表项状态按钮点击
		statusClick(index) {
			// this.configurationList[index].status =
			//   !this.configurationList[index].status;
			let enable = !this.configurationList[index].status ? '1' : '0'
			let id = this.configurationList[index].id
			this.enableAlarmConf(id, enable)
		},
		// 删除全选点击
		deleteAllClick() {
			if (this.deleteIsAll) {
				this.configurationList.forEach((item, index) => {
					item.isCheck = true
				})
			} else {
				this.configurationList.forEach((item, index) => {
					item.isCheck = false
				})
			}
		},
		// 删除告警确认按钮点击
		deleteBtnClick() {
			this.removeAlarms()
		},
		// 确定取消告警配置
		quxiaogjbj() {
			this.Information = false
			this.insideSwitch.addGaojing = false
			this.insideSwitch.bianjiGaojing = false
		},
		// 传感器切换
		addcgqChange() {
			let index = null
			// console.log(this.bianjiGjFrom.subDeviceNum);
			this.cgqList.forEach((item, index_) => {
				if (item.subDeviceNum == this.addGjFrom.cgqAct) {
					index = index_
					console.log(item)
					// this.csList = this.cgqList[index].itemList
					this.csList = item.itemList
				}
			})
			console.log(this.csList)

			this.bianjiGjFrom.dataItemCode = ''
			this.addGjFrom.csAct = ''
			this.addGjFrom.ceiling = ''
			this.addGjFrom.lower = ''
		},
		// 传感器参数切换
		addcsChange() {
			this.addGjFrom.ceiling = ''
			this.addGjFrom.lower = ''
		},
		bjcsChange() {
			this.bianjiGjFrom.alarmUpper = ''
			this.bianjiGjFrom.alarmLower = ''
		},
		// 传感器切换
		bjcgqChange() {
			let index = null
			this.cgqList.forEach((item, index_) => {
				if (item.subDeviceNum == this.bianjiGjFrom.subDeviceNum) {
					index = index_
				}
			})
			this.csList = this.cgqList[index].itemList
			this.addGjFrom.csAct = ''
			this.bianjiGjFrom.alarmUpper = ''
			this.bianjiGjFrom.alarmLower = ''
		},
		// 确定添加告警信息点击
		quedingAddbtnClick() {
			if (!this.addGjFrom.cgqAct) {
				this.$refs.tsmsg3.msgShow('请选择传感器', 1.5)
				return
			}
			if (!this.addGjFrom.csAct) {
				this.$refs.tsmsg3.msgShow('请选择参数', 1.5)
				return
			}
			if (!(this.addGjFrom.ceiling || this.addGjFrom.lower)) {
				this.$refs.tsmsg3.msgShow('请输入告警值', 1.5)
				return
			}
			if (!this.addGjFrom.ceiling) {
				this.$refs.tsmsg3.msgShow('请输入上限', 1.5)
				return
			}
			if (!this.addGjFrom.lower) {
				this.$refs.tsmsg3.msgShow('请输入下限', 1.5)
				return
			}
			if (this.addGjFrom.ceiling && this.addGjFrom.lower) {
				if (Number(this.addGjFrom.ceiling) <= Number(this.addGjFrom.lower)) {
					this.$refs.tsmsg3.msgShow('告警最低【' + this.checkName + '】需低于告警最高【' + this.checkName + '】', 1.5)
					return
				}
				const rsg = /^-?\d{1,3}$/
				if (!rsg.test(this.addGjFrom.ceiling) || !rsg.test(this.addGjFrom.lower)) {
					this.$refs.tsmsg3.msgShow('告警值为整数', 1.5)
					return
				}
				this.setAlarmConf()
			}
			// if (this.addGjFrom.cgqAct) {
			//   if (this.addGjFrom.csAct) {
			//     if (this.addGjFrom.ceiling || this.addGjFrom.lower) {
			//       if (this.addGjFrom.ceiling && this.addGjFrom.lower) {
			//         if (
			//           Number(this.addGjFrom.ceiling) > Number(this.addGjFrom.lower)
			//         ) {
			//           this.setAlarmConf();
			//         } else {
			//           this.$refs.tsmsg3.msgShow(
			//             "告警最低【" +
			//               this.checkName +
			//               "】需低于告警最高【" +
			//               this.checkName +
			//               "】",
			//             1.5
			//           );
			//           return;
			//         }
			//       } else {
			//         this.setAlarmConf();
			//       }
			//     } else {
			//       this.$refs.tsmsg3.msgShow("请输入告警值", 1.5);
			//       return;
			//     }
			//   } else {
			//     this.$refs.tsmsg3.msgShow("请选择参数", 1.5);
			//     return;
			//   }
			// } else {
			//   this.$refs.tsmsg3.msgShow("请选择传感器", 1.5);
			//   return;
			// }
		},
		// 确认编辑点击
		quedingBianjibtnClick() {
			if (!this.bianjiGjFrom.subDeviceNum) {
				this.$refs.tsmsg4.msgShow('请选择传感器', 1.5)
				return
			}
			if (!this.bianjiGjFrom.dataItemCode) {
				this.$refs.tsmsg4.msgShow('请选择参数', 1.5)
				return
			}
			if (!(this.bianjiGjFrom.alarmUpper || this.bianjiGjFrom.alarmLower)) {
				this.$refs.tsmsg4.msgShow('请输入告警值', 1.5)
				return
			}
			if (this.bianjiGjFrom.alarmUpper && this.bianjiGjFrom.alarmLower) {
				if (Number(this.bianjiGjFrom.alarmUpper) >= Number(this.bianjiGjFrom.alarmLower)) {
					this.$refs.tsmsg4.msgShow('告警最低【' + this.checkName + '】需低于告警最高【' + this.checkName + '】', 1.5)
					return
				}
				const rsg = /^-?\d{1,3}$/
				if (!rsg.test(this.bianjiGjFrom.alarmUpper) || !rsg.test(this.bianjiGjFrom.alarmLower)) {
					this.$refs.tsmsg4.msgShow('告警值为整数', 1.5)
					return
				}
				this.setAlarmConf2()
			}
			// if (this.bianjiGjFrom.subDeviceNum) {
			//   if (this.bianjiGjFrom.dataItemCode) {
			//     if (this.bianjiGjFrom.alarmUpper || this.bianjiGjFrom.alarmLower) {
			//       if (this.bianjiGjFrom.alarmUpper && this.bianjiGjFrom.alarmLower) {
			//         if (
			//           Number(this.bianjiGjFrom.alarmUpper) >
			//           Number(this.bianjiGjFrom.alarmLower)
			//         ) {
			//           this.setAlarmConf2();
			//         } else {
			//           this.$refs.tsmsg4.msgShow(
			//             "告警最低【" +
			//               this.checkName +
			//               "】需低于告警最高【" +
			//               this.checkName +
			//               "】",
			//             1.5
			//           );
			//           return;
			//         }
			//       } else {
			//         this.setAlarmConf2();
			//       }
			//     } else {
			//       this.$refs.tsmsg4.msgShow("请输入告警值", 1.5);
			//       return;
			//     }
			//   } else {
			//     this.$refs.tsmsg4.msgShow("请选择参数", 1.5);
			//     return;
			//   }
			// } else {
			//   this.$refs.tsmsg4.msgShow("请选择传感器", 1.5);
			//   return;
			// }
		},
		// 添加告警信息关闭
		addGaojingClose() {
			this.addGjFrom = {
				cgqAct: '',
				csAct: '',
				ceiling: '',
				lower: '',
			}
			this.csList = []
		},
		// 告警列表点击编辑
		gjItemClick(item) {
			this.bianjiGjFrom = JSON.parse(JSON.stringify(item))
			let index = null
			this.cgqList.forEach((item, index_) => {
				if (item.subDeviceNum == this.bianjiGjFrom.subDeviceNum) {
					index = index_
					// this.csList = this.cgqList[index].itemList
					this.csList = item.itemList
				}
			})

			this.insideSwitch.bianjiGaojing = true
		},
		/**
		 * 获取数据
		 * **/
		//查询告警配置列表
		stAlarmConfList() {
			console.log(this.changeList)
			let params = {}
			if (this.changeList.length == 1 && this.changeList[0] == 'all') {
				params = {
					deviceNum: this.deviceNum,
					subDeviceNumList: [],
				}
			} else {
				params = {
					deviceNum: this.deviceNum,
					subDeviceNumList: this.changeList,
				}
			}

			this.$http.postAction(this.$api.stAlarmConfList, params).then((res) => {
				// res = {
				//   code: 200,
				//   msg: "ok",
				//   data: [
				//     {
				//       id: "409",
				//       deviceNum: "22111700001",
				//       subDeviceNum: "1",
				//       subDeviceName: "六合一传感器",
				//       dataItemCode: "KQWD",
				//       unit: "℃",
				//       dataItemName: "空气温度",
				//       alarmUpper: "40",
				//       alarmLower: "30",
				//       effectiveState: "0",
				//     },
				//     {
				//       id: "410",
				//       deviceNum: "22111700001",
				//       subDeviceNum: "1",
				//       subDeviceName: "六合一传感器",
				//       dataItemCode: "KQWD",
				//       unit: "℃",
				//       dataItemName: "空气温度",
				//       alarmUpper: "3",
				//       alarmLower: "2",
				//       effectiveState: "1",
				//     },
				//     {
				//       id: "417",
				//       deviceNum: "22111700001",
				//       subDeviceNum: "1",
				//       subDeviceName: "六合一传感器",
				//       dataItemCode: "KQWD",
				//       unit: "℃",
				//       dataItemName: "空气温度",
				//       alarmUpper: "3",
				//       alarmLower: "2",
				//       effectiveState: "1",
				//     },
				//     {
				//       id: "509",
				//       deviceNum: "22111700001",
				//       subDeviceNum: "1",
				//       subDeviceName: "六合一传感器",
				//       dataItemCode: "KQSD",
				//       unit: "%RH",
				//       dataItemName: "空气湿度",
				//       alarmUpper: "40",
				//       alarmLower: "30",
				//       effectiveState: "1",
				//     },
				//   ],
				// };
				if (res.data.length) {
					let datas = res.data
					datas.forEach((item, index) => {
						item.name = item.subDeviceName + '-' + item.dataItemName
						item.status = item.effectiveState == '0' ? false : true
						item.isCheck = false
						if (item.alarmUpper != '' && item.alarmLower != '') {
							item.content =
								'如果【' +
								item.dataItemName +
								'】高于' +
								'【' +
								item.alarmUpper +
								'】' +
								item.unit +
								'时<br/>就执行告警信息推送<br/>如果【' +
								item.dataItemName +
								'】低于' +
								'【' +
								item.alarmLower +
								'】' +
								item.unit +
								'时<br/>就执行告警信息推送'
						} else if (item.alarmUpper == '') {
							item.content =
								'如果【' +
								item.dataItemName +
								'】低于' +
								'【' +
								item.alarmLower +
								'】' +
								item.unit +
								'时<br/>就执行告警信息推送'
						} else if (item.alarmLower == '') {
							item.content =
								'如果【' +
								item.dataItemName +
								'】高于' +
								'【' +
								item.alarmUpper +
								'】' +
								item.unit +
								'时<br/>就执行告警信息推送'
						}
					})
					this.configurationList = datas
				} else {
					this.configurationList = []
					if (this.insideSwitch.delete) {
						this.insideSwitch.delete = false
					}
				}
			})
		},
		// 添加告警配置
		setAlarmConf() {
			let params = {
				alarmLower: this.addGjFrom.lower,
				dataItemCode: this.addGjFrom.csAct,
				alarmUpper: this.addGjFrom.ceiling,
				deviceNum: this.deviceNum,
				subDeviceNum: this.addGjFrom.cgqAct,
			}
			this.$http.postAction(this.$api.setAlarmConf, params).then((res) => {
				if (res.code == '200') {
					this.$refs.tsmsg3.msgShow('添加成功', 1.5)
					this.stAlarmConfList()
					setTimeout(() => {
						this.insideSwitch.addGaojing = false
					}, 1500)
				} else {
					this.$refs.tsmsg3.msgShow(res.msg, 1.5)
					this.stAlarmConfList()
					setTimeout(() => {
						this.insideSwitch.addGaojing = false
					}, 1500)
				}
			})
		},
		// 编辑告警配置
		setAlarmConf2() {
			let params = {
				id: this.bianjiGjFrom.id,
				alarmLower: this.bianjiGjFrom.alarmLower,
				dataItemCode: this.bianjiGjFrom.dataItemCode,
				alarmUpper: this.bianjiGjFrom.alarmUpper,
				deviceNum: this.bianjiGjFrom.deviceNum,
				subDeviceNum: this.bianjiGjFrom.subDeviceNum,
			}
			this.$http.postAction(this.$api.setAlarmConf, params).then((res) => {
				if (res.code == '200') {
					this.$refs.tsmsg4.msgShow('编辑成功', 1.5)
					this.stAlarmConfList()
					setTimeout(() => {
						this.insideSwitch.bianjiGaojing = false
					}, 1500)
				} else {
					this.$refs.tsmsg4.msgShow(res.msg, 1.5)
					this.stAlarmConfList()
					setTimeout(() => {
						this.insideSwitch.bianjiGaojing = false
					}, 1500)
				}
			})
		},
		//告警设置-启用禁用
		enableAlarmConf(id, enable) {
			this.$http.postAction(this.$api.enableAlarmConf + '?id=' + id + '&enable=' + enable).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg1.msgShow('设置成功', 1.5)
					this.stAlarmConfList()
				} else {
					this.$refs.tsmsg1.msgShow(res.msg, 1.5)
				}
			})
		},
		// 删除点击
		InformationOpen() {
			let arr = this.configurationList.filter((item, index) => {
				if (item.isCheck) {
					return item
				}
			})
			if (!arr.length) {
				this.$refs.tsmsg2.msgShow('请选择要删除的告警信息', 1.5)
				return
			}
			this.Information = true
		},
		// 告警设置-删除
		removeAlarms() {
			let params = {
				ids: [],
			}
			let arr = this.configurationList.filter((item, index) => {
				if (item.isCheck) {
					return item
				}
			})
			if (arr.length) {
				arr.forEach((item, index) => {
					params.ids.push(item.id)
				})
			} else {
				this.$refs.tsmsg2.msgShow('请选择要删除的告警信息', 1.5)
				return
			}
			this.$http.postAction(this.$api.removeAlarms, params).then((res) => {
				if (res.code == 200) {
					this.stAlarmConfList()
					this.Information = false
					this.$refs.tsmsg2.msgShow('删除成功', 1.5)
				} else {
					this.Information = false
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
				}
			})
		},
		// input 只允许输入整数
		onlyNumber(num, type, keys) {
			let n = String(num)
			const t = n.charAt(0)
			// 先把非数字的都替换掉，除了数字和.
			// n = n.replace(/[^\d\\.]/g, '')
			// 必须保证第一个为数字而不是.
			n = n.replace(/\./g, '').replace(/\-/g, '')
			// // 必须保证第一个数字非0
			n = n.replace(/^0(\d)/g, ($0, $1) => $1)
			// // 保证只有出现一个.而没有多个.
			// n = n.replace(/\.{2,}/g, '.')
			// // 保证.只出现一次，而不能出现两次以上
			// n = n.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
			n = n.substr(0, 4)
			// 如果第一位是负号，则允许添加
			if (t == '-') {
				n = '-' + n
			}
			this[type][keys] = n
			return n
		},
	},
}
</script>
<style>
.el-input__inner {
	cursor: pointer !important;
}
</style>
<style lang="scss" scoped>
/deep/ .alarmConfiguration {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		span {
			font-size: 0.225rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		display: flex;
		flex-direction: column;

		.btn-box {
			flex: 1;
			// position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			.btn-item {
				box-sizing: border-box;
				border: 0.0125rem solid #2daea9;
				cursor: pointer;
				width: 1.75rem;
				height: 0.45rem;
				background: #2daea9;
				border-radius: 0.05rem;
				text-align: center;
				line-height: 0.45rem;
				color: white;
			}
			.cancel {
				background: #fff;
				color: #2daea9;
			}
		}
		.my-sel-box {
			position: relative;
			height: 0.45rem;
			margin-bottom: 0.3rem;
			.my-sel-header {
				width: 100%;
				height: 100%;
			}
			.change-centent {
				position: absolute;
				top: 0.475rem;
				left: 0;
				width: 100%;
				// height: 2.35rem;
				height: 0;
				overflow: hidden;
				background: #ffffff;
				box-sizing: border-box;

				border-radius: 0.05rem;
				transition: height 0.3s;

				.centent-box {
					width: 100%;
					height: 100%;
					box-sizing: border-box;
					padding-right: 4px;
					overflow: auto;
					display: flex;
					flex-wrap: wrap;
					//   justify-content: space-between;
					&::-webkit-scrollbar {
						width: 4px;
						height: 0px;
						background-color: transparent;
					}
					&::-webkit-scrollbar-track {
						border-radius: 4px;
						background-color: transparent;
					}
					&::-webkit-scrollbar-thumb {
						border-radius: 6px;
						background-color: rgba(77, 77, 77, 0.2);
					}
					.item {
						cursor: pointer;
						padding: 0 0.2rem;
						// height: 0.45rem;
						line-height: 0.45rem;
						font-size: 0.2rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #666666;
						background: #ffffff;
						border: 1px solid #f1f1f1;
						border-radius: 4px;
						margin-right: 0.2rem;
					}
					.item-active {
						background: #2daea9;
						color: white;
					}
				}
			}
			.change-centent-show {
				max-height: 2.35rem;
				// min-height: 1.25rem;
				height: auto;
				border: 1px solid #f1f1f1;
				box-shadow: 0px 3px 7px 0px rgba(45, 45, 45, 0.1);
				padding: 0.25rem 0.1rem 0.25rem 0.25rem;
			}
		}
		.rotating {
			transform: rotate(180deg);
		}
		.addrc-box {
			height: 1.1375rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			cursor: pointer;
			box-sizing: border-box;
			padding-top: 0.2rem;
			text-align: center;
			font-size: 0.2rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #2daea9;
			img {
				margin-bottom: 0.175rem;
			}
		}
		.line {
			height: 1px;
			background: #f1f1f1;
			margin: 0.3rem 0;
		}
		.list-box {
			height: 70%;
			overflow: auto;
			.item {
				padding: 0.2rem 0.25rem 0.275rem 0.125rem;
				background: #f1f9fa;
				border-radius: 0.125rem;
				margin-bottom: 0.3rem;
				cursor: pointer;
				&:last-child {
					margin-bottom: 0;
				}
				.top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-left: 14px;
					padding-bottom: 0.2rem;
					border-bottom: 1px solid #fff;
					margin-bottom: 0.1rem;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #454545;
					img {
						cursor: pointer;
					}
				}
				.bottom {
					padding-left: 14px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #454545;
					line-height: 2;
					text-align: left;
				}
			}
			.item:last-child {
				margin-bottom: 0;
			}
		}
		.list-box-no {
			height: 70%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
/deep/ .insideDrawe {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		margin-bottom: 0.25rem;
		span {
			font-size: 0.225rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.flcu-box {
		display: flex;
		flex-direction: column;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		display: flex;
		flex-direction: column;
		position: relative;
		.gj-delete-list {
			margin-top: 0.3rem;
			height: 87%;
			overflow: auto;
			.item {
				padding: 0.2rem 0.25rem 0.275rem 0.125rem;
				background: #f1f9fa;
				border-radius: 0.125rem;
				margin-bottom: 0.3rem;
				&:last-child {
					margin-bottom: 0;
				}
				.top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-left: 14px;
					padding-bottom: 0.2rem;
					border-bottom: 1px solid #fff;
					margin-bottom: 0.1rem;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #454545;
					img {
						cursor: pointer;
					}
				}
				.bottom {
					padding-left: 14px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #454545;
					line-height: 2;
					text-align: left;
				}
			}
			.item:last-child {
				margin-bottom: 0;
			}
		}
		.gj-selbox {
			margin-top: 0.2rem;
			margin-bottom: 0.3rem;
			height: 0.45rem;
			.el-select {
				width: 100%;
				height: 100%;
				.el-input__inner {
					width: 100%;
					height: 0.45rem;
					line-height: 0.45rem;
				}
				.el-input__icon {
					line-height: 0.45rem;
				}
				.el-icon-arrow-up:before {
					content: '\e78f';
				}
			}
		}
		.gjsrbox {
			margin-bottom: 0.3rem;
			height: 2.0375rem;
			background: #f1f9fa;
			border-radius: 0.125rem;
			box-sizing: border-box;
			padding: 0.3rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.green {
				color: rgba(45, 174, 169, 1);
			}
			.inpbox {
				display: flex;
				align-items: center;
				.input-box {
					flex: 1;
					height: 0.45rem;
					margin-left: 0.125rem;
					.el-input__inner {
						height: 0.45rem;
						line-height: 0.45rem;
					}
					.el-input__suffix {
						// width: 4vw;
						height: 100%;
						.el-input__suffix-inner {
							display: block;
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							.suffix-content {
								font-style: normal;
								display: flex;
								align-items: center;
								width: 100%;
								height: 1.099vw;
								box-sizing: border-box;
								border-left: 1px solid #f1f1f1;
								justify-content: space-around;
								padding: 0 0.1875rem;
								img {
									cursor: pointer;
									width: 0.175rem;
									height: 0.175rem;
								}
							}
						}
					}
				}
			}
		}
		.btn-box {
			flex: 1;
			// position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			.btn-item {
				box-sizing: border-box;
				border: 0.0125rem solid #2daea9;
				cursor: pointer;
				width: 1.75rem;
				height: 0.45rem;
				background: #2daea9;
				border-radius: 0.05rem;
				text-align: center;
				line-height: 0.45rem;
				color: white;
			}
			.cancel {
				background: #fff;
				color: #2daea9;
			}
		}
	}
	.Information {
		position: absolute;
		z-index: 999999;
		top: 40%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) scale(0);
		width: 3.45rem;
		height: 2.075rem;
		background: #ffffff;
		box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		transition: all 0.3s;
		.top {
			box-sizing: border-box;
			height: 1.375rem;
			text-align: center;
			// line-height: 1.375rem;
			line-height: 1.5;
			border-bottom: 1px solid #f1f1f1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.bottom {
			display: flex;
			height: 0.6875rem;
			justify-content: space-around;
			align-items: center;
			& > div {
				cursor: pointer;
			}
			.line {
				width: 1px;
				height: 0.45rem;
				background: #f1f1f1;
			}
			.green {
				color: rgba(45, 174, 169, 1);
			}
		}
	}
	.Information-show {
		transform: translateX(-50%) translateY(-50%) scale(1);
	}
}
</style>
