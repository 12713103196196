<template>
  <div class="lxwmDrawerBox">
    <el-drawer
      custom-class="lxwmDrawer"
      :visible.sync="show"
      :with-header="false"
      size="24%"
      @close="drawerClose"
    >
      <div class="drawer-content">
        <h3>联系我们</h3>
        <h6>请留下您的个人信息，及联系方式</h6>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="name">
            <el-input
              type="text"
              v-model="ruleForm.name"
              autocomplete="off"
              placeholder="请输入您的姓名/公司名称"
              @focus="loginNameFous"
              @blur="loginNameBlur"
              :maxlength="50"
            >
              <i slot="prefix" class="slot-phone">
                <img
                  v-show="!inputFous.name"
                  src="../../../assets/image/home/XM.png"
                  alt=""
                />
                <img
                  v-show="inputFous.name"
                  src="../../../assets/image/home/XM2.png"
                  alt=""
                />
              </i>
              <i slot="suffix">*</i>
            </el-input>
          </el-form-item>
          <el-form-item prop="industry">
            <el-input
              type="text"
              v-model="ruleForm.industry"
              autocomplete="off"
              placeholder="请输入您的行业领域"
              @focus="fieldFous"
              @blur="fieldBlur"
              :maxlength="50"
            >
              <i slot="prefix" class="slot-phone">
                <img
                  v-show="!inputFous.industry"
                  src="../../../assets/image/home/HY.png"
                  alt=""
                />
                <img
                  v-show="inputFous.industry"
                  src="../../../assets/image/home/HY2.png"
                  alt=""
                />
              </i>
              <i slot="suffix">*</i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              type="text"
              v-model="ruleForm.address"
              autocomplete="off"
              placeholder="请输入您的所在地"
              @focus="addressFous"
              @blur="addressBlur"
              :maxlength="150"
            >
              <i slot="prefix" class="slot-phone">
                <img
                  v-show="!inputFous.address"
                  src="../../../assets/image/home/DD.png"
                  alt=""
                />
                <img
                  v-show="inputFous.address"
                  src="../../../assets/image/home/DD2.png"
                  alt=""
                />
              </i>
            </el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input
              type="number"
              v-model="ruleForm.phone"
              autocomplete="off"
              placeholder="请输入您联系电话"
              @focus="phoneFous"
              @blur="phoneBlur"
              :maxlength="11"
            >
              <i slot="prefix" class="slot-phone">
                <img
                  v-show="!inputFous.phone"
                  src="../../../assets/image/home/HY.png"
                  alt=""
                />
                <img
                  v-show="inputFous.phone"
                  src="../../../assets/image/home/HY2.png"
                  alt=""
                />
              </i>
              <i slot="suffix">*</i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请对您的意向进行一个简单的描述"
              v-model="ruleForm.description"
              :maxlength="300"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="show = false">取消</el-button>
            <el-button class="submit" @click="submitForm('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
        <Msg ref="tjmsg"></Msg>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Msg from "@/components/msg.vue"; //信息提示组件
export default {
  components: { Msg },
  data() {
    var checkPhone = (rule, value, callback) => {
      var reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确手机号"));
      } else {
        callback();
      }
    };
    return {
      show: false,
      //   表单数据
      ruleForm: {
        name: "",
        industry: "",
        address: "",
        phone: "",
        description: "",
      },
      isLoding: false,
      // 表单校验
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入您的姓名/公司名称",
          },
        ],
        industry: [
          {
            required: true,
            trigger: "blur",
            message: "请输入您的行业领域",
          },
        ],
        phone: [
          {
            required: true,
            trigger: "blur",
            message: "请输入您联系电话",
          },
          { trigger: "blur", validator: checkPhone },
        ],
      },
      // 输入框获取焦点
      inputFous: {
        name: false,
        industry: false,
        address: false,
        phone: false,
      },
    };
  },
  methods: {
    // 输入框焦点事件
    loginNameFous() {
      this.inputFous.name = true;
      this.$refs["ruleForm"].clearValidate("name"); //获取焦点时如果有效验结果清除结果
    },
    loginNameBlur() {
      this.inputFous.name = false;
    },
    fieldFous() {
      this.inputFous.industry = true;
      this.$refs["ruleForm"].clearValidate("industry"); //获取焦点时如果有效验结果清除结果
    },
    fieldBlur() {
      this.inputFous.industry = false;
    },
    addressFous() {
      this.inputFous.address = true;
      this.$refs["ruleForm"].clearValidate("address"); //获取焦点时如果有效验结果清除结果
    },
    addressBlur() {
      this.inputFous.address = false;
    },
    phoneFous() {
      this.inputFous.phone = true;
      this.$refs["ruleForm"].clearValidate("phone"); //获取焦点时如果有效验结果清除结果
    },
    phoneBlur() {
      this.inputFous.phone = false;
    },
    // 提交
    submitForm(formName) {
      if (!this.isLoding) {
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoding = false;
          // 接口请求
          let params = Object.assign({}, this.ruleForm);
          this.$http.postAction(this.$api.contactUs, params).then((res) => {
            if (res.code == 200) {
              this.$message(res.msg, "success");
              setTimeout(() => {
                this.ruleForm = this.$options.data().ruleForm;
                this.isLoding = true;
                this.show = false;
              }, 800);
            } else {
              this.isLoding = true;
              this.$message(res.msg, "error");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 抽屉关闭
    drawerClose() {
      this.$refs["ruleForm"].resetFields();
      this.ruleForm = {
        name: "",
        industry: "",
        address: "",
        phone: "",
        description: "",
      };
    },
  },
};
</script>

<style lang="scss">
.lxwmDrawerBox {
  .lxwmDrawer {
    border-radius: 15px 0px 0px 15px;
    .el-drawer__body {
      box-sizing: border-box;
      padding-top: 1.125rem;
      padding-left: 0.5rem;
      padding-right: 0.3375rem;
    }
    .drawer-content {
      width: 100%;
      height: 100%;
      h3 {
        font-size: 0.325rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      h6 {
        margin-top: 0.25rem;
        font-size: 0.175rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #999999;
      }
      .el-form {
        margin-top: 0.75rem;
        .el-input.is-active .el-input__inner,
        .el-input__inner:focus {
          border-color: #069f99;
        }
        .el-select .el-input__inner:focus,
        .el-select .el-input.is-focus .el-input__inner {
          border-color: #069f99;
        }
        .el-textarea__inner:focus {
          border-color: #069f99;
        }
        .el-input__prefix {
          i {
            line-height: 0.6875rem;
            margin-left: 0.125rem;
          }
        }
        .el-input__suffix {
          i {
            line-height: 0.6875rem;
            color: red;
            font-size: 0.25rem;
            padding-right: 0.25rem;
          }
        }
        .codeInput {
          width: 160px;
        }
        .el-select {
          width: 100%;
        }
        .el-select-dropdown__item.selected {
          color: #069f99 !important;
        }
        .el-input__inner {
          font-size: 0.25rem;
          height: 0.6875rem;
          border-width: 0.025rem;
          line-height: 0.6875rem;
          padding-left: 0.625rem;
        }
        .el-textarea__inner {
          font-size: 0.25rem;
          border-width: 0.025rem;
          line-height: 0.6875rem;
        }
        .el-button {
          width: 181px;
          height: 52px;
          background: #ecf1f4;
          border-radius: 10px;
          border-color: transparent;
          span {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
        .el-button:focus,
        .el-button:hover {
          border-color: transparent;
          color: #999999;
        }
        .submit {
          background: #2daea9;
          color: #ffffff;
          span {
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
