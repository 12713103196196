<template>
	<div class="product-left">
		<!-- 只有一个 -->
		<div class="product-div flex_ju_c one" v-if="data.length == 1">
			<div class="divs flex flex_a_c center">
				<div class="flex flex_a_c">
					<img class="product-icon" :src="makeIcons(data[0].itemCode)" alt="" />
					<div>
						<div class="product-word">{{ data[0]['itemName'] }}</div>
						<div class="product-word">{{ data[0]['itemValue'] }}{{ data[0]['itemUnitCode'] }}</div>
					</div>
				</div>
				<img class="product-line center-line" src="@/assets/image/lines/left_center.png" alt="" />
			</div>
		</div>
		<!-- 有两个 -->
		<div class="product-div flex_ju_sa two" v-else-if="data.length == 2">
			<div class="divs flex" v-for="(item, index) in data" :key="index">
				<div class="flex flex_a_c">
					<img class="product-icon" :src="makeIcons(item.itemCode)" alt="" />
					<div>
						<div class="product-word">{{ item.itemName }}</div>
						<div class="product-word">{{ item.itemValue }}{{ item.itemUnitCode }}</div>
					</div>
				</div>
				<img v-if="index == 0" class="product-line twotop-line" src="@/assets/image/lines/left_top.png" alt="" />
				<img v-else-if="index == 1" class="product-line twobot-line" src="@/assets/image/lines/left_bot.png" alt="" />
			</div>
		</div>
		<!-- 有三个 -->
		<div class="product-div flex_ju_sa three" v-else-if="data.length == 3">
			<div class="divs flex" v-for="(item, index) in data" :key="index">
				<div class="flex flex_a_c">
					<img class="product-icon" :src="makeIcons(item.itemCode)" alt="" />
					<div>
						<div class="product-word">{{ item.itemName }}</div>
						<div class="product-word">{{ item.itemValue }}{{ item.itemUnitCode }}</div>
					</div>
				</div>
				<img v-if="index == 0" class="product-line twotop-line" src="@/assets/image/lines/left_top.png" alt="" />
				<img
					v-else-if="index == 1"
					class="product-line center-line"
					src="@/assets/image/lines/left_center.png"
					alt=""
				/>
				<img v-else-if="index == 2" class="product-line twobot-line" src="@/assets/image/lines/left_bot.png" alt="" />
			</div>
		</div>
		<div class="product-div flex_ju_sa four" v-else-if="data.length == 4">
			<div class="flex divs" v-for="(item, index) in data" :key="index">
				<div class="flex flex_a_c">
					<img class="product-icon" :src="makeIcons(item.itemCode)" alt="" />
					<div>
						<div class="product-word">{{ item.itemName }}</div>
						<div class="product-word">{{ item.itemValue }}{{ item.itemUnitCode }}</div>
					</div>
				</div>
				<img v-if="index == 0" class="product-line top-line" src="@/assets/image/lines/left_topb.png" alt="" />
				<img
					v-else-if="index == 1"
					class="product-line center-line"
					src="@/assets/image/lines/left_center.png"
					alt=""
				/>
				<img v-else-if="index == 2" class="product-line bot-line" src="@/assets/image/lines/left_bot.png" alt="" />
				<img v-else-if="index == 3" class="product-line botb-line" src="@/assets/image/lines/left_botb.png" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
import { icons } from '@/assets/json/qxicon'
import { iconList } from '@/assets/json/img'
// 生产监测-环境监测左边数据
export default {
	props: {
		data: {
			type: Array,
			default: () => {
				return []
			},
		},
	},
	methods: {
		makeIcons(code) {
			// 先判断一个
			if (icons[code]) {
				return icons[code]
			} else {
				// 查询iconList里面是否有这个
				let iconIndex = iconList.findIndex((item) => {
					return item.key == code
				})
				// 有，取值
				if (iconIndex > -1) {
					return iconList[iconIndex]['path']
				} else {
					// 没有，查询是否包含
					let iconIndexTwo = iconList.findIndex((item) => {
						return code.indexOf(item.key) > -1 || item.key.indexOf(code) > -1
					})
					if (iconIndexTwo > -1) {
						return iconList[iconIndexTwo]['path']
					} else {
						return require('@/assets/image/qx/icon_yw.png')
					}
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.product-left {
	.product-div {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.product-icon {
		width: 0.5rem;
		height: 0.5rem;
		margin-right: 0.19rem;
	}
	.product-word {
		font-family: 'Source Han Sans CN';
		font-size: 0.2rem;
		color: #2daea9;
		padding: 0.05rem 0;
		&:nth-child(2) {
			text-align: left;
		}
	}
	.divs {
		position: relative;
	}
	.product-line {
		position: absolute;
		top: 0;
		left: 1.69rem;
		width: 2.26rem;
		height: auto;
	}
	.one {
		.center-line {
			top: 0.18rem;
		}
	}

	.two {
		.twotop-line {
			top: 0.25rem;
		}
		.twobot-line {
			top: -0.7rem;
		}
	}
	.three {
		.center-line {
			height: 0.13rem;
			top: 0.25rem;
		}
		.twotop-line {
			top: 0.25rem;
		}
		.twobot-line {
			width: 2.5rem;
			top: -0.73rem;
		}
	}
	.four {
		.top-line {
			top: 0.25rem;
		}
		.center-line {
			top: 0.25rem;
		}
		.bot-line {
			top: -0.38rem;
		}
		.botb-line {
			width: 2.63rem;
			top: -0.94rem;
		}
	}
}
</style>
