<template>
	<el-drawer custom-class="deviceDrawer kgSetUp" title="设置" :visible.sync="drawer" size="23%" @close="drawerClose">
		<!-- 顶部盒子 -->
		<div class="drawe-top">
			<div class="img-box">
				<img
					:style="{
						width: 1.5 + 'rem',
						height: 1.5 + 'rem',
					}"
					src="@/assets/image/product/diancifa.png"
					alt=""
				/>
			</div>
			<div class="txt-box">
				<div class="tb-li doble" :style="{ height: 32 + 'px' }">
					<div class="flex flex_a_c" :style="{ fontSize: 16 + 'px' }" v-show="!bjInputShow">
						{{ infos.subDeviceName }}
						<div class="bj-icon" @click="editorClick(infos.subDeviceName)"></div>
					</div>
					<div class="input-div" v-show="bjInputShow">
						<el-input placeholder="请输入内容" v-model="bjInputValue" maxlength="9">
							<i slot="suffix" class="suffix-content">
								<img src="../../../assets/image/product/queding.png" alt="" @click.stop="determineName" />
								<img src="../../../assets/image/product/quxiao.png" alt="" @click.stop="qxeditor" />
							</i>
						</el-input>
					</div>
				</div>
				<div class="tb-li">
					{{ infos.deviceName }}
					<div class="line"></div>
					<span class="green">在线</span>
				</div>
				<div class="tb-li">
					设备状态：<span class="blue" v-if="infos.dataItemValue == '1'">已打开</span>
					<span class="red" v-else>已关闭</span>
				</div>
			</div>
		</div>
		<!-- 开关盒子 -->
		<div class="switch-box">
			<div class="status-box">
				<div class="icon"></div>
				<span v-if="infos.dataItemValue == '1'">已打开</span>
				<span v-else>已关闭</span>
			</div>
			<img
				@click="onAndOff"
				v-if="infos.dataItemValue == '1'"
				class="cursor set_icon"
				src="@/assets/image/production/set_openh.png"
				alt=""
			/>
			<img @click="onAndOff" v-else class="cursor set_icon" src="@/assets/image/production/set_closeh.png" alt="" />
		</div>
		<!-- 菜单盒子 -->
		<div class="menu-box">
			<div class="b-line"></div>
			<div class="menu-li" @click="menuClick(0)">
				<div class="li-l"><img src="@/assets/image/product/shijijan.png" alt="" /> 时间日程</div>
			</div>
			<div class="menu-li" @click="menuClick(1)">
				<div class="li-l"><img src="@/assets/image/product/zidonghua.png" alt="" /> 设备自动化</div>
			</div>
			<div class="menu-li" @click="menuClick(2)">
				<div class="li-l"><img src="@/assets/image/product/zhixingjilu.png" alt="" /> 执行记录</div>
			</div>
		</div>
		<!-- 里面潜逃的抽屉 -->
		<!-- 时间日程 -->
		<el-drawer
			title="时间日程"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.schedule"
			custom-class="insideDrawe flcu-box"
		>
			<!-- 添加日程盒子 -->
			<div class="addrc-box" @click="openBjschedule(false)">
				<img src="@/assets/image/product/tianjia.png" alt="" />
				<div>点击添加时间日程</div>
			</div>
			<div class="line"></div>
			<!-- 列表 -->
			<div class="schedule-list" v-if="scheduleList.length">
				<div class="item" v-for="(item, index) in scheduleList" :key="index">
					<div class="item-header">
						<div class="header-left">
							<img v-if="item.executionCycle == '1'" src="@/assets/image/product/shizhong.png" alt="" />
							<img v-if="item.executionCycle == '2'" src="@/assets/image/product/meizhou.png" alt="" />
							<img v-if="item.executionCycle == '4'" src="@/assets/image/product/rili.png" alt="" />
							<span v-if="item.executionCycle == '1'">
								<span v-if="item.exerciseDate == '1'">每天</span>
								<span v-else
									>每天 <span class="gray">(每隔{{ item.exerciseDate - 1 }}天)</span></span
								>
							</span>
							<span v-if="item.executionCycle == '2'">
								<span>{{ exerciseDate(item.exerciseDate) }}</span>
							</span>
							<span v-if="item.executionCycle == '4'">
								<span>{{ item.exerciseDate }}</span>
							</span>
						</div>
						<div class="header-right">
							<img
								v-if="item.effectiveState == '1'"
								src="@/assets/image/product/qiyong.png"
								alt=""
								@click="statusClick(item)"
							/>
							<img v-else src="@/assets/image/product/jinyong.png" alt="" @click="statusClick(item)" />
						</div>
					</div>
					<div class="item-top">
						<!-- <div
              class="r-jiao"
              :class="{ 'r-jiao-no': item.effectiveState == '0' }"
            >
              <span v-if="item.effectiveState == '1'">已生效</span
              ><span v-else>未生效</span>
            </div> -->
						<div class="lie-1">
							<div>
								时间：<span>{{ item.conditionTime }}</span>
							</div>
							<div>
								动作：
								<span>{{ item.actionItemName }}</span>
								<!-- <span v-if="item.action">打开</span
								><span v-else>关闭</span> -->
							</div>
						</div>
						<div class="lie-1">
							<!-- <div>
                日期：<span>{{ exerciseDate(item.exerciseDate) }}</span>
              </div> -->
							<div>是否生效：<span v-if="item.effectiveState == '1'">已生效</span><span v-else>未生效</span></div>
						</div>
					</div>
					<div class="item-bottom">
						<!-- <div
              class="cz-div blue"
              v-if="item.effectiveState == '0'"
              @click="handleEnable(item)"
            >
              <img src="@/assets/image/product/xiafa.png" alt="" />
              下发
            </div> -->
						<!-- <div class="cz-line" v-if="item.effectiveState == '0'"></div> -->
						<div class="cz-div green" @click="openBjschedule(true, item)">
							<img src="@/assets/image/product/icon-bj.png" alt="" />
							编辑
						</div>
						<div class="cz-line"></div>
						<div class="cz-div red" @click="timeDayDel(item)">
							<img src="@/assets/image/product/icon-sc.png" alt="" />
							删除
						</div>
					</div>
				</div>
			</div>
			<div class="schedule-list-no" v-else>
				<img src="@/assets/image/home/ZWT.png" alt="" />
			</div>
			<!-- 编辑时间日程 -->
			<el-drawer
				title="时间日程"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.bjschedule"
				custom-class="insideDrawe flcu-box"
				@close="bjscheduleClose"
			>
				<div class="prompts-title">时间选择</div>
				<div class="flex flex_a_c" :style="{ marginTop: 21 + 'px' }">
					时间选择：
					<div class="time-box">
						<el-time-picker
							v-model="timercFrom.conditionTime"
							placeholder="任意时间点"
							format="HH:mm"
							value-format="HH:mm"
							:clearable="false"
						>
							<!-- <i slot="prefix" class="el-input__icon el-icon-date"></i> -->
						</el-time-picker>
					</div>
				</div>
				<div class="time-type-box">
					<el-radio-group v-model="timercFrom.executionCycle">
						<div class="time-type-li" @click="timercFrom.executionCycle = '1'">
							<div class="li-head">每天<el-radio label="1"></el-radio></div>
							<div
								class="li-bottom"
								:class="{
									'li-bottom-show': timercFrom.executionCycle == '1',
								}"
							>
								<el-radio-group v-model="timercFrom.timeNumberType">
									<el-radio label="0">每天</el-radio>
									<el-radio label="1"
										>每
										<div class="input-box">
											<el-input v-model="timercFrom.timeNumber" type="number"></el-input>
										</div>
										天</el-radio
									>
								</el-radio-group>
							</div>
						</div>
						<div class="time-type-li" @click="timercFrom.executionCycle = '2'">
							<div class="li-head">每周执行<el-radio label="2"></el-radio></div>
							<div
								class="li-bottom"
								:class="{
									'li-bottom-show': timercFrom.executionCycle == '2',
								}"
							>
								<el-checkbox-group v-model="timercFrom.checkList">
									<el-checkbox label="1">周一</el-checkbox>
									<el-checkbox label="2">周二</el-checkbox>
									<el-checkbox label="3">周三</el-checkbox>
									<el-checkbox label="4">周四</el-checkbox>
									<el-checkbox label="5">周五</el-checkbox>
									<el-checkbox label="6">周六</el-checkbox>
									<el-checkbox label="7">周日</el-checkbox>
								</el-checkbox-group>
							</div>
						</div>
						<div class="time-type-li" @click="timercFrom.executionCycle = '4'">
							<div class="li-head">指定日期<el-radio label="4"></el-radio></div>
							<div
								class="li-bottom border-b"
								:class="{
									'li-bottom-show': timercFrom.executionCycle == '4',
								}"
							>
								<div class="picktime-box">
									<el-date-picker
										v-model="timercFrom.pickTime"
										size="small"
										type="date"
										placeholder="请选择日期"
										popper-class="pick-poper"
										class="picker-div"
										format="yyyy-MM-dd"
										value-format="yyyy-MM-dd"
										prefix-icon=" "
										clear-icon="el-icon-date"
										:clearable="false"
										:editable="false"
										:picker-options="pickerOptions0"
									>
									</el-date-picker>
									<i class="pick-icon el-icon-date"></i>
								</div>
							</div>
						</div>
					</el-radio-group>
				</div>
				<div class="line"></div>
				<div class="prompts-title">执行动作</div>
				<div class="zxaction-box">
					<el-radio-group v-model="timercFrom.actionItemCode">
						<el-radio v-for="(item, index) in infos.commandsItemList" :key="index" :label="item.commandsItemCode">{{
							item.commandsItemName
						}}</el-radio>
						<!-- <el-radio label="0">打开</el-radio>
						<el-radio label="1">关闭</el-radio> -->
					</el-radio-group>
				</div>
				<!-- 确定按钮 -->
				<div class="btn-box">
					<div class="btn-item" @click="scheduleupto">保存并下发</div>
				</div>
				<Msg ref="tsmsg2"></Msg>
			</el-drawer>
			<!-- 确定删除信息警告 -->
			<div class="Information" :class="{ 'Information-show': Information }">
				<div class="top">确定{{ Informationtitle }}吗？</div>
				<div class="bottom">
					<div @click="scheduleCancle">取消</div>
					<div class="line"></div>
					<div class="green" @click="scheduleSubmit">确定</div>
				</div>
			</div>
			<Msg ref="tsmsg2"></Msg>
		</el-drawer>
		<!-- 设备自动化展示页 -->
		<el-drawer
			title="设备自动化"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.automation"
			custom-class="insideDrawe flcu-box"
		>
			<div class="prompts-title">条件选择</div>
			<div class="table-box">
				<div class="table-li">
					<div class="li-left border-bottom border-right">传感设备</div>
					<div class="li-right border-bottom">
						{{ automationInfos.subDeviceName }}
					</div>
				</div>
				<div class="table-li">
					<div class="li-left border-right">环境参数</div>
					<div class="li-right">
						{{ automationInfos.dataItemName }}
					</div>
				</div>
			</div>
			<div class="line"></div>
			<div class="prompts-title">执行动作</div>
			<div class="table-box">
				<div class="table-li">
					<div class="li-left border-bottom border-right">高于上限</div>
					<div class="li-right border-bottom">
						{{ automationInfos.rightVal || '--' }}
					</div>
				</div>
				<div class="table-li">
					<div class="li-left border-right">执行动作</div>
					<div class="li-right green">
						{{ automationInfos.actionItemNameLeft }}
					</div>
				</div>
			</div>
			<div class="table-box">
				<div class="table-li">
					<div class="li-left border-bottom border-right">低于下限</div>
					<div class="li-right border-bottom">
						{{ automationInfos.leftVal || '--' }}
					</div>
				</div>
				<div class="table-li">
					<div class="li-left border-right">执行动作</div>
					<div class="li-right red">
						{{ automationInfos.actionItemNameRight }}
					</div>
				</div>
			</div>
			<div class="line"></div>
			<div class="prompts-title">下发结果</div>
			<div class="table-box">
				<div class="table-li">
					<div class="li-left border-right">是否生效</div>
					<div class="li-right green" v-if="automationInfos.effectiveState == '1'">已生效</div>
					<div class="li-right red" v-if="automationInfos.effectiveState == '0'">未生效</div>
					<div class="li-right" v-if="automationInfos.effectiveState == '--'">
						{{ automationInfos.effectiveState }}
					</div>
				</div>
			</div>
			<div class="btn-box">
				<div class="btn-item cancel" @click="Information = true">删除配置</div>
				<div class="btn-item" @click="xiugaiautomation">修改配置</div>
			</div>
			<!-- 确定删除信息警告 -->
			<div class="Information" :class="{ 'Information-show': Information }">
				<div class="top">确定删除吗？</div>
				<div class="bottom">
					<div @click="Information = false">取消</div>
					<div class="line"></div>
					<div class="green" @click="delautomation">确定</div>
				</div>
			</div>
		</el-drawer>
		<!-- 设备自动化编辑页 -->
		<el-drawer
			title="设备自动化"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.automationbj"
			custom-class="insideDrawe flcu-box"
			@close="automationbjClose"
		>
			<div class="prompts-title">条件选择</div>
			<div class="flex flex_a_c" :style="{ marginTop: 21 + 'px' }">
				传感设备：
				<div class="time-box">
					<el-select v-model="automationbjFrom.sensorNum" placeholder="请选择" @change="equipSelect">
						<el-option
							v-for="(item, index) in equipmentList"
							:key="index"
							:label="item.subDeviceName"
							:value="item.subDeviceNum"
						>
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="parameter-box">
				<div class="box-header">环境参数</div>
				<div class="box-content" v-if="equipmentItems.length">
					<el-radio-group v-model="automationbjFrom.dataItemCode">
						<el-radio v-for="(item, index) in equipmentItems" :key="index" :label="item.dataItemCode">{{
							item.dataItemName
						}}</el-radio>
					</el-radio-group>
				</div>
				<div class="box-content t_c font_14 c_45" v-else>请先选择传感设备</div>
			</div>
			<div class="line"></div>
			<div class="prompts-title">执行动作</div>
			<div class="zxaction-box2">
				<div class="item-li">
					上限值：
					<div class="input-box">
						<el-input
							v-model="automationbjFrom.rightVal"
							placeholder="设置上限值"
							@input="onlyNumber($event, 'rightVal')"
							type="number"
						></el-input>
					</div>
				</div>
				<el-radio-group v-model="automationbjFrom.actionItemCodeRight">
					<el-radio v-for="(item, index) in infos.commandsItemList" :key="index" :label="item.commandsItemCode">{{
						item.commandsItemName
					}}</el-radio>
				</el-radio-group>
				<div class="item-li">
					下限值：
					<div class="input-box">
						<el-input
							v-model="automationbjFrom.leftVal"
							placeholder="设置下限值"
							@input="onlyNumber($event, 'leftVal')"
							type="number"
						></el-input>
					</div>
				</div>
				<el-radio-group v-model="automationbjFrom.actionItemCodeLeft">
					<el-radio v-for="(item, index) in infos.commandsItemList" :key="index" :label="item.commandsItemCode">{{
						item.commandsItemName
					}}</el-radio>
				</el-radio-group>
			</div>
			<div class="btn-box">
				<div class="btn-item" @click="sbzdhIssued">保存并下发</div>
			</div>
			<Msg ref="tsmsg4"></Msg>
		</el-drawer>
		<!-- 执行记录 -->
		<el-drawer
			title="执行记录"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.perform"
			custom-class="insideDrawe flcu-box"
			@close="performClose"
		>
			<!-- 查询条件盒子 -->
			<div class="search-box-kgsb">
				<span>查询条件</span>
				<div class="saach-item">
					<el-date-picker
						v-model="performFrom.time"
						type="daterange"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd"
						@change="getRecordList"
						:picker-options="pickerOptions1"
					>
					</el-date-picker>
				</div>
				<div class="saach-item flex flex_ju_sb">
					<div class="flex-sel">
						<el-select v-model="performFrom.operation" placeholder="请选择操作源" @change="getRecordList" clearable>
							<el-option :label="item.dictLabel" :value="item.dictValue" v-for="(item, index) in osList" :key="index">
							</el-option>
						</el-select>
					</div>
					<div class="flex-sel">
						<el-select v-model="performFrom.status" placeholder="请选择执行状态" @change="getRecordList" clearable>
							<el-option :label="item.dictLabel" :value="item.dictValue" v-for="(item, index) in orList" :key="index">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			<!-- 查询列表盒子 -->
			<div class="seach-list" v-if="performList.length" ref="seachList">
				<div class="list-item" v-for="(item, index) in performList" :key="index" @click="performItemClick(item)">
					<div class="list-item-top">
						<div>{{ item.deviceName }}</div>
						<div class="r-div" v-if="item.operationResult == '1'">
							<img src="../../../assets/image/equipment/icon-chengg.png" alt="" />
							执行中
						</div>
						<div class="r-div r-div-green" v-if="item.operationResult == '2'">
							<img src="../../../assets/image/equipment/icon-shibia.png" alt="" />
							执行失败
						</div>
						<div class="r-div" v-if="item.operationResult == '3'">
							<img src="../../../assets/image/equipment/icon-chengg.png" alt="" />
							执行成功
						</div>
					</div>
					<div class="list-item-bot">
						<div class="lib-div ellipsis">
							<i>所属区域:</i>
							<span>{{ item.areaName || '--' }}</span>
						</div>
						<div class="lib-div ellipsis">
							<i>执行时间:</i><span>{{ item.operateTime || '--' }}</span>
						</div>
						<div class="lib-div ellipsis">
							<i>操作源:</i><span>{{ item.operationSourceName || '--' }}</span>
						</div>
						<div class="lib-div ellipsis">
							<i>执行内容:</i><span>{{ item.recordContents }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="seach-list-no" v-else>
				<img src="@/assets/image/home/ZWT.png" alt="" />
			</div>
			<!-- 执行记录详情 -->
			<el-drawer
				:title="`详情（${performInfoObj.deviceName}-${performInfoObj.subDeviceName}）`"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.executionInfo"
				custom-class="insideDrawe flcu-box"
			>
				<div class="performInfo">
					<div class="info-item">
						<div class="title">所属区域:</div>
						<div class="cont">{{ performInfoObj.areaName || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">执行时间:</div>
						<div class="cont">{{ performInfoObj.operateTime || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">操作源:</div>
						<div class="cont">
							{{ performInfoObj.operationSourceName || '--' }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">产品类型:</div>
						<div class="cont">{{ performInfoObj.productTypeName || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">产品名称:</div>
						<div class="cont">{{ performInfoObj.deviceName || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">设备编号:</div>
						<div class="cont">{{ performInfoObj.deviceNum || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">业务类型:</div>
						<div class="cont">
							{{ performInfoObj.businessTypeName || '--' }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">操作账号:</div>
						<div class="cont">
							{{ performInfoObj.operationAccount || '--' }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">位置信息:</div>
						<div class="cont">{{ performInfoObj.ipAddress || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">执行状态:</div>
						<div class="cont">
							<span v-if="performInfoObj.operationResult == '2'">执行失败</span>
							<span v-if="performInfoObj.operationResult == '3'">执行成功</span>
						</div>
					</div>
					<div class="info-item">
						<div class="title">执行条件:</div>
						<div class="cont">
							{{ performInfoObj.executionCondition || '--' }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">执行内容:</div>
						<div class="cont">{{ performInfoObj.recordContents || '--' }}</div>
					</div>
				</div>
			</el-drawer>
		</el-drawer>
		<Msg ref="tsmsg"></Msg>
	</el-drawer>
</template>

<script>
import Msg from '@/components/msg.vue' //信息提示组件
export default {
	components: { Msg },
	data() {
		return {
			// 控制抽屉的开关
			drawer: false,
			infosType: null, //0控制箱 1 智能终端
			infos: {}, // 上个页面传递过来的参数
			// 控制里面抽屉的开关
			insideSwitch: {
				schedule: false, //时间日程
				bjschedule: false, //编辑时间日程
				automation: false, //设备自动化展示页
				automationbj: false, //设备自动化编辑页
				perform: false, //执行记录
				executionInfo: false, //执行记录详情
			},
			isBjSchedule: false, // 时间日程是否是编辑操作，主要用过添加和编辑的区分
			//   控制编辑输入框的显示
			bjInputShow: false,
			//   编辑输入框内容
			bjInputValue: '',
			//   时间日程列表
			scheduleList: [],
			// 控制确定删除弹窗
			Information: false,
			InformationId: '', // 需要删除的id
			Informationtitle: '删除',
			//   时间日程编辑表单
			defaultTimerc: {
				conditionTime: '', // 执行时间
				executionCycle: '1', //1每天2每周3指定日期
				timeNumberType: '0', //按天来算的几天  每天的情况: 0控制箱  1智能终端
				timeNumber: '', // 按日来算的几天
				checkList: ['1'], // 按周的选中值
				pickTime: '', // 指定日期的时间
				actionItemCode: '0', // 执行动作值
			},
			timercFrom: {
				conditionTime: '', // 执行时间
				executionCycle: '1', //1每天2每周3指定日期
				timeNumberType: '0', //按天来算的几天  每天的情况: 0控制箱  1智能终端
				timeNumber: '', // 按日来算的几天
				checkList: ['1'], // 按周的选中值
				pickTime: '', // 指定日期的时间
				actionItemCode: '0', // 执行动作值
			},
			timercEditId: '', // 时间日程编辑状态下的id

			// 设备自动化展示数据
			automationInfos: {
				id: null,
				sensorNum: '--',
				subDeviceName: '--',
				dataItemCode: '--',
				dataItemName: '--',
				rightVal: '--', //上限
				actionItemCodeRight: '--',
				actionItemNameRight: '--',
				leftVal: '--', //下限
				actionItemCodeLeft: '--',
				actionItemNameLeft: '--',
				effectiveState: '--',
			},
			// 设备自动化编辑表单
			automationbjFrom: {
				id: null,
				sensorNum: '',
				dataItemCode: '',
				rightVal: '', //上限
				actionItemCodeRight: '',
				leftVal: '', //下限
				actionItemCodeLeft: '',
			},
			// 设备自动化设备列表
			equipmentList: [],
			// 设备自动化环境参数
			equipmentItems: [],
			// 执行记录时间查询
			performFrom: {
				time: '', //时间
				operation: '', //操作源
				status: '', //执行状态
			},
			// 执行记录操作源数组
			osList: [],
			// 执行记录操作结果数组
			orList: [],
			// 业务类型数组
			ywList: [],
			// 执行记录列表数组
			performList: [],
			// 执行记录详情对象
			performInfoObj: {
				// name: '控制箱一号-放风机01号',
				// area: '某某区域',
				// time: '2022-05-12 15:43:24',
				// source: 'App',
				// cpType: '控制箱设备',
				// cpName: '控制箱一号',
				// cpNum: '12313454645478748',
				// ywType: '灾害模式',
				// czNumber: '4564654645656',
				// address: '河南省焦作市山阳区',
				// performState: '成功',
				// performconditions:
				// 	'执行条件执行条件执行条件，执行条件执行条件执行条件执行条件执行条件，执行条件执行条件，执行条件执行条件执行条件执行条件执行条件，执行条。',
				// content:
				// 	'执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容',
			},
			pickerOptions0: {
				disabledDate: (time) => {
					let num = new Date().getTime()
					return time.getTime() < num - 86400000
				},
			},
			pickerOptions1: {
				disabledDate: (time) => {
					let num = new Date().getTime()
					return time.getTime() > num
				},
			},
		}
	},
	watch: {
		infos: {
			handler(newValue) {
				console.log(newValue)
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		// 抽屉关闭
		drawerClose() {
			this.bjInputShow = false
			this.drawer = false
		},
		// 关闭全部的抽屉，主要用于设备状态改变
		drawerCloseAll() {
			if (this.insideSwitch.bjschedule) {
				this.bjscheduleClose()
			}
			if (this.insideSwitch.schedule) {
				this.insideSwitch.schedule = false
			}

			if (this.insideSwitch.automation) {
				this.insideSwitch.automation = false
			}
			if (this.insideSwitch.automationbj) {
				this.automationbjClose()
			}
			if (this.insideSwitch.perform) {
				if (this.insideSwitch.executionInfo) {
					this.insideSwitch.executionInfo = false
				}
				this.performClose()
			}
			this.drawerClose()
		},
		// 点击编辑按钮
		editorClick(value) {
			this.bjInputValue = value
			this.bjInputShow = true
		},
		// 取消编辑
		qxeditor() {
			this.bjInputShow = false
		},
		// 确认编辑
		determineName() {
			let deviceNum = this.infos.deviceNum
			let serviceId = this.infos.subDeviceNums[0]
			let serviceName = this.bjInputValue
			let objRegExp = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
			if (objRegExp.test(this.bjInputValue)) {
				if (this.infosType == '0') {
					this.updateControlName2(serviceId, deviceNum, serviceName)
				}
				if (this.infosType == '1') {
					this.updateControlName(deviceNum, serviceId, serviceName)
				}
			} else {
				this.$refs.tsmsg.msgShow('名称只能是中文，英文，数字，下划线', 1.5)
			}
		},
		// 修改设备名称
		updateControlName(deviceNum, serviceId, serviceName) {
			this.$http
				.getAction(
					this.$api.updateMetadataServiceName +
						'?deviceNum=' +
						deviceNum +
						'&serviceId=' +
						serviceId +
						'&serviceName=' +
						serviceName
				)
				.then((res) => {
					if (res.code == 200) {
						this.bjInputShow = false
						this.$refs.tsmsg.msgShow('修改成功', 1.5)
						this.$bus.$emit('xiugaiNameHuiD')
					} else {
						if (res.msg == '修改失败，服务id不存在，或者名称与原名称重复！') {
							this.bjInputShow = false
							this.$refs.tsmsg.msgShow('修改失败，不能和原名称一样', 1.5)
							return
						}
						this.bjInputShow = false
						this.$refs.tsmsg.msgShow(res.msg, 1.5)
					}
				})
		},
		// 修改设备名称2
		updateControlName2(controlCompNum, deviceId, dccName) {
			let params = {
				deviceId: deviceId,
				controlCompNum: controlCompNum,
				dccName: dccName,
			}
			this.$http.postAction(this.$api.updateControlName, params).then((res) => {
				if (res.code == 200) {
					this.bjInputShow = false
					this.$refs.tsmsg.msgShow('修改成功', 1.5)
					this.$bus.$emit('xiugaiNameHuiD')
				} else {
					this.bjInputShow = false
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
				}
			})
		},
		// 菜单点击
		menuClick(index) {
			// 时间日程
			if (index == 0) {
				this.insideSwitch.schedule = true
				// 获取时间日程列表数据
				this.getTimeList()
			}
			//   设备自动化
			if (index == 1) {
				// this.insideSwitch.automation = true
				// 获取设备自动化列表数据
				this.getEquipList()
			}
			//   执行记录
			if (index == 2) {
				this.queryRecord()
				// 获取执行记录数据
				this.getRecordList()
				this.insideSwitch.perform = true
			}
		},
		// 打开编辑时间日程
		openBjschedule(bool, item) {
			console.log(item)

			// 是编辑状态
			if (bool) {
				if (item.effectiveState == '1') {
					this.$refs.tsmsg2.msgShow('当前已启用无法修改', 1.5)
					return
				}
				// 拿到数据并进行赋值
				let datas = { ...item }
				this.timercEditId = datas.id
				// 赋值
				for (const key in this.timercFrom) {
					if (datas[key]) {
						this.timercFrom[key] = datas[key]
					}
				}
				// 判断并赋值时间选择数据
				// 按日
				if (datas.executionCycle == 1) {
					if (datas.exerciseDate == '1') {
						this.timercFrom.timeNumberType = '0'
					} else {
						this.timercFrom.timeNumberType = '1'
						this.timercFrom.timeNumber = datas.exerciseDate
					}
				} else if (datas.executionCycle == 2) {
					// 按周
					this.timercFrom.checkList = datas.exerciseDate.split(',')
				} else if (datas.executionCycle == 4) {
					// 指定日期
					this.timercFrom.pickTime = datas.exerciseDate
				}
			} else {
				// 表单数据置空
				this.timercFrom = { ...this.defaultTimerc }
			}
			this.isBjSchedule = bool
			this.insideSwitch.bjschedule = true
		},
		// 时间日程编辑页面点击下发
		scheduleupto() {
			let params = {}
			let path = ''
			// 判断exerciseDate的传值
			let exerciseDate = ''
			if (this.timercFrom.conditionTime == '') {
				this.$refs.tsmsg2.msgShow('请选择时间点', 1.5)
				return
			}
			// 按日
			if (this.timercFrom.executionCycle == 1) {
				if (this.timercFrom.timeNumberType == 0) {
					exerciseDate = '1'
				} else {
					console.log(this.timercFrom.timeNumber, '78999')
					if (this.timercFrom.timeNumber == '') {
						this.$refs.tsmsg2.msgShow('执行时间不能为空', 1.5)
						return
					}
					if (!Number.isInteger(Number(this.timercFrom.timeNumber))) {
						this.$refs.tsmsg2.msgShow('执行时间必须为正整数', 1.5)
						return
					}
					exerciseDate = Number(this.timercFrom.timeNumber) + 1
				}
			} else if (this.timercFrom.executionCycle == 2) {
				// 按周
				// console.log('按周', this.timercFrom.checkList.join(',') == '')
				if (this.timercFrom.checkList.join(',') == '') {
					this.$refs.tsmsg2.msgShow('执行周期不能为空', 1.5)
					return
				}
				exerciseDate = this.timercFrom.checkList.join(',')
			} else if (this.timercFrom.executionCycle == 4) {
				// 指定日期
				// console.log(this.timercFrom.pickTime, 'this.timercFrom.pickTime')
				if (this.timercFrom.pickTime == '') {
					this.$refs.tsmsg2.msgShow('指定日期不能为空', 1.5)
					return
				}
				exerciseDate = this.timercFrom.pickTime
			}
			// 新增状态
			params = {
				executionCycle: this.timercFrom.executionCycle,
				exerciseDate: exerciseDate,
				conditionTime: this.timercFrom.conditionTime,
				deviceNum: this.infos.deviceNum,
				subDeviceNum: this.infos.subDeviceNums[0],
				actionItemCode: this.timercFrom.actionItemCode,
			}
			// 编辑状态
			if (this.isBjSchedule) {
				path = this.$api.timeConfigUpdate
				this.$set(params, 'id', this.timercEditId)
			} else {
				path = this.$api.timeConfigAdd
			}
			this.$http
				.postAction(path, params)
				.then((res) => {
					if (res.code == 200) {
						this.insideSwitch.bjschedule = false
						this.$refs.tsmsg2.msgShow('下发成功', 1.5)
					} else {
						// this.$refs.tsmsg2.msgShow(res.msg, 1.5)
						if (res.msg == '请求参数不正确：执行时间不能为空') {
							this.$refs.tsmsg2.msgShow('请选择执行时间', 1.5)
						} else {
							this.$refs.tsmsg2.msgShow(res.msg, 1.5)
						}
					}
					this.getTimeList()
				})
				.catch((err) => {
					if (err.msg == '请求参数不正确：执行时间不能为空') {
						this.$refs.tsmsg2.msgShow('请选择执行时间', 1.5)
					} else {
						this.$refs.tsmsg2.msgShow(err.msg, 1.5)
					}
				})
		},
		// 编辑时间日程关闭
		bjscheduleClose() {
			this.getTimeList()
		},
		/*
		 * @description: 设备自动化
		 */
		// 删除设备自动化记录
		delautomation() {
			// this.$refs.tsmsg.msgShow('删除成功', 1.5)
			this.$http
				.deleteAction(this.$api.thresholdDel + '?id=' + this.automationInfos.id)
				.then((res) => {
					if (res.code == 200) {
						this.$refs.tsmsg.msgShow('删除成功', 1.5)
					} else {
						this.$refs.tsmsg.msgShow(res.msg, 1.5)
					}
				})
				.finally(() => {
					this.Information = false
					this.insideSwitch.automation = false
				})
		},
		// 设备自动化下发
		sbzdhIssued() {
			if (this.automationbjFrom.dataItemCode == '') {
				this.$refs.tsmsg4.msgShow('请选择环境参数', 1.5)
				return
			}
			if (this.automationbjFrom.rightVal == '') {
				this.$refs.tsmsg4.msgShow('请设置上限值', 1.5)
				return
			}
			if (this.automationbjFrom.leftVal == '') {
				this.$refs.tsmsg4.msgShow('请设置下限值', 1.5)
				return
			}
			if (this.automationbjFrom.rightVal - 0 < this.automationbjFrom.leftVal - 0) {
				this.$refs.tsmsg4.msgShow('请设置正确的上下限', 1.5)
				return
			}
			if (this.automationbjFrom.actionItemCodeLeft == '') {
				this.$refs.tsmsg4.msgShow('请设置下限值执行动作', 1.5)
				return
			}
			if (this.automationbjFrom.actionItemCodeRight == '') {
				this.$refs.tsmsg4.msgShow('请设置上限值执行动作', 1.5)
				return
			}
			console.log(this.infos)
			let otherParams = {
				id: this.automationInfos.id,
				deviceId: this.automationInfos.deviceId,
				deviceNum: this.infos.deviceNum,
				subDeviceNum: this.infos.subDeviceNums[0],
			}
			console.log(this.infos)
			let params = Object.assign({}, this.automationbjFrom, otherParams)
			console.log(params)
			this.$http.postAction(this.$api.thresholdSet, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg4.msgShow('下发成功', 1.5)
					setTimeout(() => {
						this.getEquipList()
						this.insideSwitch.automation = true
						this.insideSwitch.automationbj = false
					}, 1500)
				} else {
					this.$refs.tsmsg4.msgShow(res.msg, 1.5)
					setTimeout(() => {
						// this.insideSwitch.automationbj = false;
					}, 1500)
				}
			})
			// .catch((error) => {
			//   this.$refs.tsmsg3.msgShow(err.msg, 1.5);
			// })
			// .finally(() => {
			//   // this.insideSwitch.automationbj = false;
			// });
		},
		// 设备自动化编辑页面关闭
		automationbjClose() {
			this.automationbjFrom = {
				sensorNum: '',
				dataItemCode: '',
				rightVal: '', //上限
				actionItemCodeRight: '',
				leftVal: '', //下限
				actionItemCodeLeft: '',
			}
		},
		// 设备选择
		equipSelect(val) {
			let selectIndex = this.equipmentList.findIndex((item) => {
				return item.subDeviceNum == val
			})
			if (selectIndex > -1) {
				this.equipmentItems = this.equipmentList[selectIndex]['itemList']
			} else {
				this.equipmentItems = []
			}
		},
		// 设备对比判断
		equipShow(val) {
			let equipmentList = this.equipmentList
			let dzIndex = equipmentList.findIndex((item) => {
				return item.subDeviceNum == val
			})
			let names = ''
			let itemsName = ''
			if (dzIndex > -1) {
				names = this.equipmentList[dzIndex]['subDeviceName']

				let equipmentItems = this.equipmentList[dzIndex]['itemList'] || []
				this.equipmentItems = equipmentItems
				let selectIndex = equipmentItems.findIndex((item) => {
					return item.dataItemCode == this.automationInfos.dataItemCode
				})
				if (selectIndex > -1) {
					itemsName = equipmentItems[selectIndex]['dataItemName']
				} else {
					itemsName = '--'
				}
			} else {
				names = '--'
				itemsName = '--'
				this.equipmentItems = []
			}

			let params = {
				names: names,
				itemName: itemsName,
			}
			return params
		},
		// 动作对比判断
		// equipDz(val) {
		//   let commandsItemList = this.infos.commandsItemList;
		//   let dzIndex = commandsItemList.findIndex((item) => {
		//     return item.commandsItemCode == val;
		//   });
		//   let names = "";
		//   if (dzIndex > -1) {
		//     names = this.infos.commandsItemList[dzIndex]["commandsItemName"];
		//   } else {
		//     names = "--";
		//   }
		//   return names;
		// },
		/*
		 * @description: 执行记录
		 */
		// 执行记录列表点击
		performItemClick(item) {
			this.insideSwitch.executionInfo = true
			// 赋值
			this.$nextTick(() => {
				this.performInfoObj = item
			})
		},
		// 执行记录时间选择
		timeChange() {
			// 重新获取数据
			this.getRecordList()
		},
		/*
		 * @description: 时间日程
		 */
		// 时间日程-日期显示处理
		exerciseDate(val) {
			let arr = val.split(',')
			let newArr = []
			arr.forEach((item) => {
				newArr.push(this.weekTran(item))
			})
			let result = `${newArr.join(',')}`
			return result
		},
		// 周几转化
		weekTran(num) {
			if (num == 1) {
				return '周一'
			} else if (num == 2) {
				return '周二'
			} else if (num == 3) {
				return '周三'
			} else if (num == 4) {
				return '周四'
			} else if (num == 5) {
				return '周五'
			} else if (num == 6) {
				return '周六'
			} else {
				return '周日'
			}
		},
		// 时间日程点击删除
		timeDayDel({ id }) {
			this.Informationtitle = '删除'
			this.Information = true
			this.InformationId = id
		},
		// 时间日程点击取消
		scheduleCancle() {
			this.Information = false
			this.InformationId = ''
			this.Informationtitle = ''
		},
		// 时间日程点击下发
		handleEnable({ id }) {
			this.Informationtitle = '下发'
			this.Information = true
			this.InformationId = id
		},

		/*
		 * @description: 数据请求
		 */
		// 获取时间日程列表数据
		getTimeList() {
			let params = {
				deviceNum: this.infos.deviceNum,
				subDeviceNum: this.infos.subDeviceNums[0],
			}
			this.$http.postAction(this.$api.autoTimeConfig, params).then((res) => {
				if (res.code == 200) {
					let datas = res.data || []
					this.scheduleList = datas || []
				} else {
					this.scheduleList = []
				}
			})
		},
		// 获取设备自动化列表数据
		getEquipList() {
			let params = {
				deviceNum: this.infos.deviceNum,
				subDeviceNum: this.infos.subDeviceNums[0],
				// deviceNum: "22120900060",
				// subDeviceNum: "c_1",
			}
			this.$http.postAction(this.$api.thresholdList, params).then((res) => {
				// res = {
				//   code: 200,
				//   msg: "ok",
				//   data: {
				//     id: "226",
				//     createId: "49",
				//     createName: "13383917367",
				//     modifyId: "49",
				//     modifyName: "13383917367",
				//     sensorNum: "1",
				//     dataItemCode: "KQSD",
				//     leftVal: "10",
				//     actionItemCodeLeft: "1",
				//     rightVal: "50",
				//     actionItemCodeRight: "0",
				//     deviceId: "1310",
				//     deviceNum: "22121400000",
				//     subDeviceNum: "1",
				//     effectiveState: "0",
				//     createTime: "2023-01-10T17:52:25",
				//     modifyTime: "2023-01-10T17:53:24",
				//   },
				// };
				if (res.code == 200 && res.data) {
					let datas = res.data || {}
					if (datas && JSON.stringify(datas) != '') {
						this.automationInfos = datas
						this.equipmentList.forEach((item) => {
							if (item.subDeviceNum == res.data.sensorNum) {
								this.automationInfos.subDeviceName = item.subDeviceName
								item.itemList.forEach((item2) => {
									if (item2.dataItemCode == res.data.dataItemCode) {
										this.automationInfos.dataItemName = item2.dataItemName
									}
								})
							}
						})
						this.infos.commandsItemList.forEach((item) => {
							if (item.commandsItemCode == res.data.actionItemCodeLeft) {
								this.automationInfos.actionItemNameLeft = item.commandsItemName
							}
							if (item.commandsItemCode == res.data.actionItemCodeRight) {
								this.automationInfos.actionItemNameRight = item.commandsItemName
							}
						})
					} else {
						this.automationInfos = {
							id: null,
							sensorNum: '--',
							subDeviceName: '--',
							dataItemCode: '--',
							dataItemName: '--',
							rightVal: '--', //上限
							actionItemCodeRight: '--',
							actionItemNameRight: '--',
							leftVal: '--', //下限
							actionItemCodeLeft: '--',
							actionItemNameLeft: '--',
							effectiveState: '--',
						}
					}
				} else {
					this.automationInfos = {
						id: null,
						sensorNum: '--',
						subDeviceName: '--',
						dataItemCode: '--',
						dataItemName: '--',
						rightVal: '--', //上限
						actionItemCodeRight: '--',
						actionItemNameRight: '--',
						leftVal: '--', //下限
						actionItemCodeLeft: '--',
						actionItemNameLeft: '--',
						effectiveState: '--',
					}
					this.insideSwitch.automation = false
					this.automationbjFrom.sensorNum = this.equipmentList[0].subDeviceNum
					this.equipmentItems = this.equipmentList[0].itemList
					this.insideSwitch.automationbj = true
				}
			})
		},
		// 修改配置
		xiugaiautomation() {
			this.equipmentList.forEach((item) => {
				if (item.subDeviceNum == this.automationInfos.sensorNum) {
					this.automationbjFrom.sensorNum = this.automationInfos.sensorNum
					this.equipmentItems = item.itemList
				}
			})
			this.automationbjFrom.dataItemCode = this.automationInfos.dataItemCode
			this.automationbjFrom.rightVal = this.automationInfos.rightVal
			this.automationbjFrom.actionItemCodeRight = this.automationInfos.actionItemCodeRight
			this.automationbjFrom.leftVal = this.automationInfos.leftVal
			this.automationbjFrom.actionItemCodeLeft = this.automationInfos.actionItemCodeLeft
			this.insideSwitch.automationbj = true
		},
		//    获取执行记录列表数据
		getRecordList() {
			let startTime = ''
			let endTime = ''
			if (this.performFrom.time && this.performFrom.time.length) {
				startTime = this.performFrom.time[0]
				endTime = this.performFrom.time[1]
			} else {
				startTime = ''
				endTime = ''
			}
			let params = {
				list: [
					{
						deviceNum: this.infos.deviceNum,
						subDeviceNums: [this.infos.subDeviceNums[0]],
					},
				],
				currentPage: 1,
				pageSize: 20,
			}
			if (startTime !== '') {
				params.startTime = startTime
			}
			if (endTime !== '') {
				params.endTime = endTime
			}
			if (this.performFrom.operation !== '') {
				params.operationSources = [this.performFrom.operation]
			} else {
				params.operationSources = []
			}
			if (this.performFrom.status !== '') {
				params.operationResult = this.performFrom.status
			} else {
				params.operationResult = ''
			}
			this.$http.postAction(this.$api.recordList, params).then((res) => {
				// res = {
				// 	code: 200,
				// 	msg: 'ok',
				// 	data: {
				// 		currentPage: 1,
				// 		totalCount: '1943',
				// 		pageSize: 3,
				// 		records: [
				// 			{
				// 				id: '639974d57f2c5e6b129e174e',
				// 				sendTime: '2022-12-14T15:01:41.961',
				// 				deviceNum: '22120900045',
				// 				deviceName: '智能卷被机45',
				// 				productType: '6',
				// 				productTypeName: '智能终端',
				// 				productId: '27',
				// 				productName: '智能终端',
				// 				subDeviceNum: 'c_1',
				// 				subDeviceName: '卷被机',
				// 				operation: '1',
				// 				operationName: '上升',
				// 				operateTime: '2022-12-14T15:01:42.019',
				// 				operationSource: '2',
				// 				operationSourceName: 'APP',
				// 				operationResult: '3',
				// 				operationResultName: '执行成功',
				// 				businessType: '1',
				// 				businessTypeName: '远程控制',
				// 				businessParam: ['6bfe67bac5924a08bd852de1b603e2e2'],
				// 				operationAccount: '15537230575',
				// 				ipAddress: '42.234.54.154',
				// 				executionCondition: '按钮触发',
				// 				recordContents: '卷被机执行了上升动作',
				// 			},
				// 			{
				// 				id: '639974c27f2c5e6b129e1748',
				// 				sendTime: '2022-12-14T15:01:22.881',
				// 				deviceNum: '22120900045',
				// 				deviceName: '智能卷被机45',
				// 				productType: '6',
				// 				productTypeName: '智能终端',
				// 				productId: '27',
				// 				productName: '智能终端',
				// 				subDeviceNum: 'c_1',
				// 				subDeviceName: '卷被机',
				// 				operation: '1',
				// 				operationName: '上升',
				// 				operateTime: '2022-12-14T15:01:35.019',
				// 				operationSource: '2',
				// 				operationSourceName: 'APP',
				// 				operationResult: '3',
				// 				operationResultName: '执行成功',
				// 				businessType: '1',
				// 				businessTypeName: '远程控制',
				// 				businessParam: ['cba7f3e0af464abdb192c5178e688527'],
				// 				operationAccount: '15537230575',
				// 				ipAddress: '42.234.54.154',
				// 				executionCondition: '按钮触发',
				// 				recordContents: '卷被机执行了上升动作',
				// 			},
				// 			{
				// 				id: '639973d77f2c5e6b129e1717',
				// 				sendTime: '2022-12-14T14:57:27.079',
				// 				deviceNum: '22120900064',
				// 				deviceName: '64',
				// 				productType: '6',
				// 				productTypeName: '智能终端',
				// 				productId: '27',
				// 				productName: '智能终端',
				// 				subDeviceNum: 'c_1',
				// 				subDeviceName: '卷被机',
				// 				operation: '3',
				// 				operationName: '急停',
				// 				operationSource: '2',
				// 				operationSourceName: 'APP',
				// 				operationResult: '1',
				// 				operationResultName: '执行中',
				// 				businessType: '3',
				// 				businessTypeName: '急停',
				// 				businessParam: ['24efbc52e4af4bde9666f5950ef1f317'],
				// 				operationAccount: '17516477369',
				// 				ipAddress: '125.46.163.122',
				// 				executionCondition: '按钮触发',
				// 				recordContents: '卷被机执行取消急停动作',
				// 			},
				// 		],
				// 	},
				// }
				if (res.code == 200) {
					let result = res.data || {}
					if (result.totalCount > 0) {
						let datas = result.records || []
						this.performList = datas || []
						let dom = this.$refs.seachList
						dom.scrollTop = 0
					} else {
						this.performList = []
					}
					console.log(this.performList)
				} else {
					this.performList = []
				}
			})
		},
		// 执行记录关闭
		performClose() {
			this.performFrom = {
				time: '', //时间
				operation: '', //操作源
				status: '', //执行状态
			}
			this.insideSwitch.perform = false
		},
		// 时间日程确定删除/下发
		scheduleSubmit() {
			if (this.Informationtitle == '删除') {
				this.$http.deleteAction(this.$api.timeConfigDel + '/' + this.InformationId).then((res) => {
					if (res.code == 200) {
						this.Information = false
						this.$refs.tsmsg2.msgShow(res.msg, 1.5)
						// 重新请求数据
						this.$nextTick(() => {
							this.getTimeList()
						})
						this.Informationtitle = ''
					} else {
						this.Information = false
						this.InformationId = ''
						this.Informationtitle = ''
						this.$message(res.msg, 'error')
					}
				})
			} else if (this.Informationtitle == '下发') {
				this.$http.postAction(this.$api.timeConfigEnable + '?id=' + this.InformationId + '&enable=1').then((res) => {
					if (res.code == 200) {
						this.Information = false
						this.$refs.tsmsg2.msgShow(res.msg, 1.5)
						// 重新请求数据
						this.$nextTick(() => {
							this.getTimeList()
						})
						this.Informationtitle = ''
					} else {
						this.Information = false
						this.InformationId = ''
						this.Informationtitle = ''
						this.$refs.tsmsg2.msgShow(res.msg, 1.5)
					}
				})
			}
		},
		// 时间日程状态点击
		statusClick(item) {
			console.log(item)
			let enable = ''
			if (item.effectiveState == '0') {
				enable = '1'
			} else {
				enable = '0'
			}
			this.$http.postAction(this.$api.timeConfigEnable + '?id=' + item.id + '&enable=' + enable).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
					// 重新请求数据
					this.$nextTick(() => {
						this.getTimeList()
					})
					this.Informationtitle = ''
				} else {
					this.Information = false
					this.InformationId = ''
					this.Informationtitle = ''
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
				}
			})
		},

		// 设备状态切换  打开  关闭
		onAndOff() {
			let params = {
				deviceNum: this.infos.deviceNum,
				subDeviceNum: this.infos.subDeviceNums[0],
				duration: '',
				khd: '',
			}
			let caozuoArr = this.infos.commandsItemList.filter((item) => {
				if (item.commandsItemCode !== this.infos.dataItemValue) {
					return item
				}
			})
			params.operate = caozuoArr[0].commandsItemCode
			this.$http.postAction(this.$api.operateDevice, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg.msgShow('操作成功', 1.5)
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
				}
			})
		},
		// 筛选执行条件
		queryRecord() {
			this.$http.postAction(this.$api.queryRecord, {}).then((res) => {
				if (res.data) {
					this.osList = res.data.osList
					this.orList = res.data.orList
					this.ywList = res.data.ywList
				}
			})
		},
		// input 只允许输入整数
		onlyNumber(num, type) {
			let n = String(num)
			const t = n.charAt(0)
			// 先把非数字的都替换掉，除了数字和.
			// n = n.replace(/[^\d\\.]/g, '')
			// 必须保证第一个为数字而不是.
			n = n.replace(/\./g, '').replace(/\-/g, '')
			// // 必须保证第一个数字非0
			n = n.replace(/^0(\d)/g, ($0, $1) => $1)
			// // 保证只有出现一个.而没有多个.
			// n = n.replace(/\.{2,}/g, '.')
			// // 保证.只出现一次，而不能出现两次以上
			// n = n.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
			n = n.substr(0, 4)
			// 如果第一位是负号，则允许添加
			if (t == '-') {
				n = '-' + n
			}
			this.automationbjFrom[type] = n
			return n
		},
	},
}
</script>

<style lang="scss" scoped>
/deep/ .kgSetUp {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		span {
			font-size: 0.225rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		display: flex;
		flex-direction: column;
		.drawe-top {
			display: flex;
			padding-bottom: 0.275rem;
			border-bottom: 1px solid rgba(241, 241, 241, 1);
			.img-box {
				width: 1.5rem;
				height: 1.5rem;
				background-color: rgba(45, 174, 169, 0.18);
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				// img {
				//   width: 1.5rem;
				//   height: 1.5rem;
				// }
			}
			.txt-box {
				margin-left: 0.225rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				box-sizing: border-box;
				padding: 0.15rem 0;
				.tb-li {
					display: flex;
					font-size: 0.2rem;
					.line {
						width: 0.0125rem;
						height: 0.2rem;
						background: #bfbfbf;
						margin: 0 0.2rem;
					}
					.bj-icon {
						cursor: pointer;
						margin-left: 0.1875rem;
						width: 0.2rem;
						height: 0.2rem;
						background: url('../../../assets/image/product/icon-bj2.png') no-repeat;
					}
					.input-div {
						width: 2.875rem;
						height: 0.4rem;
						.el-input {
							height: 100%;
						}
						.el-input__inner {
							border-radius: 0.1rem;
							height: 100%;
							font-weight: normal;
						}
						.el-input.is-active .el-input__inner,
						.el-input__inner:focus {
							border-color: rgba(45, 174, 169, 1);
						}
						.el-input__suffix {
							width: 4vw;
							height: 100%;
							.el-input__suffix-inner {
								display: block;
								width: 100%;
								height: 100%;
								display: flex;
								align-items: center;
								.suffix-content {
									display: flex;
									align-items: center;
									width: 100%;
									height: 1.099vw;
									box-sizing: border-box;
									border-left: 1px solid #f1f1f1;
									justify-content: space-around;
									padding: 0 0.1875rem;
									img {
										cursor: pointer;
										width: 0.175rem;
										height: 0.175rem;
									}
								}
							}
						}
					}
				}
				.doble {
					font-weight: bold;
				}
				.green {
					color: rgba(73, 201, 136, 1);
				}
				.blue {
					color: rgba(45, 174, 169, 1);
				}
			}
		}
		.switch-box {
			margin: 0.25rem 0;
			height: 0.9rem;
			background: rgba(231, 241, 243, 0.6);
			border-radius: 0.1rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 0 0.3rem;
			.status-box {
				display: flex;
				align-items: center;
				.icon {
					width: 0.45rem;
					height: 0.45rem;
					background: url('../../../assets/image/product/kaiguan.png') no-repeat;
					margin-right: 0.2625rem;
				}
				span {
					font-size: 0.2rem;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #454545;
				}
			}
			.set_icon {
				width: 0.8625rem;
				height: 0.35rem;
			}
		}
		.menu-box {
			margin-top: 0.3rem;
			.menu-li {
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 0.2rem;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #454545;
				line-height: 0.2625rem;
				margin-bottom: 0.3rem;
				.blue {
					font-size: 0.175rem;
					color: rgba(45, 174, 169, 1);
					margin: 0 0.175rem;
				}
				&::after {
					content: '';
					width: 0.1125rem;
					height: 0.2rem;
					background: url('../../../assets/image/equipment/icon-open.png') no-repeat;
					background-size: 100% 100%;
				}
				.li-l {
					font-size: 0.2rem;
					img {
						margin-right: 0.25rem;
					}
				}
			}
			.b-line {
				height: 1px;
				background: #f1f1f1;
				margin-bottom: 0.3125rem;
			}
		}
	}
}
/deep/ .insideDrawe {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		margin-bottom: 0.25rem;
		span {
			font-size: 0.225rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.flcu-box {
		display: flex;
		flex-direction: column;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		display: flex;
		flex-direction: column;
		position: relative;
		.drawe-top {
			display: flex;
			padding-bottom: 0.275rem;
			border-bottom: 1px solid rgba(241, 241, 241, 1);
			.img-box {
				width: 1.5rem;
				height: 1.5rem;
				background-color: rgba(45, 174, 169, 0.18);
				border-radius: 50%;
			}
			.txt-box {
				margin-left: 0.225rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.tb-li {
					display: flex;
					font-size: 0.2rem;
					.line {
						width: 0.0125rem;
						height: 0.2rem;
						background: #bfbfbf;
						margin: 0 0.2rem;
					}
				}

				.doble {
					font-weight: bold;
				}
				.green {
					color: rgba(73, 201, 136, 1);
				}
				.blue {
					color: rgba(45, 174, 169, 1);
				}
			}
		}
		.addrc-box {
			height: 1.1375rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			cursor: pointer;
			box-sizing: border-box;
			padding-top: 0.2rem;
			text-align: center;
			font-size: 0.2rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #2daea9;
			img {
				margin-bottom: 0.175rem;
			}
		}
		.line {
			height: 1px;
			background: #f1f1f1;
			margin: 0.3rem 0;
		}
		.schedule-list {
			height: 80%;
			overflow: auto;
			.item {
				background: #e7f1f3;
				border-radius: 0.125rem;
				overflow: hidden;
				margin-bottom: 0.3rem;
				font-size: 0.2rem;
				.item-header {
					display: flex;
					justify-content: space-between;
					box-sizing: border-box;
					padding: 0.225rem 0.3rem;
					border-bottom: 1px solid rgba(255, 255, 255, 0.6);
					.header-left {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 28px;
						display: flex;
						// span {
						//    line-height: 28px;
						// }
						img {
							width: 0.225rem;
							height: 0.225rem;
							margin-right: 0.175rem;
						}
						.gray {
							// color: rgba(156, 156, 156, 1);
							color: #5e5353;
						}
					}
					.header-right {
						margin-left: 0.45rem;
						img {
							cursor: pointer;
						}
					}
				}
				.item-top {
					height: 1.1375rem;
					box-sizing: border-box;
					padding: 0.25rem 0.3rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					position: relative;
					.r-jiao {
						position: absolute;
						top: 0.1375rem;
						right: -0.3125rem;
						width: 1.25rem;
						height: 0.3875rem;
						background: rgba(45, 174, 169, 1);
						text-align: center;
						line-height: 0.3875rem;
						color: white;
						transform: rotate(45deg);
					}
					.r-jiao-no {
						background: rgba(213, 213, 213, 1);
					}
					.lie-1 {
						display: flex;
						& > div {
							flex: 1;
							font-size: 0.2rem;
							span {
								color: rgba(45, 174, 169, 1);
								font-size: 0.2rem;
							}
						}
					}
				}
				.item-bottom {
					//   height: 0.7125rem;
					height: 0;
					overflow: hidden;
					transition: all 0.5s;
					box-sizing: border-box;

					border-top: 1px solid rgba(255, 255, 255, 0.6);
					display: flex;
					justify-content: space-around;
					.cz-div {
						cursor: pointer;
						display: flex;
						// display: none;
						align-items: center;
						img {
							margin-right: 0.15rem;
						}
					}
					.cz-line {
						// display: none;
						width: 0.0125rem;
						background: rgba(255, 255, 255, 0.6);
					}
					.blue {
						color: rgba(43, 137, 217, 1);
					}
					.green {
						color: rgba(45, 174, 169, 1);
					}
					.red {
						color: rgba(227, 52, 52, 1);
					}
				}
			}
			.item:hover {
				.item-bottom {
					height: 0.7125rem;
					display: flex;
					padding: 0.125rem;
				}
			}
		}
		.schedule-list-no {
			height: 80%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.prompts-title {
			position: relative;
			//   margin-top: 0.2875rem;
			display: flex;
			align-items: center;
			&::before {
				margin-right: 0.3rem;
				content: '';
				width: 0.025rem;
				height: 0.2rem;
				background: #2daea9;
			}
		}
		.time-box {
			flex: 1;
			height: 0.45rem;
			margin-left: 0.1875rem;
			.el-date-editor.el-input,
			.el-date-editor.el-input__inner {
				width: 100%;
				height: 100%;
				.el-input__inner {
					height: 100%;
					line-height: 0.45rem;
					border-radius: 0.1rem;
					padding-left: 0.25rem;
				}
				.el-input__icon {
					line-height: 0.45rem;
					font-size: 0.25rem;
				}
				.el-input__prefix {
					left: 250px;
				}
			}
			.el-select {
				width: 100%;
				height: 100%;
				.el-input__inner {
					height: 0.475rem;
					line-height: 0.475rem;
				}
				.el-icon-arrow-up:before {
					content: '\e78f';
				}
			}
		}
		.time-type-box {
			margin-top: 0.25rem;
			.el-radio-group {
				width: 100%;
			}
			.time-type-li {
				.li-head {
					cursor: pointer;
					box-sizing: border-box;
					height: 0.55rem;
					background: #f2f8f9;
					border: 1px solid #eeeeee;
					padding-left: 0.3125rem;
					padding-right: 0.3rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.el-radio__label {
						display: none;
					}
				}
				.li-bottom {
					height: 0;
					overflow: hidden;
					transition: all 0.5s;
					box-sizing: border-box;
					border-left: 1px solid #eeeeee;
					border-right: 1px solid #eeeeee;
					padding: 0 0.3125rem;

					.el-radio-group {
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.input-box {
							display: inline-block;
							// width: 49px;
							height: 22px;
							margin: 0 0.05rem;
						}
					}
					.el-checkbox-group {
						display: flex;
						flex-wrap: wrap;
						// align-items: center;
						.el-checkbox:nth-child(1) {
							margin-bottom: 18px;
						}
						.el-checkbox {
							margin-right: 28px;
						}
					}
					.picktime-box {
						position: relative;
						width: 4.3rem;
						height: 0.45rem;
						margin: 0.125rem auto 0;
						.el-date-editor.el-input,
						.el-date-editor.el-input__inner {
							width: 100%;
						}
						.pick-icon {
							position: absolute;
							right: 0.25rem;
							top: 45%;
							transform: translateY(-50%);
							font-size: 0.25rem;
							color: #e5e5e5;
						}
					}
				}
				.li-bottom-show {
					padding: 0.2rem 0.3125rem;
					height: 1.125rem;
				}
				.border-b {
					border-bottom: 1px solid #eeeeee;
				}
			}
		}
		.zxaction-box {
			margin-top: 0.25rem;
			height: 0.75rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			box-sizing: border-box;
			padding-left: 0.25rem;
			display: flex;
			align-items: center;
			.el-radio-group {
				.el-radio {
					margin-right: 1.05rem;
				}
			}
		}

		.table-box {
			border: 1px solid #eeeeee;
			margin-top: 0.25rem;
			.table-li {
				display: flex;
				.li-left {
					width: 29.4%;
					height: 0.6rem;
					box-sizing: border-box;
					background: #f2f8f9;
					text-align: center;
					line-height: 0.6rem;
					color: #666666;
				}
				.li-right {
					width: 70.6%;
					height: 0.6rem;
					box-sizing: border-box;
					padding-left: 0.3rem;
					color: #666666;
					text-align: left;
					line-height: 0.6rem;
				}
				.border-bottom {
					border-bottom: 1px solid #eeeeee;
				}
				.border-right {
					border-right: 1px solid #eeeeee;
				}
				.blue {
					color: rgba(43, 137, 217, 1);
				}
				.green {
					color: rgba(45, 174, 169, 1);
				}
				.red {
					color: rgba(227, 52, 52, 1);
				}
			}
		}
		.btn-box {
			flex: 1;
			// position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			.btn-item {
				box-sizing: border-box;
				border: 0.0125rem solid #2daea9;
				cursor: pointer;
				width: 1.75rem;
				height: 0.45rem;
				background: #2daea9;
				border-radius: 0.05rem;
				text-align: center;
				line-height: 0.45rem;
				color: white;
			}
			.cancel {
				background: #fff;
				color: #2daea9;
			}
		}
		.parameter-box {
			margin-top: 0.3rem;
			box-sizing: border-box;
			border: 1px solid #eeeeee;
			.box-header {
				box-sizing: border-box;
				padding-left: 0.25rem;
				line-height: 0.55rem;
				height: 0.55rem;
				background: #f2f8f9;
				border-bottom: 1px solid #eeeeee;
				color: #333333;
			}
			.box-content {
				box-sizing: border-box;
				padding: 0.2rem 0.5rem;
				min-height: 100px;
				.el-radio {
					margin-bottom: 0.2rem;
					// margin-right: 100px;
					width: 132px;
				}
				.el-radio:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
		.zxaction-box2 {
			margin-top: 0.25rem;
			height: 2.6rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			box-sizing: border-box;
			padding: 0.25rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.item-li {
				width: 100%;
				display: flex;
				align-items: center;
				.input-box {
					flex: 1;
					height: 0.4125rem;
					.el-input__inner {
						height: 0.4125rem;
						line-height: 0.4125rem;
					}
				}
			}
		}
		.seach-list {
			height: 7.925rem;
			overflow: auto;
			margin-top: 0.25rem;
			.list-item {
				cursor: pointer;
				height: 2.475rem;
				border-radius: 0.175rem;
				background: rgba(241, 249, 250, 1);
				margin-bottom: 0.25rem;
				box-sizing: border-box;
				padding: 0.125rem;
				.list-item-top {
					box-sizing: border-box;
					padding: 0.125rem;
					height: 0.575rem;
					border-bottom: 1px solid rgba(255, 255, 255, 1);
					display: flex;
					justify-content: space-between;
					font-size: 0.2rem;
					font-family: Microsoft YaHei;
					font-weight: bold;
					.r-div {
						color: #2daea9;
						img {
							width: 0.2rem;
							height: 0.2rem;
							margin-right: 0.075rem;
						}
					}
				}
				.list-item-bot {
					box-sizing: border-box;
					height: 1.75rem;
					padding: 0.225rem 0.1875rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.lib-div {
						font-size: 0.175rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						i {
							display: inline-block;
							font-style: normal;
							width: 0.75rem;
						}
						span {
							color: #333333;
							margin-left: 0.1875rem;
						}
					}
				}
			}
		}
		.seach-list-no {
			height: 7.925rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.performInfo {
			.info-item {
				display: flex;
				margin-bottom: 0.2875rem;
				.title {
					width: 90px;
					color: #999999;
					line-height: 1.5;
				}
				.cont {
					flex: 1;
					margin-left: 0.2rem;
					line-height: 1.5;
				}
			}
		}
		.timeChange-box {
			position: relative;
			height: 36px;
			.el-date-editor.el-input,
			.el-date-editor.el-input__inner {
				width: 100%;
			}
			.pick-icon {
				position: absolute;
				right: 0.25rem;
				top: 45%;
				transform: translateY(-50%);
				font-size: 0.25rem;
				color: #e5e5e5;
			}
		}
		.search-box-kgsb {
			min-height: 1.8875rem;
			background: #ffffff;
			border: 1px solid #f1f1f1;
			border-radius: 0.05rem;
			box-sizing: border-box;
			padding: 0.2rem 0.3rem;
			font-size: 0.2rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #454545;
			.saach-item {
				height: 0.45rem;
				margin-top: 0.2rem;

				.el-date-editor--datetimerange.el-input,
				.el-date-editor--datetimerange.el-input__inner {
					width: 100%;
					height: 100%;
					line-height: 0.45rem;
				}
				.el-range-editor.el-input__inner {
					width: 100%;
					height: 100%;
					line-height: 0.45rem;
				}
				.el-date-editor .el-range__icon {
					position: absolute;
					left: 313px;
					line-height: 0.35rem;
					font-size: 0.25rem;
				}
				.el-date-editor .el-range__close-icon {
					line-height: 0.35rem;
				}
				.el-input__suffix-inner {
					width: 0.2rem;
					height: 100%;
					display: block;
					box-sizing: border-box;
					// padding-top: 0.125rem;
					padding-top: 0;
					margin-right: 0.25rem;
					.seach-icon {
						position: relative;
						cursor: pointer;
						width: 0.2rem;
						height: 0.2rem;
						background: url('../../../assets/image/equipment/icon-seach.png') no-repeat;
						&::before {
							position: absolute;
							top: 50%;
							left: -0.25rem;
							transform: translateY(-50%);
							content: '';
							width: 1px;
							height: 22px;
							background: #f1f1f1;
						}
					}
				}
				.flex-sel {
					width: 48%;
					height: 100%;
					.el-select {
						width: 100%;
						height: 100%;
						.el-input__inner {
							height: 0.45rem;
							line-height: 0.45rem;
						}
						.el-input__icon {
							line-height: 0.45rem;
						}
						.el-icon-arrow-up:before {
							content: '\e78f';
						}
					}
				}
			}
		}
	}
	.Information {
		position: absolute;
		z-index: 999999;
		top: 40%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) scale(0);
		width: 3.45rem;
		height: 2.075rem;
		background: #ffffff;
		box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		transition: all 0.3s;
		.top {
			box-sizing: border-box;
			height: 1.375rem;
			text-align: center;
			line-height: 1.375rem;
			border-bottom: 1px solid #f1f1f1;
		}
		.bottom {
			display: flex;
			height: 0.6875rem;
			justify-content: space-around;
			align-items: center;
			& > div {
				cursor: pointer;
			}
			.line {
				width: 1px;
				height: 0.45rem;
				background: #f1f1f1;
			}
			.green {
				color: rgba(45, 174, 169, 1);
			}
		}
	}
	.Information-show {
		transform: translateX(-50%) translateY(-50%) scale(1);
	}
}
</style>
