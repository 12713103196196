<template>
	<div class="execute-page" @click="isShowTree = false">
		<!-- 原始显示搜索页面 -->
		<div class="init-page" v-show="!isShow">
			<div class="flex flex_a_c init-top">
				<img class="init-img" src="@/assets/image/personal/zx_icon.png" alt="" />
				<div class="t_l">
					<div class="titles">执行记录</div>
					<div class="words">记录您的设备所进行的操作</div>
				</div>
			</div>
			<div class="flex flex_a_c flex_ju_sb">
				<!-- 搜索 -->
				<el-autocomplete
					class="input-with-select"
					v-model="searchInfo"
					:fetch-suggestions="querySearch"
					placeholder="请输入设备名称查询"
					@select="handleSelect"
				>
					<el-button slot="append" icon="el-icon-search" @click="handleSearchSmall"></el-button>
				</el-autocomplete>
				<!-- <el-input placeholder="请输入设备名称查询" class="input-with-select">
					
				</el-input> -->
				<div class="font_16 c_white t_c cursor btn-search" @click="handleSearchAll">综合查询</div>
			</div>
		</div>
		<!-- 表单查询页面 -->
		<div class="all-page" v-show="isShow">
			<!-- 查询结果文字和关闭 -->
			<div class="flex flex_a_c flex_ju_sb bold top-titles">
				<span>查询结果</span>
				<i class="cursor el-icon-close" @click="closeForm"></i>
			</div>
			<!-- 表单 -->
			<div class="form-alls">
				<div class="form-wrap">
					<el-form
						:model="queryParam"
						ref="ruleform"
						:inline="true"
						class="warnform-inline"
						label-position="right"
						label-width="120px"
					>
						<div class="flex form-hadshow">
							<el-form-item label="时间选择">
								<el-date-picker
									v-model="times"
									type="daterange"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									format="yyyy-MM-dd"
									value-format="yyyy-MM-dd"
									:default-value="getPredate8()"
									:picker-options="pickerOptions1"
								>
								</el-date-picker>
							</el-form-item>
							<el-form-item label="产品类型">
								<el-select
									v-model="productType"
									placeholder="请选择产品类型"
									@change="handleTypeChange"
									multiple
									@focus="selectFocus"
									collapse-tags
								>
									<el-option
										v-for="(item, index) in productTree"
										:key="index"
										:label="item.productTypeName"
										:value="item.productType"
									></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="产品名称">
								<el-select
									v-model="deviceNum"
									placeholder="请选择产品名称"
									@change="deviceNameChange"
									multiple
									@focus="selectFocus"
									collapse-tags
								>
									<el-option
										v-for="(item, index) in equipNameSelect"
										:key="index"
										:label="item.deviceName"
										:value="item.deviceNum"
									></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="设备名称">
								<div class="input-tree" :class="{ 'input-tree-had': treeNames.length }" @click.stop="treeInputTap">
									{{ treeNames.length ? treeNames.join('、') : '请选择设备名称' }}
								</div>
								<!-- 树形选择：只有输入框聚焦时出现 -->
								<div class="tree-wrap" v-show="isShowTree">
									<el-tree :data="deviceNameTree" :props="defaultProps" node-key="id" @node-click="handleNodeClick">
										<template #default="{ node, data }">
											<div class="custom-tree-node">
												<!-- 有下一级 -->
												<span v-if="data.children && data.children.length">{{ node.label }}</span>
												<div class="flex flex_a_c flex_ju_sb" v-else>
													<span>{{ node.label }}</span>
													<div
														class="flex flex_a_c flex_ju_c check-div"
														:class="{
															'check-div-active': treeChoose.includes(data.id),
														}"
													>
														<i class="el-icon-check check-icon" v-if="treeChoose.includes(data.id)"></i>
													</div>
												</div>
											</div>
										</template>
									</el-tree>
								</div>
							</el-form-item>
							<div class="flex flex_a_c cursor coll-word" @click="handleFormColl">
								{{ isAllForm ? '收起' : '展开' }}
								<i class="el-icon-arrow-down icon-arrow-down" :class="{ rotate180: isAllForm }"></i>
							</div>
						</div>
						<!-- 可折叠选项 -->
						<!-- <div class="pos-wrap"> -->
						<div class="form-collapse" :class="{ 'form-collapse-show': isAllForm }">
							<div class="mt">
								<el-form-item label="操作源">
									<el-checkbox-group v-model="queryParam.operationSources">
										<el-checkbox v-for="(item, index) in sourceList" :key="index" :label="item.dictValue">{{
											item.dictLabel
										}}</el-checkbox>
									</el-checkbox-group>
								</el-form-item>
							</div>
							<div class="mt">
								<el-form-item label="业务类型">
									<el-checkbox-group v-model="queryParam.businessTypes">
										<el-checkbox v-for="(item, index) in businessList" :key="index" :label="item.dictValue">{{
											item.dictLabel
										}}</el-checkbox>
									</el-checkbox-group>
								</el-form-item>
							</div>
							<div class="mt">
								<el-form-item label="执行状态">
									<el-radio-group v-model="queryParam.operationResult">
										<el-radio v-for="(item, index) in orList" :key="index" :label="item.dictValue">{{
											item.dictLabel
										}}</el-radio>
									</el-radio-group>
								</el-form-item>
							</div>
							<div class="mt">
								<el-form-item label="是否本人操作">
									<el-radio-group v-model="userPerson">
										<el-radio label="3">是</el-radio>
										<el-radio label="6">否</el-radio>
									</el-radio-group>
								</el-form-item>
							</div>
							<!-- 下面的按钮 -->
							<div class="flex flex_a_c flex_ju_c btns-wrap">
								<div class="btn-div cursor btn-reset" @click="formReset">重置</div>
								<div class="btn-div cursor btn-search" @click="searchClick">查询</div>
							</div>
						</div>

						<!-- </div> -->
					</el-form>
					<div class="flex flex_a_c flex_ju_c btns-wrap">
						<div class="btn-div cursor btn-reset" @click="formReset">重置</div>
						<div class="btn-div cursor btn-search" @click="searchClick">查询</div>
					</div>
				</div>
			</div>
			<!-- table表格 -->
			<div class="flex flex_col warn-tables" @click="isAllForm = false">
				<!-- 表格 -->
				<el-table
					ref="multipleTable"
					:data="tableData"
					class="tables"
					:height="440"
					:stripe="true"
					align="center"
					header-cell-class-name="header-cell"
					cell-class-name="table-cell"
				>
					<el-table-column prop="operateTime" label="时间" align="center" width="160" show-overflow-tooltip>
					</el-table-column>

					<el-table-column prop="areaName" label="区域" align="center" show-overflow-tooltip> </el-table-column>

					<el-table-column prop="productTypeName" label="产品类型" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="productName" label="产品名称" align="center" show-overflow-tooltip> </el-table-column>

					<el-table-column prop="deviceNum" label="设备编号" align="center" show-overflow-tooltip> </el-table-column>

					<el-table-column prop="deviceName" label="设备名称" align="center" show-overflow-tooltip> </el-table-column>
					<el-table-column prop="operationAccount" label="操作账号" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="operationSourceName" label="操作源" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="businessTypeName" label="业务类型" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="operationResultName" label="执行状态" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="ipAddress" label="位置信息" align="center" show-overflow-tooltip> </el-table-column>
					<el-table-column prop="executionCondition" label="执行条件" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="recordContents" label="执行内容" align="center" show-overflow-tooltip>
					</el-table-column>
					<!-- 占位数据 -->
					<div slot="empty" class="t_c table-empty">
						<img class="table-noimg" src="@/assets/image/personal/no_table.png" alt="" />
						<div class="table-emptyword">请进行综合查询</div>
					</div>
				</el-table>
				<!-- 分页 -->
				<el-pagination
					class="pagination"
					layout="prev, pager, next"
					:current-page.sync="ipagination.currentPage"
					:page-size="ipagination.pageSize"
					:total="ipagination.total"
					:hide-on-single-page="true"
					@current-change="handleCurrentChange"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
/*
 * @description: 执行记录页面
 */
export default {
	data() {
		return {
			queryData: [], // 本地搜索的保存数据
			searchInfo: '',
			times: [],
			/* 查询条件 */
			queryParam: {
				operationSources: [], // 操作源
				businessTypes: [], // 业务类型
				operationResult: '', // 执行结果
			},
			userPerson: '3', // 是否本人操作
			productType: [],
			deviceNum: [],
			treeChoose: [],
			treeNames: [], // 设备名称选中显示值
			isShow: false, // 是否显示表单查询页面
			isAllForm: false, // 是否展示全部的表单搜索项
			// 表格数据
			tableData: [],
			/* 分页参数 */
			ipagination: {
				pageSize: 8,
				total: 0,
				currentPage: 1,
			},
			// 表单选择项数据
			// 产品类型
			productTree: [],
			equipNameSelect: [], // 产品名称
			equipAll: [], // 产品名称全部数据，主要用于从搜索页过来的情况
			deviceNameTree: [], // 设备名称
			isShowTree: false,
			defaultProps: {
				children: 'children',
				label: 'label',
			},
			// 操作源
			sourceList: [],
			// 业务类型
			businessList: [],
			// 操作结果选择
			orList: [],
			pickerOptions1: {
				disabledDate: (time) => {
					let num = new Date().getTime()
					if (time.getTime() > num) {
						return true
					}
				},
			},
		}
	},
	mounted() {
		this.findAreaDeviceList()
		// 请求表格数据
		// this.loadData(1)
		// 获取执行条件选择数据
		this.getChooseData()
	},
	methods: {
		// 搜索
		querySearch(queryString, cb) {
			var restaurants = this.queryData
			let results = []
			if (queryString) {
				results = restaurants.filter((item) => {
					return item.value.indexOf(queryString) > -1
				})
			}
			// 调用 callback 返回建议列表的数据
			cb(results)
		},
		// 设备选中
		handleSelect() {
			// console.log(this.searchInfo)
		},
		// 列表数据加载
		loadData(arg) {
			if (arg == 1) {
				this.ipagination.currentPage = 1
			}
			let listArr = []
			if (this.treeChoose.length) {
				this.treeChoose.forEach((item) => {
					let codeArrs = item.split('-')
					let mapIndex = listArr.findIndex((items) => {
						return items.deviceNum == codeArrs[0]
					})
					if (mapIndex > -1) {
						listArr[mapIndex]['subDeviceNums'].push(codeArrs[1])
					} else {
						listArr.push({
							deviceNum: codeArrs[0],
							subDeviceNums: [codeArrs[1]],
						})
					}
				})
			}
			let params = {
				currentPage: this.ipagination.currentPage,
				pageSize: this.ipagination.pageSize,
				// operationResult: '',
			}
			if (this.times && this.times.length) {
				this.$set(params, 'startTime', this.times[0])
				this.$set(params, 'endTime', this.times[1])
			}
			if (this.productType && this.productType.length) {
				this.$set(params, 'productType', this.productType.join(','))
			}
			if (this.deviceNum && this.deviceNum.length) {
				this.$set(params, 'deviceNums', this.deviceNum)
			}
			if (listArr.length) {
				this.$set(params, 'list', listArr)
			}
			if (this.userPerson == '3') {
				let userInfo = JSON.parse(window.localStorage.getItem('wlwUsers')) || {}
				this.$set(params, 'operationAccount', userInfo.loginName || '')
			} else {
				this.$set(params, 'operationAccount', '')
			}

			// 折叠项数据
			// 操作源
			if (this.queryParam.operationSources && this.queryParam.operationSources.length) {
				this.$set(params, 'operationSources', this.queryParam.operationSources)
			}
			if (this.queryParam.businessTypes && this.queryParam.businessTypes != '') {
				this.$set(params, 'businessTypes', this.queryParam.businessTypes)
			}
			if (this.queryParam.operationResult && this.queryParam.operationResult.length) {
				this.$set(params, 'operationResult', this.queryParam.operationResult)
			}
			console.log(params)
			this.$http.postAction(this.$api.recordList, params).then((res) => {
				if (res.code == 200) {
					let result = res.data || {}
					if (result.totalCount > 0) {
						let datas = result.records || []
						this.tableData = datas || []
						this.ipagination.total = Number(result.totalCount)
					} else {
						this.tableData = []
						this.ipagination.total = 0
					}
				}
			})
		},
		// 搜索点击查询
		handleSearchSmall() {
			console.log(this.searchInfo == '', 'searchInfo')
			if (this.searchInfo == '') {
				this.$message('无该设备的执行记录', 'error')
				return
			}
			let nameArr = this.searchInfo.split('-')

			// 根据值赋值设备选中项
			let nameTwo = nameArr[1] // 只能卷被机
			let nameThree = nameArr[2] // 卷被机
			let productIndex = this.equipAll.findIndex((item) => {
				return item.deviceName == nameTwo && item.areaName == nameArr[0]
			})
			if (productIndex > -1) {
				let productTypes = this.equipAll[productIndex]['productType']
				let deviceNums = this.equipAll[productIndex]['deviceNum']
				// 赋值选中值
				this.productType.push(productTypes)
				this.deviceNum.push(deviceNums)
				this.treeNames.push(nameThree)

				// 设置产品名称下拉选
				let deviceTree = []
				let mapIndex = this.productTree.findIndex((items) => {
					return items.productType == productTypes
				})
				if (mapIndex > -1) {
					deviceTree = deviceTree.concat(this.productTree[mapIndex]['deviceTree'] || [])
				}
				this.equipNameSelect = deviceTree

				// 设置设备名称的选择项

				let trees = []
				deviceTree.forEach((items) => {
					let isInclude = deviceNums.includes(items.deviceNum)
					if (isInclude) {
						let obj = {
							label: items.deviceName,
							id: items.deviceNum,
							children: [],
						}
						if (items.subsetList && items.subsetList.length) {
							items.subsetList.forEach((item) => {
								if (item.subDeviceName == nameThree) {
									this.treeChoose.push(items.deviceNum + '-' + item.subDeviceNum)
								}
								obj.children.push({
									label: item.subDeviceName,
									id: items.deviceNum + '-' + item.subDeviceNum,
								})
							})
						}
						trees.push(obj)
					}
				})
				this.deviceNameTree = trees
			} else {
				this.productType = []

				this.deviceNum = []
				this.treeChoose = []
				this.treeNames = []

				this.equipNameSelect = []
				this.deviceNameTree = []
			}
			// 页面显示开始请求数据
			this.$nextTick(() => {
				this.isShow = true
				this.loadData(1)
			})
		},
		// 综合查询点击
		handleSearchAll() {
			this.isShow = true
			// 数据重置
			this.formReset()
			this.loadData(1)
		},
		// 关闭表单和表格数据
		closeForm() {
			this.isShow = false
		},
		// 展开隐藏表单下部分
		handleFormColl() {
			this.isAllForm = !this.isAllForm
		},
		selectFocus() {
			this.isShowTree = false
		},
		// 点击重置
		formReset() {
			this.$refs['ruleform'].resetFields()
			for (const key in this.queryParam) {
				if (key == 'operationSources' || key == 'businessTypes') {
					this.queryParam[key] = []
				} else {
					this.queryParam[key] = ''
				}
			}
			// 选中值重置
			this.times = []
			this.productType = ''
			this.deviceNum = []
			this.treeChoose = []
			this.deviceNameTree = []
			this.treeNames = []
			this.equipNameSelect = [] // 产品名称下拉选
			this.deviceNameTree = [] // 设备名称下拉选
			this.userPerson = '3'
			this.searchClick()
		},
		// 点击查询
		searchClick() {
			this.$refs['ruleform'].validate((valid) => {
				if (valid) {
					this.loadData(1)
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		// 产品类型选中
		handleTypeChange(val) {
			// let mapIndex = this.productTree.findIndex((item) => {
			// 	return item.productType == val
			// })
			// let deviceTree = this.productTree[mapIndex]['deviceTree'] || []
			// // 赋值产品名称下拉选择
			// this.equipNameSelect = deviceTree
			// this.deviceNum = []

			// this.deviceNameTree = []
			// this.treeChoose = []

			let deviceTree = []
			val.forEach((item) => {
				let mapIndex = this.productTree.findIndex((items) => {
					return items.productType == item
				})
				if (mapIndex > -1) {
					deviceTree = deviceTree.concat(this.productTree[mapIndex]['deviceTree'] || [])
				}
			})
			this.equipNameSelect = deviceTree
			this.deviceNum = []
			this.treeChoose = []
			this.treeNames = []

			this.deviceNameTree = []
		},
		// 产品名称选中
		deviceNameChange(val) {
			let trees = []
			this.equipNameSelect.forEach((items) => {
				let isInclude = val.includes(items.deviceNum)
				if (isInclude) {
					let obj = {
						label: items.deviceName,
						id: items.deviceNum,
						children: [],
					}
					if (items.subsetList && items.subsetList.length) {
						items.subsetList.forEach((item) => {
							obj.children.push({
								label: item.subDeviceName,
								id: items.deviceNum + '-' + item.subDeviceNum,
							})
						})
					}
					trees.push(obj)
				}
			})
			this.deviceNameTree = trees
			this.treeChoose = []
			this.treeNames = []
		},
		// 设备名称聚焦
		treeInputTap() {
			this.isShowTree = !this.isShowTree
		},
		// 树形点击事件
		handleNodeClick(data) {
			if (data.children && data.children.length) return
			// 此列表单选
			let ids = data.id
			let names = data.label
			let findindex = this.treeChoose.indexOf(ids)
			if (this.treeChoose.includes(ids)) {
				this.treeChoose.splice(findindex, 1)
				this.treeNames.splice(findindex, 1)
			} else {
				this.treeChoose.push(ids)
				this.treeNames.push(names)
			}
		},
		// 点击查询
		// 分页的页码改变
		handleCurrentChange(val) {
			this.ipagination.currentPage = val
			this.loadData()
		},
		// 获取执行条件选择数据
		getChooseData() {
			let params = {
				areaList: [],
			}
			this.$http.postAction(this.$api.queryRecord, params).then((res) => {
				// res = {
				// 	code: 200,
				// 	msg: 'ok',
				// 	data: {
				// 		osList: [
				// 			{
				// 				id: '85',
				// 				createId: '49',
				// 				createName: '13383917367',
				// 				modifyId: '49',
				// 				modifyName: '13383917367',
				// 				dictLabel: 'WEB',
				// 				dictValue: '1',
				// 				dictDataSort: 1,
				// 				status: 0,
				// 				statusDesc: '启用',
				// 				remark: '',
				// 			},
				// 			{
				// 				id: '86',
				// 				createId: '49',
				// 				createName: '13383917367',
				// 				modifyId: '49',
				// 				modifyName: '13383917367',
				// 				dictLabel: 'APP',
				// 				dictValue: '2',
				// 				dictDataSort: 2,
				// 				status: 0,
				// 				statusDesc: '启用',
				// 				remark: '',
				// 			},
				// 			{
				// 				id: '87',
				// 				createId: '49',
				// 				createName: '13383917367',
				// 				modifyId: '49',
				// 				modifyName: '13383917367',
				// 				dictLabel: '遥控器',
				// 				dictValue: '3',
				// 				dictDataSort: 3,
				// 				status: 0,
				// 				statusDesc: '启用',
				// 				remark: '',
				// 			},
				// 			{
				// 				id: '88',
				// 				createId: '49',
				// 				createName: '13383917367',
				// 				modifyId: '49',
				// 				modifyName: '13383917367',
				// 				dictLabel: '物理旋钮',
				// 				dictValue: '4',
				// 				dictDataSort: 4,
				// 				status: 0,
				// 				statusDesc: '启用',
				// 				remark: '',
				// 			},
				// 			{
				// 				id: '89',
				// 				createId: '49',
				// 				createName: '13383917367',
				// 				modifyId: '49',
				// 				modifyName: '13383917367',
				// 				dictLabel: '设备自动化',
				// 				dictValue: '5',
				// 				dictDataSort: 5,
				// 				status: 0,
				// 				statusDesc: '启用',
				// 				remark: '',
				// 			},
				// 			{
				// 				id: '90',
				// 				createId: '49',
				// 				createName: '13383917367',
				// 				modifyId: '49',
				// 				modifyName: '13383917367',
				// 				dictLabel: '云端自动化',
				// 				dictValue: '6',
				// 				dictDataSort: 6,
				// 				status: 0,
				// 				statusDesc: '启用',
				// 				remark: '',
				// 			},
				// 		],
				// 		orList: [
				// 			{
				// 				id: '96',
				// 				createId: '49',
				// 				createName: '13383917367',
				// 				modifyId: '49',
				// 				modifyName: '13383917367',
				// 				dictLabel: '执行失败',
				// 				dictValue: '2',
				// 				dictDataSort: 2,
				// 				status: 0,
				// 				statusDesc: '启用',
				// 				remark: '',
				// 			},
				// 			{
				// 				id: '97',
				// 				createId: '49',
				// 				createName: '13383917367',
				// 				modifyId: '49',
				// 				modifyName: '13383917367',
				// 				dictLabel: '执行成功',
				// 				dictValue: '3',
				// 				dictDataSort: 3,
				// 				status: 0,
				// 				statusDesc: '启用',
				// 				remark: '',
				// 			},
				// 		],
				// 		ywList: [
				// 			{
				// 				id: '91',
				// 				createId: '49',
				// 				createName: '13383917367',
				// 				modifyId: '49',
				// 				modifyName: '13383917367',
				// 				dictLabel: '远程控制',
				// 				dictValue: '1',
				// 				dictDataSort: 1,
				// 				status: 0,
				// 				statusDesc: '启用',
				// 				remark: '',
				// 			},
				// 			{
				// 				id: '92',
				// 				createId: '49',
				// 				createName: '13383917367',
				// 				modifyId: '49',
				// 				modifyName: '13383917367',
				// 				dictLabel: '微调',
				// 				dictValue: '2',
				// 				dictDataSort: 2,
				// 				status: 0,
				// 				statusDesc: '启用',
				// 				remark: '',
				// 			},
				// 			{
				// 				id: '93',
				// 				createId: '49',
				// 				createName: '13383917367',
				// 				modifyId: '49',
				// 				modifyName: '13383917367',
				// 				dictLabel: '急停',
				// 				dictValue: '3',
				// 				dictDataSort: 3,
				// 				status: 0,
				// 				statusDesc: '启用',
				// 				remark: '',
				// 			},
				// 			{
				// 				id: '94',
				// 				createId: '49',
				// 				createName: '13383917367',
				// 				modifyId: '49',
				// 				modifyName: '13383917367',
				// 				dictLabel: '设备自动化',
				// 				dictValue: '4',
				// 				dictDataSort: 4,
				// 				status: 0,
				// 				statusDesc: '启用',
				// 				remark: '',
				// 			},
				// 		],
				// 		areaList: [
				// 			{
				// 				id: '0',
				// 				areaName: '全区域',
				// 				sysUserId: '41',
				// 			},
				// 		],
				// 		productTree: [
				// 			{
				// 				productType: '6',
				// 				productTypeName: '智能终端',
				// 				deviceTree: [
				// 					{
				// 						deviceId: '756',
				// 						productId: '27',
				// 						productType: '6',
				// 						deviceName: '智能卷被机',
				// 						deviceNum: '22102603000',
				// 						deviceCompId: '22102603000',
				// 						areaName: '全区域',
				// 						areaId: '0',
				// 						subsetList: [
				// 							{
				// 								subDeviceNum: 'c_1',
				// 								subDeviceName: '卷被机',
				// 							},
				// 						],
				// 						buttonList: [],
				// 					},
				// 					{
				// 						deviceId: '757',
				// 						productId: '28',
				// 						productType: '6',
				// 						deviceName: '智能卷被机2',
				// 						deviceNum: '22102603001',
				// 						deviceCompId: '22102603001',
				// 						areaName: '全区域',
				// 						areaId: '0',
				// 						subsetList: [
				// 							{
				// 								subDeviceNum: 'c_11',
				// 								subDeviceName: '卷被机11',
				// 							},
				// 							{
				// 								subDeviceNum: 'c_12',
				// 								subDeviceName: '卷被机12',
				// 							},
				// 						],
				// 						buttonList: [],
				// 					},
				// 				],
				// 			},
				// 			{
				// 				productType: '7',
				// 				productTypeName: '一体化控制箱',
				// 				deviceTree: [
				// 					{
				// 						deviceId: '782',
				// 						productId: '33',
				// 						productType: '7',
				// 						deviceName: '一体化控制箱一号',
				// 						deviceNum: '22102605000',
				// 						areaId: '0',
				// 						subsetList: [
				// 							{
				// 								subDeviceNum: 'f_1',
				// 								subDeviceName: '防风机一号',
				// 							},
				// 							{
				// 								subDeviceNum: 'f_2',
				// 								subDeviceName: '防风机二号',
				// 							},
				// 						],
				// 					},
				// 				],
				// 			},
				// 		],
				// 	},
				// }
				if (res.code == 200) {
					let datas = res.data || {}
					// 产品类型
					if (datas.productTree && datas.productTree.length) {
						let equipAll = []
						this.productTree = datas.productTree
						datas.productTree.forEach((item) => {
							equipAll = equipAll.concat(item.deviceTree)
						})
						this.equipAll = equipAll
					} else {
						this.productTree = []
					}
					// 操作源
					if (datas.osList && datas.osList.length) {
						this.sourceList = datas.osList
					} else {
						this.sourceList = []
					}

					// 业务类型
					if (datas.ywList && datas.ywList.length) {
						this.businessList = datas.ywList
					} else {
						this.businessList = []
					}
					// 操作结果/执行状态
					if (datas.orList && datas.orList.length) {
						this.orList = datas.orList
					} else {
						this.orList = []
					}
				}
			})
		},
		// 获取产品名称设备名称区域信息
		findAreaDeviceList() {
			this.$http.postAction(this.$api.findAreaDeviceList).then((res) => {
				if (res.code == 200) {
					let datas = res.data || []
					let result = []
					datas.forEach((item) => {
						result.push({
							value: item,
						})
					})
					this.queryData = result
				} else {
					this.queryData = []
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.execute-page {
	height: 100%;
	background-color: #fff;
	border-radius: 0.13rem;
	// 默认显示页面
	.init-page {
		width: 100%;
		padding: 2.51rem 4.69rem 0 6.54rem;
		.init-top {
			margin-bottom: 0.8rem;
			padding-left: 3.08rem;
		}
		.init-img {
			width: 0.8rem;
			height: 0.84rem;
			margin-right: 0.3rem;
		}
		.titles {
			color: #2daea9;
			font-size: 0.3rem;
		}
		.words {
			font-size: 0.17rem;
			color: #999999;
			margin-top: 0.2rem;
		}
		.input-with-select {
			width: 9.46rem;
		}
		.btn-search {
			width: 1.35rem;
			height: 0.45rem;
			line-height: 0.45rem;
			background: #2daea9;
			border-radius: 4px;
		}
	}
	// 表单查询页面
	.all-page {
		.top-titles {
			padding: 0.3rem;
		}
		// 表单
		.form-wrap {
			border-top: 1px dashed #f1f1f1;
			border-bottom: 1px dashed #f1f1f1;
			padding: 0.3rem 0 0.3rem 0;
			.warnform-inline {
				padding: 0;
				position: relative;
			}
			.input-tree {
				width: 3.2rem;
				height: 0.45rem;
				line-height: 0.45rem;
				border-radius: 4px;
				border: 1px solid #dcdfe6;
				cursor: pointer;
				text-align: left;
				padding-left: 0.2rem;
				font-size: 0.17rem;
				color: #999999;
				overflow: hidden;
			}
			.input-tree-had {
				color: #454545;
				font-size: 0.2rem;
			}
			.tree-wrap {
				position: absolute;
				width: 100%;
				max-height: 3.92rem;
				box-shadow: 0px 4px 14px 0px rgba(44, 44, 45, 0.1);
				border-radius: 4px;
				z-index: 22;
			}
			.custom-tree-node {
				width: 100%;
				text-align: left;
			}
			.coll-word {
				margin-left: 0.45rem;
			}
			.check-div {
				width: 0.2rem;
				height: 0.2rem;
				background: #ffffff;
				border: 1px solid #2daea9;
				border-radius: 4px;
				margin-right: 0.14rem;
			}
			.check-div-active {
				background-color: #2daea9;
				.check-icon {
					color: #fff;
					font-size: 0.1rem;
				}
			}
			.pos-wrap {
				position: relative;
			}
			.form-hadshow {
				padding: 0 0.3rem;
			}
			.form-collapse {
				width: 100%;
				height: 0;
				overflow: hidden;
				transition: all 0.5s;
				position: absolute;
				background-color: #fff;
				z-index: 20;
				padding: 0 0.3rem;
				.mt {
					margin-top: 0.1rem;
				}
			}
			.form-collapse-show {
				height: 3.8rem;
				box-shadow: 0px 10px 14px 0px rgba(44, 44, 45, 0.1);
				border-radius: 4px;
			}
			// 按钮
			.btns-wrap {
				margin-top: 0.7rem;
				.btn-div {
					text-align: center;
					width: 1.75rem;
					height: 0.45rem;
					line-height: 0.45rem;
					font-size: 0.17rem;
					border-radius: 4px;
				}
				.btn-reset {
					border: 1px solid #2daea9;
					background-color: #fff;
					color: #2daea9;
				}
				.btn-search {
					border: 1px solid #2daea9;
					background: #2daea9;
					color: #fff;
					margin-left: 0.3rem;
				}
			}
		}
		// 表格数据
		.warn-tables {
			padding: 0 0.3rem;
			margin-top: 0.3rem;
			border-radius: 0;
			// height: 440px;
			// overflow: hidden;
			min-height: 5.6rem;
			.tables {
				border-radius: 0;
			}
		}
	}
}
</style>
<style lang="scss">
.execute-page {
	.input-with-select {
		.el-input__inner {
			width: 8.59rem;
			height: 0.45rem;
			line-height: 0.45rem;
			margin: 0;
		}
		.el-input-group__append {
			width: 0.88rem;
			padding: 0;
			background-color: #2daea9;
			border-color: #2daea9;
			color: #fff;
			border-left: 1px solid #2daea9;
			right: 1px;
		}
	}
	.warn-tables {
		.tables {
			border-radius: 0;
			.cell {
				font-size: 0.17rem;
			}
		}
	}
	.form-hadshow {
		.el-form-item__content {
			position: relative;
		}
		.el-select {
			width: 3.2rem;
		}
		.el-range-editor {
			width: 3.8rem !important;
			.el-range-input {
				width: 40%;
			}
			.el-range-separator {
				padding: 0;
			}
		}
	}

	.form-collapse {
		text-align: left;
		.el-form-item__content {
			vertical-align: middle;
		}
		.el-checkbox-group {
			.el-checkbox {
				width: 2.63rem;
				margin-right: 0;
			}
		}
		.el-radio-group {
			.el-radio {
				width: 2.63rem;
				margin-right: 0;
			}
		}
	}
}
</style>
