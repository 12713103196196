<template>
	<div class="deviceDetails" v-if="drawer">
		<el-drawer custom-class="deviceDrawer" title="设备详情" :visible.sync="drawer" size="23%" @close="drawerClose">
			<!-- 顶部盒子 -->
			<div class="drawe-top">
				<div class="img-box">
					<img v-if="deviceInfo.productType == '1'" src="@/assets/image/equipment/icon_ythkzx.png" alt="" />
					<img v-if="deviceInfo.productType == '2'" src="@/assets/image/equipment/icon_qxz.png" alt="" />
					<img v-if="deviceInfo.productType == '3'" src="@/assets/image/equipment/icon_sqz.png" alt="" />
					<img v-if="deviceInfo.productType == '4'" src="@/assets/image/equipment/icon_scd.png" alt="" />
					<img v-if="deviceInfo.productType == '5'" src="@/assets/image/equipment/icon_cq.png" alt="" />
					<img v-if="deviceInfo.productType == '6'" src="@/assets/image/equipment/icon_znzd.png" alt="" />
					<img v-if="deviceInfo.productType == '2-6'" src="@/assets/image/equipment/icon_znzd.png" alt="" />
				</div>
				<div class="txt-box">
					<div class="tb-li doble">{{ deviceInfo.deviceName }}</div>
					<div class="tb-li">
						{{ deviceInfo.areaName }}
						<div class="line"></div>
						<span class="green" v-if="deviceInfo.deviceStatus == 'online'">在线</span>
						<span class="red" v-if="deviceInfo.deviceStatus == 'offline'">离线</span>
					</div>
					<div class="tb-li">
						产品型号：<span class="blue">{{ deviceInfo.productId }}</span>
					</div>
					<div class="tb-li">
						产品编号：<span class="blue">{{ deviceInfo.deviceNum }}</span>
					</div>
				</div>
			</div>
			<!-- 菜单盒子 -->
			<div class="menu-box">
				<div class="menu-li" @click="menuClick(0)">设备解绑</div>
				<!-- <div class="menu-li" @click="menuClick(1)">执行记录</div> -->
				<div class="menu-li" @click="menuClick(2)">共享设备</div>
				<div class="menu-li" @click="menuClick(3)">解除共享</div>
				<div class="b-line"></div>
				<div class="menu-li" @click="menuClick(4)">产品信息</div>
				<div class="menu-li" @click="menuClick(5)">采集间隔</div>
				<div v-show="isAlertShow" class="menu-li" @click="menuClick(6)">告警间隔</div>
				<div class="menu-li" @click="menuClick(7)">
					<span>固件更新<span class="blue" v-if="updateObj.upgrade">(发现新版本)</span></span>
				</div>
			</div>
			<!-- 里面潜逃的抽屉 -->
			<!-- 设备解绑 -->
			<el-drawer
				title="设备解绑"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.unbundling"
				custom-class="insideDrawe flcu-box"
			>
				<!-- 顶部盒子 -->
				<div class="drawe-top">
					<div class="img-box">
						<img v-if="deviceInfo.productType == '1'" src="@/assets/image/equipment/icon_ythkzx.png" alt="" />
						<img v-if="deviceInfo.productType == '2'" src="@/assets/image/equipment/icon_qxz.png" alt="" />
						<img v-if="deviceInfo.productType == '3'" src="@/assets/image/equipment/icon_sqz.png" alt="" />
						<img v-if="deviceInfo.productType == '4'" src="@/assets/image/equipment/icon_scd.png" alt="" />
						<img v-if="deviceInfo.productType == '5'" src="@/assets/image/equipment/icon_cq.png" alt="" />
						<img v-if="deviceInfo.productType == '6'" src="@/assets/image/equipment/icon_znzd.png" alt="" />
					</div>
					<div class="txt-box">
						<div class="tb-li doble">{{ deviceInfo.deviceName }}</div>
						<div class="tb-li">
							{{ deviceInfo.areaName }}
							<div class="line"></div>
							<span class="green" v-if="deviceInfo.deviceStatus == 'online'">在线</span>
							<span class="red" v-if="deviceInfo.deviceStatus == 'offline'">离线</span>
						</div>
						<div class="tb-li">
							产品型号：<span class="blue">{{ deviceInfo.productId }}</span>
						</div>
						<div class="tb-li">
							产品编号：<span class="blue">{{ deviceInfo.deviceNum }}</span>
						</div>
					</div>
				</div>
				<div class="icon-box">
					<div class="img">
						<img src="../../../assets/image/equipment/jiebang.png" alt="" />
						<div class="img_txt">确定解除设备绑定吗？</div>
					</div>
					<div class="btn-box">
						<div class="btn-item" @click="insideSwitch.unbundling = false">取消</div>
						<div class="btn-item quding-item" @click="unbundlingClick">确定</div>
					</div>
				</div>
			</el-drawer>
			<!-- 执行记录 -->
			<el-drawer
				title="执行记录"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.execution"
				custom-class="insideDrawe flcu-box"
			>
				<!-- 查询条件盒子 -->
				<div class="search-box">
					<span>查询条件</span>
					<div class="saach-item">
						<el-select v-model="zxjlSeachForm.shebei" placeholder="请选择子设备">
							<el-option v-for="item in seachjiluLIst" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="saach-item">
						<el-date-picker
							v-model="zxjlSeachForm.time"
							type="datetimerange"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							value-format="YYYY-MM-DD"
						>
						</el-date-picker>
					</div>
				</div>
				<!-- 查询列表盒子 -->
				<div class="seach-list">
					<div class="list-item" v-for="(item, index) in performList" :key="index" @click="performItemClick(index)">
						<div class="list-item-top">
							<div>{{ item.name }}</div>
							<div class="r-div">
								<img src="../../../assets/image/equipment/icon-chengg.png" alt="" />
								执行成功
							</div>
						</div>
						<div class="list-item-bot">
							<div class="lib-div ellipsis">
								<i>所属区域:</i>
								<span>{{ item.area }}</span>
							</div>
							<div class="lib-div ellipsis">
								<i>执行时间:</i><span>{{ item.time }}</span>
							</div>
							<div class="lib-div ellipsis">
								<i>操作源:</i><span>{{ item.source }}</span>
							</div>
							<div class="lib-div ellipsis">
								<i>执行内容:</i><span>{{ item.content }}</span>
							</div>
						</div>
					</div>
				</div>
				<!-- 执行记录详情 -->
				<el-drawer
					title="详情（控制箱一号-放风机01号）"
					:append-to-body="true"
					size="25%"
					:visible.sync="insideSwitch.executionInfo"
					custom-class="insideDrawe flcu-box"
				>
					<div class="performInfo">
						<div class="info-item">
							<div class="title">所属区域:</div>
							<div class="cont">{{ performInfoObj.area }}</div>
						</div>
						<div class="info-item">
							<div class="title">执行时间:</div>
							<div class="cont">{{ performInfoObj.time }}</div>
						</div>
						<div class="info-item">
							<div class="title">操作源:</div>
							<div class="cont">{{ performInfoObj.source }}</div>
						</div>
						<div class="info-item">
							<div class="title">产品类型:</div>
							<div class="cont">{{ performInfoObj.cpType }}</div>
						</div>
						<div class="info-item">
							<div class="title">产品名称:</div>
							<div class="cont">{{ performInfoObj.cpName }}</div>
						</div>
						<div class="info-item">
							<div class="title">设备编号:</div>
							<div class="cont">{{ performInfoObj.cpNum }}</div>
						</div>
						<div class="info-item">
							<div class="title">业务类型:</div>
							<div class="cont">{{ performInfoObj.ywType }}</div>
						</div>
						<div class="info-item">
							<div class="title">操作账号:</div>
							<div class="cont">{{ performInfoObj.czNumber }}</div>
						</div>
						<div class="info-item">
							<div class="title">位置信息:</div>
							<div class="cont">{{ performInfoObj.address }}</div>
						</div>
						<div class="info-item">
							<div class="title">执行状态:</div>
							<div class="cont">{{ performInfoObj.performState }}</div>
						</div>
						<div class="info-item">
							<div class="title">执行条件:</div>
							<div class="cont">{{ performInfoObj.performconditions }}</div>
						</div>
						<div class="info-item">
							<div class="title">执行内容:</div>
							<div class="cont">{{ performInfoObj.content }}</div>
						</div>
					</div>
				</el-drawer>
			</el-drawer>
			<!-- 共享设备 -->
			<el-drawer
				title="共享设备"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.shared"
				custom-class="insideDrawe flcu-box gxsbDrawe"
			>
				<div class="toptxt-div">
					<img src="@/assets/image/equipment/tishi.png" alt="" />
					<div class="txt-div">共享后其他用户可以查看或操作您的设备，请谨慎操作； 您可以对共享的设备随时取消共享</div>
				</div>
				<div class="title-div">
					搜索账号
					<span>（您可以搜索<span class="green">多个</span>账号进行共享）</span>
				</div>
				<div class="input-div">
					<el-input v-model="sharedInput" placeholder="请输入账号">
						<div slot="suffix" class="seach-icon"></div>
					</el-input>
				</div>
				<!-- 用户列表 -->
				<div class="user-list">
					<div
						class="user-item"
						:class="{ 'user-item-act': item.isCheck }"
						v-for="(item, index) in userList"
						:key="index"
						@click="userClick(index)"
					>
						<div class="r-l"></div>
						<div class="r-r">
							<div class="name">物联网昵称</div>
							<div>13256487963</div>
						</div>
						<div class="check">
							<i class="el-icon-check"></i>
						</div>
					</div>
				</div>
				<!-- 确定按钮 -->
				<div class="btn-box">
					<div class="btn-item" @click="sharedBtnClick">确定</div>
				</div>
			</el-drawer>
			<!-- 解除共享 -->
			<el-drawer
				title="解除共享"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.remove"
				custom-class="insideDrawe flcu-box"
			>
				<el-checkbox v-model="allchecked" @change="allcheckedChange">全选</el-checkbox>
				<div class="remove-list">
					<div class="remove-item" v-for="(item, index) in removelist" :key="index">
						<div class="item-header">
							<div class="h-l">共享时间：{{ item.time }}</div>
							<el-checkbox v-model="item.isCheckd" @change="removeCheckChange(item, index)"></el-checkbox>
						</div>
						<div class="item-bot">
							<div>接收人账号：{{ item.account }}</div>
							<div>接收人名称：{{ item.name }}</div>
						</div>
					</div>
				</div>
				<div class="btn-box">
					<div class="btn-item cancel" @click="insideSwitch.remove = false">取消</div>
					<div class="btn-item" @click="removeTrueClick">确定</div>
				</div>
				<!-- 确定解除共享信息警告 -->
				<div class="Information" :class="{ 'Information-show': Information }">
					<div class="top">确定解除共享吗？</div>
					<div class="bottom">
						<div @click="Information = false">取消</div>
						<div class="line"></div>
						<div class="green" @click="InformationTrueClick">确定</div>
					</div>
				</div>
			</el-drawer>
			<!-- 产品信息 -->
			<el-drawer
				title="产品信息"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.proInfo"
				custom-class="insideDrawe flcu-box"
			>
				<div class="proinfo-list">
					<div class="proinfo-item">
						<div class="item-l">产品SN</div>
						<div class="item-r">{{ proInfoObj.sn }}</div>
					</div>
					<div class="proinfo-item">
						<div class="item-l">产品MAC</div>
						<div class="item-r">{{ proInfoObj.mac }}</div>
					</div>
					<div class="proinfo-item">
						<div class="item-l">硬件版本</div>
						<div class="item-r">{{ proInfoObj.fVersion }}</div>
					</div>
					<div class="proinfo-item">
						<div class="item-l">ICCID</div>
						<div class="item-r">{{ proInfoObj.iccid }}</div>
					</div>
					<div class="proinfo-item">
						<div class="item-l">IMEI</div>
						<div class="item-r">{{ proInfoObj.imei }}</div>
					</div>
				</div>
			</el-drawer>
			<!-- 采集间隔 -->
			<el-drawer
				title="采集间隔"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.collect"
				custom-class="insideDrawe flcu-box"
				@close="collectDrawerClose"
			>
				<!-- 顶部盒子 -->
				<div class="drawe-top">
					<div class="img-box">
						<img v-if="deviceInfo.productType == '1'" src="@/assets/image/equipment/icon_ythkzx.png" alt="" />
						<img v-if="deviceInfo.productType == '2'" src="@/assets/image/equipment/icon_qxz.png" alt="" />
						<img v-if="deviceInfo.productType == '3'" src="@/assets/image/equipment/icon_sqz.png" alt="" />
						<img v-if="deviceInfo.productType == '4'" src="@/assets/image/equipment/icon_scd.png" alt="" />
						<img v-if="deviceInfo.productType == '5'" src="@/assets/image/equipment/icon_cq.png" alt="" />
						<img v-if="deviceInfo.productType == '6'" src="@/assets/image/equipment/icon_znzd.png" alt="" />
					</div>
					<div class="txt-box">
						<div class="tb-li doble">{{ deviceInfo.deviceName }}</div>
						<div class="tb-li">
							{{ deviceInfo.areaName }}
							<div class="line"></div>
							<span class="green" v-if="deviceInfo.deviceStatus == 'online'">在线</span>
							<span class="red" v-if="deviceInfo.deviceStatus == 'offline'">离线</span>
						</div>
						<div class="tb-li">
							产品型号：<span class="blue">{{ deviceInfo.productId }}</span>
						</div>
						<div class="tb-li">
							产品编号：<span class="blue">{{ deviceInfo.deviceNum }}</span>
						</div>
					</div>
				</div>
				<div class="prompt-title">请设置采集间隔（最少5分钟）</div>
				<div class="saach-item">
					<el-input
						v-model="collectTime"
						placeholder="请输入分钟数"
						type="number"
						oninput="if(value.length>3)value=value.slice(0,3)"
					>
						<div slot="suffix" class="seach-icon">分钟</div>
					</el-input>
				</div>
				<!-- 确定按钮 -->
				<div class="btn-box">
					<div class="btn-item" @click="collectTrueClick">确定</div>
				</div>
				<Msg ref="tsmsg2"></Msg>
			</el-drawer>
			<!-- 告警间隔 -->
			<el-drawer
				title="告警间隔"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.alarm"
				custom-class="insideDrawe flcu-box"
				@close="alarmDrawerClose"
			>
				<!-- 顶部盒子 -->
				<div class="drawe-top">
					<div class="img-box">
						<img v-if="deviceInfo.productType == '1'" src="@/assets/image/equipment/icon_ythkzx.png" alt="" />
						<img v-if="deviceInfo.productType == '2'" src="@/assets/image/equipment/icon_qxz.png" alt="" />
						<img v-if="deviceInfo.productType == '3'" src="@/assets/image/equipment/icon_sqz.png" alt="" />
						<img v-if="deviceInfo.productType == '4'" src="@/assets/image/equipment/icon_scd.png" alt="" />
						<img v-if="deviceInfo.productType == '5'" src="@/assets/image/equipment/icon_cq.png" alt="" />
						<img v-if="deviceInfo.productType == '6'" src="@/assets/image/equipment/icon_znzd.png" alt="" />
					</div>
					<div class="txt-box">
						<div class="tb-li doble">{{ deviceInfo.deviceName }}</div>
						<div class="tb-li">
							{{ deviceInfo.areaName }}
							<div class="line"></div>
							<span class="green" v-if="deviceInfo.deviceStatus == 'online'">在线</span>
							<span class="red" v-if="deviceInfo.deviceStatus == 'offline'">离线</span>
						</div>
						<div class="tb-li">
							产品型号：<span class="blue">{{ deviceInfo.productId }}</span>
						</div>
						<div class="tb-li">
							产品编号：<span class="blue">{{ deviceInfo.deviceNum }}</span>
						</div>
					</div>
				</div>
				<div class="prompt-title">请设置告警间隔（最少5分钟）</div>
				<div class="saach-item">
					<el-input
						v-model="alarmTime"
						placeholder="请输入分钟数"
						type="number"
						oninput="if(value.length>3)value=value.slice(0,3)"
					>
						<div slot="suffix" class="seach-icon">分钟</div>
					</el-input>
				</div>
				<!-- 确定按钮 -->
				<div class="btn-box">
					<div class="btn-item" @click="alarmTrueClick">确定</div>
				</div>
				<Msg ref="tsmsg2"></Msg>
			</el-drawer>
			<!-- 固件更新 -->
			<el-drawer
				title="固件更新"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.update"
				custom-class="insideDrawe flcu-box"
				:before-close="updateDrawerbClose"
			>
				<!-- :wrapperClosable="!updateObj.isNowUpdate && updateObj.status != 'updating'" -->
				<div class="update-box">
					<div class="img">
						<div class="num">V{{ updateObj.versionNow }}</div>
						<div class="icon" v-if="updateObj.upgrade"></div>
					</div>
					<div class="txt" v-if="updateObj.upgrade && updateObj.status !== 'updating'">发现<span>新版本</span></div>
					<div class="txt" v-if="!updateObj.upgrade && updateObj.status !== 'updating'">
						当前版本为<span>最新版本</span>
					</div>
					<div class="txt" v-if="updateObj.isNowUpdate || updateObj.status == 'updating'">固件正在升级，请稍等...</div>
					<div class="btn-div" v-if="updateObj.upgrade && updateObj.status !== 'updating'" @click="updateTrueClick">
						升级
					</div>
				</div>
				<Msg ref="tsmsg2"></Msg>
			</el-drawer>
			<Msg ref="tsmsg"></Msg>
		</el-drawer>
	</div>
</template>

<script>
import Msg from '@/components/msg.vue' //信息提示组件
import { mapState } from 'vuex'
export default {
	components: { Msg },
	props: { isAlertShow: {} },
	computed: {
		// 行程设备时长自动上报
		...mapState(['socketOnline']),
	},
	watch: {
		socketOnline: {
			handler(newVal) {
				console.log(newVal)
				if (newVal.data) {
					if (this.drawer && this.insideSwitch.update && newVal.data.deviceNum == this.deviceInfo.deviceNum) {
						// 固件正在升级
						if (newVal.data.deviceBizStatus == 'updating') {
							console.log('正在升级')
							this.updateObj.isNowUpdate = true
							this.updateObj.status == 'updating'
							this.getDeviceVersion()
						}
						// 升级完成
						if (newVal.data.deviceBizStatus == 'normal') {
							this.updateObj.status == 'normal'
							this.getDeviceVersion()
							this.$refs.tsmsg2.msgShow('升级成功', 1.5)
						}
						// 升级失败
						if (newVal.data.deviceBizStatus == 'notUpdate') {
							this.updateObj.status == 'notUpdate'
							this.getDeviceVersion()
							this.$refs.tsmsg2.msgShow('升级失败', 1.5)
						}
					}
				}
			},
			deep: true,
			immediate: true,
		},
	},
	data() {
		return {
			// 控制抽屉的开关
			drawer: false,
			// 详情信息
			deviceInfo: {},
			// 控制里面抽屉的开关
			insideSwitch: {
				unbundling: false, //设备解绑
				execution: false, //执行记录
				executionInfo: false, //执行记录详情
				shared: false, //共享设备
				remove: false, //解除共享
				proInfo: false, //产品信息
				collect: false, //采集间隔
				alarm: false, //告警间隔
				update: false, //固件更新
			},
			//查询执行记录时的子设备
			seachjiluLIst: [
				{
					value: '选项1',
					label: '黄金糕',
				},
				{
					value: '选项2',
					label: '双皮奶',
				},
				{
					value: '选项3',
					label: '蚵仔煎',
				},
				{
					value: '选项4',
					label: '龙须面',
				},
				{
					value: '选项5',
					label: '北京烤鸭',
				},
			],
			// 查询条件
			zxjlSeachForm: {
				shebei: '',
				time: '',
			},
			// 执行记录列表数组
			performList: [
				{
					name: '放风机01号',
					area: '某某区域',
					time: '2022-05-12 15:43:24',
					source: 'App',
					content:
						'执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容',
				},
				{
					name: '放风机01号',
					area: '某某区域',
					time: '2022-05-12 15:43:24',
					source: 'App',
					content:
						'执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容',
				},
				{
					name: '放风机01号',
					area: '某某区域',
					time: '2022-05-12 15:43:24',
					source: 'App',
					content:
						'执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容',
				},
				{
					name: '放风机01号',
					area: '某某区域',
					time: '2022-05-12 15:43:24',
					source: 'App',
					content:
						'执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容',
				},
			],
			// 执行记录详情对象
			performInfoObj: {
				name: '控制箱一号-放风机01号',
				area: '某某区域',
				time: '2022-05-12 15:43:24',
				source: 'App',
				cpType: '控制箱设备',
				cpName: '控制箱一号',
				cpNum: '12313454645478748',
				ywType: '灾害模式',
				czNumber: '4564654645656',
				address: '河南省焦作市山阳区',
				performState: '成功',
				performconditions:
					'执行条件执行条件执行条件，执行条件执行条件执行条件执行条件执行条件，执行条件执行条件，执行条件执行条件执行条件执行条件执行条件，执行条。',
				content:
					'执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容',
			},
			// 共享设备查询账号信息
			sharedInput: '',
			// 共享设备查询列表是否选中
			sharedSeachCheck: false,
			// 用户列表
			userList: [
				{
					phone: '4564654654',
					isCheck: false,
				},
				{
					phone: '4564654654',
					isCheck: false,
				},
				{
					phone: '4564654654',
					isCheck: false,
				},
			],
			// 解除共享时按钮是否全选
			allchecked: false,
			// 解除共享时数组
			removelist: [
				{
					time: '2022-11-22 10:10:10',
					account: '123454645465464',
					name: '某某某名称',
					isCheckd: false,
				},
				{
					time: '2022-11-22 10:10:10',
					account: '123454645465464',
					name: '某某某名称',
					isCheckd: false,
				},
				{
					time: '2022-11-22 10:10:10',
					account: '123454645465464',
					name: '某某某名称',
					isCheckd: false,
				},
			],
			// 控制确定解除共享弹窗
			Information: false,
			// 产品信息
			proInfoObj: {
				sn: '--',
				mac: '--',
				fVersion: '--',
				iccid: '--',
				imei: '--',
			},
			//采集间隔输入框
			collectTime: '',
			// 告警时间输入框
			alarmTime: '',
			// 固件升级
			updateObj: {
				deviceNum: '--',
				programIdNow: '--',
				programIdNew: '--',
				versionNow: '--',
				versionNew: '--',
				upgrade: null,
				programMark: '--',
				mandatory: null,
				status: '--',
			},
		}
	},
	methods: {
		// 抽屉关闭
		drawerClose() {
			this.drawer = false
		},
		// 菜单点击
		menuClick(index) {
			// 设备解绑
			if (index == 0) {
				this.insideSwitch.unbundling = true
			}
			// 执行记录
			if (index == 1) {
				this.insideSwitch.execution = true
			}
			// 关联设备
			if (index == 2) {
				this.$refs.tsmsg.msgShow('功能开发中', 1.5)
				return
				this.insideSwitch.shared = true
			}
			// 解除共享
			if (index == 3) {
				this.$refs.tsmsg.msgShow('功能开发中', 1.5)
				return
				this.insideSwitch.remove = true
			}
			// 产品信息
			if (index == 4) {
				this.getDeviceMac()
				this.insideSwitch.proInfo = true
			}
			// 采集间隔
			if (index == 5) {
				this.querySleepTime()
				this.insideSwitch.collect = true
			}
			// 告警间隔
			if (index == 6) {
				this.queryAlarmTime()
				this.insideSwitch.alarm = true
			}
			// 固件更新
			if (index == 7) {
				this.insideSwitch.update = true
			}
		},
		// 设备解绑点击确定
		unbundlingClick() {
			// this.insideSwitch.unbundling = false;
			// this.$refs.tsmsg.msgShow("解绑成功", 1.5);
			this.$http.postAction(this.$api.unbindDevice + '?deviceId=' + this.deviceInfo.deviceId).then((res) => {
				if (res.code == 200) {
					this.insideSwitch.unbundling = false
					this.drawer = false
					this.$refs.tsmsg.msgShow('解绑成功', 1.5)
					this.$bus.$emit('getMyDevice')
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
				}
			})
		},
		// 执行记录列表点击
		performItemClick(index) {
			this.insideSwitch.executionInfo = true
		},
		// 搜索用户点击
		seachUserClick() {
			this.sharedSeachCheck = !this.sharedSeachCheck
		},
		// 用户列表项点击
		userClick(index) {
			this.userList[index].isCheck = !this.userList[index].isCheck
		},
		// 共享确定点击
		sharedBtnClick() {
			this.insideSwitch.shared = false
			this.sharedSeachCheck = false
			this.$refs.tsmsg.msgShow('共享成功', 1.5)
		},
		// 解除共享全选按钮状态改变
		allcheckedChange() {
			if (this.allchecked) {
				this.removelist.forEach((item, index) => {
					item.isCheckd = true
				})
			} else {
				this.removelist.forEach((item, index) => {
					item.isCheckd = false
				})
			}
		},
		// 单个选择框点击
		removeCheckChange(item, index) {
			let arr = this.removelist.filter((item, index) => {
				if (!item.isCheckd) {
					return item
				}
			})
			if (!arr.length) {
				this.allchecked = true
			} else {
				this.allchecked = false
			}
		},
		// 解除共享确定点击
		removeTrueClick() {
			this.Information = true
		},
		// 弹窗信息确定点击
		InformationTrueClick() {
			this.Information = false
			this.insideSwitch.remove = false
			this.$refs.tsmsg.msgShow('解除共享成功', 1.5)
		},
		// 采集时间确认按钮点击
		collectTrueClick() {
			// if (this.collectTime >= 5) {
			//   this.setSleepTime();
			// } else {
			//   this.$refs.tsmsg2.msgShow("请输入大于等于5的数字", 1.5);
			// }
			const regex = /^[5-9]\d*$|^[1-9]\d+\d*$/
			let bool = regex.test(this.collectTime)
			if (bool) {
				this.setSleepTime()
			} else {
				this.$refs.tsmsg2.msgShow('请输入大于等于5的整数', 1.5)
			}
		},
		// 采集时间抽屉关闭
		collectDrawerClose() {
			this.collectTime = ''
		},
		// 告警时间抽屉关闭
		alarmDrawerClose() {
			this.alarmTime = ''
		},
		isPositiveInteger(number) {
			// 使用Number.isInteger()函数判断是否为整数
			// 使用大于符号（>）判断是否大于0
			if (Number.isInteger(number) && number > 0) {
				return true // 是大于的整数
			} else {
				return false // 不是大于的整数
			}
		},
		// 告警时间抽屉点击
		alarmTrueClick() {
			if (this.alarmTime >= 5) {
				if (this.isPositiveInteger(Number(this.alarmTime))) {
					this.setAlarmTime()
				} else {
					this.$refs.tsmsg2.msgShow('请输入大于等于5的整数', 1.5)
				}
			} else {
				this.$refs.tsmsg2.msgShow('请输入大于等于5的数字', 1.5)
			}
			// const regex = /^[5-9]\d*$|^[1-9]\d+\d*$/
			// let bool = regex.test(this.collectTime)
			// if (bool) {
			// 	this.setAlarmTime()
			// } else {
			// 	this.$refs.tsmsg2.msgShow('请输入大于等于5的整数', 1.5)
			// }
		},
		// 点击升级按钮
		updateTrueClick() {
			this.updateObj.isNowUpdate = true
			// setTimeout(() => {
			//   this.$refs.tsmsg2.msgShow("升级成功", 1.5);
			//   this.updateObj.isNowUpdate = false;
			//   this.updateObj.ishaveNew = false;
			// }, 1000);
			this.equipmentUpgrade()
		},
		/**
		 * 数据请求
		 * **/
		// 获取设备信息MAC信息等
		getDeviceMac() {
			this.$http.getAction(this.$api.getDeviceMac + '?deviceNum=' + this.deviceInfo.deviceNum).then((res) => {
				// console.log(res);
				if (res.data) {
					this.proInfoObj = res.data
				} else {
					this.proInfoObj = {
						sn: '--',
						mac: '--',
						fVersion: '--',
						iccid: '--',
						imei: '--',
					}
				}
			})
		},
		//获取设备固件版本信息
		getDeviceVersion() {
			this.updateObj = {
				deviceNum: '--',
				programIdNow: '--',
				programIdNew: '--',
				versionNow: '--',
				versionNew: '--',
				upgrade: null,
				programMark: '--',
				mandatory: null,
				status: '--',
				isNowUpdate: false, //是否正在升级
			}
			this.$http.getAction(this.$api.getDeviceVersion + '?deviceId=' + this.deviceInfo.deviceId).then((res) => {
				if (res.data) {
					this.updateObj = res.data
				} else {
					this.updateObj = {
						deviceNum: '--',
						programIdNow: '--',
						programIdNew: '--',
						versionNow: '--',
						versionNew: '--',
						upgrade: null,
						programMark: '--',
						mandatory: null,
						status: '--',
					}
				}
			})
		},
		// 设备升级
		equipmentUpgrade() {
			this.$http
				.postAction(
					this.$api.equipmentUpgrade +
						'?deviceId=' +
						this.deviceInfo.deviceId +
						'&programId=' +
						this.updateObj.programIdNew
				)
				.then((res) => {
					console.log(res)
					if (res.code == '200') {
						this.getDeviceVersion()
					} else {
						this.updateObj.isNowUpdate = false
					}
				})
		},
		// 固件升级抽屉关闭
		updateDrawerbClose(done) {
			console.log(this.updateObj)
			if (this.updateObj.isNowUpdate || this.updateObj.status == 'updating') {
				this.$refs.tsmsg2.msgShow('固件正在升级，请稍等...', 1.5)
			} else {
				done()
				this.insideSwitch.update = false
			}
		},
		//查询休眠间隔时间(智能终端为采集时间)
		querySleepTime() {
			this.$http.getAction(this.$api.querySleepTime + '?deviceNum=' + this.deviceInfo.deviceNum).then((res) => {
				// console.log(res);
				if (res.data) {
					if (res.data.sleepTime) {
						this.collectTime = res.data.sleepTime / 60
					} else {
						this.collectTime = res.data.sleepTimeDefault / 60
					}
				} else {
					this.collectTime = ''
				}
			})
		},
		//设置休眠时间(智能终端为采集时间)
		setSleepTime() {
			let pamas = {
				deviceNum: this.deviceInfo.deviceNum,
				sleepTime: this.collectTime * 60,
				// sleepOn: '1',
			}
			this.$http.postAction(this.$api.setSleepTime, pamas).then((res) => {
				console.log(res)
				if (res.code == '200') {
					this.insideSwitch.collect = false
					this.$refs.tsmsg.msgShow('设置成功', 1.5)
				} else {
					this.insideSwitch.collect = false
					this.$refs.tsmsg.msgShow('设置失败', 1.5)
				}
			})
		},
		// 查询告警间隔
		queryAlarmTime() {
			this.$http.getAction(this.$api.queryAlarmTime + '?deviceNum=' + this.deviceInfo.deviceNum).then((res) => {
				// console.log(res);
				if (res.data) {
					if (res.data.alarmSpan) {
						this.alarmTime = (res.data.alarmSpan - 0) / 60
					} else {
						this.alarmTime = (res.data.alarmSpanDefault - 0) / 60
					}
				} else {
					this.alarmTime = ''
				}
			})
		},
		// 设置告警时间
		setAlarmTime() {
			let pamas = {
				deviceNum: this.deviceInfo.deviceNum,
				alarmSpan: (this.alarmTime - 0) * 60,
			}
			this.$http.postAction(this.$api.setAlarmTime, pamas).then((res) => {
				console.log(res)
				if (res.code == '200') {
					this.insideSwitch.alarm = false
					this.$refs.tsmsg.msgShow('设置成功', 1.5)
				} else {
					this.insideSwitch.alarm = false
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.deviceDetails {
	/deep/ .deviceDrawer {
		border-radius: 0.225rem 0px 0px 0.225rem;
		.el-drawer__header {
			height: 0.825rem;
			box-sizing: border-box;
			text-align: left;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #454545;
			padding-top: 0;
			border-bottom: 1px solid #f1f1f1;
			span {
				font-size: 0.225rem;
			}
		}
		.el-drawer__close-btn {
			color: #454545;
		}
		.el-drawer__body {
			padding: 0 0.3rem 0.25rem;
			display: flex;
			flex-direction: column;
			.drawe-top {
				display: flex;
				padding-bottom: 0.275rem;
				border-bottom: 1px solid rgba(241, 241, 241, 1);
				.img-box {
					width: 1.5rem;
					height: 1.5rem;
					background-color: rgba(45, 174, 169, 0.18);
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					img {
						width: 62.5%;
						height: 62.5%;
					}
				}
				.txt-box {
					margin-left: 0.225rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.tb-li {
						display: flex;
						font-size: 0.2rem;
						.line {
							width: 0.0125rem;
							height: 0.2rem;
							background: #bfbfbf;
							margin: 0 0.2rem;
						}
					}
					.doble {
						font-weight: bold;
					}
					.green {
						color: rgba(73, 201, 136, 1);
					}
					.red {
						color: rgba(252, 120, 76, 1);
					}
					.blue {
						color: rgba(45, 174, 169, 1);
					}
				}
			}
			.menu-box {
				margin-top: 0.3rem;
				.menu-li {
					cursor: pointer;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 0.2rem;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #454545;
					line-height: 0.2625rem;
					margin-bottom: 0.3rem;
					.blue {
						font-size: 0.175rem;
						color: rgba(45, 174, 169, 1);
						margin: 0 0.175rem;
					}
					&::after {
						content: '';
						width: 0.1125rem;
						height: 0.2rem;
						background: url('../../../assets/image/equipment/icon-open.png') no-repeat;
						background-size: 100% 100%;
					}
				}
				.b-line {
					height: 1px;
					background: #f1f1f1;
					margin-bottom: 0.3125rem;
				}
			}
		}
	}
}
/deep/ .gxsbDrawe {
	.toptxt-div {
		display: flex;
		margin-bottom: 0.3rem;
		img {
			margin-right: 0.2rem;
			width: 0.175rem;
			height: 0.175rem;
			margin-top: 0.025rem;
		}
		.txt-div {
			font-size: 0.175rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #2daea9;
			line-height: 0.25rem;
		}
	}
	.title-div {
		margin-bottom: 0.2rem;
		span {
			font-size: 0.175rem;
		}
		.green {
			color: #2daea9;
		}
	}
	.input-div {
		height: 0.45rem;
		.el-date-editor--datetimerange.el-input,
		.el-date-editor--datetimerange.el-input__inner {
			width: 100%;
			height: 100%;
			line-height: 0.45rem;
		}
		.el-date-editor .el-range__icon {
			line-height: 0.35rem;
		}
		.el-date-editor .el-range__close-icon {
			line-height: 0.35rem;
		}
		.el-input__suffix-inner {
			width: 0.2rem;
			height: 100%;
			display: block;
			box-sizing: border-box;
			padding-top: 0.125rem;
			margin-right: 0.25rem;
			.seach-icon {
				position: relative;
				cursor: pointer;
				width: 0.2rem;
				height: 0.2rem;
				background: url('../../../assets/image/equipment/icon-seach.png') no-repeat;
				&::before {
					position: absolute;
					top: 50%;
					left: -0.25rem;
					transform: translateY(-50%);
					content: '';
					width: 1px;
					height: 22px;
					background: #f1f1f1;
				}
			}
		}
	}
	.user-list {
		margin-top: 0.3rem;
		height: 73%;
		// overflow: auto;
		.user-item {
			cursor: pointer;
			position: relative;
			height: 1.05rem;
			background: #ffffff;
			border: 1px solid #f1f1f1;
			box-shadow: 2px 2px 6px 0px rgba(44, 44, 45, 0.1), -2px -2px 6px 0px rgba(44, 44, 45, 0.1);
			border-radius: 4px;
			margin-bottom: 0.3rem;
			display: flex;
			align-items: center;
			box-sizing: border-box;
			border: 0.0125rem solid transparent;
			&:last-child {
				margin-bottom: 0;
			}
			.r-l {
				margin-left: 0.25rem;
				width: 0.8rem;
				height: 0.8rem;
				border-radius: 50%;
				background: gray;
			}
			.r-r {
				margin-left: 0.2rem;
				.name {
					margin-bottom: 0.2rem;
				}
			}
			.check {
				display: none;
				position: absolute;
				right: 0;
				bottom: 0;
				width: 0;
				right: 0;
				border: 17px solid transparent;
				border-bottom-color: rgba(45, 174, 169, 1);
				border-right-color: rgba(45, 174, 169, 1);
				.el-icon-check {
					position: absolute;
					top: 0;
					left: 0;
					color: white;
				}
			}
		}
		.user-item-act {
			border-color: #2daea9;
			background: white;
			.check {
				display: block;
			}
		}
	}
}
/deep/ .insideDrawe {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		span {
			font-size: 0.225rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.flcu-box {
		display: flex;
		flex-direction: column;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		display: flex;
		flex-direction: column;
		position: relative;
		.drawe-top {
			display: flex;
			padding-bottom: 0.275rem;
			border-bottom: 1px solid rgba(241, 241, 241, 1);
			.img-box {
				width: 1.5rem;
				height: 1.5rem;
				background-color: rgba(45, 174, 169, 0.18);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 62.5%;
					height: 62.5%;
				}
			}
			.txt-box {
				margin-left: 0.225rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.tb-li {
					display: flex;
					font-size: 0.2rem;
					.line {
						width: 0.0125rem;
						height: 0.2rem;
						background: #bfbfbf;
						margin: 0 0.2rem;
					}
				}
				.doble {
					font-weight: bold;
				}
				.green {
					color: rgba(73, 201, 136, 1);
				}
				.blue {
					color: rgba(45, 174, 169, 1);
				}
			}
		}
		.icon-box {
			flex: 1;
			box-sizing: border-box;
			padding-top: 36%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.img {
				display: flex;
				flex-direction: column;
				align-items: center;
				img {
					width: 2.925rem;
					height: 2.1625rem;
				}
				// &::after {
				// 	margin-top: 0.4875rem;
				// 	content: '确定解除设备绑定吗？';
				// 	font-size: 0.2remx;
				// 	font-family: Microsoft YaHei;
				// 	font-weight: 400;
				// 	color: #454545;
				// }
				.img_txt {
					margin-top: 0.4875rem;
					font-size: 0.2remx;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #454545;
				}
			}
			.btn-box {
				display: flex;
				justify-content: space-around;
				.btn-item {
					cursor: pointer;
					width: 1.75rem;
					height: 0.45rem;
					background: #ffffff;
					border: 1px solid #2daea9;
					border-radius: 0.05rem;
					box-sizing: border-box;
					font-size: 0.175rem;
					text-align: center;
					line-height: 0.45rem;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #2daea9;
				}
				.quding-item {
					background: #2daea9;
					color: #feffff;
				}
			}
		}
		.search-box {
			min-height: 1.8875rem;
			background: #ffffff;
			border: 1px solid #f1f1f1;
			border-radius: 0.05rem;
			box-sizing: border-box;
			padding: 0.2rem 0.3rem;
			font-size: 0.2rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #454545;
			.saach-item {
				height: 0.45rem;
				margin-top: 0.2rem;
				.el-select {
					width: 100%;
					height: 100%;
				}
				.el-date-editor--datetimerange.el-input,
				.el-date-editor--datetimerange.el-input__inner {
					width: 100%;
					height: 100%;
					line-height: 0.45rem;
				}
				.el-date-editor .el-range__icon {
					line-height: 0.35rem;
				}
				.el-date-editor .el-range__close-icon {
					line-height: 0.35rem;
				}
				.el-input__suffix-inner {
					width: 0.2rem;
					height: 100%;
					display: block;
					box-sizing: border-box;
					padding-top: 0.125rem;
					margin-right: 0.25rem;
					.seach-icon {
						position: relative;
						cursor: pointer;
						width: 0.2rem;
						height: 0.2rem;
						background: url('../../../assets/image/equipment/icon-seach.png') no-repeat;
						&::before {
							position: absolute;
							top: 50%;
							left: -0.25rem;
							transform: translateY(-50%);
							content: '';
							width: 1px;
							height: 22px;
							background: #f1f1f1;
						}
					}
				}
			}
			.results-box {
				position: relative;
				cursor: pointer;
				margin-top: 0.2rem;
				height: 1.05rem;
				background: #f1f9fa;
				border-radius: 4px;
				display: flex;
				align-items: center;
				box-sizing: border-box;
				border: 0.0125rem solid transparent;
				.r-l {
					margin-left: 0.25rem;
					width: 0.8rem;
					height: 0.8rem;
					border-radius: 50%;
					background: gray;
				}
				.r-r {
					margin-left: 0.2rem;
					.name {
						margin-bottom: 0.2rem;
					}
				}
				.check {
					display: none;
					position: absolute;
					right: 0;
					bottom: 0;
					width: 0;
					right: 0;
					border: 17px solid transparent;
					border-bottom-color: rgba(45, 174, 169, 1);
					border-right-color: rgba(45, 174, 169, 1);
					.el-icon-check {
						position: absolute;
						top: 0;
						left: 0;
						color: white;
					}
				}
			}
			.results-box-act {
				border-color: #2daea9;
				background: white;
				.check {
					display: block;
				}
			}
			.warm-box {
				.no-seach {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 0.25rem;
					.icon {
						width: 0.25rem;
						height: 0.25rem;
						background: url('../../../assets/image/equipment/icon-noseach.png') no-repeat;
						background-size: 100% 100%;
					}
					.txt {
						color: #fc784c;
						margin-left: 0.1375rem;
					}
				}
			}
		}
		.tishi-box {
			width: 5.4rem;
			height: 1rem;
			background: url('../../../assets/image/equipment/icon-warimh.png') no-repeat;
			background-size: 100% 100%;
			box-sizing: border-box;
			padding-top: 0.3625rem;
			padding-left: 0.2125rem;
			padding-right: 0.2125rem;
			color: #fc784c;
			line-height: 0.25rem;
			opacity: 0;
		}
		.op1 {
			opacity: 1;
		}
		.seach-list {
			height: 7.925rem;
			overflow: auto;
			margin-top: 0.25rem;
			.list-item {
				cursor: pointer;
				height: 2.475rem;
				border-radius: 0.175rem;
				background: rgba(241, 249, 250, 1);
				margin-bottom: 0.25rem;
				box-sizing: border-box;
				padding: 0.125rem;
				.list-item-top {
					box-sizing: border-box;
					padding: 0.125rem;
					height: 0.575rem;
					border-bottom: 1px solid rgba(255, 255, 255, 1);
					display: flex;
					justify-content: space-between;
					font-size: 0.2rem;
					font-family: Microsoft YaHei;
					font-weight: bold;
					.r-div {
						color: #2daea9;
						img {
							width: 0.2rem;
							height: 0.2rem;
							margin-right: 0.075rem;
						}
					}
				}
				.list-item-bot {
					box-sizing: border-box;
					height: 1.75rem;
					padding: 0.225rem 0.1875rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.lib-div {
						font-size: 0.175rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						i {
							display: inline-block;
							font-style: normal;
							width: 0.75rem;
						}
						span {
							color: #333333;
							margin-left: 0.1875rem;
						}
					}
				}
			}
		}
		.performInfo {
			.info-item {
				display: flex;
				margin-bottom: 0.2875rem;
				.title {
					width: 1rem;
					color: #999999;
					line-height: 1.5;
				}
				.cont {
					width: 4.375rem;
					margin-left: 0.2rem;
					line-height: 1.5;
				}
			}
		}
		.btn-box {
			flex: 1;
			// position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			.btn-item {
				box-sizing: border-box;
				border: 0.0125rem solid #2daea9;
				cursor: pointer;
				width: 1.75rem;
				height: 0.45rem;
				background: #2daea9;
				border-radius: 0.05rem;
				text-align: center;
				line-height: 0.45rem;
				color: white;
			}
			.cancel {
				background: #fff;
				color: #2daea9;
			}
		}
		.remove-list {
			margin-top: 0.25rem;
			height: 8.7rem;
			overflow: auto;
			.remove-item {
				box-sizing: border-box;
				height: 1.95rem;
				background: #f1f9fa;
				border-radius: 0.125rem;
				padding: 0.125rem;
				margin-bottom: 0.3rem;
				.item-header {
					box-sizing: border-box;
					height: 0.65rem;
					border-bottom: 2px solid #fff;
					padding-left: 0.175rem;
					padding-right: 0.175rem;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.h-l {
					}
				}
				.item-bot {
					height: 1.15rem;
					padding: 0.25rem 0.175rem 0.3125rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}
			}
		}
		.proinfo-list {
			.proinfo-item {
				display: flex;
				justify-content: space-between;
				padding-bottom: 0.25rem;
				border-bottom: 1px solid #f1f1f1;
				font-size: 0.2rem;
				margin-bottom: 0.2375rem;
				.item-r {
					color: #999999;
				}
			}
		}
		.prompt-title {
			position: relative;
			margin-top: 0.2875rem;
			display: flex;
			align-items: center;
			&::before {
				margin-right: 0.3rem;
				content: '';
				width: 0.025rem;
				height: 0.2rem;
				background: #2daea9;
			}
		}
		.saach-item {
			height: 0.45rem;
			margin-top: 0.2rem;
			.el-select {
				width: 100%;
				height: 100%;
			}
			.el-date-editor--datetimerange.el-input,
			.el-date-editor--datetimerange.el-input__inner {
				width: 100%;
				height: 100%;
				line-height: 0.45rem;
			}
			.el-date-editor .el-range__icon {
				line-height: 0.35rem;
			}
			.el-date-editor .el-range__close-icon {
				line-height: 0.35rem;
			}
			.el-input__suffix-inner {
				height: 100%;
				display: block;
				box-sizing: border-box;
				padding-top: 0.175rem;
				margin-right: 0.25rem;
				.seach-icon {
					position: relative;
					cursor: pointer;
					// width: 0.2rem;
					height: 0.2rem;

					color: #333333;
					&::before {
						position: absolute;
						top: 50%;
						left: -0.25rem;
						transform: translateY(-50%);
						content: '';
						width: 1px;
						height: 22px;
						background: #f1f1f1;
					}
				}
			}
		}
		.update-box {
			flex: 1;
			display: flex;
			flex-direction: column;
			// justify-content: center;
			align-items: center;
			box-sizing: border-box;
			padding-top: 40%;
			position: relative;
			.img {
				position: relative;
				width: 2.8875rem;
				height: 2.8rem;
				background: url('../../../assets/image/equipment/shengji.png') no-repeat;
				background-size: 100% 100%;
				.num {
					font-size: 40px;
					font-family: SF Pro Display;
					font-weight: 400;
					color: #ffffff;
					text-align: center;
					line-height: 2.8rem;
				}
				.icon {
					position: absolute;
					right: 0.125rem;
					bottom: 0;
					width: 0.85rem;
					height: 0.85rem;
					background: url('../../../assets/image/equipment/shengjibanben.png') no-repeat;
				}
			}
			.btn-div {
				cursor: pointer;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 1.75rem;
				height: 36px;
				background: #2daea9;
				border-radius: 0.05rem;
				text-align: center;
				line-height: 0.45rem;
				color: white;
			}
			.txt {
				margin-top: 0.525rem;
				span {
					color: rgba(45, 174, 169, 1);
				}
			}
		}
	}
	.Information {
		position: absolute;
		z-index: 999999;
		top: 40%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) scale(0);
		width: 3.45rem;
		height: 2.075rem;
		background: #ffffff;
		box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		transition: all 0.3s;
		.top {
			box-sizing: border-box;
			height: 1.375rem;
			text-align: center;
			line-height: 1.375rem;
			border-bottom: 1px solid #f1f1f1;
		}
		.bottom {
			display: flex;
			height: 0.6875rem;
			justify-content: space-around;
			align-items: center;
			& > div {
				cursor: pointer;
			}
			.line {
				width: 1px;
				height: 0.45rem;
				background: #f1f1f1;
			}
			.green {
				color: rgba(45, 174, 169, 1);
			}
		}
	}
	.Information-show {
		transform: translateX(-50%) translateY(-50%) scale(1);
	}
}
</style>
