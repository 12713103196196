<template>
	<div class="equipmentBox flex">
		<!-- 左边 -->
		<div class="left">
			<div class="header-box">
				<div :class="{ 'act-div': tabIndex == 0 }" :style="{ marginRight: 32 + 'px' }">
					<span @click="tabClick(0)">我的产品</span>
				</div>
				<div :class="{ 'act-div': tabIndex == 1 }">
					<span @click="tabClick(1)">他人共享的产品</span>
				</div>
			</div>
			<div class="l-cont-box">
				<div class="item all">
					<img src="../../assets/image/equipment/all-icon.png" alt="" />
					<div class="txt-box">
						<div class="name">产品总数</div>
						<div class="num">{{ sbNumList.all }}</div>
					</div>
				</div>
				<div class="item online">
					<img src="../../assets/image/equipment/online-icon.png" alt="" />
					<div class="txt-box">
						<div class="name">产品在线数</div>
						<div class="num">{{ sbNumList.online }}</div>
					</div>
				</div>
				<div class="item offline">
					<img src="../../assets/image/equipment/offline-icon.png" alt="" />
					<div class="txt-box">
						<div class="name">产品离线数</div>
						<div class="num">{{ sbNumList.offline }}</div>
					</div>
				</div>
			</div>
			<div class="l-btn-box" v-if="tabIndex == 0">
				<el-button class="myElBtn" :disabled="sbNumList.all == 0" @click="openInitiatingShare">发起共享</el-button>
				<el-button class="myElBtn" :disabled="sbNumList.all == 0" @click="openremoveShare">解除共享</el-button>
			</div>
		</div>
		<!-- 右边 -->
		<div class="right">
			<!-- 信息提示 -->
			<Msg ref="xgNamemsg"></Msg>
			<!-- 无设备时 -->
			<div class="nosb-box" v-if="sbNumList.all == 0">
				<div class="img">
					<img src="../../assets/image/equipment/zanwuchanpin.png" alt="" />
				</div>
				<el-button class="myElBtn" v-if="tabIndex == 0" @click="addCanpin">添加产品</el-button>
			</div>
			<!-- 有设备时 -->
			<div class="have-box" v-else>
				<div class="top-box">
					<div class="tab-box">
						<div
							class="tab-item"
							:class="{
								'tab-item-act': tabItemIndex == index,
								afterNone: index - 1 == tabItemIndex || index == tabItemIndex || index == 0,
							}"
							v-for="(item, index) in sbList"
							:key="index"
							@click.stop="tabItemClick(index, item)"
						>
							{{ item.productTypeDesc }}<span class="num">({{ item.totalNum }})</span>
							<!-- 鼠标移入显示 -->
							<div class="houver-box">
								<div
									class="txt"
									:class="{
										'txt-act': tabItemIndex == index && houverControl.index == 0,
									}"
									@click.stop="houseBoxClick(index, 0)"
								>
									未共享的设备（{{ item.online || '--' }}）
								</div>
								<div
									class="txt"
									:class="{
										'txt-act': tabItemIndex == index && houverControl.index == 1,
									}"
									@click.stop="houseBoxClick(index, 1)"
								>
									已共享的设备（{{ item.offline || '--' }}）
								</div>
							</div>
						</div>
					</div>
					<el-button v-show="tabIndex == 0" class="myElBtn2" @click="addCanpin">添加产品</el-button>
				</div>
				<!-- 线 -->
				<div class="line"></div>
				<div class="state-box">
					<div class="txt">状态：</div>
					<el-checkbox-group v-model="stateList" @change="stateChange">
						<el-checkbox label="在线"></el-checkbox>
						<el-checkbox label="离线"></el-checkbox>
					</el-checkbox-group>
				</div>
				<!-- 设备列表盒子 -->
				<div class="equipment-box" v-if="equipmentList.length">
					<div
						class="item"
						:class="{ mb0: isMaginBottom(index, equipmentList) }"
						v-for="(item, index) in equipmentList"
						:key="index"
					>
						<div class="name-box">
							<div class="pic-box">
								<img
									v-if="sbList[tabItemIndex].productType == '1'"
									src="@/assets/image/equipment/icon_ythkzx.png"
									alt=""
								/>
								<img
									v-if="sbList[tabItemIndex].productType == '2'"
									src="@/assets/image/equipment/icon_qxz.png"
									alt=""
								/>
								<img
									v-if="sbList[tabItemIndex].productType == '3'"
									src="@/assets/image/equipment/icon_sqz.png"
									alt=""
								/>
								<img
									v-if="sbList[tabItemIndex].productType == '4'"
									src="@/assets/image/equipment/icon_scd.png"
									alt=""
								/>
								<img v-if="sbList[tabItemIndex].productType == '5'" src="@/assets/image/equipment/icon_cq.png" alt="" />
								<img
									v-if="sbList[tabItemIndex].productType == '6'"
									src="@/assets/image/equipment/icon_znzd.png"
									alt=""
								/>
								<img v-if="sbList[tabItemIndex].productType == '2-6'" :src="item.productPic" alt="" />
							</div>
							<div class="name" v-show="editorObj.index !== index || editorObj.isBool == false">
								<div class="name-con">{{ item.deviceName }}</div>
								<div class="icon" v-show="tabIndex == 0" @click.stop="listeditorName(index, item.deviceName)"></div>
							</div>
							<div class="editor-name-box" v-show="editorObj.index == index && editorObj.isBool == true">
								<el-input placeholder="请输入内容" v-model="editorInput" maxlength="9">
									<i slot="suffix" class="suffix-content">
										<img src="@/assets/image/equipment/icon-quxiao.png" alt="" @click.stop="listqxeditor" />
										<img src="@/assets/image/equipment/icon-queding.png" alt="" @click.stop="listdetermineName(item)" />
									</i>
								</el-input>
							</div>
						</div>
						<div class="equ-con-box">
							<div class="equ-li">
								状态：<span v-if="item.deviceStatus == 'online'" class="green">在线</span
								><span v-else class="red">离线</span>
							</div>
							<div class="equ-li">产品区域：{{ item.areaName }}</div>
							<!-- <div class="equ-li">产品型号：{{ item.productId }}</div> -->
							<div class="equ-li">产品型号：{{ item.productName }}</div>
							<div class="equ-li">产品编号：{{ item.deviceNum }}</div>
							<div class="equ-li">
								组件清单：控制组件<span class="blue" v-if="item.controlCompCount" @click="openControlDrawer(item)">{{
									item.controlCompCount
								}}</span
								><span v-else> -- </span>传感组件
								<span class="blue" v-if="item.sensorCount" @click="openSensingComponent(item)">{{
									item.sensorCount
								}}</span
								><span v-else> -- </span>
							</div>
							<div class="gx-icon">
								<img v-if="item.shared" src="@/assets/image/equipment/yigongxiang.png" alt="" />
								<img v-else src="@/assets/image/equipment/weigongxiang.png" alt="" />
							</div>
						</div>
						<div class="equ-bottom-box">
							<div class="ebb-item" @click="gotoProductService(item)">
								<img src="@/assets/image/equipment/icon-canpinfw.png" alt="" />
								进入产品服务
							</div>
							<div class="equ-line"></div>
							<div class="ebb-item" @click="openDeviceDetails(item)">
								<img src="@/assets/image/equipment/icon-chakan.png" alt="" />
								查看设备详情
							</div>
						</div>
					</div>
				</div>
				<div class="equipment-box-no" v-else>
					<div class="img">
						<img src="@/assets/image/equipment/zanwuchanpin.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<!-- 添加产品抽屉 -->
		<AddCp ref="addcp"></AddCp>
		<!-- 设备详情抽屉 -->
		<DeviceDetails ref="deviceDetails" :isAlertShow="isAlertShow"></DeviceDetails>
		<!-- 发起共享抽屉 -->
		<InitiatingShare ref="InitiatingShare"></InitiatingShare>
		<!-- 解除共享抽屉 -->
		<RemoveShare ref="removeShare"></RemoveShare>
		<!-- 控制组件抽屉 -->
		<ControlComponent ref="ControlComponent"></ControlComponent>
		<!-- 传感组件抽屉 -->
		<SensingComponent ref="SensingComponent"></SensingComponent>
		<!-- 产品初始化弹窗 -->
		<Initialization ref="Initialization"></Initialization>
	</div>
</template>

<script>
// 添加产品抽屉
import AddCp from './components/addCP.vue'
// 设备详情抽屉
import DeviceDetails from './components/deviceDetails.vue'
// 发起共享抽屉
import InitiatingShare from './components/initiatingShare.vue'
// 解除共享抽屉
import RemoveShare from './components/removeShare.vue'
// 控制组件抽屉
import ControlComponent from './components/controlComponent.vue'
// 传感组件抽屉
import SensingComponent from './components/sensingComponent.vue'
// 产品初始化抽屉
import Initialization from './components/Initialization.vue'
import Msg from '@/components/msg.vue' //信息提示组件
export default {
	components: {
		AddCp,
		Msg,
		DeviceDetails,
		InitiatingShare,
		RemoveShare,
		ControlComponent,
		SensingComponent,
		Initialization,
	},
	data() {
		return {
			// 控制我的设备还是共享
			tabIndex: 0, //0我的设备 1共享设备
			// 设备数量
			sbNumList: {
				all: 0,
				online: 0,
				offline: 0,
			},
			// 设备类型列表
			sbList: [],
			// 当前选中的设备类型
			tabItemIndex: 0,
			// 控制悬浮窗选中类型
			houverControl: {
				index: null, // null 未选中，0 未共享 1 共享
			},
			// 状态选择
			stateList: ['在线', '离线'],
			// 设备列表(所有)
			equipmentAllList: [
				// {
				//   name: "控制箱一号",
				//   state: true,
				//   area: "大鹏一号",
				//   model: "SP123123123132",
				//   serialNum: "1231321312132",
				//   control: "8",
				//   sensor: "8",
				//   shared: true,
				// },
			],
			// 设备列表（筛选后）
			equipmentList: [
				// {
				//   name: "控制箱一号",
				//   state: true,
				//   area: "大鹏一号",
				//   model: "SP123123123132",
				//   serialNum: "1231321312132",
				//   control: "8",
				//   sensor: "8",
				//   shared: true,
				// },
			],
			// 控制修改名字
			editorObj: {
				index: 0, //修改项的下标
				isBool: false, //是否有修改
			},
			// 修改名字输入框文字
			editorInput: '',
			isAlertShow: true,
		}
	},
	mounted() {
		// window.addEventListener("click", () => {
		//   this.editorObj.isBool = false;
		// });
		this.$bus.$on('openDrwer', this.openInitialization)
		this.$bus.$on('getMyDevice', this.getMyDevice)
		// 获取我的设备列表
		this.getMyDevice()
	},
	methods: {
		// 判断是否有下编剧
		isMaginBottom(index, list) {
			let num = list.length % 3
			let start = list.length - num + 1
			if (num == 0) {
				if (index + 1 >= list.length - 3) {
					return true
				} else {
					return false
				}
			} else {
				if (index + 1 >= start) {
					return true
				} else {
					return false
				}
			}
		},
		// 我的还是共享点击切换
		tabClick(index) {
			if (index == 1) {
				this.$refs.xgNamemsg.msgShow('功能开发中', 1.5)
				return
			}
			if (this.tabIndex == index) return

			this.tabIndex = index

			// 请求设备列表数量数据
			this.getMyDevice()
		},
		// 添加产品
		addCanpin() {
			// this.isHavect = true;
			this.$refs.addcp.drawer = true
		},
		// 设备类型点击
		tabItemClick(index, item) {
			console.log(item, '----------------------------======>????????')
			if (item.productTypeDesc == '气象站') {
				this.isAlertShow = false
			} else {
				this.isAlertShow = true
			}
			this.tabItemIndex = index
			this.houverControl.index = null
			this.stateList = ['在线', '离线']
			this.equipmentAllList = this.sbList[index].list
			this.equipmentList = this.equipmentAllList
		},
		// 设备类型里的悬浮弹窗点击 typeIndex类型的下标  index未共享还是共享
		houseBoxClick(typeIndex, index) {
			this.tabItemIndex = typeIndex
			this.houverControl.index = index
		},

		// 状态复选框值改变
		stateChange() {
			console.log(this.stateList)
			if (this.stateList.length == 2) {
				this.equipmentList = this.equipmentAllList
			} else {
				if (this.stateList.length == 0) {
					this.equipmentList = []
					return
				}
				if (this.stateList[0] == '在线') {
					let newArr = []
					newArr = this.equipmentAllList.filter((item) => {
						if (item.deviceStatus == 'online') {
							return item
						}
					})
					this.equipmentList = newArr
				}
				if (this.stateList[0] == '离线') {
					let newArr = []
					newArr = this.equipmentAllList.filter((item) => {
						if (item.deviceStatus == 'offline') {
							return item
						}
					})
					this.equipmentList = newArr
				}
			}
		},
		// 列表中修改名字点击
		listeditorName(index, name) {
			this.editorObj.index = index
			this.editorObj.isBool = true
			this.editorInput = name
		},
		// 列表取消修改
		listqxeditor() {
			this.editorObj.isBool = false
		},
		// 列表确定修改
		listdetermineName(item) {
			console.log(item)
			let deviceNum = item.deviceNum
			let objRegExp = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
			if (objRegExp.test(this.editorInput)) {
				this.updateDeviceName(deviceNum, this.editorInput)
			} else {
				this.$refs.xgNamemsg.msgShow('名称只能是中文，英文，数字，下划线', 1.5)
			}
		},
		// 进入产品服务
		gotoProductService(item) {
			console.log(item)
			console.log(this.sbList)
			let type = this.sbList[this.tabItemIndex].productType
			console.log(type)

			if (type == '1') {
				this.$router.push({
					path: '/product/integration',
					query: { deviceNum: item.deviceNum },
				})
			}
			if (type == '2') {
				this.$router.push({
					path: '/product/qixiang',
					query: { deviceNum: item.deviceNum },
				})
			}
			if (type == '2-6') {
				this.$router.push({
					path: '/product/qixiang',
					query: { deviceNum: item.deviceNum },
				})
			}
			if (type == '3') {
				this.$router.push({
					path: '/product/shangq',
					query: { deviceNum: item.deviceNum },
				})
			}
			if (type == '4') {
				this.$router.push({
					path: '/product/shachong',
					query: { deviceNum: item.deviceNum },
				})
			}
			if (type == '5') {
				this.$router.push({
					path: '/product/chongq',
					query: { deviceNum: item.deviceNum },
				})
			}
			if (type == '6') {
				this.$router.push({
					path: '/product/smart',
					query: { deviceNum: item.deviceNum },
				})
			}
		},
		// 打开设备详情抽屉
		openDeviceDetails(item) {
			console.log(item)
			if (item.productName.includes('墒情') || item.productName.includes('气象')) {
				this.isAlertShow = false
			} else {
				this.isAlertShow = true
			}
			item.productType = this.sbList[this.tabItemIndex].productType
			this.$refs.deviceDetails.deviceInfo = item
			this.$refs.deviceDetails.drawer = true
			this.$refs.deviceDetails.getDeviceVersion()
		},
		// 打开发起共享抽屉
		openInitiatingShare() {
			this.$refs.xgNamemsg.msgShow('功能开发中', 1.5)
			return
			this.$refs.InitiatingShare.drawer = true
		},
		// 打开解除共享抽屉
		openremoveShare() {
			this.$refs.xgNamemsg.msgShow('功能开发中', 1.5)
			return
			this.$refs.removeShare.drawer = true
		},
		// 打开控制组件清单抽屉
		openControlDrawer(item) {
			this.$refs.ControlComponent.editorObj.isBool = false
			this.$refs.ControlComponent.deviceId = item.deviceId
			this.$refs.ControlComponent.getDeviceControl()
			this.$refs.ControlComponent.drawer = true
		},
		// 打开传感器组件清单抽屉
		openSensingComponent(item) {
			this.$refs.SensingComponent.editorObj.isBool = false
			this.$refs.SensingComponent.deviceId = item.deviceId
			this.$refs.SensingComponent.getDeviceSensor()
			this.$refs.SensingComponent.drawer = true
		},
		// 打开初始化抽屉
		openInitialization() {
			this.$refs.Initialization.drawer = true
		},

		/*
		 * @description: 数据接口获取
		 */
		// 左侧设备列表
		getMyDevice() {
			let params = {
				id: 0,
				// type: this.tabIndex,
				type: '',
			}
			this.$http.getAction(this.$api.myDpList, params).then((res) => {
				if (res.code == 200) {
					let datas = res.data || {}
					// 赋值设备数量
					this.$set(this.sbNumList, 'all', datas.totalNum | 0)
					this.$set(this.sbNumList, 'online', datas.onlineNum | 0)
					this.$set(this.sbNumList, 'offline', datas.offlineNum | 0)
					this.sbList = datas.areaList
					this.equipmentAllList = datas.areaList[this.tabItemIndex].list || []
					this.equipmentList = this.equipmentAllList
				}
			})
		},
		// 修改设备名称
		updateDeviceName(deviceNum, deviceName) {
			this.$http
				.getAction(this.$api.updateDeviceName + '?deviceNum=' + deviceNum + '&deviceName=' + deviceName)
				.then((res) => {
					if (res.code == '200') {
						this.editorObj.isBool = false
						this.$refs.xgNamemsg.msgShow('修改成功', 1.5)
						this.getMyDevice()
					} else {
						this.editorObj.isBool = false
						this.$refs.xgNamemsg.msgShow(res.msg, 1.5)
					}
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.equipmentBox {
	height: 100%;
	min-height: 610px;
	& > div {
		height: 100%;
		background: #ffffff;
		border-radius: 0.125rem;
	}
	.left {
		width: 22.3%;
		position: relative;
		margin-right: 0.3rem;
		.header-box {
			display: flex;
			height: 8%;
			box-sizing: border-box;
			border-bottom: 1px solid rgb(241, 241, 241);
			padding-left: 0.2875rem;
			& > div {
				// width: 50%;
				height: 100%;

				display: flex;
				justify-content: center;
				align-items: center;
				span {
					cursor: pointer;
					font-size: 0.225rem;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #454545;
				}
			}
			.act-div {
				span {
					color: #2daea9;
				}
			}
		}
		.l-cont-box {
			height: 92%;
			box-sizing: border-box;
			padding: 1.25vw;
			.item {
				height: 16.4%;
				background: pink;
				margin-bottom: 1.25vw;
				display: flex;
				box-sizing: border-box;
				padding: 6%;
				img {
					// width: 11.7%;
					// height: 26.6%;
					width: 0.525rem;
					height: 0.4rem;
				}
				.txt-box {
					margin-left: 0.2875rem;
					text-align: left;
					.name {
						font-size: 0.25rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #666666;
						margin-bottom: 1.8vh;
					}
					.num {
						font-size: 3.7vh;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #1182fb;
					}
				}
			}
			.all {
				background: url('../../assets/image/equipment/all-bg.png') no-repeat;
				background-size: 100% 100%;
			}
			.online {
				background: url('../../assets/image/equipment/online-bg.png') no-repeat;
				background-size: 100% 100%;
				.txt-box {
					.num {
						color: #49c988;
					}
				}
			}
			.offline {
				background: url('../../assets/image/equipment/offline-bg.png') no-repeat;
				background-size: 100% 100%;
				.txt-box {
					.num {
						color: #fc784c;
					}
				}
			}
		}
		.l-btn-box {
			width: 100%;
			position: absolute;
			bottom: 3.8%;
			display: flex;
			justify-content: space-around;
		}
	}
	.right {
		width: 76%;
		box-sizing: border-box;
		padding: 1.34%;
		padding-right: 1%;
		.nosb-box {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.img {
				width: 16.9%;
				height: 21.8%;
				margin-bottom: 0.5rem;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
		.have-box {
			width: 100%;
			height: 100%;
			.top-box {
				display: flex;
				justify-content: space-between;
				padding-right: 0.125rem;
				.tab-box {
					display: flex;
					padding: 0.05rem;
					background: #f6f6f6;
					border-radius: 0.1rem;
					.tab-item {
						position: relative;
						// padding: 0.1375rem;
						padding: 0.1375rem 0.2875rem;
						font-size: 0.2rem;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #666666;
						cursor: pointer;
						&::after {
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							content: '';
							width: 1px;
							height: 32px;
							background: #000000;
							opacity: 0.08;
						}
						&:hover .houver-box {
							display: flex;
						}
						.houver-box {
							width: 4.5625rem;
							height: 0.8875rem;
							background: url('../../assets/image/equipment/xuanzekuang.png') no-repeat;
							background-size: 100% 100%;
							position: absolute;
							top: 98%;
							left: 0;
							z-index: 10;
							display: none;
							// display: flex;
							box-sizing: border-box;
							padding-top: 0;
							padding: 0 0.375rem;
							align-items: center;
							justify-content: space-between;
							.txt {
								font-size: 0.2rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #454545;
							}
							.txt-act {
								color: #2daea9;
							}
						}
						.num {
							margin-left: 0.0625rem;
						}
					}
					.tab-item-act {
						background: #ffffff;
						border-radius: 8px;
						color: #2daea9;
					}
					.afterNone {
						&::after {
							display: none;
						}
					}
				}
			}
			.line {
				margin-top: 2.2vh;
				margin-bottom: 2.2vh;
				height: 1px;
				background: #f1f1f1;
			}
			.state-box {
				display: flex;
				align-items: center;
				.txt {
					margin-right: 0.125rem;
				}
			}
			.equipment-box {
				margin-top: 0.3rem;
				height: 80%;
				overflow: auto;
				display: flex;
				flex-wrap: wrap;
				// justify-content: space-between;
				padding-right: 0.125rem;
				&::-webkit-scrollbar {
					width: 6px;
					height: 0px;
					background-color: transparent;
				}
				&::-webkit-scrollbar-track {
					border-radius: 6px;
					background-color: transparent;
				}
				&::-webkit-scrollbar-thumb {
					border-radius: 6px;
					background-color: rgba(45, 174, 169, 0.2);
				}
				.item {
					width: 22.2vw;
					height: 14.4vw;
					background: #f1f9fa;
					border-radius: 0.125rem;
					margin-bottom: 0.3rem;
					margin-right: 0.175rem;
					.name-box {
						display: flex;
						height: 3.2vw;
						box-sizing: border-box;
						border: 1px solid #ffffff;
						align-items: center;
						.pic-box {
							width: 1.7vw;
							height: 1.7vw;
							border-radius: 50%;
							box-sizing: border-box;
							background: #ffffff;
							border: 1px solid #2daea9;
							margin-left: 0.3rem;
							margin-right: 0.15rem;
							display: flex;
							align-items: center;
							justify-content: center;
							img {
								width: 62.5%;
								height: 62.5%;
							}
						}
						.name {
							display: flex;
							align-items: center;
							.name-con {
							}
							.icon {
								margin-left: 0.125rem;
								cursor: pointer;
								width: 0.1875rem;
								height: 0.1875rem;
								background: url('../../assets/image/equipment/icon-bj.png') no-repeat;
								background-size: 100% 100%;
							}
						}
					}
					.editor-name-box {
						width: 17.3vw;
						height: 1.8vw;
					}
					.equ-con-box {
						position: relative;
						height: 8.75vw;
						box-sizing: border-box;
						padding: 0.25rem 0.3rem;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.equ-li {
							text-align: left;
							font-size: 0.175rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #454545;
							.green {
								color: rgba(73, 201, 136, 1);
							}
							.red {
								color: rgba(252, 120, 76, 1);
							}
							.blue {
								margin: 0 0.1rem;
								cursor: pointer;
								color: rgba(51, 146, 230, 1);
							}
						}
						.gx-icon {
							position: absolute;
							top: 0.3rem;
							right: 0.3rem;
							width: 0.575rem;
							height: 0.5rem;
							img {
								width: 100%;
								height: 100%;
							}
						}
					}
					.equ-bottom-box {
						height: 2.39vw;
						box-sizing: border-box;
						border-top: 1px solid #ffffff;
						padding: 0.125rem;
						display: flex;
						justify-content: space-around;
						.ebb-item {
							cursor: pointer;
							display: flex;
							font-size: 0.175rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #2daea9;
							align-items: center;
							img {
								margin-right: 0.1125rem;
							}
						}
						.equ-line {
							width: 1px;
							height: 100%;
							border: 1px solid #ffffff;
						}
					}
				}
				.item:nth-child(3n) {
					margin-right: 0;
				}
				.mb0 {
					margin-bottom: 0;
				}
			}
			.equipment-box-no {
				margin-top: 0.3rem;
				height: 80%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
</style>
<style lang="scss">
.equipmentBox {
	.l-btn-box {
		.myElBtn {
			width: 34.5%;
			background: #2daea9;
			border-color: transparent;
			color: #ffffff;
		}
		.el-button.is-disabled,
		.el-button.is-disabled:focus,
		.el-button.is-disabled:hover {
			background: #d6d6d6;
			color: #ffffff;
			border-color: #d6d6d6;
		}
	}
	.right {
		.myElBtn {
			width: 1.5rem;
			background: #2daea9;
			border-color: transparent;
			color: #ffffff;
		}
		.myElBtn2 {
			width: 1.25rem;
			height: 0.45rem;
			background: #2daea9;
			border-color: transparent;
			color: #ffffff;
			padding: 0;
		}
		// 复选框样式
		.el-checkbox__input.is-checked + .el-checkbox__label {
			color: rgba(45, 174, 169, 1);
		}
		.el-checkbox__input.is-checked .el-checkbox__inner,
		.el-checkbox__input.is-indeterminate .el-checkbox__inner {
			background-color: rgba(45, 174, 169, 1);
			border-color: rgba(45, 174, 169, 1);
		}
		.el-checkbox__inner:hover {
			border-color: rgba(45, 174, 169, 1);
		}
		.editor-name-box {
			.el-input__inner {
				border-radius: 0.1rem;
			}
			.el-input.is-active .el-input__inner,
			.el-input__inner:focus {
				border-color: rgba(45, 174, 169, 1);
			}
			.el-input__suffix {
				width: 4vw;
				height: 100%;
				.el-input__suffix-inner {
					display: block;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					.suffix-content {
						display: flex;
						align-items: center;
						width: 100%;
						height: 1.099vw;
						box-sizing: border-box;
						border-left: 1px solid #f1f1f1;
						justify-content: space-around;
						padding: 0 0.1875rem;
						img {
							cursor: pointer;
							width: 0.175rem;
							height: 0.175rem;
						}
					}
				}
			}
		}
	}
}
</style>
