/*
 * @description: 导出相关逻辑
 */
export default {
	data() {
		return {
			chooseId: '',
			/* 分页参数 */
			// 0已导出，1正在导出，2导出失败
			ipagination: {
				pageSize: 10,
				total: 0,
				currentPage: 1,
			},
			// 主要在详情页面使用
			exportTime: '', // 导出时间
			exportName: '', //导出文件名称
			exportStatus: '', // 导出状态
		}
	},
	methods: {
		/*
		 * @description: 数据请求
		 */
		// 获取导出记录列表
		getExportRecord() {
			let params = {
				page: this.ipagination.currentPage,
				limit: this.ipagination.pageSize,
			}
			// 0已导出，1正在导出，2导出失败
			this.$http.postAction(this.$api.lineExportList, params).then((res) => {
				if (res.code == 0) {
					let datas = res.data || []
					if (res.count > 0) {
						this.tableData = datas || []
						this.ipagination.total = Number(res.count)
					} else {
						this.tableData = []
						this.ipagination.total = 0
						this.ipagination.pageSize = 10
					}
				} else {
					this.tableData = []
					this.ipagination.total = 0
					this.ipagination.pageSize = 10
				}
			})
		},
		// TODO 点击详情
		handleDetail(item) {
			this.chooseId = item.id
			// 导出时间
			this.exportTime = item.modifyTime || ''
			this.exportName = item.fileName || ''
			this.exportStatus = item.exportStatus

			let exportParam = item.exportParam || ''

			if (!exportParam || exportParam == '') {
				return
			}
			this.detailsDrawer = true
			let exportParams = JSON.parse(exportParam)
			let sensorData = exportParams.sensorData || []
			let innerTable = []
			if (sensorData && sensorData.length) {
				sensorData.forEach((item) => {
					let names = item.name.split('-')
					if (names.length == 5) {
						let cgqArr = []
						cgqArr.push({
							name: names[3],
							items: [names[4]],
						})
						// 查询控制箱是否一样
						let mapIndex = innerTable.findIndex((itemt) => {
							return itemt.allname == `${names[0]}-${names[2]}`
						})
						// 已经添加进去了
						if (mapIndex > -1) {
							// 采集项
							let arrs = innerTable[mapIndex]['cgqArr']
							let twoIndex = arrs.findIndex((itemp) => {
								return itemp.name == cgqArr[0]['name']
							})
							if (twoIndex > -1) {
								innerTable[mapIndex]['cgqArr'][twoIndex]['items'] = innerTable[mapIndex]['cgqArr'][twoIndex][
									'items'
								].concat(cgqArr[0].items)
							} else {
								innerTable[mapIndex]['cgqArr'] = innerTable[mapIndex]['cgqArr'].concat(cgqArr)
							}
						} else {
							// 没有一样的添加
							innerTable.push({
								name: '全区域-' + names[0],
								equipName: names[2],
								allname: `${names[0]}-${names[2]}`,
								cgqArr: cgqArr,
							})
						}
					} else if (names.length == 4) {
						let cgqArr = []
						cgqArr.push({
							name: names[2],
							items: [names[3]],
						})
						// 查询控制箱是否一样
						let mapIndex = innerTable.findIndex((itemt) => {
							return itemt.allname == `${names[0]}-${names[1]}`
						})
						// 已经添加进去了
						if (mapIndex > -1) {
							// 采集项
							let arrs = innerTable[mapIndex]['cgqArr']
							let twoIndex = arrs.findIndex((itemp) => {
								return itemp.name == cgqArr[0]['name']
							})
							if (twoIndex > -1) {
								innerTable[mapIndex]['cgqArr'][twoIndex]['items'] = innerTable[mapIndex]['cgqArr'][twoIndex][
									'items'
								].concat(cgqArr[0].items)
							} else {
								innerTable[mapIndex]['cgqArr'] = innerTable[mapIndex]['cgqArr'].concat(cgqArr)
							}
						} else {
							// 没有一样的添加
							innerTable.push({
								name: '全区域',
								equipName: names[1],
								allname: `${names[0]}-${names[1]}`,
								cgqArr: cgqArr,
							})
						}
					}
				})
			}
			this.innerTable = innerTable

			// 获取详情信息  -- 不使用
			// let pageparams = {
			// 	page: 1,
			// 	limit: 30,
			// }
			// let params = Object.assign({}, pageparams, exportParams)
			// console.log(params)
			// this.$http.postAction(this.$api.exportDetail, params).then((res) => {
			// 	if (res.code == 200) {
			// 		let datas = res.data || {}
			// 		if (Number(datas.totalCount) > 0) {
			// 			let records = datas.records || []
			// 			records.forEach((item, index) => {
			// 				let cgqArr = []
			// 				cgqArr.push({
			// 					name: item.subName,
			// 					items: item.paramName,
			// 				})
			// 				innerTable.push({
			// 					name: '全区域-' + item.areaName,
			// 					equipName: item.productName,
			// 					cgqArr: cgqArr,
			// 					itemArr: [],
			// 				})
			// 			})
			// 		}
			// 		if (innerTable.length) {
			// 			innerTable.forEach((item, index) => {
			// 				let mapIndex = innerTableAll.findIndex((items) => {
			// 					return items.name == item.name && items.equipName == item.equipName
			// 				})
			// 				console.log(mapIndex)
			// 				if (mapIndex > -1) {
			// 					innerTableAll[mapIndex]['cgqArr'] = innerTableAll[mapIndex]['cgqArr'].concat(item.cgqArr)
			// 				} else {
			// 					innerTableAll.push(item)
			// 				}
			// 			})

			// 			// 再次进行格式设置
			// 			innerTableAll.forEach((item) => {
			// 				let itemArr = []
			// 				item.cgqArr.forEach((items) => {
			// 					let itemIndex = itemArr.findIndex((itemf) => {
			// 						return itemf.name == items.name
			// 					})
			// 					if (itemIndex > -1) {
			// 						itemArr[itemIndex]['items'].push(items.items)
			// 					} else {
			// 						itemArr.push({
			// 							name: items.name,
			// 							items: [items.items],
			// 						})
			// 					}
			// 				})
			// 				item.itemArr = itemArr
			// 			})
			// 			console.log(innerTableAll)
			// 		}
			// 	}
			// })
		},
		// 重新导出
		exportAgain(items) {
			let exportParams = JSON.parse(items.exportParam)
			let params = {
				exportParam: exportParams,
			}
			console.log(params)
			this.$http.postAction(this.$api.saveExport, params).then((res) => {
				if (res.code == 200) {
					this.$message(res.msg, 'success')
				} else {
					this.$message(res.msg, 'error')
				}
			})
		},
		// 点击删除
		handleDel({ id }) {
			this.chooseId = id
			this.Information = true
		},
		// 确认删除
		scheduleDel() {
			let params = {
				exportRecordId: this.chooseId,
			}
			this.$http
				.getAction(this.$api.exportRecordDelete, params)
				.then((res) => {
					if (res.code == 200) {
						this.$refs.tsmsg.msgShow(res.msg, 1.5);
					} else {
						this.$refs.tsmsg.msgShow(res.msg, 1.5);
					}
					this.getExportRecord()
				})
				.finally(() => {
					this.Information = false
				})
		},

		// 点击下载
		handleDown({ id, fileName }) {
			this.chooseId = id
			// 请求接口
			let params = {
				bucketName: 'program',
				fileName: fileName,
				expiry: 3600,
			}
			this.$http
				.postAction(this.$api.downExport, params)
				.then((res) => {
					if (res.code == 200) {
						let datas = res.data || ''
						if (datas && datas.length) {
							window.location.href = datas
						}
					} else {
						this.$message(res.msg, 'error')
					}
				})
				.catch((err) => {
					this.$message('下载出错', 'error')
				})
		},
		// 分页的页码改变
		handleCurrentChange(val) {
			this.ipagination.currentPage = val
			this.getExportRecord()
		},
	},
}
