<template>
  <div class="flex district-page">
    <!-- 左边 -->
    <div class="c_45 font_16 flex flex_col left">
      <!-- 上面的表单 -->
      <div class="selects">
        <div class="select-titles">查询信息</div>
        <el-form
          :model="queryParam"
          ref="ruleform"
          :inline="true"
          class="warnform-inline"
        >
          <el-form-item label="所在区域" class="form-item-noleft">
            <el-select v-model="queryParam.areaId" placeholder="请选择所在区域">
              <el-option
                v-for="(item, index) in areaSelect"
                :key="index"
                :value="item.id"
                :label="item.areaName"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="产品类型">
            <el-select
              v-model="queryParam.productType"
              placeholder="请选择产品类型"
              clearable
            >
              <el-option
                v-for="(item, index) in typeSelect"
                :key="index"
                :value="item.dictValue"
                :label="item.dictLabel"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品型号">
            <el-input
              placeholder="请输入产品型号"
              v-model="queryParam.productName"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品编号">
            <el-input
              placeholder="请输入产品编号"
              v-model="queryParam.deviceNum"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品名称" class="form-item-noleft">
            <el-input
              placeholder="请输入产品名称"
              v-model="queryParam.deviceName"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品状态">
            <el-select
              v-model="queryParam.deviceStatus"
              placeholder="请选择状态"
            >
              <el-option value="offline" label="离线"></el-option>
              <el-option value="online" label="在线"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="产品归属">
						<el-select v-model="queryParam.productPattern" placeholder="请选择归属">
							<el-option :value="0" label="自有设备"></el-option>
							<el-option :value="1" label="他人共享"></el-option>
						</el-select>
					</el-form-item> -->
          <el-form-item class="el-form-item__content-btns">
            <div class="flex flex_ju_end form-btns">
              <el-button type="primary" class="btn-reset" @click="formReset"
                >重置</el-button
              >
              <el-button type="primary" @click="searchClick">查询</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- 下面的表格 -->
      <div class="flex flex_col warn-tables">
        <!-- 关联区域按钮 -->
        <el-button class="btn-link" @click="handleLink">
          <img
            class="icon_link"
            src="@/assets/image/personal/icon_link.png"
            alt=""
          />
          <span>关联区域</span>
        </el-button>
        <!-- 表格 -->
        <el-table
          ref="multipleTable"
          :data="tableData"
          class="tables"
          :height="450"
          :stripe="true"
          align="center"
          header-cell-class-name="header-cell"
          cell-class-name="table-cell"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="60" align="right">
          </el-table-column>
          <el-table-column type="index" label="序号" width="100" align="center">
          </el-table-column>
          <el-table-column
            prop="areaName"
            label="所在区域"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>

          <el-table-column
            prop="productTypeDesc"
            label="产品类型"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="productName"
            label="产品型号"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="deviceName"
            label="产品名称"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="deviceNum"
            label="产品编号"
            width="210"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="产品状态"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-if="scope.row.deviceStatus == 'online'">在线</span>
              <span v-else>离线</span>
            </template>
          </el-table-column>
          <!-- TODO 此版本没有共享功能，此显示隐藏 -->
          <!-- <el-table-column label="产品归属" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-if="scope.row.productPattern == 0">自有设备</span>
							<span v-else class="attribution-share">他人共享</span>
						</template>
					</el-table-column> -->

          <!-- 占位数据 -->
          <div slot="empty" class="t_c table-empty">
            <img
              class="table-noimg"
              src="@/assets/image/personal/no_table.png"
              alt=""
            />
            <div class="table-emptyword">暂无产品数据</div>
          </div>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          class="pagination"
          layout="prev, pager, next"
          :current-page.sync="ipagination.currentPage"
          :page-size="ipagination.pageSize"
          :total="ipagination.total"
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 右边-树形控件 -->
    <div class="right">
      <div class="hea-box">区域管理</div>
      <template v-if="treeData && treeData.length">
        <!-- 树形控件 -->
        <div class="trees-box" :class="{ 'trees-box-link': isLink }">
          <el-tree
            ref="trees"
            :data="treeData"
            :props="defaultProps"
            highlight-current
            :default-expanded-keys="['0']"
            node-key="id"
            @node-click="handleNodeClick"
          >
            <template #default="{ node, data }">
              <div class="custom-tree-node" v-if="!isLink">
                <!-- 第一级--父级 -->
                <div
                  v-if="data.children"
                  class="flex flex_ju_sb flex_a_c tree-parent"
                >
                  <span>
                    <!-- <i
											class="el-icon-caret-bottom"
											style="color: #c0c4cc; font-size: 12px"
											v-if="!data.children.length"
										></i
										> -->
                    {{ node.label }}</span
                  >
                  <i
                    class="el-icon-circle-plus-outline tree-iconadd"
                    @click.stop="treeAdd(node, data)"
                  ></i>
                </div>
                <!-- 第二级--子级 -->
                <div v-else class="flex flex_a_c flex_ju_sb">
                  <!-- 编辑状态 -->
                  <template v-if="data.isEdit">
                    <el-input
                      class="tree-input"
                      autofocus
                      v-model="editData.areaName"
                      placeholder="请输入区域名称"
                      :ref="'slotTreeInput' + data['id']"
                      @blur="treeBlur(node, data)"
                      :maxlength="9"
                    ></el-input>
                    <div class="flex flex_a_c">
                      <i
                        class="el-icon-circle-check tree-iconsubmit"
                        @mousedown="handleEditSubmit($event, node, data)"
                      ></i>

                      <i
                        class="el-icon-circle-close tree-iconclose"
                        @mousedown="handleEditCancle($event, node, data)"
                      ></i>
                    </div>
                  </template>
                  <!-- 默认状态 -->
                  <template v-else>
                    <span>{{ node.label }}</span>
                    <div class="flex flex_a_c">
                      <img
                        src="@/assets/image/equipment/icon-quxiao.png"
                        class="tree-icon"
                        alt=""
                        @click.stop="treeDetele($event, node, data)"
                      />
                      <img
                        src="@/assets/image/equipment/icon-bj.png"
                        class="tree-icon treeicon-edit"
                        alt=""
                        @click.stop="treeEdit($event, node, data)"
                      />
                    </div>
                  </template>
                </div>
              </div>
              <!-- 关联状态显示 -->
              <div class="custom-tree-node" v-else>
                <!-- 第一级--父级 -->
                <div v-if="data.children" class="flex flex_ju_sb">
                  <el-radio v-model="radioLink" :label="data.id">{{
                    node.label
                  }}</el-radio>
                </div>
                <!-- 第二级--子级 -->
                <div v-else class="flex flex_a_c flex_ju_sb tree-node-boy">
                  <el-radio v-model="radioLink" :label="data.id">{{
                    node.label
                  }}</el-radio>
                </div>
              </div>
            </template>
          </el-tree>
          <!-- 没有子级数据的占位图 -->
          <template
            v-if="
              treeData.length &&
              (!treeData[0]['children'] || !treeData[0]['children'].length)
            "
          >
            <el-empty
              description="暂未添加区域信息"
              class="tree-empty-all tree-empty-qy"
            >
              <img
                slot="image"
                class="tree-empty-img"
                src="@/assets/image/personal/no_qyu.png"
                alt=""
              />
            </el-empty>
          </template>
        </div>
        <!-- 关联区域时的确定按钮 -->
        <el-button
          v-if="isLink"
          :type="radioLink.length ? 'primary' : 'info'"
          class="btn-treelink"
          :disabled="!radioLink.length"
          @click="handleLinkSubmit"
          >确定</el-button
        >
      </template>
      <!-- 右侧树形没有数据的占位图 -->
      <template v-else>
        <el-empty description="暂未添加区域信息" class="tree-empty-all">
          <img
            slot="image"
            class="tree-empty-img"
            src="@/assets/image/personal/no_qyu.png"
            alt=""
          />
        </el-empty>
      </template>
    </div>
    <!-- 树形删除确认框 -->
    <el-dialog
      :title="delItem.areaName"
      :visible.sync="dialogVisible"
      custom-class="dialog-treedel"
      :show-close="false"
      :modal-append-to-body="false"
    >
      <span>确定删除该区域信息吗？</span>
      <div class="red-txt">(删除后，产品将移动到全区域)</div>
      <div slot="footer" class="flex dialog-footer">
        <div class="treedia-btn treedia-cancle" @click="handleDeteleCancle">
          取 消
        </div>
        <div class="treedia-btn treedia-submit" @click="handleDeteleSubmit">
          确 定
        </div>
      </div>
    </el-dialog>
    <Msg ref="tsmsg"></Msg>
  </div>
</template>

<script>
/*
 * @description: 个人中心-区域管理
 */
import Msg from "@/components/msg.vue"; //信息提示组件
import tableMinin from "@/mixins/TableMinin";
import district from "./assets/distruct";
export default {
  mixins: [tableMinin, district],
  components: { Msg },
  data() {
    const self = this;
    return {
      ipagination: {
        pageSize: 8,
        total: 0,
        currentPage: 1,
      },
      isLink: false, // 是否是关联区域状态
      areaSelect: [], // 区域下拉选
      typeSelect: [], // 产品类型型号下拉选
      queryParam: {
        page: "1",
        limit: "8",
        productType: "",
        productName: "",
        deviceName: "",
        deviceNum: "",
        areaId: "",
        deviceStatus: "",
      },
      //树形组件数据
      treeData: [
        {
          id: "0",
          areaName: "全区域",
          sysUserId: "46",
          children: [],
        },
      ],
      defaultProps: {
        children: "children",
        label: "areaName",
      },
      // 存放树形组件的选中
      playIdObj: {},
      multipleSelection: [], // 批量选中数据
      radioLink: "", // 右侧树形关联区域时的选中值/id
      url: {
        list: self.$api.sysPageList,
        areaList: self.$api.sysAreaList,
        areaSave: self.$api.sysAreaSave,
        delArea: self.$api.sysAreaDel,
      },
      disableMixinCreated: true,
      ifUseLoadBack: false,
      isTranPage: true,
      pageValue: "page",
      pageLimit: "limit",
    };
  },
  mounted() {
    this.loadAreaData();
    //  获取产品类型型号下拉选
    this.getTypeSelect();

    this.$nextTick(() => {
      this.loadData();
    });
  },
  methods: {
    // 树形组件点击事件
    handleNodeClick(data) {
      // console.log(data);
      // 说明点击的是第一层，拦截点击事件
      if (data.children && data.children.length) return;
      // 此列表单选
      this.playIdObj = data;
    },
    // 表格选中事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // 区域选中取消
      if (!val.length) {
        this.radioLink = "";
      }
    },
    // 关联区域点击
    handleLink() {
      if (!this.isLink) {
        if (this.multipleSelection.length) {
          this.isLink = !this.isLink;
        } else {
          this.$message("暂无选中值", "warning");
        }
      } else {
        this.isLink = false;
        this.$refs.multipleTable.clearSelection();
        this.radioLink = "";
      }
    },
    // 关联确定
    handleLinkSubmit() {
      if (!this.radioLink || this.radioLink == "") {
        this.$message("请选择区域", "warning");
        return;
      }
      let multipleSelection = [];
      this.multipleSelection.forEach((item) => {
        multipleSelection.push(Number(item.deviceId));
      });
      let params = {
        deviceId: multipleSelection,
        areaId: this.radioLink,
      };
      // 请求关联接口
      this.$http.postAction(this.$api.sysAreaDeviceSave, params).then((res) => {
        if (res.code == 200) {
          this.$message("区域关联成功", "success");

          // 数据恢复
          setTimeout(() => {
            this.isLink = false;
            this.$refs.multipleTable.clearSelection();
            this.radioLink = "";
          }, 200);

          // 重新请求列表数据
          this.loadData(1);
        } else {
          this.$message(res.msg, "error");
        }
      });
    },

    /*
     * @description: 数据请求
     */
    // 获取table表格数据
    loadData(arg) {
      if (!this.url.list || this.url.list == "") {
        this.$message("请设置url.list!", "error");
        return;
      }

      if (arg == 1) {
        this.ipagination.currentPage = 1;
      }
      this.loading = true;
      let params = Object.assign({}, this.queryParam);
      if (!params.areaId || params.areaId == "") {
        this.$set(params, "areaId", "0");
      }
      this.$http
        .postAction(this.url.list, params)
        .then((res) => {
          if (res.code == 200) {
            let result = res.data || {};
            if (result.total > 0) {
              let datas = result.records || [];
              this.tableData = datas || [];
              this.ipagination.total = Number(result.total);
            } else {
              this.tableData = [];
              this.ipagination.total = 0;
            }
          } else {
            this.tableData = [];
            this.ipagination.total = 0;
          }
        })
        .finally(() => {})
        .catch(() => {});
    },
    // 获取右侧区域数据
    loadAreaData() {
      if (!this.url.areaList || this.url.areaList == "") {
        this.$message("请设置url.areaList!", "error");
        return;
      }

      this.$http
        .getAction(this.url.areaList)
        .then((res) => {
          if (res.code == 200) {
            let datas = res.data || [];
            let treeData = [];
            this.areaSelect = datas;
            if (!datas.length) {
              treeData = [];
              this.$set(this.queryParam, "areaId", "");
            } else {
              // treeData = [...datas]
              // this.$set(this.queryParam, 'areaId', treeData[0]['id'])
              // treeData.forEach((item, index) => {
              // 	if (!item.children) {
              // 		this.$set(treeData[index], 'children', [])
              // 	}
              // })
              // 只有全区域
              if (datas.length == 1 && datas[0]["areaName"] == "全区域") {
                treeData = [...datas];
                this.$set(treeData[0], "children", []);
              } else {
                treeData = [];
                // 有其它的区域
                let allIndex = datas.findIndex((item) => {
                  return item.areaName == "全区域";
                });
                let allItem = datas.slice(allIndex, 1)[0];
                this.$set(allItem, "children", []);
                let newDatas = [...datas];
                this.$delete(newDatas, allIndex);
                allItem.children = newDatas;
                treeData.push(allItem);
              }
            }
            this.$set(this.queryParam, "areaId", treeData[0]["id"]);
            this.treeData = treeData;
          } else {
            this.areaSelect = [];
          }
          console.log(this.treeData);
        })
        .finally((err) => {})
        .catch(() => {
          this.areaSelect = [];
        });
    },
    // 获取产品类型型号下拉选
    getTypeSelect() {
      this.$http
        .getAction(this.$api.getProductType)
        .then((res) => {
          if (res.code == 200) {
            let datas = res.data || [];
            this.typeSelect = datas || [];
          } else {
            this.typeSelect = [];
          }
        })
        .catch(() => {
          this.typeSelect = [];
        });
    },
    // 重置点击
    formReset() {
      this.$refs["ruleform"].resetFields();
      this.queryParam = {
        page: "1",
        limit: "8",
        productType: "",
        productName: "",
        deviceName: "",
        deviceNum: "",
        areaId: "",
        deviceStatus: "",
      };
      if (this.areaSelect.length) {
        this.$set(this.queryParam, "areaId", this.areaSelect[0]["id"]);
      } else {
        this.$set(this.queryParam, "areaId", "0");
      }
      this.$nextTick(() => {
        this.loadData(1);
      });
    },
  },
};
</script>

<style lang="scss">
@import "./assets/distruct.scss";
</style>
