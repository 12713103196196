<template>
	<el-drawer
		custom-class="notice-drawer"
		title="详情"
		:visible.sync="visible"
		size="25%"
		@close="drawerClose"
	>
		<div class="content">
			<div>
				<span>业务类型:</span><span class="words">{{ typeName }}</span>
			</div>
			<div class="con-wrap">
				<span>业务标题:</span><span class="words">{{ infos.messageTitle }}</span>
			</div>
			<div class="con-wrap">
				<span>发生时间:</span><span class="words">{{ infos.createTime }}</span>
			</div>
			<div class="con-wrap">
				<span>推送时间:</span><span class="words">{{ infos.pushTime }}</span>
			</div>
			<div class="flex con-wrap">
				<div>业务内容:</div>
				<div class="words detail-word">
					{{ infos.messageBody }}
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
export default {
	data() {
		return {
			// 控制抽屉的开关
			visible: false,
			infos: {}, // 详情数据
			typeName: '',
		}
	},
	methods: {
		open({ item, types }) {
			this.visible = true
			this.infos = item || {}
			this.typeName = types || ''
		},
		// 抽屉关闭
		drawerClose() {
			this.visible = false
			this.infos = {}
		},
	},
}
</script>

<style lang="scss">
.notice-drawer {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		margin-bottom: 0.3rem;
		span {
			font-size: 0.23rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		text-align: left;
	}
	.content {
		font-size: 0.17rem;
		color: #999999;
		.con-wrap {
			margin-top: 0.28rem;
		}
		.words {
			color: #333333;
			margin-left: 0.21rem;
		}
		.detail-word {
			flex: 1;
			line-height: 1.5;
			margin-top: -0.04rem;
		}
	}
}
</style>
