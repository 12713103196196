<template>
  <div class="initiatingShare" v-if="drawer">
    <el-drawer
      custom-class="initiatingShareDrawer"
      title="发起共享"
      :visible.sync="drawer"
      size="23%"
    >
      <div class="toptxt-div">
        <img src="@/assets/image/equipment/tishi.png" alt="" />
        <div class="txt-div">
          共享后其他用户可以查看或操作您的设备，请谨慎操作；
          您可以对共享的设备随时取消共享
        </div>
      </div>
      <div class="title-div">
        搜索账号
        <span>（您可以搜索<span class="green">多个</span>账号进行共享）</span>
      </div>
      <div class="input-div">
        <el-input v-model="sharedInput" placeholder="请输入账号">
          <div slot="suffix" class="seach-icon"></div>
        </el-input>
      </div>
      <!-- 用户列表 -->
      <div class="user-list">
        <div
          class="user-item"
          :class="{ 'user-item-act': item.isCheck }"
          v-for="(item, index) in userList"
          :key="index"
          @click="userClick(index)"
        >
          <div class="r-l"></div>
          <div class="r-r">
            <div class="name">物联网昵称</div>
            <div>13256487963</div>
          </div>
          <div class="check">
            <i class="el-icon-check"></i>
          </div>
        </div>
      </div>
      <!-- 树形列表盒子 -->
      <!-- <div class="tree-box">
        <el-checkbox v-model="allchecked" @change="allcheckedChange"
          >全选</el-checkbox
        >
        <el-tree
          ref="tree"
          :data="treeData"
          show-checkbox
          node-key="id"
          :check-on-click-node="true"
          @check-change="checkChange"
        >
        </el-tree>
      </div> -->
      <!-- 确定按钮 -->
      <div class="btn-box">
        <div class="btn-item" @click="btnClick">下一步</div>
      </div>
      <el-drawer
        title="发起共享"
        :append-to-body="true"
        size="23%"
        :visible.sync="insideSwitch.sblistdrawer"
        custom-class="sblistdrawer flcu-box"
      >
        <div class="tree-box">
          <el-checkbox v-model="allchecked" @change="allcheckedChange"
            >全选</el-checkbox
          >
          <el-tree
            ref="tree"
            :data="treeData"
            show-checkbox
            node-key="id"
            :check-on-click-node="true"
            @check-change="checkChange"
          >
          </el-tree>
        </div>
        <div class="btn-box">
          <div class="btn-item" @click="quedingClick">确定</div>
        </div>
      </el-drawer>
    </el-drawer>
    <Msg ref="tjmsg"></Msg>
  </div>
</template>

<script>
import Msg from "@/components/msg.vue"; //信息提示组件
export default {
  components: { Msg },
  data() {
    return {
      // 控制抽屉的开关
      drawer: false,
      // 控制里面抽屉的开关
      insideSwitch: {
        sblistdrawer: false, //设备列表
      },
      // 共享设备查询账号信息
      sharedInput: "",
      // 用户列表
      userList: [
        {
          phone: "4564654654",
          isCheck: false,
        },
        {
          phone: "4564654654",
          isCheck: false,
        },
        {
          phone: "4564654654",
          isCheck: false,
        },
      ],
      // 共享设备查询列表是否选中
      sharedSeachCheck: false,
      //按钮是否全选
      allchecked: false,
      //   树形列表
      treeData: [
        {
          id: "1",
          label: "一体化控制箱（10）",
          disabled: true,
          children: [
            {
              id: "1-1",
              label: "控制箱一号",
            },
            {
              id: "1-2",
              label: "控制箱二号",
            },
          ],
        },
        {
          id: "2",
          label: "智能终端（6）",
          disabled: true,
          children: [
            {
              id: "2-1",
              label: "智能终端四号",
            },
            {
              id: "2-2",
              label: "智能终端六号",
            },
          ],
        },
        {
          id: "3",
          label: "气象站（5）",
          disabled: true,
          children: [
            {
              id: "3-1",
              label: "气象站五号",
            },
            {
              id: "3-2",
              label: "气象站六号",
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
        disabled: "disabled",
      },
    };
  },
  methods: {
    // 搜索用户点击
    seachUserClick() {
      this.sharedSeachCheck = !this.sharedSeachCheck;
    },
    // 用户列表项点击
    userClick(index) {
      this.userList[index].isCheck = !this.userList[index].isCheck;
    },
    // 全选按钮点击
    allcheckedChange() {
      if (this.allchecked) {
        let arr = [];
        this.treeData.forEach((item, index) => {
          if (item.children.length) {
            item.children.forEach((item2, index2) => {
              arr.push(item2.id);
            });
          }
        });
        this.$refs.tree.setCheckedKeys(arr);
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    // 节点被点击
    checkChange() {
      let checkArr = this.$refs.tree.getCheckedKeys(true);
      let arr = [];
      this.treeData.forEach((item, index) => {
        if (item.children.length) {
          item.children.forEach((item2, index2) => {
            arr.push(item2.id);
          });
        }
      });
      if (checkArr.length == arr.length) {
        this.allchecked = true;
      } else {
        this.allchecked = false;
      }
    },
    // 下一步按钮点击
    btnClick() {
      this.insideSwitch.sblistdrawer = true;
    },
    // 确定点击
    quedingClick() {
      this.drawer = false;
      this.insideSwitch.sblistdrawer = false;
    },
  },
};
</script>

<style lang="scss" scoped >
.initiatingShare {
  /deep/ .initiatingShareDrawer {
    border-radius: 0.225rem 0px 0px 0.225rem;
    .el-drawer__header {
      height: 0.825rem;
      box-sizing: border-box;
      text-align: left;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #454545;
      padding-top: 0;
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 0.3rem;
      span {
        font-size: 0.225rem;
      }
    }
    .el-drawer__close-btn {
      color: #454545;
    }
    .el-drawer__body {
      padding: 0 0.3rem 0.25rem;
      display: flex;
      flex-direction: column;
      text-align: left;
      .search-box {
        min-height: 1.8875rem;
        background: #ffffff;
        border: 1px solid #f1f1f1;
        border-radius: 0.05rem;
        box-sizing: border-box;
        padding: 0.2rem 0.3rem;
        font-size: 0.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #454545;
        .saach-item {
          height: 0.45rem;
          margin-top: 0.2rem;
          .el-select {
            width: 100%;
            height: 100%;
          }
          .el-date-editor--datetimerange.el-input,
          .el-date-editor--datetimerange.el-input__inner {
            width: 100%;
            height: 100%;
            line-height: 0.45rem;
          }
          .el-date-editor .el-range__icon {
            line-height: 0.35rem;
          }
          .el-date-editor .el-range__close-icon {
            line-height: 0.35rem;
          }
          .el-input__suffix-inner {
            width: 0.2rem;
            height: 100%;
            display: block;
            box-sizing: border-box;
            padding-top: 0.125rem;
            margin-right: 0.25rem;
            .seach-icon {
              position: relative;
              cursor: pointer;
              width: 0.2rem;
              height: 0.2rem;
              background: url("../../../assets/image/equipment/icon-seach.png")
                no-repeat;
              &::before {
                position: absolute;
                top: 50%;
                left: -0.25rem;
                transform: translateY(-50%);
                content: "";
                width: 1px;
                height: 22px;
                background: #f1f1f1;
              }
            }
          }
        }
        .results-box {
          position: relative;
          cursor: pointer;
          margin-top: 0.2rem;
          height: 1.05rem;
          background: #f1f9fa;
          border-radius: 4px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          border: 0.0125rem solid transparent;
          .r-l {
            margin-left: 0.25rem;
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
            background: gray;
          }
          .r-r {
            margin-left: 0.2rem;
            .name {
              margin-bottom: 0.2rem;
            }
          }
          .check {
            display: none;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 0;
            right: 0;
            border: 17px solid transparent;
            border-bottom-color: rgba(45, 174, 169, 1);
            border-right-color: rgba(45, 174, 169, 1);
            .el-icon-check {
              position: absolute;
              top: 0;
              left: 0;
              color: white;
            }
          }
        }
        .results-box-act {
          border-color: #2daea9;
          background: white;
          .check {
            display: block;
          }
        }
        .warm-box {
          .no-seach {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0.25rem;
            .icon {
              width: 0.25rem;
              height: 0.25rem;
              background: url("../../../assets/image/equipment/icon-noseach.png")
                no-repeat;
              background-size: 100% 100%;
            }
            .txt {
              color: #fc784c;
              margin-left: 0.1375rem;
            }
          }
        }
      }
      .tishi-box {
        width: 5.4rem;
        height: 1rem;
        background: url("../../../assets/image/equipment/icon-warimh.png")
          no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding-top: 0.3625rem;
        padding-left: 0.2125rem;
        padding-right: 0.2125rem;
        color: #fc784c;
        line-height: 0.25rem;
      }

      .btn-box {
        flex: 1;
        // position: relative;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        .btn-item {
          box-sizing: border-box;
          border: 0.0125rem solid #2daea9;
          cursor: pointer;
          width: 1.75rem;
          height: 0.45rem;
          background: #2daea9;
          border-radius: 0.05rem;
          text-align: center;
          line-height: 0.45rem;
          color: white;
        }
        .cancel {
          background: #fff;
          color: #2daea9;
        }
      }
      .toptxt-div {
        display: flex;
        margin-bottom: 0.3rem;
        img {
          margin-right: 0.2rem;
          width: 0.175rem;
          height: 0.175rem;
          margin-top: 0.025rem;
        }
        .txt-div {
          font-size: 0.175rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2daea9;
          line-height: 0.25rem;
        }
      }
      .title-div {
        margin-bottom: 0.2rem;
        span {
          font-size: 0.175rem;
        }
        .green {
          color: #2daea9;
        }
      }
      .input-div {
        height: 0.45rem;
        .el-date-editor--datetimerange.el-input,
        .el-date-editor--datetimerange.el-input__inner {
          width: 100%;
          height: 100%;
          line-height: 0.45rem;
        }
        .el-date-editor .el-range__icon {
          line-height: 0.35rem;
        }
        .el-date-editor .el-range__close-icon {
          line-height: 0.35rem;
        }
        .el-input__suffix-inner {
          width: 0.2rem;
          height: 100%;
          display: block;
          box-sizing: border-box;
          padding-top: 0.125rem;
          margin-right: 0.25rem;
          .seach-icon {
            position: relative;
            cursor: pointer;
            width: 0.2rem;
            height: 0.2rem;
            background: url("../../../assets/image/equipment/icon-seach.png")
              no-repeat;
            &::before {
              position: absolute;
              top: 50%;
              left: -0.25rem;
              transform: translateY(-50%);
              content: "";
              width: 1px;
              height: 22px;
              background: #f1f1f1;
            }
          }
        }
      }
      .user-list {
        margin-top: 0.3rem;
        height: 73%;
        // overflow: auto;
        .user-item {
          cursor: pointer;
          position: relative;
          height: 1.05rem;
          background: #ffffff;
          border: 1px solid #f1f1f1;
          box-shadow: 2px 2px 6px 0px rgba(44, 44, 45, 0.1),
            -2px -2px 6px 0px rgba(44, 44, 45, 0.1);
          border-radius: 4px;
          margin-bottom: 0.3rem;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          border: 0.0125rem solid transparent;
          &:last-child {
            margin-bottom: 0;
          }
          .r-l {
            margin-left: 0.25rem;
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
            background: gray;
          }
          .r-r {
            margin-left: 0.2rem;
            .name {
              margin-bottom: 0.2rem;
            }
          }
          .check {
            display: none;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 0;
            right: 0;
            border: 17px solid transparent;
            border-bottom-color: rgba(45, 174, 169, 1);
            border-right-color: rgba(45, 174, 169, 1);
            .el-icon-check {
              position: absolute;
              top: 0;
              left: 0;
              color: white;
            }
          }
        }
        .user-item-act {
          border-color: #2daea9;
          background: white;
          .check {
            display: block;
          }
        }
      }
    }
  }
}
/deep/ .sblistdrawer {
  border-radius: 0.225rem 0px 0px 0.225rem;
  .el-drawer__header {
    height: 0.825rem;
    box-sizing: border-box;
    text-align: left;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #454545;
    padding-top: 0;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 0.3rem;
    span {
      font-size: 0.225rem;
    }
  }
  .el-drawer__close-btn {
    color: #454545;
  }
  .el-drawer__body {
    padding: 0 0.3rem 0.25rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    .tree-box {
      margin-top: 0.3rem;
      box-sizing: border-box;
      .el-tree {
        margin-top: 0.3rem;
        .el-checkbox__input.is-disabled .el-checkbox__inner {
          display: none;
        }
      }
    }
    .btn-box {
      flex: 1;
      // position: relative;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      .btn-item {
        box-sizing: border-box;
        border: 0.0125rem solid #2daea9;
        cursor: pointer;
        width: 1.75rem;
        height: 0.45rem;
        background: #2daea9;
        border-radius: 0.05rem;
        text-align: center;
        line-height: 0.45rem;
        color: white;
      }
      .cancel {
        background: #fff;
        color: #2daea9;
      }
    }
  }
}
</style>