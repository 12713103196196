import Mock from 'mockjs'
const Random = Mock.Random
// 生产管理
// 头部下拉选
Mock.mock('/wlw/proFindTypeByOrgId', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			typeId: '21047284',
			facName: '设备监测',
		},
		{
			typeId: '21047283',
			facName: '虫情管理',
		},
	],
})
// 获取大棚组/区域下拉选
Mock.mock('/wlw/findFarmingByOrgCode', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			orgId: '21047284',
			facName: '设备监测',
		},
		{
			orgId: '21047283',
			facName: '虫情管理',
		},
	],
})
// 获取table列表
Mock.mock('/wlw/proTableList', 'post', {
	meta: {
		success: true,
		message: 'ok',
	},
	'data|10': [
		{
			orgId: '区域',
			id: '@id',
			bioName: '名称',
			typeId: '类型',
			content: '@csentence()',
			times: '@datetime()',
			'person|+1': ['张某某', '李某某'],
		},
	],
})
// 新增/编辑
// 删除
