<template>
  <div class="warn-tables environ-table" v-loading="loading">
    <div v-if="tableData.length" class="t_c">
      <!-- :height="510" -->
      <el-table
        :data="tableData"
        class="tables"
        :stripe="false"
        align="center"
        header-cell-class-name="header-cell"
        cell-class-name="table-cell"
      >
        <el-table-column
          prop="arrivalTime"
          label="时间"
          align="center"
          width="250"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column :label="allName" align="center" show-overflow-tooltip>
          <!-- 动态显示类型 -->
          <el-table-column
            :label="item.itemName"
            align="center"
            show-overflow-tooltip
            v-for="(item, index) in tableCTypes"
            :key="index"
          >
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.tableList[item.dataItem] &&
                  scope.row.tableList[item.dataItem] >= 0
                "
                >{{ scope.row.tableList[item.dataItem] }}</span
              >
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination"
        layout="prev, pager, next"
        :current-page.sync="ipagination.currentPage"
        :page-size="ipagination.pageSize"
        :total="ipagination.total"
        :hide-on-single-page="true"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- 暂无数据 -->
    <div
      class="table-empty flex flex_col flex_ju_c flex_a_c bottom-empty"
      v-else
    >
      <img
        class="table-noimg"
        src="@/assets/image/personal/no_table.png"
        alt=""
      />
      <div class="table-emptyword">暂无数据</div>
    </div>
  </div>
</template>

<script>
/**
 * @description 统计报表
 */
import tableMinin from "@/mixins/TableMinin";
export default {
  mixins: [tableMinin],
  data() {
    const self = this;
    return {
      // 表格数据
      queryParam: {},
      allName: "",
      isTranPage: true,
      pageValue: "page",
      pageLimit: "limit",

      disableMixinCreated: true,
      isFillTime: false,
      ifUseLoadBack: false,
      tableCTypes: [],
      tableData: [],
      /* 分页参数 */
      ipagination: {
        pageSize: 8,
        total: 0,
        currentPage: 1,
      },
      url: {
        list: self.$api.environmentTable,
      },
    };
  },
  mounted() {},
  methods: {
    setForm(val) {
      let queryAll = JSON.parse(JSON.stringify(val));
      this.queryParam = queryAll.params;
      this.allName = queryAll.allName;
    },
    loadData(arg) {
      if (!this.url.list || this.url.list == "") {
        this.$message("请设置url.list属性!", "error");
        return;
      }
      if (arg == 1) {
        this.ipagination.currentPage = 1;
      }
      this.loading = true;
      let params = this.getQueryParams();
      this.$http
        .postAction(this.url.list, params)
        .then((res) => {
          if (res.code == 200) {
            let result = res.data || {};
            let datas = result.records || [];
            if (!datas.length) {
              this.tableData = [];
              this.tableCTypes = [];
              this.loading = false;
              return;
            }
            if (Number(result.totalCount) > 0) {
              this.ipagination.total = Number(result.totalCount);
            } else {
              this.tableData = [];
              this.ipagination.total = 0;
              this.ipagination.pageSize = 8;
            }
            // 数据调整处理
            let lenData = []; //类型数组
            datas.forEach((item) => {
              let tableList = {};
              item.dataList.forEach((item2) => {
                // lenged
                let lenIndex = lenData.findIndex((kk) => {
                  return kk.dataItem == item2.dataItem;
                });
                if (lenIndex > -1) {
                } else {
                  lenData.push({
                    dataItem: item2.dataItem,
                    itemName: `${item2.dataItemName}(${item2.dataItemUnit})`,
                  });
                }
                tableList[item2.dataItem] = item2.dataItemValue;
              });
              item.tableList = tableList;
            });
            this.tableCTypes = lenData;
            this.tableData = datas;
          } else {
            this.tableData = [];
            this.tableCTypes = [];
            this.loading = false;
          }
        })
        .finally((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    hideLoad() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.environ-table {
  margin-top: 0.25rem;
  border-radius: 0;
  padding-bottom: 0 !important;
  .bottom-empty {
    line-height: 1.3;
    // height: 8rem;
    margin-top: 2.49rem;
    .table-noimg {
      height: 2.4rem;
    }
  }
}
</style>
<style>
.environ-table .tables .header-cell .cell {
  height: 7vh;
  line-height: 7vh;
}
</style>
