// 消息中心
import Mock from 'mockjs'
const Random = Mock.Random
// 消息通知列表
Mock.mock('/wlw/noticeList', 'post', (req) => {
	let options = JSON.parse(req.body)
	let pageSize = options.pageSize
	let typeId = options.typeId || null
	console.log(options)
	let datas = []
	for (let i = 0; i < pageSize; i++) {
		datas.push({
			typeId: typeId ? typeId : Random.integer(1, 6),
			titles: Random.ctitle(),
			words: Random.csentence(),
			status: Random.integer(0, 1),
			startTime: Random.datetime(),
			pushTime: Random.datetime(),
			ids: Random.id(),
		})
	}
	return {
		meta: {
			success: true,
		},
		data: {
			total: '20',
			size: '10',
			current: '1',
			orders: [],
			optimizeCountSql: true,
			hitCount: false,
			searchCount: true,
			pages: '2',
			records: datas,
		},
	}
})
// 系统日志列表
Mock.mock('/wlw/findLoggList', 'post', (req) => {
	let options = JSON.parse(req.body)
	let pageSize = options.pageSize
	let typeId = options.typeId || null
	console.log(options)
	let datas = []
	for (let i = 0; i < pageSize; i++) {
		datas.push({
			ids: Random.id(),
			typeId: typeId ? typeId : Random.integer(1, 6),
			content: Random.csentence(),
			modal: '日志类型XXX',
			times: Random.datetime(),

			ways: 'Web平台',
			equipname: 'TAISHIJI1',
			address: '全区域-大棚一号',
		})
	}
	return {
		meta: {
			success: true,
		},
		data: {
			total: '20',
			size: '10',
			current: '1',
			orders: [],
			optimizeCountSql: true,
			hitCount: false,
			searchCount: true,
			pages: '2',
			records: datas,
		},
	}
})

// 区域管理列表
Mock.mock('/wlw/districtList', 'post', (req) => {
	let options = JSON.parse(req.body)
	let pageSize = options.pageSize
	let datas = []
	for (let i = 0; i < pageSize; i++) {
		datas.push({
			ids: Random.id(),
			area: '全区域',
			types: '气象站',
			model: 'A-01',
			equipname: Random.csentence(),
			serialNumber: 'YF1235684576231546',
			status: Random.integer(0, 1),
			attribution: Random.integer(0, 1),
		})
	}
	return {
		meta: {
			success: true,
		},
		data: {
			total: '20',
			size: pageSize,
			current: '1',
			orders: [],
			optimizeCountSql: true,
			hitCount: false,
			searchCount: true,
			pages: Math.ceil(20 / pageSize),
			records: datas,
		},
	}
})
