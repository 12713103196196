// 接口统一管理页面
const api = {
	apiLogin: '/web/api/anon/login', // 登录
	getSmsCode: '/web/api/anon/sms-code', // 获取验证码
	getcaptcha: "/web/api/anon/captcha",//获取图形验证码

	forgetPass: '/web/api/anon/forgot-password', // 忘记密码
	hadRegiest: '/web/api/anon/register-check', // 检测手机号是否注册
	toRegiest: '/web/api/anon/register', // 注册
	codeLogin: '/web/api/anon/sms-code-login', // 短信验证码登录
	getAuthUrl: '/web/api/anon/sso/auth-url', // 获取用户中心登录地址
	getIndustrySelect: '/web/api/anon/sys-industry-list', // 获取行业下拉选
	// 首页
	getMsgList: '/web/api/message/sys-message-list', // 获取消息列表、消息中心
	contactUs: '/web/api/cooperativePurpose/save', // 联系我们
	articleList: '/web/api/article/list', // 合作共赢列表
	srticleDetail: '/web/api/article/find', // 合作共赢详情
	recommedList: '/web/api/productRecommend/list', // 产品推荐列表
	getUserDeviceCount: '/web/api/areaDevice/getUserDeviceCount', //设备统计
	savePageView: '/web/api/article/savePageView', // 合作共赢/内容发布增加阅读次数
	commenSavePage: '/web/api/productRecommend/savePageView', // 产品推荐添加阅读次数

	// 产品管理
	myDpList: '/web/api/areaDevice/dpList', // 我的设备列表
	bindDevice: '/web/api/areaDevice/bind-device', //添加产品
	getDeviceMac: '/web/api/device/get-device-mac-info', // 获取设备信息MAC信息等
	getDeviceVersion: '/web/api/device/get-device-new-version', // 获取设备固件版本信息
	equipmentUpgrade: '/web/api/device/upgrade-device', //设备升级
	CancelUpgrade: '/web/api/device/un-upgrade-device', //取消升级
	getDeviceSensor: '/web/api/device/get-device-sensor', // 通过设备ID获取设备传感组件
	getDeviceControl: '/web/api/device/get-device-control', //通过设备ID获取设备控制组件
	updateControlName: '/web/api/device/update-control-name', //修改设备控制组件名称
	querySleepTime: '/web/api/smartTerminal/querySleepTime', // 查询休眠间隔时间(智能终端为采集时间)
	setSleepTime: '/web/api/smartTerminal/setSleepTime', //设置休眠时间(智能终端为采集时间)
	queryAlarmTime: '/web/api/smartTerminal/queryAlarmTime', //查询告警时间
	setAlarmTime: '/web/api/smartTerminal/setAlarmTime', //设置告警时间
	getDeviceControl: '/web/api/device/get-device-control', // 通过设备ID获取设备控制组件
	updateDeviceName: '/web/api/areaDevice/update-device-name', //修改设备名称
	unbindDevice: '/app/api/areaDevice/unbind-device', //设备解除绑定
	updateMetadataServiceName: '/web/api/device/update-metadata-service-name', //根据设备id或者设备编码修改子设备名称
	// 产品服务
	userDeviceList: '/web/api/areaDevice/userDeviceList', //我的区域树状结构树
	deviceSub: '/web/api/areaDevice/device-sub', //智能终端设备及子设备信息数据
	deviceSensorData: '/web/api/areaDevice/device-sensor-last-data', //传感实时数据
	deviceSensorLinedata: '/web/api/areaDevice/device-sensor-data', //折线图数据
	stAlarmConfList: '/web/api/stAlarmConf/warningList', //查询告警配置列表
	setAlarmConf: '/web/api/stAlarmConf/setAlarmConf', //设置告警配置
	enableAlarmConf: '/web/api/stAlarmConf/enableAlarmConf', //告警设置-启用禁用
	removeAlarms: '/web/api/stAlarmConf/removeAlarms', //告警设置-删除
	thresholdList: '/web/api/threshold-conf/list', // 设备自动化 - 查询
	thresholdSet: '/web/api/threshold-conf/set', // 设备自动化环境阈值- 设置
	thresholdDel: '/web/api/threshold-conf/delete', // 设备自动化环境宇宙 - 删除
	// 设备操作
	operateDevice: '/web/api/smartTerminal/operateDevice', //设备操作
	scram: '/web/api/smartTerminal/scram', //设备急停 | 取消
	deviceFineTuning: '/web/api/smartTerminal/deviceFineTuning', //设备微调
	aligned: '/web/api/smartTerminal/aligned', //设备复位

	// 安全密码
	querySafePassword: '/web/api/smartTerminal/query-safe-password', //查询是否设置密码
	setSafePassword: '/web/api/smartTerminal/set-safe-password', //设置安全密码
	comparePassword: '/web/api/smartTerminal/compare-password', //安全密码比对
	updateSafePassword: '/web/api/smartTerminal/update-safe-password', //修改安全密码

	// 时长配置
	queryDuration: '/web/api/smartTerminal/queryDuration', //查询时长配置
	setDuration: '/web/api/smartTerminal/setDuration', //设置时长
	automaticConfig: '/web/api/smartTerminal/automaticConfig', //行程设备总时长自动设定功能
	// 弹层上的接口
	operationRecord: '/web/api/operation-record/list', // 执行记录
	autoTimeConfig: '/web/api/autoTimeConfig/list', // 查询时间日程列表
	timeConfigDel: '/web/api/autoTimeConfig/delete', // 时间日程删除
	timeConfigEnable: '/web/api/autoTimeConfig/enable', // 时间日程下发
	timeConfigAdd: '/web/api/autoTimeConfig/add', // 时间日程添加
	timeConfigUpdate: '/web/api/autoTimeConfig/update', // 时间日程修改

	// 产品服务
	getProductType: '/web/api/areaDevice/getProductType', // 获取所有产品类型

	// 视频
	getVideoList: '/web/api/user/camera/list', // 用户摄像头列表
	pageVideoList: '/web/api/user/camera/videoList', // 用户摄像头列表-视频监控
	deviceCamera: '/web/api/areaDevice/device-camera', //用户设备所关联摄像头
	deviceCameraAdd: '/web/api/areaDevice/device-camera-add', //用户设备关联摄像头
	rebindCamera: '/web/api/areaDevice/rebind-camera', //用户设备取消关联摄像头

	videoUrl: '/hnyfkj/api/v1/getRealVideoUrl', // 视频播放地址
	// 大华
	dhptzCtrl: '/hnyfkj/api/v1/ptzCtrl', // 视频云台控制方向
	ptzOperationCtrl: '/hnyfkj/api/v1/ptzOperationCtrl', // 视频云台控制大小
	dhCapture: 'hnyfkj/api/v1/capturePic', // 抓图

	// 海康
	hkptzCtrl: '/hnyfkj/api/v1/ptzControlling', // 云台控制
	hkCapture: 'hnyfkj/api/v1/getCapture', // 抓图

	// 综合分析
	// 环境数据分析
	sensorDeviceInfo: '/web/api/areaDevice/sensor-device-info', // 传感设备信息列表
	environmentAnalyse: '/web/api/areaDevice/device-environment-analyse', // 环境数据分析
	environmentAnalyseMore: '/web/api/areaDevice/device-sensor-data-multiparameter', // 多设备多参数折线图
	environmentTable: '/web/api/areaDevice/device-environment-analyse-report', // 环境数据分析报表
	lineExportList: '/web/api/export-record/list', // 导出记录列表
	exportDetail: '/app/api/areaDevice/device-sensor-data-multiparameter-report', // 导出详情
	exportRecordDelete: '/web/api/export-record/delete', // 删除导出记录
	downExport: '/web/api/export-record/download-export-file', // 下载导出文件
	saveExport: '/web/api/export-record/save', // 生成导出记录

	deviceSelect: '/web/api/areaDevice/userGZJYDeviceList', // 光照,降雨设备下拉列表
	lightChart: '/web/api/areaDevice/device-sensor-illuminance-charts', // 日光照图表
	rainChart: '/web/api/areaDevice/device-sensor-rainfall-charts', // 降雨量图表

	// new
	// 个人中心
	getUserInfo: '/web/api/user/user-info', // 获取用户信息
	updateUser: '/web/api/user/update-user-info', //修改用户信息
	updatePwd: '/web/api/user/update-pwd', // 修改密码
	updatePhone: '/web/api/user/update-phone', // 修改手机号
	getCheckCode: '/web/api/user/check-sms-code', // 校验校验码
	getAreaList: '/web/api/admin-division/list', // 获取行政区域列表

	// 区域管理
	sysAreaList: '/web/api/sys-area/list', // 获取用户区域列表
	sysAreaDel: '/web/api/sys-area/delete', // 删除用户区域
	sysAreaSave: '/web/api/sys-area/save', // 保存用户区域
	sysAreaDeviceDel: '/web/api/areaDevice/delete', // 删除区域下绑定的设备关系
	sysAreaDeviceSave: '/web/api/areaDevice/saveAreaAndDevice', // 保存区域和设备关联关系
	sysPageList: '/web/api/areaDevice/userDevicePageList', // 列表分页

	// 执行记录
	recordList: '/web/api/executive-record/list', // 执行记录数据
	queryRecord: '/web/api/executive-record/query-executive-record', // 查询筛选条件
	findAreaDeviceList: '/web/api/executive-record/findAreaDeviceList', // 获取产品名称设备名称区域信息

	// 消息中心
	getMsgType: '/web/api/message/get-message-type', // 获取消息类型
	getMsgInfo: '/web/api/message/sys-message-info', // 获取消息详情
	makeAllRead: '/web/api/message/sys-message-read-all', // 全部设置已读
	makeOneRead: '/web/api/message/sys-message-read', // 批量设置消息为已读
}
export default api
