import Mock from 'mockjs'
const Random = Mock.Random
// 墒情监测
// 头部下拉选
Mock.mock('/wlw/soilFindDeviceByOrgId', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			facId: '21047284',
			facName: '墒情站2号',
		},
		{
			facId: '21047283',
			facName: '墒情站1号',
		},
	],
})
// 墒情详情数据
Mock.mock('/wlw/soillatestDeviceData', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: {
		facId: '36123',
		dataTime: '2022-05-31 15:05:28',
		sensorVOs: [
			{
				key: 'TRWDA',
				value: '22.60',
				name: '土温1',
				unitName: '℃',
				alarmStatus: 0,
			},
			{
				key: 'TRSDA',
				value: '8.40',
				name: '土湿1',
				unitName: '%',
				alarmStatus: 0,
			},
			{
				key: 'TRWDB',
				value: '21.80',
				name: '土温2',
				unitName: '℃',
				alarmStatus: 0,
			},
			{
				key: 'TRSDB',
				value: '10.70',
				name: '土湿2',
				unitName: '%',
				alarmStatus: 0,
			},
			{
				key: 'TRWDC',
				value: '21.80',
				name: '土温3',
				unitName: '℃',
				alarmStatus: 0,
			},
			{
				key: 'TRSDC',
				value: '10.90',
				name: '土湿3',
				unitName: '%',
				alarmStatus: 0,
			},
			{
				key: 'TRWDD',
				value: '21.90',
				name: '土温4',
				unitName: '℃',
				alarmStatus: 0,
			},
			{
				key: 'TRSDD',
				value: '8.70',
				name: '土湿4',
				unitName: '%',
				alarmStatus: 0,
			},
			{
				key: 'TRWDE',
				value: '21.80',
				name: '土温5',
				unitName: '℃',
				alarmStatus: 0,
			},
			{
				key: 'TRSDE',
				value: '8.70',
				name: '土湿5',
				unitName: '%',
				alarmStatus: 0,
			},
		],
	},
})
// 图表采集项下拉选
Mock.mock('/wlw/soilgetDeviceItems', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			itemCode: 'TRWDA',
			itemName: '土温1',
			itemUnitCode: '℃',
		},
		{
			itemCode: 'TRSDA',
			itemName: '土湿1',
			itemUnitCode: '%',
		},
		{
			itemCode: 'TRWDB',
			itemName: '土温2',
			itemUnitCode: '℃',
		},
		{
			itemCode: 'TRSDB',
			itemName: '土湿2',
			itemUnitCode: '%',
		},
		{
			itemCode: 'TRWDC',
			itemName: '土温3',
			itemUnitCode: '℃',
		},
		{
			itemCode: 'TRSDC',
			itemName: '土湿3',
			itemUnitCode: '%',
		},
		{
			itemCode: 'TRWDD',
			itemName: '土温4',
			itemUnitCode: '℃',
		},
		{
			itemCode: 'TRSDD',
			itemName: '土湿4',
			itemUnitCode: '%',
		},
		{
			itemCode: 'TRWDE',
			itemName: '土温5',
			itemUnitCode: '℃',
		},
		{
			itemCode: 'TRSDE',
			itemName: '土湿5',
			itemUnitCode: '%',
		},
	],
})

// 气象监测
// 头部下拉选
Mock.mock('/wlw/qxfindDeviceByOrgId', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			facId: '16081216',
			facName: '范县气象站1',
		},
		{
			facId: '39786',
			facName: '范县-王楼乡土壤墒情2',
		},
		{
			facId: '16081217',
			facName: '范县气象站2',
		},
		{
			facId: '39785',
			facName: '范县-王楼乡土壤墒情1',
		},
	],
})
// 详情数据
Mock.mock('/wlw/qxlatestDeviceData', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: {
		facId: '16081216',
		dataTime: '2022-05-31 15:04:42',
		sensorVOs: [
			{
				key: 'FS',
				value: '7.1',
				name: '风速',
				unitName: 'm/s',
				alarmStatus: 0,
			},
			{
				key: 'JYL',
				value: '0.0',
				name: '降雨量',
				unitName: 'mm/min',
				alarmStatus: 0,
			},
			{
				key: 'OUTWD',
				value: '32.2',
				name: '室外温度',
				unitName: '℃',
				alarmStatus: 0,
			},
			{
				key: 'TRWD',
				value: '22.7',
				name: '土壤温度',
				unitName: '℃',
				alarmStatus: 0,
			},
			{
				key: 'JYLDAY',
				value: '0.0',
				name: '日雨量累计',
				unitName: 'mm',
				alarmStatus: 0,
			},
			{
				key: 'TRWDA',
				value: '139',
				name: '土温1',
				unitName: '℃',
				alarmStatus: 0,
			},
		],
	},
})
// 采集项
Mock.mock('/wlw/qxgetDeviceItems', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			itemCode: 'FS',
			itemName: '风速',
			itemUnitCode: 'm/s',
		},
		{
			itemCode: 'JYL',
			itemName: '降雨量',
			itemUnitCode: 'mm/min',
		},
		{
			itemCode: 'OUTWD',
			itemName: '室外温度',
			itemUnitCode: '℃',
		},
		{
			itemCode: 'TRWD',
			itemName: '土壤温度',
			itemUnitCode: '℃',
		},
		{
			itemCode: 'JYLDAY',
			itemName: '日雨量累计',
			itemUnitCode: 'mm',
		},
		{
			itemCode: 'TRWDA',
			itemName: '土温1',
			itemUnitCode: '℃',
		},
	],
})

// 远程诊断
Mock.mock('/wlw/findOrgVideoTree', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			orgName: '大棚02',
			orgId: 'ade777e3-abc6-4e16-9b60-bd5bb64707d0',
			orgCode: '342-1-1-3',
			parentOrgCode: '342-1-1',
			childOrgs: [],
			videos: [
				{
					id: '1301712918287040513',
					orgId: 'ade777e3-abc6-4e16-9b60-bd5bb64707d0',
					vname: '监控2',
					vcode: '1000015$1$0$0',
					vurl: 'http://112.6.122.38:7086/live/cameraid/1000015%240/substream/2.m3u8',
					createUser: 'f5d60b89-1102-44c1-8f66-1273dbd704de',
					createTime: [2020, 9, 4, 10, 45, 19, 33000000],
					type: 0,
					netOrgId: '0dfc7a8c-65ba-45ce-9a39-49b57f6f7d14',
					delFlag: '1',
				},
			],
		},
	],
})

// 水肥监测
// 头部下拉选
Mock.mock('/wlw/sffindDeviceByOrgId', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			id: 28,
			deviceName: '博润二',
			deviceNo: 'YUANFENG001',
			projectId: 25,
			deviceAddr: '北京',
			createDate: [2021, 11, 30, 8, 23, 53, 701000000],
			sid: 'ModbusRTU',
			subscribetopic: '/usr/plcnet/www/bysz/com/28/edge/u',
			publishtopic: '/usr/plcnet/www/bysz/com/28/edge/d',
		},
	],
})
// 详情数据
Mock.mock('/wlw/sflatestDeviceStateData', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: {
		d: [
			{
				sid: 'ModbusRTU',
				pid: 'A1',
				pidDesc: 'A阀状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2',
				pidDesc: 'B阀状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A3',
				pidDesc: 'C阀状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A4',
				pidDesc: '施肥泵状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A5',
				pidDesc: '灌溉泵状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A6',
				pidDesc: '搅拌1状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A7',
				pidDesc: '搅拌2状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A8',
				pidDesc: '搅拌3状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A9',
				pidDesc: '1区阀门状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A10',
				pidDesc: '2区阀门状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A11',
				pidDesc: '3区阀门状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A12',
				pidDesc: '4区阀门状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A13',
				pidDesc: '5区阀门状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A14',
				pidDesc: '6区阀门状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A15',
				pidDesc: '7区阀门状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A16',
				pidDesc: '8区阀门状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A17',
				pidDesc: '9区阀门状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A18',
				pidDesc: '10区阀门状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A19',
				pidDesc: '11区阀门状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A20',
				pidDesc: '12区阀门状态',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1016',
				pidDesc: 'EC当前值',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1018',
				pidDesc: 'PH当前值',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1124',
				pidDesc: '灌区时控索引',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1198',
				pidDesc: 'EC上限',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1200',
				pidDesc: 'EC下限',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1202',
				pidDesc: 'PH上限',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1204',
				pidDesc: 'PH下限',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1210',
				pidDesc: '二氧化碳',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1211',
				pidDesc: '空气相对湿度',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1212',
				pidDesc: '空气温度',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1213',
				pidDesc: '土壤湿度',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1214',
				pidDesc: '土壤温度',
				readWriteMode: 0,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1215',
				pidDesc: '光照',
				readWriteMode: 0,
				success: false,
			},
		],
	},
})
// 可执行指令
Mock.mock('/wlw/sflatestDeviceActionData', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: {
		d: [
			{
				sid: 'ModbusRTU',
				pid: 'A2001',
				pidDesc: '轮灌一键启动',
				readWriteMode: 2,
				success: true,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2004',
				pidDesc: '一键停止',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2131',
				pidDesc: '1区轮灌激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2132',
				pidDesc: '2区轮灌激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2133',
				pidDesc: '3区轮灌激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2134',
				pidDesc: '4区轮灌激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2135',
				pidDesc: '5区轮灌激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2136',
				pidDesc: '6区轮灌激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2137',
				pidDesc: '7区轮灌激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2138',
				pidDesc: '8区轮灌激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2139',
				pidDesc: '9区轮灌激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2140',
				pidDesc: '10区轮灌激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2141',
				pidDesc: '11区轮灌激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2142',
				pidDesc: '12区轮灌激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2201',
				pidDesc: 'A吸肥阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2202',
				pidDesc: 'B吸肥阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2203',
				pidDesc: 'C吸肥阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2204',
				pidDesc: '施肥泵手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2205',
				pidDesc: '灌溉泵手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2211',
				pidDesc: '1区电磁阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2212',
				pidDesc: '2区电磁阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2213',
				pidDesc: '3区电磁阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2214',
				pidDesc: '4区电磁阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2215',
				pidDesc: '5区电磁阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2216',
				pidDesc: '6区电磁阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2217',
				pidDesc: '7区电磁阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2218',
				pidDesc: '8区电磁阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2219',
				pidDesc: '9区电磁阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2220',
				pidDesc: '10区电磁阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2221',
				pidDesc: '11区电磁阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2222',
				pidDesc: '12区电磁阀手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2227',
				pidDesc: '搅拌1手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2228',
				pidDesc: '搅拌2手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2229',
				pidDesc: '搅拌3手动按钮',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2230',
				pidDesc: '调酸激活开关',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2231',
				pidDesc: '罐区时控定时激活',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2234',
				pidDesc: '罐区时控1组开关',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2235',
				pidDesc: '罐区时控2组开关',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A2236',
				pidDesc: '罐区时控3组开关',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'A7001',
				pidDesc: '施肥开关',
				readWriteMode: 2,
				success: false,
			},
			{
				sid: 'ModbusRTU',
				pid: 'B1012',
				pidDesc: '灌溉时长计时',
				readWriteMode: 2,
				success: false,
			},
		],
	},
})

Mock.mock('/wlw/sfactionOneCommand', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: {
		d: [
			{
				sid: 'ModbusRTU',
				pid: 'A1',
				v: '1',
				readWriteMode: 2,
				success: false,
			},
		],
	},
})

// 虫情监测

Mock.mock('/wlw/cqfindDeviceByOrgId', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			deviceCode: 'G95799392',
			deviceName: '虫情1',
		},
		{
			deviceCode: 'G95799362',
			deviceName: '虫情2',
		},
		{
			deviceCode: 'G95799367',
			deviceName: '虫情3',
		},
		{
			deviceCode: 'G95799398',
			deviceName: '虫情4',
		},
		{
			deviceCode: 'G95799372',
			deviceName: '虫情5',
		},
		{
			deviceCode: 'G95799387',
			deviceName: '虫情6',
		},
		{
			deviceCode: 'G95799393',
			deviceName: '虫情7',
		},
		{
			deviceCode: 'G95799373',
			deviceName: '虫情8',
		},
		{
			deviceCode: 'G95799368',
			deviceName: '虫情9',
		},
		{
			deviceCode: 'G95799399',
			deviceName: '虫情10',
		},
	],
})

// 虫情图片
Mock.mock('/wlw/cqfindDevicePictureByDeviceCode', 'post', (req) => {
	let options = JSON.parse(req.body)
	let pageSize = options.pageSize
	console.log(options)
	let datas = []
	for (let i = 0; i < pageSize; i++) {
		datas.push({
			blastPicture: 'https://img1.baidu.com/it/u=3748646381,2353166638&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=331',
			photoTime: '2022',
		})
	}
	return {
		meta: {
			success: true,
			message: 'ok',
		},
		data: {
			total: '52',
			size: pageSize,
			current: '1',
			orders: [],
			optimizeCountSql: true,
			hitCount: false,
			searchCount: true,
			pages: '3',
			records: datas,
		},
	}
})
