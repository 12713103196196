<template>
	<div id="app">
		<router-view />
		<div class="app-wrap">
			<el-drawer
				title="告警提示"
				:visible.sync="drawer"
				direction="btt"
				:modal="false"
				custom-class="notify-warn"
				size="15%"
			>
				<span
					>当前{{ warnItem.name }}为{{ warnMsg.dataItemValue }}{{ warnItem.unit }},已{{
						warnMsg.alarm_type == 'down' ? '低于' : '高于'
					}}设置{{ warnMsg.alarm_type == 'down' ? '下限' : '上限' }}{{ warnMsg.dataItemAlarmValue
					}}{{ warnItem.unit }}。请及时查看！</span
				>
			</el-drawer>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			websocket: null, // 连接的socket
			drawer: false,
			warnMsg: {}, // 推送的告警信息
			propList: [
				{
					dataItemName: '降雨量',
					dataItemCode: 'JYL',
					dataItemUnit: 'mm/min',
				},
				{
					dataItemName: '风速',
					dataItemCode: 'FS',
					dataItemUnit: 'm/s',
				},
				{
					dataItemName: 'CO2',
					dataItemCode: 'CO2',
					dataItemUnit: 'ppm',
				},
				{
					dataItemName: '光照度',
					dataItemCode: 'KGD',
					dataItemUnit: 'kLux',
				},
				{
					dataItemName: '土壤温度',
					dataItemCode: 'TRWD',
					dataItemUnit: '°C',
				},
				{
					dataItemName: '土壤温度',
					dataItemCode: 'TW',
					dataItemUnit: '°C',
				},
				{
					dataItemName: '土壤湿度',
					dataItemCode: 'TS',
					dataItemUnit: '%RH',
				},
				{
					dataItemName: '土壤湿度',
					dataItemCode: 'TRSD',
					dataItemUnit: '%RH',
				},
				{
					dataItemName: '温度',
					dataItemCode: 'KQWD',
					dataItemUnit: '°C',
				},
				{
					dataItemName: '湿度',
					dataItemCode: 'KQSD',
					dataItemUnit: '%RH',
				},
				{
					dataItemName: '大气压力',
					dataItemCode: 'DQYL',
					dataItemUnit: 'pa',
				},
				{
					dataItemName: '累计有功电量',
					dataItemCode: 'LJDL',
					dataItemUnit: 'kW/h',
				},
				{
					dataItemName: 'A相电压',
					dataItemCode: 'AXDY',
					dataItemUnit: 'V',
				},
				{
					dataItemName: 'B相电压',
					dataItemCode: 'BXDY',
					dataItemUnit: 'V',
				},
				{
					dataItemName: 'C相电压',
					dataItemCode: 'CXDY',
					dataItemUnit: 'V',
				},
				{
					dataItemName: 'A相电流',
					dataItemCode: 'AXDL',
					dataItemUnit: 'A',
				},
				{
					dataItemName: 'B相电流',
					dataItemCode: 'BXDL',
					dataItemUnit: 'A',
				},
				{
					dataItemName: 'C相电流',
					dataItemCode: 'CXDL',
					dataItemUnit: 'A',
				},
				{
					dataItemName: '净累计水量',
					dataItemCode: 'JLJSL',
					dataItemUnit: 'm³',
				},
				{
					dataItemName: '瞬时水流量',
					dataItemCode: 'SSSLL',
					dataItemUnit: 'm³/h',
				},
				{
					dataItemName: '泵前压力',
					dataItemCode: 'BQYL',
					dataItemUnit: 'MPa',
				},
				{
					dataItemName: '泵后压力',
					dataItemCode: 'BHYL',
					dataItemUnit: 'MPa',
				},
				{
					dataItemName: '航向角度',
					dataItemCode: 'HXJD',
					dataItemUnit: '°',
				},
				{
					dataItemName: 'EC',
					dataItemCode: 'EC',
					dataItemUnit: '%',
				},
					{
					dataItemName: 'PH',
					dataItemCode: 'PH',
					dataItemUnit: '%',
				},
				{
					dataItemName: '氮含量',
					dataItemCode: 'DHL',
					dataItemUnit: '%',
				},
				{
					dataItemName: '磷含量',
					dataItemCode: 'LHL',
					dataItemUnit: '%',
				},
				{
					dataItemName: '钾含量',
					dataItemCode: 'JHL',
					dataItemUnit: '%',
				},
			],

			demoTime: null,
			indexs: 0,
		}
	},
	computed: {
		warnItem() {
			console.log(this.warnMsg, 'this.warnMsg')
			let findIndexs = this.propList.findIndex((item) => {
				return item.dataItemCode == this.warnMsg.dataItemCode
			})
			console.log(findIndexs, 'findIndexs')
			let params = {}
			if (findIndexs > -1) {
				params = {
					name: this.propList[findIndexs]['dataItemName'],
					unit: this.propList[findIndexs]['dataItemUnit'],
				}
			} else {
				params = {
					name: '--',
					unit: '--',
				}
			}
			return params
		},
	},
	mounted() {
		// 接收登录
		this.$bus.$on('hadLogin', () => {
			console.log('hadLogin')
			let token = window.localStorage.getItem('wlwtoken') || ''
			// 判断登录状态
			if (token && token != '') {
				// socket连接
				this.websocketfun()
			}
		})
		// 退出-关闭连接
		this.$bus.$on('layoutLogin', () => {
			console.log('layoutLogin')
			this.websocket.close()
			setTimeout(() => {
				this.websocket = null
			}, 500)
		})
		// 主要针对刷新的状态
		let token = window.localStorage.getItem('wlwtoken') || ''
		// 判断登录状态
		if (token && token != '') {
			// socket连接
			this.websocketfun()
		}
		// TODO  测试推送
		// this.demoTime = setTimeout(() => {
		//   let params = {
		//     data: {
		//       deviceNum: "22120900081",
		//       deviceCode: "22120900081",
		//       deviceId: 1284,
		//       deviceName: "智能放风机3",
		//       deviceBizStatus: "normal",
		//     },
		//     srvCode: 1,
		//     srvVer: 1,
		//     // srvTs: "1675329568337",
		//     userName: "15138115712",
		//     srvTs: Date.parse(new Date()),
		//   };
		//   console.log('sendWarn')
		//   // this.setSocketWarn(params)
		//   this.$store.commit("setSocketOnline", params);
		//   // this.warnMsg = params.data
		//   // this.drawer = true
		//   // this.indexs += 1
		//   // setTimeout(() => {
		//   // 	this.drawer = false
		//   // }, 6000)
		// }, 3000);
	},
	methods: {
		websocketfun() {
			if (typeof WebSocket != 'function') {
				return this.$message('您的浏览器不支持Websocket通信协议', 'warning')
			}

			console.log('websocketfun')
			let token = window.localStorage.getItem('wlwtoken') || ''
			const that = this
			if (this.websocket) {
				this.websocket.close()
				this.websocket = null
			}

			if ('WebSocket' in window) {
				// 当前浏览器Location对象
				const nowLocation = window.location
				// 协议 => http、https
				const protocol = nowLocation.protocol
				// host => ip:port
				const host = nowLocation.host
				const websocket_pattern = '/agro-universe'
				const webSocketApiUrl =
					(protocol.startsWith('https') ? 'wss://' : 'ws://') +
					host +
					websocket_pattern +
					'/ws-connect?satoken=' +
					token
				this.websocket = new WebSocket(webSocketApiUrl)
				// this.websocket = new WebSocket(
				//   "ws://222.133.14.246:28089/agro-universe/ws-connect?satoken=" + token
				//   // "ws://82.157.58.19:8083/agro-universe/ws-connect?satoken=" + token
				//   // "wss://wlw.hnyfkj.com/agro-universe/ws-connect?satoken=" + token
				// );
			}

			this.websocket.onopen = function () {
				console.log('连接成功')
			}

			this.websocket.onclose = function () {
				console.log('退出连接')
			}

			this.websocket.onmessage = function (event) {
				console.log('收到消息:websocket' + event.data)
				// 弹窗
				let datanotice = JSON.parse(event.data)
				// datanotice = {
				// 	srvCode: 1,
				// 	srvVer: 1,
				// 	srvTs: '1666747173383',
				// 	data: {
				// 		deviceNum: '22102501000',
				// 		deviceCode: '22102501000',
				// 		deviceId: 752,
				// 		deviceName: '智能卷被机',
				// 		deviceStatus: 'offline',
				// 		lastOfflineTime: 1666747173383,
				// 		offlineDetails: 'iot通知设备离线!',
				// 	},
				// }
				let params = {
					srvTs: datanotice.srvTs, // 时间戳，为确保每次推送都能被监听到
					data: datanotice.data,
				}
				/*
                * @description: 区分类型
                srvCode 业务编码 1 在线离线 | 2 执行设备实时状态 | 3 行程设备时长自动上报 | 4查询消息中心|5 告警推送消息
                */

				if (datanotice.srvCode == 1) {
					that.$store.commit('setSocketOnline', params)
				} else if (datanotice.srvCode == 2) {
					that.$store.commit('setSocketStatus', params)
				} else if (datanotice.srvCode == 3) {
					that.$store.commit('setSocketTime', params)
				} else if (datanotice.srvCode == 4) {
					that.$store.commit('setSocketMsg', params)
				} else if (datanotice.srvCode == 5) {
					// 告警信息，当前页处理
					that.$store.commit('setSocketWarn', params)
					that.warnMsg = params.data
					that.drawer = true
					// 弹出提示定时隐藏
					setTimeout(() => {
						that.drawer = false
					}, 10000)
				}
			}

			this.websocket.onerror = function () {
				console.log('连接出错')
			}

			window.onbeforeunload = function () {
				// websocket.close(num);
				that.websocket.close()
				this.websocket = null
			}
		},
	},
	// 页面销毁，断开连接
	beforeDestroy() {
		if (this.websocket) {
			this.websocket.close()
			this.websocket = null
		}
		this.$bus.$off('hadLogin')
		this.$bus.$off('layoutLogin')

		if (this.demoTime) {
			clearInterval(this.demoTime)
			this.demoTime = null
		}
	},
}
</script>

<style lang="scss">
@import './assets/fonts/font.css';
@import './assets/css/global.scss';
@import '@/assets/css/formtable.scss';
html,
body,
#app {
	width: 100%;
	height: 100%;
	min-width: 1300px;
	min-height: 100%;
}

.app-wrap {
	.el-drawer__wrapper {
		left: 80%;
		.el-drawer.btt {
			left: auto;
			height: 18%;
			border-radius: 10px 10px 0 0;
		}
		.el-drawer__header {
			margin-bottom: 0;
			span {
				color: #ff8771;
				font-weight: bold;
			}
		}
		.el-drawer__body {
			padding: 0.25rem;
		}
	}
}
</style>
