// 任务管理
import Mock from 'mockjs'
const Random = Mock.Random
// 任务操作列表
Mock.mock('/wlw/taskSetTableList', 'post', (req) => {
	let options = JSON.parse(req.body)
	let pageSize = options.pageSize
	console.log(options)
	let datas = []
	for (let i = 0; i < pageSize; i++) {
		datas.push({
			ids: Random.id(),
			status: Random.integer(0, 3),
			startTime: Random.datetime(),
			endTime: Random.datetime(),
			names: Random.csentence(),
		})
	}
	return {
		meta: {
			success: true,
		},
		data: {
			total: '20',
			size: '10',
			current: '1',
			orders: [],
			optimizeCountSql: true,
			hitCount: false,
			searchCount: true,
			pages: '2',
			records: datas,
		},
	}
})
// 历史记录列表
Mock.mock('/wlw/taskHisTableList', 'post', (req) => {
	let options = JSON.parse(req.body)
	let pageSize = options.pageSize
	console.log(options)
	let datas = []
	for (let i = 0; i < pageSize; i++) {
		datas.push({
			ids: Random.id(),
			status: Random.integer(0, 3),
			startTime: Random.datetime(),
			endTime: Random.datetime(),
			names: Random.csentence(),
			setTime: Random.datetime(),
		})
	}
	return {
		total: '20',
		size: '10',
		current: '1',
		orders: [],
		optimizeCountSql: true,
		hitCount: false,
		searchCount: true,
		pages: '2',
		records: datas,
	}
})
