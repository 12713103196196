<template>
	<div
		class="player"
		v-loading="isLoading"
		element-loading-text="视频加载中"
		element-loading-background="rgba(0, 0, 0, 0.8)"
	>
		<!-- <div class="player-div" v-if="isLoading">加载中......</div> -->
		<div class="flex flex_col flex_a_c flex_ju_c player-div play-error" v-if="isEmpty">
			<img class="mbbtn-close" @click="closeThis" src="/close.png" alt="" />
			<img class="img_noplay" src="@/assets/image/videos/noplay.png" alt="" />
			<div class="font_16 c_white">网络错误，请检查网络配置或者播放链接是否正确</div>
		</div>
		<div class="player-div" v-if="!isEmpty">
			<iframe
				:src="playUrl"
				frameBorder="0"
				marginHeight="0"
				marginWidth="0"
				style="
					position: absolute;
					background: block,
					z-index: -1;
					top: 0;
					left: 0;
					isibility: inherit;
					filter: alpha(opacity=0);
					width: 100%;
					height: 100%;
				"
			/>
			<div class="mb-divs" v-if="isShowclose">
				<iframe
					class="vlcIframe"
					:src="'./mb.html?code=' + code"
					frameBorder="0"
					marginHeight="0"
					marginWidth="0"
					style="
						position: absolute;
						background: transparent;
						visibility: inherit;
						top: 0px;
						right: 0;
						width: 0.38rem;
						height: 0.38rem;
						z-index: 5;
					"
				></iframe>
			</div>
		</div>
	</div>
</template>

<script>
import bus from '@/api/bus'
export default {
	data() {
		return {
			playUrl: '',
			isLoading: true, // 是否是视频请求中状态
			isEmpty: false, // 返回的视频地址是否为空
			urls: 'http://222.133.14.246:15120',
		}
	},
	props: {
		code: {
			type: String,
			default: '',
		},
		isShowclose: {
			type: Boolean,
			default: true,
		},
	},
	watch: {
		code: {
			handler(val, oldval) {
				if (oldval && this.isLoading) {
					this.$message('上个视频正在加载中，请稍后')
				} else {
					this.isLoading = true
					this.getUrl(val)
				}
			},
			// 深度观察监听
			immediate: true,
		},
	},
	mounted() {
		// 监听子页面点击穿透事件
		window.addEventListener('message', this.receiveMessage)
	},
	methods: {
		// 获取url地址
		getUrl(code) {
			// TODO 暂时先把地址调整为拼接形式
			// rtsp://221.13.155.38:9090/dss/monitor/param?cameraid=1000185%240&substream=1
			// let codes = '1000185$1$0$0'
			let codeSplit = code.split('$')
			let codePin = codeSplit[0]
			// 最后一位
			let codeEndNum = codeSplit.slice(-1)

			let endUrl = `rtsp://221.13.155.38:9090/dss/monitor/param?cameraid=${codePin}%24${codeEndNum}`
			this.$nextTick(() => {
				let urls = './video.html?streamUrl=' + encodeURI(endUrl)
				this.playUrl = urls
			})
			this.isLoading = false

			// let params = {
			// 	cameraId: code,
			// 	mediaType: 3,
			// 	streamType: 1,
			// 	trackId: 701,
			// 	transType: 1,
			// 	usedVCS: 0,
			// 	vcsVideocodec: 1,
			// 	vcsResolution: 1,
			// 	vcsFps: 1,
			// 	vcsBps: 1,
			// }
			// this.$http.postAction(this.$api.videoUrl, params, this.urls).then((res) => {
			// 	if (res.code == '0' && res.msg == 'success' && res.data != '') {
			// 		this.isLoading = false
			// 		this.isEmpty = false
			// 		this.$nextTick(() => {
			// 			let urls = './video.html?streamUrl=' + encodeURI(res.data)
			// 			this.playUrl = urls
			// 		})
			// 	} else {
			// 		this.isLoading = false
			// 		this.isEmpty = true
			// 	}
			// })
		},
		receiveMessage(e) {
			let needCloseCode = e.data.data
			if (this.code == needCloseCode) {
				bus.$emit('closeCode', this.code)
			}
		},
		closeThis() {
			bus.$emit('closeCode', this.code)
		},
	},
}
</script>

<style lang="scss" scoped>
.player {
	background-color: black;
	height: 100%;
	/* border: 1px solid white; */
	color: white;
	text-align: center;
	position: relative;
	.el-loading-spinner {
		.el-loading-text {
			font-size: 0.23rem;
			color: #fff;
			margin-top: 0.13rem;
		}
		.path {
			stroke: #fff;
		}
	}
}
.player-div {
	height: 100%;
	color: white;
	text-align: center;
	position: relative;
}
.play-error {
	width: 100%;
	height: 100%;
	background-color: #343434;
	.mbbtn-close {
		position: absolute;
		background: transparent;
		visibility: inherit;
		top: 0px;
		right: 0;
		width: 0.38rem;
		height: 0.38rem;
		z-index: 5;
		cursor: pointer;
	}
	.img_noplay {
		width: 0.76rem;
		height: 0.64rem;
		margin-bottom: 0.46rem;
	}
}
</style>
<style lang="scss">
.player {
	.el-loading-spinner {
		.el-loading-text {
			font-size: 0.23rem;
			color: #fff;
			margin-top: 0.13rem;
		}
		.path {
			stroke: #fff;
		}
	}
}
</style>
