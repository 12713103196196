<template>
	<div class="flex flex_ju_sb flex_a_c c_six font_16 headers">
		<!-- 左侧 -->
		<div class="flex flex_a_end">
			<img class="icon_logo" src="@/assets/image/index/icon_logo.png" alt="" />
			<!-- 面包屑 -->
			<bread />
		</div>
		<!-- 右侧 -->
		<div class="flex flex_a_c header-right">
			<div class="flex flex_a_c header-users">
				<img class="icon_use" src="@/assets/image/index/icon_use.png" alt="" />
				<span>欢迎您：{{ userName }}</span>
			</div>
			<!-- <div class="header-line">{{ userInfo.orgName || "--" }}</div> -->
			<!-- <div class="header-line cursor" @click="toLoginin">后台管理</div> -->
			<div class="header-line cursor" @click="exitClick">退出</div>
			<!-- 暂无帮助文档 -->
			<!-- <div class="header-line cursor">帮助文档</div> -->

			<div class="header-line">{{ nowTime }}</div>
		</div>
	</div>
</template>

<script>
/**
 * @description 头部
 */

import Bread from '../Breadcrumb/index.vue' //引入组件
export default {
	components: {
		Bread,
	},
	data() {
		return {
			nowTimer: null,
			nowTime: '',
			userName: '--',
			userInfo: {},
		}
	},
	beforeDestroy() {
		// 清除定时器
		if (this.nowTimer) {
			clearInterval(this.nowTimer)
		}
	},
	mounted() {
		// 时间定时刷新
		const _this = this
		// 用户信息获取
		this.makeUserInfo()
		this.getUserInfo();
		_this.nowTime = _this.handleData()
		this.nowTimer = setInterval(() => {
			_this.nowTime = _this.handleData()
		}, 1000)
		this.$bus.$on("getUserInfoAll",this.getUserInfo)
	},
	methods: {
		// 用户信息获取
		makeUserInfo() {
			let names = window.localStorage.getItem('wlwuserName')
			// let userInfo = window.localStorage.getItem('wlwOrg')
			
			// this.userInfo = JSON.parse(userInfo) || {}
		},
		// 获取用户信息
		async getUserInfo() {
			await this.$http.getAction(this.$api.getUserInfo).then((res) => {
				if (res.code == 200) {
					let datas = res.data || {}
					this.userName = datas.userName ||datas.loginName
				}
			})
		},
		// 当前时间
		handleData() {
			const timeDate = new Date()
			const hour = timeDate.getHours()
			const min = timeDate.getMinutes().toString().padStart(2, 0)
			const s = timeDate.getSeconds().toString().padStart(2, 0)
			let datas = this.getNowDate()
			return `${hour}:${min}:${s} ${datas}`
		},
		// 点击退出
		exitClick() {
			this.$confirm('您确定要退出该账号吗？', '退出登录', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				customClass: 'logOut-dialog',
				cancelButtonClass: 'btn-cancel',
				confirmButtonClass: 'btn-confirm',
			})
				.then(() => {
					window.localStorage.removeItem('wlwtoken')
					window.localStorage.removeItem('wlwuserName')
					let users = this.getItem('wlwUser')
					let userInfo = this.getItem('wlwUserInfo')
					let orgs = this.getItem('wlwOrg')
					if (users) {
						window.localStorage.removeItem('wlwUser')
					}
					if (userInfo) {
						window.localStorage.removeItem('wlwUserInfo')
					}
					if (orgs) {
						window.localStorage.removeItem('wlwOrg')
					}
					this.$message('退出成功', 'success')
					setTimeout(() => {
						this.$router.push('/login')
					}, 200)
					// 事件发送，进行socket关闭
					this.$bus.$emit('layoutLogin')
					// 走接口退出，暂时不用
					// this.loyout()
				})
				.catch(() => {
					// this.$message('已取消退出', 'info')
				})
		},
		async loyout() {
			await this.$http
				.postAction(this.$api.apiLogout)
				.then((res) => {
					if (res.meta.success) {
						window.localStorage.removeItem('wlwtoken')
						window.localStorage.removeItem('wlwuserName')
						let users = this.getItem('wlwUser')
						let userInfo = this.getItem('wlwUserInfo')
						let orgs = this.getItem('wlwOrg')
						if (users) {
							window.localStorage.removeItem('wlwUser')
						}
						if (userInfo) {
							window.localStorage.removeItem('wlwUserInfo')
						}
						if (orgs) {
							window.localStorage.removeItem('wlwOrg')
						}

						this.$message('退出成功', 'success')
						setTimeout(() => {
							this.$router.push('/login')
						}, 200)
					} else {
						this.$message(res.meta.message, 'error')
					}
				})
				.catch(() => {
					this.$message('服务出错，请稍后重试', 'error')
				})
		},
		toLoginin() {
			// window.open('https://ibms.hnyfkj.com', '_blank')
			let token = window.localStorage.getItem('wlwtoken')
			//   window.open(
			//     "https://ibms.hnyfkj.com/loginin?X-Token=" +
			//       token +
			//       "&firstPage=https://ibms.hnyfkj.com/vue",
			//     "_blank"
			//   );
			;(window.location.href =
				'http://ibms.hnyfkj.com/loginin?X-Token=' +
				token +
				'&firstPage=http://ibms.hnyfkj.com/vue'),
				'_blank'

			// (window.location.href =
			//   "http://nywlw.hnyfkj.com/loginin?X-Token=" +
			//   token +
			//   "&firstPage=http://nywlw.hnyfkj.com/nywlw"),
			//   "_blank";

			// window.open(
			// 'http://hnw.vaiwan.com/loginin?X-Token=' + token + '&firstPage=http://192.168.100.113:8080',
			// 	'http://hnw.vaiwan.com/loginin?X-Token=' + token + '&firstPage=https://ibms.hnyfkj.com/vue',
			// 	'_blank'
			// )

			// http://hnw.vaiwan.com/loginin?X-Token=e3a2d38a-aac1-4c8a-adcd-65b7ac7831b2&firstPage=https://ibms.hnyfkj.com/vue/#/home
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/header.scss';
@import '@/assets/css/del_dialog.scss';
</style>
