<template>
	<div class="qx-box flex">
		<!-- 左边 -->
		<div class="left-box">
			<div class="hea-box">
				我的区域
				<div class="btn-box">
					<el-tooltip class="item" effect="dark" content="我的设备" placement="bottom-start">
						<div class="item">
							<img
								v-show="mySbType == 0 || mySbType == 2"
								src="@/assets/image/product/wode.png"
								alt=""
								@click="mySbTypeClick(1)"
							/>
							<img
								v-show="mySbType == 1"
								src="@/assets/image/product/wodexuanzhong.png"
								alt=""
								@click="mySbTypeClick(0)"
							/>
						</div>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="他人共享" placement="bottom-end">
						<div class="item">
							<img
								v-show="mySbType == 0 || mySbType == 1"
								src="@/assets/image/product/taren.png"
								alt=""
								@click="mySbTypeClick(2)"
							/>
							<img v-show="mySbType == 2" src="@/assets/image/product/gongxiang.png" alt="" @click="mySbTypeClick(0)" />
						</div>
					</el-tooltip>
				</div>
			</div>
			<div class="trees-box" v-if="treeData.length">
				<el-tree
					ref="trees"
					:data="treeData"
					:props="defaultProps"
					highlight-current
					:default-expanded-keys="[morenzankai]"
					node-key="id"
					@node-click="handleNodeClick"
				>
					<template #default="{ node, data }">
						<span class="custom-tree-node">
							<span v-if="data.children && data.children.length"
								>{{ node.label }}（<span class="on-color">{{ data.online }}</span
								>/{{ data.allNum }}）</span
							>
							<span
								v-else
								:class="{
									'tree-choose-active': playIdObj.id == data.id,
								}"
								><img
									class="tree-icon"
									v-if="data.online"
									src="../../assets/image/product/icon-online.png"
									alt="" /><img class="tree-icon" v-else src="../../assets/image/product/icon-offlInne.png" alt="" />{{
									node.label
								}}
								<img
									class="tree-icon-soure"
									v-show="data.source == 1"
									src="@/assets/image/product/tarengongxing.png"
									alt=""
							/></span>
						</span>
					</template>
				</el-tree>
			</div>
			<div class="table-empty flex flex_col flex_ju_c flex_a_c trees-box" v-else>
				<img class="table-noimg" src="@/assets/image/home/ZWT.png" alt="" />
				<div class="table-emptyword">暂无数据</div>
			</div>
		</div>
		<!-- 右边 -->
		<div class="right-box">
			<div class="righttop-box">
				<div class="pic-box">
					<img src="@/assets/image/qx/icon_bg2.png" alt="" />
				</div>
				<div class="data-box">
					<div class="box-header">
						气象实时数据
						<div class="h-right">
							<span v-show="collectData.online">采集时间：{{ collectData.time || '--' }}</span
							><img
								v-show="collectData.online"
								class="shuaxin"
								src="../../assets/image/product/shuaxin.png"
								alt=""
								@click="deviceSensorData"
							/>
							<span v-show="!collectData.online">设备已离线</span>
							<img
								class="shezhi"
								:class="{ 'shezhi-lixian': !collectData.online }"
								src="@/assets/image/product/shezhi2.png"
								alt=""
								@click="openSetUp"
							/>
						</div>
					</div>
					<div class="content-box">
						<div class="content" v-if="collectData.list.length">
							<div
								class="item"
								:class="{
									mr0: (index + 1) % 6 == 0,
									mb0: isMaginBottom(index, collectData.list),
								}"
								v-for="(item, index) in collectData.list"
								:key="index"
							>
								<img :src="item.path" alt="" />
								<div class="name">{{ item.dataItemName }}</div>
								<div class="value">
									{{ item.dataItemValue
									}}<span class="unit" v-show="item.dataItemName != '风向'">{{ item.dataItemUnit }}</span>
								</div>
							</div>
						</div>
						<div class="content-no" v-else>
							<img src="@/assets/image/home/ZWT.png" alt="" />
						</div>
					</div>
				</div>
			</div>
			<div class="rightbottom-box">
				<div class="box-header">
					气象统计数据
					<div class="h-right">
						<el-form
							:model="tjFrom"
							ref="warnform"
							:inline="true"
							class="flex flex_ju_sb warnform-inline rb-form"
							:style="{ paddingRight: 0 + 'px' }"
						>
							<div class="form-inputs">
								<el-form-item label="" :style="{ marginRight: 40 + 'px' }">
									<el-select placeholder="请选择" v-model="tjFrom.collect">
										<el-option
											v-for="(item, index) in collectList"
											:key="index"
											:label="item.dataItemName"
											:value="item.dataItemCode"
										></el-option>
									</el-select>
								</el-form-item>

								<el-form-item label="" class="form-picker" :style="{ marginRight: 40 + 'px' }">
									<div class="picker-divs picker-divs-more">
										<el-date-picker
											v-model="tjFrom.startTime"
											size="small"
											type="datetime"
											placeholder="选择日期"
											popper-class="pick-poper"
											class="picker-div"
											format="yyyy-MM-dd HH:mm:ss"
											value-format="yyyy-MM-dd HH:mm:ss"
											prefix-icon=" "
											clear-icon="el-icon-date"
											:clearable="false"
											:picker-options="pickerOptions0"
											:editable="false"
										>
										</el-date-picker>
										<i class="pick-icon el-icon-date"></i>
										<i class="pick-icon-yuan el-icon-time"></i>
									</div>

									<span class="pick-word">至</span>
									<div class="picker-divs picker-divs-more">
										<el-date-picker
											v-model="tjFrom.endTime"
											size="small"
											type="datetime"
											placeholder="选择日期"
											popper-class="pick-poper"
											class="picker-div"
											format="yyyy-MM-dd HH:mm:ss"
											value-format="yyyy-MM-dd HH:mm:ss"
											prefix-icon=" "
											clear-icon="el-icon-date"
											:clearable="false"
											:picker-options="pickerOptions1"
											:editable="false"
										>
										</el-date-picker>
										<i class="pick-icon el-icon-date"></i>
										<i class="pick-icon-yuan el-icon-time"></i>
									</div>
								</el-form-item>
							</div>

							<el-form-item class="form-btns" :style="{ marginRight: 0 + 'px' }">
								<el-button type="primary" @click="searchClick">查询</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<div class="content-box">
					<div class="echart" ref="chartLine" v-show="isLine"></div>
					<div class="table-empty chart-lines flex flex_col flex_a_c flex_ju_c" v-show="!isLine">
						<img class="table-noimg" src="@/assets/image/home/ZWT.png" alt="" />
						<div class="table-emptyword">暂无气象数据</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 告警信息 -->
		<Qixaingalarm ref="Qixaingalarm"></Qixaingalarm>
	</div>
</template>

<script>
import CircularJSON from 'circular-json'
import Qixaingalarm from './components/qixaingalarm.vue'
import { todayTime, GetPreMonthDay, GetNextMonthDay, getFormatDate } from '../../times/moment'
export default {
	components: { Qixaingalarm },
	data() {
		return {
			// 控制我的区域里设备类型的筛选
			mySbType: 0, //0全部，1我的2他人的
			//树形组件数据
			treeData: [],
			// 控制默认展开
			morenzankai: '',
			defaultProps: {
				children: 'children',
				label: 'label',
			},
			// 存放树形组件的选中
			playIdObj: {},
			// 气象设备信息
			qxequipment: {},
			// 采集数据
			collectData: {
				time: '', //采集时间
				online: true, //是否在线
				list: [],
			},
			list: null,
			// 图标数组
			imgList: [
				{
					dataItemCode: 'WD',
					path: require('@/assets/image/qxicon/icon_wd.png'),
				},
				{
					dataItemCode: 'SD',
					path: require('@/assets/image/qxicon/icon_sd.png'),
				},
				{
					dataItemCode: 'TW',
					path: require('@/assets/image/qxicon/icon_wd.png'),
				},
				{
					dataItemCode: 'TS',
					path: require('@/assets/image/qxicon/icon_sd.png'),
				},
				{
					dataItemCode: 'KQWD',
					path: require('@/assets/image/qxicon/icon_wd.png'),
				},
				{
					dataItemCode: 'KQSD',
					path: require('@/assets/image/qxicon/icon_sd.png'),
				},
				{
					dataItemCode: 'YLLJ',
					path: require('@/assets/image/qxicon/icon_yllj.png'),
				},
				{
					dataItemCode: 'JYL',
					path: require('@/assets/image/qxicon/icon_jyl.png'),
				},
				{
					dataItemCode: 'YL',
					path: require('@/assets/image/qxicon/icon_yllj.png'),
				},
				{
					dataItemCode: 'FZYL',
					path: require('@/assets/image/qxicon/icon_jyl.png'),
				},
				{
					dataItemCode: 'DQYL',
					path: require('@/assets/image/qxicon/icon_qy.png'),
				},
				{
					dataItemCode: 'SZQY',
					path: require('@/assets/image/qxicon/icon_qy.png'),
				},
				{
					dataItemCode: 'LDWD',
					path: require('@/assets/image/qxicon/icon_ldwd.png'),
				},
				{
					dataItemCode: 'FS',
					path: require('@/assets/image/qxicon/icon_fs.png'),
				},
				{
					dataItemCode: 'FX',
					path: require('@/assets/image/qxicon/icon_fxx.png'),
				},
				{
					dataItemCode: 'YW',
					path: require('@/assets/image/qxicon/icon_yw.png'),
				},
				{
					dataItemCode: 'ZFL',
					path: require('@/assets/image/qxicon/icon_zfl.png'),
				},
				{
					dataItemCode: 'ZWFS',
					path: require('@/assets/image/qxicon/icon_zwfs.png'),
				},
				{
					dataItemCode: 'GZQD',
					path: require('@/assets/image/qxicon/icon_zwfs.png'),
				},
				{
					dataItemCode: 'ZFS',
					path: require('@/assets/image/qxicon/icon_zfs.png'),
				},
				{
					dataItemCode: 'PM25',
					path: require('@/assets/image/qxicon/2.5.png'),
				},
				{
					dataItemCode: 'PM10',
					path: require('@/assets/image/qxicon/10.png'),
				},
				{
					dataItemCode: 'ZY',
					path: require('@/assets/image/qxicon/zaoyin.png'),
				},
			],
			pickerOptions0: {
				disabledDate: (time) => {
					if (this.tjFrom.endTime) {
						if (this.tjFrom.endTime != '') {
							let num = new Date(this.tjFrom.endTime).getTime()
							return time.getTime() > num
						} else {
						}
					} else {
						return false
					}
				},
			},
			pickerOptions1: {
				disabledDate: (time) => {
					let num = new Date().getTime()
					if (time.getTime() > num) {
						return true
					} else {
						let startNum = new Date(this.tjFrom.startTime).getTime() - 43200000
						if (time.getTime() < startNum) {
							return true
						} else {
							return false
						}
					}
				},
			},
			//采集项数组
			collectList: [],
			//表单参数
			tjFrom: {
				collect: '',
				startTime: '',
				endTime: '',
			},
			isLine: false, // 是否显示折线图
			routerQuery: null,
		}
	},
	mounted() {},
	created() {
		let router = this.$router.currentRoute.query
		if (router) {
			this.routerQuery = router.deviceNum
		} else {
			this.routerQuery = null
		}
		this.$set(this.tjFrom, 'startTime', `${todayTime()} 00:00:00`)
		this.$set(this.tjFrom, 'endTime', `${todayTime()} 23:59:59`)
		this.userDeviceList()
	},
	methods: {
		// 我的区域设备类型切换
		mySbTypeClick(index) {
			this.mySbType = index
		},
		// 判断是否有下编剧
		isMaginBottom(index, list) {
			let num = list.length % 6
			let start = list.length - num + 1
			if (num == 0) {
				if (index + 1 >= list.length - 6) {
					return true
				} else {
					return false
				}
			} else {
				if (index + 1 >= start) {
					return true
				} else {
					return false
				}
			}
		},
		// 查询点击
		searchClick() {
			this.deviceSensorLinedata()
		},
		// 打开设置弹层
		openSetUp() {
			this.$refs.Qixaingalarm.deviceInfo = this.playIdObj
			this.$refs.Qixaingalarm.subDeviceInfo = this.qxequipment
			this.$refs.Qixaingalarm.csList = this.qxequipment.itemList
			this.$refs.Qixaingalarm.drawer = true
		},
		// 折线图
		// 折线图绘制
		lineChart(xData, yData, yName, yUnit) {
			// xData = [1, 2, 3];
			// yData = [1, 2, 30000];
			// yName = "温度";
			// yUnit = "℃";
			const _this = this
			let myChart = this.$echarts.init(this.$refs.chartLine)
			let maxY = Math.max(...yData)
			let symbolSize = 60
			let symbolFont = 12
			if (maxY.length <= 2) {
				symbolSize = 40
				symbolFont = 16
			} else if (maxY.length > 2 && maxY.length <= 3) {
				symbolSize = 50
				symbolFont = 14
			} else if (maxY.length > 3 && maxY.length < 4) {
				symbolSize = 60
				symbolFont = 12
			} else {
				symbolSize = 65
				symbolFont = 12
			}
			let option = {
				tooltip: {
					show: true,
					trigger: 'axis',
					backgroundColor: '#fff',
					padding: [14, 17],
					axisPointer: {
						type: 'line',
						lineStyle: {
							color: '#DADADA',
							type: 'solid',
							width: 2,
						},
					},
					extraCssText:
						'box-shadow: 0px 0px 12px 0px rgba(76, 216, 179, 0.33);text-align:left;font-size:14px;color:#3F3F3F;letter-spacing: 1px;',
					formatter: function (param) {
						let infos = ''
						infos += `<div style="">${param[0].name}</div><div style="text-align:left;margin-top:12px;color:#2DAEA9;">${yName}：<span style="color:#2DAEA9;">${param[0].value}${yUnit}</span></div>`
						return infos
					},
				},
				color: '#2DAEA9',
				grid: {
					left: '6%',
					bottom: '13%',
					top: '20%',
					right: '3%',
				},
				xAxis: {
					type: 'category',
					data: xData,
					// 刻度线
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#EDEDED',
							width: 2,
						},
					},
					axisLabel: {
						color: '#6B6B6B',
						fontSize: 14,
						lineHeight: 30,
					},
					boundaryGap: false,
				},
				yAxis: {
					type: 'value',
					name: '（' + yUnit + '）',
					nameTextStyle: {
						color: '#6B6B6B',
						fontSize: 14,
						align: 'right',
					},
					axisLabel: {
						color: '#6B6B6B',
						fontSize: 14,
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: '#EDEDED',
							width: 2,
						},
					},
				},
				series: [
					{
						type: 'line',
						smooth: true,
						showSymbol: false,
						symbolSize: 10,
						itemStyle: {
							borderColor: '#2DAEA9',
							borderWidth: 8,
						},
						areaStyle: {
							color: 'rgba(45, 174, 169, 0.26)',
						},
						lineStyle: {
							width: 4,
						},
						markPoint: {
							symbolSize: symbolSize,
							label: {
								color: '#fff',
								fontSize: symbolFont,
							},
							data: [
								{
									type: 'max',
									name: '最大值',
									itemStyle: {
										color: '#FF648C',
									},
								},
								{
									type: 'min',
									name: '最小值',
									itemStyle: {
										color: '#3EA2EF',
									},
								},
							],
						},
						markLine: {
							data: [
								{
									type: 'average',
									name: '平均值',
									lineStyle: {
										type: 'solid',
										color: '#FFA800',
									},
									label: {
										position: 'middle',
										formatter: '平均值:{c}',
										color: '#FFA800',
										distance: 10,
										fontSize: 16,
									},
									symbol: 'none',
								},
							],
						},
						data: yData,
					},
				],
			}
			myChart.clear()
			myChart.setOption(option)
			window.addEventListener('resize', function () {
				myChart.resize()
			})
		},

		// 树形组件点击事件
		handleNodeClick(data) {
			console.log(data)
			// 说明点击的是第一层，拦截点击事件
			if (data.children && data.children.length) return
			// 此列表单选
			this.playIdObj = data
			this.deviceSub()
			//   this.deviceSensorData();
			//   this.deviceSensorLinedata();
		},
		/**
		 * 获取数据
		 * **/
		// 我的区域树状结构
		userDeviceList() {
			this.$http.getAction(this.$api.userDeviceList + '?type=2').then((res) => {
				if (res.data) {
					let arr = []
					res.data.forEach((item, index) => {
						if (item.list.length) {
							let obj = {}
							obj.label = item.areaName
							obj.id = index + '-' + index
							obj.online = item.onlineNum
							obj.allNum = item.totalNum
							obj.children = []
							item.list.forEach((item2, index2) => {
								obj.children.push({
									deviceNum: item2.deviceNum,
									label: item2.deviceName,
									id: item2.deviceId,
									online: item2.deviceStatus == 'offline' ? false : true,
									source: 0, //0自己1共享
									areaName: item.areaName,
									deviceId: item2.deviceId,
								})
							})
							arr.push(obj)
						}
					})
					if (arr.length) {
						this.treeData = arr
						if (this.routerQuery) {
							this.treeData.forEach((item, index) => {
								item.children.forEach((item2, index2) => {
									if (item2.deviceNum == this.routerQuery) {
										this.playIdObj = arr[index].children[index2]
										this.morenzankai = this.treeData[index].id
									}
								})
							})
						} else {
							this.playIdObj = arr[0].children[0]
							//   let menuNode = CircularJSON.stringify(arr[0].children[0]);
							//   this.playIdObj = CircularJSON.parse(menuNode);
							this.morenzankai = this.treeData[0].id
						}
						this.deviceSub()
					}
				} else {
					this.treeData = []
				}
			})
		},
		// 获取子设备
		deviceSub() {
			this.$http.postAction(this.$api.deviceSub + '?deviceId=' + this.playIdObj.id + '&subDeviceType=1').then((res) => {
				if (res.data) {
					this.qxequipment = res.data.subsetList[0]
					this.collectList = this.qxequipment.itemList

					this.tjFrom.collect = this.collectList[0].dataItemCode
					this.deviceSensorData()
					this.deviceSensorLinedata()
				} else {
					this.qxequipment = {}
				}
			})
		},
		// 请求实事数据
		deviceSensorData() {
			this.collectData.list = []
			this.$http
				.postAction(
					this.$api.deviceSensorData +
						'?deviceId=' +
						this.playIdObj.id +
						'&subDeviceId=' +
						this.qxequipment.subDeviceNum
				)
				.then((res) => {
					// res = {
					// 	code: 200,
					// 	msg: 'ok',
					// 	data: {
					// 		arrivalTime: '2022-10-24 09:29:40',
					// 		propList: [
					// 			{
					// 				dataItemName: '雨量累计',
					// 				dataItemCode: 'YLLJ',
					// 				dataItemValue: '42.61',
					// 				dataItemUnit: 'mm',
					// 			},
					// 			{
					// 				dataItemName: '降雨量',
					// 				dataItemCode: 'JYL',
					// 				dataItemValue: '42.61',
					// 				dataItemUnit: 'mm',
					// 			},
					// 			{
					// 				dataItemName: '数字气压',
					// 				dataItemCode: 'SZQY',
					// 				dataItemValue: '42.61',
					// 				dataItemUnit: 'hpa',
					// 			},
					// 			{
					// 				dataItemName: '土壤湿度',
					// 				dataItemCode: 'TRSD',
					// 				dataItemValue: '42.61',
					// 				dataItemUnit: '%',
					// 			},
					// 			{
					// 				dataItemName: '温度',
					// 				dataItemCode: 'WD',
					// 				dataItemValue: '42.61',
					// 				dataItemUnit: '°C',
					// 			},
					// 			{
					// 				dataItemName: '湿度',
					// 				dataItemCode: 'SD',
					// 				dataItemValue: '83.88',
					// 				dataItemUnit: '%',
					// 			},
					// 		],
					// 	},
					// }
					if (res.data.propList.length) {
						// 如果在线 时间显示-- 搜索按钮不显示，采集项各项数值显示 --   bug 3966
						if (!this.playIdObj.online) {
							console.log('=====')
							this.collectData.time = '--'
							this.collectData.online = false
						} else {
							this.collectData.time = res.data.arrivalTime

							this.collectData.online = true
							this.collectData.list = this.matchIcon(res.data.propList)
							console.log(this.collectData.list, 'this.collectData.list')
							this.collectData.list.forEach((item) => {
								if (item.dataItemName === '风向') {
									// 根据具体逻辑更新风向值
									let degree = parseFloat(item.dataItemValue) // 将度数值转换为浮点数

									// 判断风向
									let direction = ''
									if (degree >= 337.5 || degree < 22.5) {
										direction = '北风'
									} else if (degree >= 22.5 && degree < 67.5) {
										direction = '东北风'
									} else if (degree >= 67.5 && degree < 112.5) {
										direction = '东风'
									} else if (degree >= 112.5 && degree < 157.5) {
										direction = '东南风'
									} else if (degree >= 157.5 && degree < 202.5) {
										direction = '南风'
									} else if (degree >= 202.5 && degree < 247.5) {
										direction = '西南风'
									} else if (degree >= 247.5 && degree < 292.5) {
										direction = '西风'
									} else if (degree >= 292.5 && degree < 337.5) {
										direction = '西北风'
									}

									item.dataItemValue = direction // 更新风向值
								}
							})
							this.$forceUpdate()
						}

						// this.collectData.list = res.data.propList;
					} else {
						this.collectData.time = ''
						this.collectData.online = false
						this.collectData.list = []
					}
				})
		},
		// 匹配图标
		matchIcon(list) {
			list.forEach((item) => {
				this.imgList.forEach((item2) => {
					if (item.dataItemCode == item2.dataItemCode) {
						item.path = item2.path
					}
				})
			})
			list.forEach((item) => {
				if (!item.path) {
					item.path = require('@/assets/image/qxicon/icon_wd.png')
				}
				if (!item.dataItemValue) {
					item.dataItemValue = '--'
				}
				if (!item.dataItemUnit) {
					item.dataItemUnit = '--'
				}
			})
			return list
		},
		// 折线图数据
		deviceSensorLinedata() {
			let params = {
				deviceId: this.playIdObj.id,
				startTime: this.tjFrom.startTime,
				endTime: this.tjFrom.endTime,
				subDeviceId: this.qxequipment.subDeviceNum,
				dataItems: [this.tjFrom.collect],
			}
			this.$http.postAction(this.$api.deviceSensorLinedata, params).then((res) => {
				if (res.data.xList.length) {
					this.isLine = true
					let xData = res.data.xList
					let yData = []
					let yName = ''
					let yUnit = ''
					let code = ''
					res.data.yList.forEach((item, index) => {
						if (index == 0) {
							code = Object.keys(item)
						}
						yData.push(item[code])
					})
					let arr = []
					if (this.collectList.length) {
						arr = this.collectList.filter((item) => {
							if (item.dataItemCode == code) {
								return item
							}
						})
						yName = arr[0].dataItemName
						yUnit = arr[0].unitName
						this.$nextTick(() => {
							this.lineChart(xData, yData, yName, yUnit)
						})
					}
				} else {
					this.isLine = false
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.qx-box {
	height: 100%;
	min-height: 610px;
	.hea-box {
		height: 0.8rem;
		box-sizing: border-box;
		border-bottom: 1px solid #f1f1f1;
		font-size: 0.225rem;
		font-family: Microsoft YaHei;
		padding: 0.3rem;
		font-weight: bold;
		color: #454545;
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.btn-box {
			display: flex;
			.item {
				margin-left: 0.2rem;
			}
			img {
				cursor: pointer;
			}
		}
	}
	.box-header {
		height: 16.53%;
		box-sizing: border-box;
		border-bottom: 1px solid #f1f1f1;
		font-size: 0.225rem;
		font-family: Microsoft YaHei;
		padding: 0.3rem;
		font-weight: bold;
		color: #454545;
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.h-right {
			font-size: 0.175rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
			display: flex;
			align-items: center;
			.shuaxin {
				cursor: pointer;
				margin-left: 0.2rem;
			}
			.shezhi {
				cursor: pointer;
				margin-left: 0.4rem;
			}
			.shezhi-lixian {
				pointer-events: none;
				filter: grayscale(1);
			}
		}
	}
	.left-box {
		width: 22.3%;
		position: relative;
		margin-right: 0.25rem;
		height: 100%;
		background: #ffffff;
		border-radius: 0.125rem;
		.trees-box {
			height: 89%;
			overflow-y: scroll;
			box-sizing: border-box;
			padding: 0.3rem;
		}
	}
	.right-box {
		width: 76%;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.righttop-box {
			height: 48.8%;
			display: flex;
			justify-content: space-between;
			.pic-box {
				width: 21.77%;
				height: 100%;
				background: #ffffff;
				border-radius: 0.125rem;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					height: 86.3%;
				}
			}
			.data-box {
				width: 76.77%;
				height: 100%;
				background: #ffffff;
				border-radius: 0.125rem;
				.content-box {
					height: 83.47%;
					width: 100%;

					box-sizing: border-box;
					padding: 0.3rem 0.0875rem 0.3rem 0.3rem;
					.content {
						width: 100%;
						height: 100%;
						box-sizing: border-box;
						// padding-right: 8px;
						overflow: auto;
						display: flex;
						flex-wrap: wrap;
						&::-webkit-scrollbar {
							width: 6px;
							height: 0px;
							background-color: transparent;
						}
						&::-webkit-scrollbar-track {
							border-radius: 6px;
							background-color: transparent;
						}
						&::-webkit-scrollbar-thumb {
							border-radius: 6px;
							background-color: rgba(45, 174, 169, 0.2);
						}
						.item {
							width: 1.875rem;
							height: 1.575rem;
							background: #f2f8f9;
							border-radius: 0.125rem;
							margin-right: 0.275rem;
							margin-bottom: 0.275rem;
							box-sizing: border-box;
							padding: 0.1875rem 0;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							align-items: center;
							img {
								width: 0.5rem;
								height: 0.45rem;
							}
							.name {
								font-size: 0.2rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #2daea9;
							}
							.value {
								font-size: 0.3rem;
								font-family: Microsoft YaHei;
								font-weight: bold;
								color: #2daea9;
							}
						}
						.mr0 {
							margin-right: 0;
						}
						.mb0 {
							margin-bottom: 0;
						}
					}
					.content-no {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
		.rightbottom-box {
			width: 100%;
			height: 48.8%;
			background: #ffffff;
			border-radius: 0.125rem;
			.content-box {
				height: 83.47%;
				width: 100%;
				.echart {
					width: 100%;
					height: 100%;
				}
				.table-empty {
				}
				.chart-lines {
					height: 85%;
					// margin: 0.15rem auto 0;
				}
				.table-noimg {
					width: 3rem;
				}
				.table-emptyword {
					color: #c8c8c8;
					font-size: 0.23rem;
				}
			}
		}
	}
}
</style>
