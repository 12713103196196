import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// axios、ajax封装
import * as http from './api/request'
// 统一接口管理
import api from './api/api'
import bus from './api/bus'
// TODO Mock模拟数据，发布需调整
// import api from './api/apiMock'
// echarts图表
import * as echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css'
// element-ui组件模块
import '@/ele.js'
import './api/dateTools.js'
import './api/rem'
import 'animate.css'

import './mock'

// 滑块验证
import dragVerify from 'vue-drag-verify2'
Vue.use(dragVerify)

// 图片预览功能
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer)

import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

import VueVideoPlayer from 'vue-video-player'
import 'videojs-flash'
import 'videojs-contrib-hls'
import 'videojs-flvjs-es6'
// 圆形 滑块 组件_Vue.js的圆形滑块组件
import VueCircleSlider from 'vue-circle-slider'

Vue.use(VueCircleSlider)

window.videojs = VueVideoPlayer.videojs
Vue.prototype.$http = http
Vue.prototype.$echarts = echarts
Vue.prototype.$api = api
Vue.prototype.$bus = bus
Vue.prototype.$store = store
Vue.config.productionTip = false
Vue.filter('ProductType', (str) => {
	let arr = [
		{
			type: "1",
			name: "一体化控制箱"
		},
		{
			type: "2",
			name: "气象站"
		},
		{
			type: "3",
			name: "墒情站"
		},
		{
			type: "4",
			name: "杀虫灯"
		},
		{
			type: "5",
			name: "虫情"
		},
		{
			type: "6",
			name: "智能终端"
		},
	]
	let name = "";
	arr.forEach((item, index) => {
		if (item.type == str) {
			name = item.name
		}
	})
	if (name == "") {
		name = "--"
	}
	return name
})
Vue.filter("TimeDrop00", (str) => {
	const regexPattern = /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
	let bool = regexPattern.test(str)
	if (bool) {
		const formattedTime = str.replace(/:\d{2}$/, '');
		return formattedTime
	} else {
		return str
	}

})
new Vue({
	router,
	store,
	render: (h) => h(App),
	beforeCreate() {
		Vue.prototype.$bus = this //安装全局事件总线
	},
}).$mount('#app')
