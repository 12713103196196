<template>
	<div v-if="!item.hidden" class="slideitem">
		<template v-if="hasOneShowingChild(item.children, item)">
			<el-menu-item :index="resolvePath(onlyOneChild.path)">
				<img
					v-if="onlyOneChild.meta.icon"
					:src="
						resolvePath(onlyOneChild.path) == activeMenu
							? activeImg(onlyOneChild.meta.icon)
							: defaultImg(onlyOneChild.meta.icon)
					"
					alt=""
				/>
				<div class="circle" v-else></div>
				<span>{{ onlyOneChild.meta.title }}</span>
			</el-menu-item>
		</template>

		<el-submenu v-else ref="subMenu" :index="item.path" popper-append-to-body>
			<template slot="title">
				<img
					v-if="item.meta.icon"
					:src="activeMenu.indexOf(item.path) > -1 ? activeImg(item.meta.icon) : defaultImg(item.meta.icon)"
					alt=""
				/>
				<span>{{ item.meta.title }}</span>
			</template>
			<sidebar-item
				v-for="child in item.children"
				:key="child.path"
				:is-nest="true"
				:item="child"
				class="nest-menu"
				:base-path="resolvePath(child.path)"
			/>
		</el-submenu>
	</div>
</template>

<script>
export default {
	name: 'SidebarItem',
	props: {
		// route object
		item: {
			type: Object,
			required: true,
		},
		isNest: {
			type: Boolean,
			default: false,
		},
		basePath: {
			type: String,
			default: '',
		},
	},
	data() {
		this.onlyOneChild = null
		return {
			mouseIsEnter: false,
		}
	},
	computed: {
		activeMenu() {
			const route = this.$route
			const { meta, path } = route
			if (meta.activeMenu) {
				return meta.activeMenu
			}
			return path
		},
	},
	methods: {
		// 判断是否有下级，只有一个子级时是自身，表示没有子级
		hasOneShowingChild(children = [], parent) {
			let childs = children.filter((item) => {
				return !item.hidden
			})
			const showingChildren = childs.filter((item) => {
				if (item.hidden) {
					return false
				} else {
					this.onlyOneChild = item
					return true
				}
			})
			if (showingChildren.length === 1) {
				return true
			}
			if (showingChildren.length === 0) {
				this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
				return true
			}

			return false
		},
		// 路由地址拼接
		resolvePath(routePath) {
			if (this.basePath == '/') {
				return '/home'
			} else {
				if (routePath) {
					return `${this.basePath}/${routePath}`
				} else {
					return this.basePath
				}
			}
		},
		defaultImg(icon) {
			return require('@/assets/image/menu/deactive-' + icon + '.png')
		},
		activeImg(icon) {
			return require('@/assets/image/menu/active-' + icon + '.png')
		},
	},
	mounted() {},
}
</script>
<style lang="scss">
.slideitem {
	border-right: none;
	background-color: #0b313c;
	img {
		width: 0.3rem;
		height: 0.25rem;
		vertical-align: middle;
		margin-right: 0.31rem;
	}
	// ele UI
	.el-menu-item {
		span {
			color: #a7b5b9;
			font-size: 0.2rem;
		}
		.circle {
			width: 0.05rem;
			height: 0.05rem;
			margin-right: 0.2125rem;
			background: #a7b5b9;
			border-radius: 50%;
		}
	}

	// .el-submenu__title,
	.el-menu-item {
		width: 2.95rem;
		height: 0.6375rem;
		// margin: 0 auto;
		margin-left: 0.1rem;
		padding-left: 0.16rem !important;
		text-align: left;
		font-size: 0.2rem;
		border-radius: 0.125rem;
		display: flex;
		align-items: center;
		&.is-active {
			border-radius: 0.125rem;
			background-color: #2f5059 !important;
			span {
				color: #fff;
			}
		}
	}
	// .el-submenu__title {
	.el-submenu__title {
		height: 0.6375rem;
		padding-left: 0.16rem !important;
		line-height: 0.6375rem;
		span {
			font-size: 0.2rem;
		}
	}
	.el-submenu {
		text-align: left;
		width: 2.95rem;
		// margin: 0 auto;
		margin-left: 0.11rem;
		font-size: 0.2rem;
		border-radius: 0.125rem;
		&.is-active {
			.el-submenu__title {
				span {
					color: #fff;
				}
			}
		}
	}

	.el-icon-arrow-down {
		position: absolute;
		font-size: 0.175rem;
		// color: #0B313C;
		color: #a7b5b9;
		right: 0.25rem;
	}
}
.slideitem2 {
	.el-menu-item {
		// margin-left: 8px;
		transform: translateX(-5px);
	}
	.el-submenu {
		transform: translateX(-5px);
	}
}
</style>
