<template>
	<div class="ValidCode disabled-select" :style="`width:${width}; height:${height}`">
		<div class="code-parent" @click.stop="refreshCode">
			<span
				v-for="(item, index) in codeList"
				:key="index"
				:style="getStyle(item)"
				>{{ item.code }}</span
			>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		width: {
			type: String,
			default: '1.69rem',
		},
		height: {
			type: String,
			default: '0.63rem',
		},
		length: {
			type: Number,
			default: 4,
		},
	},
	data() {
		return {
			codeList: [],
		}
	},
	mounted() {
		this.createdCode()
	},
	methods: {
		refreshCode() {
			this.createdCode()
		},
		createdCode() {
			const len = this.length
			const codeList = []
			const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz0123456789'
			const charsLen = chars.length
			// 生成
			// 彩色的
			// for (let i = 0; i < len; i++) {
			// 	const rgb = [Math.round(Math.random() * 220), Math.round(Math.random() * 240), Math.round(Math.random() * 200)]
			// 	codeList.push({
			// 		code: chars.charAt(Math.floor(Math.random() * charsLen)),
			// 		color: `rgb(${rgb})`,
			// 		fontSize: `1${[Math.floor(Math.random() * 10)]}px`,
			// 		padding: `${[Math.floor(Math.random() * 10)]}px`,
			// 		transform: `rotate(${Math.floor(Math.random() * 90) - Math.floor(Math.random() * 90)}deg)`,
			// 	})
			// }
			// 纯色的
			for (let i = 0; i < len; i++) {
				const rgb = [
					Math.round(Math.random() * 220),
					Math.round(Math.random() * 240),
					Math.round(Math.random() * 200),
				]
				codeList.push({
					code: chars.charAt(Math.floor(Math.random() * charsLen)),
					color: `rgb(${rgb})`,
					fontSize: `28px`,
					padding: `2px`,
					transform: `rotate(${
						Math.floor(Math.random() * 90) - Math.floor(Math.random() * 90)
					}deg)`,
				})
			}
			// 指向
			this.codeList = codeList
			// 将当前数据派发出去
			this.$emit('codeUpdata', codeList.map((item) => item.code).join(''))
		},
		getStyle(data) {
			return `color: ${data.color}; font-size: ${data.fontSize}; padding: ${data.padding}; transform: ${data.transform}`
		},
	},
}
</script>

<style scoped lang="scss">
.ValidCode {
	cursor: pointer;
	span {
		display: inline-block;
	}
	.code-parent {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #e2f3f2;
		overflow: hidden;
	}
}
</style>
