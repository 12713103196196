/*
 * @description: 环境数据分析页面
 */
export default {
	data() {
		return {
			anaPlayArr: [],
			anaplayArrInfo: [],
		}
	},
	methods: {
		// 树形组件点击事件
		handleNodeClick(data) {
			// console.log(data);
			// 说明点击的是第一层，拦截点击事件
			if (data.children) return
			// 拦截重复点击事件
			if (this.playIdObj == data) return
			// 此列表单选
			this.playIdObj = data
			// 页面回到初始状态
			this.openType = 'all'
			// 图表还是报表
			this.tabIndex = 0
			// 传感器选中第几个
			this.treeThreeIndex = 0
			// 放大状态恢复
			if (this.isShowBig) {
				this.isShowBig = false
			}
			// console.log(data)
			this.getDeviceInfo()
		},
		// 数据分析树形事件
		handleNodeClickAna(data) {
			if (data.children) return
			// 此列表单选
			let ids = data.id
			// 判断是否选中，选中了 删除，没有选中则添加选中
			let findindex = this.anaPlayArr.indexOf(ids)
			if (this.anaPlayArr.includes(ids)) {
				this.anaPlayArr.splice(findindex, 1)
				this.anaplayArrInfo.splice(findindex, 1)
			} else {
				if (this.anaPlayArr.length >= 6) {
					this.$message('一次分析可支持最多6个参数进行分析', 'warning')
				} else {
					this.anaPlayArr.push(ids)
					this.anaplayArrInfo.push(data)
				}
			}
		},
		// 页面显示类型改变
		changeOpenType(type) {
			if (!this.treeData.length && !treeData[0]['children'].length) {
				// this.$message('暂无设备', 'error')
				this.$refs.tsmsg.msgShow("暂无设备", 1.5);
				return
			}
			if (this.openType == type) return

			// 点击开始分析
			if (type == 'lines') {
				// 对需要传参的数据进行处理，并请求数据
				console.log(this.anaPlayArr)
				if (!this.anaPlayArr.length) {
					// this.$message('请先选择分析项', 'error')
					this.$refs.tsmsg.msgShow("请先选择分析项", 1.5);
					return
				}
				this.openType = type
				// 获取折线图数据
				this.getAnalysisLines()
			} else if (type == 'alalysis') {
				this.openType = type
				// 点击的是数据分析
				// 遍历数据分析页面数据，获取每个设备下面的传感器数据
				let analysisTree = JSON.parse(JSON.stringify(this.analysisTree))
				// 有数据，说明是点击重筛显示的，不用再次请求
				if (analysisTree[0] && analysisTree[0]['children'] && analysisTree[0]['children'].length) {
					this.anaPlayArr = []
					this.anaplayArrInfo = []
				} else {
					this.anaPlayArr = []
					this.anaplayArrInfo = []
					// 遍历获取数据
					for (let i = 0; i < analysisTree.length; i++) {
						// 获取对应设备下的传感器数据
						let deviceIds = []
						deviceIds.push(analysisTree[i]['id'])
						let params = deviceIds
						this.$http.postAction(this.$api.sensorDeviceInfo, params).then((res) => {
							if (res.code == 200) {
								let result = res.data || []
								if (result.length) {
									let datas = result[0]
									// 里面的传感器设备
									if (datas.subsetList.length) {
										datas.subsetList.forEach((item) => {
											let obj = {}

											if (item.itemList && item.itemList.length) {
												obj.label = item.subDeviceName
												obj.deviceNum = item.deviceNum
												obj.id = item.subDeviceNum
												obj.children = []
												// 采集项
												item.itemList.forEach((items) => {
													let objTwo = {}
													objTwo.label = items.dataItemName
													objTwo.allname = `${datas.deviceName}-${item.subDeviceName}-${items.dataItemName}`
													objTwo.id =
														datas['deviceNum'] +
														'~' +
														analysisTree[i]['id'] +
														'~' +
														item.subDeviceNum +
														'~' +
														items.dataItemCode
													obj.children.push(objTwo)
												})

												analysisTree[i]['children'].push(obj)
											}
										})
									}
								} else {
									analysisTree = []
								}
							}
						})
					}

					this.analysisTree = analysisTree
				}
			} else if (type == 'all') {
				// 显示的是图表和报表页面
				// 显示的是图表和报表页面
				if (this.tabIndex == 0) {
					// 如果是放大状态，则状态取消
					if (this.isShowBig) {
						this.isShowBig = false
					}
				} else {
					// 报表数据
					this.$nextTick(() => {
						this.setBigTableParam()
					})
				}
				this.openType = type
				this.anaPlayArr = []
				this.anaPlayArr = []
				this.isShowLine = false
			}
		},
		// 切换选中设备
		changeEquip(index) {
			if (this.treeThreeIndex == index) return
			this.treeThreeIndex = index

			// 重新获取下面的折线图数据
			this.getAllItemLine()
		},
		// 切换图表与报表
		changeTab(index) {
			if (this.tabIndex == index) return
			this.tabIndex = index

			if (index == 1) {
				this.setBigTableParam()
			}
		},
		// 设置报表的传参并调起请求
		setBigTableParam() {
			let deviceInfo = this.treeThreeItem[this.treeThreeIndex]
			let itemList = deviceInfo.itemList
			let sensorData = []
			itemList.forEach((item) => {
				sensorData.push({
					dataItem: item.dataItemCode,
				})
			})
			let params = {
				page: 1,
				limit: 8,
				startTime: this.queryParam.startTime,
				endTime: this.queryParam.endTime,
				deviceCode: this.deviceInfo['deviceNum'],
				subDeviceId: deviceInfo.subDeviceNum,
				sensorData: sensorData,
			}
			let deviceTab = this.treeThreeItem[this.treeThreeIndex]
			let allName = `${this.playIdObj.pname}-${this.playIdObj.label}-${deviceTab.subDeviceName}`

			if (this.lengend.length) {
				// 请求报表接口
				let allQuery = {
					params: params,
					allName: allName,
				}
				this.$nextTick(() => {
					this.$refs.bigTableModalRef.setForm(allQuery)
					this.$refs.bigTableModalRef.loadData(1)
				})
			}
		},
		// 综合和单个数据的切换，放大缩小的点击事件
		changeWrap(type, index) {
			if (type == this.isShowBig) return
			this.bigIndex = index
			this.isShowBig = type

			// 显示单个放大数据
			if (this.isShowBig) {
				let datas = this.smallLineData[index]
				this.bigTableName = `${datas.name}(${datas.unit})`
				let bigTableData = []
				datas.dataList.forEach((item, index) => {
					let aTableData = {
						times: item[0],
						values: item[1],
					}
					bigTableData.push(aTableData)
				})
				this.bigTableData = bigTableData.reverse()
				// 设置拆分表格上显示的数据
				this.handleCurrentChange(1)
				// 设置调整数据
				this.$nextTick(() => {
					this.makeBigLine(datas, index)
				})
			}
		},

		// 绘制单个显示数据的折线图
		makeBigLine(datas, index) {
			console.log(datas)
			const _this = this
			// let xData = datas.acquisitionTimes
			// let yData = datas.values
			let myChart = this.$echarts.init(this.$refs.bigChart)
			let areaColors = this.convertHexToRGB(this.lengendColors[index], 0.26)
			let colors = this.lengendColors[index]
			let option = {
				title: {
					text: datas.name,
					textStyle: {
						color: '#454545',
						fontSize: 18,
						fontWeight: 'normal',
					},
					right: 'center',
					top: 30,
				},
				tooltip: {
					show: true,
					trigger: 'axis',
					backgroundColor: '#fff',
					padding: [14, 17],
					axisPointer: {
						type: 'line',
						lineStyle: {
							color: colors,
							type: 'solid',
							width: 2,
						},
					},
					extraCssText:
						'box-shadow: 0px 0px 12px 0px rgba(76, 216, 179, 0.33);text-align:left;font-size:14px;color:#3F3F3F;letter-spacing: 1px;',
					formatter: function (param) {
						let infos = ''
						infos += `<div style="text-align:left;">${datas.name}：<span style="color:${colors}">${param[0].value[1]}${datas.unit}</span></div><div style="margin-top:12px">${param[0].name}</div`
						return infos
					},
				},
				color: this.lengendColors[index],
				grid: {
					left: '8%',
					bottom: '8%',
					top: '12%',
					right: '5%',
				},
				xAxis: {
					type: 'category',
					// 刻度线
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#EDEDED',
							width: 2,
						},
					},
					axisLabel: {
						color: '#6B6B6B',
						fontSize: 14,
						lineHeight: 30,
					},
					boundaryGap: false,
				},
				yAxis: {
					type: 'value',
					name: '(' + datas.unit + ')',
					nameTextStyle: {
						color: '#6B6B6B',
						fontSize: 14,
						align: 'right',
					},
					axisLabel: {
						color: '#6B6B6B',
						fontSize: 14,
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: '#EDEDED',
							width: 2,
						},
					},
				},
				series: [
					{
						type: 'line',
						smooth: true,
						showSymbol: false,
						symbolSize: 10,
						itemStyle: {
							borderColor: this.lengendColors[index],
							borderWidth: 8,
						},
						areaStyle: {
							color: areaColors,
						},
						lineStyle: {
							width: 4,
						},
						markPoint: {
							symbolSize: 65,
							label: {
								color: '#fff',
								fontSize: 12,
							},
							data: [
								{
									type: 'max',
									name: '最大值',
									itemStyle: {
										color: '#FF648C',
									},
									z: 1,
								},
								{
									type: 'min',
									name: '最小值',
									itemStyle: {
										color: '#3EA2EF',
									},
									z: 2,
								},
							],
						},
						markLine: {
							data: [
								{
									type: 'average',
									name: '平均值',
									lineStyle: {
										type: 'solid',
										color: '#FFA800',
									},
									label: {
										position: 'insideEndTop',
										formatter: '平均值:{c}',
										color: '#FFA800',
										distance: 10,
										fontSize: 16,
									},
									symbol: 'none',
								},
							],
						},
						data: datas.dataList,
					},
				],
			}
			myChart.clear()
			myChart.setOption(option)
			window.addEventListener('resize', function () {
				myChart.resize()
			})
		},
		// 但数据展示 -- 页码变化
		handleCurrentChange(val) {
			this.rightPageNum = val
			// 数据进行截取
			let showData = this.bigTableData.slice((val - 1) * 10, val * 10)
			this.bigTableShowData = showData
		},

		// TODO 该接口有问题：导出按钮点击
		exportDownLoad() {
			let anaplayArrInfo = JSON.parse(JSON.stringify(this.anaplayArrInfo))
			let sensorData = []
			anaplayArrInfo.forEach((item, index) => {
				let splitIds = item.id.split('~')
				sensorData.push({
					deviceCode: splitIds[0],
					subDeviceId: splitIds[2],
					dataItem: splitIds[3],
					name: `${this.playIdObj.pname}-${item.allname}`,
				})
			})
			// let params = {
			// 	startTime: this.queryParam.startTime,
			// 	endTime: this.queryParam.endTime,
			// 	exportParam: sensorData,
			// }
			let params = {
				exportParam: {
					startTime: this.queryParam.startTime,
					endTime: this.queryParam.endTime,
					subDeviceId: [],
					dataItems: [],
					sensorData: sensorData,
				},
			}
			this.$http.postAction(this.$api.saveExport, params).then((res) => {
				if (res.code == 200) {
					// this.$message(res.msg, 'success')
					this.$refs.tsmsg.msgShow(res.msg, 1.5);
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5);
				}
			})
		},

		// 导出记录点击
		handleOpenExport() {
			this.$refs.exportDrawer.open()
		},

		// 绘制数据分析的折线图
		lineAnalysis(yAxis, series) {
			// console.log(yAxis)
			console.log(series)
			let xType = yAxis.length == 1 ? 'category' : 'time'
			var num = yAxis.length * 2 - 1 + '%'
			const _this = this
			let myChart = this.$echarts.init(this.$refs.lineAnalysis)
			let option = {
				tooltip: {
					show: true,
					trigger: 'axis',
					backgroundColor: '#fff',
					padding: [11, 17],
					axisPointer: {
						type: 'line',
						lineStyle: {
							color: '#DADADA',
							type: 'solid',
							width: 2,
						},
					},
					extraCssText:
						'box-shadow: 0px 0px 12px 0px rgba(76, 216, 179, 0.33);text-align:left;font-size:14px;color:#3F3F3F;letter-spacing: 1px;',
					formatter: function (value) {
						var vdata1 = value[0].data
						var date = vdata1[0]
						//去除重复数据
						for (var i = 0; i < value.length; i++) {
							for (var j = value.length - 1; j > i; j--) {
								if (value[j].seriesName == value[i].seriesName) {
									value.splice(j, 1)
									break
								}
							}
						}
						var html = date + '</br>'
						for (var i = 0; i < value.length; i++) {
							var color = value[i].color
							var seriesName = value[i].seriesName
							let nameIndex = series.findIndex((item) => {
								return item.name == seriesName
							})
							var vdata = value[i].data
							if (vdata[1] || vdata[1] == 0) {
								let seriesNameArr = seriesName.split('-')
								seriesNameArr.splice(-1)
								var ser = seriesNameArr.join('-') + '：'
								// var ser = seriesName + '：'
								html = html + ser
								html += `<span style="color: #43B5B3">${vdata[1]}${series[nameIndex]['unit']}</span>`
								if (i != value.length - 1) {
									html = html + '</br>'
								}
							}
						}
						return html
					},
				},
				color: this.lineColors,
				legend: {
					show: true,
					selectedMode: false,
					type: 'scroll',
					orient: 'horizontal',
					icon: 'rect',
					top: '2%',
					width: '90%',
					itemGap: 30,
					itemWidth: 26,
					itemHeight: 12,
					textStyle: {
						fontSize: 14,
						color: '#454545',
					},
					pageButtonItemGap: 10, // 按钮和页信息之间的间隔
					pageButtonGap: 20, // 控制块和图例项之间的间隔
					pageIconInactiveColor: '#89EAE8', // 按钮未激活颜色
					pageIconColor: '#42B5B2', // 按钮颜色
					pageIconSize: [10, 20], // 翻页按钮的大小 [宽, 高]
					pageTextStyle: {
						color: '#6B6B6B',
						fontSize: 14,
					},
					formatter: function (name) {
						let seriesNameArr = name.split('-')
						seriesNameArr.splice(-1)
						var ser = seriesNameArr.join('-')
						return ser
					},
				},

				grid: {
					left: num,
					bottom: '8%',
					top: '12%',
					right: '5%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					//   maxInterval: 3600 * 24 * 1000,
					// minInterval: 3600 * 24,
					axisLabel: {
						color: '#6B6B6B',
						fontSize: 14,
						// margin: 15,
						lineHeight: 15,
						// width: 80,
						// overflow: 'breakAll',
						// formatter: function (value) {
						// 	var date = _this.formateDate(new Date(value), 'yyyy-MM-dd hh:mm:ss')
						// 	return date
						// },
					},
					//   position: "bottom",
					//   offset: 0,
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#EDEDED',
							width: 2,
						},
					},
					boundaryGap: false,
				},
				yAxis: yAxis,
				series: series,
			}
			myChart.clear()
			myChart.setOption(option)
			window.addEventListener('resize', function () {
				myChart.resize()
			})
		},

		// 点击查询
		searchClick() {
			// 判断当前页面的显示
			if (this.openType == 'all') {
				// 显示的是图表和报表页面
				if (this.tabIndex == 0) {
					// 如果是放大状态，则状态取消
					if (this.isShowBig) {
						this.isShowBig = false
					}
					// 获取全部折线图数据
					this.getAllItemLine()
				} else {
					// 报表数据
					this.setBigTableParam()
				}
			} else if (this.openType == 'alalysis') {
				// 走无法查询的提示，即不生效
				this.$message('该页面不支持查询', 'warning')
				return
			} else if (this.openType == 'lines') {
				// 获取折线图数据
				this.getAnalysisLines()
			}
		},
	},
}
