import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue' // 登陆页面
import Index from '../views/Index.vue' // 右侧内容主页面

// 新版
// 首页
import Home from '../views/home/Home.vue' // 首页
// import Home from '../views/home/home.vue' // 首页
// 产品管理
import MyEquipment from '../views/myEquipment/myEquipment.vue'
// 产品服务
import integration from '../views/product/integration.vue' // 一体化控制箱
import smart from '../views/product/smart.vue' // 智能终端
import qixiang from '../views/product/qixiang.vue' //气象
import shangq from '../views/product/shangq.vue' //墒情
import chongq from '../views/product/chongq.vue' //虫情
import shachong from '../views/product/shachong.vue' //杀虫灯
// 视频监控
import monitoring from '../views/monitoring/monitoring.vue'
// 综合分析
import environment from '../views/analysis/environment.vue' //环境数据分析
import light from '../views/analysis/light.vue' //日光照分析
import rain from '../views/analysis/rain.vue' //雨量累计分析
// 个人中心
import personal from '../views/personal/personal.vue' //用户中心
import district from '../views/personal/district.vue' //区域管理
import execution from '../views/personal/execution.vue' //执行记录
// 消息中心
import log from '../views/message/log.vue' //系统日志
import notice from '../views/message/notice.vue' //消息通知
Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'login',
		component: Login,
		hidden: true,
	},
	// 首页
	{
		path: '/',
		component: Index,
		redirect: '/home',
		meta: { title: '首页' },
		children: [
			{
				path: 'home',
				component: Home,
				meta: { title: '首页', icon: 'home' },
			},
		],
	},
	// 我的设备
	{
		path: '/equipment',
		component: Index,
		children: [
			{
				path: '',
				component: MyEquipment,
				meta: { title: '产品管理', icon: 'equipment' },
			},
		],
	},
	//	共享设备
	// {
	// 	path: '/sharedEquipment',
	// 	component: Index,
	// 	children: [
	// 		{
	// 			path: '',
	// 			component: sharedEquipment,
	// 			meta: { title: '共享设备', icon: 'shared' },
	// 		},
	// 	],
	// },
	// 产品服务
	{
		path: '/product',
		component: Index,
		meta: { title: '产品服务', icon: 'product' },
		children: [
			{
				path: 'integration',
				component: integration,
				meta: { title: '一体化控制箱' },
			},
			{
				path: 'smart',
				component: smart,
				meta: { title: '智能终端' },
			},
			{
				path: 'qixiang',
				component: qixiang,
				meta: { title: '气象监测' },
			},
			{
				path: 'shangq',
				component: shangq,
				meta: { title: '墒情监测' },
			},
			// {
			// 	path: 'chongq',
			// 	component: chongq,
			// 	meta: { title: '虫情监测' },
			// },
			// {
			// 	path: 'shachong',
			// 	component: shachong,
			// 	meta: { title: '远程杀虫灯监测' },
			// },
		],
	},
	// 视频监控
	{
		path: '/monitoring',
		component: Index,
		children: [
			{
				path: '',
				component: monitoring,
				meta: { title: '视频监控', icon: 'monitoring' },
			},
		],
	},
	// 综合分析
	{
		path: '/analysis',
		component: Index,
		meta: { title: '综合分析', icon: 'analysis' },
		children: [
			{
				path: 'environment',
				component: environment,
				meta: { title: '环境数据分析' },
			},
			{
				path: 'light',
				component: light,
				meta: { title: '日光照分析' },
			},
			{
				path: 'rain',
				component: rain,
				meta: { title: '雨量累计分析' },
			},
		],
	},
	// 个人中心
	{
		path: '/personal',
		component: Index,
		meta: { title: '个人中心', icon: 'personal' },
		children: [
			{
				path: 'district',
				component: district,
				meta: { title: '区域管理' },
			},
			{
				path: 'user',
				component: personal,
				meta: { title: '用户信息' },
			},

			{
				path: 'execution',
				component: execution,
				meta: { title: '执行记录' },
			},
		],
	},
	// 消息中心
	{
		path: '/message',
		component: Index,
		meta: { title: '消息中心', icon: 'message' },
		children: [
			{
				path: 'log',
				component: log,
				meta: { title: '系统日志' },
			},
			{
				path: 'notice',
				component: notice,
				meta: { title: '消息通知' },
			},
		],
	},
]

const router = new VueRouter({
	mode: 'hash',
	// base: process.env.BASE_URL,
	base: '/agro-universe-vue/',
	routes,
})

// 路由守卫设置
router.beforeEach((to, from, next) => {
	console.log(to)
	let toPath = to.path
	// 判断登陆信息不存在并且进入不是登录页，跳转到登录页面
	let token = window.localStorage.getItem('wlwtoken')
	if (toPath == '/login') {
		return next()
	}
	if (token && token != '') {
		return next()
	} else {
		return next('/login')
	}
})

export default router
