<template>
  <div id="videoall">
    <div class="videoBox" v-show="isCanPlay">
      <video
        ref="videoPlayer"
        loop
        muted
        controls
        autoplay
        preload="auto"
        id="videoss"
        class="videoss video-js vjs-default-skin vjs-big-play-centered"
      ></video>
    </div>

    <div
      class="flex flex_col flex_a_c flex_ju_c play-error"
      v-show="!isCanPlay"
    >
      <img class="img_noplay" src="@/assets/image/videos/noplay.png" alt="" />
      <div class="font_16 c_white">
        网络错误，请检查网络配置或者播放链接是否正确
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
// 教程说明
// https://blog.csdn.net/little__SuperMan/article/details/89203270/
export default {
  data() {
    return {
      isCanPlay: true,
      videoUrl: "",
      videoPlayer: null,
      options: {
        controls: true, // 是否显示控制条
        muted: true, //是否静音
        autoplay: true, //自动播放
        loop: false, //是否循环
        fluid: false, // 自适应宽高
        language: "zh-CN", // 设置语言
        // aspectRatio: '3:1',
        inactivityTimeout: false,
        controlBar: {
          // 设置控制条组件
          /* 设置控制条里面组件的相关属性及显示与否
		    'currentTimeDisplay':true,
		    'timeDivider':true,
		    'durationDisplay':true,
		    'remainingTimeDisplay':false,
		    volumePanel: {
		      inline: false,
		    }
		    */
          /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
          children: [
            { name: "playToggle" }, // 播放按钮
            { name: "currentTimeDisplay" }, // 当前已播放时间
            { name: "progressControl" }, // 播放进度条
            { name: "durationDisplay" }, // 总时间
            {
              // 倍数播放
              name: "playbackRateMenuButton",
              playbackRates: [0.5, 1, 1.5, 2, 2.5],
            },
            {
              name: "volumePanel", // 音量控制
              inline: false, // 不使用水平方式
            },
            { name: "FullscreenToggle" }, // 全屏
          ],
        },
        language: "zh-CN",
        poster: "", //播放前显示的视频画面，播放开始之后自动移除
        preload: "auto", //预加载
        // width: 650,
        // height: 330,
        techOrder: ["html5", "flvjs", "flash"], // 兼容顺序
        flvjs: {
          mediaDataSource: {
            isLive: false,
            cors: true,
            withCredentials: false,
          },
        },
        sources: [],
      },
    };
  },
  mounted() {},
  methods: {
    handelVideoUrl(reloadUrl) {
      const _this = this;
      if (this.videoUrl && this.videoUrl != "") {
        this.editUrl(reloadUrl);
        return;
      }
      if (reloadUrl && reloadUrl != "") {
        this.videoUrl = reloadUrl;
      } else {
        this.videoUrl = "";
        this.isCanPlay = false;
      }
      if (this.videoUrl && this.videoUrl.length) {
        this.options.sources = [this.makeUrlSource(this.videoUrl)];

        this.$nextTick(() => {
          this.isCanPlay = true;
          this.videoPlayer = videojs(
            this.$refs.videoPlayer,
            {
              ...this.options,
            },
            function onPlayerReady() {
              this.on("loadstart", function () {
                _this.isCanPlay = true;
              });
              this.on("playing", function () {
                _this.isCanPlay = true;
              });
              this.on("error", function () {
                _this.isCanPlay = false;
              });
            }
          );
          this.videoPlayer.volume(0);//设置音量
          //   this.pause();
          setTimeout(() => {
            this.videoPlayer.pause();
          }, 200);
        });
      }
    },
    play() {
      this.videoPlayer.play();
    },
    pause() {
      this.videoPlayer.pause();
    },
    editUrl(src) {
      this.isCanPlay = true;
      this.videoUrl = src;
      this.$nextTick(() => {
        this.videoPlayer.src(this.makeUrlSource(src));
      });
    },
    dispose() {
      if (this.videoPlayer) {
        this.videoPlayer.dispose();
      }
    },
    makeUrlSource(reloadUrl) {
      const url = reloadUrl.replace(/\s+/g, "");
      let sources = {};
      if (url.indexOf("rtmp") > -1) {
        //rtmp格式视频
        sources = [{ src: reloadUrl, type: "rtmp/flv" }];
      } else if (url.lastIndexOf(".") > -1) {
        var playurl = url.substring(url.lastIndexOf(".") + 1);
        // console.log(playurl.split('?')[0])
        if (playurl === "flv") {
          // console.log('flv格式视频')
          //flv格式视频
          sources = [{ src: reloadUrl, type: "video/x-flv" }];
        } else if (playurl.split("?")[0] === "flv") {
          //对**直播平台flv地址做特殊处理
          // console.log('flv格式视频')
          //flv格式视频
          sources = [{ src: reloadUrl, type: "video/x-flv" }];
        } else if (playurl === "m3u8") {
          //m3u8格式视频
          // console.log('m3u8格式视频')
          sources = [{ src: reloadUrl, type: "application/x-mpegURL" }];
        } else {
          // console.log('其他格式视频')
          //其他格式视频
          sources = [{ src: reloadUrl, type: "video/mp4" }];
        }
        return sources;
      }
    },
  },
  destroyed() {
    if (this.videoPlayer) {
      this.videoPlayer.dispose();
    }
  },
  mounted() {
    const video = document.querySelector(".videoss");
    video.addEventListener("error", () => {
      console.log("播放失败");
      this.isCanPlay = false;
    });
  },
};
</script>
<style lang="scss">
#videoall {
  .videoBox {
    width: 100%;
    height: 100%;
  }
  .video-js {
    width: 100%;
    height: 100%;
  }
  .play-error {
    width: 100%;
    height: 100%;
    background-color: #343434;
    .img_noplay {
      width: 0.76rem;
      height: 0.64rem;
      margin-bottom: 0.46rem;
    }
  }
}
</style>
