<template>
	<div class="flex flex_ju_sb videos-page">
		<template v-if="restaurants.length || isInit">
			<!-- 左边列表 -->
			<div class="lefts">
				<div class="flex flex_a_c titles">监控列表</div>
				<div class="video-trees">
					<!-- 输入框 -->
					<!-- <el-autocomplete
						class="input-autocomplete"
						v-model="searchInfo"
						:fetch-suggestions="querySearch"
						placeholder="请输入监控点名称"
						@select="handleSelect"
						suffix-icon="el-icon-search"
						value-key="cameraName"
					></el-autocomplete> -->
					<el-input
						class="input-autocomplete"
						placeholder="请输入内容"
						v-model="searchInfo"
						@keyup.enter.native="loadData(true)"
						@submit.native.prevent
					>
						<i slot="suffix" class="el-input__icon el-icon-search cursor" @click="loadData(true)"></i>
					</el-input>
					<template v-if="restaurants.length">
						<el-tree
							ref="trees"
							:data="treeData"
							:props="defaultProps"
							highlight-current
							:default-expanded-keys="['1']"
							node-key="id"
							@node-click="handleNodeClickS"
						>
							<template #default="{ node, data }">
								<span class="custom-tree-node">
									<span v-if="data.children"
										>{{ node.label }}（<span class="on-color">{{ data.onlineNum }}</span
										>/{{ data.count }}）</span
									>
									<span
										v-else
										:class="{
											'tree-choose-active': playIdList.indexOf(data.cameraResourceId) > -1,
										}"
										><img
											class="tree-icon"
											v-if="data.online"
											src="@/assets/image/product/icon-online.png"
											alt=""
										/><img class="tree-icon" v-else src="@/assets/image/product/icon-offlInne.png" alt="" />{{
											node.label
										}}
									</span>
								</span>
							</template>
						</el-tree>
					</template>

					<div class="table-empty flex flex_col flex_ju_c flex_a_c trees-box" v-else>
						<img class="table-noimg" src="@/assets/image/home/ZWT.png" alt="" />
						<div class="table-emptyword">暂无数据</div>
					</div>
				</div>
			</div>
			<!-- 中间播放 -->
			<div class="centers">
				<div class="videos" v-if="isShowVideos">
					<videos-all-videos
						ref="hlsVideoPlayer"
						style="width: 100%; height: 100%"
						@changeVideoIndex="changeVideoIndex"
						@changeYtIndex="changeYtIndex"
					></videos-all-videos>
				</div>
				<div class="videos flex flex_col flex_a_c flex_ju_c play-error" v-else>
					<img class="table-noimg" src="@/assets/image/sf/empty.png" alt="" />
					<div class="table-emptyword">暂无播放视频</div>
				</div>
			</div>
			<!-- 右边云台控制 -->
			<div class="rights">
				<div class="flex flex_a_c titles">
					<span class="title-line"></span>
					<span class="c_45 bold">云台控制</span>
				</div>
				<div class="control-wrap">
					<div class="controls">
						<!-- 八个带小箭头的按钮，表示方向 -->
						<div
							v-for="index in 8"
							:key="index"
							class="control-jiantou"
							:class="'control-jiantou' + index"
							@click="toControl(index)"
						>
							<img
								:class="direct == index ? 'yt_chooses' : 'yt_choose'"
								src="@/assets/image/videos/yt_choose.png"
								alt=""
							/>
							<img class="icon_jiantou" :class="'jiantou_icon' + index" src="@/assets/image/videos/icon1.png" alt="" />
						</div>
						<!-- 两个中间按钮表示放大缩小 -->
						<div class="control-center div_add" @click="toCenterControl('0')">
							<img class="addicon_n" src="@/assets/image/videos/yt_addn.png" alt="" />
							<img class="addicon_h" src="@/assets/image/videos/yt_addh.png" alt="" />
							<img class="addicon_hs" v-if="direct == '0'" src="@/assets/image/videos/yt_addh.png" alt="" />
						</div>
						<div class="control-center div_jian" @click="toCenterControl(13)">
							<img class="addicon_n" src="@/assets/image/videos/yt_jiann.png" alt="" />
							<img class="addicon_h" src="@/assets/image/videos/yt_jianh.png" alt="" />
							<img class="addicon_hs" v-if="direct == 13" src="@/assets/image/videos/yt_jianh.png" alt="" />
						</div>
					</div>
					<!-- 调焦和抓拍 -->
					<div class="flex flex_a_c flex_ju_sb focus-wrap">
						<!-- 调焦 -->
						<div class="flex flex_a_c focus-focus">
							<!-- 大按钮 -->
							<div class="flex flex_a_c flex_ju_c icon_big" @click="changeFocus('-')">
								<i class="el-icon-plus"></i>
							</div>
							<div class="flex flex_a_c flex_ju_c focus-word">调焦</div>
							<!-- 小按钮 -->
							<div class="flex flex_a_c flex_ju_c icon_small" @click="changeFocus('+')">
								<i class="el-icon-minus"></i>
							</div>
						</div>
						<!-- 抓拍 -->
						<div class="flex flex_a_c flex_ju_c cursor focus-capture" @click="makeCapture">
							<img class="icon_zp" src="@/assets/image/videos/icon_zp.png" alt="" />
							<span class="font_14 c_white capture-word">抓拍</span>
						</div>
					</div>
					<!-- 分栏控制 -->
					<div class="flex flex_ju_c flex_wrap sets-wrap">
						<div
							class="t_c cursor set_div"
							v-for="(item, index) in setImgs"
							:key="index"
							:class="{ 'set-div-active': item.name == layoutName }"
							@click="changeLayout(item)"
						>
							<img class="set_icon" :src="item.icons" alt="" />
							<div class="font_14 set_name">{{ item.name }}</div>
						</div>
					</div>
				</div>
				<!-- 抓拍图片 -->
				<div class="flex flex_a_c flex_ju_sb titles pict-titles">
					<div class="flex flex_a_c">
						<span class="title-line"></span>
						<span class="c_45 bold">抓拍图片</span>
					</div>
					<div class="record-wrod">抓拍记录></div>
				</div>
				<!-- 抓拍记录 -->
				<div class="pictures-wrap">
					<div class="pictures" v-if="captureImg.length">
						<img class="picter-img" :src="item" v-for="(item, index) in captureImg" :key="index" alt="" />
					</div>
					<div class="pictures flex flex_col flex_a_c flex_ju_c" v-else>
						<img class="table-noimg" src="@/assets/image/personal/no_table.png" alt="" />
						<span class="table-emptyword">暂无抓拍图片</span>
					</div>
				</div>
			</div>
		</template>
		<div class="noSheBei" v-else>
			<img class="table-noimg" src="@/assets/image/sf/empty.png" alt="" />
			<div class="table-emptyword">用户未安装视频监控!</div>
		</div>
	</div>
</template>

<script>
/**
 * @description 远程诊断
 * platformType 平台类型 1大华,2海康
 */
import videosAllVideos from './components/videosAllVideos.vue'
import bus from '@/api/bus'
export default {
	components: { videosAllVideos },
	data() {
		return {
			searchInfo: '',
			restaurants: [],
			isInit: false, // 是否已经初始化过了，主要用于搜索为空时的判断
			// 左侧树形监控列表
			treeData: [
				{
					cameraName: '我的监控',
					id: '1',
					online: '15',
					allNum: '18',
					children: [],
				},
			],
			defaultProps: {
				children: 'children',
				label: 'cameraName',
			},
			// 视频页面的分栏设置
			setImgs: [
				{
					name: '1×1',
					nums: 1,
					icons: require('@/assets/image/videos/set_one.png'),
				},
				{
					name: '2×2',
					nums: 4,
					icons: require('@/assets/image/videos/set_two.png'),
				},
				{
					name: '1+5',
					nums: 6,
					icons: require('@/assets/image/videos/set_three.png'),
				},
				{
					name: '1+7',
					nums: 8,
					icons: require('@/assets/image/videos/set_four.png'),
				},
			],
			layoutName: '1×1', // 分栏管理的选中值
			layoutNum: 1, // 分栏窗口数
			oldLayoutNum: 1, // 旧的分栏窗口数
			isShowVideos: false,
			playVurls: '', // 正在播放的视频
			playCodeList: [], // 选中的视频集合
			playUrlList: [], // 选中播放视频的地址
			playNameList: [], // 选中播放视频的名称
			playIdList: [],
			playTypeList: [], // 选中播放视频的平台
			chooseIndex: 0, // 当前选中的第几个
			chooseMaxIndex: 0, // 分栏切换时，最多切换到了几个分栏
			chooseYtIndex: -1, // 云台控制选中值
			ytCode: '', // 云台控制的设备值
			videoUrls: process.env.VUE_APP_API_VIDEO_URL, // 视频操作相关域名
			loading: true,

			direct: '', // 云台控制的方向
			isStop: true, // 是否停止

			captureImg: [], // 抓拍图片
		}
	},
	mounted() {
		// 获取数据
		this.loadData(false)
		// 点击关闭对应的视频
		bus.$on('closeCode', (val) => {
			let codeIndex = this.playCodeList.findIndex((item) => {
				return item == val
			})
			if (codeIndex > -1) {
				if (this.playCodeList.length == 1) {
					this.playCodeList = []
				} else {
					this.$set(this.playCodeList, codeIndex, '')
				}
			}
		})
	},
	destroyed() {
		bus.$off('closeCode')
	},
	methods: {
		// 搜索
		querySearch(queryString, cb) {
			var restaurants = this.restaurants
			let results = []
			if (queryString) {
				results = restaurants.filter((item) => {
					return item.cameraName.indexOf(queryString) > -1
				})
			}
			// 调用 callback 返回建议列表的数据
			cb(results)
		},
		handleSelect(item) {
			console.log(item)
			console.log(this.searchInfo)
		},
		handleIconClick(ev) {
			console.log(ev)
		},
		// 获取数据
		loadData(isSearch) {
			// let userInfo = this.getItem('wlwUserInfo') || {}
			// let userInfos = JSON.parse(userInfo)
			let params = {
				// orgId: userInfos.orgId,
				name: this.searchInfo,
			}
			this.isInit = isSearch
			this.$http
				.getAction(this.$api.pageVideoList, params)
				.then((res) => {
					if (res.code == 200) {
						let datas = res.data || []
						this.treeData[0]['onlineNum'] = datas.onlineNum || 0
						this.treeData[0]['count'] = datas.count || 0
						this.treeData[0]['children'] = datas.data || []
						this.restaurants = datas.data || []
					}
				})
				.catch(() => {
					this.treeData = []
				})
				.finally((err) => {
					setTimeout(() => {
						this.loading = false
					}, 500)
				})
		},
		handleNodeClickS(data) {
			// 说明点击的是第一层，拦截点击事件
			if (data.videos && data.videos.length) return
			// 点击的数据
			let nodes = this.$refs.trees.getCurrentNode()
			// 获取点击数据的视频地址
			let vurl = nodes.cameraUrl || ''
			let vid = nodes.cameraResourceId || ''
			let vcode = nodes.cameraNum || ''
			let vname = nodes.cameraName || '' // 视频名称
			let platformType = nodes.platformType || '' // 视频类型  1大华,2海康

			// TODO 方法一：直接在列表中添加设备类型的字段
			let vtype = nodes.cameraType || '1' // 设备类型  摄像头类型（1枪机2球机）
			console.log(vtype)

			// 判断视频为空
			if (vurl && vurl != '' && vurl != ' ') {
				// 视频显示
				this.isShowVideos = true

				this.$nextTick(() => {
					// 视频已选中播放
					if (this.playIdList.indexOf(vid) > -1) {
						this.$message('该视频已选中播放', 'error')
						return
					}
					// 判断分栏情况
					// 只有一栏 ==== 没有数据进行添加，有数据进行编辑替换
					if (this.layoutNum == 1) {
						if (!this.playCodeList.length) {
							console.log('添加1')
							this.$refs.hlsVideoPlayer.handelVideoUrl(vcode, vurl, vname, vtype)
							// 把当前的链接放进去
							this.playCodeList.push(vcode)
							this.playUrlList.push(vurl)
							this.playNameList.push(vname)
							this.playIdList.push(vid)
							this.playTypeList.push(platformType)
						} else {
							console.log('编辑one')
							// 如果已经有正在播放的视频，进行视频的替换
							this.playCodeList = [vcode]
							this.playUrlList = [vurl]
							this.playNameList = [vname]
							this.playIdList = [vid]
							this.playTypeList = [platformType]
							this.$refs.hlsVideoPlayer.editUrl(vcode, vurl, vname, 0, vtype)
						}
					} else {
						console.log('多窗口')
						// 多栏情况
						// 还有播放窗口：当选选中窗口数大于已选中播放的列表
						if (this.layoutNum > this.playCodeList.length) {
							// 选中切换的是正在播放的，直接编辑替换
							if (this.chooseIndex < this.playCodeList.length) {
								console.log('判断编辑3')
								this.$refs.hlsVideoPlayer.editIsPlay(this.chooseIndex, true)
								this.$set(this.playCodeList, this.chooseIndex, vcode)
								this.$set(this.playUrlList, this.chooseIndex, vurl)
								this.$set(this.playNameList, this.chooseIndex, vname)
								this.$set(this.playIdList, this.chooseIndex, vid)
								this.$set(this.playTypeList, this.chooseIndex, platformType)
								this.$refs.hlsVideoPlayer.resetEdit(vcode, vurl, vname, this.chooseIndex, vtype)
							} else {
								// 判断是否是已经添加过的
								// 没有添加过，直接添加
								if (this.chooseIndex > this.chooseMaxIndex) {
									console.log('添加more')
									this.playCodeList.push(vcode)
									this.playUrlList.push(vurl)
									this.playNameList.push(vname)
									this.playIdList.push(vid)
									this.playTypeList.push(platformType)
									this.$refs.hlsVideoPlayer.handelVideoUrl(vcode, vurl, vname, vtype)
								} else {
									console.log('判断编辑1')
									this.$refs.hlsVideoPlayer.editIsPlay(this.chooseIndex, true)
									this.$set(this.playCodeList, this.chooseIndex, vcode)
									this.$set(this.playUrlList, this.chooseIndex, vurl)
									this.$set(this.playNameList, this.chooseIndex, vname)
									this.$set(this.playIdList, this.chooseIndex, vid)
									this.$set(this.playTypeList, this.chooseIndex, platformType)
									this.$refs.hlsVideoPlayer.resetEdit(vcode, vurl, vname, this.chooseIndex, vtype)
								}
							}
						} else {
							// 添加到最后一个的时候，判断是否是从少窗口切换来的
							console.log('oldLayoutNum:' + this.oldLayoutNum)
							console.log('判断编辑2')
							this.$refs.hlsVideoPlayer.editIsPlay(this.chooseIndex, true)
							this.$set(this.playCodeList, this.chooseIndex, vcode)
							this.$set(this.playUrlList, this.chooseIndex, vurl)
							this.$set(this.playNameList, this.chooseIndex, vname)
							this.$set(this.playIdList, this.chooseIndex, vid)
							this.$set(this.playTypeList, this.chooseIndex, platformType)
							this.$refs.hlsVideoPlayer.resetEdit(vcode, vurl, vname, this.chooseIndex, vtype)
						}

						this.$refs.hlsVideoPlayer.thisCodeLength = this.playCodeList.length
					}
				})
			} else {
				this.$message('暂无视频数据', 'error')
				if (!this.playCodeList.length) {
					this.isShowVideos = false
				}
			}
		},
		changeVideoIndex({ chooseIndex, maxIndex }) {
			this.chooseIndex = chooseIndex
			if (this.chooseMaxIndex < maxIndex) {
				this.chooseMaxIndex = maxIndex
			}
		},
		// 云台控制选中的改变
		changeYtIndex({ val }) {
			if (this.chooseYtIndex == val) return
			this.chooseYtIndex = val
			this.direct = ''
			if (val == -1) {
				this.ytCode = ''
			} else {
				// 获取云台控制视频的code即传参id值
				this.ytCode = this.playCodeList[val]
			}
		},
		// TODO 分栏管理选中改变
		changeLayout({ name, nums }) {
			// this.$message('该功能暂不可用', 'warn')
			// return
			// 当前分栏重复选择
			if (this.layoutName == name) return
			// 没有选中视频的时候，不允许切换分栏
			if (!this.playCodeList.length) return
			// 赋值上一个分栏窗口数，用作对比
			this.oldLayoutNum = this.layoutNum
			console.log(this.oldLayoutNum)
			// 赋值页面的窗口数
			this.$refs.hlsVideoPlayer.layoutNum = nums

			this.$refs.hlsVideoPlayer.changeYtChoose()

			let hadCodeList = []
			let hadUrlList = []
			let hadNameList = []
			let hadIdList = []
			let hadTypeList = []
			// TODO 暂时没必要进行，无连接的视频添加时已进行了判断处理，此步骤无用
			this.playCodeList.forEach((item, index) => {
				// 剔除为空的情况，进行数据重新添加
				if (item != '') {
					hadCodeList.push(item)
					hadUrlList.push(this.playUrlList[index])
					hadNameList.push(this.playNameList[index])
					hadIdList.push(this.playIdList[index])
					hadTypeList.push(this.playTypeList[index])
				}
			})

			// 如果选中切换的窗口屏幕小于当前展示的屏幕数，直接替换已经存在的，把多余的隐藏播放
			// 说明：为什么是隐藏而不是直接销毁删除
			// 答：直接删除会造成再次赋值视频无法播放的问题
			if (nums < this.layoutNum) {
				console.log('small')
				// 截取当前展示的前几个
				hadCodeList = hadCodeList.slice(0, nums)
				hadUrlList = hadUrlList.slice(0, nums)
				hadNameList = hadNameList.slice(0, nums)
				hadIdList = hadIdList.slice(0, nums)
				// 赋值当前
				this.playCodeList = [...hadCodeList]
				this.playUrlList = [...hadUrlList]
				this.playNameList = [...hadNameList]
				this.playIdList = [...hadIdList]
				this.playTypeList = [...hadTypeList]
				this.$refs.hlsVideoPlayer.thisCodeLength = hadCodeList.length

				//
				// hadCodeList.forEach((item, index) => {
				// 	this.$refs.hlsVideoPlayer.resetUrl(item, hadUrlList[index], index)
				// })
				setTimeout(() => {
					let chooseMaxIndex = this.chooseMaxIndex
					chooseMaxIndex = chooseMaxIndex + 1
					if (chooseMaxIndex == 3 || chooseMaxIndex == 5 || chooseMaxIndex == 7) {
						chooseMaxIndex = chooseMaxIndex + 1
					} else {
						chooseMaxIndex = chooseMaxIndex
					}
					for (let i = 0; i < chooseMaxIndex; i++) {
						console.log(i)
						if (hadCodeList[i] && hadCodeList[i] != '') {
							// 修改赋值
							this.$refs.hlsVideoPlayer.editIsPlay(i, true)
						} else {
							this.$refs.hlsVideoPlayer.editIsPlay(i, false)
						}
					}
					// 	// hadCodeList.forEach((item, index) => {
					// 	// 	this.$refs.hlsVideoPlayer.handelVideoUrl(item, hadUrlList[index], hadNameList[index], index)
					// 	// })
				}, 400)

				// if (hadCodeList.length > nums) {
				// 	this.$message('请先关闭其它视频', 'warn')
				// 	return
				// } else {
				// 	this.playCodeList = hadCodeList
				// 	this.playUrlList = hadUrlList
				// 	hadCodeList.forEach((item, index) => {
				// 		this.$refs.hlsVideoPlayer.editUrl(item, hadUrlList[index], index)
				// 	})
				// }
			} else {
				this.playCodeList = hadCodeList
				this.playUrlList = hadUrlList
				this.playNameList = hadNameList
				this.playIdList = hadIdList
				this.playTypeList = hadTypeList
				this.$refs.hlsVideoPlayer.thisCodeLength = hadCodeList.length

				for (let i = 0; i < hadCodeList.length; i++) {
					console.log(i)
					this.$refs.hlsVideoPlayer.editIsPlay(i, true)
				}
				// setTimeout(() => {
				// 	for (const i in hadCodeList) {
				// 		this.$refs.hlsVideoPlayer.editUrl(hadCodeList[i], hadUrlList[i], hadNameList[i], i)
				// 	}
				// }, 400)

				// hadCodeList.forEach((item, index) => {
				// 	this.$refs.hlsVideoPlayer.resetUrl(item, hadUrlList[index], index)
				// })
			}
			if (nums == this.playCodeList.length) {
				this.$refs.hlsVideoPlayer.chooseIndex = this.playCodeList.length - 1
				this.chooseIndex = this.playCodeList.length - 1
			} else {
				this.$refs.hlsVideoPlayer.chooseIndex = this.playCodeList.length
				this.chooseIndex = this.playCodeList.length
			}

			this.layoutName = name
			this.layoutNum = nums
		},
		// 方向改变
		toControl(direct) {
			// 没有操作视频
			if (!this.ytCode || this.ytCode == '') {
				return
			}
			// 获取类型
			let types = this.playTypeList[this.chooseYtIndex]
			console.log(types)
			// 大华
			if (types == 1) {
				// 是停止状态，可以进行操作
				// 不存在，直接开始
				if (this.direct == '' || this.direct == 'capture') {
					let params = {
						cameraId: this.ytCode,
						direct: direct,
						step: 30,
						stop: false,
					}
					this.direct = direct
					this.$http.postAction(this.$api.dhptzCtrl, params, this.videoUrls).then((res) => {})
				} else if (this.direct == direct) {
					// 二次点击-停止
					let params = {
						cameraId: this.ytCode,
						direct: direct,
						step: 30,
						stop: true,
					}
					this.direct = ''
					this.$http.postAction(this.$api.dhptzCtrl, params, this.videoUrls).then((res) => {})
				} else {
					let paths = ''
					if (this.direct == '0' || this.direct == '13') {
						paths = this.$api.ptzOperationCtrl
					} else {
						paths = this.$api.dhptzCtrl
					}
					// 其它操作
					// 先调取停止，然后再操控
					let params = {
						cameraId: this.ytCode,
						direct: Number(this.direct) == 13 ? 3 : Number(this.direct),
						step: 30,
						stop: true,
					}
					let startParams = {
						cameraId: this.ytCode,
						direct: direct,
						step: 30,
						stop: false,
					}
					this.$http.postAction(paths, params, this.videoUrls).then((res) => {
						console.log(res)
						if (res.msg == 'success') {
							// 调开始
							this.direct = direct
							this.$http.postAction(this.$api.dhptzCtrl, startParams, this.videoUrls).then((res) => {})
						}
					})
				}
			} else {
				// 海康
				let hkDirect = ''
				// 1上，2下，3左，4右，5左上，6左下，7右上，8右下
				// 0变倍+，1变焦+，2光圈+，3变倍-，4变焦-,5光圈-
				switch (String(direct)) {
					case '1':
						hkDirect = 2
						break
					case '2':
						hkDirect = 3
						break
					case '3':
						hkDirect = 0
						break
					case '4':
						hkDirect = 1
						break
					case '5':
						hkDirect = 6
						break
					case '6':
						hkDirect = 7
						break
					case '7':
						hkDirect = 8
						break
					case '8':
						hkDirect = 9
						break
					case '0':
						// 焦距
						hkDirect = 4
						break
					case '13':
						hkDirect = 5
						break

					default:
						break
				}
				// command 0左转 1右转 2上转 3下转 4焦距变大 5焦距变小 6左上 7左下 8右上 9右下 10焦点前移 11焦点后移 12光圈扩大 13光圈缩小 14接通雨刷开关 15开始记录轨迹 16停止记录轨迹 17开始轨迹 18停止轨迹 19到预置点
				if (this.direct == '' || this.direct == 'capture') {
					let params = {
						cameraIndexCode: this.ytCode,
						action: 0, // 0-开始 ，1-停止
						command: hkDirect,
						speed: 30,
					}
					this.direct = hkDirect
					this.$http.postAction(this.$api.hkptzCtrl, params, this.videoUrls).then((res) => {})
				} else if (this.direct == hkDirect) {
					// 二次点击-停止
					let params = {
						cameraIndexCode: this.ytCode,
						action: 1, // 0-开始 ，1-停止
						command: hkDirect,
						speed: 30,
					}
					this.direct = ''
					this.$http.postAction(this.$api.hkptzCtrl, params, this.videoUrls).then((res) => {})
				} else {
					// 其它操作
					// 先调取停止，然后再操控
					let params = {
						cameraIndexCode: this.ytCode,
						action: 1, // 0-开始 ，1-停止
						command: hkDirect,
						speed: 30,
					}
					let startParams = {
						cameraIndexCode: this.ytCode,
						action: 0, // 0-开始 ，1-停止
						command: hkDirect,
						speed: 30,
					}
					this.$http.postAction(this.$api.hkptzCtrl, params, this.videoUrls).then((res) => {
						console.log(res)
						if (res.msg == 'success') {
							// 调开始
							this.direct = hkDirect
							this.$http.postAction(this.$api.hkptzCtrl, startParams, this.videoUrls).then((res) => {})
						}
					})
				}
			}
		},
		// 视频放大缩小
		toCenterControl(direct) {
			// 没有操作视频
			if (!this.ytCode || this.ytCode == '') {
				return
			}
			// 获取类型
			let types = this.playTypeList[this.chooseYtIndex]
			console.log(types)
			if (types == 1) {
				let directs = direct == '13' ? '3' : direct
				if (this.direct == '' || this.direct == 'capture') {
					let params = {
						cameraId: this.ytCode,
						direct: Number(directs),
						step: 30,
						stop: false,
					}
					this.direct = direct
					this.$http.postAction(this.$api.ptzOperationCtrl, params, this.videoUrls).then((res) => {})
				} else if (this.direct == direct) {
					// 二次点击-停止
					let params = {
						cameraId: this.ytCode,
						direct: Number(directs),
						step: 30,
						stop: true,
					}
					this.direct = ''
					this.$http.postAction(this.$api.ptzOperationCtrl, params, this.videoUrls).then((res) => {})
				} else {
					let paths = ''
					if (this.direct == '0' || this.direct == '13') {
						paths = this.$api.ptzOperationCtrl
					} else {
						paths = this.$api.dhptzCtrl
					}
					// 其它操作
					// 先调取停止，然后再操控
					let params = {
						cameraId: this.ytCode,
						direct: Number(this.direct) == 13 ? 3 : Number(this.direct),
						step: 30,
						stop: true,
					}
					let startParams = {
						cameraId: this.ytCode,
						direct: Number(directs),
						step: 30,
						stop: false,
					}
					this.$http.postAction(paths, params, this.videoUrls).then((res) => {
						if (res.msg == 'success') {
							// 调开始
							this.direct = direct
							const { data: ress } = this.$http.postAction(this.$api.ptzOperationCtrl, startParams, this.videoUrls)
						}
					})
				}
			} else {
				// 海康
				let hkDirect = ''
				// 1上，2下，3左，4右，5左上，6左下，7右上，8右下
				// 0变倍+，1变焦+，2光圈+，3变倍-，4变焦-,5光圈-
				switch (String(direct)) {
					case '1':
						hkDirect = 2
						break
					case '2':
						hkDirect = 3
						break
					case '3':
						hkDirect = 0
						break
					case '4':
						hkDirect = 1
						break
					case '5':
						hkDirect = 6
						break
					case '6':
						hkDirect = 7
						break
					case '7':
						hkDirect = 8
						break
					case '8':
						hkDirect = 9
						break
					case '0':
						// 焦距
						hkDirect = 4
						break
					case '13':
						hkDirect = 5
						break

					default:
						break
				}
				// command 0左转 1右转 2上转 3下转 4焦距变大 5焦距变小 6左上 7左下 8右上 9右下 10焦点前移 11焦点后移 12光圈扩大 13光圈缩小 14接通雨刷开关 15开始记录轨迹 16停止记录轨迹 17开始轨迹 18停止轨迹 19到预置点
				if (this.direct == '' || this.direct == 'capture') {
					let params = {
						cameraIndexCode: this.ytCode,
						action: 0, // 0-开始 ，1-停止
						command: hkDirect,
						speed: 30,
					}
					this.direct = hkDirect
					this.$http.postAction(this.$api.hkptzCtrl, params, this.videoUrls).then((res) => {})
				} else if (this.direct == hkDirect) {
					// 二次点击-停止
					let params = {
						cameraIndexCode: this.ytCode,
						action: 1, // 0-开始 ，1-停止
						command: hkDirect,
						speed: 30,
					}
					this.direct = ''
					this.$http.postAction(this.$api.hkptzCtrl, params, this.videoUrls).then((res) => {})
				} else {
					// 其它操作
					// 先调取停止，然后再操控
					let params = {
						cameraIndexCode: this.ytCode,
						action: 1, // 0-开始 ，1-停止
						command: hkDirect,
						speed: 30,
					}
					let startParams = {
						cameraIndexCode: this.ytCode,
						action: 0, // 0-开始 ，1-停止
						command: hkDirect,
						speed: 30,
					}
					this.$http.postAction(this.$api.hkptzCtrl, params, this.videoUrls).then((res) => {
						console.log(res)
						if (res.msg == 'success') {
							// 调开始
							this.direct = hkDirect
							this.$http.postAction(this.$api.hkptzCtrl, startParams, this.videoUrls).then((res) => {})
						}
					})
				}
			}
		},
		// 调焦
		changeFocus(val) {
			let types = this.playTypeList[this.chooseYtIndex]
			// 大华
			if (types == 1) {
				let direct = ''
				// 直接操作
				if (val == '-') {
					direct = 4
				} else {
					direct = 1
				}
				if (this.direct == '' || this.direct == 'capture') {
					let params = {
						cameraId: this.ytCode,
						direct: Number(direct),
						step: 30,
						stop: false,
					}
					this.direct = direct
					this.$http.postAction(this.$api.ptzOperationCtrl, params, this.videoUrls).then((res) => {})
				} else if (this.direct == direct) {
					// 二次点击-停止
					let params = {
						cameraId: this.ytCode,
						direct: Number(direct),
						step: 30,
						stop: true,
					}
					this.direct = ''
					this.$http.postAction(this.$api.ptzOperationCtrl, params, this.videoUrls).then((res) => {})
				} else {
					// 其它操作
					// 先调取停止，然后再操控
					let params = {
						cameraId: this.ytCode,
						direct: Number(this.direct),
						step: 30,
						stop: true,
					}
					let startParams = {
						cameraId: this.ytCode,
						direct: Number(directs),
						step: 30,
						stop: false,
					}
					this.$http.postAction(paths, params, this.videoUrls).then((res) => {
						if (res.msg == 'success') {
							// 调开始
							this.direct = direct
							const { data: ress } = this.$http.postAction(this.$api.ptzOperationCtrl, startParams, this.videoUrls)
						}
					})
				}
			} else {
				// 海康
				let hkDirect = ''
				// 直接操作
				if (val == '-') {
					hkDirect = 10
				} else {
					hkDirect = 11
				}

				if (this.direct == '' || this.direct == 'capture') {
					let params = {
						cameraIndexCode: this.ytCode,
						action: 0, // 0-开始 ，1-停止
						command: hkDirect,
						speed: 30,
					}
					this.direct = hkDirect
					this.$http.postAction(this.$api.hkptzCtrl, params, this.videoUrls).then((res) => {})
				} else if (this.direct == hkDirect) {
					// 二次点击-停止
					let params = {
						cameraIndexCode: this.ytCode,
						action: 1, // 0-开始 ，1-停止
						command: hkDirect,
						speed: 30,
					}
					this.direct = ''
					this.$http.postAction(this.$api.hkptzCtrl, params, this.videoUrls).then((res) => {})
				} else {
					// 其它操作
					// 先调取停止，然后再操控
					let params = {
						cameraIndexCode: this.ytCode,
						action: 1, // 0-开始 ，1-停止
						command: hkDirect,
						speed: 30,
					}
					let startParams = {
						cameraIndexCode: this.ytCode,
						action: 0, // 0-开始 ，1-停止
						command: hkDirect,
						speed: 30,
					}
					this.$http.postAction(this.$api.hkptzCtrl, params, this.videoUrls).then((res) => {
						console.log(res)
						if (res.msg == 'success') {
							// 调开始
							this.direct = hkDirect
							this.$http.postAction(this.$api.hkptzCtrl, startParams, this.videoUrls).then((res) => {})
						}
					})
				}
			}
		},
		// 抓拍
		makeCapture() {
			// 没有操作视频
			if (!this.playCodeList[this.chooseIndex]) {
				return
			}
			let cameraId = this.playCodeList[this.chooseIndex]
			let types = this.playTypeList[this.chooseYtIndex]
			console.log(this.playCodeList[this.chooseIndex])
			console.log(this.direct)
			if (types == 1) {
				if (this.direct == '' || this.direct == 'capture') {
					let params = {
						cameraId: cameraId,
						time: 6000,
					}
					this.direct = 'capture'
					this.$nextTick(() => {
						this.$http
							.getAction(this.$api.dhCapture, params, this.videoUrls)
							.then((res) => {
								console.log(res)
							})
							.finally(() => {
								console.log('finally')
							})
					})
				} else {
					// TODO 判断已经操作的类型
				}
			} else {
				if (this.direct == '' || this.direct == 'capture') {
					let params = {
						cameraIndexCode: cameraId,
					}
					this.direct = 'capture'
					this.$nextTick(() => {
						this.$http
							.getAction(this.$api.hkCapture, params, this.videoUrls)
							.then((res) => {
								if (res && res.code == 0) {
									let datas = res.data || {}
									if (datas && datas.picUrl) {
										this.captureImg.unshift(datas.picUrl)
									}
								}
							})
							.finally(() => {
								console.log('finally')
							})
					})
				} else {
					let params = {
						cameraIndexCode: this.ytCode,
						action: 1, // 0-开始 ，1-停止
						command: this.direct,
						speed: 30,
					}
					let capparams = {
						cameraIndexCode: cameraId,
					}
					this.$http.postAction(this.$api.hkptzCtrl, params, this.videoUrls).then((res) => {
						console.log(res)
						if (res.msg == 'success') {
							// 调开始
							this.direct = 'capture'
							this.$nextTick(() => {
								this.$http
									.getAction(this.$api.hkCapture, capparams, this.videoUrls)
									.then((res) => {
										if (res.code == 0) {
											let datas = res.data || {}
											if (datas && datas.picUrl) {
												this.captureImg.unshift(datas.picUrl)
											}
										}
									})
									.finally(() => {
										console.log('finally')
									})
							})
						}
					})
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.videos-page {
	height: 100%;
	text-align: left;
	.titles {
		height: 0.8rem;
		box-sizing: border-box;
		border-bottom: 1px solid #f1f1f1;
		font-size: 0.225rem;
		font-family: Microsoft YaHei;
		padding: 0.3rem;
		font-weight: bold;
		color: #454545;
		text-align: left;
		display: flex;
		align-items: center;
		.title-line {
			display: inline-block;
			width: 5px;
			height: 18px;
			background: #459e8c;
			margin-right: 0.19rem;
		}
	}
	.lefts {
		width: 5.06rem;
		height: 100%;
		min-height: 580px;
		background: #ffffff;
		border-radius: 15px;
		.titles {
			border-bottom: 1px solid #f1f1f1;
		}
		.video-choose-active {
			color: #2daea9;
		}
	}
	.centers {
		width: 12.6rem;
		height: 100%;
		min-height: 580px;
		background: #ffffff;
		border-radius: 15px;
		.videos {
			width: 12.01rem;
			// height: 9.54rem;
			height: 95.5%;
			margin: 0.22rem auto 0;
		}
		.play-error {
			.table-noimg {
				width: 3.13rem;
			}
			.table-emptyword {
				margin-top: 0.75rem;
				font-size: 0.2rem;
				color: #797979;
			}
		}
	}
	.rights {
		// width: 2.92rem;
		width: 4.38rem;
		height: 100%;
		min-height: 580px;
		background: #ffffff;
		border-radius: 15px;
		.titles {
			border-bottom: 1px solid #f1f1f1;
		}
		.control-wrap {
			padding: 0.3rem;
		}
		// 云台控制按钮模块
		.controls {
			width: 2.35rem;
			height: 2.35rem;
			// background: rgba(231, 241, 243, 0.79);
			border-radius: 50%;
			margin: auto;
			background: url('../../assets/image/videos/yt_bg.png') no-repeat;
			background-size: 100% 100%;
			position: relative;
			.control-jiantou {
				position: absolute;
				width: 0.86rem;
				height: 0.42rem;
				cursor: pointer;
				&:hover {
					.yt_choose {
						display: block;
					}
				}
				.yt_choose {
					position: relative;
					width: 100%;
					height: 100%;
					z-index: 3;
					display: none;
				}
				.yt_chooses {
					position: relative;
					width: 100%;
					height: 100%;
					z-index: 3;
				}
				.icon_jiantou {
					width: 0.21rem;
					position: absolute;
					z-index: 4;
					top: 40%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			// 各个方向的箭头样式
			// 上
			.control-jiantou1 {
				top: 0.13rem;
				left: 0.74rem;
			}
			// 下
			.control-jiantou2 {
				bottom: 0.15rem;
				left: 0.74rem;
				transform: rotate(180deg);
			}
			// 左
			.control-jiantou3 {
				top: 0.95rem;
				left: -0.085rem;
				transform: rotate(-90deg);
			}
			// 右
			.control-jiantou4 {
				top: 0.94rem;
				right: -0.06rem;
				transform: rotate(90deg);
			}
			// 左上
			.control-jiantou5 {
				top: 0.36rem;
				left: 0.16rem;
				transform: rotate(-43deg);
			}
			// 左下
			.control-jiantou6 {
				bottom: 0.39rem;
				left: 0.16rem;
				transform: rotate(-134deg);
			}
			// 右上
			.control-jiantou7 {
				top: 0.36rem;
				right: 0.18rem;
				transform: rotate(46deg);
			}
			// 右下
			.control-jiantou8 {
				bottom: 0.4rem;
				right: 0.17rem;
				transform: rotate(134deg);
			}
			// 中间的加减
			.control-center {
				position: absolute;
				width: 1.13rem;
				height: 0.54rem;
				left: 0.61rem;
				cursor: pointer;
				&:hover {
					.addicon_h {
						display: block;
					}
				}
				&.div_add {
					top: 0.6rem;
				}
				&.div_jian {
					bottom: 0.6rem;
				}
				.addicon_n {
					position: relative;
					width: 100%;
					height: 100%;
					z-index: 3;
				}
				.addicon_h {
					position: absolute;
					width: 100%;
					height: 100%;
					z-index: 4;
					display: none;
					top: 0;
					left: 0;
				}
				.addicon_hs {
					position: absolute;
					width: 100%;
					height: 100%;
					z-index: 4;
					top: 0;
					left: 0;
				}
			}
		}
		// 调焦和抓拍
		.focus-wrap {
			height: 0.45rem;
			margin-top: 0.3rem;

			// 调焦按钮
			.focus-focus {
				width: 1.63rem;
				height: 0.45rem;
				border: 1px solid #2daea9;
				border-radius: 0.1rem;
				overflow: hidden;
			}
			.icon_big {
				width: 0.5rem;
				height: 0.45rem;
				background-color: #2daea9;
				cursor: pointer;
				color: #fff;
			}
			.focus-word {
				font-size: 0.17rem;
				color: #2daea9;
				flex: 1;
				height: 100%;
				background-color: #fff;
			}
			.icon_small {
				width: 0.5rem;
				height: 0.45rem;
				background-color: #2daea9;
				cursor: pointer;
				color: #fff;
			}

			// 抓拍按钮
			.focus-capture {
				width: 1.63rem;
				height: 0.45rem;
				background: #2daea9;
				border: 1px solid #2daea9;
				border-radius: 0.1rem;
				.icon_zp {
					width: 0.25rem;
					height: 0.25rem;
					margin-right: 0.29rem;
				}
			}
		}
		.sets-wrap {
			margin-top: 0.3rem;
			.set_div {
				width: 0.85rem;
				padding: 0.14rem 0 0.09rem;
				&:not(:first-child) {
					margin-left: 0.11rem;
				}
				&:hover {
					background-color: #e7f1f3;
				}
			}
			.set-div-active {
				background-color: #e7f1f3;
			}
			.set_name {
				color: #2daea9;
				margin-top: 0.14rem;
			}
		}
		// 抓拍图片部分
		.pict-titles {
			border-bottom: none;
			border-top: 1px solid #f1f1f1;
		}
		.record-wrod {
			font-size: 0.2rem;
			color: #2daea9;
			cursor: pointer;
		}
		.pictures {
			height: 2.48rem;
			padding-left: 0.23rem;
			padding-right: 0.07rem;
			overflow-y: scroll;
			box-sizing: border-box;
			&::-webkit-scrollbar {
				width: 6px;
				height: 10px;
				background-color: transparent;
			}

			&::-webkit-scrollbar-track {
				border-radius: 10px;
				background-color: transparent;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background-color: rgba(45, 174, 169, 0.2);
			}
			.picter-img {
				width: 1.14rem;
				height: 0.68rem;
				border: 1px solid #2daea9;
				margin-left: 0.24rem;
				margin-top: 0.2rem;
				box-sizing: border-box;
				&:nth-child(3n + 1) {
					margin-left: 0;
				}
				&:nth-child(1) {
					margin-top: 0;
				}
				&:nth-child(2) {
					margin-top: 0;
				}
				&:nth-child(3) {
					margin-top: 0;
				}
			}
			.table-noimg {
				width: 2.2rem;
			}
			.table-emptyword {
				color: #797979;
				margin-top: 0.1rem;
				margin-left: 0;
				font-size: 0.17rem;
			}
		}
	}
}
.noSheBei {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 15px;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.table-emptyword {
		margin-top: 0.75rem;
		color: #797979;
		font-size: 0.17rem;
	}
}
</style>
<style lang="scss">
.video-trees {
	height: 91%;
	overflow-y: scroll;
	padding: 0.3rem;
	.el-tree-node__content {
		height: 0.44rem;
		color: #454545;
		font-size: 0.17rem;
	}
	.el-tree-node.is-current > .el-tree-node__content {
		background-color: #e7f1f3 !important;
	}
	.input-autocomplete {
		width: 4.46rem;
		height: 0.45rem;
		background: #ffffff;

		margin-bottom: 0.25rem;
		.el-input__inner {
			border-radius: 0.23rem;
			height: 0.45rem;
			line-height: 0.45rem;
		}
	}
}
</style>
