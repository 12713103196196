<template>
	<el-drawer
		custom-class="deviceDrawer kgSetUp"
		:title="isfineTuning ? '微调操作' : '设置'"
		:visible.sync="drawer"
		size="23%"
		@close="drawerClose"
	>
		<!-- 顶部盒子 -->
		<div class="drawe-top">
			<div class="img-box">
				<img src="@/assets/\image/product/xcsb_img.png" alt="" />
			</div>
			<div class="txt-box">
				<div class="tb-li doble" :style="{ height: 32 + 'px' }">
					<div class="flex flex_a_c" :style="{ fontSize: 16 + 'px' }" v-show="!bjInputShow">
						{{ equipmentInfo.subDeviceName }}
						<div class="bj-icon" @click="editorClick(equipmentInfo.subDeviceName)"></div>
					</div>
					<div class="input-div" v-show="bjInputShow">
						<el-input type="text" placeholder="请输入内容" v-model="bjInputValue" maxlength="6">
							<i slot="suffix" class="suffix-content">
								<img src="../../../assets/image/product/queding.png" alt="" @click.stop="determineName" />
								<img src="../../../assets/image/product/quxiao.png" alt="" @click.stop="qxeditor" />
							</i>
						</el-input>
					</div>
				</div>
				<div class="tb-li">
					{{ kzxInfo.areaName }}
					<div class="line"></div>
					<span class="green"> <span v-if="kzxInfo.online">在线</span><span v-else>离线</span></span>
				</div>
				<div class="tb-li">
					设备状态：
					<template v-if="equipmentInfo.itemList[0].dataItemValue == '0'">
						<span class="blue"
							><span v-if="equipmentInfo.itemList[2].dataItemValue == '00'"
								>当前位置{{ equipmentInfo.itemList[1].dataItemValue }}%</span
							>
							<span v-if="equipmentInfo.itemList[2].dataItemValue == '10'"
								>下降到{{ equipmentInfo.itemList[1].dataItemValue }}%</span
							>
							<span v-if="equipmentInfo.itemList[2].dataItemValue == '01'"
								>上升到{{ equipmentInfo.itemList[1].dataItemValue }}%</span
							></span
						>
					</template>
					<template v-else>
						<span class="red">已急停</span>
					</template>
				</div>
			</div>
		</div>
		<!-- 圆环进度条盒子 -->
		<div class="annulus-box" v-show="!isfineTuning">
			<!-- <div class="annulus-content"></div> -->
			<Ring ref="Ring" :operateDevice="operateDeviceTuo"></Ring>
		</div>
		<!-- 微调提示信息按钮 -->
		<div class="wt-tishibox" v-show="isfineTuning">
			<div class="jiqi-box"></div>
			<div class="tishi-box">
				<div class="tishi-content">
					{{ xtInfoTxt }}
					<div class="time" v-if="showcountdownUp">
						<CountdownUp ref="countdownUp"></CountdownUp>
					</div>
				</div>
			</div>
		</div>
		<!-- 控制设备按钮盒子 -->
		<div class="control-btn-box">
			<!-- 普通调整 -->
			<div class="btn-box" v-show="!isfineTuning">
				<div
					class="item mb18"
					:class="{
						itembggreen: controlItem.falling,
						'item-disable': controlItemDisable.falling,
					}"
					@click="controlBtnClick('falling')"
				>
					<img v-if="!controlItem.falling" src="@/assets/image/product/xiajiang.png" alt="" />
					<img v-else src="@/assets/image/product/xiajiangAct.png" alt="" />
					下降
				</div>
				<div
					class="item mb18"
					:class="{
						itembggreen: controlItem.stop,
						'item-disable': controlItemDisable.stop,
					}"
					@click="controlBtnClick('stop')"
				>
					<img v-if="!controlItem.stop" src="@/assets/image/product/tingzhi.png" alt="" />
					<img v-else src="@/assets/image/product/tingzhiAct.png" alt="" />
					停止
				</div>
				<div
					class="item mb18"
					:class="{
						itembggreen: controlItem.rising,
						'item-disable': controlItemDisable.rising,
					}"
					@click="controlBtnClick('rising')"
				>
					<img v-if="!controlItem.rising" src="@/assets/image/product/shangsheng.png" alt="" />
					<img v-else src="@/assets/image/product/shangshengAct.png" alt="" />
					上升
				</div>
				<div
					class="item"
					:class="{
						itembggreen: controlItem.fineTuning,
						'item-disable': controlItemDisable.fineTuning,
					}"
					@click="fineTuning"
					@mousedown="controlItem.fineTuning = true"
					@mouseup="controlItem.fineTuning = false"
				>
					<img v-if="!controlItem.fineTuning" src="@/assets/image/product/weitiao.png" alt="" />
					<img v-else src="@/assets/image/product/weitiaoAct.png" alt="" />
					微调
				</div>
				<div class="item orgeen" :class="{ itembgorg: controlItem.scram }" @click="controlBtnClick('scram')">
					<img v-if="!controlItem.scram" src="@/assets/image/product/jiting2.png" alt="" />
					<img v-else src="@/assets/image/product/jiting2Act.png" alt="" />
					<span v-if="!controlItem.scram">急停</span><span v-else>取消</span>
				</div>
			</div>
			<!-- 微调按钮 -->
			<div class="btn-box" v-show="isfineTuning">
				<div
					class="item mb18"
					:class="{
						itembggreen: controlItem.back,
						'item-disable': controlItemDisable.back,
					}"
					@click="clickBack"
					@mousedown="controlItem.back = true"
					@mouseup="controlItem.back = false"
				>
					<img v-if="!controlItem.back" src="@/assets/image/product/back.png" alt="" />
					<img v-else src="@/assets/image/product/backAct.png" alt="" />
					返回
				</div>
				<div
					class="item mb18"
					:class="{
						itembggreen: controlItem.vtRising,
						'item-disable': controlItemDisable.vtRising,
					}"
					@click="controlBtnClick('vtRising')"
				>
					<img v-if="!controlItem.vtRising" src="@/assets/image/product/xiangshangweitiao.png" alt="" />
					<img v-else src="@/assets/image/product/xiangshangweitiaoAct.png" alt="" />
					向上
				</div>
				<div
					class="item mb18 pading15"
					:class="{
						itembggreen: controlItem.toTop,
						'item-disable': controlItemDisable.toTop,
					}"
					@click="controlBtnClick('toTop')"
				>
					<img v-if="!controlItem.toTop" src="@/assets/image/product/dingfuwei.png" alt="" />
					<img v-else src="@/assets/image/product/dingfuweiAct.png" alt="" />
					顶复位
				</div>
				<div
					class="item"
					:class="{
						itembggreen: controlItem.vtStop,
						'item-disable': controlItemDisable.vtStop,
					}"
					@click="controlBtnClick('vtStop')"
				>
					<img v-if="!controlItem.vtStop" src="@/assets/image/product/tingzhi.png" alt="" />
					<img v-else src="@/assets/image/product/tingzhiAct.png" alt="" />
					停止
				</div>
				<div
					class="item"
					:class="{
						itembggreen: controlItem.vtFall,
						'item-disable': controlItemDisable.vtFall,
					}"
					@click="controlBtnClick('vtFall')"
				>
					<img v-if="!controlItem.vtFall" src="@/assets/image/product/xaingxiaweitiao.png" alt="" />
					<img v-else src="@/assets/image/product/xiangxiaweitiaoAct.png" alt="" />
					向下
				</div>
				<div
					class="item pading15"
					:class="{
						itembggreen: controlItem.toBottom,
						'item-disable': controlItemDisable.toBottom,
					}"
					@click="controlBtnClick('toBottom')"
				>
					<img v-if="!controlItem.toBottom" src="@/assets/image/product/difuwei.png" alt="" />
					<img v-else src="@/assets/image/product/difuweiAct.png" alt="" />
					底复位
				</div>
			</div>
		</div>
		<!-- 菜单盒子 -->
		<div class="menu-box" v-show="menuisShow">
			<div class="b-line"></div>
			<div class="menu-li" @click="menuClick(3)">
				<div class="li-l"><img src="@/assets/image/product/shichang.png" alt="" /> 时长保护</div>
			</div>
			<div class="menu-li" @click="menuClick(0)">
				<div class="li-l"><img src="@/assets/image/product/shijijan.png" alt="" /> 时间日程</div>
			</div>
			<div class="menu-li" @click="menuClick(1)">
				<div class="li-l"><img src="@/assets/image/product/zidonghua.png" alt="" /> 设备自动化</div>
			</div>
			<div class="menu-li" :style="{ marginBottom: 0 }" @click="menuClick(2)">
				<div class="li-l"><img src="@/assets/image/product/zhixingjilu.png" alt="" /> 执行记录</div>
			</div>
		</div>
		<div class="line" v-show="!menuisShow"></div>
		<!-- 微调设置的时间设置（向上 向下） -->
		<div class="prompts-title" v-show="!menuisShow">
			<span v-if="controlItem.vtRising">向上微调设置</span>
			<span v-else>向下微调设置</span>
		</div>
		<div class="vt-time-box" v-show="!menuisShow">
			时间类型：
			<el-radio-group v-model="vtTimeType">
				<el-radio label="0">秒</el-radio>
				<el-radio label="1">分钟</el-radio>
			</el-radio-group>
		</div>
		<div class="vt-time-box" v-show="!menuisShow">
			运行时间：
			<div class="input-box">
				<el-input
					v-model="vtRunTime"
					placeholder="请输入时间（运行时间为大于0的正整数）"
					type="number"
					@input="vtRunTimeinput"
				></el-input>
			</div>
		</div>
		<div class="btn-box" v-show="!menuisShow">
			<div class="btn-item" @click="deviceFineTuning">确定</div>
		</div>
		<!-- 确定信息警告 -->
		<div class="Information" :class="{ 'Information-show': Information }">
			<div class="top">
				请确认现场设备实际已到达<span v-if="controlItem.toTop">顶部</span><span v-else>底部</span>位置
			</div>
			<div class="bottom">
				<div @click="alignedQuxiao">取消</div>
				<div class="line"></div>
				<div class="green" @click="aligned">确定</div>
			</div>
		</div>
		<!-- 里面潜逃的抽屉 -->
		<!-- 时长保护 -->
		<el-drawer
			title="时长保护"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.timeprotect"
			custom-class="insideDrawe flcu-box"
			@close="timeprotectClose"
		>
			<div class="timeproect">
				<span>总时长</span>
				<span class="green-span">{{ timeproect.allTime || '--' }}s</span>
			</div>
			<div class="timeproect">
				<span>单次运行时长</span>
				<span class="green-span">{{ timeproect.runTime || '--' }}s</span>
			</div>
			<div class="timeproect">
				<span>单次间隔时长</span>
				<span class="green-span">{{ timeproect.intervalTime || '--' }}s</span>
			</div>
			<div class="btn-box">
				<div class="btn-item" @click="xgscbhpzClick">修改配置</div>
			</div>
		</el-drawer>
		<!-- 时长保护配置 -->
		<el-drawer
			title="时长保护"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.protect"
			custom-class="insideDrawe flcu-box shic"
		>
			<div slot="title" class="xgmm-box">
				时长保护
				<img @click="openchangPwd" src="@/assets/image/product/xiugaimima.png" alt="" />
			</div>
			<div class="prompts-title">总时长</div>
			<div class="scbh-input">
				<el-input v-model="protectFrom.allTime" placeholder="请输入时间">
					<i slot="suffix" class="suffix-content"> 秒</i>
				</el-input>
			</div>
			<div class="warm-txt">(设备从一端运行到另一端，运行所消耗的总时间)</div>
			<div class="line"></div>
			<div class="prompts-title">单次运行时长</div>
			<div class="scbh-input">
				<el-input v-model="protectFrom.runTime" placeholder="请输入时间">
					<i slot="suffix" class="suffix-content"> 秒</i>
				</el-input>
			</div>
			<div class="warm-txt">(设备持续运行过程中，单次运行所消耗的时间)</div>
			<div class="line"></div>
			<div class="prompts-title">单次间隔时长</div>
			<div class="scbh-input">
				<el-input v-model="protectFrom.intervalTime" placeholder="请输入时间">
					<i slot="suffix" class="suffix-content"> 秒</i>
				</el-input>
			</div>
			<div class="warm-txt">(设备持续运行过程中，单次运行结束到下次运行开始的时间间隔)</div>
			<!-- 确定按钮 -->
			<div class="btn-box">
				<div class="btn-item" @click="setDuration">确定</div>
			</div>
			<Msg ref="tsmsg4"></Msg>
		</el-drawer>
		<!-- 修改安全密码 -->
		<el-drawer
			title="修改安全密码"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.changPwd"
			custom-class="insideDrawe flcu-box"
		>
			<div class="c_45">原安全密码：</div>
			<div class="xgmm-input">
				<el-input
					v-model="xiugaiPwdFrom.oldPwd"
					placeholder="请输入原安全密码"
					:maxlength="6"
					:type="xiugaiPwdType.oldPwd ? 'password' : 'text'"
				>
					<i
						slot="suffix"
						:class="xiugaiPwdType.oldPwd ? 'eyen-icon' : 'eye-icon'"
						@mousedown="zzsj"
						@click="xgpwdEyeClick('oldPwd')"
					></i
				></el-input>
			</div>
			<div class="c_45">新安全密码：</div>
			<div class="xgmm-input">
				<el-input
					v-model="xiugaiPwdFrom.newPwd"
					placeholder="请输入新安全密码"
					:maxlength="6"
					:type="xiugaiPwdType.newPwd ? 'password' : 'text'"
				>
					<i
						slot="suffix"
						:class="xiugaiPwdType.newPwd ? 'eyen-icon' : 'eye-icon'"
						@mousedown="zzsj"
						@click="xgpwdEyeClick('newPwd')"
					></i>
				</el-input>
			</div>
			<div class="c_45">确认新安全密码：</div>
			<div class="xgmm-input">
				<el-input
					v-model="xiugaiPwdFrom.againNewPwd"
					placeholder="请再次输入新安全密码"
					:maxlength="6"
					:type="xiugaiPwdType.againNewPwd ? 'password' : 'text'"
				>
					<i
						slot="suffix"
						:class="xiugaiPwdType.againNewPwd ? 'eyen-icon' : 'eye-icon'"
						@mousedown="zzsj"
						@click="xgpwdEyeClick('againNewPwd')"
					></i>
				</el-input>
			</div>
			<!-- 确定按钮 -->
			<div class="btn-box">
				<div class="btn-item" @click="updateSafePassword">确定</div>
			</div>
			<Msg ref="tsmsg5"></Msg>
		</el-drawer>
		<!-- 安全密码认证 -->
		<el-drawer
			title="安全密码认证"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.certification"
			custom-class="insideDrawe flcu-box"
			@close="certificationClose"
		>
			<div class="rz-tishibox">
				<div class="jiqi-box"></div>
				<div class="right-box">请在下方输入正确的安全密码，进行认证</div>
				<div class="wjmm" @click="insideSwitch.wjaqmm = true">忘记密码></div>
			</div>
			<div class="mminput-box flexbox">
				<div class="code-item" :class="codeValue.length == 0 && inputFocus ? 'code-item-active' : ''">
					<template v-if="codeValue[0]">●</template>
				</div>
				<div class="code-item" :class="codeValue.length == 1 && inputFocus ? 'code-item-active' : ''">
					<template v-if="codeValue[1]">●</template>
				</div>
				<div class="code-item" :class="codeValue.length == 2 && inputFocus ? 'code-item-active' : ''">
					<template v-if="codeValue[2]">●</template>
				</div>
				<div class="code-item" :class="codeValue.length == 3 && inputFocus ? 'code-item-active' : ''">
					<template v-if="codeValue[3]">●</template>
				</div>
				<div class="code-item" :class="codeValue.length == 4 && inputFocus ? 'code-item-active' : ''">
					<template v-if="codeValue[4]">●</template>
				</div>
				<div class="code-item" :class="codeValue.length >= 5 && inputFocus ? 'code-item-active' : ''">
					<template v-if="codeValue[5]">●</template>
				</div>
				<el-input
					class="input-code"
					v-model="codeValue"
					maxlength="6"
					@blur="codeInputBlur"
					@focus="codeInputFocus"
					oninput="if(value.length > 6) value = value.slice(0,6)"
					ref="myinput"
					type="number"
				>
				</el-input>
			</div>
			<div class="btn-box">
				<div class="btn-item" @click="mmrzClick">确定</div>
			</div>
			<Msg ref="tsmsg3"></Msg>
		</el-drawer>
		<!-- 忘记安全密码 -->
		<el-drawer
			title="忘记安全密码"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.wjaqmm"
			custom-class="insideDrawe flcu-box"
			@close="wjaqmmClose"
		>
			<div class="c_45">登录密码：</div>
			<div class="xgmm-input">
				<el-input
					v-model="wjaqmmFrom.Pwd"
					placeholder="请输入登录密码"
					:maxlength="6"
					:type="wjaqmmPwdType.Pwd ? 'password' : 'text'"
				>
					<i
						slot="suffix"
						:class="wjaqmmPwdType.Pwd ? 'eyen-icon' : 'eye-icon'"
						@mousedown="zzsj"
						@click="wjpwdEyeClick('Pwd')"
					></i>
				</el-input>
			</div>
			<div class="c_45">新安全密码：</div>
			<div class="xgmm-input">
				<el-input
					v-model="wjaqmmFrom.newPwd"
					placeholder="请输入新安全密码"
					:maxlength="6"
					:type="wjaqmmPwdType.newPwd ? 'password' : 'text'"
				>
					<i
						slot="suffix"
						:class="wjaqmmPwdType.newPwd ? 'eyen-icon' : 'eye-icon'"
						@mousedown="zzsj"
						@click="wjpwdEyeClick('newPwd')"
					></i>
				</el-input>
			</div>
			<div class="c_45">确认新安全密码：</div>
			<div class="xgmm-input">
				<el-input
					v-model="wjaqmmFrom.againNewPwd"
					placeholder="请再次输入新安全密码"
					:maxlength="6"
					:type="wjaqmmPwdType.againNewPwd ? 'password' : 'text'"
				>
					<i
						slot="suffix"
						:class="wjaqmmPwdType.againNewPwd ? 'eyen-icon' : 'eye-icon'"
						@mousedown="zzsj"
						@click="wjpwdEyeClick('againNewPwd')"
					></i>
				</el-input>
			</div>
			<!-- 确定按钮 -->
			<div class="btn-box">
				<div class="btn-item" @click="wjaqmmQDClick">确定</div>
			</div>
			<Msg ref="tsmsgwjmm"></Msg>
		</el-drawer>
		<!-- 时间日程 -->
		<el-drawer
			title="时间日程"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.schedule"
			custom-class="insideDrawe flcu-box"
		>
			<!-- 添加日程盒子 -->
			<div class="addrc-box" @click="openBjschedule(false)">
				<img src="@/assets/image/product/tianjia.png" alt="" />
				<div>点击添加时间日程</div>
			</div>
			<div class="line"></div>
			<!-- 列表 -->
			<div class="schedule-list" v-if="scheduleList.length">
				<div class="schedule-item" v-for="(item, index) in scheduleList" :key="index">
					<div class="item-header">
						<div class="header-left">
							<img v-if="item.executionCycle == '1'" src="@/assets/image/product/shizhong.png" alt="" />
							<img v-if="item.executionCycle == '2'" src="@/assets/image/product/meizhou.png" alt="" />
							<img v-if="item.executionCycle == '4'" src="@/assets/image/product/rili.png" alt="" />
							<span v-if="item.executionCycle == '1'">
								<span v-if="item.exerciseDate == '1'">每天</span>
								<span v-else>每{{ item.exerciseDate }}天</span>
							</span>
							<span v-if="item.executionCycle == '2'">
								<span>{{ item.date }}</span>
							</span>
							<span v-if="item.executionCycle == '4'">
								<span>{{ item.exerciseDate }}</span>
							</span>
						</div>
						<div class="header-right">
							<img
								v-if="item.effectiveState == '1'"
								src="@/assets/image/product/qiyong.png"
								alt=""
								@click="statusClick(item)"
							/>
							<img v-else src="@/assets/image/product/jinyong.png" alt="" @click="statusClick(item)" />
						</div>
					</div>
					<div class="item-top">
						<!-- <div class="r-jiao" :class="{ 'r-jiao-no': !item.iseffect }">
              <span v-if="item.iseffect">已生效</span><span v-else>未生效</span>
            </div> -->
						<div class="lie-1">
							<div>
								时间：<span>{{ item.time }}</span>
							</div>
							<div>
								动作：
								<!-- <span v-if="item.action">打开</span
                ><span v-else>关闭</span> -->
								{{ item.action }}
							</div>
						</div>
						<div class="lie-1 mt20">
							<div>是否生效：<span v-if="item.effectiveState == '1'">已生效</span><span v-else>未生效</span></div>
						</div>
					</div>
					<div class="item-bottom">
						<!-- <div
              class="cz-div blue"
              v-if="!item.iseffect"
              @click="timeConfigEnable(item.id)"
            >
              <img src="@/assets/image/product/xiafa.png" alt="" />
              下发
            </div>
            <div class="cz-line" v-if="!item.iseffect"></div> -->
						<div class="cz-div green" @click="openBjschedule(true, item)">
							<img src="@/assets/image/product/icon-bj.png" alt="" />
							编辑
						</div>
						<div class="cz-line"></div>
						<div class="cz-div red" @click="openscsjrcqrxx(item)">
							<img src="@/assets/image/product/icon-sc.png" alt="" />
							删除
						</div>
					</div>
				</div>
			</div>
			<div class="schedule-list-no" v-else>
				<img src="@/assets/image/home/ZWT.png" alt="" />
			</div>
			<!-- 编辑时间日程 -->
			<el-drawer
				title="时间日程"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.bjschedule"
				custom-class="insideDrawe flcu-box"
				@close="bjscheduleClose"
			>
				<div class="prompts-title">时间选择</div>
				<div class="flex flex_a_c" :style="{ marginTop: 21 + 'px' }">
					时间选择：
					<div class="time-box">
						<el-time-picker
							v-model="timercFrom.time"
							placeholder="任意时间点"
							format="HH:mm"
							value-format="HH:mm"
							:clearable="false"
						>
							<i slot="suffix" class="el-input__icon el-icon-date"></i>
						</el-time-picker>
					</div>
				</div>
				<div class="time-type-box">
					<el-radio-group v-model="timercFrom.timeType">
						<div class="time-type-li" @click="timercFrom.timeType = '1'">
							<div class="li-head">每天<el-radio label="1"></el-radio></div>
							<div class="li-bottom" :class="{ 'li-bottom-show': timercFrom.timeType == '1' }">
								<el-radio-group v-model="timercFrom.timeNumberType">
									<el-radio label="1">每天</el-radio>
									<el-radio label="2"
										>每
										<div class="input-box">
											<el-input v-model="timercFrom.timeNumber" type="number"></el-input>
										</div>
										天</el-radio
									>
								</el-radio-group>
							</div>
						</div>
						<div class="time-type-li" @click="timercFrom.timeType = '2'">
							<div class="li-head">每周执行<el-radio label="2"></el-radio></div>
							<div class="li-bottom" :class="{ 'li-bottom-show': timercFrom.timeType == '2' }">
								<el-checkbox-group v-model="timercFrom.checkList">
									<el-checkbox label="1">周一</el-checkbox>
									<el-checkbox label="2">周二</el-checkbox>
									<el-checkbox label="3">周三</el-checkbox>
									<el-checkbox label="4">周四</el-checkbox>
									<el-checkbox label="5">周五</el-checkbox>
									<el-checkbox label="6">周六</el-checkbox>
									<el-checkbox label="7">周日</el-checkbox>
								</el-checkbox-group>
							</div>
						</div>
						<div class="time-type-li" @click="timercFrom.timeType = '4'">
							<div class="li-head">指定日期<el-radio label="4"></el-radio></div>
							<div class="li-bottom border-b" :class="{ 'li-bottom-show': timercFrom.timeType == '4' }">
								<div class="picktime-box">
									<el-date-picker
										v-model="timercFrom.pickTime"
										size="small"
										type="date"
										placeholder="请选择日期"
										popper-class="pick-poper"
										class="picker-div"
										format="yyyy-MM-dd"
										value-format="yyyy-MM-dd"
										prefix-icon=" "
										clear-icon="el-icon-date"
										:clearable="false"
										:editable="false"
										:picker-options="pickerOptions0"
									>
									</el-date-picker>
									<i class="pick-icon el-icon-date"></i>
								</div>
							</div>
						</div>
					</el-radio-group>
				</div>
				<div class="line"></div>
				<div class="prompts-title">执行动作</div>
				<div class="zxaction-box">
					<el-radio-group v-model="timercFrom.action">
						<!-- <el-radio label="0">上升</el-radio>
            <el-radio label="1">停止</el-radio>
            <el-radio label="2">下降</el-radio> -->
						<el-radio
							:label="item.commandsItemCode"
							v-for="(item, index) in equipmentInfo.commandsItemList"
							:key="index"
							>{{ item.commandsItemName }}</el-radio
						>
					</el-radio-group>
				</div>
				<!-- 确定按钮 -->
				<div class="btn-box">
					<div class="btn-item" @click="scheduleupto">保存并下发</div>
				</div>
				<Msg ref="tsmsg6"></Msg>
			</el-drawer>
			<!-- 确定删除信息警告 -->
			<div class="Information" :class="{ 'Information-show': Information }">
				<div class="top">确定删除吗？</div>
				<div class="bottom">
					<div @click="Information = false">取消</div>
					<div class="line"></div>
					<div class="green" @click="scheduleDel">确定</div>
				</div>
			</div>
			<Msg ref="tsmsg2"></Msg>
		</el-drawer>
		<!-- 设备自动化展示页 -->
		<el-drawer
			title="设备自动化"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.automation"
			custom-class="insideDrawe flcu-box"
		>
			<div class="prompts-title">条件选择</div>
			<div class="table-box">
				<div class="table-li">
					<div class="li-left border-bottom border-right">传感设备</div>
					<div class="li-right border-bottom">
						{{ automationzsFrom.subDeviceName }}
					</div>
				</div>
				<div class="table-li">
					<div class="li-left border-right">环境参数</div>
					<div class="li-right">{{ automationzsFrom.dataItemName }}</div>
				</div>
			</div>
			<div class="line"></div>
			<div class="prompts-title">执行动作</div>
			<div class="table-box">
				<div class="table-li">
					<div class="li-left border-bottom border-right">高于上限</div>
					<div class="li-right border-bottom">
						{{ automationzsFrom.rightVal }}
					</div>
				</div>
				<div class="table-li">
					<div class="li-left border-right">执行动作</div>
					<div class="li-right green">
						{{ automationzsFrom.actionItemNameRight }}
					</div>
				</div>
			</div>
			<div class="table-box">
				<div class="table-li">
					<div class="li-left border-bottom border-right">低于下限</div>
					<div class="li-right border-bottom">
						{{ automationzsFrom.leftVal }}
					</div>
				</div>
				<div class="table-li">
					<div class="li-left border-right">执行动作</div>
					<div class="li-right red">
						{{ automationzsFrom.actionItemNameLeft }}
					</div>
				</div>
			</div>
			<div class="line"></div>
			<div class="prompts-title">下发结果</div>
			<div class="table-box">
				<div class="table-li">
					<div class="li-left border-right">是否生效</div>
					<div class="li-right green" v-if="automationzsFrom.effectiveState == '1'">已生效</div>
					<div class="li-right red" v-if="automationzsFrom.effectiveState == '0'">未生效</div>
				</div>
			</div>
			<div class="btn-box">
				<div v-if="automationzsFrom.id" class="btn-item cancel" @click="Information = true">删除配置</div>
				<div v-if="automationzsFrom.id" class="btn-item" @click="openautomationbj">修改配置</div>
			</div>
			<!-- 确定删除信息警告 -->
			<div class="Information" :class="{ 'Information-show': Information }">
				<div class="top">确定删除吗？</div>
				<div class="bottom">
					<div @click="Information = false">取消</div>
					<div class="line"></div>
					<div class="green" @click="delautomation">确定</div>
				</div>
			</div>
		</el-drawer>
		<!-- 设备自动化编辑页 -->
		<el-drawer
			title="设备自动化"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.automationbj"
			custom-class="insideDrawe flcu-box"
			@close="automationbjClose"
		>
			<div class="prompts-title">条件选择</div>
			<div class="flex flex_a_c" :style="{ marginTop: 21 + 'px' }">
				传感设备：
				<div class="time-box">
					<el-select v-model="automationbjFrom.sensorNum" placeholder="请选择" @change="sbzdhCgqChange">
						<el-option
							v-for="item in cgqList"
							:key="item.subDeviceNum"
							:label="item.subDeviceName"
							:value="item.subDeviceNum"
						>
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="parameter-box">
				<div class="box-header">环境参数</div>
				<div class="box-content">
					<el-radio-group v-model="automationbjFrom.dataItemCode" v-if="automationbjFrom.equipment !== ''">
						<el-radio :label="item.dataItemCode" v-for="(item, index) in cjxList" :key="index">{{
							item.dataItemName
						}}</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="line"></div>
			<div class="prompts-title">执行动作</div>
			<div class="zxaction-box2">
				<div class="item-li">
					上限值：
					<div class="input-box">
						<el-input
							v-model="automationbjFrom.rightVal"
							placeholder="设置上限值"
							@input="onlyNumber($event, 'rightVal')"
							type="number"
						></el-input>
					</div>
				</div>
				<el-radio-group v-model="automationbjFrom.actionItemCodeRight">
					<el-radio
						:label="item.commandsItemCode"
						v-for="(item, index) in equipmentInfo.commandsItemList"
						:key="index"
						>{{ item.commandsItemName }}</el-radio
					>
				</el-radio-group>
				<div class="item-li">
					下限值：
					<div class="input-box">
						<el-input
							v-model="automationbjFrom.leftVal"
							placeholder="设置下限值"
							@input="onlyNumber($event, 'leftVal')"
							type="number"
						></el-input>
					</div>
				</div>
				<el-radio-group v-model="automationbjFrom.actionItemCodeLeft">
					<el-radio
						:label="item.commandsItemCode"
						v-for="(item, index) in equipmentInfo.commandsItemList"
						:key="index"
						>{{ item.commandsItemName }}</el-radio
					>
				</el-radio-group>
			</div>
			<div class="btn-box">
				<div class="btn-item" @click="sbzdhIssued">保存并下发</div>
			</div>
			<Msg ref="tsmsg7"></Msg>
		</el-drawer>
		<!-- 执行记录 -->
		<el-drawer
			title="执行记录"
			:append-to-body="true"
			size="23%"
			:visible.sync="insideSwitch.perform"
			custom-class="insideDrawe flcu-box"
			@close="performClose"
		>
			<!-- 查询条件盒子 -->
			<div class="search-box-kgsb">
				<span>查询条件</span>
				<div class="saach-item">
					<el-date-picker
						v-model="performFrom.time"
						type="daterange"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd"
						@change="operationRecord"
						:picker-options="pickerOptions1"
					>
					</el-date-picker>
				</div>
				<div class="saach-item flex flex_ju_sb">
					<div class="flex-sel">
						<el-select clearable v-model="performFrom.operation" placeholder="请选择操作源" @change="operationRecord">
							<el-option :label="item.dictLabel" :value="item.dictValue" v-for="(item, index) in osList" :key="index">
							</el-option>
						</el-select>
					</div>
					<div class="flex-sel">
						<el-select clearable v-model="performFrom.status" placeholder="请选择执行状态" @change="operationRecord">
							<el-option :label="item.dictLabel" :value="item.dictValue" v-for="(item, index) in orList" :key="index">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			<!-- 查询列表盒子 -->
			<div class="seach-list" v-if="performList.length">
				<div class="list-item" v-for="(item, index) in performList" :key="index" @click="performItemClick(index)">
					<div class="list-item-top">
						<div>{{ item.subDeviceName }}</div>
						<div class="r-div" v-if="item.operationResult == '1'">
							<img src="../../../assets/image/equipment/icon-chengg.png" alt="" />
							执行中
						</div>
						<div class="r-div r-div-green" v-if="item.operationResult == '2'">
							<img src="../../../assets/image/equipment/icon-shibia.png" alt="" />
							执行失败
						</div>
						<div class="r-div" v-if="item.operationResult == '3'">
							<img src="../../../assets/image/equipment/icon-chengg.png" alt="" />
							执行成功
						</div>
					</div>
					<div class="list-item-bot">
						<div class="lib-div ellipsis">
							<i>所属区域:</i>
							<span>{{ item.areaName || '--' }}</span>
						</div>
						<div class="lib-div ellipsis">
							<i>执行时间:</i><span>{{ item.operateTime || '--' }}</span>
						</div>
						<div class="lib-div ellipsis">
							<i>操作源:</i><span>{{ item.operationSourceName || item.operationSource }}</span>
						</div>
						<div class="lib-div ellipsis">
							<i>执行内容:</i><span>{{ item.recordContents || '--' }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="seach-list-no" v-else>
				<img src="@/assets/image/home/ZWT.png" alt="" />
			</div>
			<!-- 执行记录详情 -->
			<el-drawer
				:title="`详情（${performInfoObj.deviceName}-${performInfoObj.subDeviceName}）`"
				:append-to-body="true"
				size="23%"
				:visible.sync="insideSwitch.executionInfo"
				custom-class="insideDrawe flcu-box"
			>
				<div class="performInfo">
					<div class="info-item">
						<div class="title">所属区域:</div>
						<div class="cont">{{ performInfoObj.areaName || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">执行时间:</div>
						<div class="cont">{{ performInfoObj.operateTime || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">操作源:</div>
						<div class="cont">
							{{ performInfoObj.operationSourceName || performInfoObj.operationSource }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">产品类型:</div>
						<div class="cont">
							{{ performInfoObj.productType || '--' | ProductType }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">产品名称:</div>
						<div class="cont">{{ performInfoObj.productName || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">设备编号:</div>
						<div class="cont">{{ performInfoObj.deviceNum || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">业务类型:</div>
						<div class="cont">
							{{ performInfoObj.businessTypeName || '--' }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">操作账号:</div>
						<div class="cont">
							{{ performInfoObj.operationAccount || '--' }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">位置信息:</div>
						<div class="cont">{{ performInfoObj.ipAddress || '--' }}</div>
					</div>
					<div class="info-item">
						<div class="title">执行状态:</div>
						<div class="cont">
							<span v-if="performInfoObj.operationResult == '2'">执行失败</span>
							<span v-if="performInfoObj.operationResult == '3'">执行成功</span>
						</div>
					</div>
					<div class="info-item">
						<div class="title">执行条件:</div>
						<div class="cont">
							{{ performInfoObj.executionCondition || '--' }}
						</div>
					</div>
					<div class="info-item">
						<div class="title">执行内容:</div>
						<div class="cont">{{ performInfoObj.recordContents || '--' }}</div>
					</div>
				</div>
			</el-drawer>
		</el-drawer>
		<Msg ref="tsmsg"></Msg>
	</el-drawer>
</template>

<script>
import Msg from '@/components/msg.vue' //信息提示组件
// import Ring from "./Ring.vue";
import Ring from './RingTwo.vue'
import CountdownUp from './CountdownUp.vue'
export default {
	components: { Msg, Ring, CountdownUp },
	data() {
		return {
			// 控制抽屉的开关
			drawer: false,
			// 控制箱信息
			kzxInfo: {},
			// 设备信息
			equipmentInfo: {
				subDeviceNum: '',
				subDeviceName: '',
				subDeviceType: '',
				subDeviceIcon: '',
				itemList: [
					{
						dataItemCode: 'e_stop',
						dataItemName: '急停',
						dataItemValue: '0',
					},
					{
						dataItemCode: 'khd',
						dataItemName: '设备开度值',
						dataItemValue: '0',
					},
					{
						dataItemCode: 'status',
						dataItemName: '状态',
						dataItemValue: '00',
					},
				],
				commandsItemList: [
					{
						commandsItemCode: '00',
						commandsItemName: '停止',
					},
					{
						commandsItemCode: '10',
						commandsItemName: '下降',
					},
					{
						commandsItemCode: '01',
						commandsItemName: '上升',
					},
				],
			},
			// 传感器设备列表信息
			cgqList: [
				{
					subDeviceNum: '--',
					subDeviceName: '--',
					subDeviceType: '--',
					subDeviceIcon: '--',
					itemList: [],
					commandsItemList: [],
				},
			],
			// 采集项列表
			cjxList: [],
			// 控制里面抽屉的开关
			insideSwitch: {
				timeprotect: false, //时长保护
				protect: false, //时长保护配置
				certification: false, //安全密码认证
				changPwd: false, //修改安全密码
				wjaqmm: false, //忘记安全密码
				schedule: false, //时间日程
				bjschedule: false, //编辑时间日程
				automation: false, //设备自动化展示页
				automationbj: false, //设备自动化编辑页
				perform: false, //执行记录
				executionInfo: false, //执行记录详情
			},
			//   控制编辑输入框的显示
			bjInputShow: false,
			//   编辑输入框内容
			bjInputValue: '',
			//   时间日程列表
			scheduleList: [
				// {
				//   time: "16.50",
				//   action: true,
				//   date: "每周（周二、周三、周四、周五）",
				//   iseffect: true,
				// },
				// {
				//   time: "16.50",
				//   action: true,
				//   date: "每周（周二、周三、周四、周五）",
				//   iseffect: false,
				// },
			],
			// 控制确定删除弹窗
			Information: false,
			//   时间日程编辑表单
			timercFrom: {
				time: '',
				timeType: '1', //1每天2每周3指定日期
				timeNumberType: '1', //1固定1天2不固定几天
				timeNumber: '',
				checkList: ['1'],
				pickTime: '',
				action: '', //0上升1停止2下降
			},
			// 时间日程是新增还是编辑
			timeRcbjOradd: false, //false 新增 true编辑
			// 当前编辑对象
			nowbjTimeRcObj: {},
			// 当前删除对象
			nowdelTimeRcObj: {},
			// 设备自动化展示表单
			automationzsFrom: {
				id: null,
				sensorNum: '--',
				subDeviceName: '--',
				dataItemCode: '--',
				dataItemName: '--',
				rightVal: '--', //上限
				actionItemCodeRight: '--', //0上升1停止2下降
				actionItemNameRight: '--',
				leftVal: '--', //下限
				actionItemCodeLeft: '--', //0上升1停止2下降
				actionItemNameLeft: '--',
				effectiveState: '--',
			},
			// 设备自动化编辑表单
			automationbjFrom: {
				sensorNum: '',
				dataItemCode: '',
				rightVal: '', //上限
				actionItemCodeRight: '', //0上升1停止2下降
				leftVal: '', //下限
				actionItemCodeLeft: '', //0上升1停止2下降
			},
			// 设备自动化设备列表
			equipmentList: [
				// {
				//   value: "选项1",
				//   label: "黄金糕",
				// },
				// {
				//   value: "选项2",
				//   label: "双皮奶",
				// },
				// {
				//   value: "选项3",
				//   label: "蚵仔煎",
				// },
				// {
				//   value: "选项4",
				//   label: "龙须面",
				// },
				// {
				//   value: "选项5",
				//   label: "北京烤鸭",
				// },
			],
			// 执行记录时间查询
			performFrom: {
				time: '', //时间
				operation: '', //操作源
				status: '', //执行状态
			},
			// 执行记录操作源数组
			osList: [],
			// 执行记录操作结果数组
			orList: [],
			// 业务类型数组
			ywList: [],
			// 执行记录列表数组
			performList: [
				// {
				//   name: "放风机01号",
				//   area: "某某区域",
				//   time: "2022-05-12 15:43:24",
				//   source: "App",
				//   content:
				//     "执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容",
				// },
				// {
				//   name: "放风机01号",
				//   area: "某某区域",
				//   time: "2022-05-12 15:43:24",
				//   source: "App",
				//   content:
				//     "执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容",
				// },
				// {
				//   name: "放风机01号",
				//   area: "某某区域",
				//   time: "2022-05-12 15:43:24",
				//   source: "App",
				//   content:
				//     "执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容",
				// },
				// {
				//   name: "放风机01号",
				//   area: "某某区域",
				//   time: "2022-05-12 15:43:24",
				//   source: "App",
				//   content:
				//     "执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容",
				// },
			],
			// 执行记录详情对象
			performInfoObj: {
				// name: "控制箱一号-放风机01号",
				// area: "某某区域",
				// time: "2022-05-12 15:43:24",
				// source: "App",
				// cpType: "控制箱设备",
				// cpName: "控制箱一号",
				// cpNum: "12313454645478748",
				// ywType: "灾害模式",
				// czNumber: "4564654645656",
				// address: "河南省焦作市山阳区",
				// performState: "成功",
				// performconditions:
				//   "执行条件执行条件执行条件，执行条件执行条件执行条件执行条件执行条件，执行条件执行条件，执行条件执行条件执行条件执行条件执行条件，执行条。",
				// content:
				//   "执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容执行内容",
			},
			//   修改安全密码对象
			xiugaiPwdFrom: {
				oldPwd: '',
				newPwd: '',
				againNewPwd: '',
			},
			//   修改安全密码对象显示方式
			xiugaiPwdType: {
				oldPwd: true,
				newPwd: true,
				againNewPwd: true,
			},
			// 忘记安全密码对象
			wjaqmmFrom: {
				Pwd: '',
				newPwd: '',
				againNewPwd: '',
			},
			// 忘记安全密码对象显示方式
			wjaqmmPwdType: {
				Pwd: true,
				newPwd: true,
				againNewPwd: true,
			},
			// 时长保护
			timeproect: {
				allTime: '--',
				runTime: '--',
				intervalTime: '--',
			},
			//   时长保护配置
			protectFrom: {
				allTime: '',
				runTime: '',
				intervalTime: '',
			},
			// 控制设备操作按钮点击
			controlItem: {
				falling: false, //下降
				stop: true, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: false, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			},
			// 是否显示微调倒计时
			showcountdownUp: false,
			// 控制设备操作按钮禁用
			controlItemDisable: {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: false, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			},
			// 普通操作还是微操
			isfineTuning: false,
			// 微调操作文字信息
			xtInfoTxt: '请使用下方操作，完成您的微调。',
			// 菜单列表是否显示
			menuisShow: true,
			// 微调时间类型
			vtTimeType: '0', //0秒1分钟
			// 微调运行时间
			vtRunTime: '',
			// 认证安全密码
			codeValue: '',
			inputFocus: false,
			pickerOptions0: {
				disabledDate: (time) => {
					let num = new Date().getTime()
					return time.getTime() < num - 86400000
				},
			},
			pickerOptions1: {
				disabledDate: (time) => {
					let num = new Date().getTime()
					return time.getTime() > num
				},
			},
		}
	},
	methods: {
		// 抽屉关闭
		drawerClose() {
			this.controlItemDisable = {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: false, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			}
			this.isfineTuning = false
			this.menuisShow = true
			this.$refs.Ring.isTuoRun = false
			this.drawer = false
		},
		// 点击编辑按钮
		editorClick(value) {
			this.bjInputValue = value
			this.bjInputShow = true
		},
		// 取消编辑
		qxeditor() {
			this.bjInputShow = false
		},
		// 确认编辑
		determineName() {
			// this.bjInputShow = false;
			let deviceNum = this.kzxInfo.deviceNum
			let serviceId = this.equipmentInfo.subDeviceNum
			let serviceName = this.bjInputValue
			let objRegExp = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
			if (objRegExp.test(this.bjInputValue)) {
				this.updateControlName(deviceNum, serviceId, serviceName)
			} else {
				this.$refs.tsmsg.msgShow('名称只能是中文，英文，数字，下划线', 1.5)
			}
		},
		// 修改设备名称
		updateControlName(deviceId, controlCompNum, dccName) {
			let params = {
				deviceId: deviceId,
				controlCompNum: controlCompNum,
				dccName: dccName,
			}
			this.$http.postAction(this.$api.updateControlName, params).then((res) => {
				if (res.code == 200) {
					this.bjInputShow = false
					this.$refs.tsmsg.msgShow('修改成功', 1.5)
					this.$bus.$emit('xiugaiNameHuiD')
				} else {
					if (res.msg == '修改失败，服务id不存在，或者名称与原名称重复！') {
						this.bjInputShow = false
						this.$refs.tsmsg.msgShow('修改失败，不能和原名称一样', 1.5)
						return
					}
					this.bjInputShow = false
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
				}
			})
		},
		// 圆环拖动赋值
		yuanhuanTValue() {
			this.$refs.Ring.sliderValueTwo = this.equipmentInfo.itemList[1].dataItemValue - 0
		},
		// 圆环进度赋值
		yuanhuanValue() {
			this.$refs.Ring.finishCount = this.equipmentInfo.itemList[1].dataItemValue
			this.$refs.Ring.sliderValue = this.equipmentInfo.itemList[1].dataItemValue - 0
			if (!this.$refs.Ring.isTuoRun) {
				this.$refs.Ring.sliderValueTwo = this.equipmentInfo.itemList[1].dataItemValue - 0
			}
			this.equipmentInfo.itemList.forEach((item) => {
				this.equipmentInfo.commandsItemList.forEach((item2) => {
					if (item2.commandsItemCode == item.dataItemValue) {
						let commandsItemName = item2.commandsItemName
						if (commandsItemName == '停止') {
							this.$refs.Ring.status = '0'
							this.$refs.Ring.color = 'rgba(45, 174, 169, 1)'
							this.$refs.Ring.color2 = this.$refs.Ring.color
							this.$refs.Ring.sliderValueTwo = this.equipmentInfo.itemList[1].dataItemValue - 0
						}
						if (commandsItemName == '下降') {
							this.$refs.Ring.status = '2'
							this.$refs.Ring.color = 'rgba(45, 174, 169, 1)'
						}
						if (commandsItemName == '上升') {
							this.$refs.Ring.status = '1'
							this.$refs.Ring.color = 'rgba(45, 174, 169, 1)'
						}
						if (this.$refs.Ring.isTuoRun) {
							if (this.$refs.Ring.sliderValueTwo > this.$refs.Ring.sliderValue) {
								this.$refs.Ring.color2 = 'rgba(45, 174, 169, .3)'
								this.$refs.Ring.qiuColor = 'rgba(45, 174, 169, 1)'
								this.$refs.Ring.qiuColor2 = '#fff'
							} else if (this.$refs.Ring.sliderValueTwo < this.$refs.Ring.sliderValue) {
								this.$refs.Ring.color2 = '#bae2e1'
								this.$refs.Ring.qiuColor = 'rgba(45, 174, 169, 1)'
								this.$refs.Ring.qiuColor2 = '#fff'
							} else {
								this.$refs.Ring.color2 = this.$refs.Ring.color
							}
						}
					}
				})
			})
			// 判断是否急停
			this.equipmentInfo.itemList.forEach((item) => {
				if (item.dataItemCode == 'e_stop') {
					if (item.dataItemValue == '1') {
						this.$refs.Ring.status = '-1'
						this.$refs.Ring.color = 'rgba(248, 134, 56, 1)'
						this.$refs.Ring.color2 = 'rgba(248, 134, 56, 1)'
						this.$refs.Ring.isHua = false
					}
				}
			})
			// this.$refs.Ring.draw();
		},
		// 菜单点击
		menuClick(index) {
			// 时长保护
			if (index == 3) {
				if (this.controlItem.stop || this.controlItem.scram || this.controlItem.vtStop) {
					this.insideSwitch.certification = true
					// this.$refs.myinput.focus();
				} else {
					this.$refs.tsmsg.msgShow('当前设备正在操作', 1.5)
					return
				}
			}
			// 时间日程
			if (index == 0) {
				if (this.controlItem.stop || this.controlItem.scram || this.controlItem.vtStop) {
					this.autoTimeConfig()
					this.insideSwitch.schedule = true
				} else {
					this.$refs.tsmsg.msgShow('当前设备正在操作', 1.5)
					return
				}
			}
			//   设备自动化
			if (index == 1) {
				if (this.controlItem.stop || this.controlItem.scram || this.controlItem.vtStop) {
					this.thresholdList()
					this.insideSwitch.automation = true
				} else {
					this.$refs.tsmsg.msgShow('当前设备正在操作', 1.5)
					return
				}
			}
			//   执行记录
			if (index == 2) {
				if (this.controlItem.stop || this.controlItem.scram || this.controlItem.vtStop) {
					this.queryRecord()
					this.operationRecord()
					this.insideSwitch.perform = true
				} else {
					this.$refs.tsmsg.msgShow('当前设备正在操作', 1.5)
					return
				}
			}
		},
		// 验证码输入框失去焦点
		codeInputBlur() {
			this.inputFocus = false
		},
		// 验证码输入框获取到焦点
		codeInputFocus() {
			this.inputFocus = true
		},
		// 打开修改安全密码
		openchangPwd() {
			this.insideSwitch.changPwd = true
		},
		// 打开编辑时间日程
		openBjschedule(bool, item) {
			this.timeRcbjOradd = bool
			if (bool) {
				if (item.effectiveState == '1') {
					this.$refs.tsmsg2.msgShow('当前已启用无法修改', 1.5)
					return
				}
				this.nowbjTimeRcObj = item
				this.timercFrom.time = item.time
				this.timercFrom.timeType = item.executionCycle
				this.timercFrom.action = item.actionItemCode
				if (item.executionCycle == 1) {
					if (item.exerciseDate == '1') {
						this.timercFrom.timeNumberType = '1'
					} else {
						this.timercFrom.timeNumberType = '2'
						this.timercFrom.timeNumber = item.exerciseDate
					}
				}
				if (item.executionCycle == 2) {
					this.timercFrom.checkList = item.exerciseDate.split(',')
				}
				if (item.executionCycle == 4) {
					this.timercFrom.pickTime = item.exerciseDate
				}
			}
			this.insideSwitch.bjschedule = true
		},
		statusClick(item) {
			let enable = ''
			if (item.effectiveState == '0') {
				enable = '1'
			} else {
				enable = '0'
			}
			this.$http.postAction(this.$api.timeConfigEnable + '?id=' + item.id + '&enable=' + enable).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
					// 重新请求数据
					this.$nextTick(() => {
						this.autoTimeConfig()
					})
					this.Informationtitle = ''
				} else {
					this.Information = false
					this.InformationId = ''
					this.Informationtitle = ''
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
				}
			})
		},
		// 打开删除时间日程确认信息
		openscsjrcqrxx(item) {
			this.nowdelTimeRcObj = item
			this.Information = true
		},
		// 时间日程确定删除
		scheduleDel() {
			this.timeConfigDel(this.nowdelTimeRcObj.id)
		},
		// 时间日程编辑页面点击下发
		scheduleupto() {
			if (this.timercFrom.time !== '') {
				if (this.timercFrom.action !== '') {
					if (this.timeRcbjOradd) {
						// 编辑
						this.timeConfigUpdate()
					} else {
						// 新增
						this.timeConfigAdd()
					}
				} else {
					this.$refs.tsmsg6.msgShow('请选择执行动作', 1.5)
				}
			} else {
				this.$refs.tsmsg6.msgShow('请选择时间', 1.5)
			}
		},
		// 删除设备自动化记录
		delautomation() {
			this.thresholdDel(this.automationzsFrom.id)
		},
		// 设备自动化下发
		sbzdhIssued() {
			if (this.automationbjFrom.dataItemCode !== '') {
				if (this.automationbjFrom.rightVal !== '') {
					if (this.automationbjFrom.actionItemCodeRight !== '') {
						if (this.automationbjFrom.leftVal !== '') {
							if (this.automationbjFrom.actionItemCodeLeft !== '') {
								if (this.automationbjFrom.rightVal - 0 > this.automationbjFrom.leftVal - 0) {
									this.thresholdSet()
								} else {
									this.$refs.tsmsg7.msgShow('请设置合法的上下限', 1.5)
								}
							} else {
								this.$refs.tsmsg7.msgShow('请设置下限值执行动作', 1.5)
							}
						} else {
							this.$refs.tsmsg7.msgShow('请设置下限值', 1.5)
						}
					} else {
						this.$refs.tsmsg7.msgShow('请设置上限值执行动作', 1.5)
					}
				} else {
					this.$refs.tsmsg7.msgShow('请设置上限值', 1.5)
				}
			} else {
				this.$refs.tsmsg7.msgShow('请选择环境参数', 1.5)
			}
			// this.insideSwitch.automationbj = false;
		},
		// 执行记录列表点击
		performItemClick(index) {
			this.insideSwitch.executionInfo = true
			this.performInfoObj = this.performList[index]
			this.ywList.forEach((item) => {
				if (item.dictValue == this.performInfoObj.businessType) {
					item.businessTypeName = item.dictLabel
				}
			})
		},
		// 控制按钮点击
		controlBtnClick(type) {
			this.$refs.Ring.isTuoRun = false //是否是拖动运动 trun 是 false 不是
			// 取消急停
			if (type == 'scram' && this.controlItem.scram) {
				this.controlItemDisable = {
					falling: false, //下降
					stop: false, //停止
					rising: false, //上升
					scram: false, //急停
					fineTuning: false, //微调
					back: false, //返回
					vtRising: false, //向上
					toTop: false, //顶复位
					vtStop: false, //微调停止
					vtFall: false, //向下
					toBottom: false, //低复位
				}
				this.controlItem[type] = false
				this.controlItem['stop'] = true
				this.scram(false)
				return
			}
			if (this.controlItem[type]) {
				return
			}
			this.controlItem = {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: false, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			}
			this.controlItem[type] = true
			if (type == 'falling' || type == 'rising' || type == 'scram') {
				this.controlItemDisable.fineTuning = true
				//上升
				if (type == 'rising') {
					this.equipmentInfo.commandsItemList.forEach((item) => {
						if (item.commandsItemName == '上升') {
							this.operateDevice(item.commandsItemCode)
						}
					})
				}
				// 下降
				if (type == 'falling') {
					if (this.equipmentInfo.itemList[1].dataItemValue == '0') {
						this.$refs.tsmsg.msgShow('设备已下降到最底部', 1.5)
						this.controlItem = {
							falling: false, //下降
							stop: true, //停止
							rising: false, //上升
							scram: false, //急停
							fineTuning: false, //微调
							back: false, //返回
							vtRising: false, //向上
							toTop: false, //顶复位
							vtStop: false, //微调停止
							vtFall: false, //向下
							toBottom: false, //低复位
						}
						return
					}
					this.equipmentInfo.commandsItemList.forEach((item) => {
						if (item.commandsItemName == '下降') {
							this.operateDevice(item.commandsItemCode)
						}
					})
				}
			}
			if (type == 'stop') {
				this.controlItemDisable.fineTuning = false
				// 停止
				if (type == 'stop') {
					this.equipmentInfo.commandsItemList.forEach((item) => {
						if (item.commandsItemName == '停止') {
							this.operateDevice(item.commandsItemCode)
						}
					})
				}
			}
			// 急停
			if (type == 'scram') {
				this.controlItem = {
					falling: false, //下降
					stop: false, //停止
					rising: false, //上升
					scram: false, //急停
					fineTuning: false, //微调
					back: false, //返回
					vtRising: false, //向上
					toTop: false, //顶复位
					vtStop: false, //微调停止
					vtFall: false, //向下
					toBottom: false, //低复位
				}
				this.controlItem.scram = true
				this.controlItemDisable.falling = true
				this.controlItemDisable.stop = true
				this.controlItemDisable.rising = true
				this.controlItemDisable.fineTuning = true
				this.scram(true)
			}
			if (type == 'vtRising' || type == 'vtFall') {
				this.menuisShow = false
			} else {
				this.menuisShow = true
			}
			if (type == 'toTop' || type == 'toBottom') {
				if (type == 'toTop') {
					this.xtInfoTxt = '已对设备进行顶端复位，您的设备位置将位于100%'
				} else {
					this.xtInfoTxt = '已对设备进行底端复位，您的设备位置将位于0%'
				}
				this.Information = true
			}
			if (type == 'vtStop') {
				this.deviceFineTuning()
			}
		},
		// 微调按钮点击
		fineTuning() {
			this.isfineTuning = true
			this.controlItem = {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: true, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			}
		},
		// 微调时间输入
		vtRunTimeinput(val) {
			if (val.length > 4) {
				this.vtRunTime = val.slice(0, 4)
			}
		},
		// 点击返回
		clickBack() {
			this.controlItem = {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: false, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			}
			this.isfineTuning = false
			this.menuisShow = true
			this.deviceStatus()
		},
		// 安全密码关闭
		certificationClose() {
			this.codeValue = ''
			this.$http
				.getAction(
					this.$api.queryDuration +
						'?deviceNum=' +
						this.kzxInfo.deviceNum +
						'&subDeviceNum=' +
						this.equipmentInfo.subDeviceNum
				)
				.then((res) => {
					if (res.data) {
						let datas = res.data
						// 未设置时长
						if (datas.whetherSet && datas.whetherSet == false) {
							this.insideSwitch.certification = false
							this.drawer = false
						} else {
							// 已经设置时长
							this.insideSwitch.certification = false
						}
					}
				})
		},
		// 安全密码认证点击确定
		mmrzClick() {
			// let regexp = /^(?!\d*?(\d)\d*?\1)\d{6}$/;
			let regexp = /^[0-9]{6}$/
			if (regexp.test(this.codeValue)) {
				this.$http.postAction(this.$api.comparePassword + '?password=' + this.codeValue).then((res) => {
					if (res.data) {
						this.certificationClose()
						//打开时长保护
						this.insideSwitch.timeprotect = true
						// 查询时长配置
						this.queryDuration()
					} else {
						this.$refs.tsmsg3.msgShow('请输入正确的安全密码', 1.5)
					}
				})
			} else {
				this.$refs.tsmsg3.msgShow('请输入6位数字密码', 1.5)
			}
		},
		wjpwdEyeClick(key) {
			this.wjaqmmPwdType[key] = !this.wjaqmmPwdType[key]
		},
		xgpwdEyeClick(key) {
			this.xiugaiPwdType[key] = !this.xiugaiPwdType[key]
		},
		// 忘记密码确定点击
		wjaqmmQDClick() {
			if (this.wjaqmmFrom.Pwd) {
				if (this.wjaqmmFrom.newPwd) {
					if (this.wjaqmmFrom.againNewPwd) {
						let regexp = /^(?!\d*?(\d)\d*?\1)\d{6}$/
						if (this.wjaqmmFrom.againNewPwd == this.wjaqmmFrom.newPwd) {
							if (regexp.test(this.wjaqmmFrom.newPwd)) {
								this.$http
									.postAction(
										this.$api.setSafePassword +
											'?loginPassword=' +
											this.wjaqmmFrom.Pwd +
											'&password=' +
											this.wjaqmmFrom.newPwd
									)
									.then((res) => {
										if (res.code == 200) {
											this.$refs.tsmsgwjmm.msgShow('设置成功', 1.5)
											setTimeout(() => {
												this.wjaqmmClose()
											}, 1500)
										} else {
											this.$refs.tsmsgwjmm.msgShow(res.msg, 1.5)
										}
									})
							} else {
								this.$refs.tsmsgwjmm.msgShow('请输入正确的安全密码', 1.5)
							}
						} else {
							this.$refs.tsmsgwjmm.msgShow('两次输入的密码不一样', 1.5)
						}
					} else {
						this.$refs.tsmsgwjmm.msgShow('请再次输入新安全密码', 1.5)
					}
				} else {
					this.$refs.tsmsgwjmm.msgShow('请输入新安全密码', 1.5)
				}
			} else {
				this.$refs.tsmsgwjmm.msgShow('请输入登录密码', 1.5)
			}
		},
		// 忘记密码关闭
		wjaqmmClose() {
			this.insideSwitch.wjaqmm = false
			this.wjaqmmFrom = {
				Pwd: '',
				newPwd: '',
				againNewPwd: '',
			}
			this.codeValue = ''
		},
		// 修改时长保护配置
		xgscbhpzClick() {
			this.insideSwitch.protect = true
			this.protectFrom = this.timeproect
		},
		// 修改安全密码关闭
		xgaqmmClose() {
			this.insideSwitch.changPwd = false
			this.xiugaiPwdFrom = {
				oldPwd: '',
				newPwd: '',
				againNewPwd: '',
			}
		},
		// 编辑时间日程关闭
		bjscheduleClose() {
			this.insideSwitch.bjschedule = false
			this.timercFrom = {
				time: '',
				timeType: '1', //1每天2每周3指定日期
				timeNumberType: '1', //1固定1天2不固定几天
				timeNumber: '',
				checkList: ['1'],
				pickTime: '',
				action: '', //0上升1停止2下降
			}
		},

		/**
		 * 数据请求
		 * **/
		// 查询时长配置
		queryDuration() {
			this.$http
				.getAction(
					this.$api.queryDuration +
						'?deviceNum=' +
						this.kzxInfo.deviceNum +
						'&subDeviceNum=' +
						this.equipmentInfo.subDeviceNum
				)
				.then((res) => {
					if (res.data.whetherSet) {
						this.timeproect = {
							allTime: '',
							runTime: '',
							intervalTime: '',
						}
					} else {
						this.timeproect.allTime = res.data.onTotalDuration
						this.timeproect.runTime = res.data.onceIntervalDuration
						this.timeproect.intervalTime = res.data.onceOperationDuration
					}
				})
		},
		// 时长保护配置关闭
		timeprotectClose() {
			this.insideSwitch.protect = false
			this.$http
				.getAction(
					this.$api.queryDuration +
						'?deviceNum=' +
						this.kzxInfo.deviceNum +
						'&subDeviceNum=' +
						this.equipmentInfo.subDeviceNum
				)
				.then((res) => {
					if (res.data.whetherSet) {
					} else {
						this.drawer = true
					}
				})
		},
		// 修改时长配置
		setDuration() {
			if (!this.isIntNum(this.protectFrom.allTime - 0)) {
				this.$refs.tsmsg4.msgShow('总时长应输入正整数', 1.5)
				return
			}
			if (!this.isIntNum(this.protectFrom.runTime - 0)) {
				this.$refs.tsmsg4.msgShow('单次运行时长应输入正整数', 1.5)
				return
			}
			if (!this.isIntNum(this.protectFrom.intervalTime - 0)) {
				this.$refs.tsmsg4.msgShow('单次间隔时长应输入正整数', 1.5)
				return
			}
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				onTotalDuration: this.protectFrom.allTime,
				offTotalDuration: '',
				onceOperationDuration: this.protectFrom.intervalTime,
				onceIntervalDuration: this.protectFrom.runTime,
			}
			let option = {
				fullscreen: true,
				target: document.querySelector('.shic'),
				spinner: 'el-icon-loading',
				background: 'rgba(0,0,0,.1)',
			}
			let loadingInstance = this.$loading(option)
			this.$http.postAction(this.$api.setDuration, params).then((res) => {
				this.$nextTick(() => {
					// 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close()
				})
				if (res.code == 200) {
					this.$refs.tsmsg4.msgShow('设置成功', 1.5)
					setTimeout(() => {
						this.insideSwitch.protect = false
						this.queryDuration()
					}, 1500)
				} else {
					this.$refs.tsmsg4.msgShow('设置失败', 1.5)
				}
			})
		},
		// 验证是否为正整数
		isIntNum(val) {
			console.log(val)
			var regPos = /^\d+$/ //非负整数(正整数 + 0)
			// var regNeg = /^\-[1-9][0-9]*$/; // 负整数
			if (regPos.test(val)) {
				return true
			} else {
				return false
			}
		},
		// 修改安全密码
		updateSafePassword() {
			if (this.xiugaiPwdFrom.oldPwd) {
				if (this.xiugaiPwdFrom.newPwd) {
					if (this.xiugaiPwdFrom.againNewPwd) {
						let regexp = /^(?!\d*?(\d)\d*?\1)\d{6}$/
						if (this.xiugaiPwdFrom.againNewPwd == this.xiugaiPwdFrom.newPwd) {
							if (regexp.test(this.xiugaiPwdFrom.newPwd)) {
								this.$http
									.postAction(
										this.$api.updateSafePassword +
											'?oldPassword=' +
											this.xiugaiPwdFrom.oldPwd +
											'&newPassword=' +
											this.xiugaiPwdFrom.newPwd
									)
									.then((res) => {
										if (res.code == 200) {
											this.$refs.tsmsg5.msgShow('设置成功', 1.5)
											setTimeout(() => {
												this.xgaqmmClose()
											}, 1500)
										} else {
											this.$refs.tsmsg5.msgShow(res.msg, 1.5)
										}
									})
							} else {
								this.$refs.tsmsg5.msgShow('请输入正确的安全密码', 1.5)
							}
						} else {
							this.$refs.tsmsg5.msgShow('两次输入的密码不一样', 1.5)
						}
					} else {
						this.$refs.tsmsg5.msgShow('请再次输入新安全密码', 1.5)
					}
				} else {
					this.$refs.tsmsg5.msgShow('请输入新安全密码', 1.5)
				}
			} else {
				this.$refs.tsmsg5.msgShow('请输入原安全密码', 1.5)
			}
		},
		// 查询时间日程列表
		autoTimeConfig() {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
			}
			this.$http.postAction(this.$api.autoTimeConfig, params).then((res) => {
				if (res.data.length) {
					res.data.forEach((item) => {
						item.time = item.conditionTime
						item.iseffect = item.effectiveState == '1' ? true : false
						item.action = item.actionItemName
						if (item.executionCycle == '1') {
							item.date = '每天'
						}
						if (item.executionCycle == '2') {
							let weekArr = [
								{
									type: '1',
									txt: '周一',
								},
								{
									type: '2',
									txt: '周二',
								},
								{
									type: '3',
									txt: '周三',
								},
								{
									type: '4',
									txt: '周四',
								},
								{
									type: '5',
									txt: '周五',
								},
								{
									type: '6',
									txt: '周六',
								},
								{
									type: '7',
									txt: '周日',
								},
							]
							let str = ''
							item.weeks.forEach((item2, index) => {
								weekArr.forEach((item3) => {
									if (item2 == item3.type) {
										if (index == 0) {
											str += item3.txt
										} else {
											str += '、' + item3.txt
										}
									}
								})
							})
							item.date = '每周（' + str + '）'
						}
						if (item.executionCycle == '4') {
							item.date = item.exerciseDate
						}
					})
					this.scheduleList = res.data
				} else {
					this.scheduleList = []
				}
			})
		},
		// 时间日程添加
		timeConfigAdd() {
			// 判断exerciseDate的传值
			let exerciseDate = ''
			// 按日
			if (this.timercFrom.timeType == 1) {
				if (this.timercFrom.timeNumberType == 1) {
					exerciseDate = '1'
				} else {
					exerciseDate = this.timercFrom.timeNumber
				}
			} else if (this.timercFrom.timeType == 2) {
				// 按周
				exerciseDate = this.timercFrom.checkList.join(',')
			} else if (this.timercFrom.timeType == 4) {
				// 指定日期
				exerciseDate = this.timercFrom.pickTime
			}
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				executionCycle: this.timercFrom.timeType,
				exerciseDate: exerciseDate,
				conditionTime: this.timercFrom.time,
				actionItemCode: this.timercFrom.action,
				priority: '',
				duration: '',
				aperture: '',
			}
			this.$http.postAction(this.$api.timeConfigAdd, params).then((res) => {
				if (res.code == 200) {
					this.bjscheduleClose()
					this.$refs.tsmsg2.msgShow('下发成功', 1.5)
					this.autoTimeConfig()
				} else {
					this.bjscheduleClose()
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
					this.autoTimeConfig()
				}
			})
		},
		// input 只允许输入整数
		onlyNumber(num, type) {
			let n = String(num)
			const t = n.charAt(0)
			// 先把非数字的都替换掉，除了数字和.
			// n = n.replace(/[^\d\\.]/g, '')
			// 必须保证第一个为数字而不是.
			n = n.replace(/\./g, '').replace(/\-/g, '')
			// // 必须保证第一个数字非0
			n = n.replace(/^0(\d)/g, ($0, $1) => $1)
			// // 保证只有出现一个.而没有多个.
			// n = n.replace(/\.{2,}/g, '.')
			// // 保证.只出现一次，而不能出现两次以上
			// n = n.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
			n = n.substr(0, 4)
			// 如果第一位是负号，则允许添加
			if (t == '-') {
				n = '-' + n
			}
			this.automationbjFrom[type] = n
			return n
		},
		// 时间日程修改
		timeConfigUpdate() {
			// 判断exerciseDate的传值
			let exerciseDate = ''
			// 按日
			if (this.timercFrom.timeType == 1) {
				if (this.timercFrom.timeNumberType == 1) {
					exerciseDate = '1'
				} else {
					exerciseDate = this.timercFrom.timeNumber
				}
			} else if (this.timercFrom.timeType == 2) {
				// 按周
				exerciseDate = this.timercFrom.checkList.join(',')
			} else if (this.timercFrom.timeType == 4) {
				// 指定日期
				exerciseDate = this.timercFrom.pickTime
			}
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				executionCycle: this.timercFrom.timeType,
				exerciseDate: exerciseDate,
				conditionTime: this.timercFrom.time,
				actionItemCode: this.timercFrom.action,
				id: this.nowbjTimeRcObj.id,
				priority: '',
				duration: '',
				aperture: '',
			}
			this.$http.postAction(this.$api.timeConfigUpdate, params).then((res) => {
				if (res.code == 200) {
					this.bjscheduleClose()
					this.$refs.tsmsg2.msgShow('编辑成功', 1.5)
					this.autoTimeConfig()
				} else {
					this.bjscheduleClose()
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
				}
			})
		},
		// 时间日程删除
		timeConfigDel(id) {
			this.$http.deleteAction(this.$api.timeConfigDel + '/' + id).then((res) => {
				if (res.code == 200) {
					this.Information = false
					this.$refs.tsmsg2.msgShow('删除成功', 1.5)
					this.autoTimeConfig()
				} else {
					this.Information = false
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
				}
			})
		},
		// 时间日程下发
		timeConfigEnable(id) {
			this.$http.postAction(this.$api.timeConfigEnable + '?id=' + id + '&enable=1').then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg2.msgShow('下发成功', 1.5)
					this.autoTimeConfig()
				} else {
					this.Information = false
					this.$refs.tsmsg2.msgShow(res.msg, 1.5)
				}
			})
		},
		// 设备自动化 - 查询
		thresholdList() {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
			}
			this.$http.postAction(this.$api.thresholdList, params).then((res) => {
				if (res.data) {
					this.automationzsFrom = res.data
					this.cgqList.forEach((item) => {
						if (item.subDeviceNum == res.data.sensorNum) {
							this.automationzsFrom.subDeviceName = item.subDeviceName
							item.itemList.forEach((item2) => {
								if (item2.dataItemCode == res.data.dataItemCode) {
									this.automationzsFrom.dataItemName = item2.dataItemName
								}
							})
						}
					})
					this.equipmentInfo.commandsItemList.forEach((item) => {
						console.log(item)
						if (item.commandsItemCode == res.data.actionItemCodeLeft) {
							this.automationzsFrom.actionItemNameLeft = item.commandsItemName
						}
						if (item.commandsItemCode == res.data.actionItemCodeRight) {
							this.automationzsFrom.actionItemNameRight = item.commandsItemName
						}
					})
				} else {
					this.automationbjFrom.sensorNum = this.cgqList[0].subDeviceNum
					this.cjxList = this.cgqList[0].itemList
					this.insideSwitch.automation = false
					this.insideSwitch.automationbj = true
				}
			})
		},
		// 打开设备自动化编辑页
		openautomationbj() {
			this.automationbjFrom = this.automationzsFrom
			this.cgqList.forEach((item) => {
				if (item.subDeviceNum == this.automationbjFrom.sensorNum) {
					this.cjxList = item.itemList
				}
			})
			this.insideSwitch.automationbj = true
		},
		// 设备自动化编辑切换传感器
		sbzdhCgqChange() {
			this.cgqList.forEach((item) => {
				if (item.subDeviceNum == this.automationbjFrom.sensorNum) {
					this.cjxList = item.itemList
				}
			})
		},
		// 设备自动化环境阈值- 设置
		thresholdSet() {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				deviceId: this.kzxInfo.deviceId,
				rightVal: this.automationbjFrom.rightVal,
				actionItemCodeRight: this.automationbjFrom.actionItemCodeRight,
				leftVal: this.automationbjFrom.leftVal,
				actionItemCodeLeft: this.automationbjFrom.actionItemCodeLeft,
				sensorNum: this.automationbjFrom.sensorNum,
				dataItemCode: this.automationbjFrom.dataItemCode,
			}
			if (this.automationbjFrom.id) {
				params.id = this.automationbjFrom.id
			}
			this.$http.postAction(this.$api.thresholdSet, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg7.msgShow('设置成功', 1.5)
					setTimeout(() => {
						this.thresholdList()
						this.insideSwitch.automationbj = false
						this.insideSwitch.automation = false
						this.automationbjFrom = {
							sensorNum: '',
							dataItemCode: '',
							rightVal: '', //上限
							actionItemCodeRight: '', //0上升1停止2下降
							leftVal: '', //下限
							actionItemCodeLeft: '', //0上升1停止2下降
						}
					}, 1500)
				} else {
					this.$refs.tsmsg7.msgShow(res.msg, 1.5)
				}
			})
		},
		// 设备自动化删除
		thresholdDel(id) {
			this.$http.deleteAction(this.$api.thresholdDel + '?id=' + id).then((res) => {
				if (res.code == 200) {
					this.Information = false
					this.insideSwitch.automation = false
					this.$refs.tsmsg.msgShow('删除成功', 1.5)
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
				}
			})
		},
		// 设备自动化关闭
		automationbjClose() {
			this.automationbjFrom = {
				sensorNum: '',
				dataItemCode: '',
				rightVal: '', //上限
				actionItemCodeRight: '', //0上升1停止2下降
				leftVal: '', //下限
				actionItemCodeLeft: '', //0上升1停止2下降
			}
		},
		// 执行记录时间切换
		performTimeChange() {
			console.log(this.performFrom.time)
		},
		// 执行记录查询
		operationRecord() {
			let startTime = ''
			let endTime = ''
			if (this.performFrom.time && this.performFrom.time.length) {
				startTime = this.performFrom.time[0]
				endTime = this.performFrom.time[1]
			} else {
				startTime = ''
				endTime = ''
			}
			let params = {
				list: [
					{
						deviceNum: this.kzxInfo.deviceNum,
						subDeviceNums: [this.equipmentInfo.subDeviceNum],
					},
				],
				currentPage: 1,
				pageSize: 20,
			}
			if (startTime !== '') {
				params.startTime = startTime
			}
			if (endTime !== '') {
				params.endTime = endTime
			}
			if (this.performFrom.operation !== '') {
				params.operationSources = [this.performFrom.operation]
			} else {
				params.operationSources = []
			}
			if (this.performFrom.status !== '') {
				params.operationResult = this.performFrom.status
			} else {
				params.operationResult = ''
			}
			this.$http.postAction(this.$api.recordList, params).then((res) => {
				this.performList = []
				if (res.data && res.data.records.length) {
					this.performList = res.data.records
				} else {
					this.performList = []
				}
			})
		},
		// 执行记录抽屉关闭
		performClose() {
			this.performFrom = {
				time: '', //时间
				operation: '', //操作源
				status: '', //执行状态
			}
		},
		// 设备操作
		operateDevice(operate) {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				operate: operate,
				duration: '',
				khd: '',
			}
			this.$http.postAction(this.$api.operateDevice, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg.msgShow('操作成功', 1.5)
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
					// this.deviceStatus();
				}
			})
		},
		// 设备操作
		operateDeviceTuo(operateStr, khd) {
			let operate = ''
			if (operateStr == 'rise') {
				//上升
				operate = '01'
			}
			if (operateStr == 'decline') {
				//下降
				operate = '10'
			}
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,

				duration: '',
				khd: khd,
			}
			;(params.operate = operate),
				this.$http.postAction(this.$api.operateDevice, params).then((res) => {
					if (res.code == 200) {
						this.$refs.tsmsg.msgShow('操作成功', 1.5)
					} else {
						this.$refs.tsmsg.msgShow(res.msg, 1.5)
						this.deviceStatus()
					}
				})
		},
		// 设备急停
		scram(bool) {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				stop: bool ? '1' : '0',
				operateSource: 'WEB',
			}
			this.$http.postAction(this.$api.scram, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg.msgShow('操作成功', 1.5)
					// if (bool) {
					//   this.$refs.Ring.color = "rgba(248, 134, 56, 1)";
					// } else {
					//   this.$refs.Ring.color = "rgba(45, 174, 169, 1)";
					// }
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
				}
			})
		},
		isPositiveInteger(number) {
			// 使用Number.isInteger()函数判断是否为整数
			// 使用大于符号（>）判断是否大于0
			if (Number.isInteger(number) && number > 0) {
				return true // 是大于的整数
			} else {
				return false // 不是大于的整数
			}
		},
		// 设备微调
		deviceFineTuning() {
			let operate, duration
			let time = ''
			let vtFangs = ''
			if (this.controlItem.vtRising) {
				operate = '01'
				vtFangs = '向上'
			} else if (this.controlItem.vtStop) {
				operate = '00'
				vtFangs = '停止'
			} else {
				operate = '10'
				vtFangs = '向下'
			}
			if (this.controlItem.vtStop) {
				duration = ''
			} else {
				if (this.vtRunTime == '') {
					this.$refs.tsmsg.msgShow('请输入时间（运行时间为大于0的正整数）', 1.5)
					return
				} else {
					// console.log(this.vtRunTime, 'vtRunTime')
					// return
					// console.log(this.isPositiveInteger(Number(this.vtRunTime)))
					if (this.isPositiveInteger(Number(this.vtRunTime))) {
					} else {
						this.$refs.tsmsg.msgShow('请输入正确的时间（运行时间为大于0的正整数）', 1.5)
						return
					}
					if (this.vtTimeType == '0') {
						duration = this.vtRunTime
						time = this.vtRunTime
					} else {
						duration = this.vtRunTime * 60
						time = this.vtRunTime * 60
					}
				}
			}

			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				operate: operate,
				duration: duration,
			}
			this.$http.postAction(this.$api.deviceFineTuning, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg.msgShow('操作成功', 1.5)
					if (this.controlItem.vtStop) {
						this.showcountdownUp = false
						this.xtInfoTxt = '请使用使用下方操作，完成您的微调。'
					} else {
						this.showcountdownUp = true
						this.xtInfoTxt = '您设定了' + time + '秒的' + vtFangs + '微调，请观察现场设备实际位置变化'
						this.$nextTick(() => {
							this.$refs.countdownUp.time = time
							this.$refs.countdownUp.Time()
						})
					}
					if (this.controlItem.vtRising) {
						this.controlItemDisable = {
							falling: false, //下降
							stop: false, //停止
							rising: false, //上升
							scram: false, //急停
							fineTuning: false, //微调
							back: true, //返回
							vtRising: false, //向上
							toTop: true, //顶复位
							vtStop: false, //微调停止
							vtFall: true, //向下
							toBottom: true, //低复位
						}
					} else if (this.controlItem.vtStop) {
						this.controlItemDisable = {
							falling: false, //下降
							stop: false, //停止
							rising: false, //上升
							scram: false, //急停
							fineTuning: false, //微调
							back: false, //返回
							vtRising: false, //向上
							toTop: false, //顶复位
							vtStop: false, //微调停止
							vtFall: false, //向下
							toBottom: false, //低复位
						}
					} else {
						this.controlItemDisable = {
							falling: false, //下降
							stop: false, //停止
							rising: false, //上升
							scram: false, //急停
							fineTuning: false, //微调
							back: true, //返回
							vtRising: true, //向上
							toTop: true, //顶复位
							vtStop: false, //微调停止
							vtFall: false, //向下
							toBottom: true, //低复位
						}
					}
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
					this.deviceStatus()
				}
				this.menuisShow = true
				this.vtTimeType = '0'
				this.vtRunTime = ''
			})
		},
		// 确认位置信息警告取消
		alignedQuxiao() {
			this.Information = false
			this.controlItem = {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: true, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			}
		},
		// 设备复位
		aligned() {
			let params = {
				deviceNum: this.kzxInfo.deviceNum,
				subDeviceNum: this.equipmentInfo.subDeviceNum,
				address: '',
			}
			if (this.controlItem.toTop) {
				params.address = '1'
			} else {
				params.address = '0'
			}
			this.Information = false
			this.$http.postAction(this.$api.aligned, params).then((res) => {
				if (res.code == 200) {
					this.controlItem = {
						falling: false, //下降
						stop: false, //停止
						rising: false, //上升
						scram: false, //急停
						fineTuning: false, //微调
						back: false, //返回
						vtRising: false, //向上
						toTop: false, //顶复位
						vtStop: true, //微调停止
						vtFall: false, //向下
						toBottom: false, //低复位
					}
					this.$refs.tsmsg.msgShow('操作成功', 1.5)
				} else {
					this.controlItem = {
						falling: false, //下降
						stop: false, //停止
						rising: false, //上升
						scram: false, //急停
						fineTuning: false, //微调
						back: false, //返回
						vtRising: false, //向上
						toTop: false, //顶复位
						vtStop: true, //微调停止
						vtFall: false, //向下
						toBottom: false, //低复位
					}

					this.$refs.tsmsg.msgShow(res.msg, 1.5)
				}
			})
		},
		// 设备状态更新
		deviceStatus() {
			this.controlItem = {
				falling: false, //下降
				stop: false, //停止
				rising: false, //上升
				scram: false, //急停
				fineTuning: false, //微调
				back: false, //返回
				vtRising: false, //向上
				toTop: false, //顶复位
				vtStop: false, //微调停止
				vtFall: false, //向下
				toBottom: false, //低复位
			}
			if (!this.isfineTuning) {
				this.equipmentInfo.itemList.forEach((item) => {
					if (item.dataItemCode == 'status') {
						this.equipmentInfo.commandsItemList.forEach((item2) => {
							if (item.dataItemValue == item2.commandsItemCode) {
								let commandsItemName = item2.commandsItemName
								if (commandsItemName == '停止') {
									this.controlItem.stop = true
									this.controlItemDisable.fineTuning = false
									this.$refs.Ring.isHua = true
								}
								if (commandsItemName == '下降') {
									this.controlItem.falling = true
									this.controlItemDisable.fineTuning = true
									this.$refs.Ring.isHua = false
								}
								if (commandsItemName == '上升') {
									this.controlItem.rising = true
									this.controlItemDisable.fineTuning = true
									this.$refs.Ring.isHua = false
								}
							}
						})
					}
				})
				this.equipmentInfo.itemList.forEach((item) => {
					if (item.dataItemCode == 'e_stop') {
						if (item.dataItemValue == '1') {
							this.controlItem = {
								falling: false, //下降
								stop: false, //停止
								rising: false, //上升
								scram: false, //急停
								fineTuning: false, //微调
								back: false, //返回
								vtRising: false, //向上
								toTop: false, //顶复位
								vtStop: false, //微调停止
								vtFall: false, //向下
								toBottom: false, //低复位
							}
							this.controlItem.scram = true
							this.controlItemDisable.falling = true
							this.controlItemDisable.stop = true
							this.controlItemDisable.rising = true
							this.controlItemDisable.fineTuning = true
						}
					}
				})
			} else {
				this.equipmentInfo.itemList.forEach((item) => {
					if (item.dataItemCode == 'status') {
						this.controlItemDisable = {
							falling: false, //下降
							stop: false, //停止
							rising: false, //上升
							scram: false, //急停
							fineTuning: false, //微调
							back: false, //返回
							vtRising: false, //向上
							toTop: false, //顶复位
							vtStop: false, //微调停止
							vtFall: false, //向下
							toBottom: false, //低复位
						}
						this.equipmentInfo.commandsItemList.forEach((item2) => {
							if (item.dataItemValue == item2.commandsItemCode) {
								let commandsItemName = item2.commandsItemName
								if (commandsItemName == '停止') {
									this.controlItem.vtStop = true
									this.xtInfoTxt = '请使用使用下方操作，完成您的微调。'
									this.showcountdownUp = false
								}
								if (commandsItemName == '下降') {
									this.controlItem.vtFall = true
									this.controlItemDisable = {
										falling: false, //下降
										stop: false, //停止
										rising: false, //上升
										scram: false, //急停
										fineTuning: false, //微调
										back: true, //返回
										vtRising: true, //向上
										toTop: true, //顶复位
										vtStop: false, //微调停止
										vtFall: false, //向下
										toBottom: true, //低复位
									}
								}
								if (commandsItemName == '上升') {
									this.controlItem.vtRising = true
									this.controlItemDisable = {
										falling: false, //下降
										stop: false, //停止
										rising: false, //上升
										scram: false, //急停
										fineTuning: false, //微调
										back: true, //返回
										vtRising: false, //向上
										toTop: true, //顶复位
										vtStop: false, //微调停止
										vtFall: true, //向下
										toBottom: true, //低复位
									}
								}
							}
						})
					}
				})
			}
		},
		// 筛选执行条件
		queryRecord() {
			this.$http.postAction(this.$api.queryRecord, {}).then((res) => {
				if (res.data) {
					this.osList = res.data.osList
					this.orList = res.data.orList
					this.ywList = res.data.ywList
				}
			})
		},
		zzsj() {
			// 即可阻止点击按钮时触发input失去焦点事件
			event.preventDefault()
		},
	},
}
</script>

<style lang="scss" scoped>
/deep/.kgSetUp {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		span {
			font-size: 0.225rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		display: flex;
		flex-direction: column;
		.Information {
			position: absolute;
			z-index: 999999;
			top: 40%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%) scale(0);
			width: 3.45rem;
			height: 2.075rem;
			background: #ffffff;
			box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
			border-radius: 10px;
			transition: all 0.3s;
			.top {
				box-sizing: border-box;
				height: 1.375rem;
				text-align: center;
				line-height: 1.375rem;
				border-bottom: 1px solid #f1f1f1;
			}
			.bottom {
				display: flex;
				height: 0.6875rem;
				justify-content: space-around;
				align-items: center;
				& > div {
					cursor: pointer;
				}
				.line {
					width: 1px;
					height: 0.45rem;
					background: #f1f1f1;
				}
				.green {
					color: rgba(45, 174, 169, 1);
				}
			}
		}
		.Information-show {
			transform: translateX(-50%) translateY(-50%) scale(1);
		}
		.drawe-top {
			display: flex;
			padding-bottom: 0.275rem;
			border-bottom: 1px solid rgba(241, 241, 241, 1);
			.img-box {
				width: 1.5rem;
				height: 1.5rem;
				background-color: rgba(45, 174, 169, 0.18);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 0.8625rem;
				}
			}
			.txt-box {
				margin-left: 0.225rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				box-sizing: border-box;
				padding: 0.15rem 0;
				.tb-li {
					display: flex;
					font-size: 0.2rem;
					.line {
						width: 0.0125rem;
						height: 0.2rem;
						background: #bfbfbf;
						margin: 0 0.2rem;
					}
					.bj-icon {
						cursor: pointer;
						margin-left: 0.1875rem;
						width: 0.2rem;
						height: 0.2rem;
						background: url('../../../assets/image/product/icon-bj2.png') no-repeat;
					}
					.input-div {
						width: 2.875rem;
						height: 0.4rem;
						.el-input {
							height: 100%;
						}
						.el-input__inner {
							border-radius: 0.1rem;
							height: 100%;
							font-weight: normal;
						}
						.el-input.is-active .el-input__inner,
						.el-input__inner:focus {
							border-color: rgba(45, 174, 169, 1);
						}
						.el-input__suffix {
							width: 4vw;
							height: 100%;
							.el-input__suffix-inner {
								display: block;
								width: 100%;
								height: 100%;
								display: flex;
								align-items: center;
								.suffix-content {
									display: flex;
									align-items: center;
									width: 100%;
									height: 1.099vw;
									box-sizing: border-box;
									border-left: 1px solid #f1f1f1;
									justify-content: space-around;
									padding: 0 0.1875rem;
									img {
										cursor: pointer;
										width: 0.175rem;
										height: 0.175rem;
									}
								}
							}
						}
					}
				}
				.doble {
					font-weight: bold;
				}
				.green {
					color: rgba(73, 201, 136, 1);
				}
				.blue {
					color: rgba(45, 174, 169, 1);
				}
				.red {
					color: rgb(248, 134, 56);
				}
			}
		}
		.switch-box {
			margin: 0.25rem 0;
			height: 0.9rem;
			background: rgba(231, 241, 243, 0.6);
			border-radius: 0.1rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 0 0.3rem;
			.status-box {
				display: flex;
				align-items: center;
				.icon {
					width: 0.45rem;
					height: 0.45rem;
					background: url('../../../assets/image/product/kaiguan.png') no-repeat;
					margin-right: 0.2625rem;
				}
				span {
					font-size: 0.2rem;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #454545;
				}
			}
			.set_icon {
				width: 0.8625rem;
				height: 0.35rem;
			}
		}
		.menu-box {
			margin-top: 0.3rem;
			.menu-li {
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 0.2rem;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #454545;
				line-height: 0.2625rem;
				margin-bottom: 0.3rem;
				.blue {
					font-size: 0.175rem;
					color: rgba(45, 174, 169, 1);
					margin: 0 0.175rem;
				}
				&::after {
					content: '';
					width: 0.1125rem;
					height: 0.2rem;
					background: url('../../../assets/image/equipment/icon-open.png') no-repeat;
					background-size: 100% 100%;
				}
				.li-l {
					font-size: 0.2rem;
					img {
						margin-right: 0.25rem;
					}
				}
			}
			.b-line {
				height: 1px;
				background: #f1f1f1;
				margin-bottom: 0.3125rem;
			}
		}
		.annulus-box {
			height: 3.1875rem;
			.annulus-content {
				width: 3.2125rem;
				height: 3.1875rem;
				margin: 0 auto;
				background: url('../../../assets/image/product/yuanhuan.png') no-repeat;
			}
		}
		.wt-tishibox {
			height: 3.1875rem;
			box-sizing: border-box;
			padding-left: 0.2875rem;
			display: flex;
			align-items: center;
			.jiqi-box {
				width: 1.5125rem;
				height: 2.2125rem;
				background: url('../../../assets/image/product/jiqiren.png') no-repeat;
			}
			.tishi-box {
				margin-left: 0.25rem;
				height: 3.1875rem;
				width: 2.5rem;
				box-sizing: border-box;
				padding-top: 0.75rem;
				.tishi-content {
					position: relative;
					text-align: left;
					padding: 0.25rem;
					background: #2daea9;
					border-radius: 8px;
					color: #ffffff;
					line-height: 26px;
					font-size: 0.2rem;
					&::after {
						position: absolute;
						top: 0.075rem;
						left: -0.4rem;
						content: '';
						width: 0;
						height: 0;
						border: 20px solid transparent;
						border-top-width: 10px;
						border-bottom-width: 10px;
						border-right-color: #2daea9;
					}
					.time {
						text-align: center;
						span {
							font-size: 0.3rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffffff;
							line-height: 0.325rem;
						}
					}
				}
			}
		}
		.control-btn-box {
			margin-top: 0.1rem;

			.btn-box {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.item {
					cursor: pointer;
					width: 110px;
					height: 56px;
					background: rgba(231, 241, 243, 0.6);
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 0.3125rem;
				}
				.pading15 {
					padding: 0 0.1875rem;
				}
				.orgeen {
					color: rgba(248, 134, 56, 1);
				}
				.itembggreen {
					background: rgba(45, 174, 169, 1);
					color: #ffffff;
				}
				.itembgorg {
					background: rgba(248, 134, 56, 1);
					color: #fff;
				}
				.mb18 {
					margin-bottom: 0.225rem;
				}
				.item-disable {
					pointer-events: none;
					filter: grayscale(1);
					cursor: not-allowed !important;
				}
			}
		}
		.prompts-title {
			position: relative;
			//   margin-top: 0.2875rem;
			display: flex;
			align-items: center;
			&::before {
				margin-right: 0.3rem;
				content: '';
				width: 0.025rem;
				height: 0.2rem;
				background: #2daea9;
			}
		}
		.line {
			height: 1px;
			background: #f1f1f1;
			margin: 0.3rem 0;
		}
		.vt-time-box {
			text-align: left;
			margin-top: 0.3125rem;
			display: flex;
			align-items: center;
			.input-box {
				flex: 1;
			}
		}
		.btn-box {
			flex: 1;
			// position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			.btn-item {
				box-sizing: border-box;
				border: 0.0125rem solid #2daea9;
				cursor: pointer;
				width: 1.75rem;
				height: 0.45rem;
				background: #2daea9;
				border-radius: 0.05rem;
				text-align: center;
				line-height: 0.45rem;
				color: white;
			}
			.cancel {
				background: #fff;
				color: #2daea9;
			}
		}
	}
}
/deep/ .insideDrawe {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		margin-bottom: 0.25rem;
		span {
			font-size: 0.225rem;
		}
		.xgmm-box {
			img {
				cursor: pointer;
				margin-left: 0.2rem;
			}
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.flcu-box {
		display: flex;
		flex-direction: column;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		display: flex;
		flex-direction: column;
		position: relative;
		.drawe-top {
			display: flex;
			padding-bottom: 0.275rem;
			border-bottom: 1px solid rgba(241, 241, 241, 1);
			.img-box {
				width: 1.5rem;
				height: 1.5rem;
				background-color: rgba(45, 174, 169, 0.18);
				border-radius: 50%;
			}
			.txt-box {
				margin-left: 0.225rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.tb-li {
					display: flex;
					font-size: 0.2rem;
					.line {
						width: 0.0125rem;
						height: 0.2rem;
						background: #bfbfbf;
						margin: 0 0.2rem;
					}
				}

				.doble {
					font-weight: bold;
				}
				.green {
					color: rgba(73, 201, 136, 1);
				}
				.blue {
					color: rgba(45, 174, 169, 1);
				}
			}
		}
		.addrc-box {
			height: 1.1375rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			cursor: pointer;
			box-sizing: border-box;
			padding-top: 0.2rem;
			text-align: center;
			font-size: 0.2rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #2daea9;
			img {
				margin-bottom: 0.175rem;
			}
		}
		.line {
			height: 1px;
			background: #f1f1f1;
			margin: 0.3rem 0;
		}
		.schedule-list {
			height: 80%;
			overflow: auto;
			.schedule-item {
				background: #e7f1f3;
				border-radius: 0.125rem;
				overflow: hidden;
				margin-bottom: 0.3rem;
				font-size: 0.2rem;
				.item-header {
					display: flex;
					justify-content: space-between;
					box-sizing: border-box;
					padding: 0.225rem 0.3rem;
					border-bottom: 1px solid rgba(255, 255, 255, 0.6);
					.header-left {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 28px;
						display: flex;
						// span {
						//    line-height: 28px;
						// }
						img {
							width: 0.225rem;
							height: 0.225rem;
							margin-right: 0.175rem;
						}
					}
					.header-right {
						margin-left: 0.45rem;
						img {
							cursor: pointer;
						}
					}
				}
				.item-top {
					// height: 1.1375rem;
					box-sizing: border-box;
					padding: 0.3rem 0;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					position: relative;
					.r-jiao {
						position: absolute;
						top: 0.1375rem;
						right: -0.3125rem;
						width: 1.25rem;
						height: 0.3875rem;
						background: rgba(45, 174, 169, 1);
						text-align: center;
						line-height: 0.3875rem;
						color: white;
						transform: rotate(45deg);
					}
					.r-jiao-no {
						background: rgba(213, 213, 213, 1);
					}
					.lie-1 {
						margin: 0 20px;
						display: flex;
						& > div {
							flex: 1;
							font-size: 0.2rem;
							line-height: 1.5;
							span {
								color: rgba(45, 174, 169, 1);
								font-size: 0.2rem;
								line-height: 1.5;
							}
						}
					}
					.mt20 {
						margin-top: 0.25rem;
					}
				}
				.item-bottom {
					//   height: 0.7125rem;
					height: 0;
					overflow: hidden;
					transition: all 0.5s;
					box-sizing: border-box;

					border-top: 1px solid rgba(255, 255, 255, 0.6);
					display: flex;
					justify-content: space-around;
					.cz-div {
						cursor: pointer;
						display: flex;
						// display: none;
						align-items: center;
						img {
							margin-right: 0.15rem;
						}
					}
					.cz-line {
						// display: none;
						width: 0.0125rem;
						background: rgba(255, 255, 255, 0.6);
					}
					.blue {
						color: rgba(43, 137, 217, 1);
					}
					.green {
						color: rgba(45, 174, 169, 1);
					}
					.red {
						color: rgba(227, 52, 52, 1);
					}
				}
			}
			.schedule-item:hover {
				.item-bottom {
					height: 0.7125rem;
					display: flex;
					padding: 0.125rem;
				}
			}
		}
		.schedule-list-no {
			height: 80%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.prompts-title {
			position: relative;
			//   margin-top: 0.2875rem;
			display: flex;
			align-items: center;
			&::before {
				margin-right: 0.3rem;
				content: '';
				width: 0.025rem;
				height: 0.2rem;
				background: #2daea9;
			}
		}
		.time-box {
			flex: 1;
			height: 0.45rem;
			margin-left: 0.1875rem;
			.el-date-editor.el-input,
			.el-date-editor.el-input__inner {
				width: 100%;
				height: 100%;
				.el-input__inner {
					height: 100%;
					line-height: 0.45rem;
					border-radius: 0.1rem;
					padding-left: 0.25rem;
				}
				.el-input__icon {
					line-height: 0.45rem;
					font-size: 0.25rem;
				}
				.el-input__prefix {
					left: 250px;
				}
			}
			.el-select {
				width: 100%;
				height: 100%;
				.el-input__inner {
					height: 0.475rem;
					line-height: 0.475rem;
				}
				.el-icon-arrow-up:before {
					content: '\e78f';
				}
			}
		}
		.time-type-box {
			margin-top: 0.25rem;
			.el-radio-group {
				width: 100%;
			}
			.time-type-li {
				.li-head {
					cursor: pointer;
					box-sizing: border-box;
					height: 0.55rem;
					background: #f2f8f9;
					border: 1px solid #eeeeee;
					padding-left: 0.3125rem;
					padding-right: 0.3rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.el-radio__label {
						display: none;
					}
				}
				.li-bottom {
					height: 0;
					overflow: hidden;
					transition: all 0.5s;
					box-sizing: border-box;
					border-left: 1px solid #eeeeee;
					border-right: 1px solid #eeeeee;
					padding: 0 0.3125rem;

					.el-radio-group {
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.input-box {
							display: inline-block;
							width: 49px;
							height: 22px;
							margin: 0 0.05rem;
						}
					}
					.el-checkbox-group {
						display: flex;
						flex-wrap: wrap;
						// align-items: center;
						.el-checkbox:nth-child(1) {
							margin-bottom: 18px;
						}
						.el-checkbox {
							margin-right: 28px;
						}
					}
					.picktime-box {
						position: relative;
						width: 4.3rem;
						height: 0.45rem;
						margin: 0.125rem auto 0;
						.el-date-editor.el-input,
						.el-date-editor.el-input__inner {
							width: 100%;
						}
						.pick-icon {
							position: absolute;
							right: 0.25rem;
							top: 45%;
							transform: translateY(-50%);
							font-size: 0.25rem;
							color: #e5e5e5;
						}
					}
				}
				.li-bottom-show {
					padding: 0.2rem 0.3125rem;
					height: 1.125rem;
				}
				.border-b {
					border-bottom: 1px solid #eeeeee;
				}
			}
		}
		.zxaction-box {
			margin-top: 0.25rem;
			height: 0.75rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			box-sizing: border-box;
			padding-left: 0.25rem;
			padding-right: 0.25rem;
			display: flex;
			align-items: center;
			.el-radio-group {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		.table-box {
			border: 1px solid #eeeeee;
			margin-top: 0.25rem;
			.table-li {
				display: flex;
				.li-left {
					width: 29.4%;
					height: 0.6rem;
					box-sizing: border-box;
					background: #f2f8f9;
					text-align: center;
					line-height: 0.6rem;
					color: #666666;
				}
				.li-right {
					width: 70.6%;
					height: 0.6rem;
					box-sizing: border-box;
					padding-left: 0.3rem;
					color: #666666;
					text-align: left;
					line-height: 0.6rem;
				}
				.border-bottom {
					border-bottom: 1px solid #eeeeee;
				}
				.border-right {
					border-right: 1px solid #eeeeee;
				}
				.blue {
					color: rgba(43, 137, 217, 1);
				}
				.green {
					color: rgba(45, 174, 169, 1);
				}
				.red {
					color: rgba(227, 52, 52, 1);
				}
			}
		}
		.btn-box {
			flex: 1;
			// position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			.btn-item {
				box-sizing: border-box;
				border: 0.0125rem solid #2daea9;
				cursor: pointer;
				width: 1.75rem;
				height: 0.45rem;
				background: #2daea9;
				border-radius: 0.05rem;
				text-align: center;
				line-height: 0.45rem;
				color: white;
			}
			.cancel {
				background: #fff;
				color: #2daea9;
			}
		}
		.parameter-box {
			margin-top: 0.3rem;
			box-sizing: border-box;
			border: 1px solid #eeeeee;
			.box-header {
				box-sizing: border-box;
				padding-left: 0.25rem;
				line-height: 0.55rem;
				height: 0.55rem;
				background: #f2f8f9;
				border-bottom: 1px solid #eeeeee;
				color: #333333;
			}
			.box-content {
				box-sizing: border-box;
				padding: 0.2rem 0.5rem;
				.el-radio-group {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: center;
				}
				.el-radio {
					margin-bottom: 0.2rem;
					// margin-right: 100px;
					.el-radio__label {
						width: 1rem;
						display: inline-block;
					}
				}
				.el-radio:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
		.zxaction-box2 {
			margin-top: 0.25rem;
			height: 2.6rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			box-sizing: border-box;
			padding: 0.25rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.item-li {
				width: 100%;
				display: flex;
				align-items: center;
				.input-box {
					flex: 1;
					height: 0.4125rem;
					.el-input__inner {
						height: 0.4125rem;
						line-height: 0.4125rem;
					}
				}
			}
		}
		.seach-list {
			height: 7.925rem;
			overflow: auto;
			margin-top: 0.25rem;
			.list-item {
				cursor: pointer;
				height: 2.475rem;
				border-radius: 0.175rem;
				background: rgba(241, 249, 250, 1);
				margin-bottom: 0.25rem;
				box-sizing: border-box;
				padding: 0.125rem;
				.list-item-top {
					box-sizing: border-box;
					padding: 0.125rem;
					height: 0.575rem;
					border-bottom: 1px solid rgba(255, 255, 255, 1);
					display: flex;
					justify-content: space-between;
					font-size: 0.2rem;
					font-family: Microsoft YaHei;
					font-weight: bold;
					.r-div {
						color: #2daea9;
						width: 1.15rem;
						img {
							width: 0.2rem;
							height: 0.2rem;
							margin-right: 0.075rem;
						}
					}
					.r-div-green {
						color: #f56e1e !important;
					}
				}
				.list-item-bot {
					box-sizing: border-box;
					height: 1.75rem;
					padding: 0.225rem 0.1875rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.lib-div {
						font-size: 0.175rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						i {
							display: inline-block;
							font-style: normal;
							width: 0.75rem;
						}
						span {
							color: #333333;
							margin-left: 0.1875rem;
						}
					}
				}
			}
		}
		.seach-list-no {
			height: 7.925rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.performInfo {
			.info-item {
				display: flex;
				margin-bottom: 0.2875rem;
				.title {
					width: 90px;
					color: #999999;
					line-height: 1.5;
				}
				.cont {
					flex: 1;
					margin-left: 0.2rem;
					line-height: 1.5;
				}
			}
		}
		.timeChange-box {
			position: relative;
			height: 36px;
			.el-date-editor.el-input,
			.el-date-editor.el-input__inner {
				width: 100%;
			}
			.pick-icon {
				position: absolute;
				right: 0.25rem;
				top: 45%;
				transform: translateY(-50%);
				font-size: 0.25rem;
				color: #e5e5e5;
			}
		}
		.search-box-kgsb {
			min-height: 1.8875rem;
			background: #ffffff;
			border: 1px solid #f1f1f1;
			border-radius: 0.05rem;
			box-sizing: border-box;
			padding: 0.2rem 0.3rem;
			font-size: 0.2rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #454545;
			.saach-item {
				height: 0.45rem;
				margin-top: 0.2rem;

				.el-date-editor--datetimerange.el-input,
				.el-date-editor--datetimerange.el-input__inner {
					width: 100%;
					height: 100%;
					line-height: 0.45rem;
				}
				.el-range-editor.el-input__inner {
					width: 100%;
					height: 100%;
					line-height: 0.45rem;
				}
				.el-date-editor .el-range__icon {
					position: absolute;
					left: 313px;
					line-height: 0.35rem;
					font-size: 0.25rem;
				}
				.el-date-editor .el-range__close-icon {
					line-height: 0.35rem;
				}
				.el-input__suffix-inner {
					width: 0.2rem;
					height: 100%;
					display: block;
					box-sizing: border-box;
					// padding-top: 0.125rem;
					padding-top: 0 !important;
					margin-right: 0.25rem;
					.seach-icon {
						position: relative;
						cursor: pointer;
						width: 0.2rem;
						height: 0.2rem;
						background: url('../../../assets/image/equipment/icon-seach.png') no-repeat;
						&::before {
							position: absolute;
							top: 50%;
							left: -0.25rem;
							transform: translateY(-50%);
							content: '';
							width: 1px;
							height: 22px;
							background: #f1f1f1;
						}
					}
				}
				.flex-sel {
					width: 48%;
					height: 100%;
					.el-select {
						width: 100%;
						height: 100%;
						.el-input__inner {
							height: 0.45rem;
							line-height: 0.45rem;
						}
						.el-input__icon {
							line-height: 0.45rem;
						}
						.el-icon-arrow-up:before {
							content: '\e78f';
						}
					}
				}
			}
		}
		.xgmm-input {
			margin-top: 0.2rem;
			margin-bottom: 0.3rem;

			height: 0.45rem;
			.el-input__inner {
				height: 0.45rem;
				line-height: 0.45rem;
			}
			.el-input__suffix {
				width: 0.25rem;
				display: flex;
				align-items: center;
				right: 10px;
				.eye-icon {
					display: block;
					cursor: pointer;
					width: 0.25rem;
					height: 0.2rem;
					margin-right: 0.175rem;
					background: url('../../../assets/image/login/icon-eye.png') no-repeat;
				}
				.eyen-icon {
					display: block;
					cursor: pointer;
					width: 0.25rem;
					height: 0.2rem;
					margin-right: 0.175rem;
					background: url('../../../assets/image/login/icon-eyen.png') no-repeat;
				}
			}
		}
		.scbh-input {
			height: 0.45rem;
			margin: 0 0.2rem;
			margin: 0.2rem 0;
			.el-input__inner {
				height: 0.45rem;
				line-height: 0.45rem;
			}
			.el-input__suffix {
				// width: 4vw;
				height: 100%;
				.el-input__suffix-inner {
					display: block;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					.suffix-content {
						font-style: normal;
						display: flex;
						align-items: center;
						width: 100%;
						height: 1.099vw;
						box-sizing: border-box;
						border-left: 1px solid #f1f1f1;
						justify-content: space-around;
						padding: 0 0.1875rem;
						font-size: 0.175rem;
						img {
							cursor: pointer;
							width: 0.175rem;
							height: 0.175rem;
						}
					}
				}
			}
		}
		.warm-txt {
			font-size: 0.175rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #999999;
			line-height: 1.5;
			//   margin: 0 0 0.3rem 0;
		}
		.rz-tishibox {
			// height: 3.1875rem;
			box-sizing: border-box;
			padding-left: 0.2875rem;
			display: flex;
			align-items: center;
			position: relative;
			.jiqi-box {
				width: 1.5125rem;
				height: 2.2125rem;
				background: url('../../../assets/image/product/jiqiren.png') no-repeat;
			}
			.right-box {
				margin-left: 0.25rem;
				// height: 3.1875rem;
				width: 2.5rem;
				position: relative;
				text-align: left;
				padding: 0.25rem;
				background: #2daea9;
				border-radius: 8px;
				color: #ffffff;
				line-height: 26px;
				font-size: 0.2rem;
				&::after {
					position: absolute;
					top: 0.075rem;
					left: -0.4rem;
					content: '';
					width: 0;
					height: 0;
					border: 20px solid transparent;
					border-top-width: 10px;
					border-bottom-width: 10px;
					border-right-color: #2daea9;
				}
			}
			.wjmm {
				cursor: pointer;
				position: absolute;
				right: 0.45rem;
				bottom: 0.225rem;
				font-size: 0.2rem;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2daea9;
			}
		}
		.mminput-box {
			margin-top: 20px;
			position: relative;
			display: flex;
			justify-content: space-between;
		}
		.input-code {
			position: absolute;
			height: 50px;
			width: 100%;
			overflow: hidden;
		}
		.code-item {
			width: 48px;
			height: 50px;
			text-align: center;
			box-sizing: border-box;
			line-height: 50px;
			border: 1px solid #f0f0f0;
			margin-right: 10px;
			color: rgba(45, 174, 169, 1);
		}
		.code-item-active {
			border: 1px solid rgba(45, 174, 169, 1);
			box-sizing: border-box;
		}
		// 隐藏input
		.mminput-box {
			.el-input__inner {
				height: 50px;
				background-color: transparent;
				border: none;
				color: transparent;
				padding-right: 1500px;
			}
		}
		.timeproect {
			height: 0.775rem;
			background: #e7f1f3;
			border-radius: 0.125rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 0.2875rem;
			margin-bottom: 0.3rem;
			.green-span {
				color: rgba(45, 174, 169, 1);
			}
		}
	}
	.Information {
		position: absolute;
		z-index: 999999;
		top: 40%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) scale(0);
		width: 3.45rem;
		height: 2.075rem;
		background: #ffffff;
		box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		transition: all 0.3s;
		.top {
			box-sizing: border-box;
			height: 1.375rem;
			text-align: center;
			line-height: 1.375rem;
			border-bottom: 1px solid #f1f1f1;
		}
		.bottom {
			display: flex;
			height: 0.6875rem;
			justify-content: space-around;
			align-items: center;
			& > div {
				cursor: pointer;
			}
			.line {
				width: 1px;
				height: 0.45rem;
				background: #f1f1f1;
			}
			.green {
				color: rgba(45, 174, 169, 1);
			}
		}
	}
	.Information-show {
		transform: translateX(-50%) translateY(-50%) scale(1);
	}
}
</style>
