<template>
	<div class="flex flex_ju_sb person-wrap">
		<!-- 左侧切换 -->
		<div class="person-div person-left t_l bold pointer">
			<div
				v-for="(item, index) in tabList"
				:key="index"
				:class="{ 'tab-active': index == tabIndex }"
				@click="tabChange(index)"
			>
				{{ item }}
			</div>
		</div>
		<!-- 右侧内容 -->
		<div class="person-div person-right flex flex_ju_c">
			<!-- 编辑按钮 -->
			<div class="font_14 c_white t_c btn-edit pointer" @click="userEdit" v-if="tabIndex == 0 && !isEdit">编辑</div>
			<!-- 用户信息 -->
			<div v-if="tabIndex == 0" class="wrap-one" :class="{ 'wrap-one-edit': isEdit }">
				<!-- 头像 -->
				<div class="person-avatar">
					<img class="avatar-img" src="@/assets/image/index/icon_use.png" alt="" />
				</div>

				<!-- 内容 -->
				<div class="user-infos" :class="{ 'user-infoedit': isEdit }">
					<div class="flex flex_a_c">
						<span class="span-name">登录账号</span>
						<span class="user-word"> {{ userInfo.loginName || '--' }}</span>
					</div>
					<div class="flex flex_a_c">
						<span class="span-name">所属行业</span>
						<span class="user-word">{{ userInfo.industryName || '--' }}</span>
					</div>
					<div v-if="!isEdit">
						<div class="flex flex_a_c">
							<span class="span-name">用户昵称</span>
							<span class="user-word">{{ userInfo.userName || '--' }}</span>
						</div>
						<div class="flex flex_a_c">
							<span class="span-name">所在地区</span>
							<span class="user-word">{{ userInfo.areaNames || '--' }}</span>
						</div>
						<div class="flex flex_a_s">
							<span class="span-name">详细地址</span>
							<span class="user-word user-address">{{ userInfo.homeAddress || '--' }}</span>
						</div>
					</div>
					<div v-if="isEdit" class="form-edit">
						<div class="flex flex_a_c">
							<span class="span-name">用户昵称</span>
							<el-input
								class="user-word user-input"
								v-model="editInfo.userName"
								placeholder="请输入用户昵称"
								:maxlength="8"
							></el-input>
						</div>
						<div class="flex flex_a_c">
							<span class="span-name">所在地区</span>
							<el-cascader
								class="user-word user-input user-cascader"
								v-model="address"
								placeholder="省、市、区"
								:props="props"
							></el-cascader>
						</div>
						<div class="flex flex_a_c">
							<span class="span-name">详细地址</span>
							<el-input
								type="textarea"
								class="user-word user-input"
								v-model="editInfo.homeAddress"
								placeholder="请输入具体地址信息"
								:maxlength="60"
							></el-input>
						</div>
						<div class="flex flex_a_c flex_ju_c edit-btns">
							<div class="edit-btn editbtn-cancle" @click="handleUserCancle">取消</div>
							<div class="edit-btn editbtn-save" @click="handleUserSave">保存</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 修改密码 -->
			<div v-else-if="tabIndex == 1" class="wrap-password">
				<!-- 头像 -->
				<div class="person-avatar">
					<img class="avatar-img" src="@/assets/image/index/icon_use.png" alt="" />
				</div>
				<el-form
					:model="passForm"
					:rules="passRules"
					ref="rulePassForm"
					label-width="100px"
					class="pass-form"
					:hide-required-asterisk="true"
				>
					<el-form-item label="原密码" prop="oldPassword">
						<el-input
							class="user-input"
							placeholder="请输入原密码"
							v-model="passForm.oldPassword"
							:type="pwdInputType ? 'password' : 'text'"
							@focus="passwordFous(0)"
						>
							<i
								slot="suffix"
								:class="this.pwdInputType ? 'eyen-icon' : 'eye-icon'"
								@mousedown="zzsj"
								@click="pwdEyeClick"
							></i>
						</el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="newPassword">
						<el-input
							class="user-input"
							placeholder="请输入新密码(6~8位数字和字母组合)"
							v-model="passForm.newPassword"
							:maxlength="8"
							:type="pwdInputType2 ? 'password' : 'text'"
							@focus="passwordFous(1)"
						>
							<i
								slot="suffix"
								:class="this.pwdInputType2 ? 'eyen-icon' : 'eye-icon'"
								@mousedown="zzsj"
								@click="pwdEyeClick2"
							></i>
						</el-input>
					</el-form-item>
					<el-form-item label="确认新密码" prop="againPassword">
						<el-input
							:type="pwdInputType3 ? 'password' : 'text'"
							class="user-input"
							placeholder="请再次输入新密码"
							v-model="passForm.againPassword"
							:maxlength="8"
							@focus="passwordFous(2)"
						>
							<i
								slot="suffix"
								:class="this.pwdInputType3 ? 'eyen-icon' : 'eye-icon'"
								@mousedown="zzsj"
								@click="pwdEyeClick3"
							></i>
						</el-input>
					</el-form-item>
					<el-form-item class="edit-btns">
						<div class="flex flex_a_c flex_ju_c">
							<div class="edit-btn editbtn-cancle" @click="handlePassCancle">取消</div>
							<div class="edit-btn editbtn-save" @click="handlePassSave">保存</div>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<!-- 修改手机号 -->
			<div v-else-if="tabIndex == 2" class="wrap-phone">
				<div class="step-wraps">
					<el-steps :active="stepActive" process-status="process" finish-status="success" align-center>
						<el-step title="验证身份"> </el-step>
						<el-step title="更换号码"></el-step>
						<el-step title="完成修改" class="end-step">
							<i slot="icon" class="el-icon-success self-endicon"></i>
						</el-step>
					</el-steps>
				</div>

				<!-- 下面的操作 -->
				<div class="step-content">
					<div class="step-one" v-if="stepActive == 0">
						<div class="font_16 c_45 bold step-htitle">为了您的账号安全，请先完成身份验证</div>
						<div class="font_16 c_45 t_l one-forms">
							<div>
								<span class="step-one-name">原手机号：</span>
								<span class="phone-span">{{ userInfo.loginName || '--' }}</span>
							</div>
							<div class="font_16 c_45 flex flex_a_c code-div">
								<span class="step-one-name">验证码：</span>
								<el-input class="one-input" placeholder="请输入验证码" v-model="oneCode"></el-input>
								<el-button class="step-btn" @click="tapGetCode(1)">{{ codeText }}</el-button>
							</div>
						</div>
						<div class="t_c btn-tonext" @click="tapToNextOne">下一步</div>
					</div>
					<div class="step-one" v-else-if="stepActive == 1">
						<div class="font_16 c_45 bold step-htitle">请您输入新的手机号，验证完成即可使用新手机号登录</div>
						<div class="font_16 c_45 t_l one-forms">
							<div class="font_16 c_45 flex flex_a_c code-div code-phone">
								<span class="step-one-name">新手机号：</span>
								<el-input
									class="one-input"
									:class="{ 'one-input-error': phoneError }"
									placeholder="请输入新手机号"
									v-model="phoneForm.phones"
									@blur="newPhoneBlur"
								></el-input>
								<div class="phone-error" v-if="phoneError">{{ errorPMsg }}</div>
							</div>
							<div class="font_16 c_45 flex flex_a_c code-div">
								<span class="step-one-name">验证码：</span>
								<el-input class="one-input" placeholder="请输入验证码" v-model="phoneForm.twoCode"></el-input>
								<el-button class="step-btn" @click="tapGetCode(2)">{{ codeText }}</el-button>
							</div>
						</div>
						<div class="t_c btn-tonext" @click="tapToNextTwo">下一步</div>
					</div>
					<div class="step-three" v-else-if="stepActive == 2 || stepActive == 3">
						<div class="flex flex_a_c">
							<img class="three-img" src="@/assets/image/personal/phone_success.png" alt="" />
							<div class="t_l">
								<div class="font_16 c_45 bold">
									修改成功，新手机号为
									<span class="span-phone">{{ phoneForm.phones }}</span>
								</div>
								<div class="font_14 c_45 dao-time">{{ times }}s后即将跳转至登录界面</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Msg ref="tsmsg"></Msg>
		</div>
	</div>
</template>

<script>
/*
 * @description: 个人中心-用户信息页面
 */
import Msg from '@/components/msg.vue' //信息提示组件
export default {
	components: { Msg },
	data() {
		const _this = this
		var validatePass = (rule, value, callback) => {
			const pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,8}$/
			if (value === '') {
				callback(new Error('请输入新密码'))
			} else if (!pwdReg.test(value)) {
				callback(new Error('密码由6-8位数字和字母组成'))
			} else {
				callback()
			}
		}
		var validateAgainPass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'))
			} else if (value !== this.passForm.newPassword) {
				callback(new Error('两次输入密码不一致!'))
			} else {
				callback()
			}
		}
		return {
			tabIndex: 0,
			tabList: ['用户信息', '修改密码', '修改手机号'],
			isEdit: false, // 是否是编辑状态
			// 用户信息
			userInfo: {},
			// 编辑状态下的用户信息
			editInfo: {
				userName: '',
				homeAddress: '',
			},
			address: [], // 地址
			// 地址选择的级联设置
			props: {
				lazy: true,
				value: 'areaCode',
				label: 'areaName',
				lazyLoad(node, resolve) {
					console.log(node)
					const { value, level } = node
					let params = {
						parentCode: 0,
					}
					if (node.root) {
						params = {
							parentCode: 0,
						}
					} else {
						params = {
							parentCode: value,
						}
					}
					_this.$http.getAction(_this.$api.getAreaList, params).then((res) => {
						if (res.code == 200) {
							let datas = res.data || []
							if (level > 1) {
								datas.forEach((item) => {
									item.leaf = level >= 2 //判断是否为末尾节点，这个地方是0,1,2三级
								})
							}
							resolve(datas)
						}
					})
				},
			},
			// 修改密码
			passForm: {
				oldPassword: '',
				newPassword: '',
				againPassword: '',
			},
			// 修改密码表单校验
			passRules: {
				oldPassword: [
					{
						required: true,
						trigger: 'blur',
						message: '请输入原密码',
					},
				],
				newPassword: [
					{
						required: true,
						trigger: 'blur',
						message: '请输入新密码',
					},
					{ trigger: 'blur', validator: validatePass },
				],
				againPassword: [
					{
						required: true,
						trigger: 'blur',
						message: '请再次输入密码',
					},
					{ trigger: 'blur', validator: validateAgainPass },
				],
			},
			stepActive: 0, // 修改手机号的步骤条
			// 修改手机号的验证码
			oneCode: '',
			phoneError: false, // 手机号是否输入错误
			errorPMsg: '手机号格式错误',
			phoneForm: {
				phones: '',
				twoCode: '',
			},
			times: 5, // 倒计时
			smsCode: '', // 验证码接口获取到的验证码
			timers: null,
			isBtnCode: true, // 获取验证码按钮是否可点击
			codeText: '获取验证码',
			codeTime: 60,
			codeTimer: null,
			pwdInputType: true,
			pwdInputType2: true,
			pwdInputType3: true,
		}
	},
	destroyed() {
		if (this.timers) {
			clearInterval(this.timers)
		}
		if (this.codeTimer) {
			clearInterval(this.codeTimer)
		}
	},
	mounted() {
		// 获取用户信息
		this.getUserInfo()
	},
	methods: {
		// 获取用户信息
		async getUserInfo() {
			await this.$http.getAction(this.$api.getUserInfo).then((res) => {
				if (res.code == 200) {
					let datas = res.data || {}
					this.userInfo = datas
				}
			})
		},
		// zzsj
		zzsj() {
			// 即可阻止点击按钮时触发input失去焦点事件
			event.preventDefault()
		},
		// 密码小眼睛点击
		pwdEyeClick() {
			this.pwdInputType = !this.pwdInputType
		},
		pwdEyeClick2() {
			this.pwdInputType2 = !this.pwdInputType2
		},
		pwdEyeClick3() {
			this.pwdInputType3 = !this.pwdInputType3
		},
		passwordFous(index) {
			//   this.inputFous.password = true;
			if (index == 0) {
				this.$refs['rulePassForm'].clearValidate('oldPassword') //获取焦点时如果有效验结果清除结果
			}
			if (index == 1) {
				this.$refs['rulePassForm'].clearValidate('newPassword') //获取焦点时如果有效验结果清除结果
			}
			if (index == 2) {
				this.$refs['rulePassForm'].clearValidate('againPassword') //获取焦点时如果有效验结果清除结果
			}
		},
		// 左侧tab切换
		tabChange(index) {
			if (this.tabIndex == index) return
			this.pwdInputType = true
			this.pwdInputType2 = true
			this.pwdInputType3 = true
			this.tabIndex = index
			if (this.tabIndex == 1) {
				this.$nextTick(() => {
					// 密码表单重置
					this.handlePassCancle()
				})
			}
			// 清除第三个tab的定时器
			// if (this.timers) {
			//   clearInterval(this.timers);
			// }
			// if (this.codeTimer) {
			//   clearInterval(this.codeTimer);
			// }
		},
		// 点击编辑
		userEdit() {
			this.isEdit = true
			for (const key in this.editInfo) {
				this.editInfo[key] = this.userInfo[key]
			}

			if (this.userInfo.provinceCode && this.userInfo.provinceCode != '') {
				this.$set(this.address, 0, this.userInfo.provinceCode)
			}
			if (this.userInfo.cityCode && this.userInfo.cityCode != '') {
				this.$set(this.address, 1, this.userInfo.cityCode)
			}
			if (this.userInfo.countyCode && this.userInfo.countyCode != '') {
				this.$set(this.address, 2, this.userInfo.countyCode)
			}
		},
		getArea() {
			let params = {
				parentCode: 0,
			}
			this.$http.getAction(this.$api.getAreaList, params).then((res) => {})
		},
		// 用户信息-保存
		handleUserSave() {
			if (!this.editInfo.userName || this.editInfo.userName == '') {
				this.$message('用户昵称不能为空', 'error')
				return
			}
			if (!this.address || !this.address.length) {
				this.$message('请选择所在地区', 'error')
				return
			}
			if (!this.editInfo.homeAddress || this.editInfo.homeAddress == '') {
				this.$message('具体地址信息不能为空', 'error')
				return
			}
			let params = { ...this.editInfo }
			this.$set(params, 'id', this.userInfo.id)
			this.$set(params, 'provinceCode', this.address[0] || '')
			this.$set(params, 'cityCode', this.address[1] || '')
			this.$set(params, 'countyCode', this.address[2] || '')

			console.log(params)
			// 请求修改信息接口
			this.$http
				.postAction(this.$api.updateUser, params)
				.then((res) => {
					if (res.code == 200) {
						// 重新请求用户信息
						this.getUserInfo()
						this.$bus.$emit('getUserInfoAll')
						this.$message('修改成功', 'success')
					} else {
						this.$message('修改失败', 'error')
					}
				})
				.catch(() => {
					this.$message('修改失败', 'error')
				})
				.finally(() => {
					this.isEdit = false
				})
		},
		// 用户信息-取消
		handleUserCancle() {
			this.isEdit = false
			for (const key in this.editInfo) {
				this.editInfo[key] = ''
			}
		},

		// 修改密码-保存
		handlePassSave() {
			this.$refs['rulePassForm'].validate((valid) => {
				if (valid) {
					let params = Object.assign({}, this.passForm)
					this.$http
						.postAction(this.$api.updatePwd, params)
						.then((res) => {
							if (res.code == 200) {
								this.$message('密码修改成功', 'success')
								// 跳转到登录页
								setTimeout(() => {
									window.localStorage.clear()
									this.$router.push('/login')
								}, 500)
							} else {
								this.$message(res.msg, 'error')
							}
						})
						.catch(() => {
							this.$message('密码修改失败', 'error')
						})
						.finally((err) => {
							this.$nextTick(() => {
								this.handlePassCancle()
							})
						})
				} else {
					return false
				}
			})
		},
		// 修改密码-取消
		handlePassCancle() {
			// 表单清空
			this.$refs.rulePassForm.resetFields()
			this.passForm = this.$options.data().passForm
		},

		// 点击获取验证码
		tapGetCode(index) {
			if (!this.isBtnCode) {
				return
			}
			let phones = ''
			let moduleType = '4'
			if (index == 1) {
				phones = this.userInfo.loginName
				moduleType = '4'
			}
			// 请求接口，并赋值请求到的验证码
			if (index == 2) {
				if (!this.phoneForm.phones || this.phoneForm.phones == '') {
					this.$message('请先输入手机号', 'error')
					return
				}
				this.newPhoneBlur()
				if (this.phoneError) {
					return
				}
				phones = this.phoneForm.phones
				moduleType = '5'
			}
			// this.smsCode = '123'

			// 获取验证码
			// 功能代码，1注册，2忘记密码，3登录，4修改手机号_旧手机号，5修改手机号_新手机号
			let params = {
				phone: phones,
				moduleType: moduleType,
			}
			this.$http.getAction(this.$api.getSmsCode, params).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg.msgShow('获取验证码成功', 1.5)
					// 倒计时开始进行
					this.isBtnCode = false
					this.codeText = this.codeTime + 's重新获取'
					this.codeTimer = setInterval(() => {
						let codetime = this.codeTime
						codetime--
						this.codeTime = codetime
						this.codeText = codetime + 's重新获取'
						if (codetime < 1) {
							clearInterval(this.codeTimer)
							this.codeText = '获取验证码'
							this.codeTime = 60
							this.isBtnCode = true
						}
					}, 1000)
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
				}
			})
		},
		// 点击下一步
		tapToNextOne() {
			if (!this.oneCode || this.oneCode == '') {
				this.$message('请输入验证码', 'error')
				return
			}
			// 校验验证码是否正确
			let params = {
				smsCode: this.oneCode,
			}
			this.$http.getAction(this.$api.getCheckCode, params).then((res) => {
				if (res.code == 200) {
					// 进入下一步
					clearInterval(this.codeTimer)
					this.codeText = '获取验证码'
					this.codeTime = 60
					this.isBtnCode = true
					this.smsCode = ''
					this.$nextTick(() => {
						this.stepActive = 1
					})
				} else {
					this.$message(res.msg, 'error')
				}
			})
		},
		tapToNextTwo() {
			if (!this.phoneForm.phones || this.phoneForm.phones == '') {
				this.$message('请先输入手机号', 'error')
				return
			}
			if (!this.phoneForm.twoCode || this.phoneForm.twoCode == '') {
				this.$message('请输入验证码', 'error')
				return
			}
			// 请求修改手机号接口
			this.stepChange()
		},
		// 新手机号输入失焦
		newPhoneBlur() {
			const phoneReg = /^1[3456789]\d{9}$/
			if (this.phoneForm.phones == '') {
				this.phoneError = true
				this.errorPMsg = '请输入手机号'
			} else if (!phoneReg.test(this.phoneForm.phones)) {
				this.phoneError = true
				this.errorPMsg = '手机号格式错误'
			} else {
				this.phoneError = false
				this.errorPMsg = ''
			}
		},
		// 修改手机号
		stepChange() {
			let params = {
				phone: this.phoneForm.phones,
				smsCode: this.phoneForm.twoCode,
			}
			this.$http.postAction(this.$api.updatePhone, params).then((res) => {
				if (res.code == 200) {
					this.stepActive = 2

					this.timers = setInterval(() => {
						let codetime = this.times
						codetime--
						this.times = codetime
						if (codetime < 1) {
							clearInterval(this.timers)
							this.times = 0
							// this.isBtnCode = true;
							// 跳转到登录页
							setTimeout(() => {
								window.localStorage.clear()
								this.$router.push('/login')
							}, 500)
						}
					}, 1000)
				} else {
					this.$message(res.msg, 'error')
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.person-wrap {
	height: 100%;
	font-size: 0.2rem;
	color: #454545;
	.person-div {
		background-color: #fff;
		border-radius: 0.13rem;
	}
	.person-left {
		width: 3.65rem;
		height: 100%;
		padding: 0.4rem 0 0 0.3rem;
		font-size: 0.2rem;
		color: #454545;
		& > div {
			margin-bottom: 0.4rem;
			padding-left: 0.26rem;
		}
		.tab-active {
			color: #2daea9;
			position: relative;
			&::before {
				content: '';
				position: absolute;
				width: 0.04rem;
				height: 0.2rem;
				background-color: #2daea9;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}
		}
	}
	.person-right {
		flex: 1;
		margin-left: 0.24rem;
		height: 100%;
		position: relative;
		.person-avatar {
			width: 1.25rem;
			height: 1.25rem;
			background: #f1f1f1;
			border-radius: 50%;
			text-align: center;
			line-height: 1.25rem;
			.avatar-img {
				width: 0.5rem;
			}
		}
		.edit-btn {
			width: 1.75rem;
			height: 0.45rem;
			line-height: 0.45rem;
			border-radius: 4px;
			font-size: 0.17rem;
			cursor: pointer;
			&.editbtn-save {
				color: #ffffff;
				background: #2daea9;
				margin-left: 0.5rem;
			}
			&.editbtn-cancle {
				color: #2daea9;
				border: 1px solid #2daea9;
			}
		}
	}
	// 编辑按钮
	.btn-edit {
		width: 1.75rem;
		height: 0.4rem;
		background: #2daea9;
		border-radius: 0.13rem;
		line-height: 0.45rem;
		position: absolute;
		top: 0.35rem;
		right: 0.41rem;
	}
	// 用户信息默认
	.wrap-one {
		text-align: left;
		padding-top: 1.52rem;

		.person-avatar {
			margin: auto;
		}
		.user-infos {
			margin-top: 0.9rem;
			line-height: 1;
			div:not(:first-child) {
				margin-top: 0.69rem;
			}
			.span-name {
				display: inline-block;
			}
			.user-word {
				margin-left: 0.4rem;
			}
			// 详细地址
			.user-address {
				max-width: 3.75rem;
				line-height: 1.5;
				letter-spacing: 1px;
				margin-top: -3px;
			}
		}
	}
	// 用户信息编辑
	.wrap-one-edit {
		text-align: center;
		.person-avatar {
			margin-left: 0;
			margin: auto;
		}
		.form-edit {
			margin-top: 0.4rem !important;
			div {
				margin-top: 0.4rem !important;
			}
		}
		.user-infoedit {
			.user-input {
				width: 5.55rem;
				height: 0.45rem;
				margin-top: 0 !important;
			}
		}
	}
	// 修改密码
	/deep/ .wrap-password {
		padding-top: 1.52rem;
		.person-avatar {
			margin: auto;
		}
		.pass-form {
			padding-top: 0.3rem;
			.el-form-item {
				margin-top: 0.4rem;
				margin-bottom: 0;
				&.edit-btns {
					margin-top: 2.31rem;
					.el-form-item__content {
						margin-left: 0 !important;
					}
				}
				.el-input__suffix {
					display: flex;
					align-items: center;
					justify-content: center;
					.el-input__suffix-inner {
						display: block;
					}
					.eye-icon {
						display: block;
						cursor: pointer;
						width: 0.25rem;
						height: 0.2rem;
						margin-right: 0.175rem;
						background: url('../../assets/image/login/icon-eye.png') no-repeat;
					}
					.eyen-icon {
						display: block;
						cursor: pointer;
						width: 0.25rem;
						height: 0.2rem;
						margin-right: 0.175rem;
						background: url('../../assets/image/login/icon-eyen.png') no-repeat;
					}
				}
			}
		}
		.user-input {
			width: 5.55rem;
			height: 0.45rem;
			margin-top: 0 !important;
		}
	}
	// 修改手机号
	.wrap-phone {
		padding-top: 2.89rem;
		.step-content {
			width: 5.63rem;
			margin: 0.9rem auto 0;
		}
		.step-wraps {
			width: 8.13rem;
		}
		.one-forms {
			margin-top: 0.56rem;
			.step-one-name {
				display: inline-block;
				width: 1.13rem;
			}
			.code-div {
				margin-top: 0.55rem;
			}
			.phone-span {
				height: 0.45rem;
				line-height: 0.45rem;
			}
			.one-input {
				width: 2.25rem;
				height: 0.45rem;
				background: #ffffff;

				border-radius: 8px;

				.el-input__inner {
					height: 0.45rem;
					line-height: 0.45rem;
					border: 1px solid #e5e5e5;
				}
			}
			.step-btn {
				width: 1.5rem;
				height: 0.45rem;
				background: #2daea9;
				border-radius: 0.05rem;
				font-size: 0.2rem;
				color: #fefefe;
				margin-left: 0.3rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			// 修改密码的手机号错误提示
			.code-phone {
				position: relative;
				.phone-error {
					position: absolute;
					color: red;
					font-size: 14px;
					left: 1.2rem;
					top: calc(100% + 0.13rem);
				}
			}
		}

		// 下一步按钮
		.btn-tonext {
			width: 1.75rem;
			height: 0.45rem;
			// line-height: 0.45rem;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #2daea9;
			border-radius: 0.05rem;
			font-size: 0.18rem;
			color: #ffffff;
			// margin: 2.67rem auto 0;
			margin: 2.37rem auto 0;
			cursor: pointer;
		}
		.step-three {
			.three-img {
				width: 0.65rem;
				height: 0.75rem;
				margin-right: 0.3rem;
			}
			.span-phone {
				color: #2daea9;
			}
			.dao-time {
				margin-top: 0.26rem;
			}
		}
	}
}
</style>
<style lang="scss">
.person-wrap {
	.el-input__inner {
		height: 0.45rem;
		line-height: 0.45rem;
		border-radius: 8px;
	}
	.user-cascader {
		.el-input .el-input__inner {
			border-radius: 0.23rem;
		}
	}
	.wrap-password {
		.pass-form {
			.el-form-item {
				&.edit-btns {
					.el-form-item__content {
						margin-left: 0 !important;
					}
				}
			}
		}
	}
	.wrap-phone {
		.one-forms {
			.el-input__inner {
				height: 0.45rem;
				line-height: 0.45rem;
				border-radius: 8px;
			}
			.one-input-error {
				.el-input__inner {
					border-color: red;
				}
			}
		}
		.el-step__icon {
			width: 0.5rem;
			height: 0.5rem;
		}
		.el-step.is-center .el-step__line {
			top: 50%;
			transform: translateY(-50%);
			left: 66%;
			right: -34%;
		}
		// 进行状态
		.el-step__head.is-process {
			border-color: #2daea9;
			color: #fff;
			.el-step__icon {
				background-color: #2daea9;
			}
		}

		.el-step__title.is-process {
			color: #2daea9;
		}
		// 等待状态
		.el-step__head.is-wait {
			border-color: #e5e5e5;
			color: #fff;
			.el-step__icon {
				background-color: #e5e5e5;
			}
		}
		.end-step {
			.el-step__head.is-wait {
				border-color: #e5e5e5;
				color: #fff;
				.el-step__icon {
					background-color: transparent;
				}
				.self-endicon {
					font-size: 0.55rem;
					color: #e5e5e5;
				}
			}
			.el-step__head.is-process {
				border-color: #2daea9;
				color: #fff;
				.el-step__icon {
					background-color: transparent;
				}
				.self-endicon {
					font-size: 0.55rem;
					color: #2daea9;
				}
			}
		}
		// 成功状态
		.el-step__head.is-success {
			border-color: #2daea9;
			color: #fff;
			.el-step__icon {
				background-color: #2daea9;
			}
			.el-step__line {
				background-color: #2daea9;
			}
		}
		.el-step__title.is-success {
			color: #2daea9;
			font-weight: bold;
		}
	}
}
</style>
