<template>
  <div class="flex flex_col notice-page">
    <!-- 上面的选择 -->
    <div class="selects">
      <el-form
        :model="queryParam"
        ref="ruleform"
        :inline="true"
        class="flex flex_ju_sb warnform-inline"
      >
        <div class="form-inputs">
          <el-form-item label="业务类型">
            <el-select
              v-model="queryParam.messageType"
              placeholder="请选择业务类型"
            >
              <el-option
                v-for="(item, index) in selectList"
                :key="index"
                :value="item.dictValue"
                :label="item.dictLabel"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="发生时间" class="form-picker">
            <div class="picker-divs picker-divs-more">
              <el-date-picker
                v-model="queryParam.startTime"
                size="small"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期"
                popper-class="pick-poper"
                class="picker-div"
                prefix-icon=" "
                clear-icon="el-icon-date"
                :clearable="false"
                :picker-options="pickerOptions0"
                :editable="false"
                default-time="00:00:00"
              >
              </el-date-picker>
              <i class="pick-icon el-icon-date"></i>
              <i class="pick-icon-yuan el-icon-time"></i>
            </div>

            <span class="pick-word">至</span>
            <div class="picker-divs picker-divs-more">
              <el-date-picker
                v-model="queryParam.endTime"
                size="small"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期"
                popper-class="pick-poper"
                class="picker-div"
                prefix-icon=" "
                clear-icon="el-icon-date"
                :clearable="false"
                :picker-options="pickerOptions1"
                :editable="false"
                default-time="23:59:59"
              >
              </el-date-picker>
              <i class="pick-icon el-icon-date"></i>
              <i class="pick-icon-yuan el-icon-time"></i>
            </div>
          </el-form-item>
        </div>

        <el-form-item class="flex flex_ju_end form-btns">
          <el-button type="primary" class="btn-reset" @click="formReset"
            >重置</el-button
          >
          <el-button type="primary" @click="searchClick">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 下面的表格 -->
    <div class="flex flex_col warn-tables">
      <!-- 列表切换tab -->
      <div class="btn-switchs">
        <div class="btn-switch-btn" @click="handleReadP">批量已读</div>
        <div class="btn-switch-btn btn-switch-all" @click="handleReadAll">
          全部已读
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="tableData"
        class="tables"
        :height="535"
        align="center"
        header-cell-class-name="header-cell"
        cell-class-name="table-cell"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          :selectable="selectable"
          width="60"
          align="right"
        >
        </el-table-column>
        <el-table-column type="index" label="序号" width="100" align="center">
        </el-table-column>
        <el-table-column
          label="业务类型"
          align="center"
          show-overflow-tooltip
          width="120"
        >
          <template slot-scope="scope">
            <span class="titles-table">{{
              tableTitle(scope.row.messageType)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="messageTitle"
          label="业务标题"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="messageBody"
          label="业务内容"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="发生时间"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="pushTime"
          label="推送时间"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <span
              class="table-edit"
              v-if="scope.row.readStatus == 0"
              @click="handleRead(scope.row)"
              >设为已读</span
            >
            <span class="table-edit table-read" v-else>已读</span>
            <span class="table-detail" @click="handleDetail(scope.row)"
              >详情</span
            >
          </template>
        </el-table-column>

        <!-- 占位数据 -->
        <div slot="empty" class="table-empty">
          <img class="table-noimg" src="@/assets/image/home/ZWT.png" alt="" />
          <div class="table-emptyword">暂无数据</div>
        </div>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination"
        layout="prev, pager, next"
        :current-page.sync="ipagination.currentPage"
        :page-size="ipagination.pageSize"
        :total="ipagination.total"
        :hide-on-single-page="true"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <notice-drawer ref="noticeDrawerRef"></notice-drawer>
  </div>
</template>

<script>
/**
 * @description 消息通知页面
 */
import tableMinin from "@/mixins/TableMinin";
import NoticeDrawer from "./components/notice-drawer.vue";
import { mapState, mapMutations } from "vuex";
export default {
  mixins: [tableMinin],
  components: {
    NoticeDrawer,
  },
  data() {
    const self = this;
    return {
      rangeTime: [],
      selectDefault: {
        dictValue: "0",
        dictLabel: "全部",
      },
      selectList: [], // 上面的下拉选数据
      multipleSelection: [], // 批量选中数据
      isTranPage: true,
      ifUseLoadBack: false,
      pageValue: "page", // 页码传参的key值，主要用于区分不同的传参要求
      pageLimit: "limit",
      httpTime: null,
      // 接口
      url: {
        select: self.$api.getMsgType,
        list: self.$api.getMsgList,
      },
    };
  },
  computed: {
    // ...mapState(['socketMsg']),
  },
  mounted() {
    // 获取生产区域下拉选
    this.getSelect();
    // console.log(this.socketMsg)
  },
  methods: {
    getSelect() {
      this.$http
        .getAction(this.url.select)
        .then((res) => {
          if (res.code == 200) {
            let datas = res.data || [];
            this.selectList = datas || [];
            this.selectList.unshift(this.selectDefault);
            this.$set(
              this.queryParam,
              "messageType",
              this.selectList[0]["dictValue"]
            );
          } else {
            this.selectList = [];
          }
        })
        .finally(() => {
          this.loadData(1);
          // 下拉选获取后，请求列表数据
          this.httpTime = setInterval(() => {
            this.loadData(1);
          }, 300000);
        });
    },
    // 获取列表数据
    // 查询获取列表数据
    loadData(arg) {
      if (!this.url.list || this.url.list == "") {
        this.$message("请设置url.list属性!", "error");
        return;
      }
      // if (arg == 1) {
      //   this.ipagination.currentPage = 1;
      // }
      this.loading = true;
      let params = this.getQueryParams();
      let types = this.queryParam.messageType || "0";
      this.$set(params, "messageType", types);
      if (this.queryParam.startTime) {
        this.$set(params, "beginTime", this.queryParam.startTime);
        this.$delete(params, "startTime");
      }
      console.log(params);
      this.$http
        .getAction(this.url.list, params)
        .then((res) => {
          if (res.code == 0) {
            let result = res.data || {};
            if (res.count > 0) {
              let datas = result || [];
              this.tableData = datas || [];
              this.ipagination.total = Number(res.count);
              // this.ipagination.pageSize = Number(result.size)
            } else {
              this.tableData = [];
              this.ipagination.total = 0;
              this.ipagination.pageSize = 10;
            }
          }
        })
        .finally((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        })
        .catch(() => {
          this.loading = falses;
        });
    },
    // 查询按钮点击
    searchClick() {
      this.$refs["ruleform"].validate((valid) => {
        if (valid) {
          if (this.httpTime) {
            clearInterval(this.httpTime);
          }
          this.ipagination.currentPage = 1;
          this.loadData();
          this.$nextTick(() => {
            this.httpTime = setInterval(() => {
              this.loadData();
            }, 300000);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置按钮点击
    formReset() {
      this.$refs["ruleform"].resetFields();
      if (this.httpTime) {
        clearInterval(this.httpTime);
      }
      this.queryParam = {};
      if (this.selectList.length) {
        this.$set(
          this.queryParam,
          "messageType",
          this.selectList[0]["dictValue"]
        );
      } else {
        this.$set(this.queryParam, "messageType", "");
      }
      this.ipagination.currentPage = 1;
      this.loadData(1);
      this.$nextTick(() => {
        this.httpTime = setInterval(() => {
          this.loadData(1);
        }, 300000);
      });
    },
    // 类型显示
    tableTitle(value) {
      for (const item of this.selectList) {
        if (item.dictValue == value) {
          return item.dictLabel;
        }
      }
    },
    selectable(val) {
      if (val.readStatus == 0) {
        return true;
      }
      return false;
    },
    // 选项发生变化时触发
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量已读
    handleReadP() {
      const _this = this;
      if (this.multipleSelection.length) {
        this.$confirm("确定将选中消息设为已读吗？", "操作", {
          confirmButtonText: "确定",
          cancelButtonText: "再想想",
          customClass: "del-dialog",
          cancelButtonClass: "btn-cancel",
          confirmButtonClass: "btn-confirm",
        })
          .then(() => {
            let multipleSelection = [];
            this.multipleSelection.forEach((item) => {
              multipleSelection.push(item.id);
            });
            console.log(multipleSelection);
            let params = {
              ids: multipleSelection,
            };
            // 请求接口,并重新获取列表
            this.$http.postAction(this.$api.makeOneRead, params).then((res) => {
              if (res.code == 200) {
                this.$message(res.msg, "success");
                if (this.httpTime) {
                  clearInterval(this.httpTime);
                }
                this.loadData(1);
                // 重新请求数据
                this.$nextTick(() => {
                  this.httpTime = setInterval(() => {
                    this.loadData(1);
                  }, 300000);
                });
              } else {
                this.$message(res.msg, "error");
              }
            });
            // TODO 静态化处理
            // let tableData = JSON.parse(JSON.stringify(this.tableData))
            // this.multipleSelection.forEach((item) => {
            // 	let itemIndex = tableData.findIndex((items) => {
            // 		return items.id == item.id
            // 	})
            // 	this.$set(tableData[itemIndex], 'readStatus', '1')
            // })
            // this.tableData = tableData
          })
          .catch(() => {});
      } else {
        this.$message("暂无选中值", "warning");
      }
    },
    // 全部已读
    handleReadAll() {
      this.$confirm("确定全部设为已读吗？", "操作", {
        confirmButtonText: "确定",
        cancelButtonText: "再想想",
        customClass: "del-dialog",
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm",
      })
        .then(() => {
          // 请求接口,并重新获取列表

          this.$http.postAction(this.$api.makeAllRead).then((res) => {
            if (res.code == 200) {
              this.$message(res.msg, "success");
              if (this.httpTime) {
                clearInterval(this.httpTime);
              }
              this.loadData(1);
              // 重新请求数据
              this.$nextTick(() => {
                this.httpTime = setInterval(() => {
                  this.loadData(1);
                }, 300000);
              });
            } else {
              this.$message(res.msg, "error");
            }
          });

          // TODO 静态化处理
          // let tableData = JSON.parse(JSON.stringify(this.tableData))
          // tableData.forEach((item) => {
          // 	item.readStatus = 1
          // })
          // this.tableData = tableData
        })
        .catch(() => {});
    },
    // 单个设为已读
    handleRead({ id }) {
      this.$confirm("确定将此条消息设为已读吗？", "操作", {
        confirmButtonText: "确定",
        cancelButtonText: "再想想",
        customClass: "del-dialog",
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm",
      })
        .then(() => {
          let multipleSelection = [];
          multipleSelection.push(id);
          let params = {
            ids: multipleSelection,
          };
          // 请求接口,并重新获取列表
          this.$http.postAction(this.$api.makeOneRead, params).then((res) => {
            if (res.code == 200) {
              this.$message(res.msg, "success");
              if (this.httpTime) {
                clearInterval(this.httpTime);
              }
              this.loadData(1);
              // 重新请求数据
              this.$nextTick(() => {
                this.httpTime = setInterval(() => {
                  this.loadData(1);
                }, 300000);
              });
            } else {
              this.$message(res.msg, "error");
            }
          });
          // TODO 静
          // TODO 静态化处理
          // let itemIndex = this.tableData.findIndex((item) => {
          // 	return item.id == id
          // })
          // this.$set(this.tableData[itemIndex], 'readStatus', 1)
        })
        .catch(() => {});
    },
    // 详情
    handleDetail(item) {
      let multipleSelection = [];
      multipleSelection.push(item.id);
      let params = {
        ids: multipleSelection,
      };
      // 请求接口,并重新获取列表
      this.$http.postAction(this.$api.makeOneRead, params).then((res) => {
        if (res.code == 200) {
          //   this.$message(res.msg, "success");
          if (this.httpTime) {
            clearInterval(this.httpTime);
          }
          this.loadData(1);
          // 重新请求数据
          this.$nextTick(() => {
            this.httpTime = setInterval(() => {
              this.loadData(1);
            }, 300000);
          });
        } else {
          this.$message(res.msg, "error");
        }
      });
      let types = this.tableTitle(item.messageType);
      this.$refs.noticeDrawerRef.open({
        item: item,
        types: types,
      });
    },
  },
  destroyed() {
    if (this.httpTime) {
      clearInterval(this.httpTime);
    }
  },
  beforeDestroy() {
    if (this.httpTime) {
      clearInterval(this.httpTime);
    }
  },
  watch: {
    // 收到推送重新加载数据
    setSocketMsg: {
      handler(newval) {
        if (this.httpTime) {
          clearInterval(this.httpTime);
        }
        this.loadData(1);
        this.$nextTick(() => {
          this.httpTime = setInterval(() => {
            this.loadData(1);
          }, 300000);
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1585px) {
  .warnform-inline {
    .form-inputs {
      .el-form-item {
        &:not(:last-child) {
          margin-right: 2vw;
        }
        &.form-item-had {
          margin-right: 2vw;
        }
      }
      .el-select {
        width: 2.5rem;
      }
    }
  }
}
@media screen and (max-width: 1370px) {
  .warnform-inline {
    .form-inputs {
      .el-form-item {
        &:not(:last-child) {
          margin-right: 1vw;
        }
        &.form-item-had {
          margin-right: 1vw;
          .el-select {
            width: 2.3rem;
          }
        }
      }
    }
  }
}
.notice-page {
  height: 100%;
  .form-btns {
    .el-button {
      padding: 0;
      line-height: 0.45rem;
      border: 1px solid #42b5b2;
      width: 1.13rem;
      background: #42b5b2;
      border-radius: 0.05rem;
      margin-left: 0;
    }
    .btn-reset {
      background-color: #fff;
      border: 1px solid #42b5b2;
      color: #42b5b2;
      margin-right: 0.3rem;
    }
  }

  .warn-tables {
    padding-top: 0.3rem;
    padding-bottom: 0.15rem;

    flex: 1;
    min-height: 610px;
  }
  // 列表切换tab
  .btn-switchs {
    text-align: center;
    width: 2.5rem;
    position: relative;
    margin: 0 0.35rem 0.45rem 0;
    align-self: flex-end;
    .btn-switch-btn {
      width: 1rem;
      display: inline-block;
      height: 0.45rem;
      line-height: 0.45rem;
      font-size: 0.17rem;
      color: #fff;
      background-color: #2daea9;
      border-radius: 0.04rem;
      cursor: pointer;
    }
    .btn-switch-all {
      margin-left: 0.4rem;
    }
  }
  // 表格中的样式
  .tables {
    width: 97%;
    align-self: center;
    .table-edit {
      display: inline-block;
      width: 1.25rem;
      font-size: 0.17rem;
      color: #3392e6;
      cursor: pointer;
    }
    .table-read {
      font-size: 0.17rem;
      color: #666666;
    }
    .table-detail {
      font-size: 0.17rem;
      color: #2daea9;
      cursor: pointer;
    }
  }
  .pagination {
    padding-top: 0;
  }
}
</style>
<style lang="scss">
@import "@/assets/css/del_dialog.scss";
.notice-page {
  .el-table th.el-table__cell > .cell {
    padding-right: 14px;
  }
  .warnform-inline {
    .form-picker {
      .picker-divs-range {
        .picker-div {
          width: 4.4rem;
        }
        .el-date-editor .el-range__close-icon {
          display: none;
        }
        .el-range-input {
          width: 42%;
        }
        .el-range-separator {
          width: 7%;
          padding: 0;
        }
      }
    }
  }
}
</style>
