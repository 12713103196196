<template>
	<el-drawer
		custom-class="deviceDrawer setPassword"
		title="设置"
		:visible.sync="drawer"
		size="23%"
		@close="drawerClose"
	>
		<!-- 顶部盒子 -->
		<div class="drawe-top">
			<div class="img-box">
				<img src="@/assets/\image/product/xcsb_img.png" alt="" />
			</div>
			<div class="txt-box">
				<div class="tb-li doble" :style="{ height: 32 + 'px' }">
					<div class="flex flex_a_c" :style="{ fontSize: 16 + 'px' }" v-show="!bjInputShow">
						{{ equipmentInfo.subDeviceName }}
						<div class="bj-icon" @click="editorClick(equipmentInfo.subDeviceName)"></div>
					</div>
					<div class="input-div" v-show="bjInputShow">
						<el-input placeholder="请输入内容" v-model="bjInputValue">
							<i slot="suffix" class="suffix-content">
								<img src="@/assets/image/equipment/icon-quxiao.png" alt="" @click.stop="qxeditor" />
								<img src="@/assets/image/equipment/icon-queding.png" alt="" @click.stop="determineName" />
							</i>
						</el-input>
					</div>
				</div>
				<div class="tb-li">
					{{ kzxInfo.areaName }}
					<div class="line"></div>
					<span class="green"> <span v-if="kzxInfo.online">在线</span><span v-else>离线</span></span>
				</div>
				<div class="tb-li">
					设备状态：
					<!-- 控制箱 -->
					<template v-if="equipmentType == '1'">
						<template v-if="equipmentInfo.itemList[0].dataItemValue == '0'">
							<span class="blue"
								><span v-if="equipmentInfo.itemList[2].dataItemValue == '00'"
									>已停止{{ equipmentInfo.itemList[1].dataItemValue }}%</span
								>
								<span v-if="equipmentInfo.itemList[2].dataItemValue == '10'"
									>已下降{{ equipmentInfo.itemList[1].dataItemValue }}%</span
								>
								<span v-if="equipmentInfo.itemList[2].dataItemValue == '01'"
									>已上升{{ equipmentInfo.itemList[1].dataItemValue }}%</span
								></span
							>
						</template>
						<template v-else>
							<span class="red">已急停</span>
						</template>
					</template>
					<!-- 智能终端 -->
					<template v-if="equipmentType == '2'">
						<template
							v-if="equipmentInfo.itemList[2].dataItemValue == '0' || equipmentInfo.itemList[2].dataItemValue == ''"
						>
							<span class="blue"
								><span
									v-if="equipmentInfo.itemList[0].dataItemValue == '0' || equipmentInfo.itemList[0].dataItemValue == ''"
									>已停止{{ equipmentInfo.itemList[1].dataItemValue || 0 }}%</span
								>
								<span v-if="equipmentInfo.itemList[0].dataItemValue == '2'"
									>已下降{{ equipmentInfo.itemList[1].dataItemValue }}%</span
								>
								<span v-if="equipmentInfo.itemList[0].dataItemValue == '1'"
									>已上升{{ equipmentInfo.itemList[1].dataItemValue }}%</span
								></span
							>
						</template>
						<template v-else>
							<span class="red">已急停</span>
						</template>
					</template>
				</div>
			</div>
		</div>

		<div class="jiqiren">
			<div class="j-l"></div>
			<div class="txt-box">设备属于行程设备，为保证设备运行安全，请完成时长保护设置。</div>
		</div>
		<div class="line"></div>
		<div class="title">请先设置安全密码</div>
		<div class="mminput-box flexbox">
			<div class="code-item" :class="codeValue.length == 0 && inputFocus ? 'code-item-active' : ''">
				<template v-if="codeValue[0]">●</template>
			</div>
			<div class="code-item" :class="codeValue.length == 1 && inputFocus ? 'code-item-active' : ''">
				<template v-if="codeValue[1]">●</template>
			</div>
			<div class="code-item" :class="codeValue.length == 2 && inputFocus ? 'code-item-active' : ''">
				<template v-if="codeValue[2]">●</template>
			</div>
			<div class="code-item" :class="codeValue.length == 3 && inputFocus ? 'code-item-active' : ''">
				<template v-if="codeValue[3]">●</template>
			</div>
			<div class="code-item" :class="codeValue.length == 4 && inputFocus ? 'code-item-active' : ''">
				<template v-if="codeValue[4]">●</template>
			</div>
			<div class="code-item" :class="codeValue.length >= 5 && inputFocus ? 'code-item-active' : ''">
				<template v-if="codeValue[5]">●</template>
			</div>
			<el-input
				class="input-code"
				v-model="codeValue"
				:maxlength="6"
				@blur="codeInputBlur"
				@focus="codeInputFocus"
				ref="myinput"
				type="number"
				:focus="inputFocus"
			>
			</el-input>
		</div>
		<!-- <div class="tishi-box">6位数字密码且数字不能重复</div> -->
		<div class="btn-box">
			<div class="btn-item" @click="btnClick">确定</div>
		</div>
		<Msg ref="tsmsg"></Msg>
	</el-drawer>
</template>

<script>
import Msg from '@/components/msg.vue' //信息提示组件
export default {
	components: { Msg },
	data() {
		return {
			// 控制抽屉的开关
			drawer: false,
			// 类型
			equipmentType: null, //1控制箱 2智能终端
			// 控制箱信息
			kzxInfo: {},
			// 设备信息
			equipmentInfo: {
				subDeviceNum: '',
				subDeviceName: '',
				subDeviceType: '',
				subDeviceIcon: '',
				itemList: [
					{
						dataItemCode: 'e_stop',
						dataItemName: '急停',
						dataItemValue: '0',
					},
					{
						dataItemCode: 'khd',
						dataItemName: '设备开度值',
						dataItemValue: '0',
					},
					{
						dataItemCode: 'status',
						dataItemName: '状态',
						dataItemValue: '00',
					},
				],
				commandsItemList: [
					{
						commandsItemCode: '00',
						commandsItemName: '停止',
					},
					{
						commandsItemCode: '10',
						commandsItemName: '下降',
					},
					{
						commandsItemCode: '01',
						commandsItemName: '上升',
					},
				],
			},
			//   控制编辑输入框的显示
			bjInputShow: false,
			//   编辑输入框内容
			bjInputValue: '',
			codeValue: '',
			inputFocus: false,
		}
	},
	mounted() {
		// this.$refs.myinput.focus();
		console.log(this.equipmentType)
	},
	methods: {
		// 抽屉关闭
		drawerClose() {
			this.codeValue = ''
			this.drawer = false
			this.$bus.$emit('querySafePassword')
		},
		// 点击编辑按钮
		editorClick(value) {
			this.bjInputValue = value
			this.bjInputShow = true
		},
		// 取消编辑
		qxeditor() {
			this.bjInputShow = false
		},
		// 确认编辑
		determineName() {
			this.bjInputShow = false
		},
		// 验证码输入框失去焦点
		codeInputBlur() {
			this.inputFocus = false
		},
		// 验证码输入框获取到焦点
		codeInputFocus() {
			this.inputFocus = true
		},
		// 使聚焦
		makeFocus() {
			this.$refs.myinput.focus()
		},
		// 确定点击
		btnClick() {
			// 2023-01-18  whc更改:暂时去除重复校验
			// let regexp = /^(?!\d*?(\d)\d*?\1)\d{6}$/
			let regexp = /^[0-9]{6}$/
			if (regexp.test(this.codeValue)) {
				this.setSafePassword()
			} else {
				// this.$refs.tsmsg.msgShow('请输入6位数字密码且数字不能重复', 1.5)
				this.$refs.tsmsg.msgShow('请输入6位数字密码', 1.5)
			}
		},
		//设置安全密码
		setSafePassword() {
			this.$http.postAction(this.$api.setSafePassword + '?password=' + this.codeValue).then((res) => {
				if (res.code == 200) {
					this.$refs.tsmsg.msgShow('设置成功', 1.5)
					setTimeout(() => {
						this.drawerClose()
						this.$bus.$emit('openXcDrawerToscbh')
					}, 1500)
				} else {
					this.$refs.tsmsg.msgShow(res.msg, 1.5)
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped></style>
<style lang="scss">
.setPassword {
	border-radius: 0.225rem 0px 0px 0.225rem;
	.el-drawer__header {
		height: 0.825rem;
		box-sizing: border-box;
		text-align: left;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #454545;
		padding-top: 0;
		border-bottom: 1px solid #f1f1f1;
		span {
			font-size: 0.225rem;
		}
	}
	.el-drawer__close-btn {
		color: #454545;
	}
	.el-drawer__body {
		padding: 0 0.3rem 0.25rem;
		display: flex;
		flex-direction: column;
		position: relative;
		.drawe-top {
			display: flex;
			padding-bottom: 0.275rem;
			border-bottom: 1px solid rgba(241, 241, 241, 1);
			.img-box {
				width: 1.5rem;
				height: 1.5rem;
				background-color: rgba(45, 174, 169, 0.18);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 0.8625rem;
				}
			}
			.txt-box {
				margin-left: 0.225rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				box-sizing: border-box;
				padding: 0.15rem 0;
				.tb-li {
					display: flex;
					font-size: 0.2rem;
					.line {
						width: 0.0125rem;
						height: 0.2rem;
						background: #bfbfbf;
						margin: 0 0.2rem;
					}
					.bj-icon {
						cursor: pointer;
						margin-left: 0.1875rem;
						width: 0.2rem;
						height: 0.2rem;
						background: url('../../../assets/image/product/icon-bj.png') no-repeat;
					}
					.input-div {
						width: 2.875rem;
						height: 0.4rem;
						.el-input {
							height: 100%;
						}
						.el-input__inner {
							border-radius: 0.1rem;
							height: 100%;
							font-weight: normal;
						}
						.el-input.is-active .el-input__inner,
						.el-input__inner:focus {
							border-color: rgba(45, 174, 169, 1);
						}
						.el-input__suffix {
							width: 4vw;
							height: 100%;
							.el-input__suffix-inner {
								display: block;
								width: 100%;
								height: 100%;
								display: flex;
								align-items: center;
								.suffix-content {
									display: flex;
									align-items: center;
									width: 100%;
									height: 1.099vw;
									box-sizing: border-box;
									border-left: 1px solid #f1f1f1;
									justify-content: space-around;
									padding: 0 0.1875rem;
									img {
										cursor: pointer;
										width: 0.175rem;
										height: 0.175rem;
									}
								}
							}
						}
					}
				}
				.doble {
					font-weight: bold;
				}
				.green {
					color: rgba(73, 201, 136, 1);
				}
				.blue {
					color: rgba(45, 174, 169, 1);
				}
				.red {
					color: rgb(248, 134, 56);
				}
			}
		}
		.line {
			height: 1px;
			background: #f1f1f1;
			margin: 0.3rem 0;
		}
		.jiqiren {
			display: flex;
			align-items: center;
			margin-top: 0.3rem;
			box-sizing: border-box;
			padding-left: 0.3rem;
			.j-l {
				margin-right: 0.25rem;
				width: 1.5125rem;
				height: 2.2125rem;
				background: url('../../../assets/image/product/jiqiren.png') no-repeat;
			}
			.txt-box {
				width: 2.575rem;
				height: 1.3125rem;
				background: url('../../../assets/image/product/tishikuang.png') no-repeat;
				box-sizing: border-box;
				padding: 0.225rem 0.1375rem 0.1875rem 0.25rem;
				font-size: 0.2rem;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ffffff;
				line-height: 1.5;
			}
		}
		.title {
			text-align: left;
		}
		.mminput-box {
			margin-top: 20px;
			position: relative;
			display: flex;
			justify-content: space-between;
		}
		.input-code {
			position: absolute;
			width: 100%;
			overflow: hidden;
			height: 0.625rem;
		}
		.code-item {
			width: 48px;
			height: 50px;
			text-align: center;
			box-sizing: border-box;
			line-height: 50px;
			border: 1px solid #f0f0f0;
			margin-right: 10px;
			color: rgba(45, 174, 169, 1);
		}
		.code-item-active {
			border: 1px solid rgba(45, 174, 169, 1);
			box-sizing: border-box;
		}
		// 隐藏input
		.mminput-box {
			.el-input__inner {
				height: 50px;
				background-color: transparent;
				border: none;
				color: transparent;
				padding-right: 1500px;
			}
		}
		.tishi-box {
			box-sizing: border-box;
			padding-top: 0.2125rem;
			width: 2.725rem;
			height: 0.575rem;
			background: url('../../../assets/image/product/tishikuang2.png') no-repeat;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #fc784c;
			line-height: 20px;
		}
		.btn-box {
			flex: 1;
			// position: relative;
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			.btn-item {
				box-sizing: border-box;
				border: 0.0125rem solid #2daea9;
				cursor: pointer;
				width: 1.75rem;
				height: 0.45rem;
				background: #2daea9;
				border-radius: 0.05rem;
				text-align: center;
				line-height: 0.45rem;
				color: white;
			}
			.cancel {
				background: #fff;
				color: #2daea9;
			}
		}
	}
}
</style>
