<template>
  <el-drawer
    custom-class="AssociatedMonitoring"
    title="关联监控"
    :visible.sync="drawer"
    size="23%"
    @close="drawerClose"
  >
    <div class="znzd-tab">
      <div
        class="item"
        :class="{ itemAct: tabIndex == 0 }"
        @click="menuClick(0)"
      >
        关联监控
      </div>
      <div
        class="item"
        :class="{ itemAct: tabIndex == 1 }"
        @click="menuClick(1)"
      >
        解除关联
      </div>
    </div>
    <div class="flex flex_ju_sb" :style="{ marginTop: 20 + 'px' }">
      <el-checkbox v-model="allchecked" @change="allcheckedChange"
        >全选</el-checkbox
      >
      <span>已选{{ num }}项</span>
    </div>

    <div class="jk-list roll-auto" v-if="jkList.length">
      <div class="item" v-for="(item, index) in jkList" :key="index">
        <div class="flex flex_a_c">
          <el-checkbox v-model="item.isCheck"></el-checkbox>
          <div class="img-box">
            <img src="@/assets/image/product/jiankong.png" alt="" />
          </div>
          <div class="name">{{ item.cameraName }}</div>
        </div>
      </div>
    </div>
    <div class="jk-list-no" v-else>
      <img src="@/assets/image/home/ZWT.png" alt="" />
    </div>
    <!-- 确定按钮 -->
    <div class="btn-box" v-if="jkList.length">
      <div class="btn-item" @click="qudingClick">确定</div>
    </div>
    <Msg ref="tsmsg1"></Msg>
  </el-drawer>
</template>

<script>
import Msg from "@/components/msg.vue"; //信息提示组件
export default {
  components: { Msg },
  data() {
    return {
      // 控制抽屉的开关
      drawer: false,
      tabIndex: 0,
      allchecked: false,
      // 设备id
      deviceId: null,
      //监控列表
      jkList: [
        // {
        //   id: "001",
        //   name: "监控点001",
        //   isCheck: false,
        // },
        // {
        //   id: "001",
        //   name: "监控点001",
        //   isCheck: false,
        // },
        // {
        //   id: "001",
        //   name: "监控点001",
        //   isCheck: false,
        // },
        // {
        //   id: "001",
        //   name: "监控点001",
        //   isCheck: false,
        // },
        // {
        //   id: "001",
        //   name: "监控点001",
        //   isCheck: false,
        // },
        // {
        //   id: "001",
        //   name: "监控点001",
        //   isCheck: false,
        // },
        // {
        //   id: "001",
        //   name: "监控点001",
        //   isCheck: false,
        // },
        // {
        //   id: "001",
        //   name: "监控点001",
        //   isCheck: false,
        // },
        // {
        //   id: "001",
        //   name: "监控点001",
        //   isCheck: false,
        // },
        // {
        //   id: "001",
        //   name: "监控点001",
        //   isCheck: false,
        // },
      ],
      //   选中的列表
      checkList: [],
    };
  },
  computed: {
    num() {
      let arr = [];
      arr = this.jkList.filter((item) => {
        if (item.isCheck) {
          return item;
        }
      });
      return arr.length;
    },
  },
  methods: {
    drawerClose() {
      this.tabIndex = 0;
      this.drawer = false;
      this.allchecked = false;
      this.$bus.$emit("gengxinVideo");
    },
    menuClick(index) {
      this.tabIndex = index;
      //用户摄像头列表
      if (index == 0) {
        this.getVideoList();
      }
      // 用户已关联的摄像头列表
      if (index == 1) {
        this.deviceCamera();
      }
    },
    allcheckedChange() {
      if (this.allchecked) {
        this.jkList.forEach((item, index) => {
          item.isCheck = true;
        });
      } else {
        this.jkList.forEach((item, index) => {
          item.isCheck = false;
        });
      }
    },
    // 确定点击
    qudingClick() {
      // 关联
      if (this.tabIndex == 0) {
        let arr = [];
        let params = {
          deviceCameras: [],
        };
        arr = this.jkList.filter((item, index) => {
          if (item.isCheck) {
            return item;
          }
        });
        if (arr.length == 0) {
          this.$refs.tsmsg1.msgShow("请先选择监控", 1.5);
          return;
        }
        arr.forEach((item, index) => {
          params.deviceCameras.push({
            cameraResourceId: item.cameraResourceId,
            deviceId: this.deviceId,
          });
        });
        this.deviceCameraAdd(params);
      } else {
        // 解除关联
        let arr = [];
        let params = {
          deviceId: this.deviceId,
          cameraResourceId: [],
        };
        arr = this.jkList.filter((item, index) => {
          if (item.isCheck) {
            return item;
          }
        });
        if (arr.length == 0) {
          this.$refs.tsmsg1.msgShow("请先选择监控", 1.5);
          return;
        }
        arr.forEach((item, index) => {
          params.cameraResourceId.push(item.cameraResourceId);
        });
        this.rebindCamera(params);
      }
    },
    //用户摄像头列表
    getVideoList() {
      this.$http
        .getAction(this.$api.getVideoList + "?name=&exclude=1")
        .then((res) => {
          if (res.data.length) {
            res.data.forEach((item, index) => {
              item.isCheck = false;
            });
            this.jkList = res.data;
          } else {
            this.jkList = [];
          }
        });
    },
    // 用户已关联的摄像头列表
    deviceCamera() {
      this.$http
        .getAction(this.$api.deviceCamera + "?deviceId=" + this.deviceId)
        .then((res) => {
          if (res.data.length) {
            res.data.forEach((item, index) => {
              item.isCheck = false;
            });
            this.jkList = res.data;
          } else {
            this.jkList = [];
          }
        });
    },
    //用户设备关联摄像头
    deviceCameraAdd(params) {
      this.$http.postAction(this.$api.deviceCameraAdd, params).then((res) => {
        if (res.code == 200) {
          this.$refs.tsmsg1.msgShow("关联成功", 1.5);
          setTimeout(() => {
            this.drawerClose();
          }, 1500);
        } else {
          this.$refs.tsmsg1.msgShow("关联失败", 1.5);
        }
      });
    },
    //用户设备取消关联摄像头
    rebindCamera(params) {
      this.$http.postAction(this.$api.rebindCamera, params).then((res) => {
        if (res.code == 200) {
          this.$refs.tsmsg1.msgShow("解除关联成功", 1.5);
          setTimeout(() => {
            this.drawerClose();
          }, 1500);
        } else {
          this.$refs.tsmsg1.msgShow("解除关联失败", 1.5);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.AssociatedMonitoring {
  border-radius: 0.225rem 0px 0px 0.225rem;
  .el-drawer__header {
    height: 0.825rem;
    box-sizing: border-box;
    text-align: left;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #454545;
    padding-top: 0;
    border-bottom: 1px solid #f1f1f1;
    span {
      font-size: 0.225rem;
    }
  }
  .el-drawer__close-btn {
    color: #454545;
  }
  .el-drawer__body {
    padding: 0 0.3rem 0.25rem;
    display: flex;
    flex-direction: column;
    .znzd-tab {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .item {
        cursor: pointer;
        font-size: 0.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        padding-bottom: 0.15rem;
        border-bottom: 0.025rem solid transparent;
      }
      .itemAct {
        color: rgba(45, 174, 169, 1);
        border-bottom: 0.025rem solid rgba(45, 174, 169, 1);
      }
    }
    .jk-list {
      height: 83%;
      margin-top: 0.25rem;
      overflow: auto;

      .item {
        height: 1rem;
        background: #e7f1f3;
        border-radius: 0.125rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 0.25rem;
        margin-bottom: 0.25rem;
        &:last-child {
          margin-bottom: 0;
        }

        .img-box {
          margin-left: 0.3rem;
          width: 0.6rem;
          height: 0.6rem;
          background: #ffffff;
          border: 1px solid #2daea9;
          border-radius: 50%;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .name {
          margin-left: 0.2rem;
        }
      }
    }
    .jk-list-no {
      height: 83%;
      margin-top: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item:last-child {
      margin-bottom: 0;
    }
    .btn-box {
      flex: 1;
      // position: relative;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      .btn-item {
        box-sizing: border-box;
        border: 0.0125rem solid #2daea9;
        cursor: pointer;
        width: 1.75rem;
        height: 0.45rem;
        background: #2daea9;
        border-radius: 0.05rem;
        text-align: center;
        line-height: 0.45rem;
        color: white;
      }
      .cancel {
        background: #fff;
        color: #2daea9;
      }
    }
  }
}
</style>
