<template>
	<div class="flex flex_col logging-page">
		<!-- 上面的选择 -->
		<div class="selects">
			<el-form :model="queryParam" ref="ruleform" :inline="true" class="flex flex_ju_sb warnform-inline">
				<div class="form-inputs">
					<el-form-item label="业务名称">
						<el-select v-model="queryParam.typeId" placeholder="请选择业务模块">
							<el-option
								v-for="(item, index) in selectList"
								:key="index"
								:value="item.id"
								:label="item.name"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="状态">
						<el-select v-model="queryParam.typeId" placeholder="请选择状态">
							<el-option
								v-for="(item, index) in selectList"
								:key="index"
								:value="item.id"
								:label="item.name"
							></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="日志时间" class="form-picker">
						<div class="picker-divs">
							<el-date-picker
								v-model="queryParam.startTime"
								size="small"
								type="datetime"
								placeholder="请选择时间区间"
								popper-class="pick-poper"
								class="picker-div"
								prefix-icon=" "
								:clearable="false"
							>
							</el-date-picker>
						</div>
					</el-form-item>
					<el-form-item label="访问终端">
						<el-select v-model="queryParam.typeId" placeholder="请选择访问终端">
							<el-option
								v-for="(item, index) in selectList"
								:key="index"
								:value="item.id"
								:label="item.name"
							></el-option>
						</el-select>
					</el-form-item>
				</div>

				<el-form-item class="form-btns">
					<el-button type="primary" @click="searchClick">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 下面的表格 -->
		<div class="flex flex_col warn-tables">
			<!-- 表格 -->
			<el-table
				:data="tableData"
				class="tables"
				:height="535"
				:stripe="true"
				align="center"
				header-cell-class-name="header-cell"
				cell-class-name="table-cell"
			>
				<el-table-column type="index" label="序号" width="100" align="center"> </el-table-column>
				<el-table-column label="业务名称" align="center" show-overflow-tooltip>
					<template slot-scope="scope">
						<span class="titles-table">{{ tableTitle(scope.row.typeId) }}</span>
					</template>
				</el-table-column>

				<el-table-column prop="content" label="日志内容" align="center" show-overflow-tooltip> </el-table-column>
				<el-table-column prop="modal" label="状态" align="center" show-overflow-tooltip> </el-table-column>

				<el-table-column prop="times" label="日志时间" align="center" show-overflow-tooltip> </el-table-column>
				<el-table-column prop="ways" label="访问终端" align="center" show-overflow-tooltip> </el-table-column>
				<el-table-column prop="equipname" label="操作系统" align="center" show-overflow-tooltip> </el-table-column>
				<el-table-column prop="address" label="应用信息" align="center" show-overflow-tooltip> </el-table-column>

				<!-- 占位数据 -->
				<div slot="empty" class="table-empty">
					<img class="table-noimg" src="@/assets/image/home/ZWT.png" alt="" />
					<div class="table-emptyword">暂无数据</div>
				</div>
			</el-table>
			<!-- 分页 -->
			<el-pagination
				class="pagination"
				layout="prev, pager, next"
				:current-page.sync="ipagination.currentPage"
				:page-size="ipagination.pageSize"
				:total="ipagination.total"
				:hide-on-single-page="true"
				@current-change="handleCurrentChange"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
/**
 * @description 系统日志页面
 */
import tableMinin from '@/mixins/TableMinin'
export default {
	mixins: [tableMinin],
	data() {
		const self = this
		return {
			// 上面的下拉选数据
			selectList: [
				{
					name: '产品离线',
					id: 1,
				},
				{
					name: '我的场景',
					id: 2,
				},
				{
					name: '告警设置',
					id: 3,
				},
				{
					name: '时长设置',
					id: 4,
				},
				{
					name: '灾害中心',
					id: 5,
				},
				{
					name: '个人中心',
					id: 6,
				},
			],
			isTranPage: true,
			ifUseLoadBack: false,
			// 接口
			url: {
				list: self.$api.findLoggList,
			},
		}
	},
	computed: {},
	mounted() {
		// 获取生产区域下拉选
		// this.getSelect()

		this.$message('功能模块开发中...', 'warning')
	},
	methods: {
		getSelect() {
			// 下拉选获取后，请求列表数据
			// this.loadData(1)
		},
		// 类型显示
		tableTitle(value) {
			for (const item of this.selectList) {
				if (item.id == value) {
					return item.name
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1585px) {
	.warnform-inline {
		.form-inputs {
			.el-form-item {
				&:not(:last-child) {
					margin-right: 2vw;
				}
				&.form-item-had {
					margin-right: 2vw;
				}
			}
			.el-select {
				width: 2.5rem;
			}
		}
	}
}
@media screen and (max-width: 1370px) {
	.warnform-inline {
		.form-inputs {
			.el-form-item {
				&:not(:last-child) {
					margin-right: 1vw;
				}
				&.form-item-had {
					margin-right: 1vw;
					.el-select {
						width: 2.3rem;
					}
				}
			}
		}
	}
}
.logging-page {
	height: 100%;
	.warn-tables {
		padding-top: 0.72rem;
		padding-bottom: 0.15rem;

		flex: 1;
		min-height: 610px;
	}
	// 表格中的样式
	.tables {
		width: 97%;
		align-self: center;
		border-radius: 0;
	}
	.pagination {
		padding-top: 0;
	}
}
</style>
