<template>
	<div class="chart-div" :id="'chartLine' + index"></div>
</template>

<script>
/**
 * @description 环境数据分析-统计图表单个折线图
 */
export default {
	data() {
		return {
			colors: [
				'#2DAEA9',
				'#1AA1DF',

				'#8745E9',
				'#0EF0A3',
				'#EE00EB',
				'#2D3CAE',
				'#D76933',
				'#ADE534',
				'#F33D1B',
				'#EAEC1B',
				'#0EF024',
				'#0EECF3',
				'#998AE7',
				'#1560D6',
			],
		}
	},
	props: {
		datas: {
			type: Object,
			default: () => {
				return {}
			},
		},
		index: {
			type: Number,
			default: 0,
		},
		orgName: {
			type: String,
			default: '',
		},
	},
	watch: {
		datas(val) {
			console.log(val)
			this.echartLine()
		},
	},
	mounted() {
		this.echartLine()
	},
	computed: {
		areaColors() {
			return this.convertHexToRGB(this.colors[this.index], 0.26)
		},
	},
	methods: {
		echartLine() {
			const _this = this
			let lineData = JSON.parse(JSON.stringify(this.datas))
			let makeMax = JSON.parse(JSON.stringify(this.datas))
			// let xData = lineData.acquisitionTimes
			// let yData = lineData.values
			makeMax.dataList.sort(function (a, b) {
				let obj1 = a[1]
				let obj2 = b[1]
				return Number(obj2) - Number(obj1)
			})
			let maxs = makeMax.dataList[0][1]
			let splits = Math.ceil(Number(maxs) / 6)
			let yMax = splits * 6
			let myChart = this.$echarts.init(document.getElementById('chartLine' + _this.index))
			let option = {
				title: {
					text: lineData.name,
					textStyle: {
						color: '#454545',
						fontSize: 16,
						fontWeight: 'normal',
					},
					left: 'center',
					top: 8,
				},
				tooltip: {
					show: true,
					trigger: 'axis',
					backgroundColor: '#fff',
					padding: [11, 17],
					extraCssText:
						'box-shadow: 0px 0px 12px 0px rgba(76, 216, 179, 0.33);text-align:left;font-size:14px;color:#3F3F3F;letter-spacing: 1px;',
					formatter: function (param) {
						let infos = ''
						infos += `<div style="margin:12px 0">${param[0].name}</div><div style="color:${
							_this.colors[_this.index]
						}">${param[0].marker}${lineData.name}：${param[0].value[1]}${lineData.unit}</div>`
						return infos
					},
					// formatter: '气象站一号</br>{b}</br>每日降雨量累计：{c}mm',
				},
				color: this.colors[this.index],
				grid: {
					left: '12%',
					bottom: '15%',
					top: '23%',
					right: '8%',
				},
				xAxis: {
					type: 'category',
					// data: xData,
					// 刻度线
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#EDEDED',
							width: 2,
						},
					},
					axisLabel: {
						color: '#6B6B6B',
						fontSize: 13,
						lineHeight: 12,
						margin: 10,
						width: 80,
						overflow: 'breakAll',
					},
					boundaryGap: false,
				},
				yAxis: {
					type: 'value',
					name: lineData.unit,
					max: yMax,
					interval: splits,
					nameTextStyle: {
						color: '#6B6B6B',
						fontSize: 14,
						align: 'right',
					},
					axisLabel: {
						color: '#6B6B6B',
						fontSize: 14,
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: '#EDEDED',
							width: 2,
						},
					},
				},
				series: [
					{
						type: 'line',
						smooth: true,
						showSymbol: false,
						symbolSize: 10,
						itemStyle: {
							borderColor: this.colors[this.index],
							borderWidth: 4,
						},
						areaStyle: {
							color: this.areaColors,
						},
						markPoint: {
							symbolSize: 50,
							label: {
								color: '#fff',
								fontSize: 12,
							},
							data: [
								{
									type: 'max',
									name: '最大值',
									itemStyle: {
										color: '#FF648C',
									},
									z: 1,
								},
								{
									type: 'min',
									name: '最小值',
									itemStyle: {
										color: '#3EA2EF',
									},
									z: 2,
								},
							],
						},
						markLine: {
							data: [
								{
									type: 'average',
									name: '平均值',
									lineStyle: {
										type: 'solid',
										color: '#FFA800',
									},
									label: {
										position: 'middle',
										formatter: '平均值:{c}',
										color: '#FFA800',
										distance: 10,
										fontSize: 16,
									},
									symbol: 'none',
								},
							],
						},
						data: lineData.dataList,
					},
				],
			}
			myChart.clear()
			myChart.setOption(option)
			window.addEventListener('resize', function () {
				myChart.resize()
			})
		},
	},
}
</script>

<style scoped>
.chart-div {
	width: 5.79rem;
	height: 2.92rem;
}
</style>
