import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
/*
 * @description: 主要用作socket接收事件的中转存储
 */
export default new Vuex.Store({
	state: {
		socketMsg: {}, // 消息中心
		socketWarn: {}, // 告警信息
		socketTime: {}, // 行程设备时长自动上报
		socketStatus: {}, // 执行设备实时状态
		socketOnline: {}, // 在离线
	},
	mutations: {
		setSocketMsg(state, data) {
			state.socketMsg = data
		},
		setSocketWarn(state, data) {
			state.socketWarn = data
		},
		setSocketTime(state, data) {
			state.socketTime = data
		},
		setSocketStatus(state, data) {
			state.socketStatus = data
		},
		setSocketOnline(state, data) {
			state.socketOnline = data
		},
	},
	actions: {},
})
