<template>
	<div class="addCpct" v-if="drawer">
		<el-drawer
			v-loading="loading"
			custom-class="addCpDrawer"
			title="添加产品"
			:visible.sync="drawer"
			size="22%"
			@close="drawerClose"
		>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="产品编号：" prop="name">
					<el-input v-model="ruleForm.name" @focus="nameFous" placeholder="请输入产品编号"></el-input>
				</el-form-item>
				<el-form-item label="产品密码：" prop="password">
					<el-input
						v-model="ruleForm.password"
						@focus="passwordFous"
						show-password
						placeholder="请输入产品密码"
					></el-input>
				</el-form-item>
			</el-form>
			<div class="btn-box">
				<el-button class="myElBtn" @click="submitForm('ruleForm')">确定</el-button>
			</div>
		</el-drawer>
		<Msg ref="tjmsg"></Msg>
	</div>
</template>

<script>
import Msg from '@/components/msg.vue' //信息提示组件
export default {
	components: { Msg },
	data() {
		return {
			loading: false,
			// 控制抽屉的开关
			drawer: false,
			//   验证规则
			rules: {
				name: [
					{ required: true, message: '请输入产品编号', trigger: 'blur' },
					{
						min: 8,
						max: 14,
						message: '长度在 8 到 14 个字符',
						trigger: 'blur',
					},
				],
				password: [{ required: true, message: '请输入产品密码', trigger: 'blur' }],
			},
			//   表单数据
			ruleForm: {
				name: '',
				password: '',
			},
		}
	},
	methods: {
		// 鼠标获取焦点
		nameFous() {
			this.$refs['ruleForm'].clearValidate('name') //获取焦点时如果有效验结果清除结果
		},
		passwordFous() {
			this.$refs['ruleForm'].clearValidate('password') //获取焦点时如果有效验结果清除结果
		},
		// 确定
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.loading = true
					this.bindDevice()
				} else {
					return false
				}
			})
		},
		// 添加产品
		bindDevice() {
			this.$http
				.postAction(
					this.$api.bindDevice +
						'?deviceCompNum=' +
						this.ruleForm.name.slice(2, this.ruleForm.name.length - 1) +
						'&devicePass=' +
						this.ruleForm.password
				)
				.then((res) => {
					console.log(res)
					if (res.code == '200') {
						this.loading = false
						this.$refs.tjmsg.msgShow('添加成功', 1.5)
						this.$refs['ruleForm'].resetFields()
						this.drawer = false
						this.$bus.$emit('getMyDevice')
						// setTimeout(() => {
						//   this.$bus.$emit("openDrwer");
						// }, 500);
					} else {
						this.loading = false

						if (res.msg == '无该设备信息！') {
							this.$refs.tjmsg.msgShow('无该产品信息！', 1.5)
						} else {
							this.$refs.tjmsg.msgShow(res.msg, 1.5)
						}
					}
				})
		},
		// 抽屉关闭
		drawerClose() {
			this.$refs['ruleForm'].resetFields()
			this.drawer = false
		},
	},
}
</script>

<style lang="scss">
.addCpct {
	.addCpDrawer {
		border-radius: 0.225rem 0px 0px 0.225rem;
		.el-drawer__header {
			height: 0.825rem;
			box-sizing: border-box;
			text-align: left;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #454545;
			padding-top: 0;
			border-bottom: 1px solid #f1f1f1;
			span {
				font-size: 0.225rem;
			}
		}
		.el-drawer__close-btn {
			color: #454545;
		}
		.el-drawer__body {
			padding: 0 0.3rem 0.25rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.el-input.is-active .el-input__inner,
			.el-input__inner:focus {
				border-color: #2daea9;
			}
			.btn-box {
				width: 100%;
				.myElBtn {
					width: 1.75rem;
					background: #2daea9;
					border-color: transparent;
					color: #ffffff;
				}
			}
		}
	}
}
</style>
