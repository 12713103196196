<template>
  <el-container class="homeindex" direction="horizontal">
    <!-- 左侧菜单 -->
    <el-aside>
      <TheMenu />
    </el-aside>
    <!-- 右侧内容 -->
    <el-container>
      <!-- 头部 -->
      <el-header>
        <Header />
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <!-- © 2010-2020 河南元丰科技网络股份有限公司版权所有 -->
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import TheMenu from "@/components/index/TheMenu.vue";
import Header from "@/components/index/Header.vue";
/**
 * @description 内容总页面
 */
export default {
  name: "Index",
  components: {
    TheMenu,
    Header,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/index.scss";
</style>
