<template>
  <div class="Ring-box">
    <div class="yuan-bg">
      <MyCircleSlider
        v-model="sliderValue"
        :side="228"
        :min="0"
        :max="100"
        :circle-width="24"
        :progress-width="24"
        :circle-width-rel="10"
        :progress-width-rel="10"
        :knob-radius="12"
        circle-color="transparent"
        :progress-color="color"
        :knob-color="qiuColor"
      ></MyCircleSlider>
      <MyCircleSlider
        v-model="sliderValueTwo"
        :side="228"
        :min="0"
        :max="100"
        :circle-width="24"
        :progress-width="24"
        :circle-width-rel="10"
        :progress-width-rel="10"
        :knob-radius="12"
        circle-color="transparent"
        :progress-color="color2"
        :knob-color="qiuColor2"
        :isHua="isHua"
      ></MyCircleSlider>
      <div class="progress-text" v-if="status !== '-1'">
        <div class="topnum" :style="{ color: color }">
          <span v-if="status == '1'">上升中</span>
          <span v-if="status == '2'">下降中</span>
          <span v-if="status == '0'">已停止</span>
          <span v-if="status == '3'">{{ sliderValueTwo }}%</span>
          <i
            class="el-icon-switch-button"
            v-if="status == '4'"
            @click="startClick"
          ></i>
        </div>
        <div class="bottom">
          <span v-if="status == '4'">启动</span>
          <span v-else
            >当前位置{{ ((sliderValue / 100) * 100).toFixed(0) }}%</span
          >
        </div>
      </div>
      <div class="progress-text" v-else>
        <div class="topnum" :style="{ color: color }">取消急停</div>
        <div class="bottom">点击取消进行其他操作</div>
      </div>
    </div>
  </div>
</template>

<script>
import MyCircleSlider from "@/components/MyCircleSlider.vue";
export default {
  components: { MyCircleSlider },
  data() {
    return {
      sliderValue: 0,
      sliderValueTwo: 0,
      color: "rgba(45, 174, 169, 1)", //实际进度条的颜色
      color2: "rgba(45, 174, 169, .3)", //拖动进度条的颜色
      qiuColor: "#fff", //实际进度条圆球颜色
      qiuColor2: "#fff", //拖动进度条的圆球颜色
      status: "0", //-1 急停 0 停止 1 上升 2 下降 3拖动中 4拖动结束;
      isHua: true, //控制是否可以拖动
      isTuoRun: false, //是否是拖动运动 trun 是 false 不是
    };
  },
  mounted() {
    this.$bus.$on("mousedown", this.mousedown);
    this.$bus.$on("mouseup", this.mouseup);
  },
  destroyed() {
    this.$bus.$off("mousedown");
    this.$bus.$off("mouseup");
  },
  props: {
    operateDevice: {
      type: Function,
      default: null,
    },
  },
  watch: {
    sliderValueTwo: {
      handler(newVal) {
        // console.log(newVal);
        if (newVal > this.sliderValue) {
          this.color2 = "rgba(45, 174, 169, .3)";
          this.qiuColor = "rgba(45, 174, 169, 1)";
          this.qiuColor2 = "#fff";
        } else if (newVal < this.sliderValue) {
          this.color2 = "#bae2e1";
          this.qiuColor = "rgba(45, 174, 169, 1)";
          this.qiuColor2 = "#fff";
        } else {
          this.color2 = this.color;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    mousedown() {
      console.log("鼠标按下");
      this.status = "3";
    },
    mouseup() {
      console.log("鼠标抬起");
      if (this.sliderValue !== this.sliderValueTwo) {
        this.status = "4";
      }else {
        
      }
    },
    // 启动点击
    startClick() {
      this.isTuoRun = true;
      let operateStr="";
      if(this.sliderValue > this.sliderValueTwo) {
        operateStr = "decline"//下降
      }else{
        operateStr = "rise"//上升
      }
      this.operateDevice(operateStr,this.sliderValueTwo);
    },
  },
};
</script>

<style lang="scss" scoped>
.Ring-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 20px;
  .yuan-bg {
    position: relative;
    margin: 0 auto;
    width: 240px;
    height: 236px;
    background: url("../../../assets/image/product/yuanhuanbg.png") no-repeat;
    background-size: 100% 100%;
    background-position-x: 0px;
    background-position-y: -3px;
    // color: transparent;
    // &>div {
    //     transform: rotate(180deg);
    // }
    .progress-text {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%) translateY(-50%);
      width: 174px;
      height: 174px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #999999;
      font-size: 28px;
      text-align: center;
      line-height: 174px;
      font-weight: bold;
      i {
        cursor: pointer;
        font-size: 50px;
      }
      .topnum {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2daea9;
        margin-bottom: 14px;
        span {
          font-size: 28px;
        }
      }
      .bottom {
        color: #999999;
        font-size: 0.1625rem;
      }
    }
  }
}
</style>
