<template>
  <span>{{ str }}</span>
</template>

<script>
export default {
  data() {
    return {
      str: "",
      timer: null,
      time: "",
      timeTo:"",
    };
  },
  mounted() {
    this.countDown();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  computed: {},
  methods: {
    // 格式化时间
    countDown() {
      let h = parseInt((this.time / (60 * 60)) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((this.time / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(this.time % 60);
      s = s < 10 ? "0" + s : s;
      this.str = h + ":" + m + ":" + s;
    },
    //定时器开始
    Time() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.countDown();
      this.timer = setInterval(() => {
        if (this.time == this.timeTo) {
          clearInterval(this.timer);
          return;
        }
        this.time = this.time + 1;
        this.$bus.$emit("tiaozhengTime",1)
        this.countDown();
      }, 1000);
    },
    // 停止
    stop() {
      clearInterval(this.timer);
    },
    // // 重置
    // reset() {
    //   this.time = this.num;
    //   this.countDown();
    //   this.Time();
    // },
  },
};
</script>

<style>
</style>