<template>
  <div class="initialization" v-if="drawer">
    <el-drawer
      custom-class="initializationDrawer"
      title="产品初始化"
      :visible.sync="drawer"
      size="23%"
    >
      <div class="gif-box">
        <img src="../../../assets/image/equipment/dongtu.gif" alt="" />
      </div>
      <div class="txt">正在初始化，请稍等...</div>
      <div class="txt">初始失败</div>
      <div class="btn">重新初始化</div>
      <Msg ref="tjmsg"></Msg>
    </el-drawer>
  </div>
</template>

<script>
import Msg from "@/components/msg.vue"; //信息提示组件
export default {
  components: { Msg },
  data() {
    return {
      // 控制抽屉的开关
      drawer: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.initialization {
  .initializationDrawer {
    border-radius: 0.225rem 0px 0px 0.225rem;
    .el-drawer__header {
      height: 0.825rem;
      box-sizing: border-box;
      text-align: left;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #454545;
      padding-top: 0;
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 0.225rem;
      span {
        font-size: 0.225rem;
      }
    }
    .el-drawer__close-btn {
      color: #454545;
    }
    .el-drawer__body {
      padding: 0 0.3rem 0.25rem;
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: center;
      box-sizing: border-box;
      padding-top: 18%;
      .gif-box {
        // width: 2.625rem;
        // height: 2.625rem;
        // background: url("../../../assets/image/equipment/csh.png") no-repeat;
        width: 4.25rem;
        height: 4.25rem;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        line-height: 2;
      }
      .btn {
        cursor: pointer;
        margin-top: 0.5rem;
        width: 1.75rem;
        height: 0.45rem;
        background: #2daea9;
        border-radius: 4px;
        text-align: center;
        line-height: 0.45rem;
        color: #fff;
      }
    }
  }
}
</style>