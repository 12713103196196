/*
 * @description: 区域管理页面-树形相关逻辑
 */
export default {
	data() {
		return {
			dialogVisible: false, // 删除确认框是否显示
			delItem: {}, // 删除的数据
			editData: {}, // 选中编辑的数据
			isAdd: false, // 是否是新增状态
			isTap: false, // 编辑/新增是否点击了按钮,主要用于抢先失焦事件
			// 新增的默认数据
			treeAddItem: {
				areaName: '',
				id: '',
				isEdit: true,
			},
		}
	},
	methods: {
		// 新增点击
		treeAdd(node, data) {
			// 判断是否已经是新增状态
			if (this.isAdd) return
			this.isTap = false
			this.isAdd = true
			// 设置新增的数据
			let obj = { ...this.treeAddItem }
			let ids = data.id
			let thisId = `${ids}-${data.children.length + 1}`
			obj.id = thisId
			this.editData = { ...obj }
			// 添加数据
			data.children.push(obj)

			this.$nextTick(() => {
				if (this.$refs['slotTreeInput' + thisId]) {
					this.$refs['slotTreeInput' + thisId].$refs.input.focus()
				}
			})
			// 展开节点
			if (!node.expaned) {
				node.expaned = true
			}
		},
		// 删除点击
		treeDetele(event, node, data) {
			event.preventDefault()
			this.isTap = true
			// 判断是否有子节点，有子节点不能删除
			if (data.children && data.children.length) {
				this.$message('此节点有子级，不可删除！', 'error')
				return false
			} else {
				// 二次确认删除
				this.dialogVisible = true
				// 赋值需要删除的数据
				this.delItem = data
			}
		},
		// 编辑点击
		treeEdit(event, node, data) {
			event.preventDefault()
			this.isTap = true
			this.isAdd = false
			if (!node.isEdit) {
				this.$set(data, 'isEdit', true)
				this.editData = { ...data }
			}
			// 输入框聚焦
			this.$nextTick(() => {
				if (this.$refs['slotTreeInput' + data['id']]) {
					this.$refs['slotTreeInput' + data['id']].$refs.input.focus()
				}
			})
		},
		// 编辑/新增确定
		handleEditSubmit(event, node, data) {
			event.preventDefault()
			// 修改数据请求接口
			let params = {
				areaName: this.editData.areaName,
			}
			// 不是新增，是编辑状态
			if (!this.isAdd) {
				this.$set(params, 'id', data.id)
			}
			this.$http.postAction(this.url.areaSave, params).then((res) => {
				if (res.code == 200) {
					this.isTap = true
					this.$set(data, 'isEdit', false)
					this.$set(data, 'areaName', this.editData.areaName)
					setTimeout(() => {
						this.editData = {}
						this.isAdd = false
					}, 500)
					// 重新请求数据
					this.loadAreaData()
				} else {
					// this.$message(res.msg, 'error')
					this.$refs.tsmsg.msgShow(res.msg, 1.5);
				}
			})
		},
		// 编辑取消
		handleEditCancle(event, node, data) {
			event.preventDefault()
			this.isTap = true
			// 判断是否是新增状态
			if (this.isAdd) {
				// 直接删除
				this.$refs.trees.remove(data)
			} else {
				this.$set(data, 'isEdit', false)
			}
			setTimeout(() => {
				this.editData = {}
				this.isAdd = false
			}, 500)
		},
		// 输入框失焦，编辑 状态退出
		treeBlur(node, data) {
			if (this.isTap) {
				this.$set(data, 'isEdit', false)
			} else {
				this.$set(data, 'isEdit', false)
				if (this.isAdd) {
					// 直接删除
					this.$refs.trees.remove(data)
				}
			}
			setTimeout(() => {
				if (this.isAdd) {
					this.editData = {}
					this.isAdd = false
				}
			}, 10)
		},
		// 删除确认
		handleDeteleSubmit() {
			// 请求删除接口
			this.$http
				.deleteAction(this.url.delArea + '/' + this.delItem.id)
				.then((res) => {
					if (res.code == 200) {
						this.$refs.trees.remove(this.delItem)
						this.$message('区域删除成功!', 'success')
						// 重新请求列表数据
						this.loadData(1)
					} else {
						this.$message(res.msg, 'error')
						// 重新请求列表数据
						this.loadData(1)
					}
				})
				.catch(() => {
					this.$message('请求出错，请稍后重试', 'error')
				})
				.finally(() => {
					this.dialogVisible = false
					// 重新请求数据
					this.loadAreaData()
					setTimeout(() => {
						this.delItem = {}
					}, 500)
				})
		},
		// 删除取消
		handleDeteleCancle() {
			this.dialogVisible = false
			setTimeout(() => {
				this.delItem = {}
			}, 500)
		},
	},
}
