<template>
	<div class="product-box flex">
		<!-- 左边 -->
		<div class="left-box">
			<div class="hea-box">
				我的区域
				<div class="btn-box">
					<el-tooltip class="item" effect="dark" content="我的设备" placement="bottom-start">
						<div class="item">
							<img
								v-show="mySbType == 0 || mySbType == 2"
								src="@/assets/image/product/wode.png"
								alt=""
								@click="mySbTypeClick(1)"
							/>
							<img
								v-show="mySbType == 1"
								src="@/assets/image/product/wodexuanzhong.png"
								alt=""
								@click="mySbTypeClick(0)"
							/>
						</div>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="他人共享" placement="bottom-end">
						<div class="item">
							<img
								v-show="mySbType == 0 || mySbType == 1"
								src="@/assets/image/product/taren.png"
								alt=""
								@click="mySbTypeClick(2)"
							/>
							<img v-show="mySbType == 2" src="@/assets/image/product/gongxiang.png" alt="" @click="mySbTypeClick(0)" />
						</div>
					</el-tooltip>
				</div>
			</div>
			<div class="trees-box" v-if="treeData.length">
				<el-tree
					ref="trees"
					:data="treeData"
					:props="defaultProps"
					highlight-current
					:default-expanded-keys="[morenzankai]"
					node-key="id"
					@node-click="handleNodeClick"
				>
					<template #default="{ node, data }">
						<span class="custom-tree-node">
							<span v-if="data.children && data.children.length"
								>{{ node.label }}（<span class="on-color">{{ data.online }}</span
								>/{{ data.allNum }}）</span
							>
							<span
								v-else
								:class="{
									'tree-choose-active': playIdObj.id == data.id,
								}"
								><img
									class="tree-icon"
									v-if="data.online"
									src="../../assets/image/product/icon-online.png"
									alt="" /><img class="tree-icon" v-else src="../../assets/image/product/icon-offlInne.png" alt="" />{{
									node.label
								}}
								<img
									class="tree-icon-soure"
									v-show="data.source == 1"
									src="@/assets/image/product/tarengongxing.png"
									alt=""
							/></span>
						</span>
					</template>
				</el-tree>
			</div>
			<div class="table-empty flex flex_col flex_ju_c flex_a_c trees-box" v-else>
				<img class="table-noimg" src="@/assets/image/home/ZWT.png" alt="" />
				<div class="table-emptyword">暂无数据</div>
			</div>
		</div>
		<!-- 中间 -->
		<div class="center-box" v-show="!initialization">
			<div class="box-top">
				<div class="header-box">
					<div class="txt">视频监控</div>
					<div class="flex">
						<div class="sel-box">
							<el-select v-model="jkAct" placeholder="请选择" @change="videoChange">
								<el-option v-for="item in jkList" :key="item.id" :label="item.cameraName" :value="item.cameraUrl">
								</el-option>
							</el-select>
						</div>
						<img
							class="pointer"
							:style="{ marginLeft: 40 + 'px' }"
							src="../../assets/image/product/shezhi2.png"
							alt=""
							@click="associatedMonitoring"
						/>
					</div>
				</div>
				<div class="video-box">
					<div class="video-conten">
						<production-videos
							v-show="isShowVideo && !isVideoEmpty"
							ref="hlsVideoPlayer"
							style="width: 100%; height: 100%"
						></production-videos>
						<div class="flex flex_col flex_a_c flex_ju_c play-error" v-show="isShowVideo && isVideoEmpty">
							<img class="img_noplay" src="@/assets/image/videos/noplay.png" alt="" />
							<div class="font_16 c_white">暂无视频数据</div>
						</div>
						<div class="flex flex_col flex_a_c flex_ju_c play-error" v-show="!isShowVideo">
							<img class="img_noplay" src="@/assets/image/videos/noplay.png" alt="" />
							<div class="font_16 c_white">暂无监控设备</div>
						</div>
					</div>
				</div>
			</div>
			<div class="box-bottom">
				<div class="header-box">
					<div class="txt">设备操作</div>
					<div class="btn-switchs">
						<div class="btn-switch-btn" :class="{ 'btn-switch-active': tabIndex == 0 }" @click="changeTab(0)">
							开关设备
						</div>
						<div class="btn-switch-btn" :class="{ 'btn-switch-active': tabIndex == 1 }" @click="changeTab(1)">
							行程设备
						</div>
						<div class="bgs" :style="bgsStyle"></div>
					</div>
				</div>
				<div class="content-box">
					<!-- 开关设备 -->
					<div class="content" v-show="tabIndex == 0 && kgsbList.length">
						<div
							class="item"
							v-for="(item, index) in kgsbList"
							:key="index"
							:class="{
								marginRight0: (index + 1) % 3 == 0,
								maginBottom0: threeLie(index, kgsbList),
							}"
						>
							<div class="top-txt">
								<span v-if="item.itemList[0].dataItemValue == '1'">已打开</span>
								<span class="red" v-else>已关闭</span>
								<div class="icon" @click="openKgDrawer(item)"></div>
							</div>
							<img src="@/assets/image/product/img_sb_dcf.png" alt="" />
							<div class="name">{{ item.subDeviceName }}</div>
						</div>
					</div>
					<div class="content-no" v-show="tabIndex == 0 && !kgsbList.length">
						<img src="@/assets/image/home/ZWT.png" alt="" />
						<div class="table-emptyword">暂无数据</div>
					</div>
					<!-- 行程设备 -->
					<div class="content" v-show="tabIndex == 1 && xcsbLsist.length">
						<div
							class="item"
							v-for="(item, index) in xcsbLsist"
							:key="index"
							:class="{
								marginRight0: (index + 1) % 3 == 0,
								maginBottom0: threeLie(index, xcsbLsist),
								'item-animated': item.itemList[2].dataItemValue == '01' || item.itemList[2].dataItemValue == '10',
							}"
						>
							<div class="top-txt">
								<template v-if="item.itemList[0].dataItemValue == '0' || item.itemList[0].dataItemValue == ''">
									<span v-if="item.itemList[2].dataItemValue == '00'"
										>当前位置{{ item.itemList[1].dataItemValue }}%</span
									>
									<span v-if="item.itemList[2].dataItemValue == '10'">下降到{{ item.itemList[1].dataItemValue }}%</span>
									<span v-if="item.itemList[2].dataItemValue == '01'">上升到{{ item.itemList[1].dataItemValue }}%</span>
								</template>
								<template v-else>
									<span class="orange">设备已急停</span>
								</template>

								<div class="icon" @click="openXcDrawer(item)"></div>
							</div>
							<img src="@/assets/image/product/img_sb_jl.png" alt="" />
							<div class="name">{{ item.subDeviceName }}</div>
						</div>
					</div>
					<div class="content-no" v-show="tabIndex == 1 && !xcsbLsist.length">
						<img src="@/assets/image/home/ZWT.png" alt="" />
						<div class="table-emptyword">暂无数据</div>
					</div>
				</div>
				<!-- 全局急停盒子 -->
				<div
					class="scram-box"
					v-show="tabIndex == 1 && xcsbLsist.length"
					:class="{
						'scram-box-open': scramBtn == false,
						'scram-box2': scramState,
					}"
				>
					<div class="icon-box">
						<img v-if="scramState" @click="scramClick" src="../../assets/image/product/jiting_2.png" alt="" />
						<img v-else @click="scramClick" src="../../assets/image/product/jiting.png" alt="" />
						<div class="txt"><span v-if="scramState">取消急停</span><span v-else>全局急停</span></div>
					</div>
					<div class="btn-icon" @click="scramBtnClick"></div>
				</div>
			</div>
		</div>
		<!-- 右边 -->
		<div class="right-box" v-show="!initialization">
			<div class="header-box">
				<div class="txt">环境监测</div>
				<div class="header-r-box">
					<div class="sel-box">
						<el-select v-model="cgqAct" placeholder="请选择" @change="cgqChange">
							<el-option
								v-for="item in cgqList"
								:key="item.subDeviceNum"
								:label="item.subDeviceName"
								:value="item.subDeviceNum"
							>
							</el-option>
						</el-select>
					</div>
					<!-- <div
            class="icon"
            :class="{ 'icon-no': cgqList.length == 0 }"
            @click="alarmConfiguration"
          ></div> -->
					<div class="icon" @click="alarmConfiguration"></div>
				</div>
			</div>
			<!-- 上面具体数据 -->
			<div class="right-top">
				<div class="rt-relative">
					<div class="relative-div" :class="{ 'relative-div-noleft': codeInfoLisst.length == 1 }">
						<!-- <template v-if="warnHadDv"> -->
						<div v-if="codeIsWarn" class="flex flex_a_c flex_ju_c top-tipdiv">
							<img class="icon_warn" src="@/assets/image/home/icon_warn.png" alt="" />
							<span class="font_14 c_six">设备已离线</span>
						</div>
						<div v-else class="font_14 c_six top-tipdiv">
							采集时间：{{ codeInfos.dateTime || '--' }}
							<div
								v-show="codeInfos.dateTime != '' && codeInfos.dateTime != undefined && codeInfos.dateTime != '--'"
								class="shuaxin_div"
								@click="refreshData"
							>
								<!-- <img
                  class="shuaxin"
                  src="../../assets/image/product/shuaxin.png"
                  alt=""
                  @click="deviceSensorData"
                /> -->
								<i class="el-icon-refresh" :class="{ i_rotate: iconRotate }"></i>
								<span>刷新</span>
							</div>
						</div>
						<!-- </template> -->
						<!-- <div v-else class="top-tipdiv" style="height: 0.19rem"></div> -->
						<img v-if="cgqList.length > 0" class="img_bg" src="@/assets/image/production/img_bg.png" alt="" />
						<div v-else class="table-empty flex flex_col flex_ju_c flex_a_c trees-box">
							<img class="table-noimg" src="@/assets/image/home/ZWT.png" alt="" />
							<div class="table-emptyword">暂无数据</div>
						</div>
					</div>
				</div>
				<div class="flex flex_ju_sb rt-absolute">
					<!-- 左边的 -->
					<production-left
						:data="codeLeftList"
						v-if="codeInfoLisst.length > 1 || codeInfoLisst.length == 0"
					></production-left>
					<div class="rights"></div>
					<production-right
						:data="codeRightList"
						:ent-length="codeInfoLisst.length"
						:class="{ 'right-noleft': codeInfoLisst.length == 1 }"
					></production-right>
					<!-- 右边的 -->
				</div>
				<div v-if="codeTotalPage > 1" class="flex flex_a_c code-pages">
					<span
						class="cursor el-icon-caret-left code-icon"
						:class="{ 'code-icon-hui': codePage == 1 }"
						@click="codePageChange('-')"
					></span>
					<div class="c_six">
						<span style="color: #42b5b2">{{ codePage }}</span
						>/{{ codeTotalPage }}
					</div>
					<span
						class="cursor el-icon-caret-right code-icon"
						:class="{ 'code-icon-hui': codePage == codeTotalPage }"
						@click="codePageChange('+')"
					></span>
				</div>
			</div>
			<!-- 下面折线图 -->
			<div class="right-chart">
				<el-form :model="queryParam" ref="warnform" :inline="true" class="flex flex_ju_sb warnform-inline rb-form">
					<div class="form-inputs">
						<el-form-item label="">
							<el-select placeholder="请选择" v-model="queryParam.listparam">
								<el-option
									v-for="item in itemsSelect"
									:key="item.dataItemCode"
									:label="item.dataItemName"
									:value="item.dataItemCode"
								></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="" class="form-picker">
							<div class="picker-divs picker-divs-more">
								<el-date-picker
									v-model="queryParam.startTime"
									size="small"
									type="datetime"
									placeholder="选择日期"
									popper-class="pick-poper"
									class="picker-div"
									format="yyyy-MM-dd HH:mm:ss"
									value-format="yyyy-MM-dd HH:mm:ss"
									prefix-icon=" "
									clear-icon="el-icon-date"
									:clearable="false"
									:picker-options="pickerOptions0"
									:editable="false"
								>
								</el-date-picker>
								<i class="pick-icon el-icon-date"></i>
								<i class="pick-icon-yuan el-icon-time"></i>
							</div>

							<span class="pick-word">至</span>
							<div class="picker-divs picker-divs-more">
								<el-date-picker
									v-model="queryParam.endTime"
									size="small"
									type="datetime"
									placeholder="选择日期"
									popper-class="pick-poper"
									class="picker-div"
									format="yyyy-MM-dd HH:mm:ss"
									value-format="yyyy-MM-dd HH:mm:ss"
									prefix-icon=" "
									clear-icon="el-icon-date"
									:clearable="false"
									:picker-options="pickerOptions1"
									:editable="false"
								>
								</el-date-picker>
								<i class="pick-icon el-icon-date"></i>
								<i class="pick-icon-yuan el-icon-time"></i>
							</div>
						</el-form-item>
					</div>

					<el-form-item class="form-btns">
						<el-button type="primary" @click="searchClick">查询</el-button>
					</el-form-item>
				</el-form>
				<!-- 图表 -->
				<div class="chart-lines" ref="chartLine" v-show="isLine"></div>
				<div class="table-empty chart-lines flex flex_col flex_a_c flex_ju_c" v-show="!isLine">
					<img class="table-noimg" src="@/assets/image/home/ZWT.png" alt="" />
					<div class="table-emptyword">暂无数据</div>
				</div>
			</div>
		</div>
		<!-- 初始化盒子 -->
		<div class="csh-box" v-show="initialization">
			<!-- 初始化 -->
			<div class="csh-conten" v-show="cshAndsj == 0">
				<div class="pic">
					<img v-show="cshState == 0" src="../../assets/image/product/jqr.png" alt="" />
					<img v-show="cshState == 2 || cshState == 3" src="../../assets/image/product/chushihuashibai.png" alt="" />
				</div>
				<div class="txt" v-show="cshState == 0">正在初始化，请稍等...</div>
				<div class="txt" v-show="cshState == 2">初始化失败，<span>请点击重试</span></div>
				<div class="txt" v-show="cshState == 3">设备离线中，初始化失败</div>
			</div>
			<!-- 固件升级 -->
			<div class="csh-conten" v-show="cshAndsj == 1">
				<div class="pic">
					<img v-show="sjState == 0" src="../../assets/image/product/sj.png" alt="" />
					<img v-show="sjState == 1" src="../../assets/image/product/shibai.png" alt="" />
				</div>
				<div class="txt" v-show="sjState == 0">固件升级中，请稍等....</div>
				<div class="txt" v-show="sjState == 1">固件升级失败</div>
				<div class="btn-box" v-show="sjState == 1">
					<div class="item">取消升级</div>
					<div class="item item-again">重新升级</div>
				</div>
			</div>
		</div>
		<!-- 信息提示 -->
		<Msg ref="myMsg"></Msg>
		<!-- 关联监控抽屉 -->
		<AssociatedMonitoring ref="AssociatedMonitoring"></AssociatedMonitoring>
		<!-- 开关设备设置抽屉 -->
		<KgSetUp ref="kgSetUp"></KgSetUp>
		<!-- 行程设备设置抽屉 -->
		<XcSetUp ref="XcSetUp"></XcSetUp>
		<!-- 设置安全密码抽屉 -->
		<SetPassword ref="SetPassword"></SetPassword>
		<!-- 告警配置抽屉 -->
		<AlarmConfiguration ref="AlarmConfiguration"></AlarmConfiguration>
	</div>
</template>

<script>
import ProductionVideos from './components/productionVideos.vue'
import ProductionLeft from './components/productionLeft.vue'
import ProductionRight from './components/productionRight.vue'
import Msg from '@/components/msg.vue' //信息提示组件
import KgSetUp from './components/kgSetUp.vue' //开关设备设置抽屉
import XcSetUp from './components/xcSetup.vue' //行程设备设置抽屉
import SetPassword from './components/setPassword.vue'
import AssociatedMonitoring from './components/AssociatedMonitoring.vue'
import AlarmConfiguration from './components/AlarmConfiguration.vue'
import { mapState } from 'vuex'
import { todayTime, GetPreMonthDay, GetNextMonthDay, getFormatDate } from '../../times/moment'
export default {
	components: {
		ProductionVideos,
		ProductionLeft,
		ProductionRight,
		Msg,
		KgSetUp,
		AssociatedMonitoring,
		AlarmConfiguration,
		XcSetUp,
		SetPassword,
	},
	data() {
		return {
			// 控制我的区域里设备类型的筛选
			mySbType: 0, //0全部，1我的2他人的
			//树形组件数据
			treeData: [
				// {
				//   label: "全区域",
				//   id: "1",
				//   online: "10",
				//   allNum: "20",
				//   children: [
				//     {
				//       label: "控制箱一号",
				//       id: "1-1",
				//       online: true,
				//       source: 0, //0自己1共享
				//     },
				//     {
				//       label: "控制箱一号",
				//       id: "1-3",
				//       online: true,
				//       source: 1,
				//     },
				//     {
				//       label: "大棚一号",
				//       id: "1-2",
				//       online: "10",
				//       allNum: "20",
				//       children: [
				//         {
				//           label: "控制箱三号",
				//           id: "1-2-1",
				//           online: false,
				//           source: 0,
				//         },
				//       ],
				//     },
				//     {
				//       label: "大棚一号",
				//       id: "1-4",
				//       online: "10",
				//       allNum: "20",
				//       children: [
				//         {
				//           label: "控制箱三号",
				//           id: "1-4-1",
				//           online: false,
				//           source: 0,
				//         },
				//         {
				//           label: "控制箱三号",
				//           id: "1-4-2",
				//           online: true,
				//           source: 1,
				//         },
				//         {
				//           label: "控制箱三号",
				//           id: "1-4-3",
				//           online: false,
				//           source: 0,
				//         },
				//         {
				//           label: "控制箱三号",
				//           id: "1-4-4",
				//           online: true,
				//           source: 1,
				//         },
				//       ],
				//     },
				//   ],
				// },
				// {
				//   label: "全区域1111",
				//   id: "2",
				//   online: "10",
				//   allNum: "20",
				//   children: [
				//     {
				//       label: "控制箱一号",
				//       id: "2-1",
				//       online: true,
				//       source: 0, //0自己1共享
				//     },
				//   ],
				// },
			],
			// 控制默认展开
			morenzankai: '',
			defaultProps: {
				children: 'children',
				label: 'label',
			},
			// 存放树形组件的选中
			playIdObj: {},
			// 是否初始化
			initialization: false,
			// 初始化还是固件升级
			cshAndsj: 1, //0初始化1固件升级
			// 初始化的几种状态
			cshState: 0, //0正在1成功2失败3设备离线
			// 固件升级状态
			sjState: 1, //0正在1失败
			// 监控列表
			jkList: [],
			jkAct: '',
			isShowVideo: false, // 是否显示视频
			isVideoEmpty: false, // 视频链接是否是空
			// 控制是开关设备还是行程设备
			tabIndex: 0,
			sbAllList: {}, // 设备总数据
			// 开关设备列表
			kgsbList: [
				// {
				//   state: true,
				//   name: "电磁阀",
				// },
			],
			// 行程设备列表
			xcsbLsist: [
				// {
				//   name: "卷被机",
				//   state: 0, //0上升1下降
				//   num: "20",
				// },
				// {
				//   name: "卷被机",
				//   state: 0, //0上升1下降
				//   num: "20",
				// },
				// {
				//   name: "卷被机",
				//   state: 1, //0上升1下降
				//   num: "20",
				// },
			],
			// 当前选中的行程设备
			selectxcsb: {},
			// 是否设置了安全密码
			safePassword: false,
			// 控制全局急停按钮打开还是关闭
			scramBtn: true,
			// 全局急停按钮状态
			scramState: false,
			// 传感器列表
			cgqList: [],
			cgqAct: '',
			codeSelect: [], // 传感器下拉选,用作采集项的下拉选获取
			codeInfos: {
				dateTime: '--',
			}, // 传感器详细数据
			iconRotate: false, // 控制刷新图标
			iconRotateTimer: null, //刷新点击定时器
			codeInfoLisst: [
				// {
				//   itemCode: "EYHT",
				//   itemName: "二氧化碳",
				//   itemUnitCode: "ppm",
				//   itemValue: "--",
				// },
			],
			// 左边的数据
			codeLeftList: [
				// {
				//   itemCode: "EYHT",
				//   itemName: "二氧化碳",
				//   itemUnitCode: "ppm",
				//   itemValue: "--",
				// },
			],
			codePage: 1, // 实时数据当前页面
			codeTotalPage: 1, // 实时数据总页码
			// 右边的数据
			codeRightList: [
				// {
				//   itemCode: "TRSD",
				//   itemName: "土壤湿度",
				//   itemUnitCode: "%",
				//   itemValue: "--",
				// },
			],
			codeIsWarn: false, // 传感器是否是异常状态
			warnHadDv: true, // 是否有设备,同codeIsWarn配合使用
			queryParam: {
				listparam: '',
				startTime: '',
				endTime: '',
			},

			itemsSelect: [], // 采集项下拉选
			isLine: false, // 是否显示折线图
			pickerOptions0: {
				disabledDate: (time) => {
					if (this.queryParam.endTime) {
						if (this.queryParam.endTime != '') {
							let num = new Date(this.queryParam.endTime).getTime()
							return time.getTime() > num
						} else {
						}
					} else {
						return false
					}
				},
			},
			pickerOptions1: {
				disabledDate: (time) => {
					let num = new Date().getTime()
					if (time.getTime() > num) {
						return true
					} else {
						let startNum = new Date(this.queryParam.startTime).getTime() - 43200000
						if (time.getTime() < startNum) {
							return true
						} else {
							return false
						}
					}
				},
			},
			routerQuery: null,
		}
	},
	computed: {
		// tab切换的样式
		bgsStyle() {
			let params = {
				left: 0,
			}
			if (this.tabIndex == 0) {
				params = {
					left: 0,
				}
			} else {
				params = {
					left: '50%',
				}
			}
			return params
		},
		//执行设备实时状态
		...mapState(['socketStatus']),
	},
	mounted() {
		// this.lineChart();
		this.$bus.$on('openXcDrawerToscbh', this.openXcDrawerToscbh)
		this.$bus.$on('gengxinVideo', this.getVideoList) //刷新监控列表
		this.$bus.$on('querySafePassword', this.querySafePassword) //是否设置了安全密码
		this.$bus.$on('xiugaiNameHuiD', this.xiugaiNameHuiD) //修改名称请求回显
	},
	destroyed() {
		this.$bus.$off('gengxinVideo')
		this.$bus.$off('openXcDrawerToscbh')
		this.$bus.$off('querySafePassword')
		this.$bus.$off('xiugaiNameHuiD')
	},
	created() {
		let router = this.$router.currentRoute.query
		if (router) {
			this.routerQuery = router.deviceNum
		} else {
			this.routerQuery = null
		}
		this.$set(this.queryParam, 'startTime', `${todayTime()} 00:00:00`)
		this.$set(this.queryParam, 'endTime', `${todayTime()} 23:59:59`)
		this.userDeviceList()
		this.querySafePassword()
	},
	watch: {
		//执行设备实时状态
		socketStatus: {
			handler(newVal) {
				console.log(newVal)
				// 判断是否为当前选中设备的子设备状态改变获得了推送
				if (newVal.data) {
					if (newVal.data.deviceId == this.playIdObj.id && newVal.data.deviceNum == this.playIdObj.deviceNum) {
						this.sbAllList = newVal.data
						if (newVal.data) {
							this.kgsbList = newVal.data.subsetList.filter((item) => {
								if (item.subDeviceType == '2-1') {
									return item
								}
							})
							this.xcsbLsist = newVal.data.subsetList.filter((item) => {
								if (item.subDeviceType == '2-2') {
									return item
								}
							})
							// 判断开关设备的设置弹层是否打开
							if (this.$refs.kgSetUp.drawer) {
								this.kgsbList.forEach((item) => {
									if (item.subDeviceNum == this.$refs.kgSetUp.infos.subDeviceNums[0]) {
										let subDeviceNums = []
										subDeviceNums.push(item.subDeviceNum)
										let params = {
											subDeviceName: item.subDeviceName,
											subDeviceNums: subDeviceNums,
											deviceNum: this.sbAllList.deviceNum,
											commandsItemList: item.commandsItemList,
											deviceName: this.sbAllList.deviceName,
											dataItemValue: item.itemList[0].dataItemValue,
										}
										this.$refs.kgSetUp.infos = params
									}
								})
							}
							// 判断行程设备弹层是否打开
							if (this.$refs.XcSetUp.drawer) {
								console.log(this.$refs.XcSetUp.equipmentInfo)
								this.xcsbLsist.forEach((item) => {
									if (item.subDeviceNum == this.$refs.XcSetUp.equipmentInfo.subDeviceNum) {
										this.$refs.XcSetUp.equipmentInfo = item
										this.$nextTick(() => {
											this.$refs.XcSetUp.deviceStatus()
											this.$refs.XcSetUp.yuanhuanValue()
										})
									}
								})
							}
						}
					}
				}
			},
			deep: true,
			immediate: true,
		},
		// 行程设备列表
		xcsbLsist: {
			handler(newValue) {
				console.log(newValue)
				let arr = []
				newValue.forEach((item) => {
					if (item.itemList[0].dataItemValue == '1') {
						arr.push('true')
					}
				})
				if (arr.length == newValue.length) {
					this.scramState = true
				} else {
					this.scramState = false
				}
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		// 判断是否有下编剧
		threeLie(index, list) {
			let len = list.length
			if (list.length % 3 == 0) {
				if (index == len - 1 || index == len - 2 || index == len - 3) {
					return true
				}
			}
			if (list.length % 3 == 1) {
				if (index == len - 1) {
					return true
				}
			}
			if (list.length % 3 == 2) {
				if (index == len - 1 || index == len - 2) {
					return true
				}
			}
		},
		// 我的区域设备类型切换
		mySbTypeClick(index) {
			this.mySbType = index
		},
		// 树形组件点击事件
		handleNodeClick(data) {
			console.log(data)
			// 说明点击的是第一层，拦截点击事件
			if (data.children && data.children.length) return
			// 此列表单选
			this.playIdObj = data
			this.$set(this.queryParam, 'startTime', `${todayTime()} 00:00:00`)
			this.$set(this.queryParam, 'endTime', `${todayTime()} 23:59:59`)
			this.getVideoList()
			this.deviceSub()
			this.getcgqList()
		},
		// 视频切换
		videoChange() {
			let vurl = this.jkAct
			this.$nextTick(() => {
				this.$refs.hlsVideoPlayer.editUrl(vurl)
			})
		},
		// 开关设备行程设备点击切换
		changeTab(index) {
			this.tabIndex = index
		},
		// 全局急停盒子的打开和关闭
		scramBtnClick() {
			this.scramBtn = !this.scramBtn
		},
		// 设备急停
		scram(bool, info) {
			let params = {
				deviceNum: info.deviceNum,
				subDeviceNum: info.subDeviceNum,
				stop: bool ? '0' : '1',
				operateSource: 'WEB',
			}
			this.$http.postAction(this.$api.scram, params).then((res) => {
				if (res.code == 200) {
					this.scramState = !this.scramState
					this.$refs.myMsg.msgShow('设置成功', 1.5)
				} else {
					this.$refs.myMsg.msgShow(res.msg, 1.5)
				}
			})
		},
		// 全局急停按钮点击
		scramClick() {
			this.xcsbLsist.forEach((item) => {
				let info = {}
				info.subDeviceNum = item.subDeviceNum
				info.deviceNum = this.playIdObj.deviceNum
				this.scram(this.scramState, info)
			})
		},
		// 传感器切换
		cgqChange() {
			this.deviceSensorData()
			this.deviceSensorLinedata()
		},
		// 查询点击
		searchClick() {
			this.deviceSensorLinedata()
		},
		// x轴时间过长换行展示
		xlabelBreak(text) {
			// console.log(text.length) // 19
			// console.log(text); // 2022-04-22 17:17:48
			return text.replace(/\s/, '\n')
		},
		// 折线图绘制
		lineChart(xData, yData, yName, yUnit) {
			// xData = [1, 2, 3];
			// yData = [1, 2, 3];
			// yName = "温度";
			// yUnit = "℃";
			const _this = this
			let myChart = this.$echarts.init(this.$refs.chartLine)
			let maxY = Math.max(...yData)
			let symbolSize = 60
			let symbolFont = 12
			if (maxY.length <= 2) {
				symbolSize = 40
				symbolFont = 16
			} else if (maxY.length > 2 && maxY.length <= 3) {
				symbolSize = 50
				symbolFont = 14
			} else if (maxY.length > 3 && maxY.length < 4) {
				symbolSize = 60
				symbolFont = 12
			} else {
				symbolSize = 65
				symbolFont = 12
			}
			let option = {
				tooltip: {
					show: true,
					trigger: 'axis',
					backgroundColor: '#fff',
					padding: [14, 17],
					axisPointer: {
						type: 'line',
						lineStyle: {
							color: '#DADADA',
							type: 'solid',
							width: 2,
						},
					},
					extraCssText:
						'box-shadow: 0px 0px 12px 0px rgba(76, 216, 179, 0.33);text-align:left;font-size:14px;color:#3F3F3F;letter-spacing: 1px;',
					formatter: function (param) {
						let infos = ''
						infos += `<div style="">${param[0].name}</div><div style="text-align:left;margin-top:12px;color:#2DAEA9;">${yName}：<span style="color:#2DAEA9;">${param[0].value}${yUnit}</span></div>`
						return infos
					},
				},
				color: '#2DAEA9',
				grid: {
					left: '8%',
					bottom: '16%',
					top: '16%',
					right: '6%',
				},
				xAxis: {
					type: 'category',
					data: xData,
					// 刻度线
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#EDEDED',
							width: 2,
						},
					},
					axisLabel: {
						color: '#6B6B6B',
						fontSize: 14,
						// lineHeight: 30,
						formatter: (par) => {
							// console.log(par)
							return this.xlabelBreak(par)
						},
					},
					boundaryGap: false,
				},
				yAxis: {
					type: 'value',
					name: '（' + yUnit + '）',
					nameTextStyle: {
						color: '#6B6B6B',
						fontSize: 14,
						align: 'right',
					},
					axisLabel: {
						color: '#6B6B6B',
						fontSize: 14,
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: '#EDEDED',
							width: 2,
						},
					},
				},
				series: [
					{
						type: 'line',
						smooth: true,
						showSymbol: false,
						symbolSize: 10,
						itemStyle: {
							borderColor: '#2DAEA9',
							borderWidth: 8,
						},
						areaStyle: {
							color: 'rgba(45, 174, 169, 0.26)',
						},
						lineStyle: {
							width: 4,
						},
						markPoint: {
							symbolSize: symbolSize,
							label: {
								color: '#fff',
								fontSize: symbolFont,
							},
							data: [
								{
									type: 'max',
									name: '最大值',
									itemStyle: {
										color: '#FF648C',
									},
								},
								{
									type: 'min',
									name: '最小值',
									itemStyle: {
										color: '#3EA2EF',
									},
								},
							],
						},
						markLine: {
							data: [
								{
									type: 'average',
									name: '平均值',
									lineStyle: {
										type: 'solid',
										color: '#FFA800',
									},
									label: {
										position: 'middle',
										formatter: '平均值:{c}',
										color: '#FFA800',
										distance: 10,
										fontSize: 16,
									},
									symbol: 'none',
								},
							],
						},
						data: yData,
					},
				],
			}
			myChart.clear()
			myChart.setOption(option)
			window.addEventListener('resize', function () {
				myChart.resize()
			})
		},
		// 打开开关设备设置抽屉 { subDeviceName, subDeviceNum, commandsItemList, itemList }
		openKgDrawer(item) {
			this.$refs.kgSetUp.drawer = true
			let subDeviceNums = []
			// console.log(item);
			// console.log(item.subDeviceNum);
			subDeviceNums.push(item.subDeviceNum)
			let params = {
				subDeviceName: item.subDeviceName,
				subDeviceNums: subDeviceNums,
				deviceNum: this.sbAllList.deviceNum,
				commandsItemList: item.commandsItemList,
				deviceName: this.sbAllList.deviceName,
				dataItemValue: item.itemList[0].dataItemValue,
			}
			this.$refs.kgSetUp.equipmentList = this.cgqList
			this.$refs.kgSetUp.automationbjFrom.sensorNum = this.cgqList[0].subDeviceNum
			this.$refs.kgSetUp.equipmentItems = this.cgqList[0].itemList
			this.$refs.kgSetUp.infos = params
			this.$refs.kgSetUp.infosType = '0'
		},
		// 打开行程设备设置抽屉
		openXcDrawer(item) {
			console.log(item)
			this.selectxcsb = item
			// 判断是否设置了安全密码
			if (this.safePassword) {
				// this.$refs.XcSetUp.kzxInfo = this.playIdObj;
				// this.$refs.XcSetUp.equipmentInfo = this.selectxcsb;
				// this.$refs.XcSetUp.cgqList = this.cgqList;
				// this.$refs.XcSetUp.drawer = true;
				// this.$nextTick(() => {
				//   this.$refs.XcSetUp.deviceStatus();
				//   this.$refs.XcSetUp.yuanhuanValue();
				// });
				this.panduanshicStatus()
			} else {
				this.$refs.SetPassword.kzxInfo = this.playIdObj
				this.$refs.SetPassword.equipmentInfo = this.selectxcsb
				this.$refs.SetPassword.equipmentType = '1'

				// 打开设置安全密码界面
				this.$refs.SetPassword.drawer = true
				this.$nextTick(() => {
					this.$refs.SetPassword.makeFocus()
				})
			}
		},
		// 判断设备设置时长状态
		panduanshicStatus() {
			this.$http
				.getAction(
					this.$api.queryDuration +
						'?deviceNum=' +
						this.playIdObj.deviceNum +
						'&subDeviceNum=' +
						this.selectxcsb.subDeviceNum
				)
				.then((res) => {
					if (res.data) {
						let datas = res.data

						if (datas.whetherSet == false) {
							// 未设置时长，进入安全密码页面
							this.$refs.XcSetUp.kzxInfo = this.playIdObj
							this.$refs.XcSetUp.equipmentInfo = this.selectxcsb
							this.$refs.XcSetUp.cgqList = this.cgqList
							this.$refs.XcSetUp.drawer = true
							this.$nextTick(() => {
								this.$refs.XcSetUp.deviceStatus()
								this.$refs.XcSetUp.yuanhuanValue()
								this.$refs.XcSetUp.yuanhuanTValue()
								this.$refs.XcSetUp.insideSwitch.certification = true
								// this.$refs.XcSetUp.$refs.myinput.focus();
							})
						} else {
							// 已经设置时长
							this.$refs.XcSetUp.kzxInfo = this.playIdObj
							this.$refs.XcSetUp.equipmentInfo = this.selectxcsb
							this.$refs.XcSetUp.cgqList = this.cgqList
							this.$refs.XcSetUp.drawer = true
							this.$nextTick(() => {
								this.$refs.XcSetUp.deviceStatus()
								this.$refs.XcSetUp.yuanhuanValue()
								this.$refs.XcSetUp.yuanhuanTValue()
							})
						}
					}
				})
		},
		// 打开行程设备设置抽屉并进入时长保护界面
		openXcDrawerToscbh() {
			this.$refs.XcSetUp.kzxInfo = this.playIdObj
			this.$refs.XcSetUp.equipmentInfo = this.selectxcsb
			this.$refs.XcSetUp.cgqList = this.cgqList
			this.$refs.XcSetUp.drawer = true
			this.$nextTick(() => {
				this.$refs.XcSetUp.deviceStatus()
				this.$refs.XcSetUp.yuanhuanValue()
			})
			setTimeout(() => {
				this.$refs.XcSetUp.insideSwitch.protect = true
			}, 0)
		},
		// 关联监控点击
		associatedMonitoring() {
			if (!this.playIdObj || JSON.stringify(this.playIdObj) == '{}') {
				this.$refs.myMsg.msgShow('暂无可关联设备', 1.5)
				return
			}
			this.$refs.AssociatedMonitoring.deviceId = this.playIdObj.id
			// this.$refs.AssociatedMonitoring.deviceId = 1203
			this.$refs.AssociatedMonitoring.getVideoList()
			this.$refs.AssociatedMonitoring.drawer = true
		},
		// 打开告警配置
		alarmConfiguration() {
			if (!this.cgqList.length) {
				this.$refs.myMsg.msgShow('暂无设备', 1.5)
				return
			}
			this.$refs.AlarmConfiguration.deviceNum = this.playIdObj.deviceNum
			let arr = [
				{
					id: 'all',
					label: '全部',
				},
			]
			this.cgqList.forEach((item) => {
				arr.push({
					id: item.subDeviceNum,
					label: item.subDeviceName,
				})
			})
			this.$refs.AlarmConfiguration.optionList = arr
			this.$refs.AlarmConfiguration.cgqList = this.cgqList
			this.$refs.AlarmConfiguration.csList = this.cgqList[0].itemList
			this.$refs.AlarmConfiguration.stAlarmConfList()
			this.$refs.AlarmConfiguration.drawer = true
		},

		/**
		 * 获取数据
		 * **/
		// 我的区域树状结构
		userDeviceList() {
			this.$http.getAction(this.$api.userDeviceList + '?type=1').then((res) => {
				console.log(res)
				// res = {
				// 	code: 200,
				// 	msg: 'ok',
				// 	data: [
				// 		{
				// 			areaName: '全区域',
				// 			totalNum: 1,
				// 			onlineNum: 1,
				// 			list: [
				// 				{
				// 					deviceNum: '22102603000',
				// 					deviceBizStatus: '',
				// 					productId: '27',
				// 					deviceId: '811',
				// 					deviceName: '智能卷被机',
				// 					productName: '智能卷被机',
				// 					deviceStatus: 'online',
				// 				},
				// 			],
				// 			offlineNum: 0,
				// 		},
				// 		{
				// 			areaName: '区域11',
				// 			totalNum: 1,
				// 			onlineNum: 0,
				// 			list: [
				// 				{
				// 					deviceNum: '22102603000',
				// 					deviceBizStatus: '',
				// 					productId: '27',
				// 					deviceId: '1151',
				// 					deviceName: '智能卷被机',
				// 					productName: '智能卷被机',
				// 					deviceStatus: 'offline',
				// 				},
				// 			],
				// 			offlineNum: 1,
				// 		},
				// 	],
				// }
				if (res.data) {
					let arr = []
					res.data.forEach((item, index) => {
						if (item.list.length) {
							let obj = {}
							obj.label = item.areaName
							obj.id = index + '-' + index
							obj.online = item.onlineNum
							obj.allNum = item.totalNum
							obj.children = []
							item.list.forEach((item2, index2) => {
								obj.children.push({
									deviceNum: item2.deviceNum,
									label: item2.deviceName,
									id: item2.deviceId,
									online: item2.deviceStatus == 'offline' ? false : true,
									source: 0, //0自己1共享
									areaName: item.areaName,
									deviceId: item.deviceId,
								})
							})
							arr.push(obj)
						}
					})
					if (arr.length) {
						this.treeData = arr
						this.warnHadDv = true
						if (this.routerQuery) {
							this.treeData.forEach((item, index) => {
								item.children.forEach((item2, index2) => {
									if (item2.deviceNum == this.routerQuery) {
										this.playIdObj = arr[index].children[index2]
										this.morenzankai = this.treeData[index].id
									}
								})
							})
						} else {
							this.playIdObj = arr[0].children[0]
							this.morenzankai = this.treeData[0].id
						}
						this.getVideoList()
						this.deviceSub()
						this.getcgqList()
					} else {
						this.treeData = []
						this.playIdObj = {}
						this.warnHadDv = false
					}
				} else {
					this.treeData = []
					this.warnHadDv = false
				}
			})
		},
		// 用户设备所关联摄像头
		getVideoList() {
			this.$http.getAction(this.$api.deviceCamera + '?deviceId=' + this.playIdObj.id).then((res) => {
				// res = {
				//   code: 200,
				//   msg: "ok",
				//   data: [
				//     {
				//       id: "51",
				//       userId: "42",
				//       cameraResourceId: "1179",
				//       cameraResourceStatus: "0",
				//       cameraNum: "1000001@001$1$0$0",
				//       cameraName: "马呈黄1号_1",
				//       cameraUrl:
				//         "http://221.13.155.38:7086/live/cameraid/1000001@001%240/substream/2.m3u8",
				//       cameraServerId: "2",
				//       channelNum: "0",
				//       name: "元丰科技",
				//       bindId: "42",
				//       bindName: "13460213111",
				//       platformType: 1,
				//       createTime: "2022-07-08T09:15:13",
				//     },
				//     {
				//       id: "52",
				//       userId: "42",
				//       cameraResourceId: "1180",
				//       cameraResourceStatus: "0",
				//       cameraNum: "1000002@001$1$0$0",
				//       cameraName: "马呈黄2号_1",
				//       cameraUrl:
				//         "http://221.13.155.38:7086/live/cameraid/1000002@001%240/substream/2.m3u8",
				//       cameraServerId: "2",
				//       channelNum: "0",
				//       name: "元丰科技",
				//       bindId: "42",
				//       bindName: "13460213111",
				//       platformType: 1,
				//       createTime: "2022-07-08T09:15:13",
				//     },
				//     {
				//       id: "53",
				//       userId: "42",
				//       cameraResourceId: "1183",
				//       cameraResourceStatus: "0",
				//       cameraNum: "1000005$1$0$0",
				//       cameraName: "埠西头",
				//       cameraUrl:
				//         "http://221.13.155.38:7086/live/cameraid/1000005%240/substream/2.m3u8",
				//       cameraServerId: "2",
				//       channelNum: "0",
				//       name: "元丰科技",
				//       bindId: "42",
				//       bindName: "13460213111",
				//       platformType: 1,
				//       createTime: "2022-07-08T09:15:13",
				//     },
				//     {
				//       id: "54",
				//       userId: "42",
				//       cameraResourceId: "1185",
				//       cameraResourceStatus: "0",
				//       cameraNum: "1000006@001$1$0$0",
				//       cameraName: "马呈黄6号_1",
				//       cameraUrl:
				//         "http://221.13.155.38:7086/live/cameraid/1000006@001%240/substream/2.m3u8",
				//       cameraServerId: "2",
				//       channelNum: "0",
				//       name: "元丰科技",
				//       bindId: "42",
				//       bindName: "13460213111",
				//       platformType: 1,
				//       createTime: "2022-07-08T09:15:13",
				//     },
				//     {
				//       id: "55",
				//       userId: "42",
				//       cameraResourceId: "1184",
				//       cameraResourceStatus: "0",
				//       cameraNum: "1000006$1$0$0",
				//       cameraName: "马家都",
				//       cameraUrl:
				//         "http://221.13.155.38:7086/live/cameraid/1000006%240/substream/2.m3u8",
				//       cameraServerId: "2",
				//       channelNum: "0",
				//       name: "元丰科技",
				//       bindId: "42",
				//       bindName: "13460213111",
				//       platformType: 1,
				//       createTime: "2022-07-08T09:15:13",
				//     },
				//     {
				//       id: "56",
				//       userId: "42",
				//       cameraResourceId: "1186",
				//       cameraResourceStatus: "0",
				//       cameraNum: "1000008@001$1$0$0",
				//       cameraName: "马呈黄8号_1",
				//       cameraUrl:
				//         "http://221.13.155.38:7086/live/cameraid/1000008@001%240/substream/2.m3u8",
				//       cameraServerId: "2",
				//       channelNum: "0",
				//       name: "元丰科技",
				//       bindId: "42",
				//       bindName: "13460213111",
				//       platformType: 1,
				//       createTime: "2022-07-08T09:15:13",
				//     },
				//   ],
				// };
				if (res.data && res.data.length) {
					this.isShowVideo = true
					this.jkList = res.data
					this.jkAct = this.jkList[0].cameraUrl
					if (this.jkAct != '') {
						this.isVideoEmpty = false
						this.$nextTick(() => {
							this.$refs.hlsVideoPlayer.handelVideoUrl(this.jkAct)
						})
					} else {
						this.isVideoEmpty = true
					}
				} else {
					this.isShowVideo = false
					this.jkList = []
					this.jkAct = ''
				}
			})
		},
		// 获取控制设备信息
		deviceSub() {
			this.$http.postAction(this.$api.deviceSub + '?deviceId=' + this.playIdObj.id + '&subDeviceType=2').then((res) => {
				// res = {
				// 	data: {
				// 		deviceId: '756',
				// 		productId: '27',
				// 		deviceName: '智能卷被机',
				// 		deviceNum: '22102603000',
				// 		deviceCompId: '22102603000',
				// 		subsetList: [
				// 			{
				// 				subDeviceType: '2-1',
				// 				subDeviceNum: 'c_1',
				// 				itemList: [
				// 					{
				// 						dataItemValue: '1',
				// 					},
				// 				],
				// 				subDeviceName: '智能卷被机',
				// 			},
				// 		],
				// 	},
				// }
				this.sbAllList = res.data || {}
				if (res.data && res.data.subsetList.length) {
					console.log(res.data.subsetList)
					this.kgsbList = res.data.subsetList.filter((item) => {
						if (item.subDeviceType == '2-1') {
							return item
						}
					})
					this.xcsbLsist = res.data.subsetList.filter((item) => {
						if (item.subDeviceType == '2-2') {
							return item
						}
					})
				} else {
					this.kgsbList = []
					this.xcsbLsist = []
				}
			})
		},
		// 修改名称请求回显
		xiugaiNameHuiD() {
			this.$http.postAction(this.$api.deviceSub + '?deviceId=' + this.playIdObj.id + '&subDeviceType=2').then((res) => {
				this.sbAllList = res.data || {}
				if (res.data && res.data.subsetList.length) {
					console.log(res.data.subsetList)
					this.kgsbList = res.data.subsetList.filter((item) => {
						if (item.subDeviceType == '2-1') {
							return item
						}
					})
					this.xcsbLsist = res.data.subsetList.filter((item) => {
						if (item.subDeviceType == '2-2') {
							return item
						}
					})
					if (this.$refs.kgSetUp.drawer) {
						this.kgsbList.forEach((item) => {
							if (item.subDeviceNum == this.$refs.kgSetUp.infos.subDeviceNums[0]) {
								let subDeviceNums = []
								subDeviceNums.push(item.subDeviceNum)
								let params = {
									subDeviceName: item.subDeviceName,
									subDeviceNums: subDeviceNums,
									deviceNum: this.sbAllList.deviceNum,
									commandsItemList: item.commandsItemList,
									deviceName: this.sbAllList.deviceName,
									dataItemValue: item.itemList[0].dataItemValue,
								}
								this.$refs.kgSetUp.equipmentList = this.cgqList
								this.$refs.kgSetUp.automationbjFrom.sensorNum = this.cgqList[0].subDeviceNum
								this.$refs.kgSetUp.equipmentItems = this.cgqList[0].itemList
								this.$refs.kgSetUp.infos = params
							}
						})
					}
					if (this.$refs.XcSetUp.drawer) {
						this.xcsbLsist.forEach((item) => {
							if (item.subDeviceNum == this.$refs.XcSetUp.equipmentInfo.subDeviceNum) {
								this.$refs.XcSetUp.kzxInfo = this.playIdObj
								this.$refs.XcSetUp.equipmentInfo = item
								this.$refs.XcSetUp.cgqList = this.cgqList
								this.$nextTick(() => {
									this.$refs.XcSetUp.deviceStatus()
									this.$refs.XcSetUp.yuanhuanValue()
								})
							}
						})
					}
				} else {
					this.kgsbList = []
					this.xcsbLsist = []
				}
			})
		},
		// 获取传感器设备
		getcgqList() {
			this.$http.postAction(this.$api.deviceSub + '?deviceId=' + this.playIdObj.id + '&subDeviceType=1').then((res) => {
				if (res.data && res.data.subsetList.length) {
					this.warnHadDv = true
					this.cgqList = res.data.subsetList
					this.cgqAct = this.cgqList[0].subDeviceNum
					this.itemsSelect = this.cgqList[0].itemList
					this.queryParam.listparam = this.itemsSelect[0].dataItemCode
					this.deviceSensorData()
					this.deviceSensorLinedata()
				} else {
					this.cgqList = []
					this.cgqAct = ''
					this.itemsSelect = []
					this.warnHadDv = false
				}
			})
		},
		// 刷新实时数据
		refreshData() {
			if (this.iconRotateTimer) {
				return
			}
			this.iconRotateTimer = null
			this.iconRotate = true
			this.deviceSensorData()
			this.iconRotateTimer = setTimeout(() => {
				this.iconRotate = false
				clearTimeout(this.iconRotateTimer)
				this.iconRotateTimer = null
			}, 2000)
		},
		// 传感器实事数据
		deviceSensorData() {
			this.$http
				.postAction(this.$api.deviceSensorData + '?deviceId=' + this.playIdObj.id + '&subDeviceId=' + this.cgqAct)
				.then((res) => {
					// res = {
					// 	code: 200,
					// 	msg: 'ok',
					// 	data: {
					// 		arrivalTime: '2022-10-24 09:29:40',
					// 		propList: [
					// 			{
					// 				dataItemName: 'CO2',
					// 				dataItemCode: 'EYHT',
					// 				dataItemValue: '42.61',
					// 				dataItemUnit: 'ppm',
					// 			},
					// 			{
					// 				dataItemName: '光照度',
					// 				dataItemCode: 'GZQD',
					// 				dataItemValue: '42.61',
					// 				dataItemUnit: '%',
					// 			},
					// 			{
					// 				dataItemName: '土壤温度',
					// 				dataItemCode: 'TRWD',
					// 				dataItemValue: '42.61',
					// 				dataItemUnit: '°C',
					// 			},
					// 			{
					// 				dataItemName: '土壤湿度',
					// 				dataItemCode: 'TRSD',
					// 				dataItemValue: '42.61',
					// 				dataItemUnit: '%',
					// 			},
					// 			{
					// 				dataItemName: '温度',
					// 				dataItemCode: 'WD',
					// 				dataItemValue: '42.61',
					// 				dataItemUnit: '°C',
					// 			},
					// 			{
					// 				dataItemName: '湿度',
					// 				dataItemCode: 'SD',
					// 				dataItemValue: '83.88',
					// 				dataItemUnit: '%',
					// 			},
					// 		],
					// 	},
					// }
					if (res.data.arrivalTime) {
						this.codeIsWarn = false
						this.codeInfos.dateTime = res.data.arrivalTime
					} else {
						this.codeIsWarn = true
						this.codeInfos.dateTime = ''
					}
					console.log(this.codeInfos.dateTime, 'codeInfos.dateTime')
					if (res.data.propList.length) {
						this.codeIsWarn = false
						let envItems = []
						res.data.propList.forEach((item) => {
							envItems.push({
								itemCode: item.dataItemCode,
								itemName: item.dataItemName,
								itemUnitCode: item.dataItemUnit,
								itemValue: item.dataItemValue,
							})
						})
						this.codeInfoLisst = envItems
						// 总页码赋值
						this.codeTotalPage = Math.ceil(envItems.length / 8)
						this.codePage = 1
						// 大于8开始分页
						this.makeInfoItems(envItems)
					} else {
						this.codeIsWarn = true
						// 设备异常赋值下拉选数据
						let envItems = []
						this.itemsSelect.forEach((item) => {
							envItems.push({
								itemCode: item.dataItemCode,
								itemName: item.dataItemName,
								itemUnitCode: item.unitName,
								itemValue: '--',
							})
						})
						this.codeInfoLisst = envItems
						// 总页码赋值
						this.codeTotalPage = Math.ceil(envItems.length / 8)
						this.codePage = 1
						// 大于8开始分页
						this.makeInfoItems(envItems)
					}
				})
		},
		makeInfoItems(envItems) {
			// 大于8开始分页
			if (envItems.length >= 8) {
				this.codeLeftList = envItems.filter((item, index) => {
					return index < 4
				})
				this.codeRightList = envItems.filter((item, index) => {
					return index >= 4 && index < 8
				})
			} else {
				if (envItems.length == 1) {
					this.codeLeftList = []
					this.codeRightList = envItems
				} else {
					let splitNum = Math.floor((envItems.length % 2) + envItems.length / 2)
					this.codeLeftList = envItems.filter((item, index) => {
						return index < splitNum
					})
					this.codeRightList = envItems.filter((item, index) => {
						return index >= splitNum && index < envItems.length
					})
				}
			}
		},
		// 折线图数据
		deviceSensorLinedata() {
			let params = {
				deviceId: this.playIdObj.id,
				startTime: this.queryParam.startTime,
				endTime: this.queryParam.endTime,
				subDeviceId: this.cgqAct,
				dataItems: [this.queryParam.listparam],
			}
			this.$http.postAction(this.$api.deviceSensorLinedata, params).then((res) => {
				// res = {
				//   code: 200,
				//   msg: "ok",
				//   data: {
				//     xList: [
				//       "2022-08-15T18:05:00.655",
				//       "2022-08-15T17:55:00.526",
				//       "2022-08-15T17:50:00.178",
				//       "2022-08-15T17:45:00.308",
				//       "2022-08-15T17:40:00.216",
				//       "2022-08-15T17:35:00.319",
				//       "2022-08-15T17:30:00.352",
				//       "2022-08-15T17:25:00.237",
				//       "2022-08-15T17:20:00.353",
				//       "2022-08-15T17:15:00.199",
				//       "2022-08-15T17:10:00.322",
				//       "2022-08-15T17:05:00.227",
				//       "2022-08-15T17:00:00.435",
				//       "2022-08-15T16:55:00.196",
				//       "2022-08-15T16:50:00.334",
				//       "2022-08-15T16:45:00.246",
				//       "2022-08-15T16:40:00.406",
				//       "2022-08-15T16:35:00.171",
				//       "2022-08-15T16:30:00.323",
				//       "2022-08-15T16:25:00.209",
				//       "2022-08-15T16:20:00.321",
				//       "2022-08-15T16:15:00.179",
				//       "2022-08-15T16:10:00.232",
				//       "2022-08-15T16:05:01.24",
				//       "2022-08-15T16:00:00.237",
				//       "2022-08-15T15:55:00.198",
				//       "2022-08-15T15:50:00.198",
				//       "2022-08-15T15:45:00.445",
				//       "2022-08-15T15:40:00.212",
				//       "2022-08-15T15:35:00.312",
				//       "2022-08-15T15:25:00.35",
				//       "2022-08-15T15:20:00.151",
				//       "2022-08-15T15:15:00.324",
				//       "2022-08-15T15:05:00.307",
				//       "2022-08-15T15:00:00.253",
				//       "2022-08-15T14:55:00.186",
				//       "2022-08-15T14:50:00.294",
				//       "2022-08-15T14:45:00.199",
				//       "2022-08-15T14:40:00.278",
				//       "2022-08-15T14:35:00.194",
				//       "2022-08-15T14:30:00.333",
				//       "2022-08-12T18:10:00.4",
				//       "2022-08-12T17:25:00.2",
				//       "2022-08-12T17:20:00.263",
				//       "2022-08-12T17:15:00.212",
				//       "2022-08-12T17:10:00.322",
				//       "2022-08-12T17:05:00.242",
				//       "2022-08-12T17:00:00.306",
				//       "2022-08-12T16:55:00.212",
				//       "2022-08-12T16:50:00.916",
				//       "2022-08-12T16:45:00.718",
				//       "2022-08-12T10:35:00.159",
				//       "2022-08-12T10:30:00.145",
				//       "2022-08-12T10:20:00.135",
				//       "2022-08-12T10:15:00.169",
				//       "2022-08-12T10:10:00.145",
				//       "2022-08-12T10:05:00.143",
				//       "2022-08-12T09:55:00.131",
				//       "2022-08-12T09:45:00.301",
				//       "2022-08-12T09:40:00.154",
				//       "2022-08-12T09:35:00.131",
				//       "2022-08-12T09:30:00.144",
				//       "2022-08-12T09:25:00.115",
				//       "2022-08-12T09:20:00.149",
				//       "2022-08-12T09:15:00.137",
				//       "2022-08-12T09:10:00.15",
				//       "2022-08-12T09:05:00.155",
				//       "2022-08-12T08:55:00.151",
				//       "2022-08-12T08:50:00.124",
				//       "2022-08-12T08:45:00.149",
				//       "2022-08-12T08:40:00.147",
				//       "2022-08-12T08:35:00.145",
				//       "2022-08-12T08:30:00.135",
				//       "2022-08-12T08:20:00.156",
				//       "2022-08-12T08:15:00.152",
				//       "2022-08-12T08:10:00.156",
				//       "2022-08-12T08:05:00.134",
				//       "2022-08-12T08:00:00.217",
				//       "2022-08-12T07:55:00.136",
				//       "2022-08-12T07:50:00.211",
				//       "2022-08-12T07:45:00.144",
				//       "2022-08-12T07:40:00.124",
				//       "2022-08-12T07:35:00.132",
				//       "2022-08-12T07:30:00.143",
				//       "2022-08-12T07:25:00.157",
				//       "2022-08-12T07:20:00.134",
				//       "2022-08-12T07:15:00.151",
				//       "2022-08-12T07:10:00.189",
				//       "2022-08-12T07:05:00.141",
				//       "2022-08-12T07:00:00.155",
				//       "2022-08-12T06:55:00.16",
				//       "2022-08-12T06:50:00.138",
				//       "2022-08-12T06:45:00.147",
				//       "2022-08-12T06:40:00.144",
				//       "2022-08-12T06:35:00.157",
				//       "2022-08-12T06:30:00.137",
				//       "2022-08-11T19:45:00.184",
				//       "2022-08-11T18:45:00.155",
				//       "2022-08-11T18:40:00.143",
				//       "2022-08-11T18:35:00.21",
				//       "2022-08-11T18:30:00.194",
				//       "2022-08-11T18:25:00.44",
				//       "2022-08-11T18:02:39.232",
				//       "2022-08-11T17:55:10.948",
				//       "2022-08-11T17:50:07.486",
				//       "2022-08-11T17:45:07.635",
				//       "2022-08-11T17:15:12.8",
				//       "2022-08-11T17:10:06.545",
				//       "2022-08-11T17:05:13.887",
				//       "2022-08-11T17:00:23.759",
				//       "2022-08-11T16:55:05.871",
				//       "2022-08-11T16:50:07.038",
				//       "2022-08-11T16:45:13.68",
				//       "2022-08-11T16:40:01.144",
				//       "2022-08-11T16:35:00.158",
				//       "2022-08-11T16:30:00.15",
				//       "2022-08-11T16:25:00.185",
				//       "2022-08-11T16:20:00.169",
				//       "2022-08-11T16:15:00.876",
				//       "2022-08-11T16:10:00.145",
				//       "2022-08-11T16:05:00.157",
				//       "2022-08-11T16:00:00.193",
				//       "2022-08-11T15:55:00.148",
				//       "2022-08-11T15:50:00.164",
				//       "2022-08-11T15:40:00.158",
				//       "2022-08-11T15:30:00.791",
				//       "2022-08-11T15:25:00.662",
				//     ],
				//     yList: [
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.30",
				//       },
				//       {
				//         TRWD: "34.20",
				//       },
				//       {
				//         TRWD: "34.20",
				//       },
				//       {
				//         TRWD: "34.20",
				//       },
				//       {
				//         TRWD: "34.20",
				//       },
				//       {
				//         TRWD: "34.20",
				//       },
				//       {
				//         TRWD: "34.20",
				//       },
				//       {
				//         TRWD: "34.20",
				//       },
				//       {
				//         TRWD: "34.20",
				//       },
				//       {
				//         TRWD: "34.20",
				//       },
				//       {
				//         TRWD: "34.10",
				//       },
				//       {
				//         TRWD: "34.10",
				//       },
				//       {
				//         TRWD: "34.10",
				//       },
				//       {
				//         TRWD: "34.10",
				//       },
				//       {
				//         TRWD: "34.10",
				//       },
				//       {
				//         TRWD: "34.10",
				//       },
				//       {
				//         TRWD: "34.00",
				//       },
				//       {
				//         TRWD: "34.00",
				//       },
				//       {
				//         TRWD: "34.00",
				//       },
				//       {
				//         TRWD: "34.00",
				//       },
				//       {
				//         TRWD: "34.00",
				//       },
				//       {
				//         TRWD: "34.00",
				//       },
				//       {
				//         TRWD: "34.00",
				//       },
				//       {
				//         TRWD: "34.00",
				//       },
				//       {
				//         TRWD: "33.50",
				//       },
				//       {
				//         TRWD: "33.40",
				//       },
				//       {
				//         TRWD: "33.40",
				//       },
				//       {
				//         TRWD: "33.40",
				//       },
				//       {
				//         TRWD: "33.40",
				//       },
				//       {
				//         TRWD: "33.40",
				//       },
				//       {
				//         TRWD: "33.40",
				//       },
				//       {
				//         TRWD: "33.40",
				//       },
				//       {
				//         TRWD: "33.40",
				//       },
				//       {
				//         TRWD: "33.30",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "",
				//       },
				//       {
				//         TRWD: "33.10",
				//       },
				//       {
				//         TRWD: "33.10",
				//       },
				//       {
				//         TRWD: "33.00",
				//       },
				//       {
				//         TRWD: "33.00",
				//       },
				//       {
				//         TRWD: "32.90",
				//       },
				//       {
				//         TRWD: "32.90",
				//       },
				//       {
				//         TRWD: "32.90",
				//       },
				//       {
				//         TRWD: "32.90",
				//       },
				//       {
				//         TRWD: "32.90",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.80",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.70",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.60",
				//       },
				//       {
				//         TRWD: "32.50",
				//       },
				//       {
				//         TRWD: "32.50",
				//       },
				//       {
				//         TRWD: "32.50",
				//       },
				//       {
				//         TRWD: "32.50",
				//       },
				//     ],
				//   },
				// };
				if (res.data.xList.length) {
					this.isLine = true
					let xData = res.data.xList
					let yData = []
					let yName = ''
					let yUnit = ''
					let code = ''
					res.data.yList.forEach((item, index) => {
						if (index == 0) {
							code = Object.keys(item)
						}
						yData.push(item[code])
					})
					let arr = this.itemsSelect.filter((item) => {
						if (item.dataItemCode == code) {
							return item
						}
					})
					yName = arr[0].dataItemName
					yUnit = arr[0].unitName
					this.$nextTick(() => {
						this.lineChart(xData, yData, yName, yUnit)
					})
				} else {
					this.isLine = false
				}
			})
		},
		// 是否设置了安全密码
		querySafePassword() {
			this.$http.getAction(this.$api.querySafePassword).then((res) => {
				this.safePassword = res.data
			})
		},
	},
}
</script>
<style lang="scss">
.box-content {
	// .el-radio {
	// 	width: 132px;
	// 	margin-right: 0 !important;
	// }
}
</style>
<style lang="scss">
// 树形组件样式
@import '@/assets/css/trees.scss';
</style>
<style lang="scss" scoped>
.product-box {
	height: 100%;
	min-height: 610px;
	.hea-box {
		height: 0.8rem;
		box-sizing: border-box;
		border-bottom: 1px solid #f1f1f1;
		font-size: 0.225rem;
		font-family: Microsoft YaHei;
		padding: 0.3rem;
		font-weight: bold;
		color: #454545;
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.btn-box {
			display: flex;
			.item {
				margin-left: 0.2rem;
			}
			img {
				cursor: pointer;
			}
		}
	}
	.left-box {
		width: 22.3%;
		position: relative;
		margin-right: 0.25rem;
		height: 100%;
		background: #ffffff;
		border-radius: 0.125rem;
		.trees-box {
			height: 89%;
			overflow-y: scroll;
			box-sizing: border-box;
			padding: 0.3rem;
		}
	}
	.center-box {
		width: 29.3%;
		margin-right: 0.25rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		& > div {
			height: 48.7%;
			background: #ffffff;
			border-radius: 0.125rem;
		}
		.box-top {
			.header-box {
				height: 0.75rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				box-sizing: border-box;
				padding: 0 0.3rem;
				.txt {
					font-size: 0.225rem;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #454545;
					img {
						cursor: pointer;
					}
				}
				.sel-box {
					width: 2.75rem;
					height: 0.45rem;
				}
			}
			.video-box {
				height: 80%;
				box-sizing: border-box;
				padding: 0 0.3rem;
				.video-conten {
					width: 100%;
					height: 100%;
					background-color: #343434;
				}
				.play-error {
					width: 100%;
					height: 100%;
					.img_noplay {
						width: 0.76rem;
						height: 0.64rem;
						margin-bottom: 0.46rem;
					}
				}
			}
		}
		.box-bottom {
			position: relative;
			overflow: hidden;
			.header-box {
				height: 0.75rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				box-sizing: border-box;
				padding: 0 0.3rem;
				box-sizing: border-box;
				border-bottom: 1px solid #f1f1f1;
				.txt {
					font-size: 0.225rem;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #454545;
					img {
						cursor: pointer;
					}
				}
				// 列表切换tab
				.btn-switchs {
					text-align: center;
					width: 2.5rem;

					background: #ffffff;
					border: 1px solid #2daea9;
					border-radius: 0.15rem;
					position: relative;
					// margin: 0 0 0.25rem 0.5rem;
					.btn-switch-btn {
						width: 50%;
						display: inline-block;
						height: 0.45rem;
						line-height: 0.45rem;
						font-size: 0.17rem;
						color: #2daea9;
						position: relative;
						z-index: 5;
						cursor: pointer;
						transition: color 0.5s;
					}
					.btn-switch-active {
						color: #ffffff;
					}
					// 背景颜色
					.bgs {
						width: 50%;
						height: 100%;
						background: #2daea9;
						border-radius: 0.13rem;
						position: absolute;
						left: 0;
						top: 0;
						z-index: 4;
						transition: left 0.5s;
					}
				}
			}
			.content-box {
				height: 85%;
				box-sizing: border-box;
				padding: 0.25rem 0.125rem 0.25rem 0.3rem;
				.content {
					width: 100%;
					height: 100%;
					overflow: auto;
					display: flex;
					flex-wrap: wrap;
					&::-webkit-scrollbar {
						width: 6px;
						height: 0px;
						background-color: transparent;
					}
					&::-webkit-scrollbar-track {
						border-radius: 6px;
						background-color: transparent;
					}
					&::-webkit-scrollbar-thumb {
						border-radius: 6px;
						background-color: rgba(45, 174, 169, 0.2);
					}
					// justify-content: space-between;
					.item {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: center;
						width: 1.8125rem;
						height: 1.675rem;
						background: rgba(231, 241, 243, 0.6);
						border-radius: 0.125rem;
						box-sizing: border-box;
						padding-top: 0.1875rem;
						padding-bottom: 0.1875rem;
						padding-left: 0.2rem;
						padding-right: 0.2125rem;
						margin-bottom: 0.25rem;
						margin-right: 0.3rem;
						.top-txt {
							width: 115%;
							display: flex;
							justify-content: space-between;
							align-items: center;
							color: rgba(45, 174, 169, 1);
							.icon {
								cursor: pointer;
								width: 0.2rem;
								height: 0.2rem;
								background: url('../../assets/image/product/shezhi.png') no-repeat;
							}
							.red {
								color: rgba(255, 72, 72, 1);
							}
							.orange {
								color: rgba(245, 110, 30, 1);
							}
						}
					}
					.item-animated {
						img {
							animation: animated-border 2.2s infinite;
						}
					}
					@keyframes animated-border {
						0% {
							// box-shadow: 0 0 0 0 rgba(231, 241, 243, 1);
							// color: rgba(45, 174, 169, 1);
							opacity: 1;
						}

						50% {
							// box-shadow: 0 0 0 20px rgba(231, 241, 243, 0);
							// color: rgba(45, 174, 169, .3);
							opacity: 0.1;
						}
						100% {
							opacity: 1;
						}
					}
					.marginRight0 {
						margin-right: 0;
					}
					.maginBottom0 {
						margin-bottom: 0;
					}
				}
				.content-no {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					color: #c8c8c8;
					.table-emptyword {
						margin-top: 0.125rem;
					}
				}
			}
			.scram-box {
				position: absolute;
				bottom: 0.1625rem;
				left: 0;
				// cursor: pointer;
				width: 1.25rem;
				height: 0.875rem;
				background: #ffffff;
				box-shadow: 2px 0px 10px 0px rgba(5, 83, 80, 0.2);
				border-radius: 0px 0.125rem 0.125rem 0px;
				display: flex;
				align-items: center;
				box-sizing: border-box;
				padding-left: 0.1875rem;
				transition: all 0.5s;
				transform: translateX(-0.925rem);
				.icon-box {
					cursor: pointer;
					opacity: 0;
					transition: all 0.5s;
					.txt {
						margin-top: 0.1rem;
						font-size: 0.175rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: rgba(245, 110, 30, 1);
					}
				}
				.btn-icon {
					cursor: pointer;
					margin-left: 0.0875rem;
					width: 0.1375rem;
					height: 0.125rem;
					background: url('../../assets/image/product/shouqi.png') no-repeat;
					transition: all 0.5s;
					transform: rotate(180deg);
				}
			}
			.scram-box2 {
				background: #f56e1e;
				.icon-box {
					.txt {
						margin-top: 0.1rem;
						font-size: 0.175rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: rgba(255, 255, 255, 1);
					}
				}
				.btn-icon {
					background: url('../../assets/image/product/shouqi2.png') no-repeat;
				}
			}
			.scram-box-open {
				transform: translateX(0);
				.btn-icon {
					transform: rotate(0deg);
				}
				.icon-box {
					opacity: 1;
				}
			}
		}
	}
	.right-box {
		width: 45.5%;
		height: 100%;
		background: #ffffff;
		border-radius: 0.125rem;
		display: flex;
		flex-direction: column;

		.header-box {
			height: 0.75rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;
			border-bottom: 1px solid #f1f1f1;
			padding: 0 0.3rem;
			.txt {
				font-size: 0.225rem;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #454545;
			}
			.sel-box {
				width: 2.75rem;
				height: 0.45rem;
			}
			.header-r-box {
				display: flex;
				align-items: center;
			}
			.icon {
				margin-left: 0.5rem;
				cursor: pointer;
				width: 0.45rem;
				height: 0.45rem;
				background: url('../../assets/image/product/shezhi2.png') no-repeat;
				// filter: grayscale(1);
			}
			.icon-no {
				background: url('../../assets/image/product/shezhi3.png') no-repeat;
				pointer-events: none;
				cursor: default;
			}
		}
		.right-top {
			position: relative;
			// padding-top: 0.875rem;
			margin-top: 0.325rem;
			.relative-div {
				position: relative;
				width: 6.56rem;
				left: 50%;
				transform: translateX(-50%);
				// z-index: 10;
				.top-tipdiv {
					position: relative;
					z-index: 10;
					display: flex;
					justify-content: center;
					align-items: center;
					.shuaxin_div {
						cursor: pointer;
						width: 0.9rem;
						height: 0.3125rem;
						border: 1px solid #32ad9b;
						border-radius: 0.05rem;
						margin-left: 0.1875rem;
						box-sizing: border-box;
						display: flex;
						justify-content: center;
						align-items: center;
						i {
							color: #2daea9;
							font-size: 18px;
						}
						.i_rotate {
							animation: rotate 2s infinite;
						}
						span {
							color: #2daea9;
							margin-left: 0.1rem;
							font-size: 0.175rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #32ad9b;
						}
						@keyframes rotate {
							0% {
								transform: rotate(0);
							}
							100% {
								transform: rotate(360deg);
							}
						}
					}
					.shuaxin {
						cursor: pointer;

						width: 0.9125rem;
						height: 0.3125rem;
					}
				}
			}
			.relative-div-noleft {
				left: 0.6rem;
				transform: translateX(0);
			}
			.img_bg {
				// width: 6.56rem;
				// height: 2.75rem;
				width: 5.7375rem;
				height: 2.4rem;
				margin-top: 0.725rem;
			}

			.icon_warn {
				width: 0.25rem;
				margin-right: 0.06rem;
				margin-top: -0.04rem;
			}
			.rt-absolute {
				position: absolute;
				width: 100%;
				height: 92%;
				top: 0.5rem;
				left: 0;
				padding: 0 0.25rem 0 0.38rem;
				z-index: 8;
			}
			.right-noleft {
				padding-right: 1.25rem;
			}
			.code-pages {
				position: absolute;
				z-index: 10;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				font-size: 0.17rem;
				.code-icon {
					color: #afafaf;
				}
				.code-icon-hui {
					color: #d8d8d8;
				}
			}
		}
	}
	.csh-box {
		width: 76%;
		height: 100%;
		background: #ffffff;
		border-radius: 0.125rem;
		display: flex;
		align-items: center;
		justify-content: center;
		.csh-conten {
			.pic {
				// width: 2.125rem;
				// height: 3.3rem;
				margin-bottom: 0.5rem;
			}
			.txt {
				span {
					cursor: pointer;
					color: rgba(45, 174, 169, 1);
				}
			}
			.btn-box {
				display: flex;
				margin-top: 0.5rem;
				.item {
					cursor: pointer;
					width: 140px;
					height: 36px;
					box-sizing: border-box;
					background: #ffffff;
					border: 1px solid #2daea9;
					border-radius: 4px;
					margin: 0 0.15rem;
					text-align: center;
					line-height: 0.45rem;
					color: rgba(45, 174, 169, 1);
				}
				.item-again {
					background: #2daea9;
					color: white;
				}
			}
		}
	}
}
</style>
<style lang="scss">
.product-box {
	.el-input__inner {
		height: 0.45rem;
		line-height: 0.45rem;
	}
	.el-input__icon {
		line-height: 0.45rem;
	}
	.el-icon-arrow-up:before {
		content: '\e78f';
	}
	.right-chart {
		margin-top: 0.85rem;
		flex: 1;
		.rb-form {
			padding: 0 0.31rem;
			.form-btns {
				margin-right: 0;
			}
		}
		.chart-lines {
			// width: 11.09rem;
			// height: 4.41rem;
			height: 85%;
			margin: 0.15rem auto 0;
		}
		.form-inputs .el-form-item:not(:last-child) {
			margin-right: 0;
		}
		.form-picker {
			margin-left: 0.125rem;
		}
		.el-form-item {
			margin-right: 0;
			&:first-child {
				// margin-right: 0.44rem;
				.el-select {
					width: 1.79rem;
				}
			}
		}
		.form-picker {
			.picker-div {
				width: 3rem;
			}
		}
	}
}
</style>
