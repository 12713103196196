/**
 * @description table表格相关功能汇总
 * 
 * @default 非必有字段
 * @param   必有字段

			类型	 |  字段值		   | 默认值 | 说明
	* @default  isMakeTime    |  false  | 是否默认初始化时间，用于表单时间的默认赋值。 | 默认为 false 不初始化，格式yyyy-MM-dd,取值为当天。若需要时间初始化，需在单独页面设置为true
	* @default  ifUseLoadBack |  true  | 是否使用loadData公用的返回数据赋值(直接赋值)。默认为 true直接赋值。
	* @default  disableMixinCreated | true | 是否限制table的默认请求，false即页面进来就进行页面table数据的请求。若页面表单没有必填项，则此字段可在单独页面设置为false
	* @default  isFillTime    |  false | 是否在传参时在时间后面拼接时分秒数据，主要用于页面上不显示时分秒，当传值需要的情况.默认为 false,不拼接
	* @default  isTranPage    |  false | 是否传参页码数据.默认不传参
		* @default  pageValue     |  pageNum | 传参页码的自定义key值，主要用作区分不同的页码key设置
	* @default  pageLimit     |  pageSize | 传参页码数量的自定义key值，主要用作区分不同的页码key设置

	* @default  isMustTime    |  false   | 时间是否是必传值，用于时间不选择但有这个字段，value传空的情况.默认没有的时候不传这个字段
	* @param    url | [Object: list|table表格请求接口] | 页面相关接口
 */
import { getAction, postAction } from '@/api/request'
export default {
	data() {
		const self = this
		return {
			/* 查询条件 */
			queryParam: {},
			// 表格数据源
			tableData: [],
			/* table加载状态 */
			loading: true,
			isMakeTime: false, // 是否需要初始化时间
			ifUseLoadBack: true, // 是否使用loadData公用的返回数据赋值
			disableMixinCreated: true, // 限制table的默认请求
			pageValue: 'pageNum', // 页码传参的key值，主要用于区分不同的传参要求
			pageLimit: 'pageSize', // 页码传参的key值，主要用于区分不同的传参要求

			/* 分页参数 */
			ipagination: {
				pageSize: 10,
				total: 0,
				currentPage: 1,
			},
			// 时间限制
			pickerOptions0: {
				disabledDate: (time) => {
					// if (this.queryParam.endTime) {
					// 	if (this.queryParam.endTime != '') {
					// 		let num = new Date(this.queryParam.endTime).getTime()
					// 		return time.getTime() > num
					// 	} else {
					// 	}
					// } else {
					// 	return false
					// }
					let num = new Date().getTime()
					if (time.getTime() > num) {
						return true
					} else {
						let startNum = new Date(this.queryParam.startTime).getTime() - 43200000
						if (time.getTime() < startNum) {
							return true
						} else {
							return false
						}
					}
				},
			},
			pickerOptions1: {
				disabledDate: (time) => {
					let num = new Date().getTime()
					if (time.getTime() > num) {
						return true
					} else {
						let startNum = new Date(this.queryParam.startTime).getTime() - 43200000
						if (time.getTime() < startNum) {
							return true
						} else {
							return false
						}
					}
				},
			},
		}
	},
	computed: {
		isShowAllSelect() {
			let userOrg = this.getItem('wlwOrg') || {}
			let userOrgs = JSON.parse(userOrg)
			if (userOrgs.orgLevel == 2) {
				return true
			}
			return false
		},
	},
	mounted() {
		if (this.isMakeTime) {
			// 默认时间赋值
			this.$set(this.queryParam, 'startTime', this.getNowDate())
			this.$set(this.queryParam, 'endTime', this.getNowDate())
		}
		// 是否默认限制mixin的加载
		if (!this.disableMixinCreated) {
			this.loadData()
		}
	},
	methods: {
		// 查询获取列表数据
		loadData(arg) {
			if (!this.url.list || this.url.list == '') {
				this.$message('请设置url.list属性!', 'error')
				return
			}
			if (arg == 1) {
				this.ipagination.currentPage = 1
			}
			this.loading = true
			let params = this.getQueryParams()
			postAction(this.url.list, params)
				.then((res) => {
					// 判断走公共赋值还是单独数据处理
					if (this.ifUseLoadBack) {
						if (res.meta.success) {
							let datas = res.data || {}
							if (datas) {
								// 分页数据的赋值
								if (datas.totalElements) {
									this.ipagination.total = Number(datas.totalElements)
									this.ipagination.pageSize = datas.size
								} else {
									this.ipagination.total = 0
									this.ipagination.pageSize = 10
								}
								// 判断走公共赋值还是单独数据处理
								this.tableData = datas || []
							}
						}
					} else {
						let datas = res
						// 回到页面各自独立的数据处理
						this.loadBack(datas)
					}
				})
				.finally((err) => {
					setTimeout(() => {
						this.loading = false
					}, 500)
				})
				.catch(() => {
					this.loading = false
					this.isLine = false
				})
		},
		loadBack(res) {
			if (res.meta.success) {
				let result = res.data || {}
				if (result.total > 0) {
					let datas = result.records || []
					this.tableData = datas || []
					this.ipagination.total = Number(result.total)
					this.ipagination.pageSize = Number(result.size)
				} else {
					this.tableData = []
					this.ipagination.total = 0
					this.ipagination.pageSize = 10
				}
			} else {
				this.tableData = []
				this.ipagination.total = 0
				this.ipagination.pageSize = 10
			}
		},
		// 查询按钮点击
		searchClick() {
			this.$refs['ruleform'].validate((valid) => {
				if (valid) {
					// 请求折线图数据
					if (this.isEchart) {
						this.getLineData()
					} else {
						this.loadData(1)
					}
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		// 重置按钮点击
		formReset() {
			this.$refs['ruleform'].resetFields()
			this.queryParam = {}
			this.loadData(1)
		},
		// 获取/合并传递参数
		getQueryParams() {
			let param = Object.assign({}, this.queryParam)
			// console.log(this.ipagination);
			// 判断是否传递页码
			if (this.isTranPage) {
				param[this.pageValue] = this.ipagination.currentPage
				param[this.pageLimit] = this.ipagination.pageSize
			}
			// 时间是否是必传值，如果未选择，设置空
			if (this.isMustTime) {
				if (!param.startTime || param.startTime == '') {
					param.startTime = ''
				}
				if (!param.endTime || param.endTime == '') {
					param.endTime = ''
				}
			}
			// 时间后面是否补时分秒，主要用于传参是页面显示不对应的问题
			if (this.isFillTime) {
				if (param.startTime) {
					param.startTime = `${param.startTime} 00:00:00`
				}
				if (param.endTime) {
					param.endTime = `${param.endTime} 23:59:59`
				}
			}
			console.log(param)
			return param
		},
		// 分页的页码改变
		handleCurrentChange(val) {
			// console.log(val);
			this.ipagination.currentPage = val
			// console.log(this.ipagination);
			this.loadData()
		},
		// 导出
		// handleExportXls(list, head, keys, filename) {
		// 	import('vendor/Export2Excel').then((excel) => {
		// 		let fileList = JSON.parse(JSON.stringify(list))
		// 		let fileHead = JSON.parse(JSON.stringify(head))
		// 		let filterVal = JSON.parse(JSON.stringify(keys))
		// 		let datas = this.formatJSON(filterVal, fileList)
		// 		excel.export_json_to_excel({
		// 			header: fileHead,
		// 			data: datas,
		// 			filename: filename,
		// 		})
		// 	})
		// },
		// formatJSON(filterVal, jsonData) {
		// 	return jsonData.map((v) =>
		// 		filterVal.map((j) => {
		// 			return v[j]
		// 		})
		// 	)
		// },
	},
}
