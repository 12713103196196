import Mock from 'mockjs'
const Random = Mock.Random
Mock.mock('/wlw/login', 'post', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: {
		user: {
			userId: '83c041c2e5cd4cb0a9a3924221ef5dde',
			userInfoId: '42a8a7e2ffa54dddaac64d69d1eb4b3b',
			orgId: '0b412dedd15c4255b0e6192dfc463c44',
			trueName: '张文明',
			sexInfo: 1,
			email: '926105489@qq.com',
			phoneNum: '15670924821',
		},
		userInfo: {
			userId: '83c041c2e5cd4cb0a9a3924221ef5dde',
			userInfoId: '42a8a7e2ffa54dddaac64d69d1eb4b3b',
			orgId: '0b412dedd15c4255b0e6192dfc463c44',
			trueName: '张文明',
			sexInfo: 1,
			email: '926105489@qq.com',
			phoneNum: '15670924821',
		},
		org: {
			orgLevel: 2,
			orgName: '测试网点',
		},
		token: '5745e1952ee04216bc406f3edd816d88',
	},
})
Mock.mock('/wlw/logout', 'post', {
	meta: {
		success: true,
		message: 'ok',
	},
})
// 首页左上设备信息
Mock.mock('/wlw/firstPageCount', 'get', () => {
	return {
		meta: {
			success: true,
			message: 'ok',
		},
		data: [
			{
				name: '控制箱设备数量',
				num: 3,
			},
			{
				name: '水肥机设备数量',
				num: 0,
			},
			{
				name: '气象站设备数量',
				num: 1,
			},
			{
				name: '视频监控设备数量',
				num: 4,
			},
			{
				name: '土壤商情设备数量',
				num: 0,
			},
			{
				name: '虫情设备数量',
				num: 0,
			},
			{
				name: '设备总数',
				num: 8,
			},
		],
	}
})
// 首页告警信息/告警信息列表
Mock.mock('/wlw/getWarnList', 'get', (req) => {
	let options = JSON.parse(req.body)
	let pageSize = options.pageSize
	console.log(options)
	let datas = []
	for (let i = 0; i < pageSize; i++) {
		datas.push({
			title: '全区域-二氧化碳:报警信息',
			content: '全区域二氧化碳0ppm,正常范围10ppm~15ppm',
			sendTime: '2022-06-06 12:15:45',
			orgName: '温室大棚63',
			noticeType: '报警',
			deviceId: '1316305972067110914',
			terminalId: '1316305929893384193',
		})
	}
	return {
		total: '20',
		size: '10',
		current: '1',
		orders: [],
		optimizeCountSql: true,
		hitCount: false,
		searchCount: true,
		pages: '2',
		records: datas,
	}
})
// 首页生产总览
Mock.mock('/wlw/findKzxByOrgCode', () => {
	let datas = []
	for (let i = 0; i < 3; i++) {
		datas.push({
			orgId: '79107f6a-6a3d-4c82-932e-8cd265a96ceb',
			orgName: '01号大棚',
			terminalSerialNum: '196',
			status: '3',
		})
	}
	return datas
})
// 首页生产总览具体数据
Mock.mock('/wlw/kzxData', 'get', () => {
	return [
		{
			CREATE_TIME: '2022-06-07 10:31:44',
		},
		{
			CODE: 'EYHT',
			PARANAME: '二氧化碳',
			TIME: '2022-06-07 10:31:44',
			VALUE: '273ppm',
			CODENAME: '全区域-二氧化碳',
		},
		{
			CODE: 'GZQD',
			PARANAME: '光照强度',
			TIME: '2022-06-07 10:31:44',
			VALUE: '40391Lux',
			CODENAME: '全区域-光照强度',
		},
	]
})
// 获取网点信息
Mock.mock('/wlw/wangdianList', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	'data|3': [
		{
			orgName: '@increment(1)' + '号网点',
			orgId: '@id',
			equips: [
				{
					name: '放风机',
					id: '@id',
				},
				{
					name: '控制箱',
					id: '@id',
				},
			],
		},
	],
})

// 设备总数table查询
Mock.mock('/wlw/equipList', 'post', (req) => {
	let options = JSON.parse(req.body)
	console.log(options)
})

// 生产监测
// 生产区域信息
Mock.mock('/wlw/findChildOrgByParentOrgId', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			orgId: '1522047756410155010',
			orgCode: '771-1-0-2',
			orgName: '温室大棚02',
		},
		{
			orgId: '1522034181813694465',
			orgCode: '771-1-0-1',
			orgName: '温室大棚01',
		},
	],
})
// 获取监控下拉选
Mock.mock('/wlw/findVideoByOrgId', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			id: '1301712918287040513',
			orgId: 'ade777e3-abc6-4e16-9b60-bd5bb64707d0',
			vname: '监控2',
			vcode: '1000015$1$0$0',
			vurl: 'http://221.13.155.38:7086/live/cameraid/1000185%240/substream/2.m3u8',
			createUser: 'f5d60b89-1102-44c1-8f66-1273dbd704de',
			type: 0,
			netOrgId: '0dfc7a8c-65ba-45ce-9a39-49b57f6f7d14',
			delFlag: '1',
		},
		{
			id: '1301708304422617090',
			orgId: 'ade777e3-abc6-4e16-9b60-bd5bb64707d0',
			vname: '监控1',
			vcode: '1000029$1$0$0',
			vurl: 'http://112.6.122.38:7086/live/cameraid/1000029%240/substream/2.m3u8',
			createUser: 'f5d60b89-1102-44c1-8f66-1273dbd704de',
			modifyUser: 'f5d60b89-1102-44c1-8f66-1273dbd704de',
			type: 0,
			netOrgId: '0dfc7a8c-65ba-45ce-9a39-49b57f6f7d14',
			delFlag: '1',
		},
	],
})
// 传感器下拉选
Mock.mock('/wlw/findDeviceByOrgId', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			id: '00b0330d-c10d-4310-b2fd-65bc243831e7',
			typeId: 'ab1c1bbb677540ec8c47d2f58c8ac98f',
			serialNumber: 'HY02',
			deviceName: '二氧化碳1',
		},
		{
			id: '12caccba-1a8a-4358-920d-f3984f383076',
			typeId: '0a601d5f-2a89-44ac-bfda-94287a34111b',
			serialNumber: 'HY01',
			deviceName: '温湿度1',
		},
	],
})
// 设备操作列表
Mock.mock('/wlw/operateDeviceAction', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: {
		sd: [
			{
				deviceId: '8d76b019-011b-4b45-bebf-d6eed78e77d1',
				deviceNum: 'DEVICE32',
				deviceName: '湿帘泵1',
				deviceTypeId: '0f5d10c1-d2f6-4c8c-9399-609037fc4b51',
				deviceTypeCode: 'SLB',
				deviceSort: 0,
				deviceActions: [
					{
						id: '8a6b745a30d4409995f80e343156b2bc',
						code: 'ON',
						name: '打开',
						terminalCode: '0X01',
					},
					{
						id: '72ab45b4910e4692a9f0389eba711cc6',
						code: 'OFF',
						name: '关闭',
						terminalCode: '0X00',
					},
				],
				deviceStatus: 'OFF',
				deviceStatusDesc: '关闭',
				hasConfAutomation: false,
				hasConfTimed: false,
			},
			{
				deviceId: 'ae553878-584f-4632-a9fd-59093b8bf73d',
				deviceNum: 'DEVICE19',
				deviceName: '横向风机1',
				deviceTypeId: '9988a3fe-27ac-4a99-b9c8-a2a9eaa28fa1',
				deviceTypeCode: 'HXFJ',
				deviceSort: 0,
				deviceActions: [
					{
						id: '8a6b745a30d4409995f80e343156b2bc',
						code: 'ON',
						name: '打开',
						terminalCode: '0X01',
					},
					{
						id: '72ab45b4910e4692a9f0389eba711cc6',
						code: 'OFF',
						name: '关闭',
						terminalCode: '0X00',
					},
				],
				deviceStatus: 'OFF',
				deviceStatusDesc: '关闭',
				hasConfAutomation: false,
				hasConfTimed: false,
			},
		],
		ssd: [
			{
				deviceId: 'c9a384ab-e840-4256-91d5-6db10e57361b',
				deviceNum: 'DEVICE16',
				deviceName: '进风口',
				deviceTypeId: '596909c2-2691-4067-b6da-37cf6ec5e1f3',
				deviceTypeCode: 'JFK',
				deviceSort: 0,
				deviceActions: [
					{
						id: 'b3dab8818af940dd9a95174c68c6602d',
						code: '65',
						name: '打开65%',
					},
					{
						id: '22710007ec18475fa4d2f78a0df8a0bc',
						code: '66',
						name: '打开66%',
					},
				],
				ssdDeviceStatus: '0',
				ssdDeviceStatusSuffix: '%',
				hasConfAutomation: false,
				hasConfTimed: false,
			},
		],
		orgId: 'f2b23b31-1064-4a86-a255-79f149763bc6',
		orgName: '潢川试验场',
		thresholdFunStatus: 0,
		timingFunStatus: 0,
	},
})
// 类型采集项下拉选
Mock.mock('/wlw/getDeviceItems', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: [
		{
			id: 'c81e3d6eb778430bbcc9e8e7d898f136',
			code: 'EYHT',
			name: '二氧化碳',
			itemUnitCode: 'ppm',
		},
		{
			id: 'c2b1c46b430a45bab2a2d8e917d1e219',
			code: 'GZQD',
			name: '光照强度',
			plcCode: '3',
			itemUnitCode: 'Lux',
		},
		{
			id: '4fe08942a7b044068531da542ffce539',
			code: 'SD',
			name: '湿度',
			plcCode: '2',
			itemUnitCode: '%',
		},
		{
			id: 'ae603418-616e-43c2-a424-26998b4d1aaf',
			code: 'TRSD',
			name: '土壤湿度',
			plcCode: '5',
			itemUnitCode: '%',
		},
		{
			id: 'e807af9f-c222-4d66-b7c6-ee17eeed7eac',
			code: 'TRWD',
			name: '土壤温度',
			itemUnitCode: '℃',
		},
		{
			id: '3b2c2002f49549598727468a91d5d29b',
			code: 'WD',
			name: '温度',
			plcCode: '1',
			itemUnitCode: '℃',
		},
	],
})
// 传感器详细数据
Mock.mock('/wlw/latestDeviceData', 'get', {
	meta: {
		success: true,
		message: 'ok',
	},
	data: {
		sensorId: '1463809350660386817',
		dateTime: '2022-06-08 16:15:18',
		envItems: [
			{
				itemCode: 'EYHT',
				itemName: '二氧化碳',
				itemValue: '433',
				itemUnitCode: 'ppm',
			},
			{
				itemCode: 'GZQD',
				itemName: '光照强度',
				itemValue: '720',
				itemUnitCode: 'Lux',
			},
			{
				itemCode: 'SD',
				itemName: '空气湿度',
				itemValue: '43.40',
				itemUnitCode: '%',
			},
			{
				itemCode: 'WD',
				itemName: '空气温度',
				itemValue: '34.70',
				itemUnitCode: '℃',
			},
		],
	},
})
