<template>
	<div class="flex flex_col home" @click.stop="searchShow = false">
		<!-- 上边 -->
		<div class="top">
			<div class="tq" style="position: relative">
				<Weather></Weather>
			</div>
			<div class="xx">
				<div class="div-header">
					<div class="div-header-l">消息通知</div>
					<div class="div-header-r">
						<span @click="toxxtz">更多></span>
					</div>
				</div>
				<div class="content">
					<!-- 上面的Tab -->
					<div class="tab-box">
						<div
							class="tab-item"
							:class="{ 'tab-item-act': xxTabIndex == item.type }"
							v-for="(item, index) in xxTabList"
							:key="index"
							@click="xxTabItemClick(item)"
						>
							{{ item.name }}
						</div>
					</div>
					<!-- 下面的消息列表 -->
					<div class="conten-div">
						<div class="content-list" v-if="xxList.length">
							<div class="item" :class="{ 'item-up': item.showUp }" v-for="(item, index) in xxList" :key="index">
								<div class="title">{{ item.messageTitle }}</div>
								<div class="flex">
									<div class="item-content">
										<div class="icon" v-if="item.messageBody.length > 40" @click="xxItemIconClick(index)"></div>
										{{ item.messageBody }}
									</div>
								</div>

								<div class="time">{{ item.pushTime }}</div>
							</div>
						</div>
						<div :style="{ width: 100 + '%', height: 100 + '%' }" class="flex flex_ju_c flex_a_c" v-else>
							<img src="@/assets/image/home/ZWT.png" alt="" />
						</div>
					</div>
				</div>
			</div>
			<div class="hz">
				<div class="div-header">
					<div class="div-header-l">合作共赢</div>
					<div class="div-header-r">
						<span @click="lxwmClick"><img src="@/assets/image/home/LXWM.png" alt="" /> 联系我们</span>
					</div>
				</div>
				<div class="content">
					<div class="tab-box">
						<div
							class="tab-item"
							:class="{ 'tab-item-act': hzTabIndex == index }"
							v-for="(item, index) in hzTabList"
							:key="index"
							@click="hzTabItemClick(index)"
						>
							{{ item }}
						</div>
					</div>
					<div class="conten-div">
						<div class="content-list" v-if="hzList.length">
							<div class="item" v-for="(item, index) in hzList" :key="index" @click="hzItemClick(item)">
								<div class="title ellipsis">{{ item.title }}</div>
								<div class="time">{{ item.publishTime }}</div>
							</div>
						</div>
						<div :style="{ width: 100 + '%', height: 100 + '%' }" class="flex flex_ju_c flex_a_c" v-else>
							<img src="@/assets/image/home/ZWT.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 下边 -->
		<div class="bottom">
			<div class="sb">
				<div class="div-header">
					<div class="div-header-l">产品设备状态统计</div>
					<div class="div-header-r">
						<span @click="tomySb">产品管理></span>
					</div>
				</div>
				<div class="content">
					<div class="item" v-for="(item, index) in sbList" :key="index">
						<div class="l-img">
							<img :src="item.img" alt="" />
						</div>
						<div class="r-txt">
							<div class="name">{{ item.name }}</div>
							<div class="num">{{ item.num }}</div>
							<div class="online">在线数（{{ item.online }}）</div>
							<div class="offline">离线数（{{ item.offline }}）</div>
						</div>
					</div>
				</div>
			</div>
			<div class="cp">
				<div class="div-header">
					<div class="div-header-l">产品推荐</div>
					<div class="div-header-r">
						<div class="seach-box" @click.stop>
							<div class="seach-btn" @click.stop="searchOpen"></div>
							<div class="seach-input-box" :class="{ 'seach-box-show': searchShow }">
								<el-input
									v-model="seachInput"
									placeholder="请输入关键词搜索"
									@keyup.enter.native="search"
									ref="homeInputS"
								></el-input>
							</div>
						</div>
					</div>
				</div>
				<div class="content">
					<div class="content-div" v-if="cpList.length">
						<div class="item" v-for="(item, index) in cpList" :key="index">
							<div class="l-img cursor">
								<el-image
									style="width: 155px; height: 155px"
									fit="cover"
									:src="item.imgList[0]"
									@click="productPicClick(item)"
								>
								</el-image>
							</div>
							<div class="r-div">
								<div class="name">{{ item.productName }}</div>
								<div class="introduction ellipsis_three">产品简介：{{ item.productPresentation }}</div>
								<div class="price">
									￥{{ item.productPrice }}
									<div v-if="item.tbLink" class="tb" @click="toBlank(item.tbLink, item.id)"></div>
									<div v-if="item.jdLink" class="jd" @click="toBlank(item.jdLink, item.id)"></div>
									<!-- <a :href="item.tbLink" class="tb" target="_blank"></a>
									<a :href="item.jdLink" class="jd" target="_blank"></a> -->
								</div>
							</div>
						</div>
					</div>
					<div :style="{ width: 100 + '%', height: 100 + '%' }" class="flex flex_ju_c flex_a_c" v-else>
						<img src="@/assets/image/home/ZWT.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<!-- 产品图片放大查看 -->
		<ProductPicture ref="ProductPicture" :preview="previewObj"></ProductPicture>
		<!-- 案例详情抽屉 -->
		<el-drawer custom-class="alxqDrawer" :visible.sync="hzdrawer" :with-header="false" size="45%">
			<div class="close-btn" @click="hzdrawer = false"></div>
			<div class="drawer-content">
				<div class="title">{{ hzxqObj.title || '--' }}</div>
				<div class="content" v-html="hzxqObj.content"></div>
			</div>
		</el-drawer>
		<!-- 联系我们抽屉 -->
		<lxwmDrawer ref="lxwmDrawer"></lxwmDrawer>
	</div>
</template>

<script>
import lxwmDrawer from './components/lxwmDrawer.vue'
import Weather from './components/weather.vue'
import ProductPicture from './components/ProductPicture.vue'
import { mapState } from 'vuex'
export default {
	components: { lxwmDrawer, Weather, ProductPicture },
	data() {
		const self = this
		return {
			//消息通知菜单
			// 0,全部1阈值告警2场景消息3设备消息4系统消息
			xxTabList: [
				{
					name: '阈值告警',
					type: 1,
				},
				{
					name: '设备消息',
					type: 3,
				},
				{
					name: '场景消息',
					type: 2,
				},
				{
					name: '系统消息',
					type: 4,
				},
			],
			xxTabIndex: 1,
			// 消息通知列表
			xxList: [
				// {
				// 	title: '标题123',
				// 	content:
				// 		'当前温度36℃，超出阈值23-30℃，请注意！当前温度36℃，超出阈值23-30℃，请注意！当前温度36℃，超出阈值23-30℃，请注意！',
				// 	time: '2021-10-22 11:25:52',
				// 	showUp: true,
				// },
			],
			// 合作共赢菜单
			hzTabList: ['项目案例', '解决方案'],
			hzTabIndex: 0,
			// 合作共赢列表
			hzList: [
				// {
				//   title: "海淀公园强迫闲逛人员背防疫手册？",
				//   publishTime: "2022-11-09",
				// },
			],
			// 控制合作共赢案例详情
			hzdrawer: false,
			// 合作共赢案例详情
			hzxqObj: {},
			// 控制联系我们抽屉
			lxwmdrawer: false,
			//设备数组
			sbList: [
				{
					name: '智能终端',
					num: 0,
					online: 0,
					offline: 0,
					img: require('@/assets/image/home/ZNZD.png'),
					productType: '6',
				},
				{
					name: '控制箱设备',
					num: 0,
					online: 0,
					offline: 0,
					img: require('@/assets/image/home/KZX.png'),
					productType: '1',
				},
				{
					name: '气象设备',
					num: 0,
					online: 0,
					offline: 0,
					img: require('@/assets/image/home/QXZ.png'),
					productType: '2',
				},
				{
					name: '墒情设备',
					num: 0,
					online: 0,
					offline: 0,
					img: require('@/assets/image/home/SQ.png'),
					productType: '3',
				},
				{
					name: '虫情设备',
					num: 0,
					online: 0,
					offline: 0,
					img: require('@/assets/image/home/QQ.png'),
					productType: '5',
				},
				{
					name: '杀虫灯',
					num: 0,
					online: 0,
					offline: 0,
					img: require('@/assets/image/home/SCD.png'),
					productType: '4',
				},
				{
					name: '监控设备',
					num: 0,
					online: 0,
					offline: 0,
					img: require('@/assets/image/home/JK.png'),
					productType: '7',
				},
				{
					name: '水肥设备',
					num: 0,
					online: 0,
					offline: 0,
					img: require('@/assets/image/home/SF.png'),
					productType: '8',
				},
			],
			// 设备搜索框输入
			seachInput: '',
			// 搜索框是否显示
			searchShow: false,
			// 产品数组
			cpList: [
				// {
				//   name: "元丰科技智慧农业土壤速测仪",
				//   introduction:
				//     "产品简介：产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介产品简介45646444444444444444444444444444444444444444444444",
				//   price: "¥9.90-99.00",
				//   jdLink: "javascript:;",
				//   tbLink: "https://taobao.com/",
				//   imgList: [
				//     "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
				//     "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
				//     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
				//   ],
				// },
			],
			// 图片预览
			previewObj: {
				imglist: [],
				content: '',
			},
		}
	},
	created() {
		// 设备统计
		this.getUserDeviceCount()
	},
	computed: {
		...mapState(['socketMsg']),
	},
	watch: {
		socketMsg: {
			handler(newval) {
				// 接收到的值
				let data = newval.data
				// 判断需要刷新的列表和当前显示的是否一致，一致的话，进行刷新
				if (data == this.xxTabIndex) {
					// 重新获取列表数据
					this.getMsgList()
				}
			},
			deep: true,
			immediate: true,
		},
	},
	mounted() {
		// 获取消息通知列表
		this.getMsgList()
		// 合作共赢列表
		this.getArticleList()
		// 产品推荐列表
		this.getRecommedList()
	},
	methods: {
		// 跳转消息通知
		toxxtz() {
			this.$router.push('/message/notice')
		},
		// 跳转我的设备
		tomySb() {
			this.$router.push('/equipment')
		},
		//消息通知项点击
		xxTabItemClick({ type }) {
			if (this.xxTabIndex == type) return
			this.xxTabIndex = type
			// 请求对应的列表数据
			this.$nextTick(() => {
				this.getMsgList()
			})
		},
		//消息通知项图标点击
		xxItemIconClick(index) {
			this.xxList[index].showUp = !this.xxList[index].showUp
		},
		// 合作共赢通知项点击
		hzTabItemClick(index) {
			if (this.hzTabIndex == index) return
			this.hzTabIndex = index

			this.$nextTick(() => {
				this.getArticleList()
			})
		},
		// 合作共赢案例点击
		hzItemClick({ id }) {
			this.hzdrawer = true
			// 获取详情数据
			let params = {
				id: id,
			}

			this.$http
				.getAction(this.$api.srticleDetail, params)
				.then((res) => {
					// res = {
					//   code: 200,
					//   msg: "ok",
					//   data: {
					//     id: "6",
					//     articleType: "0",
					//     title: "第一观察·瞬间｜最高礼遇展现中沙关系“热度”",
					//     content:
					//       '<p><br></p><p><br></p><p>恩珠阿姨如今是一个单身人士，前几年家庭原因原本的四口之家变成了如今的阿姨和一双儿女，三口之家随着儿子的成家女儿的大学，阿姨渐渐地成为了一个人的生活。有了小时候儿女的拉扯抚养，如今空闲之余，恩珠阿姨很多的自由时间可以让自己每天享受着时光的美好。</p><p><img src="https://pics2.baidu.com/feed/a71ea8d3fd1f4134c31ce05a14f8cac1d0c85ed9.jpeg@f_auto?token=2d76767f9ac5c20f4fb8d2f50d971a48" alt="" data-href="" style="width: 656px;"/></p><p><br></p><p><br></p><p>这是阿姨的日常生活照，平常在家做做料理，养养花草，收拾收拾家务。平平淡淡的日子被阿姨过成了温馨治愈的岁月。恩珠阿姨生活极简也很潮流，日常自媒体玩得很溜，目前恩珠阿姨也是拥有千万粉丝的知名博主。</p><p><br></p><p><br></p><p><strong>进门的独立玄关</strong></p>',
					//     pageView: 0,
					//     displayStatus: "0",
					//     publishStatus: "0",
					//     publishTime: "2022-12-09",
					//     draftType: "0",
					//     parentId: "0",
					//   },
					// };
					if (res.code == 200) {
						let datas = res.data || {}
						this.hzxqObj = datas
					} else {
						this.hzxqObj = {}
					}
				})
				.finally(() => {
					// 请求阅读增加接口
					this.$http.getAction(this.$api.savePageView, params)
				})
		},
		// 点击联系我们
		lxwmClick() {
			this.$refs.lxwmDrawer.show = true
		},
		searchOpen() {
			if (!this.searchShow) {
				this.searchShow = true
				this.$nextTick(() => {
					this.$refs.homeInputS.focus()
				})
			} else {
				this.search()
			}
		},
		// 搜索点击
		search() {
			// if (this.seachInput == '') {
			// 	this.$message('请输入搜索关键词', 'error')
			// 	return
			// } else {
			// 重新获取接口
			this.$nextTick(() => {
				this.getRecommedList()
			})
			// }
		},
		// 产品图片点击
		productPicClick(pamas) {
			console.log(pamas)
			this.$refs.ProductPicture.productPic = true
			this.previewObj.imglist = pamas.imgList
			this.previewObj.content = pamas.productPresentation
		},

		/*
		 * @description: 数据获取
		 */
		// TODO
		//    获取消息通知列表
		getMsgList() {
			let params = {
				page: 1,
				limit: 10,
				messageType: this.xxTabIndex,
			}
			this.$http.getAction(this.$api.getMsgList, params).then((res) => {
				// res = {
				//   code: 0,
				//   msg: "ok",
				//   data: [
				//     {
				//       id: "1196",
				//       createId: "42",
				//       createName: "",
				//       messageType: 3,
				//       messageTypeDesc: "设备消息",
				//       userId: "42",
				//       messageLevel: 1,
				//       messageTitle: "全区域--智能卷被机",
				//       messageSubtitle: "全区域--智能卷被机",
				//       messageBody:
				//         "您全区域下的智能卷被机在2022年10月28日 16时20分00秒上线",
				//       deviceId: "765",
				//       deviceNum: "22102801000",
				//       relevancyId:
				//         '{"deviceNum":"22102801000","areaId":"0","deviceId":"765","productType":"6"}',
				//       readStatus: 0,
				//       pushTime: "2022-10-28 16:22:37",
				//       createTime: "2022-10-28 16:22:37",
				//       modifyTime: "2022-10-28 16:22:37",
				//     },
				//     {
				//       id: "1194",
				//       createId: "42",
				//       createName: "",
				//       messageType: 3,
				//       messageTypeDesc: "设备消息",
				//       userId: "42",
				//       messageLevel: 1,
				//       messageTitle: "全区域--智能卷被机",
				//       messageSubtitle: "全区域--智能卷被机",
				//       messageBody:
				//         "您全区域下的智能卷被机在2022年10月28日 16时19分41秒已离线，请您及时检查现场网络或供电情况，及时处理!",
				//       deviceId: "765",
				//       deviceNum: "22102801000",
				//       relevancyId:
				//         '{"deviceNum":"22102801000","areaId":"0","deviceId":"765","productType":"6"}',
				//       readStatus: 0,
				//       pushTime: "2022-10-28 16:22:18",
				//       createTime: "2022-10-28 16:22:18",
				//       modifyTime: "2022-10-28 16:22:18",
				//     },
				//     {
				//       id: "1195",
				//       createId: "42",
				//       createName: "",
				//       messageType: 3,
				//       messageTypeDesc: "设备消息",
				//       userId: "42",
				//       messageLevel: 1,
				//       messageTitle: "全区域--智能卷被机",
				//       messageSubtitle: "全区域--智能卷被机",
				//       messageBody:
				//         "您全区域下的智能卷被机在2022年10月28日 16时20分00秒上线",
				//       deviceId: "765",
				//       deviceNum: "22102801000",
				//       relevancyId:
				//         '{"deviceNum":"22102801000","areaId":"0","deviceId":"765","productType":"6"}',
				//       readStatus: 0,
				//       pushTime: "2022-10-28 16:21:26",
				//       createTime: "2022-10-28 16:21:26",
				//       modifyTime: "2022-10-28 16:21:26",
				//     },
				//   ],
				//   count: "61",
				// };
				if (res.code == 0 && res.data.length) {
					res.data.forEach((item, index) => {
						item.showUp = true
					})
					let datas = res.data || []
					this.xxList = datas || []
				} else {
					this.xxList = []
				}
			})
		},
		// 合作共赢列表
		getArticleList() {
			let params = {
				page: 1,
				limit: 10,
				articleType: this.hzTabIndex,
			}
			this.$http.postAction(this.$api.articleList, params).then((res) => {
				if (res.code == 0) {
					let datas = res.data || []
					this.hzList = datas || []
				} else {
					this.hzList = []
				}
			})
		},
		// 产品推荐列表
		getRecommedList() {
			let params = {
				productName: this.seachInput,
			}
			this.$http.postAction(this.$api.recommedList, params).then((res) => {
				if (res.code == 200 && res.data.length) {
					res.data.forEach((item, index) => {
						item.imgList = []
						if (item.pictureList) {
							item.pictureList.forEach((item2, index2) => {
								item.imgList.push(item2.pictureUrl)
							})
						}
						if (item.linkList && item.linkList.length) {
							item.linkList.forEach((item2) => {
								if (item2.linkType == 'http://82.157.58.19:9000/icon/jingdong.png') {
									item.jdLink = item2.webLink
								}
								if (item2.linkType == 'http://82.157.58.19:9000/icon/taobao.png') {
									item.tbLink = item2.webLink
								}
							})
						}
					})
					let datas = res.data || []
					this.cpList = datas
				} else {
					this.cpList = []
				}
			})
		},
		// 设备统计
		getUserDeviceCount() {
			this.$http.getAction(this.$api.getUserDeviceCount + '?id=0').then((res) => {
				if (res.data.length) {
					this.sbList.forEach((item) => {
						res.data.forEach((item2) => {
							if (item.productType == item2.productType) {
								item.num = item2.totalNum
								item.online = item2.onlineNum
								item.offline = item2.offlineNum
							}
						})
					})
				}
			})
		},
		toBlank(link, id) {
			let params = {
				id: id,
			}
			this.$http
				.getAction(this.$api.savePageView, params)
				.then(() => {})
				.finally(() => {
					// ;(window.location.href = link), '_blank'
					window.open(link)
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.home {
	height: 100%;
	min-height: 610px;
	.div-header {
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 0.325rem;
		// height: 0.625rem;
		height: 5.4vh;
		min-height: 0.475rem;
		border-bottom: 1px solid rgb(241, 241, 241);
		.div-header-l {
			font-size: 0.25rem;
			// font-size: 2.15vh;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #454545;
		}
		.div-header-r {
			span {
				display: flex;
				cursor: pointer;
				font-size: 0.2rem;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2daea9;
				img {
					margin-right: 0.0875rem;
				}
			}
			.seach-box {
				position: relative;
				.seach-btn {
					position: absolute;
					z-index: 1;
					top: 50%;
					right: 0.25rem;
					transform: translateY(-50%);
					cursor: pointer;
					width: 0.2125rem;
					height: 0.2125rem;
					background: url('../../assets/image/home/SS.png') no-repeat;
					background-size: 100% 100%;
				}
				.seach-input-box {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					// width: 2.925rem;
					width: 0;
					height: 0.425rem;
					background: rgba(236, 241, 244, 0.59);
					border-radius: 0.2125rem;
					transition: width 1s;
					overflow: hidden;
					&.seach-box-show {
						width: 2.925rem;
					}
				}
			}
		}
	}
	.top {
		display: flex;
		justify-content: space-between;
		height: 42%;
		& > div {
			height: 100%;
			background: #ffffff;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
		}
		.tab-box {
			background: #efefef;
			border-radius: 0.1rem;
			display: flex;
			justify-content: space-between;
			padding: 0.05rem;
			width: 56%;
			.tab-item {
				cursor: pointer;
				width: 0.95rem;
				height: 0.3625rem;
				font-size: 0.175rem;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #454545;
				text-align: center;
				line-height: 0.3625rem;
				border-radius: 0.1rem;
			}
			.tab-item-act {
				background: #ffffff;
				color: #2daea9;
			}
		}
		.tq {
			width: 29.5%;
			// background: linear-gradient(184deg, #65a4e0, #497acd);
			overflow: hidden;
		}
		.xx {
			width: 34.2%;
			.content {
				height: 80%;
				box-sizing: border-box;
				padding: 0.2125rem 0.125rem 0.25rem 0.25rem;
				.conten-div {
					margin-top: 0.1625rem;
					height: 87%;

					.content-list {
						width: 100%;
						height: 100%;
						overflow: auto;
						&::-webkit-scrollbar {
							width: 3px;
							height: 0px;
							background-color: transparent;
						}
						&::-webkit-scrollbar-track {
							border-radius: 3px;
							background-color: transparent;
						}
						&::-webkit-scrollbar-thumb {
							border-radius: 3px;
							background-color: rgba(45, 174, 169, 0.2);
						}

						.item {
							width: 98.5%;
							box-sizing: border-box;
							padding: 0.15rem 0.25rem 0.2125rem 0.225rem;
							border-radius: 0.05rem;
							background: rgba(237, 237, 237, 0.5);
							margin-bottom: 0.125rem;
							& > div {
								text-align: left;
							}
							.title {
								font-size: 0.2rem;
								font-family: Microsoft YaHei;
								font-weight: bold;
								color: #454545;
							}
							.item-content {
								margin-top: 0.1375rem;
								margin-bottom: 0.1375rem;
								font-size: 0.175rem;
								line-height: 1.5;
								font-family: Microsoft YaHei;
								font-weight: 400;
								max-height: 18px;
								color: #454545;
								overflow: hidden;

								max-height: 9999px;
								// transition: max-height 1s linear;
								&::before {
									content: '';
									float: right;
									width: 0px;
									height: calc(100% - 0.125rem);
								}

								.icon {
									cursor: pointer;
									float: right;
									width: 0.15rem;
									height: 0.1125rem;
									background: url('../../assets/image/home/XL.png') no-repeat;
									background-size: 100% 100%;
									transition: all 0.5s;
									clear: both;
									transform: rotate(180deg);
								}
							}
							.time {
								color: #939393;
							}
						}
						.item-up {
							.item-content {
								text-align: justify;

								overflow: hidden;
								text-overflow: ellipsis;
								word-break: break-all;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 1;
								max-height: 18px;
								.icon {
									transform: rotate(0deg);
								}
							}
						}
						.item:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
		.hz {
			width: 34%;
			.tab-box {
				width: 29%;
			}
			.content {
				height: 80%;
				box-sizing: border-box;
				padding: 0.2125rem 0.125rem 0.25rem 0.25rem;
				.conten-div {
					margin-top: 0.1625rem;
					height: 87%;

					.content-list {
						width: 100%;
						height: 100%;
						overflow: auto;
						&::-webkit-scrollbar {
							width: 3px;
							height: 0px;
							background-color: transparent;
						}
						&::-webkit-scrollbar-track {
							border-radius: 3px;
							background-color: transparent;
						}
						&::-webkit-scrollbar-thumb {
							border-radius: 3px;
							background-color: rgba(45, 174, 169, 0.2);
						}
						.item {
							cursor: pointer;
							width: 98.5%;
							box-sizing: border-box;
							display: flex;
							justify-content: space-between;
							line-height: 1.6;
							// margin-bottom: 0.2875rem;
							.title {
								flex: 1;
								font-size: 0.2rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #000000;
								text-align: left;
								line-height: 1.9;
							}
							.time {
								margin-left: 0.525rem;
								font-size: 0.2rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #999999;
								line-height: 1.9;
							}
						}
						.item:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	.bottom {
		margin-top: 0.225rem;
		display: flex;
		justify-content: space-between;
		height: 54.2%;
		& > div {
			height: 100%;
			background: #ffffff;
			border-radius: 10px;
		}
		.sb {
			width: 64.8%;
			.content {
				height: 85%;
				box-sizing: border-box;
				padding: 0.225rem 0.3375rem;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-content: space-between;
				.item {
					width: 24%;
					height: 48%;
					background: #ffffff;
					box-shadow: 0.0125rem 0.0125rem 0.0875rem 0px rgba(204, 204, 204, 0.44);
					border-radius: 0.1125rem;
					display: flex;
					.l-img {
						width: 54.5%;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.r-txt {
						width: 45.5%;
						box-sizing: border-box;
						padding: 10% 0;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						text-align: left;
						.name {
							font-size: 0.2rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #454545;
						}
						.num {
							font-size: 0.375rem;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #454545;
						}
						.online {
							font-size: 0.175rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #2daea9;
						}
						.offline {
							font-size: 0.175rem;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ff7171;
						}
					}
				}
			}
		}
		.cp {
			width: 34%;
			.div-header {
				padding-right: 0.17rem;
			}
			.div-header-r {
				width: 3rem;
			}
			.content {
				height: 85%;
				box-sizing: border-box;
				padding: 0.225rem 0.125rem 0.225rem 0.3375rem;
				.content-div {
					width: 100%;
					height: 100%;
					overflow: auto;
					padding-right: 0.125rem;
					&::-webkit-scrollbar {
						width: 3px;
						height: 0px;
						background-color: transparent;
					}
					&::-webkit-scrollbar-track {
						border-radius: 3px;
						background-color: transparent;
					}
					&::-webkit-scrollbar-thumb {
						border-radius: 3px;
						background-color: rgba(45, 174, 169, 0.2);
					}
					.item {
						// height: 2.275rem;
						height: 2.05rem;
						box-sizing: border-box;
						padding-bottom: 0.175rem;
						border-bottom: 1px solid rgba(153, 153, 153, 0.15);
						display: flex;
						margin-bottom: 0.125rem;
						&:last-child {
							margin-bottom: 0;
							padding-bottom: 0;
							border-bottom: none;
						}
						.l-img {
							// width: 2.1125rem;
							// height: 2.1125rem;
							width: 1.9375rem;
							height: 1.9375rem;
							background: gray;
						}
						.r-div {
							width: 65%;
							margin-left: 0.2rem;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							text-align: left;
							.name {
								font-size: 0.2rem;
								font-family: Microsoft YaHei;
								font-weight: bold;
								color: #454545;
							}
							.introduction {
								font-size: 0.175rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #999999;
								line-height: 0.2875rem;
								height: 0.925rem;
							}
							.price {
								position: relative;
								font-size: 0.325rem;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #ff7171;
								.tb {
									position: absolute;
									bottom: 0;
									right: 0.5625rem;
									width: 0.45rem;
									height: 0.4625rem;
									background: url('../../assets/image/home/TB.png') no-repeat;
									background-size: 100% 100%;
								}
								.jd {
									position: absolute;
									bottom: 0;
									right: 0;
									width: 0.45rem;
									height: 0.4625rem;
									background: url('../../assets/image/home/JD.png') no-repeat;
									background-size: 100% 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
<style lang="scss">
.home {
	// 抽屉样式
	.alxqDrawer {
		border-radius: 15px 0px 0px 15px;
		.close-btn {
			cursor: pointer;
			position: absolute;
			top: 0.3375rem;
			left: 0.3375rem;
			width: 0.375rem;
			height: 0.375rem;
			background: url('../../assets/image/home/GB.png') no-repeat;
			background-size: 100% 100%;
		}
		.el-drawer__body {
			box-sizing: border-box;
			padding: 0.375rem 10px 0.375rem 0.4375rem;
		}
		.drawer-content {
			width: 100%;
			height: 100%;
			padding-right: 15px;
			&::-webkit-scrollbar {
				width: 7px;
				height: 0px;
				background-color: transparent;
			}
			&::-webkit-scrollbar-track {
				border-radius: 7px;
				background-color: transparent;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 7px;
				background-color: rgba(45, 174, 169, 0.2);
			}
			.title {
				font-size: 26px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #333333;
			}
			.content {
				height: 90%;
				overflow: auto;
				margin-top: 0.6125rem;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 1.5;
				text-align: left;
				text-indent: 2em;
				p {
					line-height: 1.5;
				}
			}
		}
	}
	.seach-box {
	}
	.el-input__inner {
		height: 0.425rem;
		background: rgba(236, 241, 244, 0.59);
		border-radius: 0.2125rem;
		border-color: transparent;
	}
	.el-input.is-active .el-input__inner,
	.el-input__inner:focus {
		border-color: #2daea9;
	}
}
</style>
