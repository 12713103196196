import Mock from 'mockjs'
const Random = Mock.Random
// 气象数据分析table表格
Mock.mock('/wlw/weatherStationDataReport', 'post', () => {
	return {
		meta: {
			success: true,
			message: 'ok',
		},
		data: {
			content: [
				{
					acquisitionTime: '2022-05',
					list: [
						{
							acquisitionItemName: '风速',
							acquisitionItemUnitCode: 'm/s',
							acquisitionData: 12,
							acquisitionItem: 'FS',
						},
						{
							acquisitionItemName: 'ph',
							acquisitionItemUnitCode: '',
							acquisitionData: 33,
							acquisitionItem: 'PH',
						},
						{
							acquisitionItemName: '空气温度',
							acquisitionItemUnitCode: 'WD',
							acquisitionData: 24,
							acquisitionItem: '℃',
						},
					],
				},
				{
					acquisitionTime: '2022-06',
					list: [
						{
							acquisitionItemName: '风速',
							acquisitionItemUnitCode: 'm/s',
							acquisitionData: 5,
							acquisitionItem: 'FS',
						},
						{
							acquisitionItemName: 'ph',
							acquisitionItemUnitCode: '',
							acquisitionData: 25,
							acquisitionItem: 'PH',
						},
						{
							acquisitionItemName: '空气温度',
							acquisitionItemUnitCode: 'WD',
							acquisitionData: 33,
							acquisitionItem: '℃',
						},
					],
				},
				{
					acquisitionTime: '2022-06',
					list: [
						{
							acquisitionItemName: '风速',
							acquisitionItemUnitCode: 'm/s',
							acquisitionData: 5,
							acquisitionItem: 'FS',
						},
						{
							acquisitionItemName: 'ph',
							acquisitionItemUnitCode: '',
							acquisitionData: 25,
							acquisitionItem: 'PH',
						},
						{
							acquisitionItemName: '空气温度',
							acquisitionItemUnitCode: 'WD',
							acquisitionData: 33,
							acquisitionItem: '℃',
						},
					],
				},
				{
					acquisitionTime: '2022-06',
					list: [
						{
							acquisitionItemName: '风速',
							acquisitionItemUnitCode: 'm/s',
							acquisitionData: 5,
							acquisitionItem: 'FS',
						},
						{
							acquisitionItemName: 'ph',
							acquisitionItemUnitCode: '',
							acquisitionData: 25,
							acquisitionItem: 'PH',
						},
						{
							acquisitionItemName: '空气温度',
							acquisitionItemUnitCode: 'WD',
							acquisitionData: 33,
							acquisitionItem: '℃',
						},
					],
				},
				{
					acquisitionTime: '2022-06',
					list: [
						{
							acquisitionItemName: '风速',
							acquisitionItemUnitCode: 'm/s',
							acquisitionData: 5,
							acquisitionItem: 'FS',
						},
						{
							acquisitionItemName: 'ph',
							acquisitionItemUnitCode: '',
							acquisitionData: 25,
							acquisitionItem: 'PH',
						},
						{
							acquisitionItemName: '空气温度',
							acquisitionItemUnitCode: 'WD',
							acquisitionData: 33,
							acquisitionItem: '℃',
						},
					],
				},
				{
					acquisitionTime: '2022-06',
					list: [
						{
							acquisitionItemName: '风速',
							acquisitionItemUnitCode: 'm/s',
							acquisitionData: 5,
							acquisitionItem: 'FS',
						},
						{
							acquisitionItemName: 'ph',
							acquisitionItemUnitCode: '',
							acquisitionData: 25,
							acquisitionItem: 'PH',
						},
						{
							acquisitionItemName: '空气温度',
							acquisitionItemUnitCode: 'WD',
							acquisitionData: 33,
							acquisitionItem: '℃',
						},
					],
				},
				{
					acquisitionTime: '2022-06',
					list: [
						{
							acquisitionItemName: '风速',
							acquisitionItemUnitCode: 'm/s',
							acquisitionData: 5,
							acquisitionItem: 'FS',
						},
						{
							acquisitionItemName: 'ph',
							acquisitionItemUnitCode: '',
							acquisitionData: 25,
							acquisitionItem: 'PH',
						},
						{
							acquisitionItemName: '空气温度',
							acquisitionItemUnitCode: 'WD',
							acquisitionData: 33,
							acquisitionItem: '℃',
						},
					],
				},
				{
					acquisitionTime: '2022-06',
					list: [
						{
							acquisitionItemName: '风速',
							acquisitionItemUnitCode: 'm/s',
							acquisitionData: 5,
							acquisitionItem: 'FS',
						},
						{
							acquisitionItemName: 'ph',
							acquisitionItemUnitCode: '',
							acquisitionData: 25,
							acquisitionItem: 'PH',
						},
						{
							acquisitionItemName: '空气温度',
							acquisitionItemUnitCode: 'WD',
							acquisitionData: 33,
							acquisitionItem: '℃',
						},
					],
				},
				{
					acquisitionTime: '2022-06',
					list: [
						{
							acquisitionItemName: '风速',
							acquisitionItemUnitCode: 'm/s',
							acquisitionData: 5,
							acquisitionItem: 'FS',
						},
						{
							acquisitionItemName: 'ph',
							acquisitionItemUnitCode: '',
							acquisitionData: 25,
							acquisitionItem: 'PH',
						},
						{
							acquisitionItemName: '空气温度',
							acquisitionItemUnitCode: 'WD',
							acquisitionData: 33,
							acquisitionItem: '℃',
						},
					],
				},
				{
					acquisitionTime: '2022-06',
					list: [
						{
							acquisitionItemName: '风速',
							acquisitionItemUnitCode: 'm/s',
							acquisitionData: 5,
							acquisitionItem: 'FS',
						},
						{
							acquisitionItemName: 'ph',
							acquisitionItemUnitCode: '',
							acquisitionData: 25,
							acquisitionItem: 'PH',
						},
						{
							acquisitionItemName: '空气温度',
							acquisitionItemUnitCode: 'WD',
							acquisitionData: 33,
							acquisitionItem: '℃',
						},
					],
				},
			],
			empty: true,
			first: true,
			last: true,
			number: 0,
			numberOfElements: 0,
			pageable: { sort: { unsorted: true, sorted: false, empty: true }, pageSize: 10, pageNumber: 0, offset: '0' },
			size: 10,
			sort: { unsorted: true, sorted: false, empty: true },
			totalElements: '13',
			totalPages: 2,
		},
	}
})
// 气象数据分析折线图数据
Mock.mock('/wlw/weatherStationDataChart', 'post', () => {
	return {
		meta: {
			success: true,
			message: 'ok',
		},
		data: {
			'2022-07-01': [
				{
					acquisitionItemUnitCode: 'm/s',
					acquisitionData: 25,
					acquisitionItemName: '风速',
				},
			],
			'2022-07-02': [
				{
					acquisitionItemUnitCode: 'm/s',
					acquisitionData: 13,
					acquisitionItemName: '风速',
				},
			],
			'2022-07-03': [
				{
					acquisitionItemUnitCode: 'm/s',
					acquisitionData: 10,
					acquisitionItemName: '风速',
				},
			],
		},
	}
})
