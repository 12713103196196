<template>
	<div class="video-fens">
		<div class="cell-players">
			<div :class="cellClass(i)" v-for="i in Number(layoutNum)" :data-key="i" :key="i">
				<div class="video-div">
					<!-- 点击遮罩蒙层，层级7 -->
					<div class="tab-mb" @click="videoTap(i - 1)" @dblclick="apmull(i - 1)"></div>
					<!-- 绿色边框代表选中蒙层，层级8 -->
					<div v-show="i - 1 == chooseIndex && layoutNum != 1" class="tab-mb-active"></div>
					<!-- m3u8的视频播放方式 -->
					<div
						class="video-div video-divs"
						v-show="urlList[i - 1] && urlList[i - 1]['isPlay'] && !urlList[i - 1]['isYt']"
					>
						<!-- m3u8 -->
						<video
							:ref="'videoPlayer' + i"
							loop
							muted
							controls
							autoplay
							:id="'videoPlayer' + i"
							preload="auto"
							class="videoss video-js vjs-default-skin vjs-big-play-centered"
						></video>
						<!-- 视频名称图层，层级5 -->
						<div class="video-name" v-if="urlList[i - 1] && urlList[i - 1]['name']">
							{{ urlList[i - 1]['name'] || '' }}
						</div>

						<!-- 播放出错蒙层，层级4 -->
						<div
							class="flex flex_col flex_a_c flex_ju_c play-error"
							v-if="urlList[i - 1] && urlList[i - 1]['url'].length && urlList[i - 1].isError"
						>
							<img class="img_noplay" src="@/assets/image/videos/noplay.png" alt="" />
							<div class="font_16 c_white error-word">网络错误，请检查网络配置或者播放链接是否正确</div>
						</div>
						<!-- 切换云台控制蒙层，层级5 -->
						<!-- 2022/10/10  whc更改：只有球机才能进行云台控制 -->
						<div
							class="to-controls"
							v-if="urlList[i - 1] && urlList[i - 1].isPlaying && urlList[i - 1].type == '2'"
							@click="tapYt(i - 1)"
						>
							云台控制
						</div>
						<!-- <div class="to-controls" v-if="urlList[i - 1] && urlList[i - 1].isPlaying" @click="tapYt(i - 1)">
							云台控制
						</div> -->
					</div>

					<!-- 云台控制模式，即播放rtsp视频流 -->
					<div
						class="video-div video-divs"
						v-show="urlList[i - 1] && urlList[i - 1]['isPlay'] && urlList[i - 1]['isYt']"
					>
						<iframe
							:src="playUrl"
							frameBorder="0"
							marginHeight="0"
							marginWidth="0"
							style="
					position: absolute;
					background: block,
					z-index: -1;
					top: 5px;
					left: 3px;
					isibility: inherit;
					filter: alpha(opacity=0);
					width: 98.5%;
					height: 98.5%;
				"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * 分屏视频
 */
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import HwCellPlayer from './HwCellPlayer.vue'
export default {
	components: { HwCellPlayer },
	data() {
		return {
			// layoutNum: 1,
			playerList: [], //  视频播放控件合集
			chooseIndex: -1, // 选中值，即让第几个播放rtsp有云台   从 0 开始
			urlList: [], // 视频的code
			oldPlayList: [],
			layoutNum: 1, // 小窗口数量
			chooseYtIndex: -1, // 云台控制选择的是第几个
			playUrl: '',
			thisCodeLength: 0, // 当前页面展示的视频数数据
			isFull: false, // 是否是全屏的状态
			// urlList: [
			// 	{
			// 		url: '',
			// 		code: '', // 主要用于rtsp视频流的拼接
			// 		name: '', // 名称
			// 		isPlay: true, // 视频播放播放
			// 		isYt: false, // 是否在进行云台操作
			// 		isError: false, // 播放是否出错
			// 		isEdit: true, // 视频是否可以直接进行编辑替换
			// 		isPlaying: false, // 视频是否在播放中,主要用于'云台控制'文字的是否显示
			// 	},
			// ],
			options: {
				controls: true, // 是否显示控制条
				muted: true, //是否静音
				autoplay: false, //自动播放
				loop: true, //是否循环
				fluid: false, // 自适应宽高
				language: 'zh-CN', // 设置语言
				// aspectRatio: '3:1',
				inactivityTimeout: false,
				controlBar: {
					// 设置控制条组件
					/* 设置控制条里面组件的相关属性及显示与否
		    'currentTimeDisplay':true,
		    'timeDivider':true,
		    'durationDisplay':true,
		    'remainingTimeDisplay':false,
		    volumePanel: {
		      inline: false,
		    }
		    */
					/* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
					children: [
						{ name: 'playToggle' }, // 播放按钮
						{ name: 'currentTimeDisplay' }, // 当前已播放时间
						{ name: 'progressControl' }, // 播放进度条
						{ name: 'durationDisplay' }, // 总时间
						{
							// 倍数播放
							name: 'playbackRateMenuButton',
							playbackRates: [0.5, 1, 1.5, 2, 2.5],
						},
						{
							name: 'volumePanel', // 音量控制
							inline: false, // 不使用水平方式
						},
						{ name: 'FullscreenToggle' }, // 全屏
					],
				},
				language: 'zh-CN',
				poster: '', //播放前显示的视频画面，播放开始之后自动移除
				preload: 'auto', //预加载
				// width: 650,
				// height: 330,
				techOrder: ['html5', 'flvjs', 'flash'], // 兼容顺序
				flvjs: {
					mediaDataSource: {
						isLive: false,
						cors: true,
						withCredentials: false,
					},
				},
				sources: [],
			},
		}
	},
	watch: {
		layoutNum: {
			handler(val, oldval) {},
			// 深度观察监听
			immediate: true,
		},
	},
	mounted() {
		let _this = this
		$(window).resize(function () {
			if (_this.checkIsFullScreen()) {
				_this.isFull = true
			} else {
				_this.isFull = false
			}
		})
	},
	computed: {
		cellClass() {
			return function (index) {
				switch (Number(this.layoutNum)) {
					case 1:
						return ['cell-player-1']
					case 4:
						return ['cell-player-4']
					case 6:
						if (index == 1) return ['cell-player-6-1']
						if (index == 2) return ['cell-player-6-2']
						if (index == 3) return ['cell-player-6-3']
						return ['cell-player-6']
					case 8:
						if (index == 1) return ['cell-player-8-1']
						if (index == 2) return ['cell-player-8-2']
						if (index == 3) return ['cell-player-shu cell-player-8-3']
						if (index == 4) return ['cell-player-shu cell-player-8-4']
						return ['cell-player-8']
					case 9:
						return ['cell-player-9']
					case 16:
						return ['cell-player-16']
					default:
						break
				}
			}
		},
	},
	beforeDestroy() {
		this.playerList.forEach((item) => {
			if (item) {
				item.dispose()
			}
		})
		this.playerList = []
	},
	destroyed() {},
	methods: {
		handelVideoUrl(code, reloadUrl, name, type) {
			const _this = this
			if (reloadUrl) {
				let videoUrl = {
					url: reloadUrl,
					code: code,
					name: name,
					type: type,
					isPlay: true,
					isYt: false, // 是否开启云台控制
					isError: false, // 是否播放出错
					isEdit: true, // 是否可以直接进行编辑操作
				}
				// 把播放列表的链接添加到存储里面
				this.urlList.push(videoUrl)
				// 判断设置当前选择的窗口
				if (this.urlList.length < this.layoutNum) {
					this.chooseIndex = this.urlList.length
				} else {
					this.chooseIndex = this.urlList.length - 1
				}
				this.$emit('changeVideoIndex', {
					chooseIndex: this.chooseIndex,
					maxIndex: this.urlList.length - 1,
				})

				// options 深层拷贝，防止有更改的时候相互印象
				let options = JSON.parse(JSON.stringify(this.options))
				options.sources = [this.makeUrlSource(reloadUrl)]

				let playerId = ''
				let playerOldLength = this.urlList.length
				playerId = 'videoPlayer' + playerOldLength
				this.$nextTick(() => {
					this.isCanPlay = true
					let videoPlayer = videojs(
						playerId,
						{
							...options,
						},
						function onPlayerReady() {
							this.on('loadstart', function () {
								_this.$set(_this.urlList[playerOldLength - 1], 'isError', false)
								_this.$set(_this.urlList[playerOldLength - 1], 'isPlaying', false)
							})
							this.on('playing', function () {
								_this.$set(_this.urlList[playerOldLength - 1], 'isPlaying', true)
							})
							this.on('error', function () {
								_this.$set(_this.urlList[playerOldLength - 1], 'isError', true)
								_this.$set(_this.urlList[playerOldLength - 1], 'isPlaying', false)
							})
						}
					)
					// 播放控件进行添加
					this.playerList.push(videoPlayer)
				})
			}
		},
		handelVideoUrl2(code, reloadUrl, name, index, type) {
			console.log(index)
			console.log(this.playerList)
			const _this = this
			// 销毁添加以后，视频可以进行编辑操作
			this.$set(this.urlList[index], 'isPlay', true)
			this.$set(this.urlList[index], 'isEdit', true)
			// this.$set(this.urlList[index], 'isError', false)
			this.$set(this.urlList[index], 'code', code)
			this.$set(this.urlList[index], 'url', reloadUrl)
			this.$set(this.urlList[index], 'name', name)
			this.$set(this.urlList[index], 'type', type)

			let options = JSON.parse(JSON.stringify(this.options))
			options.sources = [this.makeUrlSource(reloadUrl)]

			let playerId = 'videoPlayer' + (index + 1)
			console.log(playerId)

			this.$nextTick(() => {
				this.isCanPlay = true
				this.playerList[index].dispose()
				let videoPlayer = videojs(
					playerId,
					{
						...options,
					},
					function onPlayerReady() {
						this.on('loadstart', function () {
							console.log('数据加载')
							_this.$set(_this.urlList[index], 'isError', false)
							_this.$set(_this.urlList[index], 'isPlaying', false)
						})

						this.on('playing', function () {
							_this.$set(_this.urlList[index], 'isPlaying', true)
						})
						this.on('error', function () {
							console.log('数据报错')
							_this.$set(_this.urlList[index], 'isError', true)
							_this.$set(_this.urlList[index], 'isPlaying', false)
						})
					}
				)
				// 播放控件进行添加

				setTimeout(() => {
					this.playerList[index] = videoPlayer
				}, 1000)
			})
		},
		play(i) {
			this.playerList[i].play()
		},
		pause(i) {
			this.playerList[i].pause()
		},
		// 进行视频替换
		editUrl(code, src, name, index, type) {
			console.log(this.urlList[index])
			this.isCanPlay = true
			this.$set(this.urlList[index], 'isPlay', true)
			this.$set(this.urlList[index], 'isEdit', true)
			// this.$set(this.urlList[index], 'isError', false)
			this.$set(this.urlList[index], 'code', code)
			this.$set(this.urlList[index], 'url', src)
			this.$set(this.urlList[index], 'name', name)
			this.$set(this.urlList[index], 'type', type)
			this.$nextTick(() => {
				this.playerList[index].src(this.makeUrlSource(src))
				this.playerList[index].load()
			})
		},
		// 编辑替换：字段设置
		editIsPlay(index, isPlay) {
			console.log(index)
			if (this.urlList[index]) {
				// 判断如果是云台控制，把云台关闭
				if (this.urlList[index]['isYt']) {
					this.$set(this.urlList[index], 'isYt', false)
				}
				if (isPlay && this.urlList[index]['isEdit']) {
					this.$set(this.urlList[index], 'isPlay', isPlay)
				} else {
					this.$set(this.urlList[index], 'isPlay', isPlay)
				}

				if (!isPlay) {
					this.$set(this.urlList[index], 'isEdit', false)
				}
			}
		},
		// 编辑替换：视频替换
		resetEdit(code, src, name, index, type) {
			if (this.urlList[index]) {
				if (this.urlList[index]['isYt']) {
					this.$set(this.urlList[index], 'isYt', false)
					this.chooseYtIndex = -1
					this.$emit('changeYtIndex', {
						val: -1,
					})
				}

				if (this.urlList[index]['isEdit']) {
					console.log('editUrl')
					// 可直接编辑
					this.editUrl(code, src, name, index, type)
				} else {
					// 走销毁后添加
					console.log('handelVideoUrl2')
					this.handelVideoUrl2(code, src, name, index, type)
				}
			}
		},
		dispose() {
			if (this.videoPlayer) {
				this.videoPlayer.dispose()
			}
		},
		makeUrlSource(reloadUrl) {
			const url = reloadUrl.replace(/\s+/g, '')
			let sources = {}
			if (url.indexOf('rtmp') > -1) {
				//rtmp格式视频
				sources = [{ src: reloadUrl, type: 'rtmp/flv' }]
			} else if (url.lastIndexOf('.') > -1) {
				var playurl = url.substring(url.lastIndexOf('.') + 1)
				// console.log(playurl.split('?')[0])
				if (playurl === 'flv') {
					// console.log('flv格式视频')
					//flv格式视频
					sources = [{ src: reloadUrl, type: 'video/x-flv' }]
				} else if (playurl.split('?')[0] === 'flv') {
					//对**直播平台flv地址做特殊处理
					// console.log('flv格式视频')
					//flv格式视频
					sources = [{ src: reloadUrl, type: 'video/x-flv' }]
				} else if (playurl === 'm3u8') {
					//m3u8格式视频
					// console.log('m3u8格式视频')
					sources = [{ src: reloadUrl, type: 'application/x-mpegURL' }]
				} else {
					// console.log('其他格式视频')
					//其他格式视频
					sources = [{ src: reloadUrl, type: 'video/mp4' }]
				}
				return sources
			}
		},
		// 点击选择第几个
		videoTap(index) {
			if (this.chooseIndex == index) return
			// 判断选中的中间是否其余是未填充的
			if (index > this.thisCodeLength) {
				this.$message('此窗口暂不可选择', 'error')
				return
			}
			// 赋值点击选中框
			this.chooseIndex = index
			this.$emit('changeVideoIndex', {
				chooseIndex: index,
				maxIndex: 0,
			})
		},
		// 双击全屏
		apmull(index) {
			if (this.playerList[index].isError) return
			if (this.isFull) {
				this.isFull = false
				var de = document
				if (de.exitFullscreen) {
					de.exitFullscreen()
				} else if (de.mozCancelFullScreen) {
					de.mozCancelFullScreen()
				} else if (de.webkitCancelFullScreen) {
					de.webkitCancelFullScreen()
				}
			} else {
				this.isFull = true
				this.playerList[index].requestFullscreen()
			}
		},
		checkIsFullScreen() {
			var isFullScreen = document.fullscreen || document.mozFullScreen || document.webkitIsFullScreen
			return isFullScreen == undefined ? false : isFullScreen
		},
		// 云台控制选择的是第几个
		tapYt(index) {
			let oldYtIndex = this.chooseYtIndex
			// 判断是否有已经进行云台选中的视频，如果有，恢复播放
			if (oldYtIndex > -1) {
				this.$set(this.urlList[oldYtIndex], 'isYt', false)
				this.playerList[oldYtIndex].play()
			}
			// 视频停止
			this.playerList[index].pause()

			this.$set(this.urlList[index], 'isYt', true)
			// 赋值选中的云台，并切换rtsp视频流播放
			this.chooseYtIndex = index

			this.$emit('changeYtIndex', {
				val: index,
			})
			let code = this.urlList[index]['code']

			let codeSplit = code.split('$')
			let codePin = codeSplit[0]
			// 最后一位
			let codeEndNum = codeSplit.slice(-1)

			let endUrl = `rtsp://221.13.155.38:9090/dss/monitor/param?cameraid=${codePin}%24${codeEndNum}`
			this.$nextTick(() => {
				let urls = './video.html?streamUrl=' + encodeURI(endUrl)
				this.playUrl = urls
			})
		},
		// 切换分栏数量时隐藏回退云台
		changeYtChoose() {
			let oldYtIndex = this.chooseYtIndex
			// 判断是否有已经进行云台选中的视频，如果有，恢复播放
			if (oldYtIndex > -1) {
				this.$set(this.urlList[oldYtIndex], 'isYt', false)
				this.playerList[oldYtIndex].play()
			}
			this.chooseYtIndex = -1
			this.$emit('changeYtIndex', {
				val: -1,
			})
		},
	},
}
</script>

<style lang="scss">
.video-fens {
	width: 100%;
	height: 100%;
	background-color: #000;
	.video-div {
		width: 100%;
		height: 100%;
		position: relative;
		.video-js .vjs-control-bar {
			width: calc(100% - 6px);
			z-index: 10;
			left: 3px;
			bottom: 3px;
		}
		.tab-mb {
			position: absolute;
			width: 100%;
			height: calc(100% - 40px);
			background-color: rgba($color: #000000, $alpha: 0);
			top: 0;
			left: 0;
			z-index: 7;
		}
		.video-divs {
			.close-img {
				width: 0.28rem;
				height: 0.28rem;
				position: absolute;
				right: 0.08rem;
				bottom: 0.13rem;
				cursor: pointer;
			}

			// 播放出错蒙层
			.play-error {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: #000;
				z-index: 4;
				.img_noplay {
					width: 0.76rem;
					height: 0.64rem;
					margin-bottom: 0.46rem;
				}
			}
			// 视频名称图层
			.video-name {
				font-size: 0.2rem;
				color: #fff;
				font-weight: bold;
				position: absolute;
				padding: 0.2rem 0.25rem;
				top: 0;
				right: 0;
				z-index: 5;
			}
			// 唤醒云台控制小图标
			.to-controls {
				position: absolute;
				display: inline-block;
				height: 40px;
				line-height: 40px;
				bottom: 3px;
				right: 3px;
				z-index: 10;
				padding: 0 0.13rem;
				font-size: 0.17rem;
				letter-spacing: 0.01rem;
				color: #fff;
				cursor: pointer;
			}
		}
	}
	.video-js {
		width: 100%;
		height: 100%;
	}
	.cell-players {
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		border: 1px solid #fff;
		position: relative;
		.tab-mb-active {
			position: absolute;
			width: 100.3%;
			height: 100.4%;
			background-color: rgba($color: #000000, $alpha: 0);
			top: -0.2%;
			left: -0.1%;
			border: 3px solid #38aaa7;
			z-index: 8;
		}
	}
	.cell-player {
		border: 1px solid #fff;
	}
	.cell-player-4 {
		width: 50%;
		height: 50% !important;
		box-sizing: border-box;
		border-bottom: 1px solid #fff;
		border-left: 1px solid #fff;
	}
	.cell-player-1 {
		width: 100%;
		box-sizing: border-box;
		border: 1px solid #fff;
	}
	.cell-player-6-1 {
		width: 66.66%;
		height: 66.66% !important;
		box-sizing: border-box;
		border-bottom: 1px solid #fff;
		border-left: 1px solid #fff;
	}
	.cell-player-6-2 {
		width: 33.33%;
		height: 33.33% !important;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid #fff;
		border-left: 1px solid #fff;
		.error-word {
			font-size: 0.17rem;
		}
	}
	.cell-player-6-3 {
		position: absolute;
		right: 0;
		top: 33.33%;
		width: 33.33%;
		height: 33.33%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid #fff;
		border-left: 1px solid #fff;
		.error-word {
			font-size: 0.17rem;
		}
	}
	.cell-player-6-none {
		display: none;
	}
	.cell-player-6-2-cell {
		width: 100%;
		height: 50% !important;
		box-sizing: border-box;
		border-bottom: 1px solid #fff;
		border-left: 1px solid #fff;
	}
	.cell-player-6 {
		width: 33.33%;
		height: 33.33% !important;
		box-sizing: border-box;
		border-left: 1px solid #fff;
		.error-word {
			font-size: 0.17rem;
		}
	}

	.cell-player-8-1 {
		width: 75%;
		height: 75% !important;
		box-sizing: border-box;
		border-bottom: 1px solid #fff;
		border-left: 1px solid #fff;
	}
	.cell-player-shu {
		width: 25%;
		height: 25% !important;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		right: 0;
		position: absolute;
		.img_noplay {
			width: 0.63rem !important;
			height: auto !important;
		}
		.error-word {
			font-size: 0.13rem;
		}
	}
	.cell-player-8-2 {
		width: 25%;
		height: 25% !important;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid #fff;
		border-left: 1px solid #fff;
		.img_noplay {
			width: 0.63rem !important;
			height: auto !important;
		}
		.error-word {
			font-size: 0.13rem;
		}
	}
	.cell-player-8-3 {
		top: 25%;
		border-bottom: 1px solid #fff;
		border-left: 1px solid #fff;
	}
	.cell-player-8-4 {
		top: 50%;
		border-bottom: 1px solid #fff;
		border-left: 1px solid #fff;
	}
	.cell-player-8-none {
		display: none;
	}
	.cell-player-8-2-cell {
		width: 100%;
		height: 33.3% !important;
		box-sizing: border-box;
		border-bottom: 1px solid #fff;
		border-left: 1px solid #fff;
	}
	.cell-player-8 {
		width: 25%;
		height: 25% !important;
		box-sizing: border-box;
		border-left: 1px solid #fff;
		.img_noplay {
			width: 0.63rem !important;
			height: auto !important;
		}
		.error-word {
			font-size: 0.13rem;
		}
	}

	.cell-player-9 {
		width: 33.33%;
		height: 33.33% !important;
		box-sizing: border-box;
	}
	.cell-player-16 {
		width: 25%;
		height: 25% !important;
		box-sizing: border-box;
	}
	.cell {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
}
</style>
