// 生产监测-环境监测图标
let icons = {
	EYHT: require('@/assets/image/production/icons_co2.png'), // 二氧化碳
	GZQD: require('@/assets/image/production/icons_gz.png'), // 光照强度
	SD: require('@/assets/image/production/icons_ks.png'), // 湿度

	WQSD: require('@/assets/image/production/icons_ks.png'), // 空气湿度
	WD: require('@/assets/image/production/icons_kw.png'), // 温度
	WQSD: require('@/assets/image/production/icons_kw.png'), // 空气温度
	TRSD: require('@/assets/image/production/icons_ts.png'), // 土壤湿度
	TRWD: require('@/assets/image/production/icons_tw.png'), // 土壤温度
	FS: require('@/assets/image/qx/icon_feng.png'),
	JYL: require('@/assets/image/qx/icon_yu.png'),
	FX: require('@/assets/image/qx/风向线.png'),
	LDWD: require('@/assets/image/qx/icon_lou.png'),
	YYHT: require('@/assets/image/qx/icon_yyht.png'),
	YQ: require('@/assets/image/qx/icon_yq.png'),
	AQ: require('@/assets/image/qx/icon_aq.png'),
}

// 水肥监测图标
let sfIcons = [
	{
		key: 'other',
		path: require('@/assets/image/sf/icont_gz.png'),
	},
	{
		key: '光照',
		path: require('@/assets/image/sf/icont_gz.png'),
	},
	{
		key: '阀门',
		path: require('@/assets/image/sf/icont_fm.png'),
	},
	{
		key: '阀状态',
		path: require('@/assets/image/sf/icont_fm.png'),
	},
	{
		key: '搅拌',
		path: require('@/assets/image/sf/icont_jb.png'),
	},
	{
		key: 'EC',
		path: require('@/assets/image/sf/icont_tus.png'),
	},
	{
		key: 'PH',
		path: require('@/assets/image/qx/icon_ph.png'),
	},
	{
		key: '二氧化碳',
		path: require('@/assets/image/sf/icont_co2.png'),
	},
	{
		key: '空气温度',
		path: require('@/assets/image/sf/icon_wd.png'),
	},
	{
		key: '空气相对湿度',
		path: require('@/assets/image/sf/icont_ws.png'),
	},
	{
		key: '二氧化碳',
		path: require('@/assets/image/sf/icont_co2.png'),
	},
	{
		key: '土壤温度',
		path: require('@/assets/image/sf/icont_tw.png'),
	},
	{
		key: '土壤湿度',
		path: require('@/assets/image/sf/icont_ts.png'),
	},
	{
		key: '灌溉',
		path: require('@/assets/image/sf/icont_ggb.png'),
	},
	{
		key: '灌区',
		path: require('@/assets/image/sf/icont_ggb.png'),
	},
	{
		key: '施肥',
		path: require('@/assets/image/sf/icont_sb.png'),
	},
]

let sfCmdIcon = [
	{
		key: '轮灌',
		path: require('@/assets/image/sf/icon_ggn.png'),
		actPath: require('@/assets/image/sf/icon_ggh.png'),
	},
	{
		key: '吸肥阀',
		path: require('@/assets/image/sf/icon_xfn.png'),
		actPath: require('@/assets/image/sf/icon_xfh.png'),
	},
	{
		key: '施肥泵',
		path: require('@/assets/image/sf/icon_sfn.png'),
		actPath: require('@/assets/image/sf/icon_sfh.png'),
	},
	{
		key: '灌溉泵',
		path: require('@/assets/image/sf/icon_ggbn.png'),
		actPath: require('@/assets/image/sf/icon_ggbh.png'),
	},
	{
		key: '电磁阀',
		path: require('@/assets/image/sf/icon_dcfn.png'),
		actPath: require('@/assets/image/sf/icon_dcfh.png'),
	},
	{
		key: '搅拌',
		path: require('@/assets/image/sf/icon_jbn.png'),
		actPath: require('@/assets/image/sf/icon_jbh.png'),
	},
	{
		key: '调酸',
		path: require('@/assets/image/sf/icon_tsn.png'),
		actPath: require('@/assets/image/sf/icon_tsh.png'),
	},
	{
		key: '时控定时激活',
		path: require('@/assets/image/sf/icon_dsn.png'),
		actPath: require('@/assets/image/sf/icon_dsh.png'),
	},
	{
		key: '施肥开关',
		path: require('@/assets/image/sf/icon_sfkgn.png'),
		actPath: require('@/assets/image/sf/icon_sfkgn.png'),
	},
	{
		key: '灌溉时长',
		path: require('@/assets/image/sf/icon_ggsn.png'),
		actPath: require('@/assets/image/sf/icon_ggsh.png'),
	},
]
module.exports = {
	icons: icons,
	sfIcons: sfIcons,
	sfCmdIcon: sfCmdIcon,
}
