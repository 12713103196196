<template>
  <div
    class="wrapper-box"
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
    :class="{ complete: mouseIsEnter }"
  >
    <div class="wrapper">
      <div class="logo">
        <img src="../../assets/image/menu/logo.png" alt="" />
      </div>
      <el-menu
        :default-active="activeMenu"
        class="el-menu-vertical-demo"
        background-color="#0B313C"
        text-color="#A7B5B9"
        active-text-color="#fff"
        popper-class="menu-item"
        router
        :collapse="!mouseIsEnter"
      >
        <slide-item
          v-for="routes in menuList"
          :key="routes.path"
          :item="routes"
          :base-path="routes.path"
        ></slide-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import SlideItem from "./SlideItem.vue";
export default {
  components: { SlideItem },
  name: "THeMenu",
  data() {
    return {
      toggle: false,
      clickMode: null,
      mouseIsEnter: false,
    };
  },
  watch: {
    // 监听路由变化
    $route: {
      handler(val, oldval) {
        this.pageChange(val, oldval);
      },
      // 深度观察监听
      deep: true,
    },
  },
  computed: {
    // 动态获取路由菜单拦
    menuList() {
      let arr = [];
      arr = this.$router.options.routes.filter((item) => {
        return !item.hidden;
      });
      return arr;
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  methods: {
    // 路由变化监听
    pageChange(val, oldval) {},
    // 鼠标移入
    mouseOver() {
      this.$bus.$emit("menuenter");
      this.mouseIsEnter = true;
    },
    // 鼠标移出
    mouseLeave() {
      this.$bus.$emit("menuleave");
      this.mouseIsEnter = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-box {
  position: absolute;
  top: 0;
  left: 0;
  // z-index: 10;
  z-index: 99;
  width: 0.83rem;
  height: 100%;
  overflow: hidden;
  transition: width 0.5s;
}
.complete {
  width: 3.4rem;
}
.wrapper {
  // width: 3.4rem;
  width: 3.325rem;
  // width: 76px;
  height: 100%;
  background-color: #0b313c;
  .logo {
    width: 100%;
    height: 0.875rem;
    padding-top: 0.225rem;
    background-color: #0b313c;
    margin-bottom: 0.225rem;

    img {
      width: 3rem;
      height: 0.65rem;
      display: block;
      margin: 0 auto;
      margin-left: 0;
      transform: translateX(8px);
    }
  }
  .el-menu {
    border: none;
  }
  .el-menu-vertical-demo {
    width: 100%;
    height: calc(100% - 1.1rem);
    overflow-y: scroll;
  }
}
</style>
