/*
 * @Description: 使用 moment 依赖获取的各种时间数据,需安装 moment 依赖
 * @Author: Huichao Wang
 * @Date: 2022-04-26
 */
import moment from 'moment'

/*
 * @Description: 获取今天的时间
 * @Author: your name
 * @Date: 2022-04-26
 * @param: format => 自定义显示的格式
 */
function todayTime(format) {
	let formats = format || 'YYYY-MM-DD'
	return moment().format(formats)
}

/*
 * @Description: 获取自定义前几天的时间
 * @Author: Huichao Wang
 * @Date: 2022-04-26
 * @param: day => 往前推的天数
 * @param: format => 自定义显示的格式
 */
function prevDayTime(day, format) {
	let formats = format || 'YYYY-MM-DD'
	return moment().subtract(day, 'day').format(formats)
}

/*
 * @Description: 获取本月第一天时间
 * @Author: Huichao Wang
 * @Date: 2022-05-12
 * @param: format => 自定义显示的格式
 */
function monthStartDay(format) {
	let formats = format || 'YYYY-MM-DD'
	return moment().startOf('month').format(formats)
}

/**
 * 格式化日期
 * 
 * ***/
function getFormatDate(date) {
	return moment(new Date(date)).format('YYYY-MM-DD');;
}
function getFormatDate2(date) {
	return moment(new Date(date)).format('YYYY-MM');;
}
/**
	 *获取几个月前的输入日期
	 *{param:DateTime} date 输入日期(YYYY-MM-DD)
	 *{param:number } monthNum 月数
	 */
function GetPreMonthDay(date, monthNum) {
	var dateArr = date.split('-');
	var year = dateArr[0]; //获取当前日期的年份
	var month = dateArr[1]; //获取当前日期的月份
	var day = dateArr[2]; //获取当前日期的日
	var days = new Date(year, month, 0);
	days = days.getDate(); //获取当前日期中月的天数
	var year2 = year;
	var month2 = parseInt(month) - monthNum;
	if (month2 <= 0) {
		var absM = Math.abs(month2);
		year2 = parseInt(year2) - Math.ceil(absM / 12 == 0 ? 1 : parseInt(absM) / 12);
		month2 = 12 - (absM % 12);
	}
	var day2 = day;
	var days2 = new Date(year2, month2, 0);
	days2 = days2.getDate();
	if (day2 > days2) {
		day2 = days2;
	}
	if (month2 < 10) {
		month2 = '0' + month2;
	}
	var t2 = year2 + '-' + month2 + '-' + day2;
	return t2;
}

/**
*获取下一个月的输入日期
*{param:DateTime} date 输入日期(YYYY-MM-DD)
*{param:number } monthNum 月数
*/
function GetNextMonthDay(date, monthNum) {
	var dateArr = date.split('-');
	var year = dateArr[0]; //获取当前日期的年份
	var month = dateArr[1]; //获取当前日期的月份
	var day = dateArr[2]; //获取当前日期的日
	var days = new Date(year, month, 0);
	days = days.getDate(); //获取当前日期中的月的天数
	var year2 = year;
	var month2 = parseInt(month) + parseInt(monthNum);
	if (month2 > 12) {
		year2 = parseInt(year2) + parseInt((parseInt(month2) / 12 == 0 ? 1 : parseInt(month2) / 12));
		month2 = parseInt(month2) % 12;
	}
	var day2 = day;
	var days2 = new Date(year2, month2, 0);
	days2 = days2.getDate();
	if (day2 > days2) {
		day2 = days2;
	}
	if (month2 < 10) {
		month2 = '0' + month2;
	}

	var t2 = year2 + '-' + month2 + '-' + day2;
	return t2;
}
export { todayTime, prevDayTime, monthStartDay, GetPreMonthDay, GetNextMonthDay, getFormatDate, getFormatDate2 }
