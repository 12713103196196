import Vue from 'vue'
// 2021
Vue.prototype.getNowYear = function () {
	var date = new Date()
	var year = date.getFullYear()
	var currentdate = year + ''
	return currentdate
}

// 2021-07
Vue.prototype.getNowYearMonth = function () {
	var date = new Date()
	var seperator1 = '-'
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}

	var currentdate = year + seperator1 + month
	return currentdate
}

// 2021-07-15
Vue.prototype.getNowDate = function () {
	var date = new Date()
	var seperator1 = '-'
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var strDate = date.getDate()

	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}

	var currentdate = year + seperator1 + month + seperator1 + strDate
	return currentdate
}
// 2021-07-15 10:48
Vue.prototype.getNowFormatDateS = function () {
	var date = new Date()
	var seperator1 = '-'
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var strDate = date.getDate()
	var h = date.getHours()
	var m = date.getMinutes()
	var s = date.getSeconds()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	if (h >= 0 && h <= 9) {
		h = '0' + h
	}
	if (m >= 0 && m <= 9) {
		m = '0' + m
	}
	if (s >= 0 && s <= 9) {
		s = '0' + s
	}
	var currentdate = year + seperator1 + month + seperator1 + strDate + ' ' + h + ':' + m
	return currentdate
}

// 2021-07-15 10:48:50
Vue.prototype.getNowFormatDate = function () {
	var date = new Date()
	var seperator1 = '-'
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var strDate = date.getDate()
	var h = date.getHours()
	var m = date.getMinutes()
	var s = date.getSeconds()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	if (h >= 0 && h <= 9) {
		h = '0' + h
	}
	if (m >= 0 && m <= 9) {
		m = '0' + m
	}
	if (s >= 0 && s <= 9) {
		s = '0' + s
	}
	var currentdate = year + seperator1 + month + seperator1 + strDate + ' ' + h + ':' + m + ':' + s
	return currentdate
}
//没有间隔符号的年月日
Vue.prototype.getmyNowDate = function () {
	var date = new Date()
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var strDate = date.getDate()
	var h = date.getHours()
	var m = date.getMinutes()
	var s = date.getSeconds()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	if (h >= 0 && h <= 9) {
		h = '0' + h
	}
	if (m >= 0 && m <= 9) {
		m = '0' + m
	}
	if (s >= 0 && s <= 9) {
		s = '0' + s
	}
	year = year + ''
	month = month + ''
	var currentdate = year + month + strDate
	return currentdate
}
//获取前一天
Vue.prototype.getPredate = function () {
	var day1 = new Date()
	day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000)
	var year = day1.getFullYear()
	var month = day1.getMonth() + 1
	var strDate = day1.getDate()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	year = year + ''
	month = month + ''
	var s1 = year + month + strDate
	return s1
}

//获取前一周
Vue.prototype.getPredate7 = function () {
	var day1 = new Date()
	day1.setTime(day1.getTime() - 6 * 24 * 60 * 60 * 1000)
	var month = day1.getMonth() + 1
	var strDate = day1.getDate()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	var s1 = day1.getFullYear() + '-' + month + '-' + strDate
	return s1
}

//获取前一月
Vue.prototype.getPredate8 = function () {
	var day1 = new Date()
	day1.setTime(day1.getTime() - 29 * 24 * 60 * 60 * 1000)
	var month = day1.getMonth() + 1
	var strDate = day1.getDate()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	var s1 = day1.getFullYear() + '-' + month + '-' + strDate
	return s1
}
// 格式转化
Vue.prototype.format = function (format, date, isMake = true, isPlace = true) {
	let d = new Date()
	if (!date) {
		d = new Date()
	} else {
		if (isMake) {
			let thisData = date
			if (isPlace) {
				thisData = date.replace(/-/g, '/')
			} else {
				thisData = date
			}

			d = new Date(thisData)
		} else {
			d = date
		}
	}
	// 年
	if (/YYYY/.test(format)) {
		format = format.replace(/YYYY/, d.getFullYear())
	}
	// 月份
	let month = d.getMonth() + 1
	if (/MM/.test(format)) {
		let monthStr = month < 10 ? '0' + month : month
		format = format.replace(/MM/, monthStr)
	} else if (/M/.test(format)) {
		format = format.replace(/M/, month)
	}
	// 日期
	let dates = d.getDate()
	if (/DD/.test(format)) {
		let dateStr = dates < 10 ? '0' + dates : dates
		format = format.replace(/DD/, dateStr)
	} else if (/D/.test(format)) {
		format = format.replace(/D/, dates)
	}
	// 小时
	let hours = d.getHours()
	if (/HH/.test(format)) {
		let hoursStr = hours < 10 ? '0' + hours : hours
		format = format.replace(/HH/, hoursStr)
	} else if (/H/.test(format)) {
		format = format.replace(/H/, hours)
	} else if (/hh/.test(format)) {
		let hoursMin = hours > 12 ? hours - 12 : hours
		let hoursStr = hoursMin < 10 ? '0' + hoursMin : hoursMin
		format = format.replace(/hh/, hoursStr)
	} else if (/h/.test(format)) {
		let hoursMin = hours > 12 ? hours - 12 : hours
		format = format.replace(/h/, hoursMin)
	}
	// 分
	let minutes = d.getMinutes()
	if (/mm/.test(format)) {
		let minutesStr = minutes < 10 ? '0' + minutes : minutes
		format = format.replace(/mm/, minutesStr)
	} else if (/m/.test(format)) {
		format = format.replace(/m/, minutes)
	}
	// 秒
	let seconds = d.getSeconds()
	if (/ss/.test(format)) {
		let secondsStr = seconds < 10 ? '0' + seconds : seconds
		format = format.replace(/ss/, secondsStr)
	} else if (/s/.test(format)) {
		format = format.replace(/s/, seconds)
	}
	return format
}

// 颜色转rgba
Vue.prototype.convertHexToRGB = function (color, opa) {
	if (color.length === 4) {
		let extendedColor = '#'
		for (let i = 1; i < color.length; i++) {
			extendedColor += color.charAt(i) + color.charAt(i)
		}
		color = extendedColor
	}
	const values = {
		r: parseInt(color.substr(1, 2), 16),
		g: parseInt(color.substr(3, 2), 16),
		b: parseInt(color.substr(5, 2), 16),
	}
	return `rgba(${values.r}, ${values.g}, ${values.b}, ${opa})`
}
// 数字转汉字
Vue.prototype.changeNumToHan = function (num) {
	var arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九')
	var arr2 = new Array(
		'',
		'十',
		'百',
		'千',
		'万',
		'十',
		'百',
		'千',
		'亿',
		'十',
		'百',
		'千',
		'万',
		'十',
		'百',
		'千',
		'亿'
	) //可继续追加更高位转换值
	if (!num || isNaN(num)) {
		return '零'
	}
	var english = num.toString().split('')
	var result = ''
	for (var i = 0; i < english.length; i++) {
		var des_i = english.length - 1 - i //倒序排列设值
		result = arr2[i] + result
		var arr1_index = english[des_i]
		result = arr1[arr1_index] + result
	}
	//将【零千、零百】换成【零】 【十零】换成【十】
	result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十')
	//合并中间多个零为一个零
	result = result.replace(/零+/g, '零')
	//将【零亿】换成【亿】【零万】换成【万】
	result = result.replace(/零亿/g, '亿').replace(/零万/g, '万')
	//将【亿万】换成【亿】
	result = result.replace(/亿万/g, '亿')
	//移除末尾的零
	result = result.replace(/零+$/, '')
	//将【零一十】换成【零十】
	//result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
	//将【一十】换成【十】
	result = result.replace(/^一十/g, '十')
	return result
}
// 获取localstorage数据
Vue.prototype.getItem = function (keys) {
	let values = window.localStorage.getItem(keys)
	if (values && values.length) {
		return values
	} else {
		return null
	}
}
Vue.prototype.formateDate = function (date, fmt) {
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	let o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds(),
	}
	for (let k in o) {
		if (new RegExp(`(${k})`).test(fmt)) {
			let str = o[k] + ''
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
		}
	}
	return fmt
}
// 左边补0函数
function padLeftZero(str) {
	return ('00' + str).substr(str.length)
}
// 获取指定月份的天数
Vue.prototype.getThisMonthDay = function (dates) {
	var date = new Date(dates)
	var endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() // 天数
	return endOfMonth
}
