export let iconList = [
	{
		key: 'OUTWD', //室外温度
		path: require('@/assets/image/qx/icon_wd.png'),
	},
	{
		key: 'WD', //温度
		path: require('@/assets/image/qx/icon_qx.png'),
	},
	{
		key: 'SD', //湿度
		path: require('@/assets/image/qx/icon_qx.png'),
	},
	{
		key: 'OUTSD', //室外湿度
		path: require('@/assets/image/qx/icon_qx.png'),
	},
	{
		key: 'TRSD', //土湿
		path: require('@/assets/image/qx/icon_trsd.png'),
	},
	{
		key: 'TRSDA', //土湿
		path: require('@/assets/image/qx/icon_trsd.png'),
	},
	{
		key: 'TRSDB',
		path: require('@/assets/image/qx/icon_trsd.png'),
	},
	{
		key: 'TRSDC',
		path: require('@/assets/image/qx/icon_trsd.png'),
	},
	{
		key: 'TRSDD',
		path: require('@/assets/image/qx/icon_trsd.png'),
	},
	{
		key: 'TRSDE',
		path: require('@/assets/image/qx/icon_trsd.png'),
	},
	{
		key: 'TRWD', //土温
		path: require('@/assets/image/qx/icon_trwd.png'),
	},
	{
		key: 'TRWDA', //土温
		path: require('@/assets/image/qx/icon_trwd.png'),
	},
	{
		key: 'TRWDB',
		path: require('@/assets/image/qx/icon_trwd.png'),
	},
	{
		key: 'TRWDC',
		path: require('@/assets/image/qx/icon_trwd.png'),
	},
	{
		key: 'TRWDD',
		path: require('@/assets/image/qx/icon_trwd.png'),
	},
	{
		key: 'TRWDE',
		path: require('@/assets/image/qx/icon_trwd.png'),
	},
	{
		key: 'FS', //风速
		path: require('@/assets/image/qx/icon_feng.png'),
	},
	{
		key: 'JYL', //降雨量
		path: require('@/assets/image/qx/icon_yu.png'),
	},
	{
		key: 'JYLDAY', //日雨量累计
		path: require('@/assets/image/qx/icon_shui.png'),
	},
	{
		key: 'FX', //风向
		path: require('@/assets/image/qx/风向线.png'),
	},
	{
		key: 'LDWD', //露点温度
		path: require('@/assets/image/qx/icon_lou.png'),
	},
	{
		key: 'EC', //EC
		path: require('@/assets/image/qx/icon_ec.png'),
	},
	{
		key: 'PH', //ph
		path: require('@/assets/image/qx/icon_ph.png'),
	},
	{
		key: 'DLZ', //氮
		path: require('@/assets/image/qx/icon_d.png'),
	},
	{
		key: 'LLZ', //磷
		path: require('@/assets/image/qx/icon_l.png'),
	},
	{
		key: 'JLZ', //钾
		path: require('@/assets/image/qx/icon_j.png'),
	},
	{
		key: 'YSL', //用水量
		path: require('@/assets/image/qx/icon_j.png'),
	},
	{
		key: 'GZQD', //光照强度
		path: require('@/assets/image/qx/icon_gzqd.png'),
	},
	{
		key: 'YW', //烟雾
		path: require('@/assets/image/qx/icon_yw.png'),
	},
	{
		key: 'YWE', //烟雾
		path: require('@/assets/image/qx/icon_yw.png'),
	},
	{
		key: 'YYHT', //一氧化碳
		path: require('@/assets/image/qx/icon_yyht.png'),
	},
	{
		key: 'EYHT', //二氧化碳
		path: require('@/assets/image/qx/icon_eyht.png'),
	},
	{
		key: 'LHQ', //硫化氢
		path: require('@/assets/image/qx/icon_lhq.png'),
	},
	{
		key: 'YQ', //氧气
		path: require('@/assets/image/qx/icon_yq.png'),
	},
	{
		key: 'AQ', //氨气
		path: require('@/assets/image/qx/icon_aq.png'),
	},
	{
		key: 'TRDDL', //土壤导电率
		path: require('@/assets/image/qx/icon_trddl.png'),
	},
	{
		key: 'TRYF', //土壤盐分
		path: require('@/assets/image/qx/icon_tryf.png'),
	},
	{
		key: 'JYLDAY', //日雨量累计
		path: require('@/assets/image/qx/icon_yu.png'),
	},
	{
		key: 'JYL', //降雨量
		path: require('@/assets/image/qx/icon_yu.png'),
	},
	{
		key: 'ZJYL', //当天总雨量
		path: require('@/assets/image/qx/icon_yu.png'),
	},
	{
		key: 'FZGL', //负载功率
		path: require('@/assets/image/qx/icon_fzgl.png'),
	},
	{
		key: 'ZF', //蒸发
		path: require('@/assets/image/qx/icon_ec.png'),
	},
	{
		key: 'ZFE', //蒸发
		path: require('@/assets/image/qx/icon_ec.png'),
	},
	{
		key: 'VOLTAGE', //电压
		path: require('@/assets/image/qx/icon_dy.png'),
	},
	{
		key: 'FY', //负压
		path: require('@/assets/image/qx/icon_ya.png'),
	},
	{
		key: 'JY', //静压
		path: require('@/assets/image/qx/icon_ya.png'),
	},
	{
		key: 'DQYL', //静压
		path: require('@/assets/image/qx/icon_dqyl.png'),
	},
	{
		key: 'TYFSSUM', //辐射
		path: require('@/assets/image/qx/icon_fu.png'),
	},
	{
		key: 'FSLJ', //辐射
		path: require('@/assets/image/qx/icon_fu.png'),
	},
	{
		key: 'ZWX', //紫外线
		path: require('@/assets/image/qx/icon_zi.png'),
	},
	{
		key: 'PAR', //紫外线
		path: require('@/assets/image/qx/icon_zi.png'),
	},
	{
		key: 'PARW', //紫外线
		path: require('@/assets/image/qx/icon_zi.png'),
	},
	{
		key: 'KDSJ', //紫外线
		path: require('@/assets/image/qx/icon_sc.png'),
	},
	{
		key: 'GDSJ', //紫外线
		path: require('@/assets/image/qx/icon_sc.png'),
	},
	{
		key: 'GZSJ', //紫外线
		path: require('@/assets/image/qx/icon_sc.png'),
	},
	{
		key: 'YLL', //紫外线
		path: require('@/assets/image/qx/icon_ylsj.png'),
	},
	{
		key: 'SYDL', //紫外线
		path: require('@/assets/image/qx/icon_XDC.png'),
	},
]

// export default {iconList}
