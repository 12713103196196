<template>
  <div class="breadcrumbBox">
    <span
      class="font_16 c_six onename"
      v-if="levelList[1] && levelList[1]['path'] == '/home'"
      >首页</span>
    <el-breadcrumb class="app-breadcrumb" separator=">" v-else>
      <transition-group name="breadcrumb">
        <el-breadcrumb-item v-for="(item, index) in levelList" :key="index">
          <span class="font_16 c_six" :class="[item.meta.title === '首页'? 'cursor' : '']" @click="goHome(item.meta.title)">{{
            item.meta.title
          }}</span>
          <!-- <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a> -->
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
  </div>
</template>

<script>
/**
 * @description 头部面包屑导航
 */
export default {
  data() {
    return {
      levelList: null,
    };
  },
  watch: {
    $route(route) {
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );
      const first = matched[0];

      if (!this.isDashboard(first)) {
        matched = [].concat(matched);
      }

      this.levelList = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
      console.log(this.levelList);
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name;
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      // const { params } = this.$route
      // var toPath = pathToRegexp.compile(path)
      // return toPath(params)
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(path);
    },
    goHome(str) {
      if (str == "首页") {
        this.$router.push({
          path: "/home",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.onename {
  margin-left: 0.1rem;
}
.breadcrumbBox {
  max-height: 16px;
}
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 16px;
  line-height: 50px;
  margin-left: 0.1rem;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
  .el-breadcrumb__separator {
    color: #666666 !important;
  }
}
</style>
